import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import DialogModal from './DialogModal'
import Button from '../button/Button'
import CloneImage from '../../assets/components/Modal/clone-image.svg'

const Text = styled(Typography)(({ theme }) => ({
  color: '#9f9f9f',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '150%',

  '& .confirm': {
    color: theme.palette.secondary.main,
    fontWeight: 700
  },

  '& .deny': {
    color: theme.palette.error.main,
    fontWeight: 700
  }
}))

interface IModalCloneProps {
  isOpen: boolean
  closeModal: () => void
  action: () => void
}

const ModalClone: React.FC<IModalCloneProps> = ({ isOpen, closeModal, action }) => {
  const theme = useTheme()

  const actionCloneBook = (
    <Box display='flex' justifyContent='center' mb={4} gap={2} width='100%'>
      <Button
        sx={{ width: '107px', color: '#ffffff', fontWeight: 500 }}
        color='error'
        onClick={closeModal}
      >
        Não
      </Button>

      <Button
        sx={{ width: '107px', color: '#ffffff', fontWeight: 500 }}
        color='secondary'
        onClick={action}
      >
        Sim
      </Button>
    </Box>
  )

  return (
    <>
      <DialogModal
        open={isOpen}
        handleClose={closeModal}
        actions={actionCloneBook}
        width='585px'
        height='100%'
      >
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ maxWidth: '405px', textAlign: 'center' }}>
            <img src={CloneImage} width='100%' />
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component='h3'
              sx={{
                color: theme.palette.text.primary,
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '120%',
                margin: '32px 0 16px'
              }}
            >
              Deseja mesmo clonar esse livro?
            </Typography>

            <Text>
              Crie uma cópia idêntica do livro em sua biblioteca.
            </Text>

            <Text>
              <span className='confirm'>Sim</span> para prosseguir, <span className='deny'>Não</span> para reconsiderar.
            </Text>
          </Box>
        </Box>
      </DialogModal>
    </>
  )
}

export default ModalClone
