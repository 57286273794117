
import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { AnyAction } from 'redux-saga'
import { StudentState } from './types'

const initialState: StudentState = {
  items: [],
  currentItem: {
    item: {
      user: {
        id: null,
        name: '',
        email: '',
        first_access: false,
        active: false,
        access_type: null,
        genre: '',
        birthday: '',
        phone: '',
        photo_url: '',
        token: null,
        fractal_id: null,
        children: [],
        facebook_uuid: null,
        taxpayer_number: '',
        additional_data: {
          address: {
            number: '',
            street: '',
            zipcode: '',
            complement: '',
            neighborhood: '',
          }
        },
        units: [],
        last_access_at: '',
        filters: '',
        user_phrase: '',
      },
      classroom_student: {
        id: null,
        user_user_id: null,
        school_classroom_id: null,
        registration: '',
      },
      classrooms: [],
      parents: []
    },
    isFetching: false,
    isError: false,
    messageError: '',
  },
  isFetching: false,
  isSaving: false,
  isError: false,
  messageError: '',
}

const studentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.createStudentRequest, (state: StudentState): StudentState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.createStudentSuccess, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.createStudentRejected, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(actions.updateStudentDataRequest, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.updateStudentDataSuccess, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.updateStudentDataFailure, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: true,
      messageError: 'Erro ao editar estudante.'
    }))
    .addCase(actions.getStudentByIdRequest, (state: StudentState): StudentState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(actions.getStudentByIdSuccess, (state: StudentState, action: AnyAction): StudentState => {
      return ({
        ...state,
        currentItem: {
          item: action.payload,
          isFetching: false,
          isError: false,
          messageError: '',
        }
      })
    })
    .addCase(actions.getStudentByIdRejected, (state: StudentState): StudentState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: 'Erro ao solicitar dados do aluno.',
      }
    }))
    .addCase(actions.getAllStudentsRequest, (state: StudentState): StudentState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.getAllStudentsSuccess, (state: StudentState, action: AnyAction): StudentState => {
      return ({
        ...state,
        isFetching: false,
        isError: false,
        messageError: '',
        items: action.payload.data

      })
    })
    .addCase(actions.getAllStudentsRejected, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar alunos.'
    }))
    .addCase(actions.deleteStudentRequest, (state: StudentState): StudentState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.deleteStudentSuccess, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: 'Sucesso ao deletar o aluno.',
    }))
    .addCase(actions.deleteStudentRejected, (state: StudentState): StudentState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao deletar o aluno.'
    }))
    .addCase(actions.resetStudent, (state: StudentState): StudentState => ({
      ...state,
      items: initialState.items,
      currentItem: initialState.currentItem,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: ''
    }))
})

export default studentReducer
