import React from 'react'
import { useParams } from 'react-router-dom'
import QuestionsFormContainer from '../containers/QuestionsFormContainer'
import QuestionsFormEditContainer from '../containers/QuestionsFormEditContainer'

const QuestionsFormPage = () => {
  const { id } = useParams()
  return (
    <>
      {!id && <QuestionsFormContainer />}
      {id && <QuestionsFormEditContainer id={id} />}
    </>
  )
}

export default QuestionsFormPage