import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import './index.css'
import App from './App.js'
import reportWebVitals from './reportWebVitals'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './store/configureStore'
import GlobalTheme from './index.style'
import SnackBarAlert from './components/alert/SnackBarAlert'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const onBeforeLift = () => {
  // take some action before the gate lifts
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <GlobalTheme>
        <CssBaseline />
        <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
          <App />
          <ToastContainer />
          <SnackBarAlert />
        </PersistGate>
      </GlobalTheme>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
