import React from 'react'
import { useAppSelector } from '../../../store/hooks'
import BooksForm from './BooksForm'
import { checkCurrentRole } from '../../../utils/functions'

const BooksFormView = () => {
  const { auth: { user }, persistable: { selectedRole } } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')

  return (
    <div>
      <BooksForm
        isTeacher={isTeacher}
        userId={user.id}
      />
    </div>
  )
}

export default BooksFormView
