import React, { useRef } from 'react'
import { FieldRenderProps } from 'react-final-form'

// Components
import { Box, IconButton, Typography } from '@mui/material'

// Utils
import { styled } from '@mui/material/styles'

// Icons
import CancelIcon from '@mui/icons-material/Cancel'
import { ReactComponent as Image } from '../../assets/components/Books/image-icon.svg'

const Container = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',

  '& .label': {
    color: '#9F9F9F',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 500
  },

  '& .input_container': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '& input': {
      display: 'none'
    }
  }
}))

const ImagePreview = styled(Box)(() => ({
  alignItems: 'center',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxHeight: '370px',
  maxWidth: '240px',
  position: 'relative',

  '& img': {
    borderRadius: '8px',
    height: '340px',
    width: '100%'
  },

  '& .btn_container': {
    position: 'absolute',
    right: '-10px',
    top: '-10px',

    '& button': {
      background: '#fff',
      padding: 0,
    }
  }
}))

const ButtonContainer = styled(Box)(() => ({
  alignItems: 'center',
  border: '1px dashed #d9d9d9',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '8px',
  height: '100%',
  justifyContent: 'center',
  transition: 'background 0.3s ease-in-out',

  '&:hover': {
    background: '#f9f9f9',
  },

  '& .information': {
    paddingInline: '30px',

    '& p': {
      color: '#9f9f9f',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '150%',
      maxWidth: '182px',
      marginInline: 'auto',
      textAlign: 'center',
    }
  }
}))

type ImputImageProps = FieldRenderProps<string, any>

const InputImage: React.FC<ImputImageProps> = ({
  label,
  input,
  photoObject,
  change,
  clearFile
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonInputRef = useRef<HTMLDivElement>(null)

  const handleFileClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    input.onChange(file)
  }

  const handleClearFile = () => {
    change(input.name, '')
    clearFile?.()
  }

  return (
    <Container>
      {label &&
        <Typography
          className='label'
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <Box className='input_container'>
        <input
          type='file'
          accept='image/png, image/jpeg, image/jpg '
          name={input.name}
          onChange={handleSendFile}
          ref={inputRef}
        />

        {photoObject
          ? (
            <ImagePreview>
              <img
                src={
                  typeof photoObject === 'string'
                    ? photoObject
                    : URL.createObjectURL(photoObject)
                }
                alt='preview da capa do livro'
              />
              <Box className='btn_container'>
                <IconButton
                  color='error'
                  onClick={handleClearFile}
                  title='Excluir capa'
                >
                  <CancelIcon sx={{ fontSize: '25px' }} />
                </IconButton>
              </Box>
            </ImagePreview>
          ) : (
            <ButtonContainer
              onClick={(e) => {
                e.preventDefault()
                handleFileClick()
              }}
              ref={buttonInputRef}
            >
              <Box className='icon'>
                <Image />
              </Box>
              <Box className='information'>
                <Typography>
                  Envie arquivos até 10MB: jpg, jpeg, png. Recomendado: 228x358px.
                </Typography>
              </Box>
            </ButtonContainer>
          )
        }
      </Box>
    </Container>
  )
}

export default InputImage
