import React from 'react'
import DashboardTemplate from '../components/template/DashboardTemplate'
import NotFoundImage from '../assets/components/ManagersPage/no-classrooms.png'
import { Box } from '@mui/system'

const NotFoundPage = () => {
  return (
    <DashboardTemplate title=''>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '32px'
        }}
      >
        <img src={NotFoundImage} />
        <h1
          style={{
            marginTop: '32px'
          }}
        >
          Ops! A página que você procura não existe.
        </h1>
      </Box>
    </DashboardTemplate>
  )
}

export default NotFoundPage
