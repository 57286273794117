import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { ResponseGenerator } from './../../utils/types'

import {
  contentsItemFetchRequest,
  contentsItemFetchSuccess,
  contentsItemFetchFailure,
  contentsChangePagination,
  schoolContentsItemsFetchRequest,
  schoolContentsItemsFetchSuccessful,
  schoolContentsItemsFetchFailure,
  contentsItemFetchByIdRequest,
  contentsItemFetchByIdSuccess,
  contentsItemFetchByIdFailure,
  contentItemCreateRequest,
  contentItemCreateSuccess,
  contentItemCreateFailure,
  contentItemEditRequest,
  contentItemEditSuccess,
  contentItemEditFailure,
  contentItemDeleteRequest
} from './actions'

import { Filter } from './reducer'
import * as services from './services'
import { AnyAction } from 'redux'

type AuthType = {
  user: {
    units: { id: number }[]
  }
}

function* fetchContents({ payload }: AnyAction) {
  try {
    const filters: Filter = yield select(state => state.contents.filters)
    const uiFilters = { ...filters.ui }
    delete uiFilters.checked
    const params = {
      statuses: ['active', 'adapted'].join(),
      ...uiFilters,
      ...filters.query,
      ...payload,
      oder: 'desc'
    }

    const response: ResponseGenerator = yield call(services.fetchContents, { params })
    const { data, headers } = response
    const pagination = {
      totalOfPages: Number(headers['x-total-count']) >= 10000
        ? Math.floor(10000 / filters.query.per)
        : Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count']),
      per: filters.query.per
    }
    yield put(contentsItemFetchSuccess(data.data))
    yield put(contentsChangePagination(pagination))
  } catch (error: any) {
    yield put(contentsItemFetchFailure(error))
  }
}

function* fetchSchoolContents({ payload }: AnyAction) {
  try {
    const filters: Filter = yield select(state => state.contents.filters)
    const schoolId: number | string = yield select(state => state.persistable.selectedUnit?.id)
    const auth: AuthType = yield select(state => state.auth)
    const parsedParams = {
      ...filters.query,
      school_unit_id: schoolId || auth.user.units[0].id,
      subject_ids: !filters.query.subject_ids ? payload.subject_ids : filters.query.subject_ids,
      order: 'desc'
    }
    const response: ResponseGenerator = yield call(services.fetchContents, { params: parsedParams })
    const { data, headers } = response
    const pagination: any = {
      totalOfPages: Number(headers['x-total-count']) >= 10000
        ? Math.floor(10000 / filters.query.per)
        : Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count']),
      per: filters.query.per
    }

    // remove 'NaN'
    const newPagination = Object.keys(pagination).reduce((acc: any, item) => {
      if (Number.isNaN(pagination[item])) {
        acc[item] = 0
      } else {
        acc[item] = pagination[item]
      }
      return acc
    }, {})

    yield put(contentsChangePagination(newPagination))
    yield put(schoolContentsItemsFetchSuccessful(data.data ?? []))
  } catch (error: any) {
    yield put(schoolContentsItemsFetchFailure(error))
  }
}

function* fetchContentById({ payload }: AnyAction) {
  try {
    const response: ResponseGenerator = yield call(services.fetchContentItemById, { id: payload })
    const { data } = response.data
    yield put(contentsItemFetchByIdSuccess(data))
  } catch (error: any) {
    yield put(contentsItemFetchByIdFailure(error))
  }
}

function* createContent({ payload }: AnyAction) {
  const data = payload.data ? payload.data : payload
  const { user } = yield select(state => state.auth)
  const schoolId: (number | string) = yield select(state => state.persistable.selectedUnit.id)
  const subjectIds = data.subject_ids && data.subject_ids.map((item: any) => item.id).join()
  // const ageRangeIds = String(data?.age_ranges?.id)
  const segmentIds = String(data?.segments?.id)
  const allTopics = data?.topics && Object.values(data?.topics).flat().map((topic: any) => topic.id)
  const topicsIds = allTopics ? allTopics.join(',') : null

  const fileUpload = {
    file_name: data.fileData?.data.file_name,
    original_file_name: data.fileData?.data.file_name,
    value: data.fileData?.data.url,
    size: data.fileUpload?.size,
    type: data.fileData?.data.type.split('/')[1]
  }

  const contentFormatId = String(data?.content_format_id)
  let schemaContent
  contentFormatId === '1' ? schemaContent = { value: data.videoInput } :
    contentFormatId === '4' ? schemaContent = fileUpload :
      contentFormatId === '5' ? schemaContent = { value: data.urlInput } :
        contentFormatId === '7' ? schemaContent = { value: data.textInput } : null

  // delete data.age_ranges

  const parsed = {
    ...data,
    // age_range_ids: ageRangeIds,
    language: 'pt-BR',
    is_restricted: true,
    segment_ids: segmentIds,
    subject_ids: subjectIds,
    topic_ids: topicsIds,
    content_format_id: contentFormatId && +contentFormatId,
    schema_content: schemaContent,
    author: user.name,
    school_unit_id: schoolId
  }

  try {
    const response: ResponseGenerator = yield call(services.createContent, parsed)
    if (response.status === 201) {
      yield put(contentItemCreateSuccess())
    }
  } catch (error: any) {
    yield put(contentItemCreateFailure(error))
  }
}

function* editContentItem({ payload }: AnyAction) {
  const { user } = yield select(state => state.auth)
  const schoolId: (number | string) = yield select(state => state.persistable.selectedUnit.id)
  const data = payload.data ? payload.data : payload
  const subjectIds = data.subject_ids && data.subject_ids.map((item: any) => item.id).join()
  // const ageRangeIds = String(data?.age_ranges?.id)
  const segmentIds = String(data?.segments?.id)
  const allTopics = data?.topics && Object.values(data?.topics).flat().map((topic: any) => topic.id)
  const topicsIds = allTopics ? allTopics.join(',') : null

  const fileUpload = {
    file_name: data.schema_content?.file_name,
    original_file_name: data.schema_content?.file_name,
    value: data.schema_content?.value,
    size: data.schema_content?.size,
    type: data.schema_content?.type
  }

  let newFileUpload
  data && data.fileData && Object.keys(data.fileData).length !== 0
    ? newFileUpload = {
      file_name: data?.fileData?.data.file_name,
      original_file_name: data?.fileData?.data.file_name,
      value: data?.fileData?.data.url,
      size: data?.fileUpload?.size,
      type: data?.fileData?.data.type.split('/')[1]
    }
    : newFileUpload = null

  const contentFormatId = String(data?.content_format_id)
  let schemaContent
  if (contentFormatId === '1') {
    schemaContent = { value: data.videoInput }
  } else if (contentFormatId === '4') {
    !fileUpload.file_name
      ? schemaContent = newFileUpload
      : schemaContent = fileUpload
  } else if (contentFormatId === '5') {
    schemaContent = { value: data.urlInput }
  } else if (contentFormatId === '7') {
    schemaContent = { value: data.textInput }
  } else {
    schemaContent = null
  }

  delete data.age_ranges
  delete data.topics

  const parsed = {
    ...data,
    // age_range_ids: ageRangeIds,
    language: 'pt-BR',
    is_restricted: true,
    segment_ids: segmentIds,
    subject_ids: subjectIds,
    topic_ids: topicsIds,
    content_format_id: contentFormatId && +contentFormatId,
    schema_content: schemaContent,
    author: user.name,
    school_unit_id: schoolId
  }

  try {
    yield call(services.editContent, parsed)
    yield put(contentItemEditSuccess())
  } catch (error: any) {
    yield put(contentItemEditFailure(error))
  }
}

function* deleteContentItem({ payload }: AnyAction) {
  try {
    const { pagination: { per, current }, items } = yield select(state => state.contents)
    yield call(services.deleteContent, { id: payload })
    let params: any = {
      page: current,
      per: per || 1
    }
    if (Object.keys(items).length - 1 === 0) {
      params = {
        page: current - 1 !== 0 ? current - 1 : 1,
        per: per || 1
      }
    }
    yield put(schoolContentsItemsFetchRequest(params))
  } catch (error: any) {
  }
}

function* watchFetchContents() {
  yield takeLatest(contentsItemFetchRequest, fetchContents)
}

function* watchFetchSchoolContents() {
  yield takeLatest(schoolContentsItemsFetchRequest, fetchSchoolContents)
}

function* watchFetcContentById() {
  yield takeLatest(contentsItemFetchByIdRequest, fetchContentById)
}

function* watchCreateContent() {
  yield takeLatest(contentItemCreateRequest, createContent)
}

function* watchEditContent() {
  yield takeLatest(contentItemEditRequest, editContentItem)
}

function* watchDeleteContent() {
  yield takeLatest(contentItemDeleteRequest, deleteContentItem)
}

export default function* contentsSagas() {
  yield all([
    watchFetchContents(),
    watchFetchSchoolContents(),
    watchFetcContentById(),
    watchCreateContent(),
    watchEditContent(),
    watchDeleteContent()
  ])
}
