import React, { useState } from 'react'
import { Box, Button, styled, Typography, LinearProgress } from '@mui/material'
import AuthorsTooltip from '../tooltip/AuthorsTooltip'
import { Author } from '../../models/IBooks'
import AuthorsIcon from '../../assets/components/Books/author-icon.svg'
import NoteBookIcon from '../../assets/components/Books/notebook-icon.svg'
import BarCodeIcon from '../../assets/components/Books/barcode-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const Information = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',

  '& .icon': {
    height: '16px',
    width: '16px',
    '& img': {
      height: '100%',
      width: '100%',
    }
  },

  '& span': {
    color: '#666666',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
  }
}))

const Code = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',

  '& span': {
    fontSize: '14px',
    lineHeight: '120%',
  },

  '& span:first-of-type': {
    color: theme.palette.text.primary,
    fontWeight: 500
  },

  '& span:last-of-type': {
    color: theme.palette.primary.light,
    fontWeight: 700
  }
}))

const DescriptionContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',

  '& .description': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%',
    height: '84px',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'left',
    width: '100%',

    '&::before': {
      content: '" "',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '70px',
      background: 'linear-gradient(transparent, #fff)'
    },

    '&.active': {
      height: 'auto',

      '&::before': {
        visibility: 'hidden'
      }
    }
  },

  '& .button': {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    lineHeight: '100%',
    padding: '4px',
    textTransform: 'none',
    width: 'fit-content'
  }
}))

interface IBookInformationsProps {
  bookId?: number
  bookProgress?: number
  bookCover: string
  bookTitle: string
  bookDescription: string
  bookSubjects: { id: number, name: string }[]
  bookAuthors: Author[]
  bookCoauthors?: { id: number, name: string }[]
}

const BookInformations: React.FC<IBookInformationsProps> = ({
  bookId,
  bookCover,
  bookTitle,
  bookSubjects,
  bookDescription,
  bookAuthors,
  bookCoauthors,
  bookProgress
}) => {
  const [open, setOpen] = useState(false)
  const [readMore, setReadMore] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ display: 'flex', gap: '24px' }}>
        <Box sx={{ borderRadius: '8px', height: '180px', overflow: 'hidden', width: '114px' }}>
          {bookCover
            ? (
              <img
                src={bookCover}
                alt='Capa do livro'
                width='100%'
                height='100%'
              />
            )
            : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#f1f1f1',
                  color: '#c9c9c9'
                }}
              >
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )}
        </Box>

        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component='h3'
              sx={{
                color: '#666666',
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '120%',
              }}
            >
              {bookTitle}
            </Typography>

            {bookId && (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                <img src={BarCodeIcon} width='20px' height='20px' />
                <Code>
                  <span>Código:</span>
                  <span>{bookId}</span>
                </Code>
              </Box>
            )}
          </Box>

          <Box>
            <Box sx={{ display: 'flex', gap: '56px', marginBlock: '16px' }}>
              {bookSubjects?.length > 0 && (
                <Information>
                  <Box className='icon'>
                    <img src={NoteBookIcon} />
                  </Box>
                  <span>
                    {bookSubjects?.map(subject => subject.name).join(', ')}
                  </span>
                </Information>
              )}

              <Information>
                <Box className='icon'>
                  <img src={AuthorsIcon} />
                </Box>
                <AuthorsTooltip
                  open={open}
                  openTooltip={handleTooltipOpen}
                  closeTooltip={handleTooltipClose}
                  authors={bookAuthors}
                  coauthors={bookCoauthors}
                />
              </Information>

              {bookProgress &&
                <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', marginLeft: 'auto' }}>
                  <Box
                    component='span'
                    sx={{
                      color: '#666666',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '120%'
                    }}
                  >
                    Progresso:
                  </Box>
                  <Box sx={{ width: '190px' }}>
                    <LinearProgress
                      variant='determinate'
                      value={bookProgress}
                      color='primary'
                      sx={{
                        backgroundColor: '#f0f3f4',
                        '.MuiLinearProgress-bar': {
                          backgroundColor: '#BC9BFA'
                        }
                      }}
                    />
                  </Box>
                  <Box
                    component='span'
                    sx={{
                      color: '#666666',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '120%'
                    }}
                  >
                    {bookProgress}%
                  </Box>
                </Box>
              }
            </Box>
            <DescriptionContainer>
              <Typography className={`description ${readMore ? 'active' : ''}`}>
                {bookDescription}
              </Typography>

              {bookDescription?.length > 100 && (
                <Button
                  className='button'
                  variant='text'
                  onClick={() => setReadMore(prevState => !prevState)}
                >
                  <span>Ler {readMore ? 'menos' : 'mais'}</span>
                  <KeyboardArrowDownIcon
                    sx={{
                      width: '14px',
                      height: '14px',
                      transform: readMore ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                  />
                </Button>
              )}
            </DescriptionContainer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default BookInformations
