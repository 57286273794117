import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled, useTheme } from '@mui/material/styles'

type Props = FieldRenderProps<string, any>;

const Container = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  'input::placeholder': {
    color: '#cecece !important',
    fontFamily: theme.typography.fontFamily
  },
  '> input': {
    border: '1px solid #cecece',
    borderRadius: '8px',
    padding: '10.53px 18px',
    fontSize: '14px',
    width: '100%',
    height: '40px',
    color: '#9F9F9F',
  },
  '& input:focus-visible': {
    outlineColor: `${theme.palette.primary.main} !important`,
  },
}))

const TextInput: React.FC<Props> = ({
  input,
  defaultFieldValue,
  placeholder,
  meta: { touched, error }
 }) => {
  const theme = useTheme()

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textInput = event.target.value
    input.onChange(textInput)
  }

  return (
    <>
      <Container>
        <input
          type='text'
          value={defaultFieldValue || input?.value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Container>
      {touched && error && <div style={{ color: theme.palette.error.main, fontSize: '12px', marginTop: '-8px', textAlign: 'right' }}>{error}</div>}
    </>
  )
}

export default TextInput
