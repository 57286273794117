import { createAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { JsonFormat } from '../../utils/types'
import { withPayloadType } from '../../utils/functions'
import { Class, IClassroom } from '../../models/IClassroom'

export type ClassroomJson = JsonFormat<'classrooms', IClassroom>

export interface CriateClassroomPayload {
  data: object
  afterEffect(res: AxiosResponse): void
  handleErrorEffect(): void
}

export interface FetchClassroomItemsPayload {
  page?: number
  start_from?: string | null
  school?: object[]
  students?: object[]
  school_unit_ids?: number | string
}

export interface FetchClassroomItemsSucessPayload {
  items: object[]
  pagination: object
}

export interface FetchClassroomWithStudentsPayload {
  school_unit_ids?: any
}

export interface FetchClassroomWithStudentsSuccessPayload {
  classes: Class[]
}

export interface FetchClassroomWithStudentsCoordinatorSuccessPayload {
  classes: Class[]
}

export interface FetchClassroomWithStudentsCoordinatorPayload {
  school_unit_ids?: any
  page?: number
}

export interface ChangePaginationPayload {
  total: number
  totalOfPages: number
  current: number
}

export const classroomFetchRequest = createAction('classrooms:CLASSROOM_ITEMS_FETCH_REQUEST', withPayloadType<FetchClassroomItemsPayload>())
export const classroomFetchSuccess = createAction('classrooms:CLASSROOM_ITEMS_FETCH_SUCCESS', withPayloadType<FetchClassroomItemsSucessPayload>())
export const classroomFetchFailure = createAction('classrooms:CLASSROOM_ITEMS_FETCH_ERROR')

export const archiveClassroomRequest = createAction('classrooms:ARCHIVE_CLASSROOM_REQUEST')
export const archiveClassroomSuccess = createAction('classrooms:ARCHIVE_CLASSROOM_SUCCESS')
export const archiveClassroomFailure = createAction('classrooms:ARCHIVE_CLASSROOM_FAILURE')

export const fetchClassroomWithStudentsRequest = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_REQUEST', withPayloadType<FetchClassroomWithStudentsPayload>())
export const fetchClassroomWithStudentsSuccess = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_SUCCESS', withPayloadType<FetchClassroomWithStudentsSuccessPayload>())
export const fetchClassroomWithStudentsFailure = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_FAILURE')

export const fetchClassroomWithStudentsCoordinatorRequest = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_COORDINATOR_REQUEST', withPayloadType<FetchClassroomWithStudentsCoordinatorPayload>())
export const fetchClassroomWithStudentsCoordinatorSuccess = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_COORDINATOR_SUCCESS', withPayloadType<FetchClassroomWithStudentsCoordinatorSuccessPayload>())
export const fetchClassroomWithStudentsCoordinatorFailure = createAction('classrooms:FETCH_CLASSROOM_WITH_STUDENTS_COORDINATOR_FAILURE')

export const classroomResetFilters = createAction('classrooms:CLASSROOM_RESET_FILTERS')

export const classroomWithStudentsChangePagination = createAction('classrooms:CLASSROOM_WITH_STUDENTS_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())








