import React from 'react'
import Box from '@mui/material/Box'
import { Grid, Paper, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import QuestionsContainer from '../containers/QuestionsContainer'
import Label from '../components/label/Label'
import listIcon from '../assets/icons/list-questions.svg'
import Button  from '../components/button/Button'


const QuestionsPage = () => {
  const ImgStyle = styled('img')({
    marginRight: 16,
  })

  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2, width: '100%', justifyContent: 'space-between' }}>
        <Label>
          <ImgStyle src={listIcon} alt='icone de lista' />
          Lista de Questões
        </Label>
        <Button variant='contained' onClick={() => navigate('/questions/form')}>
          Criar Questão
        </Button>
      </Box>
      <Paper sx={{ padding: match ? '56px 16px' : '56px 32px', borderRadius: 4 }}>
        <Grid container spacing={2}>
          <QuestionsContainer />
        </Grid>
      </Paper>
    </div>
  )
}

export default QuestionsPage
