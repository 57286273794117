import { MenuItem, Popover } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface CardContainerProps extends BoxProps {
  status?: string
}

export const CardContainer = styled(Box)<CardContainerProps>(({ theme, status }) => ({
  background: status === 'published'
    ? '#e6f7fa'
    : status === 'ready_to_publish'
      ? '#F5F0FE'
      : '#fce6e6',
  border: '1px solid #d9d9d9',
  borderRadius: '16px',
  display: 'flex',
  gap: '12px',
  padding: '16px 24px',

  '& .total_chapters': {
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  },

  '& .draft': {
    backgroundColor: theme.palette.error.main,
    borderRadius: '32px',
    color: '#ffffff',
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px',
  },
}))

export const BookCover = styled(Box)(() => ({
  borderRadius: '8px',
  flexShrink: 0,
  height: '190px',
  overflow: 'hidden',
  width: '130px',

  '& .cover_book': {
    height: '100%',
    width: '100%'
  },

  '& .nocover_book': {
    height: '100%',
    width: '100%',
    fontSize: '20px',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fbfbfb',
    color: '#c9c9c9'
  }
}))

export const BookInfo = styled(Box)(({ theme }) => ({
  flex: 1,

  '& .first_line': {
    display: 'flex',

    '& .field': {
      alignItems: 'center',
      display: 'flex',

      '&:nth-of-type(2)': {
        flex: 1,
        marginLeft: '65px',
      },

      '&:last-of-type': {
        justifyContent: 'flex-end'
      },

      '& .field_icon': {
        height: '20px',
        width: '20px',

        '& > img': {
          height: '100%',
          width: '100%'
        }
      },

      '& .field_title': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        marginInline: '4px',
      },

      '& .field_result': {
        color: theme.palette.primary.light,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '120%',
      },
    }
  },

  '& .second_line': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px',

    '& .classrooms_container': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .classroom': {
        background: '#fff',
        border: 'none',
        height: 'fit-content',
        maxWidth: '240px',

        [theme.breakpoints.down(1440)]: {
          maxWidth: '170px'
        },

        [theme.breakpoints.down('lg')]: {
          maxWidth: '150px'
        },

        [theme.breakpoints.down('md')]: {
          maxWidth: '120px'
        },

        '& .MuiChip-label': {
          color: theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',
          padding: '4px 6px'
        }
      }
    }
  },

  '& .book_name': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginTop: '16px',

    '& .title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '120%',
    },
  },

  '& .book_description': {
    marginTop: '8px',

    '& .description': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%',
    }
  }
}))

export const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})

export const MenuItemStyle = styled(MenuItem)({
  color: '#9F9F9F',
  fontSize: 14,
  width: 180,
  '& img': {
    marginRight: 10
  }
})

export const SubjectElement = styled(Box)(({ theme }) => ({
  '.subject': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '&_button': {
      minWidth: 'auto',
      padding: 0,
    },

    '&_description': {
      alignItems: 'center',
      display: 'flex',

      'span': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        textTransform: 'capitalize'
      }
    }
  }
}))

export const PopoverStyled = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    padding: '15px'
  },

  'h4': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'capitalize'
  },

  'ul': {
    paddingInlineStart: '25px',

    'li': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      textTransform: 'capitalize'
    }
  }
}))

export const AccessBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  },

  '& .value': {
    background: theme.palette.primary.light,
    borderRadius: '32px',
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px'
  }
}))
