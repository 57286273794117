import { Box } from '@mui/system'
import React, { useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(Box)(({ theme }) => ({
  '& > img': {
    borderRadius: '16px',
    margin: '2px',
    maxWidth: '1104px',
    maxHeight: '439px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  maxWidth: '1104px'
}))

export default function ImagePreview({ url }: any) {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const imageUrl = url

  const openImageViewer = useCallback(() => {
    setCurrentImage(0)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        alt='Imagem resposta do aluno'
        onClick={openImageViewer}
        src={imageUrl}
      />

      {isViewerOpen && (
        <ImageViewer
          src={[imageUrl]}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)'
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  )
}
