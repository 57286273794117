
import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { ISchoolAppIdState } from './types'
import { AnyAction } from 'redux-saga'

const initialState: ISchoolAppIdState = {
  items: [],
  isFetching: false,
  isError: false,
  isSaving: false,
  messageError: '',
}

const schoolAppIdReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getSchoolAppIdRequest, (state: ISchoolAppIdState): ISchoolAppIdState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.getSchoolAppIdSuccess, (state: ISchoolAppIdState, action: AnyAction): ISchoolAppIdState => ({
      ...state,
      items: action.payload,
      isFetching: false,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.getSchoolAppIdRejected, (state: ISchoolAppIdState): ISchoolAppIdState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar APPs Ids.',
    }))

})

export default schoolAppIdReducer
