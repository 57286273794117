import { AxiosResponse } from 'axios'
import http from '../../utils/http'
import { RequestParams } from '../../utils/types'
const usersEndpoint = '/users'


const multipartInstance = http
multipartInstance.defaults.headers.common['Content-Type'] =
    'multipart/form-data; boundary=----------XnJLe9ZIbbGUYtzPQJ16u1'

export const getRoles = (params: any): Promise<AxiosResponse> => {
  return http.get('/roles/application_user_roles', { params })
}
export const getUserData = () => {
  return http.get(usersEndpoint)
}
export const fetchUsers = (params: RequestParams) => {
  const queryParams = new URLSearchParams()
  if (params) {
    const { filters } = params
    Object.keys(filters).forEach((param) => {
      queryParams.append(param, filters[param])
    })
  }
  return http.get(`${usersEndpoint}?${queryParams}`)
}
export const fetchUser = ({ id, params } : { id: string|number, params: RequestParams}) => {
  const queryParams = new URLSearchParams()
  if (params) {
    const { filters } = params
    Object.keys(filters).forEach((param) => {
      queryParams.append(param, filters[param])
    })
  }
  return http.get(`${usersEndpoint}/${id}?${queryParams}`)
}
export const updateUsers = ({ id, data }: { id: string|number, data: FormData }) => {
  return multipartInstance.put(`${usersEndpoint}/${id}`, data)
}

export const deleteUserAvatar = ({ id } : { id: string|number }) => {
  return http.delete(`${usersEndpoint}/${id}/avatar`)
}

