import React from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Grid, Paper } from '@mui/material'
import AsyncSelectInput from './AsyncSelectInput'
import SelectSkill from './SelectSkill'

const SelectCompetenceCombo = ({ name, values }: any) => {
  return (
    <>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.map((name, index) => {
              return (
                <Paper key={`${name}-${index}`} sx={{ padding: '29px 33px', borderRadius: 2, backgroundColor: '#f9f9f9', width: '100%', m: 2, mr: 0 }}>
                  <Grid container>
                    <span style={{ paddingBottom: '23px', color: '#9f9f9f' }}>
                      Associar habilidades à disciplina de {values && values?.subject_ids ? values?.subject_ids[index].name : ''}
                    </span>
                    <Grid item xs={12}>
                      <Field
                        name={`${name}.knowledge_area`}
                        component={AsyncSelectInput}
                        placeholder='Selecione...'
                        label='Área de conhecimento'
                        request={{
                          path: '/knowledge_areas',
                          params: {
                            education_subject_ids: values && values?.subject_ids ? values?.subject_ids[index].id : undefined
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Field
                        name={`${name}.competences`}
                        component={AsyncSelectInput}
                        placeholder='Selecione...'
                        label='Competência'
                        request={{
                          path: '/competences',
                          params: {
                            subject_ids: values && values?.subject_ids ? values?.subject_ids[index].id : undefined,
                            with_skills: true
                          }
                        }}
                      />
                    </Grid>
                    <Paper sx={{ padding: '12px 33px 21px', borderRadius: 2, width: '100%', mt: 2 }}>
                      <Grid item xs={12}>
                        <Field
                          name={`${name}.skills`}
                          component={SelectSkill}
                          values={values.subject_ids}
                          request={values?.subject_ids?.[index]?.competences && {
                            path: '/skills',
                            params: {
                              competence_ids: values?.subject_ids?.[index]?.competences?.id
                            }
                          }}
                          placeholder='Selecione...'
                          isMulti
                          hasBorder
                          label='Habilidades'
                          searchParam='name'
                        />
                      </Grid>
                    </Paper>
                  </Grid>
                </Paper>
              )
            })}
          </>
        )}
      </FieldArray>
    </>
  )
}

export default SelectCompetenceCombo
