import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import { Box, Button as Btn, CircularProgress, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import Button from '../../button/Button'
import Input from '../../input/Input'
import { BoxOrderItems } from '../../card/BoxCard'
import TextAreaInput from '../../input/TextAreaInput'
import ModulesList from '../list/ModulesList'
import ChapterCard from '../card/ChapterCard'
import { SelectInputField } from '../../select/SelectInput'
import BookInformations from '../BookInformations'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { changeChapterOrderRequest, createChapterRequest, fetchChaptersRequest, updateBookRequest } from '../../../store/books/actions'
import { Chapter } from '../../../models/IBooks'
import AddIcon from '../../../assets/components/Books/add-icon.svg'
import { ReactComponent as OrderIcon } from '../../../assets/components/Books/order-icon.svg'

interface BookContentFormProps {
  activeTab?: number
  isTeacher?: any
  userId?: number
  prevTab: () => void
}

interface ChapterValues {
  chapter_title: string
  chapter_description: string
  chapter_order: number
  modulesFilters?: { [key: string]: any }
}

const AddButton = styled(Btn)(() => ({
  alignItems: 'center',
  background: '#f5f5f5',
  border: '1px dashed #B7B2C8',
  borderRadius: '16px',
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
  marginTop: '16px',
  padding: '25px 10px',
  width: '100%',

  '& p': {
    color: '#666666',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '120%',
    textTransform: 'none',
  },

  '&.Mui-disabled': {
    background: '#ffffff',
    borderColor: '#d9d9d9',

    '& img': {
      filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7090%) hue-rotate(93deg) brightness(114%) contrast(70%)',
    },

    '& p': {
      color: '#d9d9d9'
    }
  }
}))

const LoadContainer = styled(Box)(() => ({
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 99999,
}))

const BookContentForm: React.FC<BookContentFormProps> = ({
  activeTab,
  isTeacher,
  userId,
  prevTab
}) => {
  const [openChapterForm, setOpenChapterForm] = useState<boolean>(false)
  const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false)
  const [isSavingBook, setIsSavingBook] = useState<boolean>(false)
  const [enableDragnDrop, setEnableDragnDrop] = useState<boolean>(false)
  const [chapterItems, setChapterItems] = useState<Chapter[]>([])
  const { chapters, currentItem, isSaving } = useAppSelector(state => state.books)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    currentItem.item?.id && dispatch(fetchChaptersRequest({ bookId: currentItem.item?.id }))

    return () => {
      setIsSavingDraft(false)
      setIsSavingBook(false)
    }
  }, [currentItem.item?.id])

  useEffect(() => {
    setChapterItems(chapters.items)

    return () => {
      setChapterItems([])
    }
  }, [chapters.items])

  const chapterSubmit = (values: ChapterValues) => {
    dispatch(createChapterRequest({
      ...values,
      book_id: currentItem.item?.id,
      closeForm: () => setOpenChapterForm(false)
    }))
  }

  const validate = (values: ChapterValues) => {
    const errors: any = {}

    if (!values?.chapter_title) {
      errors.chapter_title = 'Campo obrigatório'
    }

    if (!values?.chapter_description) {
      errors.chapter_description = 'Campo obrigatório'
    }

    return errors
  }

  const showChapterForm = () => {
    setOpenChapterForm((prevState: boolean) => !prevState)
  }

  const handleSaveDraft = () => {
    setIsSavingDraft(true)
    dispatch(updateBookRequest({
      id: currentItem.item?.id,
      name: currentItem.item?.name,
      description: currentItem.item?.description,
      subjects: currentItem.item?.subjects,
      coauthors: currentItem.item?.co_authors?.map((coauthor) => coauthor.user),
      status: 'draft',
      changeSaveDraft: () => setIsSavingDraft(false)
    }))
  }

  const handleSaveBook = () => {
    setIsSavingBook(true)
    dispatch(updateBookRequest({
      id: currentItem.item?.id,
      name: currentItem.item?.name,
      description: currentItem.item?.description,
      subjects: currentItem.item?.subjects,
      coauthors: currentItem.item?.co_authors?.map((coauthor) => coauthor.user),
      status: 'ready_to_publish',
      changeSaveBook: () => setIsSavingBook(false)
    }))
  }

  const handlePublishBook = () => {
    const id = currentItem.item?.id
    dispatch(updateBookRequest({
      id,
      name: currentItem.item?.name,
      description: currentItem.item?.description,
      subjects: currentItem.item?.subjects,
      coauthors: currentItem.item?.co_authors?.map((coauthor) => coauthor.user),
      status: 'ready_to_publish',
      navigateTo: () => navigate(`/books/${id}/publish`)
    }))
  }

  const reorder = (list: Chapter[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    if (result.source.index !== result.destination.index) {
      const items = reorder(chapterItems, result.source.index, result.destination.index)
      setChapterItems(items)
      dispatch(changeChapterOrderRequest(items))
    }
  }

  if (activeTab !== 2) {
    return null
  }

  if (currentItem.isFetching || chapters.isFetching) {
    return (
      <Box>
        <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
          <Skeleton variant='text' sx={{ fontSize: '18px', marginBottom: '16px', width: '250px' }} />

          <Box sx={{ display: 'flex', gap: '24px' }}>
            <Skeleton variant='rectangular' sx={{ borderRadius: '8px', height: '180px', width: '114px' }} />

            <Box sx={{ flex: 1 }}>
              <Skeleton variant='text' sx={{ fontSize: '24px', width: '200px' }} />

              <Box sx={{ display: 'flex', gap: '40px' }}>
                <Skeleton variant='text' sx={{ fontSize: '16px', width: '50px' }} />
                <Skeleton variant='circular' width={24} height={24} />
              </Box>

              <Skeleton variant='rounded' sx={{ height: '90px', marginTop: '16px' }} />
            </Box>
          </Box>

          <Skeleton variant='rounded' height={185} sx={{ marginBlock: '16px', borderRadius: '16px' }} />
          <Skeleton variant='rounded' height={90} sx={{ borderRadius: '16px' }} />
        </Paper>

        <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
          <Skeleton variant='rounded' height={52} width={145} sx={{ borderRadius: '8px' }} />
          <Skeleton variant='rounded' height={52} width={222} sx={{ borderRadius: '8px' }} />
          <Skeleton variant='rounded' height={52} width={145} sx={{ borderRadius: '8px' }} />
          <Skeleton variant='rounded' height={52} width={162} sx={{ borderRadius: '8px' }} />
        </Box>
      </Box>
    )
  }

  return (
    <>
      {chapters.isChangingOrder && (
        <LoadContainer>
          <CircularProgress />
        </LoadContainer>
      )}
      <div>
        <Paper sx={{ borderRadius: openChapterForm ? '16px 16px 0px 0px' : '16px', padding: '32px 24px' }}>
          <Box sx={{ marginBottom: '16px' }}>
            <Typography
              component='h3'
              sx={{
                color: '#666666',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '120%',
              }}
            >
              Livro em construção
            </Typography>
          </Box>

          <BookInformations
            bookAuthors={currentItem.item?.authors}
            bookCoauthors={currentItem.item?.co_authors}
            bookCover={currentItem.item?.photo_url}
            bookDescription={currentItem.item?.description}
            bookSubjects={currentItem.item?.subjects}
            bookTitle={currentItem.item?.name}
          />

          {chapterItems && chapterItems?.length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='chapters' type='list' direction='vertical'>
                {(provided) => {
                  return (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        marginBlock: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px'
                      }}
                    >
                      {chapterItems?.map((chapter: any, index: number) => {
                        return (
                          <ChapterCard
                            key={chapter.id}
                            chapterData={chapter}
                            index={index}
                            bookId={currentItem.item?.id}
                            isTeacher={isTeacher}
                            userId={userId}
                            enableDragnDrop={enableDragnDrop}
                            setEnableDragnDrop={setEnableDragnDrop}
                          />

                        )
                      })}

                      {provided.placeholder}
                    </Box>
                  )
                }}
              </Droppable>
            </DragDropContext>
          )}

          <Box>
            <AddButton type='button' onClick={showChapterForm} disabled={openChapterForm || !!enableDragnDrop}>
              <Box sx={{ width: '40px', height: '40px' }}>
                <img src={AddIcon} alt='ícone de adicionar' width='100%' height='100%' />
              </Box>
              <Typography>
                Clique e adicione o capítulo do seu livro
              </Typography>
            </AddButton>
          </Box>
        </Paper>

        {openChapterForm && (
          <div>
            <Form
              onSubmit={chapterSubmit}
              validate={validate}
              render={({ handleSubmit, form, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Paper sx={{ borderRadius: '0px 0px 16px 16px', padding: '0px 24px 32px' }}>
                      <Box>
                        <Box sx={{ marginBottom: '16px' }}>
                          <Typography
                            component='h3'
                            sx={{
                              color: '#666666',
                              fontSize: '18px',
                              fontWeight: 700,
                              letterSpacing: '0.18px'
                            }}
                          >
                            Sobre o capítulo:
                          </Typography>
                        </Box>

                        <Grid container columnSpacing={3} rowSpacing={2}>
                          <Grid item xs={9}>
                            <Field
                              name='chapter_title'
                              component={Input}
                              label='Título'
                              placeholder='Digite o nome do capítulo'
                            />
                          </Grid>

                          <Grid item xs={3} sx={{ alignItems: 'flex-end', display: 'flex' }}>
                            <BoxOrderItems>
                              <OrderIcon />
                              <Typography
                                sx={{
                                  color: '#9f9f9f',
                                  fontSize: '12px',
                                  fontWeight: 700,
                                  letterSpacing: '0.12px'
                                }}
                              >
                                Ordem do capítulo:
                              </Typography>

                              <Field
                                name='chapter_order'
                                component={SelectInputField}
                                sx={{ width: '55px', border: 'none' }}
                                options={[{ label: chapters.items?.length + 1, value: chapters.items?.length + 1 }]}
                                change={form.change}
                                defaultValue={chapters.items?.length + 1}
                              />
                            </BoxOrderItems>
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name='chapter_description'
                              component={TextAreaInput}
                              label='Descrição'
                              minRows={10}
                              placeholder='Descreva as principais informações...'
                              style={{ width: '100%', height: '104px', resize: 'none' }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <ModulesList
                          change={form.change}
                          isTeacher={isTeacher}
                          userId={userId}
                          values={values}
                        />
                      </Box>
                    </Paper>

                    <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
                      <Button
                        type='button'
                        variant='outlined'
                        onClick={() => setOpenChapterForm(false)}
                      >
                        Voltar
                      </Button>
                      <Button
                        type='submit'
                        variant='contained'
                        disabled={chapters.isSaving}
                        startIcon={
                          chapters.isSaving
                            ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                            : undefined
                        }
                      >
                        Próximo
                      </Button>
                    </Box>
                  </form>
                )
              }}
            />
          </div>
        )}

        {!openChapterForm && chapters && chapters.items?.length === 0 && (
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => prevTab()}
              disabled={isSaving}
            >
              Voltar
            </Button>

            <Button
              type='button'
              variant='contained'
              sx={{
                background: '#bc9bfa',
                '&:hover': {
                  background: '#b18af9'
                }
              }}
              onClick={handleSaveDraft}
              disabled={isSaving}
              startIcon={
                isSaving && isSavingDraft
                  ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                  : undefined
              }
            >
              Salvar rascunho
            </Button>
          </Box>
        )}

        {!openChapterForm && chapters && chapters.items?.length > 0 && (
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Button
              type='button'
              variant='outlined'
              onClick={() => prevTab()}
              disabled={isSaving}
            >
              Voltar
            </Button>

            <Button
              type='button'
              variant='contained'
              sx={{
                background: '#bc9bfa',
                '&:hover': {
                  background: '#b18af9'
                }
              }}
              onClick={handleSaveDraft}
              disabled={isSaving}
              startIcon={
                isSaving && isSavingDraft
                  ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                  : undefined
              }
            >
              Salvar rascunho
            </Button>

            <Button
              type='button'
              variant='contained'
              onClick={handleSaveBook}
              disabled={isSaving}
              startIcon={
                isSaving && isSavingBook
                  ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                  : undefined
              }
            >
              Salvar
            </Button>

            <Button
              type='button'
              variant='contained'
              color='secondary'
              sx={{ color: '#ffffff' }}
              disabled={isSaving}
              onClick={handlePublishBook}
              startIcon={
                isSaving && !isSavingBook && !isSavingDraft
                  ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                  : undefined
              }
            >
              Publicar
            </Button>
          </Box>
        )}
      </div>
    </>
  )
}

export default BookContentForm
