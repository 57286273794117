import React, { useEffect } from 'react'

// Assets
import { ReactComponent as CalendarIcon } from '../../../assets/components/ManagersPage/calendar-icon.svg'

// Components
import { Grid, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataField } from '../title/StyledTitle'

// Styles
import { useTheme } from '@mui/material/styles'

// Utils
import { convertDate, cpfFormatter, zipCodeFormatter } from '../../../utils/functions'

// Redux
import { useDispatch } from 'react-redux'
import { getTeacherByIdRequest } from '../../../store/teachers/actions'
import { useAppSelector } from '../../../store/hooks'

const TeacherInfoTable = ({ data }: any) => {
  const info = data?.user
  const Genre = info?.genre === 'female' ? 'Feminino' : 'Masculino'
  const FormattedBirthDay = info?.birthday && convertDate(info?.birthday)
  const FormattedCPF = info?.taxpayer_number && cpfFormatter(info?.taxpayer_number)
  const theme = useTheme()
  const dispatch = useDispatch()
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const { currentItem } = useAppSelector((state) => state.teachers)
  const teacherClassrooms = currentItem?.item?.user?.classrooms

  function removeDuplicateClassrooms(data: any) {
    const seen = new Set()
    return data?.filter((item: any) => {
      const duplicate = seen?.has(item?.classroom?.id)
      seen.add(item?.classroom?.id)
      return !duplicate
    })
  }

  const removedDuplicatedClassrooms = removeDuplicateClassrooms(teacherClassrooms)

  useEffect(() => {
    dispatch(getTeacherByIdRequest({
      teacherId: info?.id,
      schoolUnitId: selectedUnit?.id
    }))
  }, [])

  return (
    <Box width={'100%'}>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 600,
          pb: '16px'
        }}
      >
        Dados pessoais
      </Typography>
      <>
        <Grid container rowSpacing={3}>
          <Grid item xs={4}>
            <Box>
              <DataField>
                <div>{info?.genre === 'female' ? 'Professora' : 'Professor'}</div>
                <div>{info?.name ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <DataField>
                <div>CPF</div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <CalendarIcon />
                  <div>{FormattedCPF ?? '-'}</div>
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <DataField>
                <div>Data de nascimento</div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <CalendarIcon />
                  <div>{FormattedBirthDay ?? '-'}</div>
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <DataField>
                <div>Email</div>
                <div>{info?.email ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <DataField>
              <div>{removedDuplicatedClassrooms?.length > 1 ? 'Turmas' : 'Turma'}</div>
                {
                  currentItem.isFetching ?
                    (
                      <Skeleton
                        variant='rectangular'
                        animation='wave'
                        sx={{
                          borderRadius: '4px',
                          width: '95%'
                        }}
                      />
                    ) : (
                      <div>
                        {removedDuplicatedClassrooms?.map((item: any) => item?.classroom?.name).join(', ')}
                      </div>
                    )
                }
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <DataField>
                <div>Sexo biológico</div>
                <div>{Genre ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <DataField>
                <div>Contato</div>
                <div>{info?.phone ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <DataField>
                <div>Fractal ID</div>
                <div>{info?.fractal_id ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: '14px',
            fontWeight: 600,
            pb: '16px',
            pt: '66px',
          }}
        >
          Endereço
        </Typography>
        <Grid container rowSpacing={3}>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>CEP</div>
                <div>{zipCodeFormatter(info?.additional_data?.address?.zipcode) ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <DataField>
                <div>Rua</div>
                <div>{info?.additional_data?.address?.street ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Bairro</div>
                <div>
                  {info?.additional_data?.address?.neighborhood ?? '-'}
                </div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Número</div>
                <div>{info?.additional_data?.address?.number ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <DataField>
                <div>Complemento</div>
                <div>{info?.additional_data?.address?.complement ?? '-'}</div>
              </DataField>
            </Box>
          </Grid>
        </Grid>
      </>
    </Box>
  )
}

export default TeacherInfoTable
