import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import Box from '@mui/material/Box'
import {
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Menu,
  Stack,
  Typography
} from '@mui/material'
import AuthorsTooltip from '../../tooltip/AuthorsTooltip'
import ModalPermissions from '../../Modal/ModalPermissions'
import ModalDelete from '../../Modal/ModalDelete'
import ModalCoauthors from '../../Modal/ModalCoauthors'
import ModalClone from '../../Modal/ModalClone'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { deleteBookRequest } from '../../../store/books/actions'

// Utils
import { useTheme } from '@mui/material/styles'
import { Author, Books, CoAuthor } from '../../../models/IBooks'

// Styles
import {
  AccessBox,
  BookCover,
  BookInfo,
  CardContainer,
  IconBtn,
  MenuItemStyle,
  PopoverStyled,
  SubjectElement
} from './styles/BookCard.styles'

// Icons
import AuthorIcon from '../../../assets/components/Books/author-icon.svg'
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'
import BookIcon from '../../../assets/components/Books/book-icon.svg'
import BookPublishIcon from '../../../assets/components/Books/book_publish-icon.svg'
import ClassroomIcon from '../../../assets/components/Books/people-icon.svg'
import CloseIcon from '@mui/icons-material/Close'
import CopyIcon from '../../../assets/components/Books/copy-icon.svg'
import DeleteIcon from '../../../assets/components/Books/trash-icon.svg'
import EditIcon from '../../../assets/components/Books/edit-icon.svg'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import NoteBookIcon from '../../../assets/components/Books/notebook-icon.svg'
import ReportIcon from '../../../assets/components/Books/report-icon.svg'
import SeeMoreIcon from '../../../assets/components/Books/see-more-icon.svg'
import ViewIcon from '../../../assets/components/Books/view-outlined-icon.svg'

interface BookCardProps {
  book?: Books
}

interface IPermission {
  actionType: 'edit' | 'delete' | 'publish'
  isOpen: boolean
}

const BookCard: React.FC<BookCardProps> = ({ book }) => {
  const [open, setOpen] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalPermission, setOpenModalPermission] = useState<IPermission>({ actionType: 'delete', isOpen: false })
  const [openModalCoauthors, setOpenModalCoauthors] = useState(false)
  const [openModalClone, setOpenModalClone] = useState(false)
  const [bookId, setBookId] = useState<any>(null)
  const [anchorMoreEl, setAnchorMoreEl] = useState<HTMLElement | null>(null)
  const openMore = Boolean(anchorMoreEl)
  const [popoverSubject, setPopoverSubject] = useState<HTMLButtonElement | null>(null)
  const [popoverClassroom, setPopoverClassroom] = useState<HTMLButtonElement | null>(null)
  const openPopoverSubject = Boolean(popoverSubject)
  const openPopoverClassroom = Boolean(popoverClassroom)
  const subjectId = openPopoverSubject ? 'subject-popover' : undefined
  const classroomId = openPopoverClassroom ? 'classroom-popover' : undefined
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { roles, user } = useAppSelector(state => state.auth)
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const theme = useTheme()

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMoreEl(event.currentTarget)
  }

  const handleCloseMore = () => {
    setAnchorMoreEl(null)
  }

  const handleSubjectPopoverOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverSubject(e.currentTarget)
  }

  const handleSubjectPopoverClose = () => {
    setPopoverSubject(null)
  }

  const handleClassroomPopoverOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverClassroom(e.currentTarget)
  }

  const handleClassroomPopoverClose = () => {
    setPopoverClassroom(null)
  }

  const textExtract = (text: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = text
    const extractedText = tempElement.innerText
    const limitedText = extractedText.length > 100 ? extractedText.substring(0, 240) + '...' : extractedText
    return limitedText
  }

  const isOwner = (authors?: Author[], coauthors?: CoAuthor[], enableEdit?: boolean) => {
    const isAuthor = authors && authors.some(author => author.user?.id === user.id)
    const isCoAuthor = enableEdit && coauthors && coauthors.some(coauthor => coauthor.user.id === user.id)
    return isAuthor || isCoAuthor
  }

  const handleDeleteBook = (bookId: any, authors?: Author[], coauthors?: CoAuthor[]) => {
    handleCloseMore()
    if (!isOwner(authors, coauthors)) {
      setOpenModalPermission({ actionType: 'delete', isOpen: true })
      return
    }
    setOpenModalDelete(true)
    setBookId(bookId)
  }

  const deleteBook = () => {
    dispatch(deleteBookRequest(bookId))
  }

  const handleEditBook = (bookId?: number, authors?: Author[], coauthors?: CoAuthor[]) => {
    handleCloseMore()
    if (!isOwner(authors, coauthors, true)) {
      setOpenModalPermission({ actionType: 'edit', isOpen: true })
      return
    }
    navigate(`/books/${bookId}/edit`, { state: { isEdit: true } })
  }

  const handleCloneBook = () => {
    handleCloseMore()
    setOpenModalClone(true)
  }

  const handleOpenModalCoauthor = () => {
    handleCloseMore()
    const isAuthor = book?.authors && book?.authors?.some(author => author?.user?.id === user.id)
    const isCoordinator = roles.some(role => role.name === 'coordinator')
    if (isAuthor || isCoordinator) {
      setOpenModalCoauthors(true)
    } else {
      setOpenModalPermission({ actionType: 'edit', isOpen: true })
    }
  }

  const handlePublishBook = () => {
    handleCloseMore()
    const isAuthor = book?.authors && book?.authors?.some(author => author?.user?.id === user.id)
    if (isAuthor) {
      navigate(`/books/${book?.id}/publish`)
    } else {
      setOpenModalPermission({ actionType: 'publish', isOpen: true })
    }
  }

  const navigateToBookReport = () => {
    navigate(`/books/${book?.id}/report`, { state: { bookTitle: book?.name } })
  }

  return (
    <>
      <CardContainer status={book?.status}>
        <BookCover>
          {book?.photo_url
            ? (
              <img
                src={book?.photo_url}
                alt='capa do livro'
                className='cover_book'
              />
            )
            : (
              <Box className='nocover_book'>
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )
          }
        </BookCover>

        <BookInfo>
          <Box className='first_line'>
            <Box className='field'>
              <Box className='field_icon'>
                <img src={BarCodeIcon} />
              </Box>
              <span className='field_title'>Código:</span>
              <span className='field_result'>{book?.id}</span>
            </Box>

            <Box className='field'>
              <Box className='field_icon'>
                <img src={AuthorIcon} />
              </Box>
              <span className='field_title'>Autores:</span>
              <AuthorsTooltip
                open={open}
                openTooltip={handleTooltipOpen}
                closeTooltip={handleTooltipClose}
                authors={book?.authors ?? []}
                coauthors={book?.co_authors ?? []}
              />
            </Box>

            <Box className='field'>
              <IconBtn
                id={`moreActionsButton[${book?.id}]`}
                aria-controls={openMore ? `moreActionsmenu[${book?.id}]` : undefined}
                aria-haspopup='true'
                aria-expanded={openMore ? 'true' : undefined}
                onClick={handleOpenMore}
              >
                <MoreVertRoundedIcon />
              </IconBtn>
              <Menu
                id={`moreActionsmenu[${book?.id}]`}
                keepMounted
                anchorEl={anchorMoreEl}
                open={anchorMoreEl?.id === `moreActionsButton[${book?.id}]`}
                onClose={handleCloseMore}
                TransitionComponent={Fade}
                sx={{
                  '& .MuiPaper-root': {
                    border: `1px solid ${theme.palette.text.secondary}`,
                    borderRadius: '8px',
                    width: '164px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconBtn onClick={handleCloseMore}>
                    <CloseIcon />
                  </IconBtn>
                </Box>

                {
                  <MenuItemStyle onClick={() => navigate(`/books/${book?.id}/details`)}>
                    <img src={ViewIcon} alt='ícone de visualizar' width='18' /> Visualizar
                  </MenuItemStyle>
                }

                <MenuItemStyle onClick={handleOpenModalCoauthor}>
                  <img src={AuthorIcon} alt='icone de coautor' width='16' /> Coautor
                </MenuItemStyle>

                <MenuItemStyle onClick={() => handleEditBook(book?.id, book?.authors, book?.co_authors)}>
                  <img src={EditIcon} alt='icone de editar' width='13' /> Editar
                </MenuItemStyle>

                {(book?.status === 'ready_to_publish' || book?.status === 'published') && (
                  <MenuItemStyle onClick={handlePublishBook}>
                    <img src={BookPublishIcon} alt='ícone de um livro' width='13px' /> Publicar
                  </MenuItemStyle>
                )}

                <MenuItemStyle onClick={handleCloneBook}>
                  <img src={CopyIcon} alt='ícone de clonar' width='12' /> Clonar
                </MenuItemStyle>

                <MenuItemStyle onClick={() => handleDeleteBook(book?.id, book?.authors, book?.co_authors)}>
                  <img src={DeleteIcon} alt='ícone de excluir' width='16' /> Excluir
                </MenuItemStyle>

                <MenuItemStyle onClick={navigateToBookReport}>
                  <img src={ReportIcon} alt='ícone de relatório' width='16' /> Relatório
                </MenuItemStyle>
              </Menu>
            </Box>
          </Box>

          <Box className='second_line'>
            <Box className='classrooms_container'>
              {book?.published_to && book?.published_to?.length !== 0 && (
                <img src={ClassroomIcon} width='20px' height='20px' />
              )}
              {book?.published_to && book?.published_to?.length <= 3 && (
                <Stack
                  direction='row'
                  spacing={1}
                >
                  {book?.published_to?.map((classroom) => (
                    <Chip
                      key={classroom.id}
                      label={classroom.name}
                      title={classroom.name}
                      variant='outlined'
                      className='classroom'
                    />
                  ))}
                </Stack>
              )}

              {book?.published_to && book?.published_to?.length > 3 && (
                <Box sx={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
                  <Stack direction='row' spacing={1}>
                    {book?.published_to?.slice(0, 3)?.map((classroom) => (
                      <Chip
                        key={classroom.id}
                        label={classroom.name}
                        title={classroom.name}
                        variant='outlined'
                        className='classroom'
                      />
                    ))}
                  </Stack>
                  <IconButton
                    aria-describedby={classroomId}
                    type='button'
                    className='classroom classroom_button'
                    onClick={handleClassroomPopoverOpen}
                    sx={{ padding: 0, right: '4px' }}
                  >
                    <img src={SeeMoreIcon} />
                  </IconButton>

                  <PopoverStyled
                    id={classroomId}
                    open={openPopoverClassroom}
                    anchorEl={popoverClassroom}
                    onClose={handleClassroomPopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Box>
                      <Typography component='h4'>Turmas</Typography>
                      <ul>
                        {book?.published_to?.map((classroom) => (
                          <li key={classroom?.id}>
                            {classroom?.name}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </PopoverStyled>
                </Box>
              )}
            </Box>

            <Grid container alignItems='center'>
              <Grid item xs={4}>
                <SubjectElement>
                  {book?.subjects && book?.subjects?.length === 1 && (
                    <Box className='subject'>
                      <img src={NoteBookIcon} width='15.5px' height='15.5px' className='subject_image' />
                      <Box className='subject_description'>
                        <span>{book?.subjects?.[0]?.name}</span>
                      </Box>
                    </Box>
                  )}

                  {book?.subjects && book?.subjects?.length > 1 && (
                    <>
                      <Button
                        aria-describedby={subjectId}
                        type='button'
                        className='subject subject_button'
                        onClick={handleSubjectPopoverOpen}
                      >
                        <img src={NoteBookIcon} height='15.5px' width='15.5px' className='subject_image' />
                        <Box
                          className='subject_description'
                        >
                          <span>{book?.subjects?.[0]?.name}</span>
                          <img src={SeeMoreIcon} />
                        </Box>
                      </Button>
                      <PopoverStyled
                        id={subjectId}
                        open={openPopoverSubject}
                        anchorEl={popoverSubject}
                        onClose={handleSubjectPopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                      >
                        <Box>
                          <Typography component='h4'>Disciplinas</Typography>
                          <ul>
                            {book?.subjects?.map((subject) => (
                              <li key={subject?.id}>
                                {subject?.name}
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </PopoverStyled>
                    </>
                  )}
                </SubjectElement>
              </Grid>

              <Grid item xs={4}>
                <AccessBox>
                  <Box
                    component='span'
                    className='label'
                  >
                    Acessos:
                  </Box>

                  <Box
                    component='span'
                    className='value'
                  >
                    {book && book.access < 10 && `00${book?.access}`}
                    {book && book.access >= 10 && book.access < 100 && `0${book?.access}`}
                    {book && book.access >= 100 && `${book?.access}`}
                  </Box>
                </AccessBox>
              </Grid>
            </Grid>
          </Box>

          <Box className='book_name'>
            <img src={BookIcon} alt='ícone de um livro' width='18px' height='18px' />
            <Typography component='h3' className='title'>
              {book?.name}
            </Typography>
          </Box>

          <Box className='book_description'>
            <Typography className='description'>
              {book && book.description
                ? textExtract(book.description)
                : 'Este livro não possui descrição.'
              }
            </Typography>
          </Box>
        </BookInfo>
      </CardContainer >

      <ModalDelete
        isOpen={openModalDelete}
        closeModal={() => setOpenModalDelete(false)}
        action={deleteBook}
        type='book'
      />

      <ModalPermissions
        open={openModalPermission.isOpen}
        closeModal={() => setOpenModalPermission({ ...openModalPermission, isOpen: false })}
        actionType={openModalPermission.actionType}
      />

      <ModalCoauthors
        isOpen={openModalCoauthors}
        closeModal={() => setOpenModalCoauthors(false)}
        schoolUnitId={selectedUnit?.id}
        book={book}
      />

      <ModalClone
        isOpen={openModalClone}
        closeModal={() => setOpenModalClone(false)}
        action={() => navigate('/books/clone', { state: book?.id })}
      />
    </>
  )
}

export default BookCard
