import React from 'react'
import BooksReportContainer from '../containers/BooksReportContainer'

const BooksReportPage = () => {
  return (
    <div>
      <BooksReportContainer />
    </div>
  )
}

export default BooksReportPage
