import React, { useRef, useState } from 'react'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/configureStore'
import FileIcon from '../../assets/icons/file-icon.svg'
import styles from './styles.module.scss'

type Props = FieldRenderProps<string, any>
interface FileData {
  file_name: string
  id: number
  type: string
  url: string
}

const ImgStyle = styled('img')({
  marginRight: 16,
})

const IconStyle = styled('img')({
  marginRight: 8,
  width: 16
})

const InputFile: React.FC<Props> = ({
  input: { name, onChange },
  contentFormat,
  initialValues,
  change,
  editMode,
  setEditMode
}) => {
  const [fileUploaded, setfileUploaded] = useState<FileData | null>(null)
  const [loading, setLoading] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [error, setError] = useState(false)
  const [msgError, setMsgError] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const buttonInputRef = useRef<HTMLDivElement>(null)
  const { auth: { credentials: { token } } } = useSelector((state: RootState) => state) as { auth: { credentials: { token: string } } }
  const requiredField = 'Campo obrigatório *'
  const fileNotSupported = 'Formato de arquivo não suportado *'

  async function sendFile(file: File): Promise<Response> {
    const formData = new FormData()
    formData.append('attachment', file)
    setLoading(true)
    return fetch('https://staging.datagateway.fractaltecnologia.com.br/api/v1/files/upload', {
      method: 'POST',
      headers: { 'X-TOKEN': token, accept: 'application/json' },
      body: formData,
    })
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && (file.name.includes('.pdf') || file.name.includes('.doc') || file.name.includes('.docx'))) {
      const response = await sendFile(file)
      const data = await response.json()
      change('fileData', data)
      change('fileUpload', file)
      onChange(file)
      setfileUploaded(data.data)
      setError(false)
      setEditMode(false)
    } else if (!(file && (file.name.includes('.pdf') || file.name.includes('.doc') || file.name.includes('.docx')))) {
      setError(true)
      setMsgError(fileNotSupported)
    } else {
      setError(true)
      setMsgError(requiredField)
    }
  }

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleInputClick = () => {
    setError(true)
    setMsgError(requiredField)
  }

  const clearInputFile = () => {
    setfileUploaded(null)
    setLoading(false)
    if (initialValues) {
      setEditMode(true)
    }
    change('schema_content', undefined)
    change('fileData', undefined)
    change('fileUpload', undefined)
    change('attachment', undefined)
    setfileUploaded(null)
    setError(true)
    setMsgError(requiredField)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(true)
    if(e && buttonInputRef.current) {
      buttonInputRef.current.style.backgroundColor = '#efefef'
    }
  }

  const handleDragLeave = () => setDragging(false)

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
    const droppedFile = e.dataTransfer.files[0]
    if (droppedFile && (droppedFile.name.includes('.pdf') || droppedFile.name.includes('.doc') || droppedFile.name.includes('.docx'))) {
      const response = await sendFile(droppedFile)
      const data = await response.json()
      change('fileData', data)
      change('fileUpload', droppedFile)
      onChange(droppedFile)
      setfileUploaded(data.data)
      setEditMode(false)
    } else if (!(droppedFile && (droppedFile.name.includes('.pdf') || droppedFile.name.includes('.doc') || droppedFile.name.includes('.docx')))) {
      setError(true)
      setMsgError(fileNotSupported)
    }
  }

  const ButtonUpload = () => {
    return (
      <div
        className={styles.btFile}
        onClick={(e) => {
          e.preventDefault()
          handleFileClick()
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        ref={buttonInputRef}
      >
        <div className={styles.file_icon}>
          <ImgStyle src={FileIcon} alt='icone de lista' />
        </div>
        <p className={styles.file_paragraph}>Formatos suportados PDF, DOC e DOCX </p>
        <p className={styles.file_paragraph}> Arraste e solte o seu arquivo aqui ou clique para pesquisar no seu computador</p>
      </div>
    )
  }

  const FileFilled = ({ fileUploaded, initialValues }: { fileUploaded?: FileData | null, initialValues?: any }) => {
    return (
      <p className={styles.file_name}>
        <IconStyle src={FileIcon} alt='icone de lista' /> {fileUploaded ? fileUploaded?.file_name : initialValues?.schema_content?.file_name}
        <IconButton
          color='error'
          sx={{ width: 18, position: 'absolute', right: 8 }}
          onClick={() => { clearInputFile() }}>
          <CancelIcon />
        </IconButton>
      </p>
    )
  }

  return (
    <Box>
      <input
        id={`input-${name}`}
        name={name}
        type='file'
        accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
        onChange={handleFileChange}
        onClick={handleInputClick}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      {loading && !fileUploaded && !dragging && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!initialValues && !loading && +contentFormat === 4 && <ButtonUpload />}
      {!initialValues && fileUploaded !== null && +contentFormat === 4 && <FileFilled fileUploaded={fileUploaded} />}
      {initialValues && !loading && editMode && +initialValues?.content_format_id === 4 && <ButtonUpload />}
      {initialValues && !loading && +initialValues?.content_format_id !== 4 && <ButtonUpload />}
      {initialValues && !editMode && +initialValues?.content_format_id === 4 && !fileUploaded && <FileFilled initialValues={initialValues} />}
      {initialValues && !editMode && fileUploaded !== null
        ? <FileFilled fileUploaded={fileUploaded} />
        : null}
      {error && !fileUploaded && !loading && (
        <Typography
          align='right'
          component='p'
          variant='caption'
          sx={{
            color: '#F69E9E'
          }}
        >
          {msgError}
        </Typography>
      )}
    </Box>
  )
}

export default InputFile