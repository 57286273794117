import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { IContent } from './../../models/IContent'

export type FetchContentsFilterPayload = { 
  terms?: string | null
  per?: number, 
  page?: number, 
  ids?: number | string 
  checked?: boolean
}

export type EditContentPayload = { 
  data: object, 
  id?: string | number 
}

export type PaginationPayload = { 
  totalOfPages: number, 
  current: number, 
  total: number, 
  per?: number 
}

export type FetchContentsEditPayload = { 
  id?: string | number 
}

export type DeleteContentPayload = {
  terms?: string | null
  id?: string | number
  page?: number
  per?: number
}

export const contentsItemFetchRequest = createAction('contents:CONTENTS_ITEMS_FETCH_REQUEST', withPayloadType<FetchContentsFilterPayload>())
export const contentsItemFetchSuccess = createAction('contents:CONTENTS_ITEMS_FETCH_SUCCESS', withPayloadType<IContent[]>())
export const contentsItemFetchFailure = createAction('contents:CONTENTS_ITEMS_FETCH_FAILURE', withPayloadType<string>())

export const schoolContentsItemsFetchRequest = createAction('contents:SCHOOL_CONTENTS_ITEMS_FETCH_REQUEST', withPayloadType<FetchContentsFilterPayload>())
export const schoolContentsItemsFetchSuccessful = createAction('contents:SCHOOL_CONTENTS_ITEMS_FETCH_SUCCESS', withPayloadType<IContent[]>())
export const schoolContentsItemsFetchFailure = createAction('contens:SCHOOL_CONTENTS_ITEMS_FETCH_FAILURE', withPayloadType<string>())

export const contentsItemFetchByIdRequest = createAction('contents:CONTENTS_ITEM_FETCH_BY_ID_REQUEST', withPayloadType<FetchContentsEditPayload>())
export const contentsItemFetchByIdSuccess = createAction('contents:CONTENTS_ITEM_FETCH_BY_ID_REQUEST_SUCCESS', withPayloadType<IContent>())
export const contentsItemFetchByIdFailure = createAction('contents:CONTENTS_ITEM_FETCH_BY_ID_REQUEST_FAILURE', withPayloadType<string>())

export const contentItemCreateRequest = createAction('contents:CONTENTS_ITEM_CREATE_REQUEST', withPayloadType<any>())
export const contentItemCreateSuccess = createAction('contents:CONTENTS_ITEM_CREATE_SUCCESS')
export const contentItemCreateFailure = createAction('contents:CONTENTS_ITEM_CREATE_FAILURE', withPayloadType<string>())

export const contentItemEditRequest = createAction('contents:CONTENTS_ITEM_EDIT_REQUEST', withPayloadType<EditContentPayload>())
export const contentItemEditSuccess = createAction('contents:CONTENTS_ITEM_EDIT_SUCCESS')
export const contentItemEditFailure = createAction('contents:CONTENTS_ITEM_EDIT_FAILURE', withPayloadType<string>())

export const contentItemDeleteRequest = createAction('contents:CONTENTS_ITEM_DELETE_REQUEST', withPayloadType<number | string>())
export const contentItemDeleteSuccess = createAction('contents:CONTENTS_ITEM_DELETE_SUCCESS', withPayloadType<DeleteContentPayload>())
export const contentItemDeleteFailure = createAction('contents:CONTENTS_ITEM_DELETE_FAILURE', withPayloadType<string>())

export const contentsChangePagination = createAction('contents:CONTENTS_CHANGE_PAGINATION', withPayloadType<PaginationPayload>())

export const clearCurrentContent = createAction('contents:CLEAR_CURRENT_CONTENT')
export const contentsSaveUiFilter = createAction('contents:CONTENTS_SAVE_UI_FILTERS', withPayloadType<FetchContentsFilterPayload>())
export const contentsResetFilter = createAction('contents:CONTENTS_RESET_FILTERS')

export const setContentSelected = createAction('contents/SET_CONTENT_SELECTED', withPayloadType<{ byId: { [key: string | number]: any }, allIds: (string | number | undefined)[] }>())

