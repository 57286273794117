import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { deleteStudentRequest, updateStudentDataRequest } from '../store/students/actions'
import { useNavigate } from 'react-router-dom'
import { Class, ClassroomStudent } from '../models/IClassroom'
import { fetchClassroomWithStudentsCoordinatorRequest } from '../store/classroom/actions'
import { showAlertMessage } from '../store/alert'

// Assets
import { ReactComponent as PeopleSVG } from '../assets/icons/people-gray.svg'
import { ReactComponent as ResetPasswordIcon } from '../assets/components/ManagersPage/lock-icon.svg'
import { ReactComponent as PlusCircledIcon } from '../assets/components/ManagersPage/plus-circled.svg'
import { ReactComponent as MoveIcon } from '../assets/components/ManagersPage/move-icon.svg'
// import { ReactComponent as TrashIcon } from '../assets/components/ManagersPage/trash-icon.svg'
import { ReactComponent as EditIcon } from '../assets/components/activity/edit-icon.svg'
import { ReactComponent as EyeIcon } from '../assets/components/ManagersPage/eye-icon.svg'
import { ReactComponent as ShiftIcon } from '../assets/components/ManagersPage/shift-icon.svg'
import NoClassroomImage from '../assets/components/ManagersPage/no-classrooms.png'
import TrashImage from '../assets/components/ManagersPage/trash-image.png'
import noStudentsImage from '../assets/components/ManagersPage/no-students.png'

// Components
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import Label from '../components/label/Label'
import CollapseTable from '../components/table/CollapseTable'
import Table, { Columns } from '../components/table/Table'
import DialogModal from '../components/Modal/DialogModal'
import {
  ActionButton,
  AddButton,
  CancelButton,
  CancelMoveStudentButton,
  ConfirmButton,
  ConfirmMoveButton,
  // DeleteStudentsButton,
  MoveStudentsButton,
} from '../components/Management/buttons/StyledButtons'
import { CollapseTitleStyle } from '../components/Management/title/StyledTitle'
import { Container, BoxStyle, StudentsContainer, BoxStyled } from '../components/Management/containers/StyledContainers'
import SeeStudentInfoModal from '../components/Management/modal/SeeStudentInfoModal'
import { ShiftContainer } from '../components/Management/containers/ShiftContainer'
import ResetPasswordModal from '../components/Management/modal/ResetPasswordModal'
import LoadingManageTeachers from '../components/Management/loading/LoadingManageTeachers'

// Style
import { useTheme } from '@mui/material/styles'

// Utils
import { scrollToTop, stringAvatar, truncateText } from '../utils/functions'


const ManageStudentsContainer = () => {

  const classroom = useAppSelector((state) => state.classroom)
  const { pagination } = useAppSelector((state) => state.classroom)
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const [classId, setClassId] = useState<null | number>(null)
  const singleClassroom = classroom?.classes?.find((classroom: Class) => classroom?.id === classId)
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(1)
  const [openDeleteStudentModal, setOpenDeleteStudentModal] = useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
  const [openMoveStudentModal, setOpenMoveStudentModal] = useState(false)
  const [openSeeStudentInfoModal, setOpenSeeStudentInfoModal] = useState(false)
  const [studentId, setStudentId] = useState<null | number>(null)
  const [studentsToMove, setStudentsToMove] = useState<any>()
  const [radioClassroomId, setRadioClassroomId] = useState<string | number | null>(null)
  const [tempClassroom, setTempClassroom] = useState<Class>()
  const [checkedClassroomId, setCheckedClassroomId] = useState<null | number>(null)
  const [ClassroomId, setClassroomId] = useState()
  const [checkedStudents, setCheckedStudents] = useState<any>([])
  const [checkedStudentsId, setCheckedStudentsId] = useState<any>([])
  const [tempStudent, setTempStudent] = useState<any>(null)
  const navigate = useNavigate()
  const theme = useTheme()

  const singleStudent =
    singleClassroom?.students &&
    singleClassroom.students?.find((student: ClassroomStudent) => {
      return student?.id === studentId
    })

  const handleEditStudent = (student: any) => {
    navigate('/manage-student/edit', { state: { classroom: tempClassroom, studentId: student?.id, student } })
    setStudentId(student?.id)
  }

  const handleOpenClassModal = (classroom: Class) => {
    setClassId(classroom.id)
    setTempClassroom(classroom)
  }

  const handleOpenSeeStudentInfoModal = (rowId: number | null) => {
    setOpenSeeStudentInfoModal(true)
    setStudentId(rowId)
  }

  const handleCloseSeeStudentInfoModal = () => {
    setOpenSeeStudentInfoModal(false)
  }

  const handleOpenResetPasswordModal = (student: any) => {
    setOpenResetPasswordModal(true)
    setTempStudent(student)
  }

  const handleDeleteStudent = async () => {
    for (let i = 0; i < checkedStudents.length; i++) {
      const student = checkedStudents[i]
      try {
        await dispatch(deleteStudentRequest(student))
        if (i !== checkedStudents.length - 1) {
          showAlertMessage({
            message: `Aluno ${student.name} deletado com sucesso.`,
            severity: 'success',
            duration: 5000
          })
        }
      } catch (error) {
        showAlertMessage({
          message: `Erro ao deletar o aluno ${student.name}.`,
          severity: 'error',
          duration: 5000
        })
      }
    }
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }, 1500)
  }

  const handleCloseDeleteStudentModal = () => {
    setOpenDeleteStudentModal(false)
  }

  const handleCloseMoveStudentModal = () => {
    setOpenMoveStudentModal(false)
  }

  // const handleOpenDeleteStudentModal = () => {
  //   setOpenDeleteStudentModal(true)
  // }

  const handleOpenMoveStudentModal = (studentsListToMove: any) => {
    setOpenMoveStudentModal(true)
    setStudentsToMove(studentsListToMove)
    setRadioClassroomId(studentsListToMove[0]?.classroomId)
  }

  const handleSelectClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioClassroomId(event.target.value)
  }

  useEffect(() => {
    dispatch(fetchClassroomWithStudentsCoordinatorRequest({ school_unit_ids: selectedUnit?.id }))
  }, [selectedUnit])

  const handleCheckStudent = (singleStudent: ClassroomStudent, currentClassroomId: any) => {
    setClassroomId(currentClassroomId)

    if (checkedStudents.length !== 0 && singleStudent?.classroomId !== checkedStudents[0]?.classroomId) {
      setCheckedStudentsId([])
      setCheckedStudents([])
      setCheckedClassroomId(null)
    } else {

      if (checkedStudentsId.includes(singleStudent.id)) {
        const updatedCheckedStudentIds = checkedStudentsId.filter((id: number) => id !== singleStudent.id)
        setCheckedStudentsId(updatedCheckedStudentIds)
        const updatedCheckedStudents = checkedStudents.filter((student: ClassroomStudent) => student.id !== singleStudent.id)
        setCheckedStudents(updatedCheckedStudents)
      } else {
        setCheckedStudentsId([...checkedStudentsId, singleStudent.id])
        setCheckedStudents([...checkedStudents, singleStudent])
      }
    }
  }

  const handleSelectAll = (classroom: Class, currentClassroomId: any, amountOfStudents: number) => {
    setClassroomId(currentClassroomId)
    if ((checkedClassroomId === currentClassroomId) && (amountOfStudents === checkedStudentsId?.length)) {
      setCheckedStudentsId([])
      setCheckedStudents([])
      setCheckedClassroomId(null)
    } else {
      const studentIds = classroom?.students?.map((student: ClassroomStudent) => student.id)
      setCheckedStudentsId(studentIds)
      setCheckedStudents(classroom.students)
      setCheckedClassroomId(currentClassroomId)
    }
  }

  const handleConfirmMoveStudent = async () => {
    for (let i = 0; i < (studentsToMove && studentsToMove.length); i++) {
      const student = studentsToMove && studentsToMove[i]
      try {
        await dispatch(updateStudentDataRequest({ student, selectedUnit, radioClassroomId }))
        if (i !== studentsToMove.length - 1) {
          showAlertMessage({
            message: `Aluno ${student.name} movido de sala com sucesso.`,
            severity: 'success',
            duration: 5000
          })
        }
      } catch (error) {
        showAlertMessage({
          message: `Erro ao mover o aluno ${student.name} de sala.`,
          severity: 'error',
          duration: 5000
        })
      }
    }
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(fetchClassroomWithStudentsCoordinatorRequest({ school_unit_ids: selectedUnit?.id, page: value }))
    scrollToTop()
  }

  const studentColumns: Columns[] = [
    {
      key: 'checkedStudent',
      name: '',
      width: '1%',
      render: (row: ClassroomStudent) => {
        function isStudentChecked(currentUserId: any, currentClassroomId: any) {
          for (const student of checkedStudents) {
            if ((student.id === currentUserId) && (student.classroomId === currentClassroomId)) {
              return true
            }
          }
          return false
        }
        return (
          <Checkbox
            onChange={() => handleCheckStudent(row, row?.classroomId)}
            checked={isStudentChecked(row?.id, row?.classroomId)}
          />
        )
      },
    },
    {
      key: 'name',
      name: (
        <Typography component='p' color='#666666' sx={{ textAlign: 'left' }} fontWeight={700}>
          Nome do aluno
        </Typography>
      ),
      width: '20%',
      render: (row: ClassroomStudent) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar {...stringAvatar(row?.name)} variant='rounded' />
            <Typography component='p' color='#666' fontWeight={500} fontSize={'14px'} textAlign={'left'}>
              {truncateText(row?.name, 35)}
            </Typography>
          </Box>
        )
      },
    },
    {
      key: 'parent',
      name: (
        <Typography component='p' color='#666666' fontWeight={700}>
          Responsável
        </Typography>
      ),
      width: '20%',
      render: (row: ClassroomStudent) => {
        return (
          <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              {row?.parents && Array.isArray(row.parents) && row?.parents.length === 1 ? (
                <Box sx={{ fontSize: '14px', fontWeight: 500, color: '#666' }}>
                  {typeof row?.parents[0]?.name === 'string'
                    ? truncateText(row?.parents[0]?.name, 24)
                    : 'Não possui responsável'}
                </Box>
              ) : (
                <Box sx={{ fontSize: '14px', fontWeight: 500, color: '#666' }}>
                  {typeof row?.parents[0]?.name === 'string' && typeof row?.parents[1]?.name === 'string'
                    ? `${truncateText(row?.parents[0]?.name, 20)}/ ${truncateText(row?.parents[1]?.name, 20)}`
                    : 'Não possui responsável'}
                </Box>
              )}
            </Box>
          </Box>
        )
      },
    },
    {
      key: 'contact',
      name: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: '#666666',
            fontWeight: 700,
            justifyContent: 'center',
          }}
        >
          Contato do Responsável
        </Box>
      ),
      width: '20%',
      render: (row: ClassroomStudent) => {
        return (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 500,
              fontSize: '14px',
              color: '#666',
            }}
          >
            {row?.parents[0]?.phone ?? 'Não possui contato'}
          </Box>
        )
      },
    },
    {
      key: 'actions',
      name: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: '#666666',
            fontWeight: 700,
            justifyContent: 'end',
          }}
        >
          Ações
        </Box>
      ),
      width: '20%',
      render: (row: ClassroomStudent) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '16px'
            }}
          >
            <Tooltip title='Gerar senha temporária'>
              <ActionButton onClick={() => handleOpenResetPasswordModal(row)}>
                <ResetPasswordIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title='Visualizar'>
              <ActionButton onClick={() => handleOpenSeeStudentInfoModal(row.id)}>
                <EyeIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title='Editar'>
              <ActionButton onClick={() => handleEditStudent(row)}>
                <EditIcon />
              </ActionButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <div>
      {classroom.isFetching ? (
        <LoadingManageTeachers />
      ) : (
        <>
          {classroom.classes.length === 0 ? (
            <Paper sx={{ display: 'flex', justifyContent: 'center', minHeight: 459, alignItems: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <img width={335} src={NoClassroomImage} alt='Homem surpreso' />
                <Typography sx={{ color: theme.palette.text.primary, fontWeight: 500, fontSize: '18px', pt: '32px' }}>Você <span style={{ color: theme.palette.primary.light }}>não tem turmas</span> cadastradas ainda</Typography>
              </Box>
            </Paper>
          ) : (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Label sx={{ paddingBottom: '16px' }}>
                  <PeopleSVG />
                  Selecione uma turma
                </Label>
                <AddButton onClick={() => navigate('/manage-student/create')}>
                  <PlusCircledIcon />
                  Adicionar aluno(s)
                </AddButton>
              </Box>
              <DialogModal
                sx={{
                  paddingTop: 0
                }}
                open={openResetPasswordModal}
                handleClose={() => setOpenResetPasswordModal(false)}
                width='30%'
              >
                <ResetPasswordModal setOpenResetPasswordModal={setOpenResetPasswordModal} student={tempStudent} />
              </DialogModal>
              <DialogModal
                width='90%'
                height='85%'
                open={openSeeStudentInfoModal}
                handleClose={handleCloseSeeStudentInfoModal}
              >
                <SeeStudentInfoModal singleStudent={singleStudent} />
              </DialogModal>
              <DialogModal height='90%' open={openMoveStudentModal} handleClose={handleCloseMoveStudentModal} width='808px'>
                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingInline: '24px',
                  }}
                >
                  <FormLabel sx={{ fontSize: '18px', paddingBottom: '16px', fontWeight: 700, color: '#666' }}>
                    Mova o aluno para uma nova turma
                  </FormLabel>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: '29px' }}>
                    <Typography sx={{ color: '#9F9F9F', fontWeight: 600 }}>Turma</Typography>
                    <Typography sx={{ color: '#9F9F9F', fontWeight: 600 }}>Qtde alunos</Typography>
                  </Box>
                  <BoxStyled sx={{ width: '100%' }}>
                    {classroom.classes.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: '24px',
                            width: '100%',
                          }}
                          key={item.id}
                        >
                          <RadioGroup
                            sx={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                            aria-label='class-id'
                            name='class'
                            value={radioClassroomId}
                            onChange={handleSelectClass}
                          >
                            <Box>
                              <FormControlLabel
                                value={item.id}
                                control={
                                  <Radio />
                                }
                                label=''
                              />
                              <CollapseTitleStyle>{item?.name}</CollapseTitleStyle>
                            </Box>
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                width: '105px',
                                justifyContent: 'space-between',
                                color: '#666',
                                fontWeight: 500,
                              }}
                            >
                              <PeopleSVG />
                              {item?.students.length} {item?.students.length === 1 ? 'aluno' : 'alunos'}
                            </Typography>
                          </RadioGroup>
                        </Box>
                      )
                    })}
                  </BoxStyled>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '16px',
                      width: '100%',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <CancelMoveStudentButton
                      onClick={handleCloseMoveStudentModal}
                    >
                      Cancelar
                    </CancelMoveStudentButton>
                    <ConfirmMoveButton
                      onClick={() => handleConfirmMoveStudent()}
                    >
                      Confirmar
                    </ConfirmMoveButton>
                  </Box>
                </FormControl>
              </DialogModal>
              <DialogModal
                open={openDeleteStudentModal}
                handleClose={handleCloseDeleteStudentModal}
                width='528px'
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '32px',
                    paddingBottom: '24px',
                  }}
                  textAlign='center'
                  pr={2}
                  pl={2}
                >
                  <img
                    alt='Imagem de cesto de lixo'
                    src={TrashImage}
                    width={205}
                  />
                  {
                    checkedStudents?.length > 1 &&
                    <Label
                      sx={{
                        margin: 0,
                        padding: 0
                      }}
                    >
                      Deseja mesmo excluir os alunos selecionados?
                    </Label>
                  }
                  {
                    checkedStudents?.length === 1 &&
                    <Label
                      sx={{
                        margin: 0,
                        padding: 0
                      }}
                    >Deseja mesmo excluir o aluno selecionado?
                    </Label>
                  }
                  <BoxStyle sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', gap: '16px' }}>
                      <CancelButton
                        onClick={handleCloseDeleteStudentModal}
                      >
                        Não
                      </CancelButton>
                      <ConfirmButton
                        onClick={() => handleDeleteStudent()}
                      >
                        Sim
                      </ConfirmButton>
                    </Box>
                  </BoxStyle>
                </Box>
              </DialogModal>
              {
                classroom.classes.length > 0 &&
                classroom.classes.map((item) => {
                  const dataWithClassroomId = item?.students?.map((student: any) => ({
                    ...student,
                    classroomId: item?.id
                  }))
                  const addClassroomIdToStudents = (item: any, classroomId: number) => {
                    if (!item || !item.students || !Array.isArray(item.students) || !classroomId) {
                      console.error('Objeto item inválido ou classroomId não fornecido.')
                      return null
                    }
                    const updatedStudents = item?.students?.map((student: ClassroomStudent) => {
                      const updatedStudent = { ...student }
                      updatedStudent.classroomId = classroomId
                      return updatedStudent
                    })

                    return {
                      ...item,
                      students: updatedStudents
                    }
                  }
                  const newItem = addClassroomIdToStudents(item, item.id)
                  const handleCheckedAllStudents = (currentClassroomId: any, item: any, checkedStudents: any) => {
                    if (!currentClassroomId || currentClassroomId !== item?.id) {
                      return false
                    }

                    if (!checkedStudents || checkedStudents.length === 0 || checkedStudents.length !== item?.students?.length) {
                      return false
                    }

                    return true
                  }
                  return (
                    <Container onClick={() => handleOpenClassModal(item)} key={item?.id}>
                      <CollapseTable
                        maxWidth={'345px'}
                        minWidth={'345px'}
                        primaryText={
                          <CollapseTitleStyle>
                            {truncateText(item?.name, 41)}
                          </CollapseTitleStyle>
                        }
                        secondaryText={
                          <ShiftContainer>
                            <ShiftIcon />
                            {(() => {
                              let translatedShift = ''
                              switch (item?.shift) {
                                case 'morning':
                                  translatedShift = 'Manhã'
                                  break
                                case 'afternoon':
                                  translatedShift = 'Vespertino'
                                  break
                                case 'night':
                                  translatedShift = 'Noturno'
                                  break
                                case 'fulltime':
                                  translatedShift = 'Integral'
                                  break
                                default:
                                  translatedShift = 'Sem turno'
                                  break
                              }
                              return translatedShift
                            })()}
                          </ShiftContainer>
                        }
                        tertiaryText={
                          <Box sx={{ fontSize: 14, fontWeight: 500 }}>
                            <Checkbox
                              onChange={() => handleSelectAll(newItem, item?.id, item?.students?.length)}
                              checked={handleCheckedAllStudents(ClassroomId, item, checkedStudents)}
                            />
                           {handleCheckedAllStudents(ClassroomId, item, checkedStudents) ? 'Desmarcar todos' : 'Selecionar todos'}
                          </Box>
                        }
                        quaternaryText={<Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            component='span'
                            sx={{
                              display: 'flex',
                              fontSize: 14,
                              gap: 1,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              ml: 4,
                            }}
                          />
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              fontWeight: 500,
                              gap: '4px',
                              justifyContent: 'space-between',
                              minWidth: '100px'
                            }}
                          >
                            <PeopleSVG style={{ width: '15px' }} />
                            <span>{item.students.length}</span>
                            <span>{item.students.length === 1
                              ? `Aluno`
                              : `Alunos`}</span>
                          </Box>
                        </Box>}
                        details={
                          <div>
                            <StudentsContainer>
                              {item.students.length > 0 ? (
                                <BoxStyled>
                                  <Table columns={studentColumns} data={dataWithClassroomId} />
                                  <Box sx={{
                                    display: 'flex',
                                    gap: '16px',
                                    justifyContent: 'flex-end'
                                  }}
                                  >
                                    <MoveStudentsButton
                                      disabled={
                                        checkedStudents.length === 0 ||
                                        checkedStudents[0]?.classroomId !== item?.id
                                      }
                                      onClick={() => handleOpenMoveStudentModal(checkedStudents)}
                                    >
                                      <MoveIcon /> Mover
                                    </MoveStudentsButton>
                                    {/* <DeleteStudentsButton
                                      disabled={
                                        checkedStudents.length === 0 ||
                                        checkedStudents[0]?.classroomId !== item?.id
                                      }
                                      onClick={() => handleOpenDeleteStudentModal()}>
                                      <TrashIcon /> Excluir
                                    </DeleteStudentsButton> */}
                                  </Box>
                                </BoxStyled>
                              ) : (
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <img
                                    src={noStudentsImage}
                                    width={350}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: '18px',
                                      fontWeight: 500,
                                      lineHeight: '21.94px',
                                      pb: '41px',
                                      pt: '84px',
                                      textAlign: 'center'
                                    }}
                                  >
                                    Ainda não existem alunos cadastrados nessa turma
                                  </Typography>
                                </Box>
                              )
                              }
                            </StudentsContainer>
                          </div>
                        }
                      />
                    </Container>
                  )
                })}
            </Box>
          )}
          <Pagination
            count={pagination.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{
              mt: '32px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          />
        </>
      )}
    </div>
  )
}

export default ManageStudentsContainer
