import React, { useState } from 'react'
import { Avatar, Box, Collapse, Grid, IconButton, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import { IClassroomChapters } from '../../../models/IBooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CalendarIcon from '../../../assets/components/Books/calendar-icon.svg'
import StudentsIcon from '../../../assets/components/Books/students-icon.svg'
import { IUser } from '../../../models/IClassroom'
import { stringAvatar } from '../../../utils/functions'

const ClassroomContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  justifyContent: 'space-between',

  '& .classroom_name': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#f0f3f4',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    padding: '5.5px 18px',
    maxWidth: '350px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  '& .date': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& p': {
      color: theme.palette.text.primary,
      fontWeight: 600,

      '&:first-of-type': {
        fontSize: '16px',
        letterSpacing: '0.16px'
      },

      '&:last-of-type': {
        fontSize: '14px',
        letterSpacing: '0.14px'
      }
    }
  },

  '& .number_of_students': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& p': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    }
  }
}))

const ModuleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#efe7ff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px',

  '& .module_name': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  '& .module_dates': {
    alignItems: 'center',
    display: 'flex',
    gap: '40px',

    '& .date': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        letterSpacing: '0.14px'
      }
    }
  }
}))

interface IClassroomViewCollapseProps {
  classroom: IClassroomChapters
  students: IUser[]
}

const ClassroomViewCollapse: React.FC<IClassroomViewCollapseProps> = ({ classroom, students }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  return (
    <Box
      sx={{
        background: '#f5f5f5',
        borderRadius: '8px',
        padding: '16px'
      }}
    >
      <ClassroomContainer>
        <Typography
          className='classroom_name'
          // component='span'
          onClick={() => setOpen(prevState => !prevState)}
          title={classroom?.school_classroom?.name}
        >
          {classroom?.school_classroom?.name}
        </Typography>

        <Box className='date start_date'>
          <img src={CalendarIcon} />
          <Typography>Início:</Typography>
          <Typography>
            {classroom?.start_date ? format(new Date(classroom.start_date), 'dd/MM/yyyy') : '00/00/00'}
          </Typography>
        </Box>

        <Box className='date end_date'>
          <img src={CalendarIcon} />
          <Typography>Fim:</Typography>
          <Typography>
            {classroom?.end_date ? format(new Date(classroom.end_date), 'dd/MM/yyyy') : '00/00/00'}
          </Typography>
        </Box>

        <Box className='number_of_students'>
          <img src={StudentsIcon} />
          <Typography>
            {students?.length}
          </Typography>

          <IconButton
            onClick={() => setOpen(prevState => !prevState)}
            sx={{ width: '18px', height: '18px' }}
          >
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Box>
      </ClassroomContainer>

      <Collapse in={open}>
        <Box>
          <Box>
            <Typography
              component='h4'
              sx={{
                color: theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '150%',
                margin: '24px 0 8px'
              }}
            >
              Módulos
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {classroom?.classroom_modules?.map((module) => {
                return (
                  <ModuleContainer key={module.id}>
                    <Typography
                      className='module_name'
                      title={module.content_module.name}
                    >
                      {module.content_module.name}
                    </Typography>

                    <Box className='module_dates'>
                      <Box className='date start_date'>
                        <img src={CalendarIcon} />
                        <Typography>Início:</Typography>
                        <Typography>
                          {module.start_date ? format(new Date(module.start_date), 'dd/MM/yyyy') : '00/00/00'}
                        </Typography>
                      </Box>

                      <Box className='date end_date'>
                        <img src={CalendarIcon} />
                        <Typography>Fim:</Typography>
                        <Typography>
                          {module.end_date ? format(new Date(module.end_date), 'dd/MM/yyyy') : '00/00/00'}
                        </Typography>
                      </Box>
                    </Box>
                  </ModuleContainer>
                )
              })}
            </Box>
          </Box>

          <Box>
            <Typography
              component='h4'
              sx={{
                color: theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '150%',
                margin: '24px 0 8px'
              }}
            >
              Alunos
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={1} mb={2}>
              {students && students.length > 0
                ? (
                  students?.map((student) => {
                    return (
                      <Grid item key={student.id} xs={4} md={3}>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '8px'
                          }}
                        >
                          <Avatar
                            alt={student.name}
                            variant='rounded'
                            {...stringAvatar(student?.name)}
                          />
                          <Typography
                            sx={{
                              color: theme.palette.text.primary,
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '120%',
                              maxWidth: '250px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                            title={student?.name}
                          >
                            {student?.name}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  })
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '16px',
                      width: '100%',
                    }}
                  >
                    <Typography
                      component='h3'
                      sx={{
                        color: '#9f9f9f',
                        fontWeight: 700
                      }}
                    >
                      Não há alunos nesta turma!
                    </Typography>
                  </Box>
                )}
            </Grid>
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ClassroomViewCollapse
