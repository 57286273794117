import React from 'react'
import { Box, Tooltip } from '@mui/material'
import SeeMoreIcon from '../../assets/components/Tooltip/see-more-icon.svg'

interface IReadMoreTooltipProps {
  principalName: string
  allNames: string
  className?: string
}

const ReadMoreTooltip: React.FC<IReadMoreTooltipProps> = ({ principalName, allNames, className }) => {
  return (
    <Tooltip title={allNames} arrow>
      <Box sx={{ alignItems: 'center', display: 'inline-flex', gap: '4px' }}>
        <Box component='span' className={className}>{principalName}</Box>
        <img src={SeeMoreIcon} />
      </Box>
    </Tooltip>
  )
}

export default ReadMoreTooltip
