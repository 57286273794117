import React from 'react'

// Components
import { Avatar, Box, Grid, LinearProgress, Typography } from '@mui/material'

// Styles
import { TableContainer } from './styles/StudentInfoItem.styles'

// Utils
import { formatTime, stringAvatar } from '../../../utils/functions'

interface IStudentInfoItemProps {
  student: {
    performance: number,
    progress: number,
    time: number
    user: { id: number, name: string }
  }
}

const StudentInfoItem: React.FC<IStudentInfoItemProps> = ({ student }) => {
  return (
    <>
      <TableContainer container columnSpacing={1}>
        <Grid item xs={6} className='grid_item'>
          <Box className='student_name' title={student.user.name}>
            <Avatar
              alt={student.user.name}
              variant='rounded'
              {...stringAvatar(student?.user?.name, 40, 40, 16)}
            />
            <Typography title={student.user.name}>{student.user.name}</Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className='grid_item'>
          <Box className='student_progress'>
            <LinearProgress
              className='linear_progress'
              variant='determinate'
              color={student?.progress >= 50 ? 'secondary' : 'error'}
              value={student?.progress ?? 0}
            />
            <Typography>{student?.progress?.toFixed(2) ?? 0}%</Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className='grid_item'>
          <Box className='student_performance'>
            <LinearProgress
              className='linear_progress'
              variant='determinate'
              color={student?.performance >= 50 ? 'secondary' : 'error'}
              value={student?.performance ?? 0}
            />
            <Typography>{student?.performance?.toFixed(2) ?? 0}%</Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className='grid_item'>
          <Box className='student_time'>
            <Typography>
              {formatTime(student?.time)}
            </Typography>
          </Box>
        </Grid>
      </TableContainer>
    </>
  )
}

export default StudentInfoItem
