import React from 'react'

// Components
import { Avatar, Button, Divider, Menu, Typography } from '@mui/material'
import { navbarIcons, ssoUrl } from '../../utils/constants'
import { Box } from '@mui/system'
import SelectUnits from './SelectUnits'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MailIcon from '../../assets/components/Header/mail-icon.svg'
import ScheduleIcon from '../../assets/components/Header/schedule-icon.svg'
import AlertIcon from '../../assets/components/Header/alert-icon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'

// Utils
import { checkCurrentRole } from '../../utils/functions'
import { currentHost } from '../../utils/http'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { signOutRequest } from '../../store/auth/actions'

// Styles
import { styled, useTheme } from '@mui/material/styles'

const HeaderStyle = styled('header')(({ theme }) => ({
  alignItems: 'center',
  borderBottom: '1px solid #CECECE',
  display: 'flex',
  height: 56,
  justifyContent: 'space-between',
  padding: '12px 32px',
  '& button': {
    border: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'capitalize',
    backgroundColor: 'transparent'
  },
  '& > div:last-of-type': {
    alignItems: 'center',
    display: 'flex',
  }
}))
const IconsContainer = styled('div')({
  display: 'flex',
  gap: 18
})
const MenuItem = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '16px',
  color: theme.palette.error.light,
  margin: '8px 22px',
  '&:hover': {
    fontWeight: 700
  }
}))
const Title = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  color: theme.palette.text.primary,
  gap: 8,
  '& p': {
    fontSize: 14,
    fontWeight: 700,
  }
}))

const Header: React.FC<any> = ({ title }: { title: string }) => {
  const dispatch = useAppDispatch()
  const { auth: { user, roles }, persistable: { selectedRole, selectedUnit } } = useAppSelector(state => state)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const userName = user?.name?.split(' ')
  const isFractalOperator = user?.id && selectedRole && checkCurrentRole(selectedRole, 'fractal_operator')
  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(signOutRequest())
  }

  const handleSsoUrlRedirect = () => {

    let url
    if (currentHost.includes('staging')) {
      url = ssoUrl.staging
    } else if (currentHost.includes('production')) {
      url = ssoUrl.production
    } else if (currentHost.includes('homolog')) {
      url = ssoUrl.staging
    } else if (currentHost.includes('local')) {
      url = ssoUrl.local
    } else {
      url = ssoUrl.staging
    }
    return url + '?app_id=18'
  }

  const ssoUrlToRedirect = handleSsoUrlRedirect()

  return (
    <HeaderStyle>
      <div>
        {title && <Title>
          <img src={navbarIcons[title]} alt='navbar icon' width={16} />
          <p>{title}</p>
        </Title>}
      </div>
      <div>
        {!isFractalOperator && user && <SelectUnits units={user.units} selectedUnit={selectedUnit} />}
        <Divider sx={{ ml: 4, mr: 4 }} orientation='vertical' variant='middle' flexItem />
        <IconsContainer>
          <button>
            <img src={AlertIcon} alt='mail icon' />
          </button>
          <button>
            <img src={MailIcon} alt='mail icon' />
          </button>
          <button>
            <img src={ScheduleIcon} alt='schedule icon' />
          </button>
        </IconsContainer>
        <Divider sx={{ ml: 4, mr: 4 }} orientation='vertical' variant='middle' flexItem />
        <div>
          <Button
            id='user-button'
            aria-controls={open ? 'user-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            <Avatar src={user?.photo_url} sx={{ width: 28, height: 28, marginRight: 1 }} />
            {`${userName[0]} ${userName[userName.length - 1]}`}
          </Button>
          <Menu
            id='user-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'user-button',
            }}
          >
            <Box sx={{ pt: '27px', paddingInline: '22px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', width: 'fit-content', gap: '5px' }}>
                <Typography sx={{ display: 'flex', fontWeight: 500, fontSize: '12px', lineHeight: '19.2px', gap: '4px', color: theme.palette.text.primary }}>
                  Perfil:
                </Typography> {roles?.map((role, index) => {
                  let roleName = ''
                  switch (role?.name) {
                    case 'operator':
                      roleName = user?.genre === 'female' ? 'Operadora' : 'Operador'
                      break
                    case 'director':
                      roleName = user?.genre === 'female' ? 'Diretora' : 'Diretor'
                      break
                    case 'coordinator':
                      roleName = user?.genre === 'female' ? 'Coordenadora' : 'Coordenador'
                      break
                    case 'teacher':
                      roleName = user?.genre === 'female' ? 'Professora' : 'Professor'
                      break
                    default:
                      roleName = role?.name
                      break
                  }
                  const isLast = index === roles.length - 1
                  const separator = isLast ? '' : ' -'
                  return (
                    <Typography key={index} sx={{ color: theme.palette.primary.main, fontWeight: 500, fontSize: '14px', lineHeight: '19.2px' }}>
                      {`${roleName}${separator}`}
                    </Typography>
                  )
                })}
              </Box>
              {user?.email && <Box sx={{ display: 'flex', width: 'fit-content', gap: '5px' }}>
                <Typography sx={{ fontWeight: 500, color: theme.palette.text.primary, fontSize: '12px', lineHeight: '14.4px' }}>{user?.email}</Typography>
              </Box>}
              <Box sx={{ display: 'flex', width: 'fit-content', gap: '5px' }}>
                <Typography sx={{ color: theme.palette.primary.light, fontWeight: 500, fontSize: '12px', lineHeight: '14.4px' }}>Fractal ID: {user?.fractal_id}</Typography>
              </Box>

              <Typography
                component='a'
                sx={{
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: 500,
                  textDecoration: 'none',
                  '&:hover': {
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                  },
                }}
                href={ssoUrlToRedirect}
                target='_blank'
              >
                Editar perfil
              </Typography>

              <Divider sx={{ borderWidth: '1px', background: 'rgba(217, 217, 217, 0.3)' }} />
            </Box>
            <MenuItem onClick={handleLogout}><LogoutIcon /> Sair</MenuItem>
          </Menu>
        </div>
      </div>
    </HeaderStyle>
  )

}
export default Header
