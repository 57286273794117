import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { textExtract } from '../../utils/functions'
import { ReactComponent as MostUsedIcon } from '../../assets/components/Books/most-used-icon.svg'
import { IBookRanking, ISubject, IUnit } from '../../models/IBooks'

const Labels = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .icon': {
    height: '16px',
    width: '16px',
    '& img': {
      height: '100%',
      width: '100%',
    }
  },

  '& span': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
  }
}))

const PublishedBadge = styled('span')(() => ({
    border: '1px solid #04AEC9',
    borderRadius: '20px',
    color: '#04AEC9',
    padding: '4px 6px'
  }))
  const NewBadge = styled('span')(() => ({
    backgroundColor: '#F69E9E',
    color: '#ffffff !important',
    borderRadius: '20px',
    padding: '4px 6px'
  }))

const InfoBook = styled(Box)(({theme}) => ({
  display: 'flex',
  gap: '4px',
  marginBottom: '8px',

  '&.info-right':{
    justifyContent: 'flex-end'
  },

  '& span': {
    fontSize: '14px',
    lineHeight: '120%',
    color: '#9f9f9f',
  },

  '& span:last-of-type': {
    color: theme.palette.text.primary,
  },

  '& span:first-of-type': {
    fontWeight: 500
  },

  '& span.highlight':{
    fontWeight: 700,
    color: theme.palette.primary.light
  },

  '& span.blue-highlight':{
    fontWeight: 700,
    color: theme.palette.secondary.main
  },
}))

const BookCover = styled(Box)(() => ({
  width: '100%',
  maxWidth: '114px',
  borderRadius: '8px',
  height: '180px',
  overflow: 'hidden'
}))

const daysInterval = (createdAt?: string | Date): number => {
  if (!createdAt) {
    return 0
  }

  const dataCriacao = typeof createdAt === 'string' ? new Date(createdAt) : createdAt
  const dataAtual = new Date()

  dataCriacao.setHours(0, 0, 0, 0)
  dataAtual.setHours(0, 0, 0, 0)

  const diferencaMs = dataAtual.getTime() - dataCriacao.getTime()
  const diferencaDias = Math.floor(diferencaMs / (1000 * 60 * 60 * 24))
  return diferencaDias
}


interface IBookInformationsProps {
  book: IBookRanking
  tag: boolean
}

const BooksUsed: React.FC<IBookInformationsProps> = ({
  book,
  tag
}) => {

  return (
    <>
      <Box sx={{ display: 'flex', gap: '8px', backgroundColor: '#f9f9f9', padding: '16px', borderRadius: '16px', flexDirection: 'column', marginBottom: '8px' }}>
        <Labels sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <PublishedBadge>
             {book.status === 'published'
              ? 'Publicado'
              : book.status === 'draft'
              ? 'Rascunho'
              : 'À publicar'}
            </PublishedBadge>
            {daysInterval(book.created_at) < 15 ?
              <NewBadge>
                Novo
              </NewBadge>
              : null}
              {tag && <MostUsedIcon />}

        </Labels>
        <Box sx={{}}>
          <Box sx={{width: '22%', float:'left' }}>
            <BookCover>
            {book.photo_url
            ? (
              <img
                src={book.photo_url}
                alt='Capa do livro'
                width='100%'
                height='100%'
              />
            )
            : (
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  fontSize: '20px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#f1f1f1',
                  color: '#c9c9c9'
                }}
              >
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )}
            </BookCover>
          </Box>

          <Box sx={{float:'left', width: '78%'}}>
            <Box sx={{paddingLeft: '8px'}}>
              <Box>
                <Typography
                  component='h3'
                  sx={{
                    color: '#666666',
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '120%',
                  }}
                >
                  {book.name}
                </Typography>
              </Box>

                        <Box>
                          <Box>
                            <Typography sx={{
                              color: '#9f9f9f',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '150%',
                              marginBottom: '16px'
                            }}>
                              {textExtract(book.description, 150)}
                            </Typography>
                          </Box>
                        </Box>

                      <Box sx={{width: '100%'}}>
                        <Box sx={{ }}>
                            <Box sx={{float: 'left'}}>
                              <InfoBook>
                                  <span>Cód:</span>
                                  <span className='highlight'>{book.id}</span>
                              </InfoBook>
                              <InfoBook>
                                  <span>Escola:</span>
                                  <span>
                                  {book?.units?.map((unit: IUnit) => (
                                    unit.name
                                  ))}
                                  </span>
                              </InfoBook>
                              <InfoBook>
                                  <span>NPS:</span>
                                  <span>-100</span>
                              </InfoBook>
                            </Box>
                            <Box sx={{float: 'right'}}>
                              <InfoBook className='info-right'>
                                  <span>Disciplina:</span>
                                  <span>
                                    {book?.subjects?.map((subject: ISubject) => (
                                    subject.name
                                  ))}
                                  </span>
                              </InfoBook>
                              <InfoBook className='info-right'>
                                  <span>Performance:</span>
                                  <span className='blue-highlight'>{book.performance?.toFixed(0)}%</span>
                              </InfoBook>
                              <InfoBook className='info-right'>
                                  <span>Acessos:</span>
                                  <span className='highlight'>{book.access}</span>
                              </InfoBook>
                            </Box>
                        </Box>
                      </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default BooksUsed
