// React
import React, { useCallback, useEffect, useReducer } from 'react'

// Components
import { Box, Grid, Skeleton, Typography } from '@mui/material'
import Button from '../components/button/Button'
import ProgressChartView from '../components/view/ProgressChartView'
import PerformanceChartView from '../components/view/PerformanceChartView'
import PerformanceStudentsView from '../components/view/PerformanceStudentsView'
import PerformanceClassroomView from '../components/view/PerformanceClassroomView'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  classroomsPerformanceRequest,
  clearClassroomsPerformance,
  clearStudentsPerformance,
  clearUnitsPerformance,
  fetchStudentsPerformanceRequest,
  fetchUnitsPerformanceRequest,
  fetchChartData
} from '../store/books/actions'

// Utils
import { scrollToTop } from '../utils/functions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { IPerformanceComparisonData } from '../models/IBooks'

// Styles
import { BoxCard, BtnContainer, ContentBox, GridContainer, TitleContainer } from './styles/BooksReportContainer.styles'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
import AccessImage from '../assets/components/Books/access-image.svg'
import AverageImage from '../assets/components/Books/average-image.svg'
import ClassroomImage from '../assets/components/Books/classroom-image.svg'
import SchoolImage from '../assets/components/Books/schools-image.svg'

interface IFiltersType {
  school_classroom_ids: string | null
  school_unit_ids: string | null
  education_grade_ids: string | null
}

interface StateType {
  progress: {
    isFetching: boolean
    isError: boolean
    items: IPerformanceComparisonData[]
    filters: IFiltersType
  },
  performance: {
    isFetching: boolean
    isError: boolean
    items: IPerformanceComparisonData[]
    filters: IFiltersType
  }
}

type IAction = { type: 'fetch_progress_request'; payload?: any } |
  { type: 'fetch_progress_success'; payload: any } |
  { type: 'fetch_progress_failure' } |
  { type: 'fetch_performance_request'; payload?: any } |
  { type: 'fetch_performance_success'; payload: any } |
  { type: 'fetch_performance_failure' } |
  { type: 'reset' }

const initialState: StateType = {
  progress: {
    isFetching: false,
    isError: false,
    items: [],
    filters: {
      school_classroom_ids: null,
      school_unit_ids: null,
      education_grade_ids: null
    }
  },
  performance: {
    isFetching: false,
    isError: false,
    items: [],
    filters: {
      school_classroom_ids: null,
      school_unit_ids: null,
      education_grade_ids: null
    }
  }
}

function reducer(state: StateType, action: IAction) {
  switch (action.type) {
    case 'fetch_progress_request':
      return {
        ...state,
        progress: {
          ...state.progress,
          isFetching: true,
          isError: false,
          filters: {
            ...state.progress.filters,
            ...action.payload
          }
        }
      }
    case 'fetch_progress_success':
      return {
        ...state,
        progress: {
          ...state.progress,
          isFetching: false,
          isError: false,
          items: action.payload
        }
      }
    case 'fetch_progress_failure':
      return {
        ...state,
        progress: {
          ...state.progress,
          isFetching: false,
          isError: true
        }
      }
    case 'fetch_performance_request':
      return {
        ...state,
        performance: {
          ...state.performance,
          isFetching: true,
          isError: false,
          filters: {
            ...state.performance.filters,
            ...action.payload
          }
        }
      }
    case 'fetch_performance_success':
      return {
        ...state,
        performance: {
          ...state.performance,
          isFetching: false,
          isError: false,
          items: action.payload
        }
      }
    case 'fetch_performance_failure':
      return {
        ...state,
        performance: {
          ...state.performance,
          isFetching: false,
          isError: true
        }
      }
    default:
      return {
        ...initialState
      }
  }
}

type IchartName = 'all' | 'progress' | 'performance'

const BooksReportContainer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const {
    classrooms_performance: { data, isFetching, isError },
    students_performance: studentsPerformance,
    units_performance: unitsPerformance
  } = useAppSelector(state => state.books)
  const [chartState, dispatchAction] = useReducer(reducer, initialState)

  const unitsWithClassrooms = unitsPerformance.data?.map(unit => ({
    id: unit.id,
    name: unit.name,
    classrooms: unit.school_classrooms.map(classroom => ({
      id: classroom.id,
      name: classroom.name
    }))
  }))

  const classrooms = unitsPerformance.data?.flatMap(unit => (
    unit.school_classrooms?.map(item => item.id)
  ))

  // Back to books list page
  const handleBackPage = () => {
    navigate('/books')
  }

  // Gets and manages data to progress and performance charts.
  const handleGetChartData = async (chartName: IchartName, params?: object) => {
    try {
      let filtersParams: any
      if (chartName === 'progress') {
        dispatchAction({ type: 'fetch_progress_request', payload: params })
        filtersParams = {
          ...chartState.progress.filters,
          ...params
        }
      } else if (chartName === 'performance') {
        dispatchAction({ type: 'fetch_performance_request', payload: params })
        filtersParams = {
          ...chartState.performance.filters,
          ...params
        }
      } else {
        dispatchAction({ type: 'fetch_progress_request' })
        dispatchAction({ type: 'fetch_performance_request' })
      }

      const response: any = await dispatch(fetchChartData.request({
        bookId: Number(id),
        filters: filtersParams
      }))

      if (chartName === 'progress') {
        dispatchAction({ type: 'fetch_progress_success', payload: response.data })
      } else if (chartName === 'performance') {
        dispatchAction({ type: 'fetch_performance_success', payload: response.data })
      } else {
        dispatchAction({ type: 'fetch_progress_success', payload: response.data })
        dispatchAction({ type: 'fetch_performance_success', payload: response.data })
      }
    } catch (error) {
      console.error(error)
      if (chartName === 'progress') {
        dispatchAction({ type: 'fetch_progress_failure' })
      } else if (chartName === 'performance') {
        dispatchAction({ type: 'fetch_performance_failure' })
      } else {
        dispatchAction({ type: 'fetch_progress_failure' })
        dispatchAction({ type: 'fetch_performance_failure' })
      }
    }
  }

  // Gets and manages data to units performance section.
  const handleGetUnitsData = useCallback((params?: object) => {
    dispatch(fetchUnitsPerformanceRequest({
      bookId: Number(id),
      filters: params
    }))
  }, [])

  // Gets and manages data to students performance section.
  const handleGetStudentsPerformanceData = useCallback((params?: object) => {
    dispatch(fetchStudentsPerformanceRequest({
      bookId: Number(id),
      filters: params
    }))
  }, [])

  useEffect(() => {
    scrollToTop()
    if (id) {
      dispatch(classroomsPerformanceRequest({ id: Number(id) }))
      handleGetChartData('all')
      handleGetUnitsData()
      handleGetStudentsPerformanceData()
    }

    return () => {
      dispatch(clearClassroomsPerformance())
      dispatchAction({ type: 'reset' })
      dispatch(clearUnitsPerformance())
      dispatch(clearStudentsPerformance())
    }
  }, [])

  return (
    <Box>
      <BtnContainer>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </BtnContainer>

      <TitleContainer>
        <img src={BookIcon} alt='ícone de livro' />
        <Typography className='title'>
          {state?.bookTitle ?? data?.name}
        </Typography>
      </TitleContainer>

      <ContentBox>
        {isFetching ? (
          <GridContainer
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            {Array(4).fill(0).map((_, index) => {
              return (
                <Grid item xs={6} md={3} key={index}>
                  <Skeleton
                    animation='wave'
                    variant='rounded'
                    sx={{
                      height: '200px',
                      maxWidth: '247px',
                      width: '100%'
                    }}
                  />
                </Grid>
              )
            })}
          </GridContainer>
        ) : (
          <GridContainer
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={AccessImage} alt='ícone de nº de acessos' />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.access ?? 0}
                    </Box>
                    <Box component='span'>
                      {data?.access && data?.access <= 1 && 'Acesso'}
                      {data?.access && data?.access > 1 && 'Acessos'}
                      {isError && 'Acessos'}
                      {!data?.access && !isError && 'Acessos'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={AverageImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.total_average ? data?.total_average?.toFixed(1) : '0'}%
                    </Box>
                    <Box component='span'>
                      Média de desempenho
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={ClassroomImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.school_classrooms ? data?.school_classrooms.length : '000'}
                    </Box>
                    <Box component='span'>
                      {data?.school_classrooms && data.school_classrooms.length > 1 ? 'Turmas' : 'Turma'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>

            <Grid item xs={6} md={3}>
              <BoxCard>
                <Box className='container'>
                  <img src={SchoolImage} />
                  <Box className='information'>
                    <Box component='span'>
                      {data?.units?.length ?? '000'}
                    </Box>
                    <Box component='span'>
                      {data?.units && data?.units?.length > 1 ? 'Escolas' : 'Escola'}
                    </Box>
                  </Box>
                </Box>
              </BoxCard>
            </Grid>
          </GridContainer>
        )}
      </ContentBox>

      <ProgressChartView
        isFetching={chartState.progress.isFetching}
        isError={chartState.progress.isError}
        data={chartState.progress.items}
        handleGetData={handleGetChartData}
      />

      <PerformanceChartView
        isFetching={chartState.performance.isFetching}
        isError={chartState.performance.isError}
        data={chartState.performance.items}
        handleGetData={handleGetChartData}
      />

      <PerformanceStudentsView
        bookId={Number(id)}
        isFetching={studentsPerformance.isFetching}
        isError={studentsPerformance.isError}
        data={studentsPerformance.data}
        handleGetData={handleGetStudentsPerformanceData}
        title={state?.bookTitle ?? data?.name}
        unitsWithClassrooms={unitsWithClassrooms}
        classrooms={classrooms}
      />

      <PerformanceClassroomView
        bookId={Number(id)}
        isFetching={unitsPerformance.isFetching}
        isError={unitsPerformance.isError}
        data={unitsPerformance.data}
        title={state?.bookTitle ?? data?.name}
        handleGetData={handleGetUnitsData}
      />
    </Box>
  )
}

export default BooksReportContainer
