import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Avatar, Checkbox } from '@mui/material'

const StudentsContainer = styled('div')(() => ({
  display: 'flex',
  fontSize: 14,
  fontWeight: 500,
  justifyContent: 'space-between',
  '& .studentCheck, .studentName': {
    alignItems: 'center',
    display: 'flex',
    '& .MuiAvatar-root': {
      marginRight: 8
    }
  },
  '& .studentName': {
    marginRight: 60
  },
  width: '100%'
}))

export interface Item {
  id: number | null
  name: string
  full_name: string
  shift: any
  grade: Grade
  students: Student[]
}

export interface Grade {
  id: number
  name: string
}

interface Student {
  id: number
  taxpayer_number: string
  name: string
  photo_url: any
  email?: string
  first_access: boolean
  phone?: string
  genre: string
  birthday: string
  classroom_name: string
  registration: string
  grade_name: string
  parents: any[]
  additional_data?: AdditionalData
}

interface AdditionalData {
  address: Address
}

interface Address {
  number: string
  street: string
  zipcode: string
  neighborhood: string
}

interface Props {
  dividedStudentsList: any
  toggleCheckboxClassroom: any
  verifyStudentChecked: any
  item: Item
}

const ActivityStudentList = ({ dividedStudentsList, toggleCheckboxClassroom, verifyStudentChecked, item }: Props) => {
  return (
    <StudentsContainer>
      {
        dividedStudentsList.map((listOfStudents: Student[], index: number) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              {
                listOfStudents.map((student: Student, key: number) => {
                  return (
                    <div className='studentCheck' key={key}>
                      <Checkbox
                        onChange={e =>
                          toggleCheckboxClassroom(e, item, student)
                        }
                        checked={verifyStudentChecked(student, item)}
                      />
                      <div className='studentName'>
                        <Avatar
                          alt={student.name}
                          src={student.photo_url}
                          variant='rounded'
                          sx={{ width: 34, height: 34 }}
                        />
                        <div>{student.name}</div>
                      </div>
                    </div>
                  )
                })
              }
            </Box>
          )
        })
      }
    </StudentsContainer>
  )
}

export default ActivityStudentList
