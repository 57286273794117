import http from '../../utils/http'

const contentsEndpoint = '/content_items'

export const fetchContents = async (params: any) => {
  const response = await http.get(contentsEndpoint, params)
  return response
}

export const fetchContentItemById = async ({ id }: any) => {
  const response = await http.get(`${contentsEndpoint}/${id}`)
  return response
}

export const createContent = async (params: any) => {
  const response = await http.post(contentsEndpoint, params)
  return response
}

export const editContent = async (payload: any) => {
  const response = await http.put(`${contentsEndpoint}/${payload.id}`, payload)
  return response
}

export const deleteContent = async ({ id }: any) => {
  const response = await http.delete(`${contentsEndpoint}/${id}`)
  return response
}