// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import ClassroomItem from './ClassroomItem'
import DatePickerField from '../../input/DatePickerField'

// Utils
import { format } from 'date-fns'
import { styled } from '@mui/material/styles'
import { Dates, IClassroomChapters } from '../../../models/IBooks'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const CollapseContent = styled(Box)(({ theme }) => ({
  marginTop: '26px',
  '& .classroom': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    paddingRight: '40px',

    '& .label': {
      color: theme.palette.primary.light,
      fontSize: '16px',
      fontWeight: 700
    },
  },

  '& .classroom_items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '16px'
  },

  [theme.breakpoints.down(1240)]: {
    '& .classroom': {
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '16px'
    }
  }
}))

const SchoolNameBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  width: 'fit-content',

  '& span': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '150%'
  },

  '& .school_name': {
    color: theme.palette.text.primary,
    marginRight: '4px'
  },

  '& .school_numberOfClassrooms': {
    color: theme.palette.primary.light,
    marginRight: '12px'
  },

  '& .school_btn': {
    height: '18px',
    width: '18px'
  }
}))

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    }
  }
}))

interface ISchoolChapterItemProps {
  school: {
    id: number
    name: string
    numberOfClassrooms: number
    schoolData: IClassroomChapters[]
  }
  chapterIndex: number
  chapterId: number
  dates: Dates[]
  addDates: (x: any) => void
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  disabledSchoolDateSelector: boolean
}

const SchoolChapterItem: React.FC<ISchoolChapterItemProps> = ({
  school,
  chapterIndex,
  chapterId,
  dates,
  addDates,
  changeChapterDate,
  changeModuleDate,
  disabledSchoolDateSelector
}) => {
  const [openSchool, setOpenSchool] = useState(false)
  const [date, setDate] = useState({ start_date: undefined, end_date: undefined })

  const classroomChapterIds = school?.schoolData?.map((item) => item.id)

  const handleChangeClassroomsDates = (
    chapterId: number,
    classroomChaptersIds: number[],
    key: string,
    value: Date,
  ) => {
    setDate({ ...date, [key]: value })
    const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
    if (chapterIndex === -1) {
      addDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: classroomChaptersIds.map((item) => ({
            id: item,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }))
        }
      ])
    } else {
      const update = [...dates]
      const classroomChapterIndex = dates?.[chapterIndex]?.classroom_chapters?.findIndex((item) => classroomChaptersIds?.includes(item.id))
      if (classroomChapterIndex === -1) {
        const classroomChapter = classroomChaptersIds.reduce((acc: any, item) => {
          acc.id = item
          acc[key] = key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
          acc.classroom_modules = []
          return acc
        }, {})

        update[chapterIndex] = {
          ...update[chapterIndex],
          classroom_chapters: [
            ...update[chapterIndex].classroom_chapters,
            classroomChapter
          ]
        }
      } else {
        classroomChaptersIds.forEach((item) => {
          const index = update[chapterIndex].classroom_chapters.findIndex(i => i.id === item)
          update[chapterIndex].classroom_chapters[index] = {
            ...update[chapterIndex].classroom_chapters[index],
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
          }
        })
      }
      addDates(update)
    }
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenSchool(true)
  }, [])

  return (
    <Box>
      <SchoolNameBox onClick={() => setOpenSchool(prevState => !prevState)}>
        <Box
          className='school_name'
          component='span'
        >
          {school?.name}
        </Box>

        <Box
          className='school_numberOfClassrooms'
          component='span'
        >
          ({school?.numberOfClassrooms})
        </Box>

        <IconButton className='school_btn'>
          <KeyboardArrowDownIcon
            fontSize='small'
            sx={{ transform: openSchool ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </IconButton>
      </SchoolNameBox>

      <Collapse in={openSchool}>
        <CollapseContent>
          <Box className='classroom'>
            <Typography className='label'>
              Quer agendar a publicação deste capítulo na escola toda?
            </Typography>

            <FormBox>
              <Box className='field'>
                <Typography className='field_label'>Início:</Typography>
                <DatePickerField
                  name='start_date'
                  locale='pt-BR'
                  timeIntervals={1}
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy'
                  placeholder='00/00/00'
                  width='170px'
                  changeDate={(value: any) =>
                    handleChangeClassroomsDates(
                      chapterId,
                      classroomChapterIds,
                      'start_date',
                      value
                    )}
                  date={date?.start_date}
                  startDate={date?.start_date}
                  endDate={date?.start_date}
                  maxDate={date?.end_date}
                  filterTime={date?.end_date && filterRangeStartDate}
                  selectsStart
                  disabled={disabledSchoolDateSelector}
                  readOnly
                />
              </Box>

              <Box className='field'>
                <Typography className='field_label'>
                  Fim:
                </Typography>
                <DatePickerField
                  name='end_date'
                  locale='pt-BR'
                  timeIntervals={1}
                  timeCaption='Hora'
                  dateFormat='dd/MM/yyyy'
                  placeholder='00/00/00'
                  width='170px'
                  changeDate={(value: any) =>
                    handleChangeClassroomsDates(
                      chapterId,
                      classroomChapterIds,
                      'end_date',
                      value
                    )}
                  date={date?.end_date}
                  startDate={date?.end_date}
                  endDate={date?.end_date}
                  minDate={date?.start_date}
                  filterTime={filterRangeEndDate}
                  disabled={disabledSchoolDateSelector}
                  readOnly
                />
              </Box>
            </FormBox>
          </Box>

          <Box className='classroom_items'>
            {school?.schoolData?.sort((a: any, b: any) => a.id - b.id)?.map((classroom) => {
              return (
                <ClassroomItem
                  key={classroom.school_classroom.id}
                  data={classroom}
                  chapterIndex={chapterIndex}
                  chapterId={chapterId}
                  dates={dates}
                  changeChapterDate={changeChapterDate}
                  changeModuleDate={changeModuleDate}
                />
              )
            })}
          </Box>
        </CollapseContent>
      </Collapse>
    </Box>
  )
}

export default SchoolChapterItem
