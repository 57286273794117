import http from '../../utils/http'
import { AxiosResponse } from 'axios'

// Endpoints
const signInEndpoint = '/auth'
const usersEndpoint = '/users'

type credentials = {
    token: string;
};

export const getRoles = (params: any): Promise<AxiosResponse> => {
  return http.get('/roles/application_user_roles', { params })
}

export const getUserData = () => {
  return http.get(usersEndpoint)
}

export const signIn = (credentials: credentials) => {
  return http
    .post(`${signInEndpoint}/sign_in`, {
      datagateway_token: credentials.token
    })
    .then((res: AxiosResponse) => res)
}

export const signOut = () => {
  return http.post(`${usersEndpoint}/logout`).then((res: AxiosResponse) => res)
}

export const updateUserTempPasswordService = (payload: any) => {
  return http.put(`${usersEndpoint}/force_temporary_password`, payload)
}

