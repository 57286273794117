import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import ChosenStudent from '../../../assets/components/Books/chooseStudent-image.svg'

const Wrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '& .image': {
    marginBottom: '8px',
    maxWidth: '195px',
    '& img': {
      width: '100%'
    }
  },

  '& .content': {
    textAlign: 'center',
    '& h3': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '120%',
      marginBottom: '16px'
    },

    '& p': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    }
  }
}))

const RenderWithoutStudent = () => {
  return (
    <Wrapper>
      <Box className='image'>
        <img src={ChosenStudent} />
      </Box>

      <Box className='content'>
        <Typography component='h3'>
          Selecione um aluno
        </Typography>

        <Typography>
          Clique em um dos alunos ao lado e veja o seu desempenho nos livros.
        </Typography>
      </Box>
    </Wrapper>
  )
}

export default RenderWithoutStudent
