import * as React from 'react'
import Box from '@mui/material/Box'
// import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'
import { Slider, useTheme } from '@mui/material'
import { ReactComponent as IconPencilSVG } from '../../assets/components/Questions/pencil-icon.svg'

type Props = FieldRenderProps<string, any>;

const BoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiFormHelperText-root': {
    margin: 0,
    color: '#D9D9D9',
  },
})

const InputContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  'input': {
    borderRadius: '8px',
    border: '1px solid #D9D9D9',
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHight: '16.8px',
    padding: '12px 15px',
    width: '72px',
  },
  'input:focus': {
    outline: `2px solid ${theme.palette.primary.main}`
  }

}))

const InputWithSlider: React.FC<Props> = ({
  input: { value, name, onChange },
  step,
  placeholder,
  max,
  meta: { touched, error },
  label,
  type,
  disabled,
  defaultFieldValue,
}: Props) => {
  const theme = useTheme()


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target
    value = value.replace(/[^0-9.]/g, '')
    value = value.replace(/,/g, '.')
    if ((value.match(/\./g) || []).length > 1) {
      return
    }
    if (Number(value) > max) {
      return
    }
    onChange(value)
  }

  return (
    <BoxStyled>
      {label && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <IconPencilSVG />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: theme.palette.primary.main,
                whiteSpace: 'nowrap',
              }}
              component='p'
            >
              {label}
            </Typography>
          </Box>
          <InputContainer>
            <input
              id={`input-${name}`}
              style={{
                background: disabled && '#fff',
                color: disabled && theme.palette.primary.main
              }}
              name={name}
              placeholder={placeholder}
              type={type}
              onChange={handleChange}
              value={defaultFieldValue || value}
              disabled={disabled}
            />
            <Typography
              align='left'
              component='p'
              variant='caption'
              sx={{
                color: '#F69E9E',
              }}
            >
              {(touched && error && error)}
            </Typography>
          </InputContainer>
        </Box>
      )}
      <Box sx={{ width: '200px' }}>
        <Slider
          aria-label='Nota'
          defaultValue={0}
          marks
          disabled={disabled}
          max={max}
          value={Number(value)}
          min={0}
          step={step}
          onChange={(e, newValue) => onChange(newValue as unknown as string)}
          valueLabelDisplay='auto'
        />
      </Box>
    </BoxStyled>
  )
}

export default InputWithSlider
