import React, { createContext, useCallback, useContext, useState } from 'react'

interface IDrawerContextData {
  isDrawerOpen: boolean
  toggleDrawerOpen: () => void
}

interface IAppThemeProviderProps {
  children: React.ReactNode
}

const SidebarContext = createContext({} as IDrawerContextData)

export const useSidebarContext = () => {
  return useContext(SidebarContext)
}

export const SidebarProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(prevState => !prevState)
  }, [])

  return (
    <SidebarContext.Provider value={{ isDrawerOpen, toggleDrawerOpen }}>
      {children}
    </SidebarContext.Provider>
  )
}
