import React from 'react'
import { styled } from '@mui/material/styles'
import { ReactComponent as FileIcon } from '../../assets/components/Button/file-icon.svg'

interface LabelStyledProps {
  isChecked?: boolean
}

const LabelStyled = styled('label')<LabelStyledProps>(({ isChecked }) => ({
  background: isChecked ? '#6666660a' : '#FFF',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  color: '#B7B2C8',
  fontSize: '16px',
  padding: '16px 32px',
  textTransform: 'capitalize',
  transition: `background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
               border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
               color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

  '&:hover': {
    background: '#6666660a'
  }
}))

interface RadioButtonProps {
  label: string
  value?: number | string
  checked?: boolean
  onChange: (value: number) => void
}

const RadioButton: React.FC<RadioButtonProps> = ({ label, checked, value, onChange }) => {

  const selectContent = (event: any) => {
    onChange(Number(event.target.value))
  }

  return (
    <LabelStyled htmlFor={`input-${value}`} isChecked={checked} onChange={selectContent}>
      <input
        type='radio'
        name={`input-${value}`}
        id={`input-${value}`}
        value={value}
        checked={checked}
        onChange={selectContent}
        style={{ display: 'none' }}
      />
      <FileIcon style={{ marginRight: '8px', width: '12px' }} />
      {label}
    </LabelStyled>
  )
}

export default RadioButton
