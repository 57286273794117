import http from '../../utils/http'

const examsEndpoint = '/concourse_exams'
const questionsEndpoint = '/questions'

export const fetchExams = (params: object) => {
  return http.get(examsEndpoint, params)
}

export const fetchExamQuestion = (params: object) => {
  return http.get(questionsEndpoint, { params })
}
