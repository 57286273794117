import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#ffffff',
  borderRadius: '16px',
  marginTop: '32px',
  padding: '48px 24px'
}))

export const FiltersContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '120px',
  marginBottom: '18px'
}))

export const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

export const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}))

export const ChartContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',

  '& .btn': {
    background: 'rgba(217, 217, 217, 0.4)',
    height: 'fit-content',
    transition: 'background 0.3s ease-in-out',
    width: 'fit-content',

    '& .icon': {
      color: 'rgb(102, 102, 102)',
      fontSize: '16px',
      textAlign: 'center'
    },

    '&.Mui-disabled': {
      '& .icon': {
        color: '#d9d9d9'
      }
    },

    '&:hover': {
      background: 'rgba(217, 217, 217, 0.8)'
    }
  },

  '& .btn_previous': {
    '& .icon': {
      marginLeft: '4px',
    }
  },

  '& .btn_next': {
    marginLeft: '24px'
  }
}))
