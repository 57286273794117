import React, { useEffect } from 'react'
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Field } from 'react-final-form'
import AsyncSelectInput from '../../../select/AsyncSelectInput'
import { QuestionFormValues } from '../QuestionsForm'
import { required } from '../../../../utils/formUtils'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { segmentsFetchRequest } from '../../../../store/education'

interface StepOneProps {
  activeStep: number
  isTeacher: any;
  userId?: any;
  rest: any
  values: QuestionFormValues
  change: any
  initialValues?: any
}

const PaperContainer = styled(Paper)(({
  '& .required': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'right',
  }
}))

const StepOne = ({ activeStep, rest, values, isTeacher, userId, change, initialValues }: StepOneProps) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('md'))
  const { segments: { items } } = useAppSelector(state => state.education)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if ( !initialValues && values && values?.question_type && values.question_type?.value !== 'discursive') {
      change('answer_type', undefined)
      change('question_options', [{}, {}, {}, {}, {}])
    }
  }, [values?.question_type])

  useEffect(() => {
    dispatch(segmentsFetchRequest())
  }, [])

  const segmentsFiltered = items?.filter((item) => item.id !== 1)
  const segmentOptions = segmentsFiltered?.map((item) => ({
    id: item.id,
    name: item.name
  }))

  if (activeStep !== 1) {
    return null
  }

  return (
    <PaperContainer sx={{ padding: match ? '40px 8px' : '40px 24px', borderRadius: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={values && values.question_type?.value === 'discursive' ? 4 : 6}>
          <Field
            component={AsyncSelectInput}
            placeholder='Selecione...'
            label='Segmento *'
            name='segment_ids'
            hasBorder
            options={segmentOptions ?? []}
            required
            validate={required}
          />
        </Grid>
        <Grid item xs={6} lg={values && values.question_type?.value === 'discursive' ? 4 : 6}>
          <Field
            component={AsyncSelectInput}
            placeholder='Tipo de questões'
            label='Tipo de questões *'
            name='question_type'
            hasBorder
            options={[
              // { id: 1, name: 'Discursiva', value: 'discursive' },
              { id: 2, name: 'Objetiva', value: 'only_choice' },
            ]}
            required
            validate={required}
          />
        </Grid>
        {values && values.question_type?.value === 'discursive' && (
          <Grid item xs={6} lg={4}>
            <Field
              name='answer_type'
              label='Tipo de Resposta *'
              placeholder='Tipo de Resposta'
              component={AsyncSelectInput}
              hasBorder
              disabled={Boolean(
                !rest || rest.question_type !== 'discursive'
              )}
              options={[
                { value: 'text_only', name: 'Texto' },
                { value: 'image_only', name: 'Imagem' },
                // { value: 'video_only', name: 'Video' } // será utilizado futuramente
              ]}
              required={Boolean(
                values && values.question_type?.value === 'discursive'
              )}
              validate={required}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            name='subject_ids'
            component={AsyncSelectInput}
            placeholder='Selecione...'
            label='Disciplinas *'
            searchParam='name'
            isMulti
            request={{
              path: '/subjects',
              params: {
                basic: true,
                teacher_user_id: isTeacher ? userId : null,
              },
            }}
            validate={required}
          />
        </Grid>
      </Grid>
      {<Typography className='required'>* Campo obrigatório</Typography>}
    </PaperContainer>
  )
}

export default StepOne
