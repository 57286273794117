import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& input': {
    borderRadius: '8px',
    border: '1px solid #cecece',
    padding: '10.53px 32px 10.53px 18px',
    fontSize: '14px',
    height: '40px',
    color: '#9F9F9F',
    '&::placeholder': {
      color: '#cecece',
      fontFamily: theme.typography.fontFamily
    },
    '&:focus-visible': {
      outlineColor: '#412A88'
    }
  },
}))

const PhoneInput: React.FC<Props> = ({
  input: { value, name, onChange, ...restInput },
  defaultFieldValue,
  meta: { touched, error },
  ...rest
}) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneInput = event.target.value.replace(/\D/g, '')
    let formattedPhone = ''

    if (phoneInput.length <= 10) {
      formattedPhone = phoneInput.replace(/(\d{2})(\d)/, '($1) $2')
      formattedPhone = formattedPhone.replace(/(\d{1})(\d{4})(\d{4})/, '$1 $2-$3')
    } else {
      formattedPhone = phoneInput.slice(0, 11)
      formattedPhone = formattedPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    }

    onChange(formattedPhone)
  }

  return (
    <Container>
      <input
        type='text'
        value={defaultFieldValue || value}
        placeholder='(00) 0 0000-0000'
        onChange={handleChange}
        {...restInput}
        {...rest}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: '#F69E9E'
        }}
      >
        {touched && error && error}
      </Typography>
    </Container>
  )
}

export default PhoneInput
