// React
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// Components
import {
  Box,
  Grid,
  Typography
} from '@mui/material'
import BookDestinationForm from './BookDestinationForm'
import BookScheduleForm from './BookScheduleForm'
import CheckboxInput from '../../input/CheckboxInput'
import DialogModal from '../../Modal/DialogModal'
import SuccessMessage from '../../message/SuccessMessage'
import Tab from '../../tabs/Tab'
import Tabs from '../../tabs/Tabs'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearBookPublished, clearCurrentBook } from '../../../store/books/actions'

// Utils
import { styled } from '@mui/material/styles'

// Icons
import StepsIcon from '../../../assets/components/Books/steps-icon.svg'
import RecipientImage from '../../../assets/components/Books/recipient-image.svg'
import SchedulingImage from '../../../assets/components/Books/scheduling-image.svg'

const TabsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  gap: '32px',
  marginBottom: '40px',

  '& .tabs_label': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px'
    }
  }
}))

const NumberIcon = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  color: '#FFF',
  height: 23,
  padding: 3,
  width: 23
}))

const BoxTitle = styled(Box)(({ theme }) => ({
  marginBottom: '28px',
  textAlign: 'center',

  '.title': {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBottom: '8px'
  },

  '.description': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%'
  }
}))

const StepBox = styled(Box)(({ theme }) => ({
  '.step': {
    color: theme.palette.primary.light,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBlock: '8px'
  },

  '.label': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '120%',
    margin: '8px 0 10px'
  },

  '.description': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%'
  }
}))

const ModalContent = styled(Box)(() => ({
  marginInline: 'auto',
  maxWidth: '564px',
  overflow: 'hidden !important',
  width: '100%',

  '& .checkbox_container': {
    marginTop: '32px',
    textAlign: 'center'
  }
}))

const BooksPublishForm = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [showGuide, setShowGuide] = useState(false)
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const {
    bookToPublish: { isSuccess, isProcessing },
    currentItem
  } = useAppSelector(state => state.books)
  const guideStorage = localStorage.getItem('schedule_guide')
  const guide = guideStorage && JSON.parse(guideStorage)

  const handleTabChange = (_: any, value: number) => {
    setActiveTab(value)
  }

  const handleNext = () => {
    setActiveTab((prevActiveTab) => prevActiveTab + 1)
  }

  const handleBack = () => {
    setActiveTab((prevActiveTab) => prevActiveTab - 1)
  }

  const handleShowguide = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const showGuide = { isTrue: true }
      localStorage.setItem('schedule_guide', JSON.stringify(showGuide))
    } else {
      const showGuide = { isTrue: false }
      localStorage.setItem('schedule_guide', JSON.stringify(showGuide))
    }
    setShowGuide(event.target.checked)
  }

  useEffect(() => {
    !guide?.isTrue && setTimeout(() => {
      setOpenModal(true)
    }, 500)

    return () => {
      dispatch(clearBookPublished())
      dispatch(clearCurrentBook())
    }
  }, [])

  return (
    <div>
      {isSuccess && !isProcessing && currentItem.isSuccess && !currentItem.isSaving && (
        <SuccessMessage
          type='publish_book'
          path='books'
        />
      )}

      {!isSuccess && !currentItem.isSuccess && (
        <Box>
          <TabsContainer>
            <Box className='tabs_label'>
              <img
                src={StepsIcon}
                alt='ícone das etapas'
                width='20px'
                height='17px'
              />
              <Typography
                component='h3'
                className='label'
              >
                Etapas:
              </Typography>
            </Box>

            <Tabs
              sx={{ width: 'calc(100% - 140px)' }}
              value={activeTab}
              variant='fullWidth'
              onChange={handleTabChange}
              aria-label='ant example'
            >
              <Tab
                icon={<NumberIcon>1</NumberIcon>}
                iconPosition='start'
                label='Destinatários'
                value={1}
              />
              <Tab
                icon={<NumberIcon>2</NumberIcon>}
                iconPosition='start'
                disabled={activeTab === 1}
                label='Agendamento'
                value={2}
              />
            </Tabs>
          </TabsContainer>

          <Box>
            <BookDestinationForm
              id={Number(id)}
              activeTab={activeTab}
              nextTab={handleNext}
            />

            <BookScheduleForm
              activeTab={activeTab}
              prevTab={handleBack}
            />
          </Box>
        </Box>
      )}

      <DialogModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        width='777px'
        height='100%'
        sx={{ padding: '0px' }}
      >
        <ModalContent>
          <BoxTitle>
            <Typography component='h2' className='title'>
              Finalize o processo
            </Typography>

            <Typography className='description'>
              Envie o seu livro para a(s) turma(s) escolhida(s)
            </Typography>
          </BoxTitle>

          <Grid container columnSpacing={7}>
            <Grid item xs={6}>
              <StepBox sx={{ textAlign: 'center' }}>
                <img src={RecipientImage} />
                <p className='step'>Etapa 1</p>
                <p className='label'>Destinatários</p>
                <p className='description'>
                  Selecione a(s) turma(s) alvo para personalizar a entrega do seu livro. Pronto, agora é só publicar.
                </p>
              </StepBox>
            </Grid>

            <Grid item xs={6}>
              <StepBox sx={{ textAlign: 'center' }}>
                <img src={SchedulingImage} />
                <p className='step'>Etapa 2</p>
                <p className='label'>Agendamento</p>
                <p className='description'>
                  Determine a disponibilidade do livro para os alunos. Defina datas de início e término.
                </p>
              </StepBox>
            </Grid>
          </Grid>

          <Box className='checkbox_container'>
            <CheckboxInput
              name='message'
              label='Não mostrar essa mensagem novamente'
              onChange={handleShowguide}
              checked={openModal && showGuide}
            />
          </Box>
        </ModalContent>
      </DialogModal>
    </div >
  )
}

export default BooksPublishForm
