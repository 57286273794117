import React from 'react'
import BooksPublishContainer from '../containers/BooksPublishContainer'

const BooksPublishPage = () => {
  return (
    <div>
      <BooksPublishContainer />
    </div>
  )
}

export default BooksPublishPage
