import React, { useState } from 'react'

// Components
import {
  Avatar,
  Box,
  Collapse,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import ChapterReportCollapse from './ChapterReportCollapse'

// Utils
import { format } from 'date-fns'
import { formatTime, stringAvatar } from '../../../utils/functions'
import http from '../../../utils/http'
import { IBookByUser, IBookData } from '../../../models/IBooks'

// Styles
import { CollpaseContainer, Container, Divider, Error, Value } from './styles/StudentReportCollapse.styles'

// Icons
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'

interface IStudentReportCollapseProps {
  studentData: IBookData
  bookId: number
}

const StudentReportCollapse: React.FC<IStudentReportCollapseProps> = ({ bookId, studentData }) => {
  const [open, setOpen] = useState(false)
  const [book, setBook] = useState({} as IBookByUser)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const startDate = studentData?.start_date ? format(new Date(studentData?.start_date), 'dd/MM/yyyy') : '--/--/----'
  const endDate = studentData?.end_date ? format(new Date(studentData?.end_date), 'dd/MM/yyyy') : '--/--/----'

  const getChapters = async (
    bookId: number,
    classroomId: number,
    userId: number
  ) => {
    setIsError(false)
    setIsLoading(true)
    try {
      const response = await http.get(`/books/student_performance?book_books_id=${bookId}&school_classroom_id=${classroomId}&user_users_id=${userId}`)
      setBook(response.data?.[0])
    } catch (error) {
      console.error(error)
      setIsError(true)
    }
    setIsLoading(false)
  }

  const handleOpenCollapse = (bookId: number, classroomId: number, userId: number) => {
    setOpen(prevState => !prevState)
    !open && getChapters(bookId, classroomId, userId)
  }

  return (
    <Container>
      <Grid
        container
        className='wrapper'
        onClick={() => handleOpenCollapse(
          bookId,
          studentData.school_classroom.id,
          studentData.user.id
        )}
      >
        <Grid item xs={5}>
          <Box className='student'>
            <Avatar
              alt={studentData?.user?.name}
              variant='rounded'
              {...stringAvatar(studentData?.user?.name, 48, 48, 14)}
            />

            <Box>
              <Typography className='student_name'>
                {studentData?.user?.name}
              </Typography>

              <Typography className='student_education'>
                {studentData?.school_classroom?.grade?.name} / {studentData?.school_classroom?.school_unit?.name}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2}>
          <Value>
            {startDate}
          </Value>
        </Grid>

        <Grid item xs={2}>
          <Value>
            {endDate}
          </Value>
        </Grid>

        <Grid item xs={2}>
          <Value>
            {formatTime(studentData?.time)}
          </Value>
        </Grid>

        <Grid item xs={1}>
          <Box sx={{ textAlign: 'right' }}>
            <KeyboardArrowDownIcon
              fontSize='small'
              className='arrow_icon'
              sx={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Collapse in={open}>
        <CollpaseContainer>
          <Divider />

          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <LinearProgress
                color='secondary'
                variant='indeterminate'
                sx={{ height: '3px', width: '100%' }}
              />
            </Box>
          )}

          {!isLoading && !isError && (
            <>
              <Typography
                component='h4'
                className='title'
              >
                Resultado
              </Typography>

              <Box className='student_info'>
                <Box className='progress'>
                  <img
                    src={ProgressIcon}
                    className='icon'
                  />

                  <Box
                    component='span'
                    className='label'
                  >
                    Progresso
                  </Box>

                  <LinearProgress
                    className='linear_progress'
                    color='secondary'
                    value={studentData.progress ?? 0}
                    variant='determinate'
                  />

                  <Box
                    component='span'
                    className='progress'
                  >
                    {studentData?.progress?.toFixed(1) ?? 0}%
                  </Box>
                </Box>

                <Box className='performance'>
                  <img src={ChartIcon} />
                  <Box
                    component='span'
                    className='label'
                  >
                    Performance
                  </Box>
                  <Box
                    component='span'
                    className='value'
                  >
                    {studentData?.performance?.toFixed(1) ?? 0}%
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  marginTop: '32px'
                }}
              >
                {book?.book_chapters?.sort((a, b) => a.chapter_order - b.chapter_order)?.map((chapter, index) => {
                  return (
                    <ChapterReportCollapse
                      key={chapter.id}
                      chapter={chapter}
                      numberOfChapter={index + 1}
                    />
                  )
                })}
              </Box>
            </>
          )}

          {isError && (
            <Error>
              <Typography>
                Ops! Parece que houve um problema ao acessar os dados do servidor. Tente novamente em alguns instantes.
              </Typography>
            </Error>
          )}
        </CollpaseContainer>
      </Collapse>
    </Container>
  )
}

export default StudentReportCollapse
