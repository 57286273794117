import React from 'react'
import BooksReportStudentsPerformanceContainer from '../containers/BooksReportStudentsPerformanceContainer'

const BooksReportStudentsPerformancePage = () => {
  return (
    <>
      <BooksReportStudentsPerformanceContainer />
    </>
  )
}

export default BooksReportStudentsPerformancePage
