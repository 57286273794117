import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import { ReactComponent as PeopleSVG } from '../assets/icons/people-gray.svg'
import { ReactComponent as PlusCircledIcon } from '../assets/components/ManagersPage/plus-circled.svg'
import { ReactComponent as ResetPasswordIcon } from '../assets/components/ManagersPage/lock-icon.svg'
import { ReactComponent as EditIcon } from '../assets/components/activity/edit-icon.svg'
import { ReactComponent as EyeIcon } from '../assets/components/ManagersPage/eye-icon.svg'
import { ReactComponent as ShiftIcon } from '../assets/components/ManagersPage/shift-icon.svg'
// import { ReactComponent as MoveIcon } from '../assets/components/ManagersPage/move-icon.svg'
import noStudentsImage from '../assets/components/ManagersPage/no-students.png'

// Components
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Pagination,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import Label from '../components/label/Label'
import {
  ActionButton,
  AddButton,
  CancelMoveStudentButton,
  // ConfirmMoveButton,
  // MoveStudentsButton as MoveTeachersButton
} from '../components/Management/buttons/StyledButtons'
import Table, { Columns } from '../components/table/Table'
import {
  BoxStyled,
  Container,
  StudentsContainer as TeachersContainer
} from '../components/Management/containers/StyledContainers'
import CollapseTable from '../components/table/CollapseTable'
import { CollapseTitleStyle } from '../components/Management/title/StyledTitle'
import { ShiftContainer } from '../components/Management/containers/ShiftContainer'
import LoadingMoveTeachers from '../components/Management/loading/LoadingMoveClassrooms'
import LoadingManageTeachers from '../components/Management/loading/LoadingManageTeachers'
import DialogModal from '../components/Modal/DialogModal'
import SeeTeacherInfoModal from '../components/Management/modal/SeeTeacherInfoModal'

// Utils
import {
  scrollToTop,
  stringAvatar,
  truncateText
} from '../utils/functions'

// Redux
// import { showAlertMessage } from '../store/alert'
import { fetchClassroomWithStudentsCoordinatorRequest } from '../store/classroom/actions'
import {
  getAllTeachersRequest,
  // updateTeacherRequest
} from '../store/teachers/actions'
import { useAppSelector, useAppDispatch } from '../store/hooks'
import { ITeacher } from '../store/teachers/types'

// Styles
import { useTheme } from '@mui/material/styles'

export interface SingleTeacher {
  user: User
  subjects: Subject[]
  classroomId: number
  grade: Grade
}

export interface Item {
  id: number
  name: string
  full_name: string
  shift: any
  grade: Grade
  teachers: ITeacher[]
}

export interface User {
  id: number
  name: string
  email: string
  phone: string
  genre: string
  birthday: string
  fractal_id: number
  taxpayer_number: string
  photo_url: any
  additional_data: any
}

export interface Subject {
  id: number
  name: string
}

export interface Grade {
  id: number
  name: string
}

export interface Classroom {
  id: number
  name: string
  full_name: string
  shift: any
  grade: Grade
  teachers: ITeacher[]
}


const ManageTeachersContainer = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { items, pagination, isFetching } = useAppSelector((state) => state.teachers)
  const classroom = useAppSelector((state) => state.classroom)
  const { pagination: classroomPagination, isFetching: classroomsLoading } = useAppSelector((state) => state.classroom)
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const [page, setPage] = useState(1)
  const [openSeeTeacherInfoModal, setOpenSeeTeacherInfoModal] = useState(false)
  const [teacherId, setTeacherId] = useState<any>('')
  const [classId, setClassId] = useState<any>('')
  const [ClassroomId, setClassroomId] = useState<any>()
  const [checkedTeachersId, setCheckedTeachersId] = useState<any>('')
  const [checkedTeachers, setCheckedTeachers] = useState<any>('')
  const [checkedClassroomId, setCheckedClassroomId] = useState<any>('')
  const [tempClassroom, setTempClassroom] = useState<any>()
  const singleClassroom = items?.find((classroom: any) => classroom?.id === classId)
  // const [teachersToMove, setTeachersToMove] = useState<any>()
  const [openMoveTeacherModal, setOpenMoveTeacherModal] = useState(false)
  const [radioClassroomId, setRadioClassroomId] = useState<string | number | null>(null)
  const singleTeacher =
    singleClassroom?.teachers &&
    singleClassroom.teachers?.find((teacher: any) => {
      return teacher?.user?.id === teacherId
    })

  useEffect(() => {
    dispatch(getAllTeachersRequest({ school_unit_id: selectedUnit?.id }))
    dispatch(fetchClassroomWithStudentsCoordinatorRequest({ school_unit_ids: selectedUnit?.id }))
  }, [selectedUnit])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(getAllTeachersRequest({ school_unit_id: selectedUnit?.id, page: value }))
    scrollToTop()
  }
  const handleChangeClassroomsPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(fetchClassroomWithStudentsCoordinatorRequest({ school_unit_ids: selectedUnit?.id, page: value }))
    scrollToTop()
  }

  const handleOpenClassModal = (classroom: Classroom) => {
    setClassId(classroom.id)
    setTempClassroom(classroom)
  }

  const handleOpenSeeTeacherInfoModal = (singleTeacherId: number) => {
    setOpenSeeTeacherInfoModal(true)
    setTeacherId(singleTeacherId)
  }

  const handleSelectAll = (classroom: Classroom, currentClassroomId: number | string | undefined | null, amountOfTeachers: number) => {

    setClassroomId(currentClassroomId)
    if ((checkedClassroomId === currentClassroomId) && (amountOfTeachers === checkedTeachersId?.length)) {
      setCheckedTeachersId([])
      setCheckedTeachers([])
      setCheckedClassroomId(null)
    } else {
      const teacherIds = classroom?.teachers?.map((teacher: any) => teacher?.user?.id)
      const teachersWithClassroomId = classroom.teachers.map((teacher: ITeacher) => {
        return {
          ...teacher,
          classroomId: currentClassroomId,
        }
      })
      setCheckedTeachersId(teacherIds)
      setCheckedTeachers(teachersWithClassroomId)
      setCheckedClassroomId(currentClassroomId)
    }
  }

  const handleCheckTeacher = (singleTeacher: SingleTeacher, currentClassroomId: number) => {
    setClassroomId(currentClassroomId)
    const teacherClassroomId = singleTeacher?.classroomId
    const oneOfTheTeachersCheckedClassroomId = checkedTeachers[0]?.classroomId

    if (checkedTeachers.length !== 0 && teacherClassroomId !== oneOfTheTeachersCheckedClassroomId) {
      setCheckedTeachersId([])
      setCheckedTeachers([])
      setCheckedClassroomId(null)
    } else {
      if (checkedTeachersId.includes(singleTeacher.user.id)) {
        const updatedCheckedTeacherIds = checkedTeachersId.filter((id: number) => id !== singleTeacher?.user?.id)
        setCheckedTeachersId(updatedCheckedTeacherIds)
        const updatedCheckedTeachers = checkedTeachers.filter((teacher: SingleTeacher) => {
          return teacher?.user?.id !== singleTeacher?.user?.id
        })
        setCheckedTeachers(updatedCheckedTeachers)
      } else {
        setCheckedTeachersId([...checkedTeachersId, singleTeacher?.user?.id])
        setCheckedTeachers([...checkedTeachers, singleTeacher])
      }
    }
  }

  const handleEditTeacher = (teacher: SingleTeacher) => {
    navigate('/manage-teacher/edit', { state: { classroom: tempClassroom, teacherId: teacher?.user?.id, teacher: teacher?.user } })
    setTeacherId(teacher?.user?.id)
  }

  // const handleOpenMoveTeacherModal = (studentsListToMove: SingleTeacher[]) => {
  //   setOpenMoveTeacherModal(true)
  //   setTeachersToMove(studentsListToMove)
  //   setRadioClassroomId(studentsListToMove[0]?.classroomId)
  // }

  const handleSelectClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioClassroomId(event.target.value)
  }

  const handleCloseMoveTeacherModal = () => {
    setOpenMoveTeacherModal(false)
  }

  // const handleConfirmMoveTeacher = () => {
  //   for (let i = 0; i < (teachersToMove && teachersToMove.length); i++) {
  //     const teacher = teachersToMove && teachersToMove[i]
  //     try {
  //       dispatch(updateTeacherRequest({ teacher, selectedUnit, radioClassroomId }))
  //       if (i !== teachersToMove.length - 1) {
  //         showAlertMessage({
  //           message: `Professor ${teacher.name} movido de sala com sucesso.`,
  //           severity: 'success',
  //           duration: 5000
  //         })
  //       }
  //     } catch (error) {
  //       showAlertMessage({
  //         message: `Erro ao mover o professor ${teacher.name} de sala.`,
  //         severity: 'error',
  //         duration: 5000
  //       })
  //     }
  //   }
  // }

  const teachersColumns: Columns[] = [
    {
      key: 'checkedTeacher',
      name: '',
      width: '1%',
      render: (row: SingleTeacher) => {
        function isTeacherChecked(currentUserId: any, currentClassroomId: any) {
          for (const teacher of checkedTeachers) {
            if ((teacher.user.id === currentUserId) && (teacher.classroomId === currentClassroomId)) {
              return true
            }
          }
          return false
        }
        return (
          <Checkbox
            onChange={() => handleCheckTeacher(row, row?.classroomId)}
            checked={isTeacherChecked(row.user.id, row?.classroomId)}
          />
        )
      },
    },
    {
      key: 'name',
      name: (
        <Typography component='p' color={theme.palette.text.primary} sx={{ textAlign: 'left' }} fontWeight={700}>
          Professores
        </Typography>
      ),
      width: '20%',
      render: (row: SingleTeacher) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar {...stringAvatar(row?.user?.name)} variant='rounded' />
            <Typography component='p' color={theme.palette.text.primary} fontWeight={500} fontSize={'14px'} textAlign={'left'}>
              {truncateText(row?.user?.name, 35)}
            </Typography>
          </Box>
        )
      },
    },
    {
      key: 'subject',
      name: (
        <Typography component='p' color={theme.palette.text.primary} fontWeight={700}>
          Disciplina
        </Typography>
      ),
      width: '20%',
      render: (row: SingleTeacher) => {
        return (
          <Box width={'100%'} sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: '14px',
            color: theme.palette.text.primary,
          }}>
            {row?.subjects?.length > 0 ? row?.subjects.map((subject: any) => subject?.name).join(', ') : '-'}
          </Box>
        )
      },
    },
    {
      key: 'email',
      name: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: theme.palette.text.primary,
            fontWeight: 700,
            justifyContent: 'center',
          }}
        >
          Email
        </Box>
      ),
      width: '20%',
      render: (row: SingleTeacher) => {
        return (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 500,
              fontSize: '14px',
              color: theme.palette.text.primary,
            }}
          >
            {row?.user?.email ?? '-'}
          </Box>
        )
      },
    },
    {
      key: 'actions',
      name: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: theme.palette.text.primary,
            fontWeight: 700,
            justifyContent: 'end',
          }}
        >
          Ações
        </Box>
      ),
      width: '20%',
      render: (row: SingleTeacher) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '16px'
            }}
          >
            <Tooltip title='Gerar senha temporária'>
              <ActionButton onClick={() => null}>
                <ResetPasswordIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title='Visualizar'>
              <ActionButton onClick={() => handleOpenSeeTeacherInfoModal(row?.user?.id)}>
                <EyeIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title='Editar'>
              <ActionButton onClick={() => handleEditTeacher(row)}>
                <EditIcon />
              </ActionButton>
            </Tooltip>
          </Box>
        )
      },
    },
  ]

  return (
    <div>
      {
        isFetching ? (
          <LoadingManageTeachers />
        ) : (<>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label sx={{ paddingBottom: '16px' }}>
              <PeopleSVG />
              Selecione uma turma
            </Label>
            <AddButton onClick={() => navigate('/manage-teacher/create')}>
              <PlusCircledIcon />
              Adicionar professor(a)
            </AddButton>
          </Box>
          <>
            <DialogModal
              width='90%'
              height='85%'
              open={openSeeTeacherInfoModal}
              handleClose={() => setOpenSeeTeacherInfoModal(false)}
            >
              <SeeTeacherInfoModal singleTeacher={singleTeacher} />
            </DialogModal>
            <DialogModal height='90%' open={openMoveTeacherModal} handleClose={handleCloseMoveTeacherModal} width='808px'>
              {
                classroomsLoading ? (
                  <LoadingMoveTeachers />
                ) : (
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingInline: '24px',
                    }}
                  >
                    <FormLabel sx={{ fontSize: '18px', paddingBottom: '16px', fontWeight: 700, color: theme.palette.text.primary }}>
                      Mova o professor para uma nova turma
                    </FormLabel>


                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', mb: '29px' }}>
                      <Typography sx={{ color: '#9F9F9F', fontWeight: 600 }}>Turma</Typography>
                      <Typography sx={{ color: '#9F9F9F', fontWeight: 600 }}>Qtde alunos</Typography>
                    </Box>
                    <BoxStyled sx={{ width: '100%' }}>
                      {classroom.classes.map((item) => {
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              mb: '24px',
                              width: '100%',
                            }}
                            key={item.id}
                          >
                            <RadioGroup
                              sx={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                              aria-label='class-id'
                              name='class'
                              value={radioClassroomId}
                              onChange={handleSelectClass}
                            >
                              <Box>
                                <FormControlLabel
                                  value={item.id}
                                  control={
                                    <Radio />
                                  }
                                  label=''
                                />
                                <CollapseTitleStyle>{item?.name}</CollapseTitleStyle>
                              </Box>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                  width: '105px',
                                  justifyContent: 'space-between',
                                  color: '#666',
                                  fontWeight: 500,
                                }}
                              >
                                <PeopleSVG />
                                {item?.students.length} {item?.students.length === 1 ? 'aluno' : 'alunos'}
                              </Typography>
                            </RadioGroup>
                          </Box>
                        )
                      })}
                    </BoxStyled>

                    <Box>
                      <Pagination
                        count={classroomPagination.totalOfPages}
                        size='small'
                        color='primary'
                        page={page}
                        onChange={handleChangeClassroomsPage}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '32px'
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '16px',
                        width: '100%',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <CancelMoveStudentButton
                        onClick={handleCloseMoveTeacherModal}
                      >
                        Cancelar
                      </CancelMoveStudentButton>
                      {/* <ConfirmMoveButton
                        onClick={() => handleConfirmMoveTeacher()}
                      >
                        Confirmar
                      </ConfirmMoveButton> */}
                    </Box>
                  </FormControl>
                )
              }
            </DialogModal>
          </>
          <>
            {
              items.map((item: Item) => {
                const dataWithClassroomId = item?.teachers?.map((teacher: ITeacher) => ({
                  ...teacher,
                  classroomId: item?.id,
                  grade: item?.grade
                }))
                const addClassroomIdToTeachers = (item: Item, classroomId: number) => {
                  if (!item || !item.teachers || !Array.isArray(item.teachers) || !classroomId) {
                    console.error('Objeto item inválido ou classroomId não fornecido.')
                    return null
                  }
                  return {
                    ...item
                  }
                }
                const newItem: any = addClassroomIdToTeachers(item, item?.id)

                const handleCheckedAllTeachers = (currentClassroomId: any, item: any, checkedTeachers: any) => {
                  if (!currentClassroomId || currentClassroomId !== item?.id) {
                    return false
                  }

                  if (!checkedTeachers || checkedTeachers.length === 0 || checkedTeachers.length !== item?.teachers?.length) {
                    return false
                  }

                  return true
                }

                return (
                  <Container onClick={() => handleOpenClassModal(item)} key={item?.id}>
                    <CollapseTable
                      maxWidth={'345px'}
                      minWidth={'345px'}
                      primaryText={
                        <CollapseTitleStyle>
                          {truncateText(item?.name, 41)}
                        </CollapseTitleStyle>
                      }
                      secondaryText={
                        <ShiftContainer>
                          <ShiftIcon />{(() => {
                            let translatedShift = ''
                            switch (item?.shift) {
                              case 'morning':
                                translatedShift = 'Manhã'
                                break
                              case 'afternoon':
                                translatedShift = 'Vespertino'
                                break
                              case 'night':
                                translatedShift = 'Noturno'
                                break
                              case 'fulltime':
                                translatedShift = 'Integral'
                                break
                              default:
                                translatedShift = 'Sem turno'
                                break
                            }
                            return translatedShift
                          })()}
                        </ShiftContainer>
                      }
                      tertiaryText={
                        <Box sx={{ fontSize: 14, fontWeight: 500 }}>
                          <Checkbox
                            onChange={() => handleSelectAll(newItem, item?.id, item?.teachers?.length)}
                            checked={handleCheckedAllTeachers(ClassroomId, item, checkedTeachers)}
                          />
                          {handleCheckedAllTeachers(ClassroomId, item, checkedTeachers) ? 'Desmarcar todos' : 'Selecionar todos'}
                        </Box>
                      }
                      quaternaryText={<Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          component='span'
                          sx={{
                            display: 'flex',
                            fontSize: 14,
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            ml: 4,
                          }}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: 500,
                            gap: '4px',
                            justifyContent: 'space-between',
                            minWidth: '150px'
                          }}
                        >
                          <PeopleSVG style={{ width: '15px' }} />
                          <span>{item?.teachers?.length}</span>
                          <span>{item?.teachers?.length === 1
                            ? `Professor(a)`
                            : `Professores`}</span>
                        </Box>
                      </Box>}
                      details={
                        <div>
                          <TeachersContainer>
                            {item?.teachers?.length > 0 ? (
                              <BoxStyled>
                                <Table columns={teachersColumns} data={dataWithClassroomId} />
                                {/* <Box
                                  sx={{
                                    display: 'flex',
                                    gap: '16px',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <MoveTeachersButton
                                    disabled={
                                      checkedTeachers.length === 0 ||
                                      checkedTeachers[0]?.classroomId !== item?.id
                                    }
                                    onClick={() => handleOpenMoveTeacherModal(checkedTeachers)}
                                  >
                                    <MoveIcon /> Mover
                                  </MoveTeachersButton>
                                  <DeleteStudentsButton
                                    disabled={
                                      checkedStudents.length === 0 ||
                                      checkedStudents[0]?.classroomId !== item?.id
                                    }
                                    onClick={() => handleOpenDeleteStudentModal()}>
                                    <TrashIcon /> Excluir
                                  </DeleteStudentsButton>
                                </Box> */}
                              </BoxStyled>
                            ) : (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                              >
                                <img
                                  src={noStudentsImage}
                                  width={350}
                                />
                                <Typography
                                  sx={{
                                    fontSize: '18px',
                                    fontWeight: 500,
                                    lineHeight: '21.94px',
                                    pb: '41px',
                                    pt: '84px',
                                    textAlign: 'center'
                                  }}
                                >
                                  Ainda não existem professores cadastrados nessa turma
                                </Typography>
                              </Box>
                            )
                            }
                          </TeachersContainer>
                        </div>
                      }
                    />
                  </Container>
                )
              })
            }
          </>
        </>)
      }
      <Pagination
        count={pagination.totalOfPages}
        size='small'
        color='primary'
        page={page}
        onChange={handleChangePage}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '32px'
        }}
      />
    </div>
  )
}

export default ManageTeachersContainer
