import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { StudentData } from './types'


export interface GetStudentRequestPayload {
  payload: number
}
export interface GetStudentSuccessPayload {
  data: StudentData
}
export interface CreateUserStudentRequestPayload {
  data: object
}

export interface DeleteUserRequestPayload {
  id: any
}


export const getStudentByIdRequest = createAction('users:GET_STUDENT_BY_ID_REQUEST', withPayloadType<GetStudentRequestPayload>())
export const getStudentByIdSuccess = createAction('users:GET_STUDENT_BY_ID_SUCCESS', withPayloadType<GetStudentSuccessPayload>())
export const getStudentByIdRejected = createAction('users:GET_STUDENT_BY_ID_REJECTED')

export const getAllStudentsRequest = createAction('users:GET_ALL_STUDENTS_REQUEST', withPayloadType<any>())
export const getAllStudentsSuccess = createAction('users:GET_ALL_STUDENTS_SUCCESS', withPayloadType<any>())
export const getAllStudentsRejected = createAction('users:GET_ALL_STUDENTS_REJECTED')

export const deleteStudentRequest = createAction('users:DELETE_STUDENT_REQUEST', withPayloadType<DeleteUserRequestPayload>())
export const deleteStudentSuccess = createAction('users:DELETE_STUDENT_SUCCESS', withPayloadType<any>())
export const deleteStudentRejected = createAction('users:DELETE_STUDENT_REJECTED', withPayloadType<any>())

export const updateStudentDataRequest = createAction('users:UPDATE_STUDENT_REQUEST', withPayloadType<any>())
export const updateStudentDataSuccess = createAction('users:UPDATE_STUDENT_SUCCESS')
export const updateStudentDataFailure = createAction('users:UPDATE_STUDENT_FAILURE')

export const createStudentRequest = createAction('users:CREATE_STUDENT_REQUEST', withPayloadType<CreateUserStudentRequestPayload>())
export const createStudentSuccess = createAction('users:CREATE_STUDENT_SUCCESS')
export const createStudentRejected = createAction('users:CREATE_STUDENT_REJECTED')

export const resetStudent = createAction('users:STUDENT_RESET_DATA')
