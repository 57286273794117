

import React from 'react'
import ActivityDetailsDistractorsTableContainer from '../containers/ActivityDetailsDistractorsTableContainer'

const ActivityDetailsDistractorsTablePage = () => {
  return (
    <ActivityDetailsDistractorsTableContainer />
  )
}

export default ActivityDetailsDistractorsTablePage
