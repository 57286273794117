import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { cloneBookRequest } from '../store/books/actions'
import { checkCurrentRole } from '../utils/functions'
import BooksForm from '../components/Books/form/BooksForm'
import Loading from '../components/Loading/Loading'

const BooksFormCloneContainer = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.auth)
  const { selectedRole } = useAppSelector(state => state.persistable)
  const { bookCloned } = useAppSelector(state => state.books)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')

  useEffect(() => {
    state && dispatch(cloneBookRequest({ id: state }))
  }, [state])

  useEffect(() => {
    bookCloned.isError && navigate('/books')
  }, [bookCloned.isError])

  return (
    <>
      {bookCloned.isFetching && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            height: 'calc(100% - 45px)',
            justifyContent: 'center',
          }}
        >
          <Box
            component='span'
            sx={{
              fontSize: '22px',
              fontWeight: 600
            }}
          >
            Clonando livro
          </Box>
          <Loading />
        </Box>
      )}

      {!bookCloned.isFetching && bookCloned?.id && (
        <Box>
          <BooksForm
            isTeacher={isTeacher}
            userId={user.id}
            id={String(bookCloned?.id)}
          />
        </Box>
      )}
    </>
  )
}

export default BooksFormCloneContainer
