import React, { useState, useEffect, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { Box, CircularProgress, Collapse, Grid, IconButton, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import Input from '../../input/Input'
import TextAreaInput from '../../input/TextAreaInput'
import Button from '../../button/Button'
import DialogModal from '../../Modal/DialogModal'
import { SelectInputField } from '../../select/SelectInput' // eslint-disable-line
import QuestionCard from '../../Questions/QuestionCard'
import ContentCard from '../../Contents/ContentCard'
import ModalDelete from '../../Modal/ModalDelete'
import ModulesList from './../list/ModulesList'
import { Chapter, Module } from '../../../models/IBooks'
import { clearModules, deleteChapterRequest, setModules, updateChapterRequest } from '../../../store/books/actions'
import { clearCurrentItem, fetchModuleByIdRequest } from '../../../store/modules/actions'
import CancelIcon from '@mui/icons-material/Cancel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChapterIcon from '../../../assets/components/Books/chapter-icon.svg'
import { ReactComponent as OrderIcon } from '../../../assets/components/Books/order-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/components/Books/edit-icon.svg'
import BarcodeIcon from '../../../assets/components/Books/barcode-icon.svg'
import { ReactComponent as ViewIcon } from '../../../assets/components/Books/view-icon.svg'
import AddWhiteIcon from '../../../assets/components/Books/add-white-icon.svg'

const ChapterCardTitle = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& span:first-of-type': {
    color: '#412a88',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '150%',
  },

  '& span:last-of-type': {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '150%',
  }
}))

const ModuleButton = styled(Button)(({ theme }) => ({
  height: 'fit-content',
  padding: 0,
  transition: 'transform 0.3s ease-in-out',

  '& span': {
    fontSize: '16px',
    fontWeight: 700
  },

  '& .title': {
    color: '#666666',
    marginRight: '4px',
  },

  '& .number_of_modules': {
    color: theme.palette.primary.light,
    marginRight: '8px'
  },
}))

const AddModuleButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.light,
  borderRadius: '8px',
  color: '#ffffff',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 500,
  gap: '8px',
  justifyContent: 'center',
  lineHeight: '120%',
  padding: '13.5px 39px',
  textTransform: 'none',
  transition: 'opacity 0.3s ease-in-out',

  '&:hover': {
    background: theme.palette.primary.light,
    opacity: 0.8,
  }
}))

interface ChapterElementProps {
  chapterData?: Chapter
  index: number
  bookId?: number
  isTeacher?: boolean
  userId?: number
  isEdit?: boolean
  setIsEdit?: any
  setEnableDragnDrop?: any
}

const ChapterElement: React.FC<ChapterElementProps> = ({
  chapterData,
  index,
  bookId,
  isTeacher,
  userId,
  isEdit,
  setIsEdit,
  setEnableDragnDrop
}) => {
  const [seeModules, setSeeModules] = useState(false)
  const [seeModulesToEdit, setSeeModulesToEdit] = useState(true)
  const [seeModulesList, setSeeModulesList] = useState(false)
  const [modulesItems, setModulesItems] = useState<any[]>([])
  const [moduleId, setModuleId] = useState<number | null>(null)
  const [chapterId, setChapterId] = useState<any>(null)
  const [openDeleteModuleModal, setOpenDeleteModuleModal] = useState(false)
  const [openDeleteChapterModal, setOpenDeleteChapterModal] = useState(false)
  const boxModuleElement = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { chapters: { isSaving }, modulesSelected } = useAppSelector(state => state.books)
  const { currentItem } = useAppSelector(state => state.modules)
  const filter = 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7473%) hue-rotate(295deg) brightness(123%) contrast(70%)'

  useEffect(() => {
    if (chapterData && chapterData.modules) {
      setModulesItems(chapterData.modules)
    }

    return () => {
      setModulesItems([])
    }
  }, [chapterData?.modules])

  // function to edit chapter
  const updateChapterSubmit = (values: any) => {
    dispatch(updateChapterRequest({
      id: values?.id,
      name: values?.name,
      description: values?.description,
      order: index + 1,
      bookId,
      closeEditForm: () => setIsEdit(false)
    }))
    setEnableDragnDrop(false)
  }

  // function that contains form validations
  const validate = (values: any) => {
    const errors: any = {}
    if (!values?.name) {
      errors.name = 'Campo obrigatório!'
    }

    if (!values?.description) {
      errors.description = 'Campo obrigatório!'
    }

    return errors
  }

  // function that opens the chapter edit form
  const handleEditChapter = () => {
    setEnableDragnDrop(true)
    setIsEdit(true)
    chapterData && chapterData.modules && dispatch(setModules(chapterData.modules))
  }

  // function that opens the chapter delete modal
  const handleOpenDeleteChapterModal = (chapterId?: number) => {
    setChapterId(chapterId)
    setOpenDeleteChapterModal(true)
  }

  // function that close the chapter delete modal
  const handleCloseDeleteChapterModal = () => {
    setOpenDeleteChapterModal(false)
  }

  // function to delete a chapter
  const removeChapter = (chapterId: any) => {
    dispatch(deleteChapterRequest(chapterId))
  }

  // function to close the chapter edit form
  const handleCancelEdit = () => {
    setIsEdit(false)
    setEnableDragnDrop(false)
    setSeeModulesToEdit(true)
    dispatch(clearModules())
  }

  // function to reorder chapter modules
  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  // function used to drop modules in "drag and drop"
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const items = reorder(modulesItems, result.source.index, result.destination.index)
    setModulesItems(items)
    dispatch(setModules(items))
  }

  // function to remove modules
  const removeModule = (moduleId: number | null) => {
    if (moduleId) {
      const modulesFiltered = modulesItems?.filter(module => module.id !== moduleId)
      setModulesItems(modulesFiltered)
      dispatch(setModules(modulesFiltered))
      setOpenDeleteModuleModal(false)
    }
  }

  // function that opens the module delete modal
  const handleOpenDeleteModuleModal = (moduleId: number) => {
    setModuleId(moduleId)
    setOpenDeleteModuleModal(true)
  }

  // function that close the module delete modal
  const handleCloseDeleteModuleModal = () => {
    setOpenDeleteModuleModal(false)
  }

  // function that closes the modules list
  const handleCancelAddModule = () => {
    setSeeModulesList(false)
    boxModuleElement.current && boxModuleElement.current.scrollIntoView({ behavior: 'smooth' })
  }

  // function to add modules in a chapter
  const handleAddModule = () => {
    if (modulesSelected && modulesSelected.length > 0) {
      setModulesItems(modulesSelected)
    }
    setSeeModulesList(false)
    boxModuleElement.current && boxModuleElement.current.scrollIntoView({ behavior: 'smooth' })
  }

  // function to view module content
  const handleViewContentInModule = (moduleId: number) => {
    dispatch(fetchModuleByIdRequest({ id: moduleId }))
    setModuleId(moduleId)
  }

  return (
    <>
      <Box>
        <Box
          sx={{ border: '1px dashed #b7b2c8', borderRadius: '16px', padding: '24px 16px' }}
          ref={boxModuleElement}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '50px' }}>
              <ChapterCardTitle
                sx={{
                  filter: isEdit ? filter : 'none'
                }}
              >
                <img src={ChapterIcon} alt='ícone do capítulo' />
                <span>Capítulo {index + 1} - </span>
                <span>{chapterData?.name}</span>
              </ChapterCardTitle>

              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: '8px',
                  filter: isEdit ? filter : 'none'
                }}>
                <OrderIcon width='16px' height='12.5px' />
                <span style={{ color: '#9f9f9f', fontSize: '14px', fontWeight: 700 }}>{index + 1}</span>
              </Box>
            </Box>

            <Box sx={{ alignItems: 'center', display: 'flex', gap: '32px' }}>
              <IconButton
                onClick={handleEditChapter}
                sx={{ width: '18px', height: '18px', padding: 0 }}
              >
                <EditIcon width='18px' height='18px' />
              </IconButton>

              <IconButton
                color='error'
                sx={{ width: '18px', height: '18px' }}
                onClick={() => handleOpenDeleteChapterModal(chapterData?.id)}
              >
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              filter: isEdit ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7473%) hue-rotate(295deg) brightness(123%) contrast(70%)' : 'none'
            }}
          >
            <Typography sx={{ color: '#9f9f9f', fontSize: '14px', fontWeight: 500, lineHeight: '150%', margin: '16px 0 24px 0' }}>
              {chapterData?.description}
            </Typography>
          </Box>

          {isEdit && (
            <Box>
              <Form
                initialValues={chapterData}
                validate={validate}
                onSubmit={updateChapterSubmit}
                render={({ handleSubmit, values, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ background: '#f9f9f9', borderRadius: '8px', padding: '30px 20px' }}>
                        <Box
                          sx={{
                            marginBottom: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography component='h4' sx={{ color: '#9f9f9f', fontSize: '16px', fontWeight: 500 }} >
                            Sobre o capítulo:
                          </Typography>
                        </Box>

                        <Grid container rowSpacing={2}>
                          <Grid item xs={12}>
                            <Field
                              name='name'
                              component={Input}
                              label='Título'
                              placeholder='Digite o nome do capítulo'
                              sx={{ background: '#ffffff' }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name='description'
                              component={TextAreaInput}
                              label='Descrição'
                              placeholder='Descreva as principais informações...'
                              style={{ width: '100%', height: '104px', resize: 'none' }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box sx={{ marginTop: '22px' }}>
                        <ModuleButton variant='text' onClick={() => setSeeModulesToEdit(prevState => !prevState)}>
                          <span className='title'>Módulos</span>
                          <span className='number_of_modules'>({modulesItems.length})</span>
                          <KeyboardArrowDownIcon
                            fontSize='small'
                            className='arrow_icon'
                            sx={{
                              transform: seeModulesToEdit ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                          />
                        </ModuleButton>

                        <Collapse in={seeModulesToEdit} timeout={300}>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='modules' type='list' direction='vertical'>
                              {(provided) => (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '12px' }}
                                >
                                  {modulesItems.map((module: Module, index: number) => {
                                    return (
                                      <Draggable key={module.id} draggableId={String(module.id)} index={index}>
                                        {(provided) => (
                                          <Box
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            sx={{ alignItems: 'center', display: 'flex', }}
                                          >
                                            <Box
                                              component='span'
                                              sx={{
                                                color: '#9f9f9f',
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                lineHeight: '150%',
                                                textAlign: 'center',
                                                width: '35px'
                                              }}
                                            >
                                              {index < 10 ? `0${index + 1}` : index + 1}
                                            </Box>

                                            <Grid
                                              container
                                              sx={{
                                                padding: '16px',
                                                background: '#f0f3f4',
                                                borderRadius: '8px',
                                                width: '100%',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Grid item xs={4}>
                                                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                  <img src={BarcodeIcon} alt='' style={{ width: '20px', height: '20px' }} />
                                                  <Box
                                                    component='span'
                                                    sx={{
                                                      color: theme.palette.primary.light,
                                                      fontWeight: 700,
                                                      lineHeight: '120%',
                                                      margin: '0 8px 0 2px'
                                                    }}
                                                  >
                                                    {module?.id}
                                                  </Box>
                                                  <Box
                                                    component='span'
                                                    sx={{
                                                      color: theme.palette.text.primary,
                                                      fontSize: '14px',
                                                      fontWeight: 600,
                                                      lineHeight: '150%'
                                                    }}
                                                  >
                                                    {module?.name}
                                                  </Box>
                                                </Box>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                  <Box
                                                    component='span'
                                                    sx={{
                                                      color: theme.palette.text.primary,
                                                      fontSize: '14px',
                                                      fontWeight: 600,
                                                      lineHeight: '150%',
                                                      marginRight: '3px'
                                                    }}
                                                  >
                                                    Questões:
                                                  </Box>
                                                  <Box
                                                    component='span'
                                                    sx={{
                                                      color: theme.palette.primary.light,
                                                      fontSize: '14px',
                                                      fontWeight: 700,
                                                      lineHeight: '150%'
                                                    }}
                                                  >
                                                    {(module?.module_items_count && module?.module_items_count < 9) ? `0${module?.module_items_count}` : module?.module_items_count}
                                                  </Box >
                                                </Box>
                                              </Grid>

                                              <Grid item xs={5} sx={{ textAlign: 'center' }}>
                                                <Box>
                                                  <Button
                                                    variant='outlined'
                                                    startIcon={<ViewIcon width='16px' />}
                                                    sx={{
                                                      background: '#ffffff',
                                                      borderColor: '#b7b2c8',
                                                      color: '#b7b2c8',
                                                      fontSize: '16px',
                                                      fontWeight: 500,
                                                      height: 'fit-content',
                                                      lineHeight: '120%',
                                                      padding: '8px 48px'
                                                    }}
                                                    onClick={() => handleViewContentInModule(module.id)}
                                                  >
                                                    Visualizar conteúdos
                                                  </Button>
                                                </Box>
                                              </Grid>

                                              <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                                <Box>
                                                  <IconButton
                                                    color='error'
                                                    sx={{ width: '18px', height: '18px' }}
                                                    onClick={() => handleOpenDeleteModuleModal(module?.id)}
                                                  >
                                                    <CancelIcon />
                                                  </IconButton>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Box>
                                        )}
                                      </Draggable>
                                    )
                                  })}
                                  {provided.placeholder}
                                </Box >
                              )}
                            </Droppable>
                          </DragDropContext>

                          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
                            <AddModuleButton
                              type='button'
                              onClick={() => setSeeModulesList(prevState => !prevState)}
                              disabled={seeModulesList}
                            >
                              <img src={AddWhiteIcon} alt='ícone de adicionar' width='21px' height='21px' />
                              Adicionar novo(s) módulo(s)
                            </AddModuleButton>
                          </Box>

                          {seeModulesList && (
                            <Box>
                              <ModulesList
                                values={values}
                                isTeacher={isTeacher}
                                userId={userId}
                                change={form.change}
                              />

                              <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
                                <Button
                                  type='button'
                                  variant='outlined'
                                  onClick={handleCancelAddModule}
                                >
                                  Cancelar
                                </Button>

                                <Button
                                  type='button'
                                  variant='contained'
                                  sx={{
                                    background: theme.palette.primary.light,
                                    '&:hover': {
                                      background: '#b18af9'
                                    }
                                  }}
                                  onClick={handleAddModule}
                                >
                                  Adicionar
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Collapse >
                      </Box >

                      {!seeModulesList && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
                          <Button
                            type='button'
                            variant='outlined'
                            onClick={handleCancelEdit}
                          >
                            Cancelar
                          </Button>

                          <Button
                            type='submit'
                            variant='contained'
                            sx={{ background: '#b7b2c8' }}
                            startIcon={
                              isSaving
                                ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
                                : undefined
                            }
                          >
                            Salvar
                          </Button>
                        </Box>
                      )}
                    </form>
                  )
                }}
              />
            </Box>
          )}

          {!isEdit && (
            <Box sx={{ marginTop: '22px' }}>
              <ModuleButton variant='text' onClick={() => setSeeModules(prevState => !prevState)}>
                <span className='title'>Módulos</span>
                <span className='number_of_modules'>({chapterData?.modules && chapterData?.modules?.length})</span>
                <KeyboardArrowDownIcon
                  fontSize='small'
                  className='arrow_icon'
                  sx={{
                    transform: seeModules ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </ModuleButton>

              <Collapse in={seeModules} timeout={300}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '12px' }}>
                  {modulesItems.map((module: Module, index: number) => {
                    return (
                      <Box
                        key={module?.id}
                        sx={{ alignItems: 'center', display: 'flex', }}
                      >
                        <Box
                          component='span'
                          sx={{
                            color: '#9f9f9f',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '150%',
                            textAlign: 'center',
                            width: '35px'
                          }}
                        >
                          {index < 10 ? `0${index + 1}` : index + 1}
                        </Box>

                        <Grid
                          container
                          sx={{
                            padding: '16px',
                            background: '#f0f3f4',
                            borderRadius: '8px',
                            width: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Grid item xs={4}>
                            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                              <img src={BarcodeIcon} alt='' style={{ width: '20px', height: '20px' }} />
                              <Box
                                component='span'
                                sx={{
                                  color: theme.palette.primary.light,
                                  fontWeight: 700,
                                  lineHeight: '120%',
                                  margin: '0 8px 0 2px'
                                }}
                              >
                                {module?.id}
                              </Box>
                              <Box
                                component='span'
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  lineHeight: '150%'
                                }}
                              >
                                {module?.name}
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item xs={2}>
                            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                              <Box
                                component='span'
                                sx={{
                                  color: theme.palette.text.primary,
                                  fontSize: '14px',
                                  fontWeight: 600,
                                  lineHeight: '150%',
                                  marginRight: '3px'
                                }}
                              >
                                Questões:
                              </Box>
                              <Box
                                component='span'
                                sx={{
                                  color: theme.palette.primary.light,
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '150%'
                                }}
                              >
                                {(module?.module_items_count && module?.module_items_count < 9) ? `0${module?.module_items_count}` : module?.module_items_count}
                              </Box >
                            </Box>
                          </Grid>

                          <Grid item xs={6} sx={{ textAlign: 'center' }}>
                            <Box>
                              <Button
                                variant='outlined'
                                startIcon={<ViewIcon width='16px' />}
                                sx={{
                                  background: '#ffffff',
                                  borderColor: '#b7b2c8',
                                  color: '#b7b2c8',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  height: 'fit-content',
                                  lineHeight: '120%',
                                  padding: '8px 48px'
                                }}
                                onClick={() => handleViewContentInModule(module.id)}
                              >
                                Visualizar conteúdos
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}
                </Box>
              </Collapse>
            </Box>
          )}
        </Box >

        <ModalDelete
          isOpen={openDeleteChapterModal}
          closeModal={handleCloseDeleteChapterModal}
          action={() => {
            removeChapter(chapterId)
            handleCloseDeleteChapterModal()
          }}
          type='chapter'
        />

        <ModalDelete
          isOpen={openDeleteModuleModal}
          closeModal={handleCloseDeleteModuleModal}
          action={() => removeModule(moduleId)}
          type='module'
        />

        <DialogModal
          open={currentItem?.isFetching || (moduleId === currentItem.item?.id)}
          handleClose={() => dispatch(clearCurrentItem())}
          width='820px'
          height='520px'
        >
          <div>
            {currentItem.isFetching && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress thickness={2} />
              </Box>
            )}
            {currentItem?.item &&
              !currentItem?.isFetching &&
              currentItem?.item?.content_module_items?.map((item: { content_item: { question: any } }, key: any) => {
                return (item?.content_item.question) ? (
                  <Box
                    key={key}
                    sx={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '16px',
                      marginBottom: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    <QuestionCard
                      question={item?.content_item.question}
                    />
                  </Box>
                ) : (
                  <Box
                    key={key}
                    sx={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '16px',
                      marginBottom: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    <ContentCard content={item?.content_item} />
                  </Box>
                )
              })}
          </div>
        </DialogModal>
      </Box>
    </>

  )
}

interface ChapterCardProps extends ChapterElementProps {
  enableDragnDrop?: boolean | null
}

const ChapterCard: React.FC<ChapterCardProps> = ({
  chapterData,
  index,
  bookId,
  isTeacher,
  userId,
  enableDragnDrop,
  setEnableDragnDrop
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)

  return (
    <>
      {enableDragnDrop
        ? (
          <ChapterElement
            chapterData={chapterData}
            index={index}
            bookId={bookId}
            isTeacher={isTeacher}
            userId={userId}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setEnableDragnDrop={setEnableDragnDrop}
          />
        ) : (
          <Draggable
            draggableId={String(chapterData?.id)}
            index={index}
          >
            {(provided) => {
              return (
                <Box
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <ChapterElement
                    chapterData={chapterData}
                    index={index}
                    bookId={bookId}
                    isTeacher={isTeacher}
                    userId={userId}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    setEnableDragnDrop={setEnableDragnDrop}
                  />
                </Box>
              )
            }}
          </Draggable>
        )}
    </>
  )
}

export default ChapterCard
