import React from 'react'
import { styled } from '@mui/material/styles'

export const ParagraphStyled = styled('p')(() => ({
  color: '#9F9F9F',
  fontWeight: 700,
  fontSize: '14px',
  margin: 0,
  wordBreak: 'break-word'
}))

interface Text {
  text: string
}

const ParentDataField = ({text}: Text) => {
  return (
    <ParagraphStyled>
      {text}
    </ParagraphStyled>
  )
}

export default ParentDataField
