import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Fade } from '@mui/material'

import { RootState } from '../../store/configureStore'
import { hideAlertMessage } from '../../store/alert'

export interface Props {
  open: boolean,
  message: string,
  severity?: 'error' | 'info' | 'success' | 'warning',
  vertical?: 'bottom' | 'top'
  horizontal?: 'center' | 'left' | 'right'
}

export interface State extends SnackbarOrigin {
  open: boolean
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant='outlined' {...props} />
})

export default function SnackBarAlert() {
  const dispatch = useDispatch()
  const { alert: { open, message, duration, severity, vertical = 'top', horizontal = 'right' } } = useSelector((state: RootState) => state)

  const handleClose = () => {
    dispatch(hideAlertMessage())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={duration}
      TransitionComponent={Fade}
      onClose={handleClose}
      sx={{ backgroundColor: '#FFF', borderRadius: 2 }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
