import React from 'react'
import BooksView from '../components/Books/list/BooksView'
import { useAppSelector } from '../store/hooks'
import { checkCurrentRole } from '../utils/functions'

const BooksContainer = () => {
  const { auth: { user }, persistable: { selectedRole } } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')

  return (
    <BooksView isTeacher={isTeacher} userId={user.id} />
  )
}

export default BooksContainer
