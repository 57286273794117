import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'
import http from '../../utils/http'

type Props = FieldRenderProps<string, any>

const BoxStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& > div': {
    fontSize: 14,
    '& .select__control': {
      cursor: 'pointer',
      borderRadius: 8,
      border: '1px solid #D9D9D9',
      minHeight: '40px',
      '& .select__placeholder': {
        color: '#D9D9D9'
      },
      '& .select__input-container': {
        color: theme.palette.text.primary
      },
      '& .select__dropdown-indicator > svg': {
        height: 16,
        width: 20
      },
      '& .select__multi-value': {
        background: '#FFFFFF',
        border: '0.75px solid #BC9BFA',
        borderRadius: 32,
        height: 20,
        '& .select__multi-value__label': {
          color: '#BC9BFA',
          fontSize: 12,
          fontWeight: 500,
          padding: '0 0 0 6px'
        },
        '& .select__multi-value__remove': {
          '&:hover': {
            background: 'none',
            '& svg': {
              backgroundColor: theme.palette.error.main
            }
          },
          '& svg': {
            fill: '#fff',
            borderRadius: '50%',
            backgroundColor: '#BC9BFA',
            height: 10,
            width: 10
          }
        }
      }
    },
    '& .select__control--is-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    }
  }
}))

const SelectInputField = ({
  input: { name, value, ...restInput },
  meta: { touched, error },
  placeholder,
  label,
  request,
  values,
  helperText,
  ...rest
}: Props) => {
  const [options, setOptions] = useState<{id: number, name: string}[]>([])

  const getOptions = async (request: any) => {
    const response = await http.get(request.path, { params: request.params })
    setOptions(response.data.data || response.data)
  }

  useEffect(() => {
    setOptions([])
    request && getOptions(request)

    return () => {
      setOptions([])
    }
  }, [values])

  return (
    <BoxStyled>
      {label &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontSize: 14,
            fontWeight: 500
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <Select
        name={name}
        classNamePrefix='select'
        placeholder={placeholder}
        options={options.map((option: any) => ({ value: option.id, label: option.name })) as any}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={value}
        {...restInput}
        {...rest}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: touched && error ? '#F69E9E' : '#D9D9D9'
        }}
      >
        {helperText}
        {touched && error && error}
      </Typography>
    </BoxStyled>
  )
}

export default SelectInputField
