import React, { Fragment } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import ActivitiesCard from '../ActivitiesCard'
import { Columns } from '../../table/Table'
import { IActivities } from '../../../models/IActivities'

type ActivitiesListGridProps = {
  columns: Columns[],
  data: IActivities[],
}

const CardContentStyled = styled(CardContent)({
  color: '#9F9F9F',
  display: 'grid',
  fontSize: 14,
  rowGap: '12px',
  gridTemplateRows: 'repeat(3, 1fr)',
  gridTemplateColumns: '2fr auto',
  height: '100%',
  alignContent: 'space-between',
  alignItems: 'center',
  gridTemplateAreas:
    `"title actions"
		"date application"
		"teacher type"
		`,
  '& #title': {
    gridArea: 'title',
    fontWeight: 600,
    color: '#666666'
  },
  '& #data': {
    gridArea: 'date',
    fontWeight: 500,
    color: '#666666',
    '& div:nth-of-type(2)': {
      color: '#9F9F9F',
      marginTop: '2px'
    }
  },
  '& #application': {
    gridArea: 'application',
    fontWeight: 500,
    color: '#666666',
    '& div:nth-of-type(2)': {
      color: '#9F9F9F',
      marginTop: '2px'
    }
  },
  '& #type': {
    gridArea: 'type',
    fontWeight: 500,
    color: '#666666',
    '& div:nth-of-type(2)': {
      color: '#9F9F9F',
      marginTop: '2px'
    }
  },
  '& #user': {
    gridArea: 'teacher',
    fontWeight: 500,
    color: '#666666',
    '& div:nth-of-type(2)': {
      color: '#9F9F9F',
      marginTop: '2px'
    }
  },
  '& #actions': {
    gridArea: 'actions',
    justifySelf: 'end',
  }
})

const ActivitiesListGrid = ({ columns, data }: ActivitiesListGridProps) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Grid container sx={{ maxWidth: 1168, margin: 'auto' }} columnSpacing={match ? 5 : 1} rowSpacing={match ? 4 : 1}>
      {data &&
        data.map(row => {
          return (
            <Grid item xs={6} md={4} lg={4} key={row.id}>
              <ActivitiesCard>
                <CardContentStyled>
                  {columns.map((column, index) => {
                    const showIndex = column.render && column.render(row)
                    return <Fragment key={`${column.name}-${index}`}>{showIndex}</Fragment>
                  })}
                </CardContentStyled>
              </ActivitiesCard>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default ActivitiesListGrid