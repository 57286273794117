import React from 'react'
import ActivitiesRankingContainer from '../containers/ActivitiesRankingContainer'

const ActivityRanking = () => {
  return (
    <div>
      <ActivitiesRankingContainer />
    </div>
  )
}

export default ActivityRanking
