// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import DatePickerField from '../../input/DatePickerField'
import SchoolChapterItem from './SchoolChapterItem'

// Utils
import { styled } from '@mui/material/styles'
import { format } from 'date-fns'
import { Dates, IChapterToPublish } from '../../../models/IBooks'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as OrderIcon } from '../../../assets/components/Books/order-icon.svg'
import ChapterIcon from '../../../assets/components/Books/chapter-icon.svg'

const ChapterItemContainer = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  border: '1px dashed #b7b2c8',
  borderRadius: '16px',
  padding: '24px 16px',

  '& .chapter_item': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    '&-title': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: '8px',

      '& span:first-of-type': {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '150%',
      },

      '& span:last-of-type': {
        color: theme.palette.text.primary,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
      }
    },

    '&-actions': {
      alignItems: 'center',
      display: 'flex',
      gap: '24px',

      '& .order': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',

        '& span': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 700
        }
      },

      '& .btn': {
        height: '18px',
        width: '18px'
      }
    }
  },

  '& .chapter_description': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%',
    marginTop: '16px',
  }
}))

const CollapseContent = styled(Box)(({ theme }) => ({
  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '32px'
  },

  '& .dates_container': {
    background: '#e4e9eb',
    borderRadius: '16px',
    margin: '16px 0 24px',
    padding: '24px 16px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '8px'
    }
  },

  '& .school_items': {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  }
}))

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    }
  }
}))

interface ChapterItemProps {
  chapterData: IChapterToPublish
  chapterIndex: number
  dates: Dates[]
  addDates: (x: any) => void
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
}

const ChapterItem: React.FC<ChapterItemProps> = ({
  chapterData,
  chapterIndex,
  dates,
  addDates,
  changeChapterDate,
  changeModuleDate
}) => {
  const [openChapter, setOpenChapter] = useState(false)
  const [date, setDate] = useState<{ start_date?: string, end_date?: string }>({ start_date: undefined, end_date: undefined })

  const schoolsFiltered = chapterData?.classroom_chapters?.map((item) => {
    return item.school_classroom.school_unit
  }).filter((item: any, index: number, self: any) => (
    index === self.findIndex((t: any) => t.id === item.id)
  ))

  const schools = schoolsFiltered?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      numberOfClassrooms: chapterData?.classroom_chapters?.filter((i) => i.school_classroom.school_unit.id === item.id)?.length,
      schoolData: chapterData?.classroom_chapters.filter((x) => x.school_classroom.school_unit.id === item.id)
    }
  })

  const classroomChaptersIds = chapterData?.classroom_chapters?.map((item) => item.id)

  const handleChangeAllSchoolsDates = (
    chapterId: number,
    classroomChapterIds: number[],
    key: string,
    value: Date
  ) => {
    setDate({ ...date, [key]: value })
    const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
    if (chapterIndex === -1) {
      addDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: classroomChapterIds.map((item) => ({
            id: item,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }))
        }
      ])
    } else {
      const update = [...dates]
      update[chapterIndex] = {
        ...update[chapterIndex],
        classroom_chapters: classroomChapterIds.map((_, index) => ({
          ...update[chapterIndex].classroom_chapters[index],
          [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
          classroom_modules: []
        }))
      }
      addDates(update)
    }
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenChapter(true)
  }, [])

  return (
    <ChapterItemContainer>
      <Box>
        <Box className='chapter_item'>
          <Box
            className='chapter_item-title'
            onClick={() => setOpenChapter(prevState => !prevState)}
          >
            <img src={ChapterIcon} />
            <span>Capítulo {chapterData?.chapter_order} - </span>
            <span>{chapterData?.name}</span>
          </Box>

          <Box className='chapter_item-actions'>
            <Box className='order'>
              <OrderIcon width='16px' height='12.5px' />
              <Box component='span'>
                {chapterData?.chapter_order}
              </Box>
            </Box>

            <IconButton
              onClick={() => setOpenChapter(prevState => !prevState)}
              className='btn'
            >
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: openChapter ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>
        </Box>

        <Typography className='chapter_description'>
          {chapterData?.description}
        </Typography>

        <Collapse in={openChapter}>
          <CollapseContent>
            <Typography className='title'>
              Defina as datas de início e fim desse capítulo
            </Typography>

            <Box className='dates_container'>
              <Typography className='label'>
                Quer definir uma data única para publicar este capítulo em todas as escolas?
              </Typography>

              <FormBox>
                <Box className='field'>
                  <Typography className='field_label'>Início:</Typography>
                  <DatePickerField
                    name='start_date'
                    locale='pt-BR'
                    timeIntervals={1}
                    timeCaption='Hora'
                    dateFormat='dd/MM/yyyy'
                    placeholder='00/00/00'
                    width='170px'
                    changeDate={(value: any) =>
                      handleChangeAllSchoolsDates(
                        chapterData.id,
                        classroomChaptersIds,
                        'start_date',
                        value
                      )}
                    date={date?.start_date}
                    startDate={date?.start_date}
                    endDate={date?.start_date}
                    maxDate={date?.end_date}
                    filterTime={date.end_date && filterRangeStartDate}
                    selectsStart
                    readOnly
                  />
                </Box>

                <Box className='field'>
                  <Typography className='field_label'>Fim:</Typography>
                  <DatePickerField
                    name='end_date'
                    locale='pt-BR'
                    timeIntervals={1}
                    timeCaption='Hora'
                    dateFormat='dd/MM/yyyy'
                    placeholder='00/00/00'
                    width='170px'
                    changeDate={(value: any) =>
                      handleChangeAllSchoolsDates(
                        chapterData.id,
                        classroomChaptersIds,
                        'end_date',
                        value
                      )}
                    date={date?.end_date}
                    startDate={date?.end_date}
                    endDate={date?.end_date}
                    minDate={date?.start_date}
                    filterTime={filterRangeEndDate}
                    readOnly
                  />
                </Box>
              </FormBox>
            </Box>

            <Box className='school_items'>
              {schools && schools?.length > 0 && schools?.map((school) => {
                return (
                  <SchoolChapterItem
                    key={school.id}
                    school={school}
                    chapterId={chapterData.id}
                    chapterIndex={chapterIndex}
                    dates={dates}
                    addDates={addDates}
                    changeChapterDate={changeChapterDate}
                    changeModuleDate={changeModuleDate}
                    disabledSchoolDateSelector={!!date?.start_date || !!date?.end_date}
                  />
                )
              })}
            </Box>
          </CollapseContent>
        </Collapse>
      </Box>
    </ChapterItemContainer>
  )
}

export default ChapterItem
