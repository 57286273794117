/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

// Assets
import TrashIcon from '../assets/components/Questions/trash-icon.svg'
import { ReactComponent as OrderByIcon } from '../assets/components/activity/orderby-icon.svg'
import ViewIcon from '../assets/components/activity/view-icon.svg'
import CloseIcon from '@mui/icons-material/Close'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import ResetIcon from '../assets/icons/reset-icon.svg'

// Components
import {
  Box,
  Fade,
  LinearProgress,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material'
import { DatePickerField } from '../components/input/DatePickerInput'
import SelectInput from '../components/select/SelectInput'
import MaterialButton from '@mui/material/Button'
import Table from '../components/table/Table'
import SearchInput from '../components/input/SearchInput'
import Button from '../components/button/Button'

// Styles
import { styled, useTheme } from '@mui/material/styles'
import styles from './styles.module.scss'

// Redux
import { useDispatch } from 'react-redux'
import { subjectsFetchRequest } from '../store/education'
import { useAppSelector } from '../store/hooks'
import { getBooksDeletedByUserRequest } from '../store/booksDeleted/actions'
import { Items } from '../store/booksDeleted/types'
import { format, formatDistance } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../utils/functions'

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '120%'
}))

const OrderByBtn = styled(MaterialButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 12,
  textTransform: 'none',
  '& svg': {
    marginRight: 8,
    '& path': {
      fill: theme.palette.secondary.main
    }
  },
  [theme.breakpoints.down(906)]: {
    border: 'none',
    minWidth: '35px',
    padding: 0,
    '& svg': {
      marginRight: 0,
      '& path': {
        fill: '#9F9F9F'
      }
    },
  }
}))

const MenuStyle = styled(Menu)({
  '& .MuiMenu-list': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 152,
    '& .MuiMenuItem-root': {
      fontSize: 12,
      '&:not(li:last-of-type)': {
        borderBottom: '1px solid #D9D9D9',
      }
    }
  }
})
const StatusContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})
const MenuItemStyle = styled(MenuItem)({
  color: '#9F9F9F',
  fontSize: 14,
  width: 180,
  '& img': {
    marginRight: 10
  }
})

const BooksDeletedContainer = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const matches = useMediaQuery('(max-width:1025px)')
  const { subjects }: any = useAppSelector((state) => state.education)
  const [anchorOrderByEl, setAnchorOrderByEl] = useState<HTMLElement | null>(null)
  const openOrderBy = Boolean(anchorOrderByEl)
  const [anchorMoreEl, setAnchorMoreEl] = useState<HTMLElement | null>(null)
  const { items, isFetching, pagination } = useAppSelector((state) => state.booksDeleted)
  const [page, setPage] = useState(1)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)

  const handleCloseMore = () => {
    setAnchorMoreEl(null)
  }

  const handleOpenOrderBy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorOrderByEl(event.currentTarget)
  }

  const handleCloseOrderBy = (event: any) => {
    const value = event.currentTarget.id
    setAnchorOrderByEl(null)
    value !== '' && value !== null
    // && handleGetActivitiesItems({ order: value })
  }

  useEffect(() => {
    dispatch(subjectsFetchRequest())
    dispatch(getBooksDeletedByUserRequest({page: 1, per: 10}))
  }, [])

  const subjectOptions = subjects?.items?.map((item: any) => {
    return { label: item?.name, value: item?.id }
  }) ?? []

  const options = [
    { label: 'Selecione...', value: 'placeholder' },
    ...subjectOptions
  ]
  const openMore = Boolean(anchorMoreEl)

  const handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMoreEl(event.currentTarget)
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(getBooksDeletedByUserRequest({ page: value }))
    scrollToTop()
  }

  const resetLocation = () => {
    const newLocation = {
      ...location,
      state: null
    }

    navigate(newLocation)
  }
  const handleStartChanges = (startValue: any) => {
    const startDateFormatted = startValue ? format(startValue, 'yyyy-MM-dd') : ''
    if (endDate && formatDistance(startValue, endDate)) {
      setEndDate(null)

    } else {
      console.log('else') // eslint-disable-line
    }
    setPage(1)
    setStartDate(startValue)
    resetLocation()
  }

  const handleEndChanges = (endValue: any) => {
    const endDateFormatted = endValue ? format(endValue, 'yyyy-MM-dd') : ''
    setEndDate(endValue)
    setPage(1)
    resetLocation()
  }

  const columns: any = [
    {
      key: 'title',
      name: 'Título',
      width: '35%',
      render: (row: Items) => {
        return (
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Box sx={{
              height: '14px',
              width: '14px',
              background: row.status === 'draft' ? '#fce6e6' :
                row.status === 'published' ? '#e6f7fa' :
                  row.status === 'ready_to_publish' ? '#F5F0FE' : '#04AEC9',
              borderRadius: '50%'
            }} />
            <Typography>{row.name}</Typography>
          </Box>
        )
      }
    },
    {
      key: 'subject',
      name: 'Disciplina',
      width: '15%',
      render: (row: any) => {
        return (
          <div>{row.subjects.map((subject: any) => subject.name).join(', ')}</div>
        )
      }
    },
    {
      key: 'create_date',
      name: 'Criação',
      width: '15%',
      render: (row: Items) => {
        return (
          <div>dd/mm/yyyy</div>
        )
      }
    },
    {
      key: 'author',
      name: 'Autor',
      width: '10%',
      render: (row: Items) => {
        return (
          <div>{row.authors.map((author: any) => author.name).join(', ')}</div>
        )
      }
    },
    {
      key: 'performance',
      name: 'Performance',
      width: '10%',
      render: (row: Items) => {
        return (
          <Box textAlign='center'>0.0%</Box>
        )
      }
    },
    {
      key: 'access',
      name: 'Acesso',
      width: '10%',
      render: (row: Items) => {
        return (
          <div>0000</div>
        )
      }
    },
    {
      key: 'actions',
      name: '',
      width: '10%',
      align: 'right',
      render: (row: any) => {
        return (
          <div>
            <IconBtn
              id={`moreActionsButton[${row.id}]`}
              aria-controls={openMore ? `moreActionsmenu[${row.id}]` : undefined}
              aria-haspopup='true'
              aria-expanded={openMore ? 'true' : undefined}
              onClick={handleOpenMore}
            >
              <MoreVertRoundedIcon />
            </IconBtn>
            <Menu
              id={`moreActionsmenu[${row.id}]`}
              keepMounted
              anchorEl={anchorMoreEl}
              open={anchorMoreEl?.id === `moreActionsButton[${row.id}]`}
              onClose={handleCloseMore}
              TransitionComponent={Fade}
              sx={{
                '& .MuiPaper-root': {
                  border: '1px solid #D9D9D9',
                  borderRadius: '8px',
                  width: '164px'
                }
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconBtn onClick={handleCloseMore}>
                  <CloseIcon />
                </IconBtn>
              </Box>
              <MenuItemStyle onClick={() => null}>
                <img src={ViewIcon} alt='icone de visualizar' width='16' /> Visualizar
              </MenuItemStyle>

              <MenuItemStyle onClick={() => null}>
                <img src={ResetIcon} alt='icone de Resetar' width='16' /> Restaurar
              </MenuItemStyle>
              <MenuItemStyle className={styles.menuDownloadIcon} onClick={() => null}>
                <img src={TrashIcon} alt='icone de Excluir' width='16' /> Excluir
              </MenuItemStyle>
            </Menu>
          </div >
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = matches
      ? ['title', 'author']
      : ['title', 'subject', 'create_date', 'author', 'performance', 'access', 'actions']
    return columns.filter((column: { key: string }) => columnKeys.includes(column.key))
  }

  return (
    <Box
      sx={{
        padding: '0px 60px 32px 41px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          marginBottom: '32px'
        }}
      >
        <img src={TrashIcon} />
        <Title>Livros excluídos</Title>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          marginBottom: '16px'
        }}
      >
        <Box
          width='100%'
        >
          <Box>
            <Typography sx={{ color: '#9F9F9F' }} variant='subtitle2' component='p'>Titulo</Typography>
          </Box>
          <Box

          >
            <SearchInput
              border='1px solid #D9D9D9'
              borderRadius='8px'
              name='searchTitle'
              placeholder='Insira o título do livro'
            />
          </Box>
        </Box>
        <Box
          width='100%'
        >
          <Box>
            <Typography sx={{ color: '#9F9F9F' }} variant='subtitle2' component='p'>Disciplina</Typography>
          </Box>
          <Box
            sx={{
              height: '40px',
              borderRadius: '8px',
              background: '#fff',

            }}
          >
            <SelectInput
              width='100%'
              name='selectSubject'
              onChange={() => null}
              sx={{ color: '#9F9F9F' }}
              defaultValue='placeholder'
              options={options}
            />
          </Box>
        </Box>
        <Box
          width='100%'
        >
          <Box>
            <Typography color='#9F9F9F' variant='subtitle2' component='p'>Mês de criação</Typography>
          </Box>
          <Box>
            <DatePickerField
              name='createdDate'
              onChange={handleStartChanges}
              startDate={startDate}
              endDate={endDate}
              placeholder='dd/mm/aaaa'
              selectsStart
            />
          </Box>
        </Box>
        <Box
          width='100%'
        >
          <Box>
            <Typography color='#9F9F9F' variant='subtitle2' component='p'>Mês de Exclusão</Typography>
          </Box>
          <Box>
            <DatePickerField
              name='deletedDate'
              onChange={handleEndChanges}
              startDate={endDate}
              endDate={endDate}
              minDate={startDate}
              placeholder='dd/mm/aaaa'
            />
          </Box>
        </Box>
      </Box>
      <Paper
        sx={{
          borderRadius: '16px',
          padding: '23px 32px 36px 32px'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box mb='32px'>
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: '18px',
                fontWeight: 600
              }}
            >Lista de livros</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '32px' }}>
            <StatusContainer
            >
              <Box
                sx={{
                  height: '14px',
                  width: '14px',
                  background: '#e6f7fa',
                  borderRadius: '50%'
                }}
              />
              <Typography>Publicado</Typography>
            </StatusContainer>
            <StatusContainer
            >
              <Box
                sx={{
                  height: '14px',
                  width: '14px',
                  background: '#fce6e6',
                  borderRadius: '50%'
                }}
              />
              <Typography>Rascunho</Typography>
            </StatusContainer>
            <StatusContainer>
              <Box
                sx={{
                  height: '14px',
                  width: '14px',
                  background: '#F5F0FE',
                  borderRadius: '50%'
                }}
              />
              <Typography>A publicar</Typography>
            </StatusContainer>
          </Box>
          <Box sx={{ display: 'flex', gap: matches ? '8px' : '16px' }}>
            <OrderByBtn
              id='orderBy'
              aria-controls={openOrderBy ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openOrderBy ? 'true' : undefined}
              onClick={handleOpenOrderBy}
              variant='outlined'
            >
              <OrderByIcon />{!matches && ' Ordenar por'}
            </OrderByBtn>
            <MenuStyle
              id='orderByMenu'
              anchorEl={anchorOrderByEl}
              open={openOrderBy}
              onClose={handleCloseOrderBy}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseOrderBy} id='new'>Recentes</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='alpha'>Ordem alfabética</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='high-per'>Maior performance</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='low-perf'>Menor performance</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='high-int'>Maior intereção</MenuItem>
              <MenuItem onClick={handleCloseOrderBy} id='low-int'>Menor intereção</MenuItem>
            </MenuStyle>
          </Box>
        </Box>
        <Box>
          {isFetching ? (<LinearProgress sx={{ marginBlock: '32px' }} />) : (<Table
            columns={getColumns()}
            data={items}
          />)}
        </Box>
        <Box
          marginTop='16px'
        >
          <Pagination
            count={pagination.totalPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '32px'
            }}
          />
        </Box>
      </Paper>
      <Box display='flex' justifyContent='flex-end' mt={4}>
        <Button onClick={() => navigate('/books')}>Voltar</Button>
      </Box>
    </Box>
  )
}

export default BooksDeletedContainer
