import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  padding: '15px',

  '& .header': {
    alignItems: 'center',
    display: 'flex',
    gap: '15%',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '& .book_title': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .title': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600
      }
    },

    '& .filter': {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      gap: '16px',
      // justifyContent: 'center'
    },

    '& .btn_export': {
      background: theme.palette.primary.light,
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14px 19px',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#ac83fa',
      }
    },
  },

  '& .label_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginBottom: '16px',

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600
    }
  },

  '& .pagination': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px'
  },

  '& .btn_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    marginTop: '32px',

    '& .btn_back': {
      borderRadius: '8px',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '14px 40px',
      textTransform: 'capitalize'
    }
  },
}))

export const DashedContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: '16px',
  padding: '32px 22px'
}))

export const StudentsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  '& .MuiGrid-item:first-of-type > .card': {
    background: theme.palette.primary.main,

    '& .student_description-school > p': {
      color: '#ffffff'
    },

    '& .student_stats-performance, & .student_stats-progress': {
      '& .label, & .value': {
        color: '#ffffff'
      },

      '& .linearProgress_performance, & .linearProgress_progress': {
        background: '#ffffff',
      }
    }
  }
}))

export const StudentCard = styled(Box)(({ theme }) => ({
  maxWidth: '350px',
  borderRadius: '16px',
  background: '#fff',
  width: '100%',
  padding: '16px 24px',
  position: 'relative',

  '& .student_position': {
    width: '28px',
    height: '28px',
    background: theme.palette.secondary.main,
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '0.2px',
    position: 'absolute',
    left: '-14px',
    top: '2px'
  },

  '& .student_description': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginBottom: '8px',

    '&-school': {
      '& p:first-of-type': {
        color: theme.palette.text.primary,
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: '0.2px',
        marginBottom: '4px',
        maxWidth: '242px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },

      '& p:last-of-type': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        maxWidth: '242px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  },

  '& .student_stats': {
    '&-performance': {
      marginBottom: '8px'
    },

    '&-performance, &-progress': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& .label, & .value': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      },

      '& .label': {
        maxWidth: '113px',
        width: '100%'
      },

      '& .value': {
        maxWidth: '36px',
        textAlign: 'center',
        width: '100%'
      },

      '& .linearProgress_performance': {
        background: theme.palette.text.secondary,
        width: '100%',

        '.MuiLinearProgress-bar': {
          backgroundColor: theme.palette.secondary.main
        }
      },

      '& .linearProgress_progress': {
        background: theme.palette.text.secondary,
        width: '100%',

        '.MuiLinearProgress-bar': {
          backgroundColor: theme.palette.primary.light
        }
      }
    }
  }
}))

export const FiltersContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '56px',
  marginBottom: '40px',

  '& .filter': {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
    flex: '1',

    '& label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500
    },

    '& .select > .select__control > .select__value-container > .select__placeholder': {
      color: theme.palette.text.secondary
    }
  }
}))

export const LoadingBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',

  '& .linear_progress': {
    height: '10px',
    maxWidth: '200px',
    width: '100%'
  }
}))

export const ErrorMessageBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#fff',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '16px',

  '& .image': {
    maxWidth: '150px',

    '& > img': {
      width: '100%'
    }
  },

  '& .message': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  }
}))
