import React, { useState, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { QuestionFormValues } from '../QuestionsForm'
import CheckmarkInput from '../../../input/CheckmarkInput'
import FiltersSelect from '../../../select/FiltersSelect'
import AsyncSelectInput from '../../../select/AsyncSelectInput'
import SelectSubjectCombo from '../../../select/SelectSubjectCombo'
import SelectCompetenceCombo from '../../../select/SelectCompetenceCombo'
import Button from '../../../button/Button'
import ContentSupportCard from '../ContentSupportCard'
import { required } from '../../../../utils/formUtils'
import { highSchoolSegmentId } from '../../../../utils/constants'
import http from '../../../../utils/http'
import { scrollToTop } from '../../../../utils/functions'
import styles from '../styles.module.scss'

const PaperContainer = styled(Paper)(({
  padding: '40px 32px',
  borderRadius: '16px',

  '& .required': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'right',
  }
}))

interface StepThreeProps {
  activeStep: number
  values: QuestionFormValues
  isTeacher: any;
  userId?: any;
  setShowContent: (isShow: boolean) => void
  removeContent: (id: number | string) => void
  form?: any
}


const StepThree: React.FC<StepThreeProps> = (props) => {
  const { activeStep, values, isTeacher, userId, setShowContent, removeContent, form } = props
  const [contents, setContents] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true
    const loadContentById = async () => {
      try {
        if (values && values.content_parents) {
          setIsLoading(true)
          const promises = values.content_parents.map(id => {
            return http.get(`/content_items/${id}`)
          })
          const results = await Promise.all(promises)
          const data = results.map((response: AxiosResponse) => response.data.data)
          if (isMounted) setContents(data)
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
      }
    }
    loadContentById()

    return () => {
      isMounted = false
      setContents([])
    }
  }, [values.content_parents])

  const removeContentById = (id: number) => {
    setContents(contents.filter(content => content.id !== id))
  }

  const handleRemoveContent = (id: number, index: number) => {
    removeContent(id)
    removeContentById(id)
    form.mutators.remove('content_parents', index)
  }

  if (activeStep !== 3) {
    return null
  }

  return (
    <PaperContainer>
      <>
        <Grid container rowSpacing={4} columnSpacing={3}>
          <>
            <Grid item xs={12}>
              <Field
                name='enem_type'
                component={CheckmarkInput}
                className={styles.label}
                label={<>Marcar como questão estilo <span>ENEM</span></>}
                type='checkbox'
                value={values?.enem_type}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={AsyncSelectInput}
                placeholder='Nível de dificuldade'
                label='Nível de dificuldade *'
                name='difficulty_level'
                hasBorder
                options={[
                  { value: 'very_easy', name: 'Muito Fácil' },
                  { value: 'easy', name: 'Fácil' },
                  { value: 'moderate', name: 'Moderado' },
                  { value: 'difficult', name: 'Difícil' },
                  { value: 'very_difficult', name: 'Muito difícil' },
                ]}
                validate={required}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FiltersSelect}
                placeholder='Nível de dificuldade do ENEM'
                label='Nível de dificuldade do ENEM'
                name='enem_difficulty_level'
                hasBorder
                options={[
                  {
                    value: 'easier',
                    name: '+ Fácil'
                  },
                  {
                    value: 'easy',
                    name: 'Fácil'
                  },
                  {
                    value: 'less_easy',
                    name: '- Fácil'
                  },
                  {
                    value: 'medium_easy',
                    name: 'Médio/Fácil'
                  },
                  {
                    value: 'medium',
                    name: 'Médio'
                  },
                  {
                    value: 'medium_hard',
                    name: 'Médio/Difícil'
                  },
                  {
                    value: 'less_hard',
                    name: '- Difícil'
                  },
                  {
                    value: 'hard',
                    name: 'Difícil'
                  },
                  {
                    value: 'harder',
                    name: '+ Difícil'
                  }
                ]}
              />
            </Grid>
          </>
          <Paper sx={{ padding: '32px', borderRadius: 2, backgroundColor: '#F9F9F9', width: '100%', m: 2, mr: 0 }}>
            <Grid container>
              <span style={{ marginBottom: '23px', color: '#9F9F9F' }}>Associar disciplinas</span>
              {<>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FieldArray name='subject_ids'>
                      {({ fields }) => {
                        return (
                          <SelectSubjectCombo
                            name='subject_ids'
                            fields={fields}
                            subjects={values?.subject_ids}
                            isTeacher={isTeacher}
                            userId={userId}
                            segment={values.segment_ids}
                            highSchoolSegmentId={highSchoolSegmentId}
                            hasBackground
                          />
                        )
                      }}
                    </FieldArray>
                  </Grid>
                </Grid> </>}
            </Grid>
          </Paper>

          {values && values.segment_ids && values.segment_ids.id === 4 && (
            <SelectCompetenceCombo name='subject_ids' values={values} />
          )}

        </Grid>
        <Grid container columnSpacing={6} rowSpacing={3} sx={{ padding: '16px 0' }}>
          {isLoading && values && values.content_parents && (
            <Box sx={{ margin: 'auto', padding: '16px' }}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && contents && contents.map((item, index) => (
            <ContentSupportCard
              key={`${item.name}-${item.id}-${index}`}
              name={item.name}
              description={item.description}
              sizeFile={item.schema_content?.size}
              author={item.who_created_user?.name}
              onClick={() => handleRemoveContent(item.id, index)}
            />
          ))}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            onClick={() => {
              setShowContent(true)
              scrollToTop()
            }}
            style={{
              marginTop: 20,
              backgroundColor: '#BC9BFA',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disableElevation
          >
            Adicionar material de apoio
          </Button>
        </Box>
      </>
      {<Typography className='required'>* Campo obrigatório</Typography>}
    </PaperContainer>
  )
}

export default StepThree
