import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Books, IUnit, ISubject } from '../../models/IBooks'
import { CircularProgress, Grid, LinearProgress, Typography, Skeleton } from '@mui/material'
import { ReactComponent as PeopleIcon } from '../../assets/components/Books/people-icon.svg'
import { ReactComponent as BarsIcon } from '../../assets/components/Books/chart-icon.svg'
import { ReactComponent as ViewSVG } from '../../assets/components/Books/view-icon.svg'
import http from '../../utils/http'

const FullList = styled('a')(({theme}) => ({
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: theme.palette.primary.light,
    'svg':{
        marginRight: '8px'
    }
}))

 const ClassLength = styled('span')(({ theme }) => ({
    color: `${theme.palette.primary.light} !important`
  }))

  const Classroom = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
    '&:last-of-type': {
        marginBottom: '0px',
    }
  }))

 const ClassroomName = styled('span')(({ theme }) => ({
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#ffffff !important',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    padding: '5.5px 18px',
    maxWidth: '350px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }))

interface IRowUnitProps {
  unit: IUnit,
  classrooms: {
    id: number
    name: string
    average: number
    total_students: number
    students_with_performance: number
  }[]
}

const RowUnit: React.FC<IRowUnitProps> = ({ unit, classrooms }) => {

  const [openUnit, setOpenUnit] = React.useState(false)

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Typography component='h3'>
            {unit.name}
            <ClassLength> ({classrooms.length})</ClassLength>
          </Typography>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpenUnit(!openUnit)}
          >
            {openUnit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            Performance
            <LinearProgress
                sx={{ width: '230px', backgroundColor: '#e0e6e7', margin: '0 8px 0 16px' }}
                // color={100 < 50 ? 'error' : 'secondary'}
                value={100}
                variant='determinate'
            />
            100%
        </Box>
      </Box>
      <Collapse in={openUnit}>
        <Box
            sx={{
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                padding: '16px',
                border: '1px solid #d9d9d9'
                }}>
            <Grid container sx={{display: 'flex', flexDirection: 'column'}}>
              {classrooms?.map(classroom => (
                <Classroom key={classroom.id}>
                   <Grid item md={5} sx={{display: 'flex', alignItems: 'center'}}>
                    <ClassroomName >
                        {classroom?.name}
                    </ClassroomName>
                  </Grid>
                   <Grid item md={2} sx={{display: 'flex', alignItems: 'center'}}>
                        <PeopleIcon style={{marginRight: '8px'}} /> {classroom.students_with_performance}/{classroom.total_students}
                  </Grid>
                   <Grid item md={3} sx={{display: 'flex', alignItems: 'center'}}>
                        <BarsIcon style={{marginRight: '8px'}}/> Desempenho
                        <LinearProgress
                        sx={{ width: '60px', backgroundColor: '#e0e6e7', margin: '0 8px 0 16px' }}
                        color={classroom.average < 50 ? 'error' : 'secondary'}
                        value={classroom.average}
                        variant='determinate'
                    />
                        {classroom.average.toFixed(0)}%
                  </Grid>
                   <Grid item md={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                   <FullList href='#'>
                        <ViewSVG /> Visualizar turma
                    </FullList>
                  </Grid>
                </Classroom>
              ))}
            </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}


interface IRowProps {
    row: Books
}
const RowBook: React.FC<IRowProps> = ({ row }) => {

    const [open, setOpen] = React.useState(false)
    const [book, setBook] = React.useState([])
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const getClassroomsPerformance = async (id: number) => {
        setOpen(!open)
        setError(false)
        setLoading(true)
        !open && await http.get(`/books/${id}/units_performance`)
        .then(res => setBook(res.data))
        .catch(() => setError(true))
        setLoading(false)
    }

    return(
        <>
        <Grid
            container
            onClick={() => getClassroomsPerformance(row?.id)}
            sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: !open ? '8px' : '8px 8px 0 0',
                padding: '16px',
                marginTop: '8px'
            }}
        >
            <Grid item md={3} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
                <Typography sx={{fontWeight: '600'}}>{row.name}</Typography>
            </Grid>
            <Grid item md={2} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
                {row?.units?.map((unit) => (unit?.name))}
            </Grid>
            <Grid item md={3} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
                {row?.authors.map((author) => (author.name))}
            </Grid>
            <Grid item md={2} sx={{ justifyContent: 'center', cursor: 'pointer', display: 'flex', flexDirection: 'column' }}>
                {row?.subjects?.map((subject: ISubject) => (
                    <p style={{margin: '0 0 8px 0'}} key={subject.id}>
                      {subject.name}
                    </p>
                  ))}
            </Grid>
            <Grid item md={1} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
                {row.access}
            </Grid>
            <Grid item md={1} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            <IconButton
                aria-label='expand row'
                size='small'
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
            </Grid>
        </Grid>
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#f5f5f5',
                borderRadius: '0 0 8px 8px',
                padding: !open ? '0' : '8px 16px',
            }}>
            <Collapse sx={{display: 'flex'}} in={open}>
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '8px' }}>
                <CircularProgress />
                </Box>
            )}

            {error && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
                <Typography>Não foi possível carregar os dados para este livro.</Typography>
                </Box>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                {book?.map((unit : any) => (
                <RowUnit
                    key={unit.id}
                    unit={unit}
                    classrooms={unit.school_classrooms}
                />
                ))}
            </Box>
            </Collapse>
        </Box>
        </>
    )
}

interface CollapsibleTableProps {
  books: Books[]
  isFetching: boolean
}

export default function CollapsibleTable({ books, isFetching }: CollapsibleTableProps) {
  return (
   <>
    <Grid container sx={{borderRadius: '8px', padding: '16px 16px 0 16px'}}>
        <Grid item md={3} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            <Typography sx={{fontWeight: '600'}}>Título</Typography>
        </Grid>
        <Grid item md={2} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            Escola
        </Grid>
        <Grid item md={3} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            Autor
        </Grid>
        <Grid item md={2} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            Disciplina
        </Grid>
        <Grid item md={1} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
            Acessos
        </Grid>
        <Grid item md={1} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}>
        </Grid>
    </Grid>
    <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          padding: '22px 0px',
          flexDirection: 'column'
        }}
      >
       {isFetching ?

        <Box sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
            <Skeleton variant='rounded' animation='wave' height='66px' width='100%' />
        </Box> :

        books?.map((book: Books) => (
            <RowBook key={book.id} row={book} />
                ))
        }


    </Box></>
  )
}
