import React from 'react'
import { useAppSelector } from '../store/hooks'
import QuestionsFormView from '../components/Questions/form/QuestionsFormView'
import { checkCurrentRole } from '../utils/functions'
 


const QuestionsFormContainer = () => {
  const { auth: { user }, persistable: { selectedRole } } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')
  return (
    <div>
      <QuestionsFormView isTeacher={isTeacher} userId={user.id} />
    </div>
  )
}

export default QuestionsFormContainer
