// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import DatePickerField from '../../input/DatePickerField'
import ModuleItem from './ModuleItem'

// Utils
import { styled } from '@mui/material/styles'
import { Dates, IClassroomChapters } from '../../../models/IBooks'

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ClassroomItemContainer = styled(Box)(({
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '16px'
}))

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  '& .col-1': {
    order: 1
  },

  '& .col-2': {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: '24px',
    justifyContent: 'flex-end',
    marginRight: '8px',
    order: 2,

    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%'
    },
  },

  '& .col-3': {
    order: 3,

    '& .collapse_btn': {
      height: '18px',
      width: '18px'
    },
  },

  [theme.breakpoints.down(1150)]: {
    flexWrap: 'wrap',

    '& .col-1': {
      flex: 1
    },

    '& .col-2': {
      order: 3,
      flex: '1 0 100%',
      justifyContent: 'flex-start',
      marginTop: '8px'
    },

    '& .col-3': {
      order: 2
    }
  }
}))

const SchoolName = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '8px',
  color: '#ffffff',
  cursor: 'pointer',
  maxWidth: '350px',
  padding: '5.5px 18px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}))

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '32px',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    }
  }
}))

const ModuleContent = styled(Box)(({ theme }) => ({
  marginTop: '8px',

  '& .module_container': {
    marginTop: '32px',
    '& .label': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 700
    },

    '& .module_items': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      marginTop: '16px'
    }
  }
}))

interface ClassroomItemProps {
  data: IClassroomChapters
  chapterIndex: number
  chapterId: number
  dates: Dates[]
  changeChapterDate: (chapterId: number, classroomChapterId: number, key: any, value: Date) => void
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
}

const ClassroomItem: React.FC<ClassroomItemProps> = ({
  data,
  chapterIndex,
  chapterId,
  dates,
  changeChapterDate,
  changeModuleDate
}) => {
  const [openClassroom, setOpenClassroom] = useState(false)
  const [date, setDate] = useState({
    start_date: data.start_date ? new Date(data.start_date) : undefined,
    end_date: data.end_date ? new Date(data.end_date) : undefined
  })

  const chapterDate = dates?.find((date) => date.chapterId === chapterId)
  const classroomChapterDate = chapterDate?.classroom_chapters?.find((item: any) => item.id === data.id)
  const startDate = classroomChapterDate?.start_date ? classroomChapterDate?.start_date?.split(' ')[0] : undefined
  const endDate = classroomChapterDate?.end_date ? classroomChapterDate?.end_date?.split(' ')[0] : undefined

  const handleChangeDate = (chapterId: number, classroomChapterId: number, key: any, value: Date) => {
    setDate({
      ...date,
      [key]: value
    })
    changeChapterDate(chapterId, classroomChapterId, key, value)
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  useEffect(() => {
    chapterIndex === 0 && setOpenClassroom(true)
  }, [])

  return (
    <ClassroomItemContainer>
      <Container>
        <Box className='col-1'>
          <SchoolName
            component='span'
            onClick={() => setOpenClassroom(prevState => !prevState)}
          >
            {data.school_classroom.name}
          </SchoolName>
        </Box>

        <Box className='col-2'>
          <Typography className='label'>
            Defina a data para essa turma:
          </Typography>

          <FormBox>
            <Box className='field'>
              <Typography className='field_label'>Início:</Typography>
              <DatePickerField
                name='start_date'
                locale='pt-BR'
                timeIntervals={1}
                timeCaption='Hora'
                dateFormat='dd/MM/yyyy'
                placeholder='00/00/00'
                width='170px'
                changeDate={(value: any) =>
                  handleChangeDate(
                    chapterId,
                    data.id,
                    'start_date',
                    value
                  )}
                date={startDate}
                startDate={date?.start_date}
                endDate={date?.start_date}
                maxDate={date?.end_date}
                filterTime={date?.end_date && filterRangeStartDate}
                selectsStart
                readOnly
              />
            </Box>

            <Box className='field'>
              <Typography className='field_label'>
                Fim:
              </Typography>
              <DatePickerField
                name='end_date'
                locale='pt-BR'
                timeIntervals={1}
                timeCaption='Hora'
                dateFormat='dd/MM/yyyy'
                placeholder='00/00/00'
                width='170px'
                changeDate={(value: any) =>
                  handleChangeDate(
                    chapterId,
                    data.id,
                    'end_date',
                    value
                  )}
                date={endDate}
                startDate={date?.end_date}
                endDate={date?.end_date}
                minDate={date?.start_date}
                filterTime={filterRangeEndDate}
                readOnly
              />
            </Box>
          </FormBox>
        </Box>

        <Box className='col-3'>
          <IconButton
            onClick={() => setOpenClassroom(prevState => !prevState)}
            className='collapse_btn'
          >
            <KeyboardArrowDownIcon
              fontSize='small'
              sx={{ transform: openClassroom ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </IconButton>
        </Box>
      </Container>

      <Collapse in={openClassroom}>
        <ModuleContent>
          <Box className='module_container'>
            <Typography className='label'>
              Defina as datas de início e fim dos módulos para essa turma
            </Typography>

            <Box className='module_items'>
              {data?.classroom_modules?.map((module) => {
                return (
                  <Box key={module.id}>
                    <ModuleItem
                      key={module.id}
                      module={module}
                      chapterId={chapterId}
                      classroomChapterId={data.id}
                      dates={dates}
                      changeModuleDate={changeModuleDate}
                      classroomChapterDates={date}
                      enableDateField={!!(startDate && endDate)}
                    />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </ModuleContent>
      </Collapse>
    </ClassroomItemContainer>
  )
}

export default ClassroomItem
