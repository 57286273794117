import React from 'react'
import ContentsFormView from '../components/Contents/form/ContentsFormView'

const ContentsFormContainer = () => {
  return (
    <div>
      <ContentsFormView />
    </div>
  )
}

export default ContentsFormContainer
