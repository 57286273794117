import React, { useEffect } from 'react'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isString, isNumber } from 'lodash'
import SchemaContent from './SchemaContent'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { questionItemByIdFetchRequest } from '../../store/questions/actions'

type ContentParentProps = {
  id: number | string
}

const LabelInfo = styled('span')({
  fontWeight: 700 
})

const getFormatNameById = (id: number) => {
  return {
    1: 'video',
    2: 'audio',
    3: 'image',
    4: 'document',
    5: 'url',
    6: 'question',
    7: 'text',
    8: 'flv',
    9: 'etc',
    10: 'animation'
  }[id]
}

const ParentContent: React.FC<ContentParentProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  const { currentItem } = useAppSelector((state) => state.questions)

  useEffect(() => {
    dispatch(questionItemByIdFetchRequest(id))
  }, [])

  if (currentItem.isFetching) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      {currentItem
        && !currentItem.isFetching
        && currentItem.item
        && currentItem.item.content_parents
        && currentItem.item.content_parents.map(content => (
          <Box key={content.id} sx={{ border: '1px solid #d9d9d9', borderRadius: '4px', padding: '15px', mb: 2 }}>
            <Box>
              <Typography>
                <LabelInfo>Código:</LabelInfo> {content.id}
              </Typography>
            </Box>
            <Divider variant='middle' sx={{ marginBlock: 1 }} />
            <Box>
              <Typography>
                <LabelInfo>NOME:</LabelInfo> {content.name}
              </Typography>
              <Typography>
                <LabelInfo>DESCRIÇÃO:</LabelInfo> {content.description}
              </Typography>
            </Box>
            <Divider variant='middle' sx={{ marginBlock: 1 }} />
            {content.content_format && content.schema_content && (
              <SchemaContent
                format={
                  isNumber(content.content_format)
                    ? { code: getFormatNameById(content.content_format) }
                    : content.content_format
                }
                content={
                  isString(content.schema_content)
                    ? JSON.parse(content.schema_content)
                    : content.schema_content
                }
              />
            )}
          </Box>
        ))}
    </Box>
  )
}

export default ParentContent