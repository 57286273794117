// React
import React from 'react'

// Components
import { Box, Grid, Paper } from '@mui/material'
import BooksContainer from '../containers/BooksContainer'
import Button from '../components/button/Button'
import Label from '../components/label/Label'

// Utils
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
import ChartReportIcon from '../assets/components/Books/chart-report-icon.svg'

const HeaderContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  '& img': {
    marginRight: '8px'
  },

  '& .buttons_container': {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',

    '& .report_btn': {
      background: theme.palette.primary.light,
    }
  }
}))

const PaperContainer = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  marginTop: '24px',
  padding: '38px 27px 24px 46px',

  [theme.breakpoints.down('lg')]: {  // => max-width: 1240px
    padding: '57px 16px'
  }
}))

const BooksPage = () => {
  const navigate = useNavigate()

  const handleToBooksReports = () => {
    navigate('/books/reports')
  }

  const handleToCreateBookForm = () => {
    navigate('/books/form', { state: { isCreate: true } })
  }

  return (
    <div>
      <HeaderContainer>
        <Label>
          <img src={BookIcon} alt='ícone de livro' />
          Livros
        </Label>
        <Box className='buttons_container'>
          <Button
            type='button'
            variant='contained'
            onClick={handleToBooksReports}
            startIcon={<img src={ChartReportIcon} />}
            className='report_btn'
          >
            Relatório de livros
          </Button>

          <Button
            variant='contained'
            onClick={handleToCreateBookForm}
          >
            Criar Livro
          </Button>
        </Box>
      </HeaderContainer>

      <PaperContainer>
        <Grid
          container
          columnSpacing={{ xs: 2 }}
        >
          <BooksContainer />
        </Grid>
      </PaperContainer>
    </div>
  )
}

export default BooksPage
