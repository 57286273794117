import React, { useState } from 'react'

// Components
import { Box, Collapse, LinearProgress } from '@mui/material'

// Utils
import { format } from 'date-fns'
import { IBookChapter } from '../../../models/IBooks'

// Styles
import {
  ChapterName,
  Container,
  TableBody,
  TableHeader
} from './styles/ChapterReportCollapse.styles'

// Icons
import ChapterIcon from '../../../assets/components/Books/book-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface IChapterReportCollapseProps {
  chapter: IBookChapter
  numberOfChapter: number
}

const ChapterReportCollapse: React.FC<IChapterReportCollapseProps> = ({ chapter, numberOfChapter }) => {
  const [open, setOpen] = useState(false)

  const handleOpenChapter = () => {
    setOpen(prevState => !prevState)
  }

  return (
    <Container>
      <Box
        className='chapter_container'
        onClick={handleOpenChapter}
      >
        <Box className='chapter'>
          <img src={ChapterIcon} />
          <ChapterName>
            <Box
              component='span'
              className='chapter_number'
            >
              Capítulo {numberOfChapter} -
            </Box>

            <Box
              component='span'
              className='chapter_name'
            >
              {chapter.name}
            </Box>
          </ChapterName>
        </Box>

        <Box sx={{ textAlign: 'right' }}>
          <KeyboardArrowDownIcon
            fontSize='small'
            className='arrow_icon'
            sx={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </Box>
      </Box>

      <Collapse in={open}>
        <Box sx={{ padding: '0px 24px 32px' }}>
          <TableHeader>
            <Box className='thead_cell'>Módulos</Box>
            <Box className='thead_cell'>Início</Box>
            <Box className='thead_cell'>Fim</Box>
            <Box className='thead_cell'>Questões</Box>
            <Box className='thead_cell'>Progresso</Box>
            <Box className='thead_cell'>Performance</Box>
          </TableHeader>

          <TableBody>
            {chapter.content_modules.map((module, index) => {
              const startDate = module?.start_date ? format(new Date(module?.start_date), 'dd/MM/yyyy') : '--/--/----'
              const endDate = module?.end_date ? format(new Date(module?.end_date), 'dd/MM/yyyy') : '--/--/----'

              return (
                <Box
                  key={module.content_module.id}
                  className='table'
                >
                  <Box className='module'>
                    <Box component='span'>
                      Módulo {index + 1} -
                    </Box>
                    <Box component='span'>
                      {module.content_module.name}
                    </Box>
                  </Box>

                  <Box className='tbody_cell'>
                    {startDate}
                  </Box>

                  <Box className='tbody_cell'>{endDate}</Box>

                  <Box className='tbody_cell'>{module.content_module.questions_count}</Box>
                  <Box className='tbody_cell progress'>
                    <LinearProgress
                      color='secondary'
                      variant='determinate'
                      value={module.progress ?? 0}
                      sx={{ width: '40px' }}
                    />
                    {module.progress ?? 0}%
                  </Box>
                  <Box className='tbody_cell'>{module.performance}%</Box>
                </Box>
              )
            })}
          </TableBody>
        </Box>
      </Collapse>
    </Container>
  )
}

export default ChapterReportCollapse
