import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Assets
import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'
import { ReactComponent as DistractorIcon } from '../assets/components/activity/distrator-icon.svg'
import { ReactComponent as AverageScoreIcon } from '../assets/components/activity/averageScore-icon.svg'
import { ReactComponent as AverageTimeIcon } from '../assets/components/activity/averageTime-icon.svg'
import { ReactComponent as DownloadIcon } from '../assets/components/activity/download-icon.svg'

// Components
import { Paper, Box, LinearProgress, Typography, styled } from '@mui/material'
import Label from '../components/label/Label'
import Table, { Columns } from '../components/table/Table'
import Button from '../components/button/Button'
import DialogModal from '../components/Modal/DialogModal'

// Redux
import { activityItemByIdFetchRequest, activityReportFetchRequest } from '../store/activities/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/configureStore'

// Utils
import http from '../utils/http'

const BoxStyled = styled(Box)(() => ({
 '.MuiTable-root' : {
  borderSpacing: 0
 },
 '.MuiTableHead-root > tr th': {
  paddingBottom: '16px',
  textAlign: 'center'
 },
 '.MuiTableBody-root > tr td': {
  borderBottom: '1px dashed #D9D9D9'
 },
 '.MuiTableBody-root > tr:last-of-type td': {
  borderBottom: 'none'
 },
 '.MuiTableHead-root > tr > td': {
  borderRadius: 0,
 },
 '.MuiTableCell-root': {
  borderRadius: '0px !important',
 },
 '.MuiTableContainer-root': {
  borderRadius: 0
 },
 '.MuiTableBody-root': {
  borderRadius: '16px',
  overflow: 'hidden'
 },
 '.MuiTableBody-root > tr:first-of-type td:first-of-type': {
  borderTopLeftRadius: '16px !important'
 },
 '.MuiTableBody-root > tr:first-of-type td:last-of-type': {
  borderTopRightRadius: '16px !important'
 },
 '.MuiTableBody-root > tr:last-of-type td:first-of-type': {
  borderBottomLeftRadius: '16px !important'
 },
 '.MuiTableBody-root > tr:last-of-type td:last-of-type': {
  borderBottomRightRadius: '16px !important'
 },
 '.MuiTableRow-root > th': {
  textAlign: 'center',
 },
}))
export const BackButton = styled('a')(() => ({
  padding: '15px 40px',
  backgroundColor: '#412A88',
  color: '#fff',
  borderRadius: '8px',
  cursor: 'pointer',
  fontSize: '16px',
  ':hover':{
    backgroundColor: '#2B1961'
  },
  ':active':{
    backgroundColor: '#BC9BFA'
  }
 }))
 const ExportButton = styled('button')(() => ({
  padding: '15px 40px',
  backgroundColor: '#BC9BFA',
  color: '#fff',
  borderRadius: '8px',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  'svg': {
   marginRight: '8px'
  },
  ':hover':{
    backgroundColor: '#04AEC9'
  },
  ':active':{
    backgroundColor: '#60DFC8'
  }
 }))

const ActivityDetailsDistractorsTableContainer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // eslint-disable-next-line camelcase
  const { item, isFetching, activityReport } = useSelector((state: RootState) => state.activities)
  useEffect(() => {
    if (id) {
      dispatch(activityItemByIdFetchRequest(id))
      dispatch(activityReportFetchRequest({activityId: id}))
    }
  }, [])

  const questionsColumns: Columns[] = [
    {
      key: 'questions',
      name:<Typography component='p' color='#9F9F9F' fontWeight={500}>Questões</Typography>,
      width:'20%',
      render: (row: any) => {
        return <Typography component='p' color='#666' fontWeight={500} fontSize={'14px'} textAlign={'center'}>{row?.question}</Typography>
      }
    },
    {
      key: 'distractors',
      name:  <Box sx={{display: 'flex', alignItems: 'center', gap: 1, color: '#9F9F9F', fontWeight: 500, justifyContent: 'center'}}><DistractorIcon/>Distrator</Box>,
      render: (row: any) => {
        return <Box width={'100%'} sx={{display: 'flex', justifyContent: 'center'}}>
        <Typography component='p' color='#666' fontWeight={400} textAlign={'center'} sx={{color: '#fff', fontSize: '10px' ,backgroundColor: '#BC9BFA', padding: '7px 20px', borderRadius: '8px' }}>{row.distractor}</Typography>
      </Box>
      }
    },
    {
      key: 'rightAnswer',
      name: <Typography component='p' color='#9F9F9F' fontWeight={500}>Alternativa correta</Typography>,
      width:'20%',
      render: (row: any) => {
        return <Box width={'100%'} sx={{display: 'flex', justifyContent: 'center'}}>
          <Typography component='p' color='#666' fontWeight={400} textAlign={'center'} sx={{color: '#fff', fontSize: '10px' ,backgroundColor: '#04AEC9', padding: '7px 20px', borderRadius: '8px' }}>{row.correct}</Typography>
        </Box>
      }
    },
    {
      key: 'averageScore',
      name:<Box sx={{display: 'flex', alignItems: 'center', gap: 1, color: '#9F9F9F', fontWeight: 500, justifyContent: 'center'}}><AverageScoreIcon/>Nota média</Box>,
      width:'20%',
      render: (row: any) => {
        function removePercentage(stringWithPercentage: string) {
          return stringWithPercentage.replace('%', '')
        }
        const noPercentage = removePercentage(row.note_media)
        return (
        <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', fontWeight: 600, fontSize: '14px', color: '#666'}}>
          <LinearProgress
            sx={{width: '72px', marginRight: '8px'}}
            variant='determinate'
            value={Number(noPercentage)}
          />
            {row.note_media}
        </Box>
        )
      }
    },
    {
      key: 'averageTime',
      name: <Box sx={{display: 'flex', alignItems: 'center', gap: 1, color: '#9F9F9F', fontWeight: 500, justifyContent: 'center'}}><AverageTimeIcon/>Tempo médio</Box>,
      width:'20%',
      render: (row: any) => {
        return  <Typography component='p' color='#666' fontWeight={600} textAlign={'center'}>{row.average_time}</Typography>
      }
    }
  ]
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('Relatório em processamento, assim que concluído será enviado para seu email!')
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true)
    http.get(`https://staging.datagateway.fractaltecnologia.com.br/api/v1/activities/${id}/export_report_questions`)
      .catch((error) => {
        setConfirmationMessage('Erro: ' + error?.response?.data?.error_msg?.message)
      })
  }
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
  }

  return (
    <div>
      {
        isFetching ? (
          <Paper sx={{ borderRadius: '16px', background: '#FFFFFF', mt: 2, padding: '40px 24px 32px' }}>
            <LinearProgress sx={{ marginBottom: 2 }} color='secondary' />
          </Paper>) : (
        <>
          <Box>
        {
        isFetching ? null : (<Label sx={{ fontWeight: 500, margin: 0 }}>
          <IconTitleSVG />
          {item?.title} / Lista de alunos / {item.activity_type && item.activity_type === 'exam' ? 'Prova' : 'Lista de exercícios'}
        </Label>)
        }
        </Box>
        <Paper sx={{ borderRadius: '16px', background: '#FFFFFF', mt: 2, padding: '40px 24px 32px' }}>
        <BoxStyled>
          <Table columns={questionsColumns} data={activityReport} />
        </BoxStyled>
        </Paper>
        <Box sx={{paddingTop: '32px', display: 'flex', justifyContent: 'flex-end', gap: 2}}>
          <BackButton onClick={() => navigate(`/activities/${id}/view-activity-details`)}>Voltar</BackButton>
          <ExportButton onClick={() => handleOpenConfirmModal()}>
            <DownloadIcon/>
            Exportar dados
          </ExportButton>
          <DialogModal
            open={openConfirmModal}
            handleClose={handleCloseConfirmModal}
            >
            <Box  textAlign='center' pr={2} pl={2} width='400px'>
              <Label sx={{paddingBottom: '16px'}}>{confirmationMessage}</Label>
              <BoxStyled sx={{display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
                <Button onClick={() => handleCloseConfirmModal()}>Ok</Button>
              </BoxStyled>
            </Box>
          </DialogModal>
        </Box>
      </>
        )
      }

    </div>
  )
}

export default ActivityDetailsDistractorsTableContainer
