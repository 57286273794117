import { createReducer, createAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { withPayloadType } from '../../utils/functions'
import { IRole } from '../../models/IRole'
import { IUnit } from '../../models/IUnit'

export interface SelectedRolePayload {
  role: IRole
}

export interface SelectedUnitPayload {
  unit: IUnit
}

export const selectedUnitFetch = createAction('persistable/SELECTED_UNIT_FETCH', withPayloadType<IUnit | null>())
export const selectedRoleFetch = createAction('persistable/SELECTED_ROLE_FETCH', withPayloadType<SelectedRolePayload>())
export const clearUnits = createAction('persistable/CLEAR_UNITS')
export const clearRoles = createAction('persistable/CLEAR_ROLES')

interface StateType {
  selectedUnit: IUnit | null
  selectedRole: IRole | null
}

export const initialState:StateType = {
  selectedUnit: null,
  selectedRole: null
}

const persistableReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(selectedUnitFetch, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      selectedUnit: action.payload
    }))
    .addCase(clearUnits, (state: StateType): StateType => ({
      ...state,
      selectedUnit: null
    }))
    .addCase(selectedRoleFetch, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      selectedRole: action.payload
    }))
    .addCase(clearRoles, (state: StateType): StateType => ({
      ...state,
      selectedRole: null
    }))
})

export default persistableReducer