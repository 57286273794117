import http from '../../utils/http'

const fetchUnitsUrl = '/units'
const fetchUnitsWithClassroomsUrl = '/units/classrooms'

export const fetchUnits = () => {
  return http.get(fetchUnitsUrl)
}

export const fetchUnitsWithClassrooms = (params: object) => {
  return http.get(fetchUnitsWithClassroomsUrl, { params })
}
