import React from 'react'
import StudentFormEditContainer from '../containers/StudentFormEditContainer'

const ManageStudentEditPage = () => {
  return (
    <div>
      <StudentFormEditContainer />
    </div>
  )
}

export default ManageStudentEditPage
