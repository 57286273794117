import React from 'react'
import { useParams } from 'react-router-dom'
import ContentsFormContainer from '../containers/ContentsFormContainer'
import ContentsFormEditContainer from '../containers/ContentsFormEditContainer'


const ContentsFormPage = () => {
  const { id } = useParams()
  return (
    <div>
      {!id && <ContentsFormContainer />}
      {id && <ContentsFormEditContainer id={id} />}
    </div>
  )
}

export default ContentsFormPage


