import { Box } from '@mui/system'
import React from 'react'
import Label from '../../label/Label'
import { Skeleton } from '@mui/material'
import { ReactComponent as PeopleSVG } from '../../../assets/icons/people.svg'

const LoadingManageTeachers = () => {
  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '32px' }}>
          <Label sx={{ paddingBottom: '16px', display: 'flex', height: '40px' }}>
            <PeopleSVG />
            <Skeleton animation='wave' sx={{ width: '200px' }} />
          </Label>
          <Box>
            <Skeleton animation='wave' sx={{ width: '200px', height: '80px' }} />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', gap: '24px'
        }}>
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '70px', borderRadius: '8px' }} />
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '70px', borderRadius: '8px' }} />
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '70px', borderRadius: '8px' }} />
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '70px', borderRadius: '8px' }} />
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '70px', borderRadius: '8px' }} />
        </Box>
      </Box>
    </Box>
  )
}

export default LoadingManageTeachers
