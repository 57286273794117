import React from 'react'
import { useParams } from 'react-router-dom'
import BooksDetailsContainer from '../containers/BooksDetailsContainer'

const BooksDetailsPage = () => {
  const { id } = useParams()

  return (
    <>
      <BooksDetailsContainer id={Number(id)} />
    </>
  )
}

export default BooksDetailsPage
