import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { styled } from '@mui/material/styles'

import { IUnit } from '../../models/IUnit'
import SelectInput from '../select/SelectInput'
import { selectedUnitFetch } from '../../store/persistable'
import { fetchTeachersClassroomsRequest } from '../../store/teachersClassrooms/actions'

const SelectContainer = styled('div')(({ theme }) => ({
  '& > div > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiSelect-select': {
    color: theme.palette.text.primary,
    fontWeight: 500
  }
}))

export interface ISelectUnitsProps {
  units?: Array<IUnit>
  selectedUnit?: IUnit | null
}

function SelectUnits({ units, selectedUnit }: ISelectUnitsProps) {
  const [unitId, setUnitId] = useState<any>('')
  const options: Array<{ label: string, value: number }> = units ? units.map(unit => ({ label: unit.name, value: unit.id })) : []
  const dispatch = useDispatch()
  const hasUnits = units && units.length > 0

  const handleSelectUnit: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (hasUnits) {
      const findUnit: IUnit = units && units.find(unit => String(unit.id) === String(event.target.value)) || units[0]
      dispatch(selectedUnitFetch(findUnit))
    }
  }

  useEffect(() => {
    setUnitId(selectedUnit?.id ?? (units && units[0].id))
  }, [selectedUnit])

  useEffect(() => {
    hasUnits && dispatch(selectedUnitFetch(selectedUnit || units[0]))
    hasUnits && dispatch(fetchTeachersClassroomsRequest(selectedUnit?.id || units?.[0].id))
  }, [selectedUnit, units])

  return (
    <SelectContainer>
      <SelectInput
        defaultValue={unitId}
        name='units'
        onChange={handleSelectUnit}
        options={options}
        width='175px'
      />
    </SelectContainer>
  )
}

export default SelectUnits
