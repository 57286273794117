import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import {
  fetchExamsRequest,
  fetchExamsSuccess,
  fetchExamsFailure,
  fetchExamsQuestionsRequest,
  fetchExamsQuestionsSuccess,
  fetchExamsQuestionsFailure,
  importExamQuestionsRequest,
  importExamQuestionsSuccess,
  setExamQuestionsFilters,
  importExamQuestionsFailure,
  clearCurrentItem,
  examsResetFilters
} from './actions'

interface StateType {
  items: object[]
  isFetching: boolean
  pagination: object
  examQuestions: object
  importingExamId: any | null
  filters: object
  messageError: string | null
  isImporting: boolean
}

const initialState: StateType = {
  items: [],
  isFetching: false,
  messageError: null,
  pagination: {
    page: 1,
    total: 0,
    totalOfPages: 0,
    per: 10
  },
  examQuestions: {
    examId: null,
    items: [],
    isFetching: false,
    messageError: null
  },
  importingExamId: null,
  isImporting: false,
  filters: {}
}

const examReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchExamsRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      messageError: null,
      filters: {
        ...state.filters,
        ...action.payload
      }
    }))
    .addCase(fetchExamsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: action.payload.data,
      isFetching: false,
      pagination: {
        ...state.pagination,
        ...action.payload.pagination
      }
    }))
    .addCase(fetchExamsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      messageError: action.payload.error
    }))
    .addCase(fetchExamsQuestionsRequest, (state: StateType): StateType => ({
      ...state,
      examQuestions: {
        ...state.examQuestions,
        examId: null,
        isFetching: true,
        messageError: null
      }
    }))
    .addCase(fetchExamsQuestionsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      examQuestions: {
        ...state.examQuestions,
        examId: action.payload.id,
        isFetching: false,
        items: action.payload.data
      }
    }))
    .addCase(fetchExamsQuestionsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      examQuestions: {
        ...state.examQuestions,
        isFetching: false,
        messageError: action.payload.error
      }
    }))
    .addCase(importExamQuestionsRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      importingExamId: action.payload.id,
      isImporting: true
    }))
    .addCase(importExamQuestionsSuccess, (state: StateType): StateType => ({
      ...state,
      importingExamId: null,
      isImporting: false
    }))
    .addCase(importExamQuestionsFailure, (state: StateType): StateType => ({
      ...state,
      importingExamId: null,
      isImporting: false
    }))
    .addCase(setExamQuestionsFilters, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filters: action.payload
    }))
    .addCase(clearCurrentItem, (state: StateType): StateType => ({
      ...state,
      examQuestions: {
        ...initialState.examQuestions
      }
    }))
    .addCase(examsResetFilters, (state: StateType): StateType => ({
      ...state,
      filters: { ...initialState.filters }
    }))
})

export default examReducer