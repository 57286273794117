import React, { ReactNode } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

const DivContainer = styled('div')({
  marginBottom: 12,
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .MuiAccordion-root': {
    borderRadius: 8
  }
})

export default function CollapseTable({
  primaryText,
  secondaryText,
  tertiaryText,
  quaternaryText,
  details,
  maxWidth,
  minWidth
}: {
  primaryText: ReactNode,
  secondaryText: ReactNode,
  tertiaryText?: ReactNode,
  quaternaryText?: ReactNode,
  details: ReactNode,
  maxWidth?: string,
  minWidth?: string
},
) {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }
  return (
    <DivContainer>
      <Accordion
        expanded={(expanded === 'panel1')}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Box sx={{ maxWidth: maxWidth, minWidth: minWidth }}>
            {primaryText}
          </Box>
          <Box sx={{ mr: 1 }}>{secondaryText}</Box>
          {tertiaryText && <Box sx={{ mr: 1 }}>{tertiaryText}</Box>}
          {quaternaryText && <Box sx={{ mr: 1 }}>{quaternaryText}</Box>}
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {details}
          </Box>
        </AccordionDetails>
      </Accordion>
    </DivContainer>
  )
}
