import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import QuestionsForm from '../components/Questions/form/QuestionsForm'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { checkCurrentRole } from '../utils/functions'
import { questionItemByIdFetchRequest } from '../store/questions/actions'

interface QuestionsFormEditContainerProps {
  id: any
}

const QuestionsFormEditContainer: React.FC<QuestionsFormEditContainerProps> = ({ id }) => {
  const [questionFormValues, setQuestionFormValues] = useState({} as any)
  const dispatch = useAppDispatch()
  const {
    auth: { user },
    persistable: { selectedRole },
    questions: { currentItem }
  } = useAppSelector(state => state)

  useEffect(() => {
    id && dispatch(questionItemByIdFetchRequest(id))
  }, [])

  const answerType = currentItem.item?.answer_type === 'text_only' ? { value: currentItem.item.answer_type, name: 'Texto' }
    : currentItem.item?.answer_type === 'image_only' ? { value: currentItem.item.answer_type, name: 'Imagem' }
      : currentItem.item?.answer_type === 'video_only' ? { value: currentItem.item.answer_type, name: 'Vídeo' }
        : undefined

  const difficultyLevel = currentItem.item?.difficulty_level === 'very_easy' ? { value: 'very_easy', name: 'Muito Fácil' }
    : currentItem.item?.difficulty_level === 'easy' ? { value: 'easy', name: 'Fácil' }
      : currentItem.item?.difficulty_level === 'moderate' ? { value: 'moderate', name: 'Moderado' }
        : currentItem.item?.difficulty_level === 'difficult' ? { value: 'difficult', name: 'Difícil' }
          : currentItem.item?.difficulty_level === 'very_difficult' ? { value: 'very_difficult', name: 'Muito difícil' }
            : undefined

  let enemDifficulty

  switch (currentItem.item?.enem_difficulty_level) {
    case 'easier':
      enemDifficulty = { value: 'easier', name: '+ Fácil' }
      break
    case 'easy':
      enemDifficulty = { value: 'easy', name: 'Fácil' }
      break
    case 'less_easy':
      enemDifficulty = { value: 'less_easy', name: '- Fácil' }
      break
    case 'medium_easy':
      enemDifficulty = { value: 'medium_easy', name: 'Médio/Fácil' }
      break
    case 'medium':
      enemDifficulty = { value: 'medium', name: 'Médio' }
      break
    case 'medium_hard':
      enemDifficulty = { value: 'medium_hard', name: 'Médio/Difícil' }
      break
    case 'less_hard':
      enemDifficulty = { value: 'less_hard', name: '- Difícil' }
      break
    case 'hard':
      enemDifficulty = { value: 'hard', name: 'Difícil' }
      break
    case 'harder':
      enemDifficulty = { value: 'harder', name: '+ Difícil' }
      break
    default:
      enemDifficulty = undefined
      break
  }


  const questionType = currentItem.item?.question_type === 'discursive' ? { id: 1, name: 'Discursiva', value: 'discursive' }
    : currentItem.item?.question_type === 'only_choice' ? { id: 2, name: 'Objetiva', value: 'only_choice' } : undefined

  const questionOptions = currentItem.item?.question_options?.map(option => ({
    description: option.description,
    is_correct: option.is_correct
  }))

  const subjects = currentItem.item?.subjects?.map(subject => {
    const topicsValues = currentItem.item?.topics?.filter(topic => topic.subject.id === subject.id).map(topic => ({ id: topic.id, name: topic.name }))
    const competencesValues = currentItem.item?.skills?.filter(skill => skill.subject?.id === subject.id).find(competence => competence)
    const skillsValues = currentItem.item?.skills?.filter(skill => skill.subject?.id === subject.id).map(skill => ({ value: skill.id, label: skill.name }))

    return {
      id: subject.id,
      name: subject.name,
      knowledge_area: subject.knowledge_area,
      topics: topicsValues,
      competences: { id: competencesValues?.competence.id, name: competencesValues?.competence.name },
      skills: skillsValues
    }
  })

  const contentParents = currentItem.item?.content_parents?.map(content => content.id)

  const initialValues = {
    id: id,
    answer_type: answerType,
    difficulty_level: difficultyLevel,
    segment_ids: currentItem.item?.segments?.[0],
    question_type: questionType,
    subject_ids: subjects,
    statement: currentItem.item?.statement,
    resolution: currentItem.item?.resolution,
    question_options: questionOptions,
    enem_type: currentItem.item?.enem_type,
    polemic: currentItem.item?.polemic,
    enem_difficulty_level: enemDifficulty,
    use_universe_ids: currentItem.item?.use_universe,
    theme_ids: currentItem.item?.themes,
    content_parents: contentParents,
  }

  useEffect(() => {
    setQuestionFormValues({
      ...initialValues
    })
  }, [currentItem])

  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')
  return (
    <div>
      {currentItem.isFetching && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
          <CircularProgress />
        </Box>
      )}
      {!currentItem.isFetching && <QuestionsForm isTeacher={isTeacher} userId={user.id} initialValues={questionFormValues} />}
    </div>
  )
}

export default QuestionsFormEditContainer
