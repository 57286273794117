import React, { Fragment } from 'react'
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import Button from '../../../button/Button'
import SimpleEditor from '../../../Editor/SimpleEditor'
import CheckmarkInput from '../../../input/CheckmarkInput'
import CheckIcon from '../../../../assets/components/Questions/check-icon.svg'
import TrashIcon from '../../../../assets/components/Questions/trash-icon.svg'
import { required } from '../../../../utils/formUtils'

interface StepTwoProps {
  activeStep: number
  values: any
}

const Container = styled('div')(({
  '& .required': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '20px',
    textAlign: 'right',
  }
}))

const requiredField = 'Campo Obrigatório *'

const validateQuestionOptions = (values: any[]) => {
  const errors = {} as Record<string, string>

  if (!values || !values.length) {
    errors.question_options = 'A questão deve ter ao menos uma opção *'
  } else {
    let countCorrect = 0

    values.forEach((option, index) => {
      if (!option.description) {
        errors[`question_options[${index}].description`] = requiredField
      }

      if (option.is_correct) {
        countCorrect += 1
      }
    })

    if (countCorrect === 0) {
      errors.question_options = 'A questão deve ter ao menos uma opção correta *'
    } else if (countCorrect > 1) {
      errors.question_options = 'A questão deve ter apenas uma opção correta *'
    }
  }
  return errors
}

const ButtonIcon = styled('button')({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer'
})

const LabelEditor = styled('label')({
  color: '#412A88',
  fontSize: '18px',
  fontWeight: 500,
})

const ImgCheck = styled('img')({
  marginRight: 64
})


const StepTwo = ({ activeStep, values }: StepTwoProps) => {
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('md'))
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  if (activeStep !== 2) {
    return null
  }

  return (
    <Container>
      <Paper sx={{ padding: match ? '40px 8px' : '40px 32px', borderRadius: 4, mb: 4 }}>
        <Grid item xs={3}>
          <Box mb={4}>
            <label>Enunciado *</label>
            <Field
              component={SimpleEditor}
              name='statement'
              placeholder='Enunciado...'
              searchParam='name'
              minRows={9}
              validate={required}
            />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box mb={1}>
            <label>Resolução</label>
            <Field
              component={SimpleEditor}
              name='resolution'
              placeholder='Resolução...'
              minRows={9}
            />
          </Box>
        </Grid>
        {<Typography className='required'>* Campo obrigatório</Typography>}
      </Paper>
      {
        values && values.question_type?.id === 2 &&
        <Paper sx={{ padding: match ? '40px 8px' : '40px 32px', borderRadius: 4 }}>
          <FieldArray
            name='question_options'
            validate={values && values.question_type?.id === 2 ? validateQuestionOptions : undefined}
          >
            {({ fields }) => {
              return (
                <Fragment>
                  {fields.map((name, index) => (
                    <Fragment key={index}>
                      <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <LabelEditor> Alternativa {alphabet[index]} *</LabelEditor>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Field
                              name={`${name}.is_correct`}
                              component={CheckmarkInput}
                              sx={{ marginRight: '8px' }}
                              label='Alternativa correta'
                              type='checkbox'
                              value={values?.question_options[index]?.is_correct}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ImgCheck src={CheckIcon} alt='icone de check' />
                              <ButtonIcon onClick={() => fields.remove(index)}>
                                <img src={TrashIcon} alt='trash icon' />
                              </ButtonIcon>
                            </Box>
                          </Box>
                        </Box>
                        <Field
                          component={SimpleEditor}
                          name={`${name}.description`}
                          minRows={9}
                        />
                      </Box>
                      <Box sx={{ mb: 4 }}>
                        <label>Comentário da alternativa</label>
                        <Field
                          component={SimpleEditor}
                          name={`${name}.comment`}
                          placeholder='Comentario 1...'
                          minRows={9}
                        />
                      </Box>
                    </Fragment>
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                    <Button
                      variant='contained'
                      onClick={() => fields.push({})}
                      disableElevation
                      disabled={values && values?.question_type && values?.question_type?.value === 'discursive'}
                    >
                      Adicionar alternativa
                    </Button>
                  </Box>
                </Fragment>
              )
            }}
          </FieldArray>
          {<Typography className='required'>* Campo obrigatório</Typography>}
        </Paper>
      }
    </Container>
  )
}

export default StepTwo
