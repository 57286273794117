import http from '../../utils/http'
import { RequestParams } from '../../utils/types'
const activitiesEndpoint = '/activities'
const activityParticipants = '/activity_participants'
const usersEndpoint = '/users'


export const fetchActivities = ({ id, params }: { id: number | string, params: any }) => {
  return http.get(`${activitiesEndpoint}?show_drafts=true&user_role_id=${id}`, {
    params
  })
}

export const saveActivity = (params: object) => http.post(activitiesEndpoint, params)

export const updateActivity = (params: any) => {
  return http.put(`${activitiesEndpoint}/${params.id}`, { ...params.requestParams })
}

export const fetchActivityItemById = ({ id, params }: { id: number, params: any }) => http.get(`${activitiesEndpoint}/${id}`, params)

export const fetchActivityParticipantStatus = ({ id }: { id: number }) => http.get(`${activitiesEndpoint}/${id}/participant_status`)

export const fetchActivityQuestionDetailsList = (params: any) => {
  return http.get(`${activitiesEndpoint}/${params?.id}/complete_questions_details_list`, {
    params: {
      school_classroom_ids: params?.school_classroom_ids
    }
  })
}

export const archiveActivity = (id: number) => {
  return http.post(`${activitiesEndpoint}/${id}/archive`)
}

export const fetchUsers = (params: RequestParams) => {
  const queryParams = new URLSearchParams()
  if (params) {
    const { filters } = params
    Object.keys(filters).forEach((param) => {
      queryParams.append(param, filters[param])
    })
  }
  return http.get(`${usersEndpoint}?${queryParams}`)
}

export const studentActivities = ({ params }: { params: any }) => {
  return http.get(`${activitiesEndpoint}/${params.id}/overview`, { params })
}

export const fetchActivityParticipantsDetails = (id: number) => {
      return http.get(`${activityParticipants}/${id}/details`)
}

export const reopenActivity = (params: any) => {
  return http.put(`${activityParticipants}/${params.id}/reopen_activity`, {...params})
}

export const fetchActivityReport = ({activityId} : { activityId: number}) => {
  return http.get(`${activitiesEndpoint}/${activityId}/report_questions?year=all`)
}

export const requestActivityDownloadFile = (payload: string | number) => {
  return http.get(`${activitiesEndpoint}/${payload}/print_activity?resource=questions&file_type=docx`)
}

export const downloadActivityFile = (payload: string | number) => {
  return http.get(`${activitiesEndpoint}/${payload}/activity_file`, {responseType: 'blob'})
}

export const generateActivityReport = ({ id, body }: any) => {
  return http.post(`${activitiesEndpoint}/${id}/generate_reports`, body)
}
