import React from 'react'
import ContentsForm from './ContentsForm'

const ContentsFormView = () => {
  return (
 <div>
  <ContentsForm isTeacher={undefined} />
 </div>
  )
}

export default ContentsFormView
