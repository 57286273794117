import * as React from 'react'
import Typography from '@mui/material/Typography'

import styles from './styles.module.scss'

export default function Label({ children, sx }: { children: React.ReactNode, sx?: React.CSSProperties }) {
  return (
    <Typography variant='h5' gutterBottom component='h2' className={styles.titleImg} sx={sx}>
      {children}
    </Typography>
  )
}
