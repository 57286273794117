import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import {
  contentsItemFetchRequest,
  contentsItemFetchSuccess,
  contentsItemFetchFailure,
  schoolContentsItemsFetchRequest,
  schoolContentsItemsFetchSuccessful,
  schoolContentsItemsFetchFailure,
  contentsItemFetchByIdRequest,
  contentsItemFetchByIdSuccess,
  contentsItemFetchByIdFailure,
  contentItemCreateRequest,
  contentItemCreateSuccess,
  contentItemCreateFailure,
  contentsChangePagination,
  contentItemEditRequest,
  contentItemEditSuccess,
  contentItemEditFailure,
  clearCurrentContent,
  contentsResetFilter,
  contentsSaveUiFilter,
  setContentSelected
} from './actions'
import { ContentValue, IContent } from '../../models/IContent'

export type Filter = {
  query: any,
  ui: any
}

interface StateType {
  items: IContent[]
  selectedContents: { byId: { [key: string | number]: any }, allIds: (string | number)[] }
  pagination: object
  currentItem: {
    item: IContent,
    isFetching: boolean,
    isError: boolean,
    messageError: string
  }
  isFetching: boolean
  isError: boolean
  isArchiving: boolean
  isSuccess: boolean
  isSavingOrUpdating: boolean
  messageError: string | null
  filters: Filter
}

const initialState: StateType = {
  items: [],
  pagination: {
    per: 1,
    total: 0,
    results: 10,
    current: 1,
    totalPages: 0
  },
  currentItem: {
    item: {...ContentValue},
    isFetching: false,
    isError: false,
    messageError: ''
  },
  isFetching: false,
  isError: false,
  isArchiving: false,
  isSuccess: false,
  isSavingOrUpdating: false,
  messageError: '',
  filters: {
    query: {},
    ui: {
      checked: false,
      subject_ids: null,
      competence_ids: null,
      skill_ids: null,
      knowledge_area_ids: null,
      use_universe_ids: null,
      terms: null,
      ids: null,
      segment_ids: null,
      format_ids: null,
      age_range_ids: null,
      theme_ids: null,
      variation_ids: null,
      author_ids: null,
    }
  },
  selectedContents: {
    byId: {},
    allIds: []
  }
}

const contentsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(contentsItemFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: false,
      messageError: '',
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(contentsItemFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      messageError: '',
      items: action.payload,
    }))
    .addCase(contentsItemFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      messageError: action.payload
    }))
    .addCase(schoolContentsItemsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(schoolContentsItemsFetchSuccessful, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isSuccess: false,
      items: action.payload
    }))
    .addCase(schoolContentsItemsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: [],
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(contentsItemFetchByIdRequest, (state: StateType): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: ''
      }
    }))
    .addCase(contentsItemFetchByIdSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: ''
      }
    }))
    .addCase(contentsItemFetchByIdFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(contentItemCreateRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: true,
      isSavingOrUpdating: true,
      messageError: ''
    }))
    .addCase(contentItemCreateSuccess, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      isSuccess: true,
      isSavingOrUpdating: false,
      messageError: ''
    }))
    .addCase(contentItemCreateFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      isSavingOrUpdating: false,
      messageError: action.payload
    }))
    .addCase(contentItemEditRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      isArchiving: false,
      isSavingOrUpdating: true,
      messageError: '',
    }))
    .addCase(contentItemEditSuccess, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      isArchiving: false,
      isSuccess: true,
      isSavingOrUpdating: false,
      messageError: '',
    }))
    .addCase(contentItemEditFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      isArchiving: false,
      isSavingOrUpdating: false,
      messageError: action.payload,
    }))
    .addCase(contentsChangePagination, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalPages: action.payload.totalOfPages,
        current: action.payload.current,
        per: action.payload.per
      }
    }))
    .addCase(clearCurrentContent, (state: StateType): StateType => ({
      ...state,
      currentItem: { ...initialState.currentItem }
    }))
    .addCase(contentsResetFilter, (state: StateType) => ({
      ...state,
      filters: { ...initialState.filters }
    }))
    .addCase(contentsSaveUiFilter, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filters: {
        ...state.filters,
        ui: {
          ...state.filters.ui,
          ...action.payload
        }
      }
    }))
    .addCase(setContentSelected, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isError: false,
      messageError: null,
      selectedContents: action.payload
    }))
})

export default contentsReducer
