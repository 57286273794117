import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { activityItemByIdFetchRequest, activityReopenRequest } from '../store/activities/actions'
import { useAppSelector } from '../store/hooks'

// Assets
import { ReactComponent as ReloadIcon } from '../assets/components/activity/reload-icon.svg'
import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'

// Components
import { Box } from '@mui/system'
import Label from '../components/label/Label'
import { Field, Form } from 'react-final-form'
import CustomButton from '../components/button/Button'
import { LinearProgress, Paper, Typography } from '@mui/material'
import DatePickerInput from '../components/input/DatePickerInput'

// Utills
import { styled, useTheme } from '@mui/material/styles'
import { required } from '../utils/formUtils'
import { formatDistance } from 'date-fns'
import { toast } from 'react-toastify'


const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '22px',
  '& button': {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    height: '22px',
    justifyContent: 'center',
    padding: 0,
    width: '22px',
    '&:hover': {
      '& svg': {
        '& path': {
          stroke: theme.palette.primary.light
        }
      }
    }
  },
  '& .dateActivities': {
    width: 'fit-content',
  },
  '& .MuiInputBase-input': {
    minWidth: '259px'
  }
}))

const CalendarContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '32px',
  paddingTop: '24px',
  paddingBottom: '40px',
}))

const ActivityReopenContainer = () => {
  const [formValues, setFormValues] = useState<any>()
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const { item, isFetching, } = useAppSelector((state) => state.activities)
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const theme = useTheme()
  const now = new Date()

  const handleStartChanges = (startValue: any) => {
    if (endDate && formatDistance(startValue, endDate, { includeSeconds: true })) {
      setEndDate(null)
    } else {
      setStartDate(startValue)
    }
  }
  const handleEndChanges = (endValue: any) => {
    setEndDate(endValue)
  }

  const filterPassedTime = (time: any) => {
    const selectedDate = String(startDate)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime && time > selectedDateTime
  }

  const handleClearDates = (change: any) => {
    setStartDate(null)
    setEndDate(null)
    change('start_date', undefined)
    change('end_date', undefined)
  }

  useEffect(() => {
    if (id) {
      dispatch(activityItemByIdFetchRequest(id))
    }
  }, [])

  const onSubmitForm = (values: any) => {
    dispatch(activityReopenRequest({ id: state.studentId, ...values, redirect: navigate }))
  }

  const notifyEndDateError = () => toast.error('Data e hora final não podem ser anterior a data e hora atual.')
  const notifyStartDateError = () => toast.error('Data e hora inicial não podem ser anterior a data e hora atual.')

  useEffect(() => {
    if (formValues?.end_date < now) {
      notifyEndDateError()
    }
    if (formValues?.start_date < now) {
      notifyStartDateError()
    }
  }, [formValues])


  return (
    <div>
      {
        isFetching ? (
          <Paper sx={{ borderRadius: '16px', background: '#FFFFFF', mt: 2, padding: '40px 24px 32px' }}>
            <LinearProgress sx={{ marginBottom: 2 }} color='secondary' />
          </Paper>) : (
          <>
            <Box>
              {
                !isFetching && (<Label sx={{ fontWeight: 500, margin: 0 }}>
                  <IconTitleSVG />
                  {item?.title} / Reabrir atividade
                </Label>)
              }
            </Box>
            <Form
              onSubmit={onSubmitForm}
              render={({ handleSubmit, form, values }) => {
                setFormValues(values)
                return (
                  <form onSubmit={handleSubmit}>
                    <Paper sx={{ borderRadius: '16px', background: '#FFFFFF', mt: 2, padding: '40px 24px 32px' }}>
                      <Box>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: '16px', fontWeight: 600 }} component='h2'>Remarcar data de entrega para <span style={{ color: theme.palette.primary.light }}>{state.studentName}</span></Typography>
                      </Box>
                      <Container>
                        <div className='dateActivities'>
                          <CalendarContainer>
                            <Field
                              name='start_date'
                              component={DatePickerInput}
                              placeholder='00/00/00'
                              label='Data e hora de início'
                              marginBottom='4px'
                              autoComplete='off'
                              validate={required}
                              required
                              showTimeSelect
                              locale='pt-BR'
                              timeIntervals={5}
                              timeCaption='Hora'
                              dateFormat='dd/MM/yyyy HH:mm'
                              startDate={startDate}
                              endDate={endDate}
                              selectsStart
                              changeDate={handleStartChanges}
                              minDate={new Date()}
                              filterTime={(time: any) => {
                                return time.getTime() >= now.getTime()
                              }}
                            />
                            <Field
                              name='end_date'
                              component={DatePickerInput}
                              placeholder='00/00/00'
                              label='Data e hora de fim'
                              marginBottom='4px'
                              autoComplete='off'
                              validate={required}
                              required
                              showTimeSelect
                              locale='pt-BR'
                              timeIntervals={5}
                              timeCaption='Hora'
                              dateFormat='dd/MM/yyyy HH:mm'
                              startDate={endDate}
                              endDate={endDate}
                              minDate={startDate}
                              changeDate={handleEndChanges}
                              filterTime={filterPassedTime}
                              disabled={!values?.start_date}
                            />
                          </CalendarContainer>
                        </div>
                        <button type='button' onClick={() => handleClearDates(form.change)}>
                          <ReloadIcon />
                        </button>
                      </Container>
                    </Paper>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '32px' }}>
                      <CustomButton variant='outlined' onClick={() => navigate(-1)}>
                        Voltar
                      </CustomButton>
                      <CustomButton
                        disabled={
                          values.end_date < now || !values.end_date
                        }
                        variant='contained'
                        type='submit'>
                        Atualizar
                      </CustomButton>
                    </Box>
                  </form>
                )
              }}
            />
          </>
        )
      }

    </div>
  )
}

export default ActivityReopenContainer
