import React, { useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Assets
import BarCodeIcon from '../../assets/components/Questions/barCode-icon.svg'
import LinkIcon from '@mui/icons-material/Link'
import TagIcon from '../../assets/components/Questions/tag-icon.svg'
import FileImage from '../../assets/components/Content/file.svg'
import { ReactComponent as CheckFilledIcon } from '../../assets/components/Questions/check-filled-icon.svg'
import { ReactComponent as DoubleRightCheckIcon } from '../../assets/icons/double-right-check-icon.svg'

// Components
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import Button from '../button/Button'
import VideoEmbed from '../Contents/ContentPLayer'
import { stringAvatar } from '../../utils/functions'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store/configureStore'

// Styles
import { styled, useTheme } from '@mui/material/styles'

const BoxHeader = styled(Box)(({ theme }) => ({
  padding: '24px',
  fontSize: '14px',
  background: '#F9F9F9',
  borderRadius: '16px 16px 0px 0px',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& img': {
      paddingRight: 10,
    },
    '& .MuiChip-root': {
      height: 20,
      marginBottom: 0,
      marginLeft: 8,
    },
    '& .info': {
      color: theme.palette.primary.light,
      fontWeight: 700,
      marginLeft: 8,
    },
  },
}))

const ButtonDiscursiveQuestion = styled('button')(({ theme }) => ({
  border: 'none',
  background: theme.palette.primary.main,
  padding: '15px 24px',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: '#F9F9F9',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '19px',
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
  '&:hover': {
    background: '#2B1961'
  },
  '&:active': {
    background: theme.palette.primary.light
  }
}))

const BoxContent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '32px',
  '& .image': {
    height: '88px',
    margin: '8px auto',
    textAlign: 'center',
    width: '88px',
    '& img': {
      height: '100%',
      width: '100%'
    }
  },
  '& .link': {
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%',
    '& span': {
      color: theme.palette.text.primary,
      fontWeight: 500
    },
    '& a': {
      color: theme.palette.text.primary,
      fontWeight: 500,
      marginLeft: '8px',
      textDecoration: 'underline',
      transition: 'color 0.3s ease',
      '&:hover': {
        color: theme.palette.primary.light,
      }
    },
    '& span:nth-of-type(2)': {
      display: 'block',
      fontWeight: 700,
      margin: '8px 0',
    }
  }
}))

const BoxValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& label': {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  '& input': {
    height: '40px',
    width: '87px',
    border: ' 1px solid #B7B2C8',
    borderRadius: 8,
    outline: 'none',
    textAlign: 'center',
    color: '#9F9F9F',
    fontFamily: 'Montserrat',
  },
  '& .link:hover': {
    color: '#664CAA !important',
  }
}))

const BoxFooter = styled(Box)(({ theme }) => ({
  padding: '24px',
  fontSize: '14px',
  background: '#F5F5F5',
  borderRadius: '0px 0px 16px 16px',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& img': {
      paddingRight: 10,
    },
    '& .MuiChip-root': {
      height: 20,
      marginBottom: 0,
      marginLeft: 8,
    },
    '& .info': {
      color: theme.palette.primary.light,
      fontWeight: 700,
      marginLeft: 8,
    },
  },
}))

const LinearProgressBox = styled(Box)(({ theme }) => ({
  width: '72px',
  display: 'grid',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const ChipStyled = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  color: theme.palette.text.secondary,
}))

interface QuestionCardDetailsProps {
  extractedDiscursiveQuestions?: any
  data: any
  activityType?: string
  questionDistractorPercentage?: any
}

const QuestionCardDetails: React.FC<QuestionCardDetailsProps> = ({ data, activityType, questionDistractorPercentage, extractedDiscursiveQuestions }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { id } = useParams()
  const { auth } = useSelector((state: RootState) => state)
  const { activities } = useSelector((state: RootState) => state)
  const [toggleSeeMore, setToggleSeeMore] = useState(false)
  const [toggleSeeAnswer, setToggleSeeAnswer] = useState(false)
  const [toggleSeeParentContent, setToggleSeeParentContent] = useState(false)
  const resolutionSectionRef = useRef<HTMLDivElement | null>(null)
  const parentContentSectionRef = useRef<HTMLDivElement | null>(null)
  const question = data?.content?.question
  const activityUserIdOwner = activities?.item?.created_by_user?.id
  const userLoggedId = auth?.user?.id

  const handleSeeMoreToggleButton = () => {
    if (toggleSeeMore) {
      setToggleSeeMore(false)
    } else {
      setToggleSeeMore(true)
    }
  }
  const handleSeeAnswerToggleButton = () => {
    if (toggleSeeAnswer) {
      setToggleSeeAnswer(false)
    } else {
      setToggleSeeAnswer(true)
      resolutionSectionRef.current && resolutionSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleSeeParentContentToggleButton = () => {
    if (toggleSeeParentContent) {
      setToggleSeeParentContent(false)
    } else {
      setToggleSeeParentContent(true)
      parentContentSectionRef.current && parentContentSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const getQuestionOptionLetter = (index: string | number) => {
    const letters: any = ['A', 'B', 'C', 'D', 'E']
    return letters[index] || ''
  }

  const isOnlyChoice = data?.content?.question?.question_type === 'only_choice'

  // const needFeedback = questionDistractorPercentage && questionDistractorPercentage[0]?.content?.need_correction > 0

  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: '#f9f9f9'
      }}>
      <BoxHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <img src={BarCodeIcon} alt='ícone do código da questão' />
            <Typography
              component='p'
              fontSize={'14px'}
              fontWeight={500}
            >
              Código:
            </Typography>
            <span className='info'>{data.content.schema_content ? data.content.id : data?.content?.question?.id}</span>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {
              question?.content_parents.length > 0 &&
              <Button
                type='button'
                sx={{
                  background: `${toggleSeeParentContent ? '#B7B2C8' : '#fff'}`,
                  borderColor: '#B7B2C8',
                  color: `${toggleSeeParentContent ? '#fff' : '#B7B2C8'}`,
                  fontWeight: 500,
                  padding: '24px',
                  width: 'fit-content',
                }}
                variant='outlined'
                startIcon={
                  <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM8 3C6.067 3 4.5 4.567 4.5 6.5C4.5 8.433 6.067 10 8 10C9.933 10 11.5 8.433 11.5 6.5C11.5 4.567 9.933 3 8 3ZM5.5 6.5C5.5 5.11929 6.61929 4 8 4C9.38071 4 10.5 5.11929 10.5 6.5C10.5 7.88071 9.38071 9 8 9C6.61929 9 5.5 7.88071 5.5 6.5Z'
                      fill={`${toggleSeeParentContent ? '#fff' : '#9F9F9F'}`}
                    />
                  </svg>
                }
                onClick={handleSeeParentContentToggleButton}
              >
                Material de apoio
              </Button>
            }
            {
              data.content.schema_content === null && data?.content?.question?.resolution !== null &&
              <Button
                type='button'
                sx={{
                  width: '186px',
                  background: `${toggleSeeAnswer ? '#B7B2C8' : '#fff'}`,
                  padding: '24px',
                  color: `${toggleSeeAnswer ? '#fff' : '#B7B2C8'}`,
                  borderColor: '#B7B2C8',
                  fontWeight: 500,
                }}
                variant='outlined'
                startIcon={
                  <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM8 3C6.067 3 4.5 4.567 4.5 6.5C4.5 8.433 6.067 10 8 10C9.933 10 11.5 8.433 11.5 6.5C11.5 4.567 9.933 3 8 3ZM5.5 6.5C5.5 5.11929 6.61929 4 8 4C9.38071 4 10.5 5.11929 10.5 6.5C10.5 7.88071 9.38071 9 8 9C6.61929 9 5.5 7.88071 5.5 6.5Z'
                      fill={`${toggleSeeAnswer ? '#fff' : '#9F9F9F'}`}
                    />
                  </svg>
                }
                onClick={handleSeeAnswerToggleButton}
              >
                Ver resolução
              </Button>
            }
            {
              !data.content.schema_content && activityType === 'exam' &&
              <BoxValue>
                <label>Valor da questão:</label>
                <input
                  type='text'
                  placeholder='0.00 pts'
                  readOnly
                  value={data.value_item === null ? '' : data.value_item + '0 pts'}
                />
              </BoxValue>
            }
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          {
            data.content.schema_content === null &&
            <Box
              sx={{
                display: 'flex',
                margin: '0 -24px',
                padding: '16px',
                width: '100%',
              }}
            >
              <img src={TagIcon} alt='ícone das tags da questão' style={{ alignSelf: 'baseline' }} />
              <Typography
                component='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 500
                }}
              >
                Tags:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {data?.content?.question?.concourse_exams?.map((item: any, index: any) => {
                  return item?.concourse ? (
                    <Chip
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px',
                        borderColor: theme.palette.primary.light,
                        color: theme.palette.primary.light,
                      }}
                      color='primary'
                      key={index}
                      label={item?.concourse?.name}
                      variant='outlined'
                    />
                  ) : item?.concourse_exam ? (
                    <Chip
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px',
                        borderColor: theme.palette.primary.light,
                        color: theme.palette.primary.light,
                      }}
                      color='primary'
                      key={index}
                      label={item?.concourse_exam?.concourse?.name + ` - ${item?.concourse_exam?.year_of_application}`}
                      variant='outlined'
                    />
                  ) : null
                })}
                <div>
                  {[
                    { value: 'only_choice', label: 'Objetiva' },
                    { value: 'discursive', label: 'Discursiva' },
                  ].map(
                    (item, index) =>
                      item.value === data?.content?.question?.question_type && (
                        <ChipStyled
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            padding: '4px 8px'
                          }}
                          key={index}
                          label={item?.label}
                          variant='outlined'
                        />
                      ),
                  )}
                  {[
                    { value: 'text_only', label: 'Texto' },
                    { value: 'image_only', label: 'Imagem' },
                    { value: 'video_only', label: 'Video' },
                  ].map(
                    (item, index) =>
                      item.value === data?.content?.question?.answer_type && (
                        <ChipStyled
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            padding: '4px 8px'
                          }}
                          key={index}
                          label={item?.label}
                          variant='outlined'
                        />
                      ),
                  )}
                  {data?.content?.question?.segments?.map((item: { name: string }, index: number) => (
                    <ChipStyled
                      sx={{ fontSize: '14px', fontWeight: 500, padding: '4px 8px' }}
                      key={index}
                      label={item?.name}
                      variant='outlined'
                    />
                  ))}
                  {data?.content?.question?.subjects?.map((item: any, index: number) => (
                    <ChipStyled
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px'
                      }}
                      key={index}
                      label={item?.name}
                      variant='outlined'
                    />
                  ))}
                </div>
                <div>
                  {data?.content?.question?.topics?.map((item: any, index: number) => (
                    <ChipStyled
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '4px 8px'
                      }}
                      key={index}
                      label={item?.complete_name}
                      variant='outlined'
                    />
                  ))}
                </div>
              </Box>
            </Box>
          }

          {data.content.schema_content &&
            <Box>
              <Box
                paddingBottom={'8px'}
                paddingTop={'8px'}
              >
                <Typography
                  component='span'
                  fontSize={'14px'}
                  fontWeight={500}
                >
                  Nome:
                </Typography>
                <span className='info'>{data.content.name}</span>
              </Box>
              <Typography component='span' sx={{ fontSize: '14px', fontWeight: 500 }}>Descrição: </Typography>
              <span className='info'>{data.content.description}</span>
            </Box>
          }
        </Box>
      </BoxHeader>
      <Divider sx={{ borderBottom: '1px dashed #9F9F9F' }} />
      <Box>
        {data.content.schema_content ? (
          data.content.content_format.code === 'text' ? (
            <div
              style={{
                marginTop: '24px',
                overflow: 'hidden',
                padding: '24px'
              }}
              dangerouslySetInnerHTML={{ __html: data.content.schema_content?.value?.value ? data.content.schema_content?.value?.value : data.content.schema_content?.value }}
            />
          )
            : data.content.content_format.code === 'document' ? (
              <BoxContent>
                <div className='image' style={{ overflow: 'hidden' }}>
                  <img src={FileImage} alt='ícone de arquivo' width='100%' />
                </div>
                <div className='link'>
                  <span>Link:</span>
                  <a target='_blank' href={data.content.schema_content?.value?.value ?? data.content.schema_content?.value} rel='noreferrer'>
                    {data.content.schema_content?.value?.value ?? data.content.schema_content?.value}
                  </a>
                  {data.content.schema_content?.size && (
                    <span>Tamanho: {(Number(data?.content?.schema_content?.size) / 10 ** 6).toFixed(2)} MB</span>
                  )}
                </div>
              </BoxContent>
            )
              : data.content.content_format.code === 'image' ? (
                <BoxContent>
                  <img width={500} src={data?.content?.schema_content?.value} alt='imagem do conteudo' />
                </BoxContent>
              )
                : data.content.content_format.code === 'audio' ? (
                  <BoxContent>
                    <audio controls>
                      <source src={data?.content?.schema_content?.value} type='audio/ogg' />
                      <source src={data?.content?.schema_content?.value} type='audio/mpeg' />
                    </audio>
                  </BoxContent>
                )
                  : data.content.content_format.code === 'video' ? (
                    <Box
                      sx={{
                        marginInline: 'auto',
                        marginTop: '24px',
                        maxWidth: '500px',
                      }}>
                      <VideoEmbed urlVideo={data?.content?.schema_content?.value} />
                    </Box>
                  ) : data.content.content_format.code === 'url' ? (
                    <BoxContent>
                      <div className='image'>
                        <div style={{
                          alignItems: 'center',
                          background: '#F6F8F9',
                          borderRadius: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          height: '100%',
                          width: '100%',
                        }}>
                          <LinkIcon sx={{ fill: '#9f9f9f', fontSize: 60 }} />
                        </div>
                      </div>
                      <div className='link'>
                        <span>Link:</span>
                        <a target='_blank' href={data?.content.schema_content?.value?.value ?? data?.content.schema_content?.value} rel='noreferrer'>
                          {data?.content.schema_content?.value?.value ?? data?.content.schema_content?.value}
                        </a>
                      </div>
                    </BoxContent>
                  ) : null
        ) : (
          <Typography
            component='p'
            sx={{ margin: '32px 24px 24px 40px', fontWeight: 500 }}
            dangerouslySetInnerHTML={{ __html: data?.content?.question?.statement }}
          />
        )}

      </Box>
      <Box sx={{ margin: '24px 0px 37px 20px' }}>
        {data?.content?.question?.question_options.map(
          (qo: { id: React.Key | null | undefined; description: any; is_correct: boolean }, index: string | number) => (
            <Box
              key={qo.id}
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '15px',
                  marginRight: '4px',
                  width: '15px',
                }}
              >
                {qo?.is_correct && <CheckFilledIcon />}
              </Box>
              <Typography
                fontWeight={500}
                marginRight={1}
                component='span'
              >
                {getQuestionOptionLetter(index)})
              </Typography>
              <Typography
                fontWeight={500}
                dangerouslySetInnerHTML={{ __html: qo?.description }}
              />
            </Box>
          ),
        )}
      </Box>
      {
        question?.content_parents.length > 0 &&
        <Collapse
          in={toggleSeeParentContent}
          timeout={500}
        >
          <Box
            ref={parentContentSectionRef}
            sx={{
              border: ' 1px dashed #9F9F9F',
              borderBottom: 'none',
              padding: '32px 24px 24px 40px',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.3rem',
                fontWeight: 500,
                marginBottom: '32px',
              }}
              component='p'>
              Material de Apoio:
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                paddingBottom: '16px',
              }}>
              <img src={BarCodeIcon} alt='ícone do código da questão' />
              <Typography
                component='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  paddingInline: '5px'
                }}
              >
                Código:
              </Typography>
              <Typography
                component='p'
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: 700
                }}
              >
                {question?.content_parents[0]?.id}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  paddingRight: '8px',
                  paddingBottom: '16px'
                }}>
                Nome:
              </Typography>
              <Typography
                component='p'
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: 700
                }}>
                {question?.content_parents[0]?.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  paddingRight: '8px',
                  paddingBottom: '32px'
                }}
              >
                Descrição:
              </Typography>
              <Typography
                component='p'
                sx={{
                  color: theme.palette.primary.light,
                  fontWeight: 700
                }}>
                {question?.content_parents[0]?.description}
              </Typography>
            </Box>
            {question?.content_parents[0]?.content_format === 5 &&
              <BoxContent>
                <div className='image'>
                  <div style={{
                    alignItems: 'center',
                    background: '#F6F8F9',
                    borderRadius: '100%',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                    <LinkIcon sx={{ fill: '#9f9f9f', fontSize: 60 }} />
                  </div>
                </div>
                <div className='link'>
                  <span>Link:</span>
                  <a target='_blank' href={question?.content_parents[0]?.schema_content?.value?.value ?? question?.content_parents[0]?.schema_content?.value} rel='noreferrer'>
                    {question?.content_parents[0]?.schema_content?.value?.value ?? question?.content_parents[0]?.schema_content?.value}
                  </a>
                </div>
              </BoxContent>
            }
            {question?.content_parents[0]?.content_format === 4 &&
              <BoxContent>
                <div className='image' style={{ overflow: 'hidden' }}>
                  <img src={FileImage} alt='ícone de arquivo' width='100%' />
                </div>
                <div className='link'>
                  <span>Link:</span>
                  <a target='_blank' href={question?.content_parents[0]?.schema_content?.value} rel='noreferrer'>
                    {question?.content_parents[0]?.schema_content?.value}
                  </a>
                  {question?.content_parents[0]?.schema_content?.size
                    ? <span>Tamanho: {(Number(question?.content_parents[0]?.schema_content?.size) / 10 ** 6).toFixed(2)} MB</span>
                    : <span>Tamanho: {(Number(question?.content_parents[0]?.schema_content?.size) / 10 ** 6).toFixed(2)} MB</span>}
                </div>
              </BoxContent>
            }
            {question?.content_parents[0]?.content_format === 3 &&
              <Box>
                <BoxValue>
                  <img width={500} src={question?.content_parents[0]?.schema_content?.value} alt='imagem do conteudo' />
                  <a className='link' style={{ color: theme.palette.primary.light, fontWeight: 700, transition: '.2s' }} target='_blank' href={question?.content_parents[0]?.schema_content?.value} rel='noreferrer'>
                    Ampliar
                  </a>
                </BoxValue>
              </Box>
            }
            {question?.content_parents[0]?.content_format === 1 &&
              <Box sx={{ marginInline: 'auto', maxWidth: '500px' }}>
                <VideoEmbed urlVideo={question?.content_parents[0]?.schema_content?.value} />
              </Box>
            }
            {
              question?.content_parents[0]?.content_format === 7 && <Typography
                sx={{ fontWeight: 500 }}
                dangerouslySetInnerHTML={{ __html: question?.content_parents[0]?.schema_content?.value }}
              />
            }
          </Box>
        </Collapse>
      }
      <Collapse in={toggleSeeAnswer} timeout={500} >
        <Box ref={resolutionSectionRef} sx={{ padding: '32px 24px 24px 40px', border: ' 1px dashed #9F9F9F', borderBottom: 'none' }}>
          <Typography
            sx={{
              marginBottom: '32px',
              fontSize: '1.3rem',
              fontWeight: 500
            }}
            component='p'>
            Resolução:
          </Typography>
          <Typography
            fontWeight={500}
            dangerouslySetInnerHTML={{ __html: data?.content?.question?.resolution }}
          />
        </Box>
      </Collapse>
      <Divider sx={{ borderBottom: '1px dashed #9F9F9F' }} />
      <BoxFooter>
        <Box
          sx={{
            display: 'flex',
            justifyContent: question?.question_type === 'discursive' ? 'flex-end' : 'space-between',
            width: '100%'
          }}>
          {
            question?.question_type !== 'discursive' ?
              <Box display={'flex'} gap={'32px'}>
                {questionDistractorPercentage && questionDistractorPercentage[0]?.content?.options?.map((percent: any, index: any) => {
                  const distractor = Number(percent.porcentage && percent?.porcentage)
                  return (
                    <Box
                      key={percent.id}
                      sx={{
                        display: 'flex',
                        gap: '7px',
                        alignItems: 'center'
                      }}>
                      <Typography
                        sx={{
                          color: '#666',
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                        component='p'>
                        {getQuestionOptionLetter(index) + ')'}
                      </Typography>
                      <LinearProgressBox>
                        <LinearProgress
                          sx={{
                            backgroundColor: theme.palette.text.secondary,
                            '.MuiLinearProgress-bar': { backgroundColor: percent?.is_distractor ? theme.palette.primary.light : theme.palette.primary.main }
                          }}
                          variant='determinate'
                          value={Number(percent.porcentage && percent?.porcentage)}
                        />
                      </LinearProgressBox>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 600,
                          color: '#666'
                        }}
                        component='p'>
                        {distractor.toFixed(1) + '%'}
                      </Typography>
                    </Box>
                  )
                })}
              </Box> : ''
          }
          {
            !data.content.schema_content &&
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '16px'
              }}
            >
              <Box>

              </Box>
              {isOnlyChoice && <Button
                type='button'
                sx={{
                  width: '186px',
                  background: `${toggleSeeMore ? '#B7B2C8' : '#fff'}`,
                  padding: '24px',
                  color: `${toggleSeeMore ? '#fff' : '#B7B2C8'}`,
                  borderColor: '#B7B2C8',
                  fontWeight: 500,
                }}
                variant='outlined'
                startIcon={
                  <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M1.25909 6.60214C1.94254 3.32689 4.79437 1 8.00002 1C11.2057 1 14.0574 3.32688 14.7409 6.60215C14.7974 6.87246 15.0622 7.04587 15.3325 6.98946C15.6029 6.93304 15.7763 6.66817 15.7199 6.39785C14.9425 2.67312 11.6934 0 8.00002 0C4.3066 0 1.05742 2.67311 0.280175 6.39786C0.223767 6.66818 0.397177 6.93305 0.667497 6.98946C0.937817 7.04587 1.20268 6.87246 1.25909 6.60214ZM8 3C6.067 3 4.5 4.567 4.5 6.5C4.5 8.433 6.067 10 8 10C9.933 10 11.5 8.433 11.5 6.5C11.5 4.567 9.933 3 8 3ZM5.5 6.5C5.5 5.11929 6.61929 4 8 4C9.38071 4 10.5 5.11929 10.5 6.5C10.5 7.88071 9.38071 9 8 9C6.61929 9 5.5 7.88071 5.5 6.5Z'
                      fill={`${toggleSeeMore ? '#fff' : '#9F9F9F'}`}
                    />
                  </svg>
                }
                onClick={handleSeeMoreToggleButton}
              >
                {toggleSeeMore ? 'Ver Menos' : 'Ver Mais'}
              </Button>}
              {(!isOnlyChoice && (activityUserIdOwner === userLoggedId)) && <ButtonDiscursiveQuestion
                onClick={() => {

                  return navigate(`/activities/${id}/view-discursive-questions`, { state: { questionData: data, discursiveQuestion: { extractedDiscursiveQuestions } } })
                }}
              >
                <DoubleRightCheckIcon />
                {!isOnlyChoice ? 'Corrigir' : 'Ver correção'}
              </ButtonDiscursiveQuestion>}
            </Box>
          }
        </Box>
        <Collapse in={toggleSeeMore} timeout={500}>
          <Grid
            container
            columns={16}
            sx={{
              display: 'flex',
              marginBottom: '48px',
              marginTop: '24px',
              width: '100%',
            }}
          >
            <Grid item xs={8}>
              <Typography fontWeight={500} component='p'>
                Acertaram:
              </Typography>
              {questionDistractorPercentage && !questionDistractorPercentage[0]?.content?.students?.right ? (
                <Typography
                  sx={{
                    color: '#9F9F9F',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '120%',
                    mt: 2
                  }}
                  component='p'
                >
                  Nenhum estudante acertou a questão.
                </Typography>
              ) : (
                <Box sx={{ mt: 2 }}>
                  {questionDistractorPercentage && questionDistractorPercentage[0]?.content?.students?.right?.map((student: any, index: any) => (
                    <Box
                      key={index}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mt: 2,
                        mb: 2
                      }}>
                      <Avatar {...stringAvatar(student?.user?.name)} variant='rounded' />
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '120%'
                          }}
                          component='p'
                        >
                          {student.user.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#9F9F9F',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '120%'
                          }}
                          component='p'
                        >
                          {student?.school_classroom?.name}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 500 }} component='p'>
                Erraram:
              </Typography>
              {questionDistractorPercentage && !questionDistractorPercentage[0]?.content?.students?.wrong ? (
                <Typography
                  sx={{ color: '#9F9F9F', fontSize: '14px', fontWeight: 400, lineHeight: '120%', mt: 2 }}
                  component='p'
                >
                  Nenhum estudante errou a questão.
                </Typography>
              ) : (
                <Box sx={{ mt: 2 }}>
                  {questionDistractorPercentage && questionDistractorPercentage[0]?.content?.students?.wrong?.map((student: any, index: any) => (
                    <Box
                      key={index}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mt: 2,
                        mb: 2
                      }}>
                      <Avatar {...stringAvatar(student?.user?.name)} variant='rounded' />
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '120%'
                          }}
                          component='p'
                        >
                          {student.user.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#9F9F9F',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '120%'
                          }}
                          component='p'
                        >
                          {student?.school_classroom?.name}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </Collapse>
      </BoxFooter>
    </Box>
  )
}

export default QuestionCardDetails
