import React, { useEffect, useState } from 'react'
import { Box, LinearProgress, Paper, Typography, Pagination } from '@mui/material'
import Label from '../components/label/Label'
import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'
import SelectInput from '../components/select/SelectInput'
import QuestionCardDetails from '../components/Questions/QuestionCardDetails'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/configureStore'
import { activityItemByIdFetchRequest } from '../store/activities/actions'
import { clearSelectedQuestions } from '../store/questions/actions'
import { scrollToTop } from '../utils/functions'
// import ButtonWithEyeIcon from '../components/Activities/ButtonWithEyeIcon'
import Button from '../components/button/Button'

const ActivityDetailsContainer = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const { item, isFetching } = useSelector((state: RootState) => state.activities)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [questions, setQuestions] = useState<any[]>([])
  const [questionsPerPage, setQuestionsPerPage] = useState(5)

  const numberOfQuestions = item?.modules?.[0]?.module?.content_module_items?.length ?? 1
  const questionsArray = item?.modules?.[0]?.module?.content_module_items ?? []

  const slicer = (items: any[], max: number) => {
    return items.reduce((acc, item, index) => {
      const group = Math.floor(index / max)
      acc[group] = [...(acc[group] || []), item]
      return acc
    }, [])
  }
  const handleChangePerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setQuestionsPerPage(Number(e.target.value))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    scrollToTop()
  }

  useEffect(() => {
    if (id) dispatch(activityItemByIdFetchRequest(id))
    scrollToTop()

    return () => {
      dispatch(clearSelectedQuestions())
    }
  }, [])

  useEffect(() => {
    const arrayOfQuestions = slicer(questionsArray, questionsPerPage)
    setQuestions(arrayOfQuestions[page - 1])
    setTotalPages(arrayOfQuestions.length)
  }, [item, questionsPerPage, page])

  const handleNavigation = () => {
    if (state) {
      navigate('/activities')
    } else {
      navigate(`/activities/${id}/view`)
    }
  }

  function extractDiscursiveQuestionsIds(modules: []) {

    const discursiveQuestionsIds = modules?.filter((module: any) => module.content.question_type === 'discursive')
      .map((module: any) => {
        // eslint-disable-next-line camelcase
        const { id, content_module_id, content_module_item_id, question_value } = module.content
        // eslint-disable-next-line camelcase
        return { id, content_module_id, content_module_item_id, question_value }
      })

    return discursiveQuestionsIds
  }

  const modules = item?.questions?.modules

  const extractedDiscursiveQuestions = extractDiscursiveQuestionsIds(modules)

  return (
    <Box>{
      isFetching ? null : (<Label sx={{ fontWeight: 500 }}>
        <IconTitleSVG />
        {item?.title} / Lista de alunos / {item.activity_type && item.activity_type === 'exam' ? 'Prova' : 'Lista de exercícios'}
      </Label>)
    }
    {
      isFetching && <LinearProgress sx={{ marginBottom: 2 }} color='secondary' />
    }
    {
      !isFetching && <Paper sx={{ borderRadius: '16px', background: '#FFFFFF', mt: 2, padding: '40px 24px 32px', }}>
      {isFetching ? '' : (<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

        <Typography component='p' variant='body2' sx={{ fontWeight: 500, fontSize: '14px' }}>{numberOfQuestions} {(numberOfQuestions > 1) ? 'questões listadas' : 'questão listada'}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography component='p'>Exibir:</Typography>
            <SelectInput
              name='selectPage'
              defaultValue={questionsPerPage}
              onChange={handleChangePerPage}
              width='150px'
              options={[
                { label: '1 por página', value: 1 },
                { label: '5 por página', value: 5 },
                { label: '10 por página', value: 10 },
                { label: '15 por página', value: 15 },
              ]}
            />
          </Box>
          {/* <ButtonWithEyeIcon onClick={() => navigate(`/activities/${id}/view-activity-distractors`)} text='Ver relatório' /> */}
        </Box>
      </Box>)}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {
          questions && questions.map((question: any) => {
            const id = question?.content?.question?.id
            return <QuestionCardDetails
              key={question?.content?.id}
              data={question}
              questionDistractorPercentage={item?.questions?.modules.filter((module: any) => module.content.id === id)}
              activityType={item?.activity_type}
              extractedDiscursiveQuestions={extractedDiscursiveQuestions}
            />
          })
        }
      </Box>
      <Box sx={{ mt: 4 }}>
        {!isFetching && totalPages > 1 && <Pagination
          count={totalPages}
          size='small'
          color='primary'
          page={page}
          onChange={handleChangePage}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        />}
      </Box>
    </Paper>
    }

      {!isFetching && (<Box display='flex' justifyContent='flex-end' mt={4}>
        <Button variant='contained' onClick={handleNavigation}>Voltar</Button>
      </Box>)}
    </Box>
  )
}

export default ActivityDetailsContainer
