import { createReducer } from '@reduxjs/toolkit'
import { JsonFormat, JsonResponseFormat } from '../../utils/types'

import { IUser, UserValue } from '../../models/IUser'
import { IRole } from '../../models/IRole'
import * as actions from './actions'

export type RolesJson = JsonFormat<'roles', IRole>
export type UserJson = JsonFormat<'users', IUser>
export type GetRoleResponse = JsonResponseFormat<UserJson[], (RolesJson)[]>

export interface UpdateCredentialsSuccessPayload {
  credentials: object
}

export interface GetRolesSuccessPayload {
  roles: IRole[]
}

export interface GetUserSuccessPayload {
  user: IUser
}


interface StateType {
  user: IUser
  credentials: object
  isLogged: boolean
  isFetching: boolean
  isError: boolean
  messageError: string | null
  roles: IRole[]
}

const initialState: StateType = {
  user: {
    ...UserValue,
    genre: ''
  },
  credentials: {},
  isLogged: false,
  isFetching: false,
  isError: false,
  messageError: '',
  roles: []
}

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getUserDataRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.getUserDataSuccess, (state: StateType, action: { payload: IUser }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      user: action.payload,
    }))
    .addCase(actions.getUserDataRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(actions.getRoleRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.getRoleSuccess, (state: StateType, action): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      roles: action.payload,
    }))
    .addCase(actions.getRoleRejected, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(
      actions.signOutRequest, (): StateType => ({
        ...initialState
      })
    )
    .addCase(actions.updateCredentialsSuccess, (state: StateType, action: { payload: UpdateCredentialsSuccessPayload }): StateType => ({
      ...state,
      isFetching: false,
      isLogged: true,
      isError: false,
      messageError: '',
      credentials: action.payload.credentials
    }))
})

export default authReducer
