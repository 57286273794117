import * as React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

interface ButtonStyled extends ButtonProps {
  variant?: 'contained' | 'text' | 'outlined' | undefined
  border?: string | null
  textColor?: string | null
  fontWeight?: number | null
}

export const ButtonLightStyled = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.light,
  borderRadius: 8,
  color: theme.palette.primary.light,
  height: 48,
  textTransform: 'inherit',
  '& svg > path': {
    fill: theme.palette.primary.light
  },
  '&:hover': {
    borderColor: theme.palette.primary.light
  }
}))

const ButtonStyled = styled(Button)({
  borderRadius: 8,
  fontSize: 16,
  fontWeight: 400,
  height: 52,
  padding: '16px 48px',
  textTransform: 'none',
  '&:active': {
    cursor: 'pointer',
    backgroundColor: '#BC9BFA'
  }
})

const CustomButton = ({ children, variant = 'contained', fontWeight, border, textColor, ...rest }: ButtonStyled) => {
  return (
    <ButtonStyled
      sx={{
        border: border,
        color: textColor,
        fontWeight: fontWeight
      }}
      variant={variant}
      {...rest}
    >
      {children}
    </ButtonStyled>
  )
}

export default CustomButton
