import React, { useEffect, useState } from 'react'

// Components
import { Box, IconButton, Skeleton, Typography } from '@mui/material'
import ProgressBarChart from '../charts/ProgressBarChart'
import SimpleSelect from '../select/SimpleSelect'
import ErrorMessage from '../message/ErrorMessage'

// Utils
import { IPerformanceComparisonData } from '../../models/IBooks'

// Styles
import {
  BoxFilter,
  BoxTitle,
  ChartContainer,
  Container,
  FiltersContainer
} from './styles/ProgressChartView.styles'

// Icons and images
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import BookIcon from '../../assets/components/Books/book-icon.svg'
import BagIcon from '../../assets/components/Books/bag-icon.svg'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'

interface IOption {
  id: number
  name: string
}

interface ISchoolOption extends IOption {
  classrooms?: { id: number, name: string }[]
  grades?: { id: number, name: string }[]
}

type IChartName = 'progress' | 'performance' | 'all'

interface IProgressChartViewProps {
  isFetching: boolean
  isError: boolean
  data: IPerformanceComparisonData[]
  handleGetData: (chartName: IChartName, params?: object) => void
}

const ProgressChartView: React.FC<IProgressChartViewProps> = ({
  isFetching,
  isError,
  data,
  handleGetData
}) => {
  const [school, setSchool] = useState<ISchoolOption | null>(null)
  const [schoolOptions, setSchoolOptions] = useState<ISchoolOption[]>([])
  const [grade, setGrade] = useState<IOption | null>(null)
  const [gradeOptions, setGradeOptions] = useState<IOption[]>([])
  const [chartData, setChartData] = useState<any>([])
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  // This function creates pagination in the data
  const paginateData = () => {
    const newData = data?.[0]?.book_chapters?.map((_, index) => {
      const result: any = { name: `Cap. ${index + 1}` }
      data.forEach(school => {
        const progress = school?.book_chapters?.[index]?.progress
        const numberOfModules = school?.book_chapters?.[index]?.modules?.length
        result[school.school_classroom.name] = progress
        result[`${school.school_classroom.name} - modules`] = numberOfModules
        result[`${school.school_classroom.name} - id`] = school.school_classroom.id
      })

      return result
    })

    if (newData && newData.length > 10) {
      setTotalPages(Math.ceil(newData.length / 10))
      const startIndex = (currentPage - 1) * 10
      const endIndex = startIndex + 10
      const newChartData = newData.slice(startIndex, endIndex)
      setChartData(newChartData)
    } else {
      setChartData(newData)
    }
  }

  // Loads school options in the schoolOptions state.
  const loadSchoolOptions = () => {
    const schoolOptionsFiltered = data.reduce((acc: any, item) => {
      const schoolIndex = acc.findIndex((school: any) => school.id === item.school_classroom.school_unit.id)

      if (schoolIndex !== -1) {
        acc?.[schoolIndex]?.classrooms?.push({
          id: item.school_classroom.id,
          name: item.school_classroom.name
        })
        acc?.[schoolIndex]?.grades?.push({
          id: item.school_classroom.grade.id,
          name: item.school_classroom.grade.name
        })
      } else {
        acc.push({
          id: item.school_classroom.school_unit.id,
          name: item.school_classroom.school_unit.name,
          classrooms: [{
            id: item.school_classroom.id,
            name: item.school_classroom.name
          }],
          grades: [{
            id: item.school_classroom.grade.id,
            name: item.school_classroom.grade.name
          }]
        })
      }
      return acc
    }, [])

    // remove duplicate items
    schoolOptionsFiltered.forEach((item: any) => {
      if (!schoolOptions.some((school) => school.id === item.id)) {
        setSchoolOptions(prevState => [...prevState, item])
      }
    })
  }

  // Loads grade options in the gradeOptions state
  const loadGradeOptions = () => {
    const gradeOptionsFiltered = data.reduce((acc: IOption[], item) => {
      const grade = item.school_classroom.grade
      if (!acc.some((i: any) => i?.id === grade.id)) {
        acc.push({ id: grade.id, name: grade.name })
      }
      return acc
    }, [])

    // remove duplicate items
    gradeOptionsFiltered.forEach((item) => {
      if (!gradeOptions.some((grade) => grade.id === item.id)) {
        setGradeOptions(prevState => [...prevState, item])
      }
    })
  }

  useEffect(() => {
    paginateData()
  }, [data, currentPage])

  useEffect(() => {
    loadSchoolOptions()
    loadGradeOptions()
  }, [data])

  // Filter schools
  const handleSchoolFilter = (event: ISchoolOption) => {
    setSchool(event)
    if (!event) {
      loadGradeOptions()
      handleGetData('progress', { school_unit_ids: null })
    } else {
      handleGetData('progress', { school_unit_ids: String(event?.id) })
      setGradeOptions(event.grades as IOption[])
    }
  }

  // Filter grades
  const handleGradeFilter = (event: any) => {
    setGrade(event)
    if (!event) {
      handleGetData('progress', { education_grade_ids: null })
      loadSchoolOptions()
    } else {
      const school = schoolOptions.filter((item) => item.grades?.some(grade => grade.id === event.id))
      setSchoolOptions(school)
      handleGetData('progress', { education_grade_ids: String(event?.id) })
    }
  }

  const handleNextPage = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1)
    } else {
      setCurrentPage(prevState => prevState + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage === 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(prevState => prevState - 1)
    }
  }

  return (
    <Container>
      <FiltersContainer>
        <BoxTitle>
          <img src={BookIcon} />
          <Typography className='title'>
            Comparativo de engajamento
          </Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SimpleSelect
            className='school'
            options={schoolOptions}
            value={school}
            onChange={handleSchoolFilter}
            placeholder='Escola...'
            isClearable
          />
        </BoxFilter>

        <BoxFilter>
          <img src={BagIcon} />
          <SimpleSelect
            className='classroom'
            options={gradeOptions}
            value={grade}
            onChange={handleGradeFilter}
            placeholder='Série...'
            isClearable
          />
        </BoxFilter>
      </FiltersContainer>

      {isFetching && (
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '300px', width: '100%' }}
        />
      )}

      {!isFetching && !isError && (
        <Box>
          <ChartContainer>
            {totalPages > 1 && (
              <IconButton
                type='button'
                onClick={handlePreviousPage}
                className='btn btn_previous'
                disabled={currentPage === 1}
              >
                <ArrowBackIosIcon className='icon' />
              </IconButton>
            )}

            <ProgressBarChart
              label={data}
              chartData={chartData}
            />

            {totalPages > 1 && (
              <IconButton
                type='button'
                onClick={handleNextPage}
                className='btn btn_next'
                disabled={currentPage === totalPages}
              >
                <ArrowForwardIosIcon className='icon' />
              </IconButton>
            )}
          </ChartContainer>
        </Box>
      )}

      {isError && (
        <ErrorMessage />
      )}
    </Container>
  )
}

export default ProgressChartView
