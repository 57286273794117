import React from 'react'

import ManageStudentsContainer from '../containers/ManageStudentsContainer'

const ManageStudentsPage = () => {
  return (
    <div>
      <ManageStudentsContainer />
    </div>
  )
}

export default ManageStudentsPage
