import React, { useEffect, useState } from 'react'
import { Box, Collapse, IconButton, LinearProgress, Typography } from '@mui/material'
import SchoolViewCollapse from './SchoolViewCollapse'
import { styled, useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { fetchClassroomsRequest } from '../../../store/books/actions'
import { compareDates } from '../../../utils/functions'
import { IClassroomChapters, Module } from '../../../models/IBooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChapterIcon from '../../../assets/components/Books/chapter-icon.svg'
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'

const ChapterContainer = styled(Box)(() => ({
  border: '1px dashed #b7b2c8',
  borderRadius: '8px'
}))

const ChapterTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  marginLeft: '8px',

  '& span': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    lineHeight: '150%'
  },

  '& span:first-of-type': {
    fontWeight: 700
  },

  '& span:last-of-type': {
    fontWeight: 500
  }
}))

const ModuleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#efe7ff',
  borderRadius: '8px',
  display: 'flex',
  gap: '24px',
  padding: '16px',

  '& .book_code': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& p': {
      fontSize: '14px',
      fontWeight: 600,

      '&:first-of-type': {
        color: theme.palette.text.primary,
      },

      '&:last-of-type': {
        color: theme.palette.primary.light
      }
    }
  },

  '& .book_name': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& p': {
      fontSize: '14px',
      fontWeight: 600,

      '&:first-of-type': {
        color: theme.palette.primary.main,
      },

      '&:last-of-type': {
        color: theme.palette.text.primary
      }
    }
  }
}))

const Status = styled(Box)(({ theme }) => ({
  paddingTop: '6px',

  '& span': {
    borderRadius: '50%',
    display: 'block',
    height: '14px',
    width: '14px',
  },

  '.finished': {
    background: theme.palette.secondary.main
  },

  '.pending': {
    background: theme.palette.error.main
  },

  '.in_progress': {
    background: theme.palette.primary.main
  },

  '.waiting': {
    background: theme.palette.primary.light
  }
}))

interface IChapterViewCollapseProps {
  chapterData: {
    id: number
    name: string
    description: string
    chapter_order: number
    number: string
    classroom_chapters?: IClassroomChapters[]
    modules?: Module[]
  }
}

const ChapterViewCollapse: React.FC<IChapterViewCollapseProps> = ({ chapterData }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { classrooms: { isFetching } } = useAppSelector(state => state.books)

  const schoolsFiltered = chapterData?.classroom_chapters?.map((item) => {
    return item.school_classroom.school_unit
  }).filter((item: any, index: number, self: any) => (
    index === self.findIndex((t: any) => t.id === item.id)
  ))

  const schools = schoolsFiltered?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      numberOfClassrooms: chapterData?.classroom_chapters?.filter((i) => i.school_classroom.school_unit.id === item.id)?.length,
      schoolData: chapterData?.classroom_chapters?.filter((x) => x.school_classroom.school_unit.id === item.id)
    }
  })

  const beforeDate = chapterData?.classroom_chapters?.some((item) => (
    item.start_date && compareDates(format(new Date(item.start_date), 'dd/MM/yyyy'))
  ))

  const betweenDate = chapterData?.classroom_chapters?.some((item) => (
    !compareDates(format(new Date(item.start_date), 'dd/MM/yyyy')) && compareDates(format(new Date(item.end_date), 'dd/MM/yyyy'))
  ))

  const afterDate = chapterData?.classroom_chapters?.some((item) => (
    item.end_date && !compareDates(format(new Date(item.end_date), 'dd/MM/yyyy'))
  ))

  const status = beforeDate
    ? 'waiting'
    : betweenDate
      ? 'in_progress'
      : afterDate
        ? 'finished'
        : 'pending'

  useEffect(() => {
    const schoolIds = schools?.map((school) => school.id)
    schoolIds && schoolIds.length > 0 && dispatch(fetchClassroomsRequest({ school_unit_ids: schoolIds }))
  }, [])

  return (
    <ChapterContainer>
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          gap: '16px',
          padding: '22px 16px'
        }}
      >
        <Status>
          <span className={status} />
        </Status>
        <Box>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Box
              onClick={() => setOpen(prevState => !prevState)}
              sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }}
            >
              <img src={ChapterIcon} width='24px' height='24px' />
              <ChapterTitle>
                <span>Capítulo {chapterData.chapter_order} -</span>
                <span>{chapterData.name}</span>
              </ChapterTitle>
            </Box>

            <Box sx={{ flex: 1, textAlign: 'right' }}>
              <IconButton
                onClick={() => setOpen(prevState => !prevState)}
                sx={{ width: '18px', height: '18px' }}
              >
                <KeyboardArrowDownIcon
                  fontSize='small'
                  sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              </IconButton>
            </Box>
          </Box>

          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '150%',
              marginTop: '16px'
            }}
          >
            {chapterData.description}
          </Typography>
        </Box>
      </Box>

      <Collapse in={open}>
        <Box sx={{ borderBottom: '1px dashed #b7b2c8' }} />
        {(isFetching) ? (
          <Box
            sx={{ paddingInline: '16px', marginBlock: '16px' }}
          >
            <LinearProgress
              sx={{ height: '3px' }}
              color='secondary'
              variant='indeterminate'
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
              margin: '32px 45px'
            }}
          >
            {schools && schools.length > 0
              ? (
                schools.map((school) => {
                  return (
                    <SchoolViewCollapse
                      key={school.id}
                      school={school}
                    />
                  )
                })
              ) : (
                <Box>
                  <Box>
                    <Typography
                      component='h4'
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '150%',
                        marginBottom: '8px'
                      }}
                    >
                      Módulos
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {(chapterData.modules && chapterData.modules.length > 0)
                      ? (
                        chapterData.modules.map((module) => {
                          return (
                            <ModuleContainer key={module.id}>
                              <Box className='book_code'>
                                <img src={BarCodeIcon} />
                                <Typography>Código</Typography>
                                <Typography>{module.id}</Typography>
                              </Box>
                              <Box className='book_name'>
                                <Typography>Nome:</Typography>
                                <Typography>{module.name}</Typography>
                              </Box>
                            </ModuleContainer>
                          )
                        })
                      ) : (
                        <Box sx={{ marginTop: '8px' }}>
                          <Typography
                            component='h2'
                            sx={{
                              color: '#9f9f9f',
                              fontSize: '18px',
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                          >
                            Não há módulos neste capítulo!
                          </Typography>
                        </Box>
                      )}
                  </Box>
                </Box>
              )}
          </Box>
        )}
      </Collapse>
    </ChapterContainer>
  )
}

export default ChapterViewCollapse
