import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'
import { cpfFormatter } from '../../utils/functions'

const AutocompleteStyled = styled(Autocomplete)({
  background: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  alignContent: 'center',
  height: '40px',
  width: '100%',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    display: 'flex',
    alignContent: 'center',
    height: '40px',
    width: '100%',
    '& fieldset': {
      borderColor: '#D9D9D9 !important',
    }
  },
  '.Mui-disabled': {
    background: '#F0F3F4'
  }
})

type AutoCompleteSelectProps = FieldRenderProps<string, HTMLElement> & {
  options: string[]
  placeholder: string
  disableClearable: boolean
  setTaxpayerNumber: (value: string) => void
  id: string
}

const AutoCompleteSelect: React.FC<AutoCompleteSelectProps> = ({
  input,
  options,
  placeholder,
  disableClearable,
  setTaxpayerNumber,
  id,
  disabled
}) => {

  const handleInputChange = (event: any, newValue: any) => {
    const regex = /^\d{0,11}$/
    const cleanedValue = newValue.replace(/\D/g, '')
    if (regex.test(cleanedValue)) {
      const formattedCpf = cpfFormatter(cleanedValue)
      input.onChange(formattedCpf)
      setTaxpayerNumber(formattedCpf)
    }
  }

  // Remove duplicate options
  const uniqueOptions = Array.from(new Set(options))

  // Remove "null" options
  const newOptions = uniqueOptions.filter((option) => !!option)

  return (
    <>
      <AutocompleteStyled
        freeSolo
        id={id}
        disabled={disabled}
        disableClearable={disableClearable}
        value={input.value}
        onChange={(event, newValue) => handleInputChange(event, newValue)}
        inputValue={input.value}
        onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
        options={newOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              inputProps: {
                ...params.inputProps,
                maxLength: 11,
                pattern: '[\\d\\.\\-]*',
              }
            }}
          />
        )}
      />
    </>
  )
}

export default AutoCompleteSelect
