import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

export const ShiftContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  width: '100px'
}))
