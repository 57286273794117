/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { ReactComponent as ActivityIcon } from '../../assets/components/activity/activity-icon.svg'

// Components
import { Divider, LinearProgress, Typography, Grid, Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

// Utils
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { IActivities } from '../../models/IActivities'
import { truncateText } from '../../utils/functions'

// Redux
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { activitiesItemsFetchRequest, activitiesResetFilters } from '../../store/activities/actions'

// Styles
import './calendar-styles.scss'
import { styled, useTheme } from '@mui/material/styles'

export default function CalendarWithEvents() {
  const theme = useTheme()

  const StatusColor = styled('span')(({ color }) => ({
    alignItems: 'center',
    background: color,
    borderRadius: '50%',
    display: 'flex',
    height: '7px',
    width: '7px',
  }))

  const StatusContainer = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',
    gap: '4px'
  }))

  const today = startOfToday()
  const [selectedDay, setSelectedDay] = useState(today)
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const translatedMonth = format(firstDayCurrentMonth, 'MMMM yyyy', { locale: ptBR })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { items, isFetching } = useAppSelector(state => state.activities)
  const { selectedUnit, selectedRole } = useAppSelector(state => state.persistable)

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

  const days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  })

  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    setSelectedDay(firstDayNextMonth)
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
    setSelectedDay(firstDayNextMonth)
  }

  const colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
  ]

  const firstDay = days[0].getDate().toString().padStart(2, '0')
  const startDate = `${format(firstDayCurrentMonth, 'yyyy-MM')}-${firstDay}`

  const params = {
    show_drafts: false,
    user_role_id: selectedRole?.id,
    order: 'desc',
    page: 1,
    per: 9999,
    school_unit_ids: selectedUnit?.id,
    start_from: startDate,
  }
  useEffect(() => {

    selectedUnit?.id && selectedRole?.id && dispatch(activitiesItemsFetchRequest(params))
    return () => {
      dispatch(activitiesResetFilters())
    }

  }, [currentMonth, selectedRole?.id, selectedUnit?.id])

  const itemsData = Array.isArray(items) && items.map(item => item.activities)
  const activitiesData = itemsData ? ([] as IActivities[]).concat(...itemsData) : []
  const activitiesDataNoArchived = activitiesData && activitiesData.filter(activity => {
    return activity.status !== 'archived'
  })
  const eventsByDay: any = {}

  activitiesDataNoArchived?.forEach((activity: any) => {

    const eventDay = format(parseISO(activity.start_date), 'yyyy-MM-dd')
    if (!eventsByDay[eventDay] || (eventsByDay[eventDay] && !eventsByDay[eventDay]?.[activity.status])) {
      eventsByDay[eventDay] = { ...eventsByDay[eventDay], [activity.status]: 1 }
    } else {
      eventsByDay[eventDay] = { ...eventsByDay[eventDay], [activity.status]: eventsByDay[eventDay]?.[activity.status] + 1 }
    }
  })

  const selectedDayMeetings = activitiesDataNoArchived && activitiesDataNoArchived.filter((activity: any) =>
    isSameDay(parseISO(activity.start_date), selectedDay)
  )

  const verifyStatus = (status: string) => {
    let bgColor: string
    switch (status) {
      case 'waiting':
        bgColor = theme.palette.error.main
        break
      case 'closed':
        bgColor = theme.palette.secondary.main
        break
      case 'opened':
        bgColor = theme.palette.primary.main
        break
      default:
        bgColor = 'transparent'
        break
    }
    return bgColor
  }

  const verifyDay = (day: any, selectedDay: any) => {
    if (isEqual(day, selectedDay) && isToday(day)) {
      return 'isToday-selected'
    } else if (!isEqual(day, selectedDay) && isToday(day)) {
      return 'isToday'
    } else if (isEqual(day, selectedDay)) {
      return 'day-selected'
    }
  }

  const ActivitiesByDay = ({ activity }: any) => {
    const startDateTime = format(new Date(activity.start_date), 'HH:mm')
    const endDateTime = format(new Date(activity.end_date), 'HH:mm dd/MM')
    const status = activity.status

    let color
    switch (status) {
      case 'closed':
        color = theme.palette.secondary.main
        break
      case 'waiting':
        color = theme.palette.error.main
        break
      case 'opened':
        color = theme.palette.primary.main
        break
      default:
        color = ''
        break
    }

    return (
      <Table aria-label='custom table'>
        <TableBody>
          <TableRow className='tableRow'>
            <TableCell
              onClick={() => navigate(`/activities/${activity?.id}/view`)}
              sx={{
                borderBottom: 'none',
                cursor: 'pointer',
                color,
                fontSize: '14px',
                fontWeight: 500,
                paddingBottom: 0,
                maxWidth: '100px',
                paddingTop: 0,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {truncateText(activity?.title, 20)}
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '14px',
                paddingBottom: 0,
                paddingTop: 0
              }}
              align='right'
            >
              {startDateTime}
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                fontSize: '14px',
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
              }}
              align='right'
            >
              {endDateTime}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <div style={{ paddingTop: '43px', maxWidth: '500px', width: '100%' }}>
      <div className='container'>
        <div style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <button
            type='button'
            onClick={previousMonth}
            className='prev-month-btn'
          >
            <span
              style={{
                position: 'absolute',
                width: '1px',
                height: '1px',
                padding: 0,
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0, 0, 0, 0)',
                whiteSpace: 'nowrap',
                borderWidth: 0
              }}
            >
            </span>
            <ArrowBackIosNewRoundedIcon sx={{ fontSize: '20px' }} />
          </button>
          <h2 className='month'>
            {translatedMonth}
          </h2>

          <button
            onClick={nextMonth}
            type='button'
            className='next-month-btn'
          >
            <span style={{
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: 0,
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0, 0, 0, 0)',
              whiteSpace: 'nowrap',
              borderWidth: 0
            }}
            >
            </span>
            <ArrowForwardIosRoundedIcon sx={{ fontSize: '20px' }} />
          </button>
        </div>
        {
          isFetching && (
            <Box mt={'12px'}>
              <LinearProgress
                sx={{ height: '3px' }}
                color='secondary'
                variant='indeterminate'
              />
            </Box>
          )
        }
        <div className='weekDaysContainer'>
          <div>Dom</div>
          <div>Seg</div>
          <div>Ter</div>
          <div>Qua</div>
          <div>Qui</div>
          <div>Sex</div>
          <div>Sab</div>
        </div>
        <div className='monthDaysContainer'>
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              style={{
                paddingTop: '0.375rem',
                paddingBottom: '0.375rem'
              }}
              className={classNames(
                dayIdx === 0 && colStartClasses[getDay(day)]
              )}
            >
              <button
                type='button'
                onClick={() => setSelectedDay(day)}
                style={{
                  flexDirection: 'column'
                }}
                className={'daysBtn styles ' + verifyDay(day, selectedDay)}
              >
                <time className='dayDate' dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
                <div style={{ display: 'flex', gap: '2px' }}>
                  {eventsByDay[format(day, 'yyyy-MM-dd')] && (
                    Array.from(Array(Object.keys(eventsByDay[format(day, 'yyyy-MM-dd')])), (e) => {
                      return e.map((status: string, i: number) => {
                        return (
                          <div
                            key={i}
                            style={{
                              width: '6px',
                              height: '6px',
                              borderRadius: '50%',
                              backgroundColor: verifyStatus(status)
                            }}
                          />
                        )
                      })
                    })
                  )}
                </div>
              </button>
            </div>
          ))}
        </div>
        <div className='statusContainer'>
          <StatusContainer>
            <StatusColor color={theme.palette.secondary.main} />
            Concluídas
          </StatusContainer>
          <StatusContainer>
            <StatusColor color={theme.palette.primary.main} />
            Em andamento
          </StatusContainer>
          <StatusContainer>
            <StatusColor color={theme.palette.error.main} />
            Agendada
          </StatusContainer>
        </div>
        <Divider />
        <section className='daysEvent'>
          <h2>
            <ActivityIcon />
            Atividades do dia{' '}
            <time style={{ textTransform: 'capitalize' }} dateTime={format(selectedDay, 'dd-MM-yyyy', { locale: ptBR })}>
              {format(selectedDay, 'dd, MMMM', { locale: ptBR })}
            </time>
          </h2>
          {(selectedDayMeetings.length > 0) && <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: '14px' }}>Titulo</TableCell>
                <TableCell sx={{ fontSize: '14px', textAlign: 'center' }} align='right'>Inicio</TableCell>
                <TableCell sx={{ fontSize: '14px', textAlign: 'center' }} align='right'>Fim</TableCell>
              </TableRow>
            </TableHead>
          </Table>}
          <ol className='activityList'>
            {selectedDayMeetings.length > 0 ? (
              selectedDayMeetings.map((activity: any) => (
                <ActivitiesByDay activity={activity} key={activity.id} />
              ))
            ) : (
              <p className='noActivity'>Sem atividades no dia.</p>
            )}
          </ol>
        </section>
      </div>
    </div>
  )
}
