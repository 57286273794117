import React, { useState } from 'react'
import { Box, Button, Collapse, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import ClassroomCollapse from './ClassroomCollapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ITeacherClassroom } from '../../../models/IClassroom'

const SchoolButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',

  '& span': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '150%',
    textTransform: 'capitalize'
  },

  '& .name': {
    color: theme.palette.text.primary,
  },

  '& .qtde_class': {
    color: theme.palette.primary.light,
    marginLeft: '4px',
    marginRight: '11px'
  },

  '&:hover': {
    backgroundColor: 'transparent'
  }
}))

interface ISchoolCollapseProps {
  schoolName: string
  schoolData?: ITeacherClassroom[]
  numberOfClassrooms?: number
  classroomsSelected: any[]
  handleSelectClassroom: (event: React.ChangeEvent<HTMLInputElement>, classroomId?: number) => void
}

const SchoolCollapse: React.FC<ISchoolCollapseProps> = ({
  schoolName,
  schoolData,
  numberOfClassrooms,
  classroomsSelected,
  handleSelectClassroom
}) => {
  const [seeSchool, setSeeSchool] = useState(true)

  return (
    <Box>
      <SchoolButton type='button' onClick={() => setSeeSchool(prevState => !prevState)}>
        <span className='name'>{schoolName}</span>
        <span className='qtde_class'>({numberOfClassrooms})</span>
        <KeyboardArrowDownIcon
          fontSize='small'
          className='arrow_icon'
          sx={{
            transform: seeSchool ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </SchoolButton>

      {!seeSchool && (
        <Divider
          variant='fullWidth'
          component='div'
          sx={{ borderStyle: 'dashed', borderColor: '#B7B2C8' }}
        />
      )}

      <Collapse in={seeSchool}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {schoolData?.map((classroom: ITeacherClassroom) => {
            return (
              <ClassroomCollapse
                key={classroom.id}
                classroom={classroom}
                classroomsSelected={classroomsSelected}
                handleSelectClassroom={handleSelectClassroom}
              />
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

export default SchoolCollapse
