import React from 'react'

// Components
import {
  Box,
  // IconButton,
  InputAdornment,
  TextField,
  // Typography
} from '@mui/material'
import DatePicker, { CalendarContainer } from 'react-datepicker'

// Utils
import ptLocale from 'date-fns/locale/pt-BR'
import { isValid, toDate } from 'date-fns'
import { styled } from '@mui/material/styles'

// Icons
// import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { ReactComponent as CalendarSVG } from '../../assets/icons/calendar.svg'

// Styles
import 'react-datepicker/dist/react-datepicker.css'

const DatePickerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  '.label': {
    color: '#9f9f9f',
    display: 'block',
    marginBottom: '4px',
    fontSize: '14px',
    fontWeight: 500
  },

  '.input': {
    alignItems: 'center',
    display: 'flex',

    '&_close-button': {
      background: theme.palette.primary.light,
      color: '#fff',
      height: '16px',
      padding: 0,
      marginLeft: '4px',

      '&_icon': {
        fontSize: '16px',
      }
    }
  },

  '.react-datepicker-wrapper': {
    width: '100%',
  },

  '.react-datepicker-popper': {
    '.react-datepicker__month-container': {
      '.react-datepicker__header': {
        background: '#fff',
        border: 'none',

        '.react-datepicker__current-month': {
          textTransform: 'capitalize'
        },

        '.react-datepicker__day-names': {
          fontSize: '12px',
          marginTop: '8px',
          textTransform: 'capitalize'
        }
      },

      '.react-datepicker__month': {
        '.react-datepicker__week': {
          '.react-datepicker__day--today': {
            background: theme.palette.primary.light,
            color: '#fff !important'
          },

          '.react-datepicker__day--selected, .react-datepicker__day--in-range': {
            background: theme.palette.secondary.main,
            borderRadius: '50%',
            color: '#fff'
          }
        }
      }
    }
  }
}))

const DatePickerStyled = styled('div')(({ theme }) => ({
  padding: 0,
  background: 'none',
  color: '#fff',
  '& .react-datepicker-wrapper': {
    marginTop: '8px',

    ',react-datepicker__input-container': {
      '.react-datepicker__close-icon::after': {
        background: `${theme.palette.primary.light} !important`
      }
    },
  },
  '& .react-datepicker': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    padding: '4px',
    '& .react-datepicker__navigation': {
      background: 'rgba(217, 217, 217, 0.4)',
      borderRadius: '50%',
      height: 20,
      margin: '-2px 8px',
      top: 18,
      width: 20,
      '& .react-datepicker__navigation-icon': {
        fontSize: '10px !important',
        '&:before': {
          borderColor: theme.palette.text.primary,
          borderWidth: '1px 1px 0 0',
          height: 6,
          top: 4,
          width: 6
        }
      },
      '& .react-datepicker__navigation-icon--previous': {
        '&:before': {
          right: -2
        }
      },
      '& .react-datepicker__navigation-icon--next': {
        '&:before': {
          left: -2
        }
      }
    },
    '& .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      color: `${theme.palette.primary.light} !important`,
      borderRadius: '50%',
      fontWeight: 400
    },
    '& .react-datepicker__year-text--keyboard-selected': {
      background: 'none',
    }
  },
}))

const TextFieldStyled = styled(TextField)(({ disabled }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 40,
    fontSize: 12,
    color: '#666666',
    width: '100%',
    backgroundColor: disabled ? 'rgba(102, 102, 102, 0.08)' : '#FFF',
    '& fieldset': {
      border: '1px solid #D9D9D9'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    },
  },
}))


type CalendarPopupProps = {
  className?: string | undefined
  children?: React.ReactNode | undefined
}

const CalendarPopup = ({ className, children }: CalendarPopupProps) => {
  return (
    <DatePickerStyled>
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    </DatePickerStyled>
  )
}

const SimpleDatePicker = ({
  name,
  dateFormat = 'dd/MM/yyyy',
  placeholder,
  isClearable,
  readOnly,
  value,
  changeDate,
  disabled,
  ...rest
}: any) => {

  const handleChange = (newValue: any) => {
    if (isValid(newValue)) {
      changeDate?.(newValue)
    }
  }

  return (
    <DatePickerContainer>
      <Box className='input'>
        <DatePicker
          {...rest}
          name={name}
          locale={ptLocale}
          calendarContainer={CalendarPopup}
          peekNextMonth={false}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          isClearable={isClearable}
          customInput={
            <TextFieldStyled
              InputProps={{
                readOnly: readOnly,
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarSVG />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <KeyboardArrowDownRoundedIcon />
                  </InputAdornment>
                )
              }}
            />
          }
          selected={value && isValid(value) ? toDate(value) : null}
          onChange={handleChange}
          value={value}
          disabled={disabled}
        />
      </Box>
    </DatePickerContainer>
  )
}

export default SimpleDatePicker
