import React from 'react'
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './routes/Routes'
import { SidebarProvider } from './contexts/SidebarContext'

const supportsHistory = 'pushState' in window.history

const App = () => (
  <Router forceRefresh={!supportsHistory} keyLength={12}>
    <SidebarProvider>
      <Routes />
    </SidebarProvider>
  </Router>
)


export default App
