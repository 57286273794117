import React from 'react'
import { Box } from '@mui/system'
import StudentInfoTable from '../table/StudentInfoTable'
import Label from '../../label/Label'
import { Typography } from '@mui/material'
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'


function SeeStudentInfoModal(singleStudent: any) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        height: 'fit-content',
        paddingBottom: '24px'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '16px',
          justifyContent: 'flex-start',
          width: '100%'
        }}
      >
        <DataIcon />
        <Label
          sx={{
            margin: 0,
            padding: 0,
            fontSize: '24px'
          }}
        >
          Dados cadastrais
        </Label>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Typography
          sx={{
            color: '#666',
            fontSize: '18px',
            fontWeight: 600,
            paddingBottom: '32px'
          }}
        >
          Aluno
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Box display={'flex'}>
            <StudentInfoTable data={singleStudent} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SeeStudentInfoModal
