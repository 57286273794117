import React from 'react'
import styles from './styles.module.scss'

const Loading = () => {
  return (
    <>
      <span className={styles.loader}></span>
    </>
  )
}

export default Loading
