import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearCurrentBook } from '../../../store/books/actions'
import Tabs from '../../tabs/Tabs'
import Tab from '../../tabs/Tab'
import BookSettingsForm from './BookSettingsForm'
import BooksContentForm from './BookContentForm'
import DialogModal from '../../Modal/DialogModal'
import CheckboxInput from '../../input/CheckboxInput'
import StepsIcon from '../../../assets/components/Books/steps-icon.svg'
import ConfigImage from '../../../assets/components/Books/config-image.svg'
import ContentImage from '../../../assets/components/Books/content-image.svg'
import SuccessMessage from '../../message/SuccessMessage'

const NumberIcon = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  color: '#FFF',
  height: 23,
  padding: 3,
  width: 23
}))

const BoxTitle = styled(Box)(({ theme }) => ({
  marginBottom: '28px',
  textAlign: 'center',

  '.title': {
    color: theme.palette.text.primary,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBottom: '8px'
  },

  '.description': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%'
  }
}))

const StepBox = styled(Box)(({ theme }) => ({
  '.step': {
    color: theme.palette.primary.light,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '120%',
    marginBlock: '8px'
  },

  '.label': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '120%',
    margin: '8px 0 10px'
  },

  '.description': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '150%'
  }
}))

interface BooksFormProps {
  isTeacher?: any
  userId?: number,
  id?: string
  isEdit?: boolean
}

const BooksForm: React.FC<BooksFormProps> = ({ isTeacher, userId, id, isEdit }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [showGuide, setShowGuide] = useState(false)
  const guideStorage = localStorage.getItem('guide')
  const guide = guideStorage && JSON.parse(guideStorage)
  const dispatch = useAppDispatch()
  const { isSuccess, isSaving } = useAppSelector(state => state.books)

  useEffect(() => {
    !guide?.isTrue && !id && setTimeout(() => {
      setOpenModal(true)
    }, 500)

    return () => {
      localStorage.removeItem('book')
      dispatch(clearCurrentBook())
    }
  }, [])

  const handleTabChange = (_: any, value: number) => {
    setActiveTab(value)
  }

  const handleNext = () => {
    setActiveTab(2)
  }

  const handleBack = () => {
    setActiveTab((prevActiveTab) => prevActiveTab - 1)
  }

  const handleShowguide = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const showGuide = { isTrue: true }
      localStorage.setItem('guide', JSON.stringify(showGuide))
    } else {
      const showGuide = { isTrue: false }
      localStorage.setItem('guide', JSON.stringify(showGuide))
    }
    setShowGuide(event.target.checked)
  }

  return (
    <Box>
      {isSuccess && !isSaving && (
        <SuccessMessage
          type='books'
          path='books'
          isEdit={isEdit}
        />
      )}

      {!isSuccess && (
        <>
          <Box sx={{ alignItems: 'flex-end', display: 'flex', gap: '32px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <img src={StepsIcon} alt='ícone das etapas' width='20px' height='17px' />
              <Typography
                component='h3'
                sx={{
                  color: '#666666',
                  fontSize: '18px',
                  fontWeight: 600,
                  letterSpacing: '0.18px'
                }}
              >
                Etapas:
              </Typography>
            </Box>

            <Tabs sx={{ width: 'calc(100% - 140px)' }} value={activeTab} variant='fullWidth' onChange={handleTabChange} aria-label='ant example'>
              <Tab icon={<NumberIcon>1</NumberIcon>} iconPosition='start' label='Configurações' value={1} />
              <Tab icon={<NumberIcon>2</NumberIcon>} iconPosition='start' disabled={activeTab === 1} label='Conteúdo' value={2} />
            </Tabs>
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <BookSettingsForm
              activeTab={activeTab}
              isTeacher={isTeacher}
              userId={userId}
              nextTab={handleNext}
              bookId={id}
            />

            <BooksContentForm
              activeTab={activeTab}
              isTeacher={isTeacher}
              userId={userId}
              prevTab={handleBack}
            />
          </Box>
        </>
      )}

      <DialogModal open={openModal} handleClose={() => setOpenModal(false)} width='777px' height='100%' sx={{ padding: '0px' }}>
        <Box sx={{ maxWidth: '564px', width: '100%', marginInline: 'auto', overflow: 'hidden !important' }}>
          <BoxTitle>
            <Typography component='h2' className='title'>
              Guia rápido para criar seu próprio livro
            </Typography>

            <Typography className='description'>
              Crie seu livro e transforme a educação! Seu livro, suas regras. <b>Vamos lá!</b>
            </Typography>
          </BoxTitle>

          <Grid container columnSpacing={7}>
            <Grid item xs={6}>
              <StepBox sx={{ textAlign: 'center' }}>
                <img src={ConfigImage} />
                <p className='step'>Etapa 1</p>
                <p className='label'>Configuração</p>
                <p className='description'>
                  Preencha dados do livro: título, descrição, capa e disciplina.
                </p>
              </StepBox>
            </Grid>

            <Grid item xs={6}>
              <StepBox sx={{ textAlign: 'center' }}>
                <img src={ContentImage} />
                <p className='step'>Etapa 2</p>
                <p className='label'>Conteúdo</p>
                <p className='description'>
                  Organize capítulos e insira módulos no seu projeto.
                </p>
              </StepBox>
            </Grid>
          </Grid>

          <Box sx={{ marginTop: '32px', textAlign: 'center' }}>
            <CheckboxInput
              name='message'
              label='Não mostrar essa mensagem novamente'
              onChange={handleShowguide}
              checked={openModal && showGuide}
            />
          </Box>
        </Box>
      </DialogModal>
    </Box>
  )
}

export default BooksForm
