import { format } from 'date-fns'
import { rangeRight } from 'lodash'

import { ROLES, Role } from './constants'

export const compareDates = (date: any) => {
  const parts = date.split('/')
  const today = new Date()

  date = new Date(parts[2], parts[1] - 1, parts[0])
  return date >= today
}

export const formatTime = (seconds: any) => {
  if (seconds < 0) {
    return '00:00:00'
  }
  const hour = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secondsRest = seconds % 60

  const hourFormated = hour.toString().padStart(2, '0')
  const minutesFormated = minutes.toString().padStart(2, '0')
  const secondsRestFormated = secondsRest.toString().padStart(2, '0')

  return `${hourFormated}:${minutesFormated}:${secondsRestFormated}`
}

export const textExtract = (text: string, qtde: number) => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = text
  const extractedText = tempElement.innerText
  const limitedText = extractedText.length > 100 ? extractedText.substring(0, qtde) + '...' : extractedText
  return limitedText
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export function withPayloadType<T>() {
  return (t: T) => ({ payload: t })
}

export function encodeQueryData(data: { [key: string]: any }) {
  const ret = []
  for (const d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }
  return ret.join('&')
}


export const checkCurrentRole = (currentRole: any, roleName: string) => {
  return currentRole?.name === roleName
}

export const getRoleByName = (name: string | null): Role | null => {
  for (const key in ROLES) {
    if (ROLES[key].id === name) {
      return ROLES[key]
    }
  }

  return null
}

export const getHighestRole = (roles: any) => {
  const sorteredRoles = roles
    .map((role: Role) => ({
      ...role,
      level: getRoleByName(role.name)?.level
    }))
    .sort((a: any, b: any) => a.level - b.level)

  const { level, ...role } = sorteredRoles[0]
  return role
}

export const transformDataToOptions = (dataArray: any) => {
  return dataArray.map((data: any) => (data.knowledge_area
    ? { id: data.id, name: data.name || data.description, knowledge_area: data.knowledge_area }
    : data?.grade
      ? { id: data.id, name: data.name, grade: data.grade }
      : { id: data.id, name: data.name || data.description }))

}

export const yearOptions = rangeRight(1900, Number(format(new Date(), 'yyyy')) + 1).map(
  year => ({
    name: String(year),
    value: String(year)
  })
)

export const groupByKey = (items: any[], key: string | number) => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )
}

function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export function stringAvatar(name: string, width=36, height=36, fontSize=12) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: width,
      height: height,
      fontSize: fontSize
    },
    children: name.split(' ').length > 1
      ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
      : `${name[0]}${name[1].toUpperCase()}`
  }
}

export const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const regex = /^[0-9,.]*$/
  if (!regex.test(event.key) && event.key !== 'Backspace') {
    event.preventDefault()
  }
}

export function bytesToMB(bytes: number) {
  return (bytes / (1024 * 1024)).toFixed(2)
}

export function truncateText(text: any, maxLength: any) {
  if (text.length <= maxLength) {
    return text
  } else {
    return text.substring(0, maxLength) + '...'
  }
}

export function formatDateString(inputDate: string) {
  return new Date(inputDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export function zipCodeFormatter(zipCode: string) {
  const cleanZipCode = zipCode && zipCode.replace(/\D/g, '')
  if (cleanZipCode?.length === 8) {
    return cleanZipCode.replace(/^(\d{5})(\d{3})$/, '$1-$2')
  } else {
    return zipCode
  }
}

export function cpfFormatter(cpf: string) {
  const cleanCPF = cpf && cpf?.replace(/\D/g, '')
  return cleanCPF?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function convertDate(dateString: any) {
  const [year, month, day] = dateString && dateString.split('-')
  const formattedDate = `${day}/${month}/${year}`

  return formattedDate
}

export const removeNonNumericCharacters = (text: string) => {
  return text?.replace(/\D/g, '') || ''
}
