/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { Box, Paper, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/system'
import SelectInput from '../../components/select/SelectInput'
import { ScoresChart } from '../charts/ScoresChart'
import { IClassroom } from '../../models/IClassroom'
import { ReactComponent as ListSVG } from '../../assets/icons/score-list.svg'
import { ReactComponent as PeopleSVG } from '../../assets/icons/people.svg'
import { DefaultOptionType } from '../../utils/types'

interface IScoreStudentsViewProps {
  activityType?: string
  activityValue?: string
  currentClassroom: any
  classrooms: Array<IClassroom>
  data: {
    classroom_name: string
    participant_id: number
    participant_score: number
    performance: number
  }[]
  isFetching?: boolean
}

const SelectsContainer = styled('div')({
  display: 'flex',
  marginTop: 8,
  marginBottom: 24,
  alignItems: 'center',
  '& .MuiBox-root': {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 16
  },
  '& > div > .MuiFormControl-root > .MuiOutlinedInput-root': {
    backgroundColor: '#FFF',
    maxWidth: '300px'
  },
  '& .MuiTypography-body2': {
    color: '#666'
  }
})

const ScoreStudentsView: React.FC<IScoreStudentsViewProps> = ({
  activityType,
  activityValue,
  currentClassroom, // eslint-disable-line
  classrooms,
  data,
  isFetching
}) => {
  const [optionsClassroom, setOptionsClassroom] = useState<any[]>([])
  const [selectedScoresClassroom, setSelectedScoresClassroom] = useState<any[]>([])

  const classroomOptions: Array<DefaultOptionType> = classrooms ? classrooms.map(classroom => {
    return ({ label: classroom.name, value: classroom.id })
  }).flat() : []

  const scoresClassroom = data?.map((item) => {
    const classroomId = classroomOptions.find((classroom: any) => classroom.label === item.classroom_name)
    return {
      user_id: item.participant_id,
      school_classroom_id: classroomId?.value,
      school_classroom_name: classroomId?.label,
      participant_score: activityType === 'exam' ? item.participant_score : item.performance
    }
  })

  const groupedArray = scoresClassroom?.reduce((acc: any, obj: any) => {
    const foundIndex = acc.findIndex((item: any) => item[0]?.school_classroom_id === obj.school_classroom_id)
    if (foundIndex !== -1) {
      acc[foundIndex].push(obj)
    } else {
      acc.push([obj])
    }
    return acc
  }, []).sort((a: any, b: any) => {
    const idA = a[0].school_classroom_id
    const idB = b[0].school_classroom_id
    return idA - idB
  })

  const allScoresClassroom = () => {
    setSelectedScoresClassroom(groupedArray)
  }

  const handleClassroomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    if (Number(value) === 0) {
      allScoresClassroom()
    } else {
      const classroomChosed = groupedArray.flatMap((item: any) => item).filter((item: any) => item.school_classroom_id === value)
      setSelectedScoresClassroom(classroomChosed)
    }
  }

  useEffect(() => {
    if (classroomOptions.length > 1) {
      setOptionsClassroom([
        { label: 'Todas as turmas', value: 0 },
        ...classroomOptions
      ])
      allScoresClassroom()
    } else {
      setOptionsClassroom(classroomOptions)
      const classroomChosed = groupedArray?.flatMap((item: any) => item).filter((item: any) => item.school_classroom_id === classroomOptions?.[0]?.value)
      setSelectedScoresClassroom(classroomChosed)
    }
  }, [data, classrooms])

  return (
    <Paper sx={{ borderRadius: 4, mt: 4, p: 3 }}>

      {isFetching && (
        <Box>
          <Skeleton height={50} width={200} />
          <Skeleton height={200} />
        </Box>
      )}
      {!isFetching && (
        <>
          <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
            <ListSVG style={{ marginRight: 10 }} />  {activityType === 'exam' ? 'Distribuição de notas' : 'Demonstrativo de performance'}
          </Typography>
          <SelectsContainer>
            <PeopleSVG style={{ marginRight: 10 }} />
            <SelectInput
              defaultValue={optionsClassroom?.[0]?.value ?? ''}
              name='classroom'
              onChange={handleClassroomChange}
              options={optionsClassroom}
            />
          </SelectsContainer>
          <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ background: '#F9F9F9', borderRadius: 4, padding: 2 }}>
            <Box width='100%'>
              <ScoresChart
                activityType={activityType}
                activityValue={activityValue}
                scores={selectedScoresClassroom}
              />
            </Box>
          </Box>
        </>
      )}
    </Paper >
  )
}

export default ScoreStudentsView
