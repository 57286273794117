import React from 'react'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IBook } from '../../../containers/data.mockup'
import { textExtract } from '../../../utils/functions'
import TagMoreReadIcon from '../../../assets/components/Books/tagMoreRead-icon.svg'
import SeeMoreIcon from '../../../assets/components/Books/see-more-icon.svg'

const CardContainer = styled(Box)(() => ({
  background: '#ffffff',
  borderRadius: '16px',
  padding: '4px 16px 24px',

  '& .tags': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end'
  }
}))

const StatusTag = styled('span')<any>(({ theme, status }) => {
  let color
  if (status === 'published') {
    color = theme.palette.secondary.main
  } else if (status === 'ready_to_publish') {
    color = theme.palette.primary.light
  } else {
    color = theme.palette.error.main
  }

  return {
    border: `1px solid ${color}`,
    borderRadius: '32px',
    color: color,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '120%',
    padding: '4px 6px'
  }
})

const NewBookTag = styled('span')(({ theme }) => ({
  background: theme.palette.error.main,
  borderRadius: '32px',
  color: '#ffffff',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '4px 6px'
}))

const BookContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',

  '& .cover': {
    borderRadius: '8px',
    height: '160px',
    flex: '0 0 114px',
    overflow: 'hidden',
    width: '114px !important',

    '&_image': {
      height: '100%',
      width: '100% !important'
    },

    '&_image_default': {
      alignItems: 'center',
      background: '#f1f1f1',
      color: '#c9c9c9',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 600,
      justifyContent: 'center',
      width: '100%',
    }
  },

  '& .book': {
    flex: 1,

    '&_title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '120%',
      marginBottom: '4px'
    },

    '&_description': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '150%',
      marginBottom: '18px'
    },

    '&_data': {
      '& .last': {
        display: 'flex',
        justifyContent: 'flex-end'
      },

      '.data': {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        width: 'fit-content',

        '& span': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%',
        },

        '& .access, & .code': {
          color: theme.palette.primary.light,
          fontWeight: 700
        },

        '& .performance': {
          color: theme.palette.secondary.main,
          fontWeight: 700
        }
      }
    }
  }
}))

interface IReadMoreComponentProps {
  principalName: string
  allNames: string
}

const ReadMoreComponent: React.FC<IReadMoreComponentProps> = ({ principalName, allNames }) => {
  return (
    <Tooltip title={allNames} arrow>
      <Box sx={{ alignItems: 'center', display: 'inline-flex', gap: '4px' }}>
        <Box component='span'>{principalName}</Box>
        <img src={SeeMoreIcon} />
      </Box>
    </Tooltip>
  )
}

interface IBookDetailsCard {
  bookData: IBook
}

const BookDetailsCard: React.FC<IBookDetailsCard> = ({ bookData }) => {
  const checkStatus = (status: string) => {
    if (status === 'published') {
      return 'Publicado'
    } else if (status === 'ready_to_publish') {
      return 'Não Publicado'
    } else {
      return 'Rascunho'
    }
  }

  return (
    <CardContainer>
      <Box className='tags'>
        <StatusTag status={bookData.status}>
          {checkStatus(bookData.status)}
        </StatusTag>
        <NewBookTag>Novo</NewBookTag>
        <img
          src={TagMoreReadIcon}
          title='Livro muito utilizado'
          width='25px'
        />
      </Box>

      <BookContainer>
        <Box className='cover'>
          {bookData.photo_url
            ? (
              <img
                src={bookData.photo_url}
                alt='Capa do livro'
                className='cover_image'
              />
            )
            : (
              <Box className='cover_image-default'>
                <span>Livro</span>
                <span>sem</span>
                <span>capa</span>
              </Box>
            )}
        </Box>

        <Box className='book'>
          <Typography
            className='book_title'
            component='h3'
          >
            {bookData.name}
          </Typography>

          <Typography className='book_description'>
            {textExtract(bookData.description, 120)}
          </Typography>

          <Grid container className='book_data' rowSpacing={1}>
            <Grid item xs={6} className='book_data-item'>
              <Box className='data'>
                <Box component='span'>Cód:</Box>
                <Box component='span' className='code'>{bookData.id}</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Disciplina:</Box>
                {bookData.subjects.length === 1 ? (
                  <Box component='span'>{bookData.subjects?.[0]?.name}</Box>
                ) : (
                  <ReadMoreComponent
                    principalName={bookData.subjects?.[0]?.name}
                    allNames={bookData.subjects.map(subject => subject.name).join(' / ')}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item'>
              <Box className='data'>
                <Box component='span'>Escola:</Box>
                {bookData.schools.length === 1 ? (
                  <Box component='span'>{bookData.schools?.[0]?.name}</Box>
                ) : (
                  <ReadMoreComponent
                    principalName={bookData.schools?.[0]?.name}
                    allNames={bookData.schools.map(school => school.name).join(' / ')}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Performance:</Box>
                <Box component='span' className='performance'>{bookData.performance}%</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item'>
              <Box className='data'>
                <Box component='span'>NPS:</Box>
                <Box component='span'>{bookData.nps}</Box>
              </Box>
            </Grid>

            <Grid item xs={6} className='book_data-item last'>
              <Box className='data'>
                <Box component='span'>Acessos:</Box>
                <Box component='span' className='access'>{bookData.acess}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </BookContainer>
    </CardContainer>
  )
}

export default BookDetailsCard
