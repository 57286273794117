import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { FieldRenderProps } from 'react-final-form'

type Props = FieldRenderProps<string, any>

const BoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiFormHelperText-root': {
    margin: 0,
    color: '#D9D9D9'
  }
})

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 40,
    fontSize: 14,
    '& fieldset': {
      border: `1px solid ${theme.palette.text.secondary}`,
    },
    '& .Mui-disabled': {
      zIndex: 999,
      '& + fieldset': {
        background: '#F0F3F4',
        border: `1px solid ${theme.palette.text.secondary}`
      }
    },
    '& .MuiInputAdornment-root': {
      '& path': {
        fill: theme.palette.text.secondary,
        zIndex: 9
      }
    },
    'input::placeholder': {
      fontWeight: 400
    },
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
    appearance: 'textfield',
    border: '1 solid #CFCFCF',
    margin: '0 11',
    borderRadius: 4,
  },
  '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  }
}))

const Input: React.FC<Props> = ({
  input: { value, name, ...restInput },
  meta: { touched, error },
  placeholder,
  label,
  defaultFieldValue,
  variant = 'outlined',
  ...rest
}: Props) => {
  return (
    <BoxStyled>
      {label &&
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontSize: 14,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            wordWrap: 'unset'
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>}
      <TextFieldStyled
        id={`input-${name}`}
        name={name}
        hiddenLabel
        placeholder={placeholder}
        variant={variant}
        value={defaultFieldValue || value}
        {...restInput}
        {...rest}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: '#F69E9E'
        }}
      >
        {touched && error && error}
      </Typography>
    </BoxStyled>
  )
}

export default Input
