import React from 'react'
import ManageTeachersContainer from '../containers/ManageTeachersContainer'

const ManageTeacherPage = () => {
  return (
    <ManageTeachersContainer />
  )
}

export default ManageTeacherPage
