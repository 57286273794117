import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'

type Props = {
  children: ReactNode
}

const NoItemsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '32px',
  '& h3': {
    fontSize: 18,
    marginBottom: 0,
    '& span': {
      color: theme.palette.primary.light,
    }
  },
  '& p': {
    color: '#9F9F9F',
    marginTop: 8,
    '& span': {
      color: theme.palette.primary.light,
      fontWeight: 900
    }
  }
}))

const NoItemsCard = ({ children }: Props) => {
  return (
    <NoItemsContainer>
      {children}
    </NoItemsContainer>
  )
}

export default NoItemsCard
