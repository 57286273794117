import http from '../../utils/http'

const filtersEndpoint = '/users'

export const fetchSavedFilters = ({ id }: { id:number }) => {
  return http.get(`${filtersEndpoint}/${id}/filters`)
}

export const saveFilters = ({ id, params }: { id: number, params: any }) => {
  return http.post(`${filtersEndpoint}/${id}/save_filters`, { ...params })
}

export const updateFilters = ({ id, params }: { id: number, params: any }) => {
  return http.put(`${filtersEndpoint}/${id}/update_filters`, { ...params })
}

export const deleteFilters = ({ id, params }: { id: number, params: any }) => {
  return http.delete(`${filtersEndpoint}/${id}/delete_filters?controller_name=${params.controller_name}&filter_name=${params.filter_name}`)
}

export const deleteAllFilters = ({ id }: { id: number }) => {
  return http.delete(`${filtersEndpoint}/${id}/delete_filters`, {
    params: {
      clean_all: true
    }
  })
}
