import React from 'react'
import TeacherFormCreateContainer from '../containers/TeacherFormCreateContainer'


const ManageTeacherCreatePage = () => {
  return (
    <div>
      <TeacherFormCreateContainer />
    </div>
  )
}

export default ManageTeacherCreatePage
