import React, { ReactNode } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { Box, CircularProgress, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IModule } from '../../models/IModule'
import BoxCard, { BoxHeader, BoxBottom } from '../card/BoxCard'
import Button from '../button/Button'
import DialogModal from '../Modal/DialogModal'
import QuestionCard from '../Questions/QuestionCard'
import ContentCard from '../Contents/ContentCard'
import BarCodeIcon from '../../assets/components/Modules/barCode-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Modules/view-icon.svg'
import { ReactComponent as ImportIcon } from '../../assets/components/Modules/import-icon.svg'

const BoxBody = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: 14,
  marginBottom: 16,
  '& p': {
    marginBlock: 0,
  },
  '& span': {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginRight: 8,
  }
}))

const ImportStyled = styled(ImportIcon)(({ theme }) => ({
  '& path': {
    fill: 'none !important',
    stroke: theme.palette.primary.light
  }
}))

type ModuleCardProps = {
  module: IModule,
  importModule: any,
  importModuleId: number,
  isImporting: boolean,
  handleViewContents: any,
  currentItem: any,
  clearCurrentItem: any
  isSelectable?: boolean
  selectModuleCheck?: ReactNode
}

const ModuleCard: React.FC<ModuleCardProps> = ({ module, importModuleId, importModule, isImporting, handleViewContents, currentItem, clearCurrentItem, isSelectable, selectModuleCheck }) => {
  const dispatch = useAppDispatch()

  return (
    <>
      <BoxCard>
        <BoxHeader>
          <div>
            <img src={BarCodeIcon} alt='ícone do código da questão' />
            Código: <span className='info'>{module.id}</span>
          </div>
        </BoxHeader>
        <Divider sx={{
          borderBottom: '1px dashed #9F9F9F',
          margin: '16px 10px 24px -24px',
          width: 'calc(100% + 48px)'
        }} />
        <BoxBody>
          <p><span>Nome:</span>{module.name}</p>
        </BoxBody>
      </BoxCard>
      <BoxBottom>
        <Button
          type='button'
          style={{ marginRight: 10, background: '#FFF' }}
          variant='outlined'
          startIcon={<ViewIcon />}
          onClick={() => handleViewContents(module.id)}
        >
          Visualizar conteṹdos
        </Button>
        {!isSelectable && (
          <Button
            type='button'
            style={{ marginRight: 10, background: '#FFF' }}
            variant='outlined'
            disabled={importModuleId === module.id && isImporting}
            startIcon={(importModuleId === module.id && isImporting) ? <CircularProgress size={18} thickness={2} /> : <ImportStyled />}
            onClick={() => dispatch(importModule({ id: module.id }))}
          >
            Importar conteúdos
          </Button>
        )}
        {selectModuleCheck}
      </BoxBottom>
      <DialogModal
        open={currentItem.isFetching || (module.id === currentItem.item?.id)}
        handleClose={() => dispatch(clearCurrentItem())}
        width='820px'
        height='520px'
      >
        <div>
          {currentItem.isFetching && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress thickness={2} />
            </Box>
          )}
          {currentItem.item &&
            !currentItem.isFetching &&
            currentItem.item.content_module_items.map((item: { content_item: { question: any } }, key: any) => {
              return (item.content_item.question) ? (
                <Box
                  key={key}
                  sx={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '16px',
                    marginBottom: '16px',
                    overflow: 'hidden'
                  }}
                >
                  <QuestionCard
                    question={item.content_item.question}
                  />
                </Box>
              ) : (
                <Box
                  key={key}
                  sx={{
                    border: '1px solid #d9d9d9',
                    borderRadius: '16px',
                    marginBottom: '16px',
                    overflow: 'hidden'
                  }}
                >
                  <ContentCard content={item.content_item} />
                </Box>
              )
            })}
        </div>
      </DialogModal>
    </>
  )
}

export default ModuleCard
