import React from 'react'
import { useAppSelector } from '../store/hooks'
import ContentsView from '../components/Contents/list/ContentsView'
import { checkCurrentRole } from '../utils/functions'

const ContentsContainer = () => {
  const { auth: { user }, persistable: { selectedRole }, contents, } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')
  return (
    <ContentsView
      contents={contents}
      isTeacher={isTeacher}
      isSchoolQuestions
      userId={user.id}
    />

  )
}

export default ContentsContainer
