import React from 'react'

import ActivityDetailsContainer from '../containers/ActivityDetailsContainer'

const ActivityPage = () => {
  return (
    <ActivityDetailsContainer />
  )
}

export default ActivityPage
