import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import SelectInputTable from '../../components/select/SelectInputTable'
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as SchoolSVG } from '../../assets/components/Books/school-icon.svg'
import BooksCatalogTable from '../table/BooksCatalogTable'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'
import { Books } from '../../models/IBooks'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

const SelectBox = styled('div')(() => ({
  '& .MuiSelect-select' : {
     paddingLeft: '8px',
     textAlign: 'left'
  }
 }))

const BooksCatalogView: any = ({
        books, 
        isFetching, 
        unitOptions,
        subjectsOptions, 
        authorsOptions,
        handleFilterUnits,
        handleFilterSubjects,
        handleFilterAuthors,
  } : any) => {

  const [listBooks, setListBooks] = useState<Books[]>(books)// eslint-disable-line
  const [selectedUnit, setSelectedUnit] = useState<string>()// eslint-disable-line
  const [selectedSubject, setSelectedSubject] = useState<string>()// eslint-disable-line

  const filterBooks = (books : any, unitId : any) => {
   const filteredBooks = books.filter((book : any) => book.units.some((unit : any) => unit.id === unitId))
   setListBooks(filteredBooks)
  }

  useEffect(() => {
    !selectedUnit && !selectedSubject ? 
    setListBooks(books) :
    filterBooks(books, selectedUnit)
  }, [books])

  
  const subjectsList =  subjectsOptions?.map((subject: any) => ({ label: subject.name, value: subject.id }))
  const SubOptions = [
    ...(subjectsList ?? [])
  ]

  const unitsList =  unitOptions?.map((unit: any) => ({ label: unit.label, value: unit.value }))
  const UnitOptions = [
    ...(unitsList ?? [])
  ]

  const authorsList =  authorsOptions?.map((author: any) => ({ label: author.label, value: author.value }))
  const AutOptions = [
    ...(authorsList ?? [])
  ]
  
  return (
    <Paper sx={paperStyled}>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
            <PerformanceSVG style={{ marginRight: 16 }} />
            Catálogo de Livros Disponíveis
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>

                    <NotebookSVG />
                    <SelectInputTable
                        border='none'
                        defaultValue='geral'
                        name=''
                        sx={{ width: '140px', textAlign: 'center' }}
                        onChange={handleFilterSubjects}
                        options={[
                          { label: 'Disciplinas:', value: 'geral' },
                            ...SubOptions
                         ]
                        }
                        />
        
                <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                    <SchoolSVG />
                    <SelectInputTable
                        border='none'
                        defaultValue='geral'
                        name=''
                        sx={{ width: '100px', textAlign: 'center' }}
                        onChange={handleFilterUnits}
                        options={[
                          { label: 'Escolas:', value: 'geral' },
                            ...UnitOptions
                         ]}
                        />
                </SelectBox>
                <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                    <SchoolSVG />
                    <SelectInputTable
                        border='none'
                        defaultValue='geral'
                        name=''
                        sx={{ width: '100px', textAlign: 'center' }}
                        onChange={handleFilterAuthors}
                        options={[
                          { label: 'Autores:', value: 'geral' },
                            ...AutOptions
                         ]}
                        />
                </SelectBox>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ width: 520 }}>
            <BooksCatalogTable books={listBooks} isFetching={isFetching}  />
          </Grid>
        </Grid>
    </Paper>
  )
}

export default BooksCatalogView
