import React from 'react'
import BooksReportClassroomPerformanceContainer from '../containers/BooksReportClassroomPerformanceContainer'

const BooksReportClassroomPerformancePage = () => {
  return (
    <BooksReportClassroomPerformanceContainer />
  )
}

export default BooksReportClassroomPerformancePage
