import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import ContentsForm from '../components/Contents/form/ContentsForm'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { contentsItemFetchByIdRequest } from '../store/contents/actions'

interface ContentsFormEditContainerProps {
  id?: any
}

const ContentsFormEditContainer: React.FC<ContentsFormEditContainerProps> = ({ id }) => {
  const { currentItem } = useAppSelector(state => state.contents)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const loadContent = (id: any) => {
      dispatch(contentsItemFetchByIdRequest(id))
    }
    loadContent(id)
  }, [])

  const topics = {}

  currentItem.item && currentItem.item.topics && currentItem.item?.topics.forEach(topic => {
    const subjectName = topic.subject.name
    const topicObject = { id: topic.id, name: topic.name }

    if (!topics[subjectName]) {
      topics[subjectName] = []
    }

    topics[subjectName].push(topicObject)
  })

  const subjects = currentItem.item?.subjects?.map(subject => {
    const topicsValues = currentItem.item?.topics?.filter(topic => topic.subject.id === subject.id).map(topic => ({ id: topic.id, name: topic.name }))
    return {
      id: subject.id,
      name: subject.name,
      topics: topicsValues
    }
  })

  const videoSchema = currentItem.item.content_format === 1 ? currentItem.item?.schema_content?.value : null
  const textSchema = currentItem.item.content_format === 7 ? currentItem.item?.schema_content?.value : null
  const attachment = currentItem.item.content_format === 4 ? currentItem.item?.schema_content : null
  const urlSchema = currentItem.item.content_format === 5 ? currentItem.item?.schema_content?.value : null

  const initialValues = {
    id: id,
    name: currentItem.item.name,
    description: currentItem.item.description,
    age_ranges: {
      id: currentItem.item?.age_ranges?.[0]?.id,
      name: String(currentItem.item?.age_ranges?.[0]?.minimum_age + ' - ' + currentItem.item?.age_ranges?.[0]?.maximum_age)
    },
    segments: currentItem.item.segments?.[0],
    subject_ids: subjects,
    content_format_id: String(currentItem.item.content_format),
    attachment: attachment,
    schema_content: currentItem.item.schema_content,
    videoInput: videoSchema,
    textInput: textSchema,
    urlInput: urlSchema,
    topics: topics
  }

  if (currentItem.isFetching) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <ContentsForm isTeacher={undefined} initialValues={initialValues} />
    </div>
  )
}

export default ContentsFormEditContainer
