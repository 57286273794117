import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { DefaultAction } from './../../utils/constants'

import * as services from './services'
import * as actions from './index'
import { showAlertMessage } from '../alert'
import { TakeableChannel } from 'redux-saga'

export function * fetchSavedFilters ({ payload }: DefaultAction<any>) {
  try {
    const response:AxiosResponse<any> = yield call(services.fetchSavedFilters, payload)
    yield put(actions.getSavedFiltersSuccess(response.data))
  } catch (error) {
    yield put(actions.getSavedFiltersFailure())
  }
}

export function * saveFilter ({ payload }: DefaultAction<any>) {
  try {
    const response: AxiosResponse = yield call(services.saveFilters, payload)
    if (response.status === 201) {
      yield put(actions.saveFiltersSuccess())
      yield put(actions.getSavedFiltersRequest({ id: payload.id }))
      yield put(showAlertMessage({
        message: 'Filtro salvo com sucesso.',
        severity: 'success',
        duration: 5000
      }))
    }
  } catch (error) {
    yield put(actions.saveFiltersFailure())
    yield put(showAlertMessage({
      message: 'Erro ao salvar filtro.',
      severity: 'error',
      duration: 5000
    }))
  }
}

export function * updateFilter({ payload }: DefaultAction<any>) {
  try {
    const response: AxiosResponse = yield call(services.updateFilters, payload)
    if (response.status === 200) {
      yield put(actions.updateFiltersSuccess())
      yield put(actions.getSavedFiltersRequest({ id: payload.id }))
      yield put(showAlertMessage({
        message: 'Filtro salvo com sucesso.',
        severity: 'success',
        duration: 5000
      }))
    }
  } catch (error) {
    yield put(actions.updateFiltersFailure())
    yield put(showAlertMessage({
      message: 'Erro ao salvar filtro.',
      severity: 'error',
      duration: 5000
    }))
  }
}

export function * deleteFilter({ payload }: DefaultAction<any>) {
  try {
    const response: AxiosResponse = yield call(services.deleteFilters, payload)
    if (response.status === 200) {
      yield put(actions.getSavedFiltersRequest({ id: payload.id }))
      yield put(showAlertMessage({
        message: 'Filtro deletado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
    }
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Falha ao deletar filtro.',
      severity: 'error',
      duration: 5000
    }))
    throw Error(String(error))
  }
}

function * deleteAllFilter({ payload }: DefaultAction<any>) {
  try {
    const response: AxiosResponse = yield call(services.deleteAllFilters, payload)
    if (response.status === 200 || response.status === 204) {
      yield put(actions.getSavedFiltersRequest({ id: payload.id }))
      yield put(showAlertMessage({
        message: 'Filtros deletados com sucesso.',
        severity: 'success',
        duration: 5000
      }))
    }
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Falha ao deletar todos os filtros.',
      severity: 'error',
      duration: 5000
    }))
    throw Error(String(error))
  }
}

//  Watchers
export function * watchFilers() {
  yield takeLatest(actions.getSavedFiltersRequest.type as unknown as TakeableChannel<unknown>, fetchSavedFilters)
  yield takeLatest(actions.saveFiltersRequest.type as unknown as TakeableChannel<unknown>, saveFilter)
  yield takeLatest(actions.updateFiltersRequest.type as unknown as TakeableChannel<unknown>, updateFilter)
  yield takeLatest(actions.deleteFiltersRequest.type as unknown as TakeableChannel<unknown>, deleteFilter)
  yield takeLatest(actions.deleteAllFiltersRequest.type as any, deleteAllFilter)
}

export default function * filtersSaga () {
  yield all([watchFilers()])
}
