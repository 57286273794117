import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { updateCredentialsRequest, getRoleRequest } from '../store/auth/actions'
import { RootState } from '../store/configureStore'

const LoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { auth: { isLogged } } = useSelector((state: RootState) => state)

  useEffect(() => {
    const queryString = 'token'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      dispatch(updateCredentialsRequest({ token: String(queryparams.get(queryString)) }))
      dispatch(getRoleRequest())
    }
  }, [])

  useEffect(() => {
    isLogged && navigate('/')
  }, [isLogged])

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress color='secondary' />
      </Box>
    </Container>
  )
}

export default LoginPage
