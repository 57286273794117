import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { convertDate, cpfFormatter } from '../../../utils/functions'
import { Parent } from '../../../models/IClassroom'
import ParentDataTitle from '../title/ParentDataTitle'
import ParentDataField from '../title/ParentDataField'

interface Data {
  data: Parent
  index: number
}

function ParentContainer(data: Data) {
  const parent: Parent = data?.data
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
    >
      <Box>
        <Grid container rowSpacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title={`Nome do responsável ${data.index + 1}`} />
            <ParentDataField text={parent?.name ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='CPF' />
            <ParentDataField text={(parent?.taxpayer_number && cpfFormatter(parent?.taxpayer_number)) ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Data de nascimento' />
            <ParentDataField text={(parent?.birthday && convertDate(parent?.birthday)) ?? '-'} />
          </Grid>
          <Grid pr={'5px'} item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Email' />
            <ParentDataField text={parent?.email ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Contato' />
            <ParentDataField text={parent?.phone ?? '-'} />
          </Grid>
        </Grid>
      </Box>
      <Typography
        sx={{
          color: '#9F9F9F',
          fontSize: '14px',
          fontWeight: 600,
          mt: '32px',
          mb: '16px'
        }}
      >
        Endereço
      </Typography>
      <Box>
        <Grid container rowSpacing={3}>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='CEP' />
            <ParentDataField text={parent?.additional_data?.address?.zipcode ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Rua' />
            <ParentDataField text={parent?.additional_data?.address?.street ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Número' />
            <ParentDataField text={parent?.additional_data?.address?.number ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Complemento' />
            <ParentDataField text={parent?.additional_data?.address?.complement ?? '-'} />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <ParentDataTitle title='Bairro' />
            <ParentDataField text={parent?.additional_data?.address?.neighborhood ?? '-'} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ParentContainer
