import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

export const StudentsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  fontWeight: 500,
}))
export const BoxStyled = styled(Box)(() => ({
  '.MuiTable-root': {
    borderSpacing: 0,
  },
  '.MuiTableHead-root > tr th': {
    paddingBottom: '16px',
    textAlign: 'center',
  },
  '.MuiTableBody-root > tr td': {
    background: 'transparent',
  },
  '.MuiTableBody-root > tr:last-of-type td': {
    borderBottom: 'none',
  },
  '.MuiTableHead-root > tr > td': {
    borderRadius: 0,
  },
  '.MuiTableCell-root': {
    borderRadius: '0px !important',
    paddingBottom: '30px',
  },
  '.MuiTableContainer-root': {
    borderRadius: 0,
  },
  '.MuiTableBody-root': {
    borderRadius: '16px',
    overflow: 'hidden',
  },
  '.MuiTableBody-root > .MuiTableRow-root': {
    background: 'transparent',
  },
  '.MuiTableBody-root > tr:first-of-type td:first-of-type': {
    borderTopLeftRadius: '16px !important',
  },
  '.MuiTableBody-root > tr:first-of-type td:last-of-type': {
    borderTopRightRadius: '16px !important',
  },
  '.MuiTableBody-root > tr:last-of-type td:first-of-type': {
    borderBottomLeftRadius: '16px !important',
  },
  '.MuiTableBody-root > tr:last-of-type td:last-of-type': {
    borderBottomRightRadius: '16px !important',
  },
  '.MuiTableRow-root > th': {
    textAlign: 'center',
  },
}))
export const BoxStyle = styled(Box)({
  display: 'flex !important',
  flexDirection: 'column',
  justifyContent: 'center !important',
  gap: '40px !important',
  '& .MuiButtonBase-root': {
    padding: '4px 12px !important',
  },
})
export const Container = styled('div')(() => ({

  '& .MuiAccordionSummary-content': {
    height: '50px',
  },
  '.MuiAccordionSummary-root': {
    padding: '0px 32px',
  },
}))
