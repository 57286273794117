import { Box, Paper, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BoxStyled = styled(Box)({
  '.MuiTable-root': {
    borderSpacing: '0 24px'
  },
  '.MuiTableHead-root > tr > th': {
    padding: '0 16px 0 0'
  },
  '.MuiTableBody-root > tr': {
    background: '#FFF'
  },
  '.MuiTableBody-root > tr > td': {
    padding: '0 16px 0 0'
  }
})

export const Student = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  color: '#666',
  fontWeight: 600
}))

export const TableHeaderCell = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600
}))

export const TableBodyCell = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500
}))

export const PaperStyled = styled(Paper)(() => ({
  background: '#FFFFFF',
  borderRadius: '16px',
  padding: '6px 22px',

  '& .pagination': {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '32px 0 18px'
  }
}))

export const ImageContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '8px',

  '& .image': {
    maxWidth: '279px',

    '& > img': {
      width: '100%'
    }
  },

  '& .content': {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center'
  }
}))
