import * as React from 'react'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  padding: '4px 3px 4px 2px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: `2px auto ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}))

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    content: '""',
    backgroundImage:
      'url("data:image/svg+xml;charset=utf-8,%3Csvg width=\'16\' height=\'16\' viewBox=\'0 0 16 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath' +
      ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12.0458 0.235595C12.3299 0.537026 12.3158 1.01169 12.0144 1.29579L4.08456 8.76968C3.74829 9.08662 3.22042 9.07686 2.89609 8.7477L0.215757 6.02737C-0.074959 5.73231 ' +
      '-0.0714425 5.25745 0.223612 4.96674C0.518666 4.67602 0.993526 4.67954 1.28424 4.97459L3.51839 7.24207L10.9856 0.204214C11.287 -0.0798856 11.7617 -0.0658356 12.0458 0.235595Z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
}))

export default function CustomizedCheckbox(props: any) {
  return (
    <FormControlLabel
      sx={{
        marginLeft: 0,
        marginRight: '8px',
        '& .MuiTypography-root': {
          fontSize: '14px',
          color: '#9F9F9F'
        }
      }}
      control={<Checkbox
        sx={{
          padding: '4px',
          marginRight: '4px',
          '&:hover': { bgcolor: 'transparent' },
        }}
        color='default'
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox' }}
      />}
      {...props}
    />
  )
}
