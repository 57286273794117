import http from '../../utils/http'
import { ISubject } from './../../models/ISubject'

export const subjectsEndpoint = '/subjects'
const fetchModulesEndpoint = '/content_modules'

export const fetchModules = (params: object) => {
  return http.get(fetchModulesEndpoint, { params })
}

export const fetchSubjects = (params: object) => {
  return http.get('/subjects', { params }).then(({ data }) => {
    return data.map((subject:ISubject) => subject.id).join(',')
  })
}

export const fetchModuleById = ({ params, id }:any) => {
  return http.get(`${fetchModulesEndpoint}/${id}`, { params })
}
