/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled, useTheme } from '@mui/material/styles'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  'input::placeholder': {
    color: '#cecece !important'
  },
  '> input': {
    border: '1px solid #cecece',
    borderRadius: '8px',
    padding: '10.53px 18px',
    fontSize: '14px',
    width: '100%',
    height: '40px',
    color: '#9F9F9F'
  },
  '& input:focus-visible': {
    outlineColor: '#412A88 !important'
  }
}))

const EmailInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
  defaultFieldValue
}) => {

  const theme = useTheme()
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.target.value


    input.onChange(emailInput)
  }

  return (
    <>
      <Container>
        <input
          type='email'
          value={defaultFieldValue || input.value}
          placeholder='exemplo@examplo.com'
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Container>

      {touched && error && <div style={{ color: theme.palette.error.main, fontSize: '12px', marginTop: '-8px', textAlign: 'right' }}>{error}</div>}
    </>
  )
}

export default EmailInput
