import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import AsyncSelectInput from './AsyncSelectInput'
import { required } from '../../utils/formUtils'

const Container = styled(Box)(({
  background: '#f9f9f9',
  borderRadius: '8px',
  marginTop: '16px',
  padding: '14px 20px 30px'
}))

const SelectMultipleSubjects = ({
  isTeacher,
  userId,
  subjects,
  segments,
  highSchoolSegmentId,
  change,
  topics,
  initialValues
}: any) => {

  useEffect(() => {
    if (!initialValues && subjects && Array.isArray(subjects) && subjects.length > 0 && topics && subjects.length <= Object.keys(topics).length) {
      const newTopics = { ...topics }
      const x = Object.keys(newTopics).filter((key: any) => (
        subjects.some((allowedKey: any) => allowedKey.name !== key)
      ))

      for (let i = 0; i < x.length; i++) {
        delete newTopics[x[i]]
      }
      change('topics', newTopics)
    } else if (subjects && Array.isArray(subjects) && subjects.length === 0) {
      change('topics', undefined)
    }
  }, [subjects])

  const Slot = subjects && Array.isArray(subjects) && subjects.length > 0 ? Container : 'div'

  return (
    <div>
      <Field
        name='subject_ids'
        component={AsyncSelectInput}
        isMulti
        placeholder='Selecione a disciplina...'
        label='Disciplinas *'
        searchParam='name'
        validate={required}
        required
        request={{
          path: '/subjects',
          params: {
            basic: true,
            with_knowledge_area: true,
            teacher_user_id: isTeacher ? userId : null
          }
        }}
      />
      {segments && segments?.id === 4 && (
        <Slot>
          {subjects && Array.isArray(subjects) && subjects.length > 0 && subjects.map((item: any, index: number) => {
            return (
              <div key={`${item.name}-${index}`} style={{ marginTop: '16px' }}>
                <Field
                  name={`topics.${item.name}`}
                  component={AsyncSelectInput}
                  isMulti
                  placeholder='Selecione...'
                  label={`Tópicos - ${segments && segments?.name || 'Ens. Médio'} - ${subjects[index]?.name}`}
                  request={{
                    path: '/topics',
                    params: {
                      segment_ids: segments && segments?.id || highSchoolSegmentId || 4,
                      subject_ids: subjects[index]?.id
                    }
                  }}
                />
              </div>
            )
          })
          }
        </Slot>
      )}
    </div>
  )
}

export default SelectMultipleSubjects
