import http from '../../utils/http'

const teachersEndpoint = '/user_teachers'

export const createTeacherService = (payload: object) => {
  return http.post(teachersEndpoint, payload)
}
export const updateTeachersService = (payload: any) => {
  return http.put(`${teachersEndpoint}/${payload?.id}`, payload.data)
}
export const getAllTeachersService = ({params}: any) => {
  return http.get(`${teachersEndpoint}?school_unit_id=${params.school_unit_id}&page=${params.page}&per=${params?.per}`)
}
export const getTeacherByIdService = (payload: any) => {
  return http.get(`${teachersEndpoint}/${payload?.teacherId}?school_unit_id=${payload?.schoolUnitId}`)
}
