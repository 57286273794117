import React from 'react'
import BooksPublishForm from '../components/Books/form/BooksPublishForm'

const BooksPublishContainer = () => {
  return (
    <>
      <BooksPublishForm />
    </>
  )
}

export default BooksPublishContainer
