import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { ClassRoomType } from '../../components/Home/ClassroomsList'


interface TeachersClassroomsType {
  data?: ClassRoomType
}

export const fetchTeachersClassroomsRequest = createAction('teacherClassroom/FETCH_TEACHERS_CLASSROOM_REQUEST',withPayloadType<number>())
export const fetchTeachersClassroomsSuccess = createAction('classrooms/FETCH_TEACHERS_CLASSROOM_SUCCESS', withPayloadType<TeachersClassroomsType>())
export const fetchTeachersClassroomsFailure = createAction('classrooms/FETCH_TEACHERS_CLASSROOM_FAILURE', withPayloadType<string>())
