import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PencilIcon from '../../assets/components/HomePage/pencil.svg'
import InfoImage from '../../assets/components/HomePage/info-image.svg'

const StatusContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '24px',
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  marginTop: '24px',
  overflow: 'hidden',

  '& .content': {
    maxWidth: '640px',
    padding: '30px 0 30px 30px',

    '&_title': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',
      marginBottom: '16px',

      '& h3': {
        color: '#f9f9f9',
        fontSize: '20px',
        fontWeight: 600
      }
    },

    '&_description': {
      color: '#f9f9f9',
      fontSize: '16px',
      fontWeight: 400,
    }
  },

  '& .image': {
    background: '#55C2D3',
    '& img': {
      height: '100%',
      width: '100%',
    }
  },

  [theme.breakpoints.down(1367)]: {
    '& .content': {
      flex: 1.2,

      '&_title': {
        '& h3': {
          fontSize: '16px'
        }
      },

      '&_description': {
        '& p': {
          fontSize: '14px'
        }
      }
    },

    '& .image': {
      flex: 0.8
    }
  }
}))

const StatusCard = () => {
  return (
    <StatusContainer>
      <Box className='content'>
        <Box className='content_title'>
          <img src={PencilIcon} />
          <Typography component='h3'>Você sabia que...</Typography>
        </Box>

        <Box className='content_description'>
          <Typography>
            o Prof. é completo para o acompanhamento e desenvolvimento em tempo real dos seus alunos nas atividades. Além disso, oferece uma gama de recursos avançados para tornar sua experiência de ensino ainda mais eficaz.
          </Typography>
        </Box>
      </Box>

      <Box className='image'>
        <img src={InfoImage} />
      </Box>
    </StatusContainer>
  )
}

export default StatusCard
