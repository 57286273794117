import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import BooksUsed from '../Books/BooksMostUsed'
import SelectInputTable from '../../components/select/SelectInputTable'
import { styled } from '@mui/material/styles'
import 'swiper/css'
import { Swiper, SwiperSlide} from 'swiper/react'
import { Navigation } from 'swiper'
import { ReactComponent as BookSVG } from '../../assets/components/Books/book-icon.svg'
import { ReactComponent as NotebookSVG } from '../../assets/components/Books/notebook-icon.svg'
import { ReactComponent as SchoolSVG } from '../../assets/components/Books/school-icon.svg'
import { ReactComponent as ViewSVG } from '../../assets/components/Books/view-icon.svg'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }
}

const SelectBox = styled('div')(() => ({
 '& .MuiSelect-select' : {
    paddingLeft: '8px',
    textAlign: 'left'
 }
}))

const FullList = styled('a')(({theme}) => ({
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    color: theme.palette.primary.light,
    'svg':{
        marginRight: '8px'
    }
}))

const BoxStyled = styled('div')(() => ({
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    position: 'relative',
  
    '& .main-slider-prev-1, .main-slider-next-1': {
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#9f9f9f',
      borderRadius: '50%',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 2,
    },

    '& .main-slider-prev-1': {
       left:'-9px'
    },
    '& .main-slider-next-1': {
       right:'-9px'
    },
  
    '& .swiper-button-disabled': {
      opacity: 0.4,
    }, 
  }))


const BookMoreUsedView: any = ({ mostAccessed, subjectsOptions, unitOptions } : any) => {

  const [mostAccessedList, setMostAccessedList] = useState(mostAccessed)// eslint-disable-line

  const newmostAccessedList = mostAccessedList && [...mostAccessedList]
  const mostAccessedReverse = newmostAccessedList && newmostAccessedList.reverse()

  useEffect(() => {
    setMostAccessedList(mostAccessed)
  }, [mostAccessed])

  const subjectsList =  subjectsOptions?.map((subject: any) => ({ label: subject.name, value: subject.id }))
  const SubOptions = [
    ...(subjectsList ?? [])
  ]

  const unitsList =  unitOptions?.map((unit: any) => ({ label: unit.label, value: unit.value }))
  const UnitOptions = [
    ...(unitsList ?? [])
  ]

  const handleFilterSubjects: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value
    const filteredMostAccessed = mostAccessed.filter((book: any) =>
      book.subjects.some((sub: any) => sub.id === value)
    )
    value === 'all' ? setMostAccessedList(mostAccessed) : setMostAccessedList(filteredMostAccessed)
  }

  const handleFilterUnits: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value
    const filteredMostAccessed = mostAccessed.filter((book: any) =>
      book.units.some((unit: any) => unit.id === value)
    )
    value === 'all' ? setMostAccessedList(mostAccessed) : setMostAccessedList(filteredMostAccessed)
  }

  return (
    <Paper sx={paperStyled}>
      {
          <>            
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600 }}>
                        <BookSVG style={{ marginRight: 8 }} />
                        Livros mais utilizados
                    </Typography>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <NotebookSVG />
                        <SelectInputTable
                            border='none'
                            defaultValue='all'
                            name='selectStatus'
                            sx={{ width: '140px', textAlign: 'center' }}
                            onChange={handleFilterSubjects}
                            options={[
                              { label: 'Disciplinas:', value: 'all' },
                                ...SubOptions
                             ]}
                            />
                    </SelectBox>
                    <SelectBox sx={{ display: 'flex', alignItems: 'center'}}>
                        <SchoolSVG />
                        <SelectInputTable
                            border='none'
                            defaultValue='all'
                            name='selectStatus'
                            sx={{ width: '100px', textAlign: 'center' }}
                            onChange={handleFilterUnits}
                            options={[
                              { label: 'Escolas:', value: 'all' },
                                ...UnitOptions
                             ]}
                            />
                    </SelectBox>
            </Box>
                <BoxStyled>
                        <button className='main-slider-prev-1'>
                            <ArrowBackIosNewRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
                        </button>
                    <Swiper
                        slidesPerView={'auto'}
                        className='slider'
                        modules={[Navigation]}
                        navigation={{
                        prevEl: '.main-slider-prev-1',
                        nextEl: '.main-slider-next-1'
                        }}
                    >
                        {
                          mostAccessedReverse?.map((book : any, key: number) => {
                            return (
                              <SwiperSlide  key={key}>
                                  <BooksUsed
                                      book={book}
                                      tag={key===0}
                                  />
                              </SwiperSlide>                            
                            )
                            } 
                          )
                        }
                        
                    </Swiper>
                    <button className='main-slider-next-1'>
                        <ArrowForwardIosRoundedIcon sx={{ fontSize: '14px', color: '#fff' }} />
                    </button>
                </BoxStyled>
              </Grid>
              <Box sx={{ fontSize: '14px', display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}>
                <FullList href='#'>
                    <ViewSVG /> Lista completa
                </FullList>
              </Box>
            </Grid>
          </>
      }
    </Paper>
  )
}

export default BookMoreUsedView
