import { IQuestion } from './../../models/IQuestion'
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { union, merge } from 'lodash'
import { schema, normalize } from 'normalizr'
import { AnyAction } from 'redux'
import { DefaultAction } from '../../utils/types'
import {
  FetchExamPayload,
  SetExamFiltersPayload,
  FetchExamsQuestionsPayload,
  fetchExamsRequest,
  fetchExamsSuccess,
  fetchExamsFailure,
  fetchExamsQuestionsRequest,
  fetchExamsQuestionsSuccess,
  fetchExamsQuestionsFailure,
  importExamQuestionsRequest,
  importExamQuestionsSuccess,
  importExamQuestionsFailure
} from './actions'
import { showAlertMessage } from '../alert'
import * as services from './services'
import { setQuestionSelected } from '../questions/actions'
import { TakeableChannel } from 'redux-saga'

export function* fetchExams({ payload }: DefaultAction<FetchExamPayload>) {
  try {
    const { per, page } = yield select(state => state.exams.pagination)
    const filters: SetExamFiltersPayload = yield select(state => state.exams.filters)
    const pagination = {
      per: payload.per || per,
      page: payload.page || page
    }
    const config = {
      params: {
        ...payload,
        ...pagination,
        ...filters,
        with_questions: true
      }
    }
    const { data: { data }, headers } = yield call(services.fetchExams, config)
    const total = Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count'])
    const totalOfPages = Number(headers['x-total-count']) >= 10000
      ? Math.floor(10000 / per)
      : Number(headers['x-total-pages'])
    yield put(fetchExamsSuccess({ data, pagination: { ...pagination, total, totalOfPages } }))
  } catch (error: any) {
    yield put(fetchExamsFailure(error))
  }
}

export function* fetchExamsQuestions({ payload: id }: DefaultAction<FetchExamsQuestionsPayload>) {
  try {
    const examId: FetchExamsQuestionsPayload = yield select(state => state.exams.examQuestions)
    const isCached = examId && examId === id
    const params = {
      concourse_exam_ids: id,
      urls_only: true
    }
    if (!isCached) {
      const { data: { data } } = yield call(services.fetchExamQuestion, params)
      yield put(fetchExamsQuestionsSuccess({ id, data }))
    }
  } catch (error: any) {
    yield put(fetchExamsQuestionsFailure(error))
  }
}

export function* importExamsQuestions({ payload: { id } }: AnyAction) {
  try {
    const params = {
      concourse_exam_ids: id,
      urls_only: true
    }
    const { data: { data }, status } = yield call(services.fetchExamQuestion, params)
    const examQuestions: IQuestion[] | null = data

    const selectedQuestions: { allIds: any, byId: any, types: any } = yield select(
      state => state.questions.selectedQuestions
    )
    const questionSchema = new schema.Entity('questions')
    const normalizedExamQuestions: { entities: { questions: { [key: string | number]: IQuestion }[] }, result: [] } = normalize(examQuestions, [questionSchema])

    const itemsTyped = examQuestions?.map((item: any) => ({
      id: item.content_item_id ?? item.id,
      type: item.content_parents ? 'question' : 'content'
    }))

    const items = union(selectedQuestions.types, itemsTyped)
    const itemsFiltered = new Set()
    const itemsTypeFilterd = items.filter((question: any) => {
      const duplicateQuestion = itemsFiltered.has(question.id)
      itemsFiltered.add(question.id)
      return !duplicateQuestion
    })

    const allIds = union(
      selectedQuestions.allIds,
      normalizedExamQuestions.result
    )

    const newNormalizedExamQuestions = Object.keys(
      normalizedExamQuestions.entities.questions || []
    ).reduce((acc: { [key: string | number]: any }, key: string | number) => {
      acc[key] = {
        id: key,
        question: normalizedExamQuestions.entities.questions[key]
      }
      return acc
    }, {})

    const byId = merge({}, selectedQuestions.byId, newNormalizedExamQuestions)
    yield put(setQuestionSelected({ allIds, byId, types: itemsTypeFilterd }))

    if (status === 200) {
      yield put(importExamQuestionsSuccess())
      yield put(showAlertMessage({
        message: 'Prova importada com sucesso.',
        severity: 'success',
        duration: 4000
      }))
    }
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Erro ao importar prova.',
      severity: 'error',
      duration: 4000
    }))
    yield put(importExamQuestionsFailure())
  }
}

export function* watchFetchExams() {
  yield takeLatest(fetchExamsRequest.type as unknown as TakeableChannel<unknown>, fetchExams)
}

export function* watchFetchExamsQuestions() {
  yield takeLatest(fetchExamsQuestionsRequest.type as unknown as TakeableChannel<unknown>, fetchExamsQuestions)
}

export function* watchImportExamsQuestions() {
  yield takeLatest(importExamQuestionsRequest.type, importExamsQuestions)
}

export default function* examsSaga() {
  yield all([
    watchFetchExams(),
    watchFetchExamsQuestions(),
    watchImportExamsQuestions()
  ])
}
