import React, { useState } from 'react'
import { Box, Grid, Menu, MenuItem, Paper, Typography } from '@mui/material'
import MaterialButton from '@mui/material/Button'
import Button from '../components/button/Button'
import AuthorsCollapse from '../components/Books/collapse/AuthorsCollapse'
import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import BookIcon from '../assets/components/Books/book-icon.svg'
import { ReactComponent as OrderByIcon } from '../assets/components/Books/orderby-icon.svg'
import { authorsData } from './data.mockup'

const OrderByBtn = styled(MaterialButton)(({ theme }) => ({
  background: '#ffffff',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 8,
  color: theme.palette.text.primary,
  fontSize: 14,
  padding: '10px 34px',
  textTransform: 'none',
  '& svg': {
    marginRight: 8
  },

}))

const MenuStyle = styled(Menu)({
  '& .MuiMenu-list': {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    width: 132,
    '& .MuiMenuItem-root': {
      fontSize: 12,
      '&:not(li:last-of-type)': {
        borderBottom: '1px solid #D9D9D9',
      }
    }
  }
})

const TableHeader = styled(Grid)(() => ({
  '& .table_header': {
    color: '#9f9f9f',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%'
  }
}))

const BooksAuthorsContainer = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [anchorOrderByEl, setAnchorOrderByEl] = useState<HTMLElement | null>(null)  // eslint-disable-line
  const [order, setOrder] = useState<string>('desc')  // eslint-disable-line
  const openOrderBy = Boolean(anchorOrderByEl)

  const handleBackPage = () => {
    navigate('/books/reports')
  }

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px'
          }}
        >
          <img src={BookIcon} />
          <Typography
            component='h2'
            sx={{
              color: theme.palette.text.primary,
              fontSize: '18px',
              fontWeight: 600,
              letterSpacing: '0.18px'
            }}
          >
            Livros / Coautores
          </Typography>
        </Box>

        <Box>
          <Box>
            <OrderByBtn
              id='orderBy'
              aria-controls={openOrderBy ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openOrderBy ? 'true' : undefined}
              onClick={() => null}
              variant='outlined'
            >
              <OrderByIcon />{' Ordenar por'}
            </OrderByBtn>
            <MenuStyle
              id='orderByMenu'
              anchorEl={anchorOrderByEl}
              open={openOrderBy}
              onClose={() => null}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => null} id='asc'>Crescente</MenuItem>
              <MenuItem onClick={() => null} id='desc'>Decrescente</MenuItem>
            </MenuStyle>
          </Box>
        </Box>
      </Box>

      <Paper
        sx={{
          borderRadius: '16px',
          padding: '35px 16px',
          marginTop: '24px'
        }}
      >
        <TableHeader container columnSpacing={1}>
          <Grid item xs={3} className='table_header'>Autor</Grid>
          <Grid item xs={2} className='table_header'>Escola</Grid>
          <Grid item xs={2} className='table_header'>Disciplina(s)</Grid>
          <Grid item xs={1} className='table_header'>Livros</Grid>
          <Grid item xs={1} className='table_header'>Performance</Grid>
          <Grid item xs={1} className='table_header'>NPS</Grid>
          <Grid item xs={2} className='table_header'>Iterações</Grid>
        </TableHeader>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '27px' }}>
          {authorsData?.map((author) => {
            return (
              <AuthorsCollapse
                key={author.id}
                authorData={author}
              />
            )
          })}
        </Box>
      </Paper>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '32px'
        }}
      >
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  )
}

export default BooksAuthorsContainer
