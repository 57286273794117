import { AxiosResponse } from 'axios'
import { IUser } from '../../models/IUser'
import { IRole } from '../../models/IRole'
import {
  JsonFormat,
  JsonResponseFormat
} from '../../utils/types'
import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export type RolesJson = JsonFormat<'roles', IRole>

export type UserJson = JsonFormat<'users', IUser>

export type GetRoleResponse = JsonResponseFormat<UserJson[],(RolesJson)[]>

// interface
export interface UserCredentialsPayload {
  token: string;
}

export interface SetUserTokenSuccessPayload {
  // user: IUser,
  // user: IUser,
  credentials: object
}

export interface SetUserSuccessPayload {
  // user: IUser,
  user: any,
  // credentials: object
}

export interface GetRolesSuccessPayload {
  roles: IRole[]
}

export interface GetUserSuccessPayload {
  user: IUser
}

export interface DeleteUserAvatarPayload {
  id: string | number,
  afterEffect?(res:AxiosResponse): void;
  handleErrorEffect(): void;
}

export const setUserTokenRequest = createAction('users:SET_USER_TOKEN_REQUEST', withPayloadType<UserCredentialsPayload>())
export const setUserTokenSuccess = createAction('users:SET_USER_TOKEN_SUCCESS', withPayloadType<SetUserTokenSuccessPayload>())
export const setUserTokenRejected = createAction('users:SET_USER_TOKEN_REJECTED')

export const getUserDataRequest = createAction('users:GET_USER_DATA_REQUEST')
export const getUserDataSuccess = createAction('users:GET_USER_DATA_SUCCESS', withPayloadType<GetUserSuccessPayload>())
export const getUserDataRejected = createAction('users:GET_USER_DATA_REJECTED')

export const getRoleRequest = createAction('users:GET_ROLE_REQUEST')
export const getRoleSuccess = createAction('users:GET_ROLE_SUCCESS', withPayloadType<GetRolesSuccessPayload>())
export const getRoleRejected = createAction('users:GET_ROLE_REJECTED')

