import React from 'react'
import QuestionsForm from './QuestionsForm'

interface QuestionsFormProps {
  isTeacher?: any
  userId?: any
}

const QuestionsFormView = (props: QuestionsFormProps) => {
  const { isTeacher, userId } = props

  return (
    <div>
      <QuestionsForm isTeacher={isTeacher} userId={userId} />
    </div>
  )
}

export default QuestionsFormView
