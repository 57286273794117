import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#f9f9f9',
  borderRadius: '8px',

  '& .chapter_container': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',

    '& .chapter': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px'
    }
  }
}))

export const ChapterName = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',

  '& span': {
    fontSize: '16px',
    lineHeight: '150%'
  },

  '& .chapter_number': {
    color: theme.palette.primary.main,
    fontWeight: 700
  },

  '& .chapter_name': {
    color: theme.palette.text.primary,
    fontWeight: 500
  }
}))

 export const TableHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '24px',
  marginBottom: '16px',

  '& .thead_cell': {
    color: theme.palette.text.primary,
    flex: 1,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%',
    textAlign: 'center',

    '&:first-of-type': {
      flex: 3,
      fontSize: '18px',
      textAlign: 'left',
    },

    '&:nth-of-type(2)': {
      paddingLeft: '20px'
    }
  }
}))

export const TableBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  '& .table': {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    paddingBlock: '20px',

    '& .module': {
      alignItems: 'center',
      display: 'flex',
      flex: 3,
      gap: '4px',
      maxWidth: '100%',
      paddingLeft: '24px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',

      '& > span': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: '150%',

        '&:first-of-type': {
          fontWeight: 700,
        },

        '&:last-of-type': {
          fontWeight: 500,
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      }
    },

    '& .tbody_cell': {
      color: '#9f9f9f',
      flex: 1,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      textAlign: 'center',

      '&.progress': {
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        justifyContent: 'center'
      }
    }
  }
}))
