/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'

// Components
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  LinearProgress,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material'
import DialogModal from './DialogModal'
import Button from '../button/Button'
import { CancelButton } from '../Books/button/StyledButtons'

// Styles
import {
  Container,
  Content,
  LoadingContainer,
  Subject,
  SubTitle,
  Teacher,
  Title
} from './styles/ModalCoauthors.styles'

// Utils
import { stringAvatar } from '../../utils/functions'
import { Books, ISubject } from '../../models/IBooks'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { addCoauthorRequest, getTeachersWithSubjectsRequest } from '../../store/books/actions'

interface IModalCoauthorsProps {
  isOpen: boolean
  closeModal: () => void
  schoolUnitId?: number
  book?: Books
}

const ModalCoauthors: React.FC<IModalCoauthorsProps> = ({
  isOpen,
  closeModal,
  schoolUnitId,
  book
}) => {
  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const { teachers, currentItem } = useAppSelector((state) => state.books)
  const [checkedTeachers, setCheckedTeachers] = useState<any[]>([])

  const isFetching = teachers.isFetching

  useEffect(() => {
    isOpen && selectedUnit?.id && dispatch(getTeachersWithSubjectsRequest({ schoolUnitId: selectedUnit?.id }))
  }, [selectedUnit?.id, isOpen])

  useEffect(() => {
    if (book?.co_authors) {
      const coauthorIds = book.co_authors.map(coauthor => coauthor.user.id)
      setCheckedTeachers(coauthorIds)
    }
  }, [book?.co_authors])

  const handleCheckTeachers = (teacher: any) => {
    setCheckedTeachers(prevState => {
      if (prevState.includes(teacher.id)) {
        return prevState.filter(id => id !== teacher.id)
      } else {
        return [...prevState, teacher.id]
      }
    })
  }

  const handleAddCoauthor = () => {
    dispatch(addCoauthorRequest({
      bookId: book?.id,
      name: book?.name,
      description: book?.description,
      subject_ids: book?.subjects?.map((subject) => String(subject?.id)),
      co_author_user_ids: checkedTeachers,
      school_unit_id: selectedUnit?.id,
      closeModal
    }))
  }

  const actionsModal = (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        marginBlock: '54px',
        paddingInline: '16px'
      }}
    >
      <CancelButton
        onClick={closeModal}
      >
        Cancelar
      </CancelButton>

      <Button
        type='button'
        onClick={handleAddCoauthor}
        startIcon={
          currentItem.isSaving && (
            <CircularProgress
              size={20}
              variant='indeterminate'
              color='inherit'
            />
          )
        }
      >
        Confirmar
      </Button>
    </Box>
  )

  return (
    <>
      <DialogModal
        open={isOpen}
        handleClose={closeModal}
        actions={!isFetching && actionsModal}
        width='930px'
        height='90%'
      >
        {isFetching
          ? (
            <LoadingContainer>
              <Typography>Carregando...</Typography>
              <LinearProgress
                color='primary'
                variant='indeterminate'
                sx={{ height: '5px', maxWidth: '250px', width: '100%' }}
              />
            </LoadingContainer>
          ) : (
            <Container>
              <Title>Adicione coautores para enriquecer e coeditar este livro juntos</Title>
              <Grid container>
                <Grid xs={6} item>
                  <SubTitle>Corpo Docente</SubTitle>
                </Grid>
                <Grid xs={6} item>
                  <SubTitle>Disciplina ministrada</SubTitle>
                </Grid>
              </Grid>

              <Content>
                {teachers?.items?.map((teacher: any, index: number) => {
                  const isChecked = checkedTeachers.includes(teacher?.user?.id)
                  return (
                    <Box key={`${teacher?.user.id}-${index}`}>
                      <Grid container>
                        <Grid xs={6} item>
                          <Teacher>
                            <Checkbox
                              onChange={() => handleCheckTeachers(teacher?.user)}
                              checked={isChecked}
                            />

                            <Avatar
                              alt={teacher?.user?.name}
                              {...stringAvatar(teacher?.user?.name, 30, 30, 14)}
                              className='avatar'
                            />
                            <Typography className='teacher'>{teacher?.user?.name}</Typography>
                          </Teacher>
                        </Grid>

                        <Grid xs={6} item>
                          <Subject>
                            <Tooltip
                              arrow
                              title={
                                teacher?.user?.subjects?.length > 1 ? (
                                  teacher?.user?.subjects?.map((subject: ISubject) => subject.name).join(' / ')
                                ) : (
                                  String(teacher?.user?.subjects?.map((subject: ISubject) => subject.name))
                                )
                              }
                            >
                              <Typography className='name'>
                                {teacher?.user?.subjects?.map((subject: ISubject, index: number, items: any[]) => (
                                  <Fragment key={`${subject.id}-${index}`}>
                                    {subject.name}
                                    {items.length > 1 && (items.length - 1) !== index && ' / '}
                                  </Fragment>
                                ))}
                              </Typography>
                            </Tooltip>
                          </Subject>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
              </Content>
            </Container>
          )}
      </DialogModal>
    </ >
  )
}

export default ModalCoauthors
