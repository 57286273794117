import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { debounce, isEmpty } from 'lodash'
import { Grid, FormGroup, IconButton, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import Box from '@mui/system/Box'
import SearchInput from '../input/SearchInput'
import AsyncSelectInput from '../select/AsyncSelectInput'
import Button from '../button/Button'
import { FormValues } from '../Activities/form/ActivitiesSettingsForm'
import Label from '../label/Label'
import CheckboxInput from '../input/CheckboxInput'
import ModalDelete from '../Modal/ModalDelete'
import ModalSaveFilter from '../Modal/ModalSaveFilter'
import SelectItem from '../select/SelectItem'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  modulesItemsFetchRequest,
  fetchModuleByCodeRequest,
  modulesSaveUiFilters,
  modulesResetFilters
} from '../../store/modules/actions'
import { segmentsFetchRequest } from '../../store/education'
import FiltersContainer, { ButtonContainer, SearchContainer } from '../Activities/FiltersContainer'
import { saveFiltersRequest, getSavedFiltersRequest, deleteFiltersRequest, updateFiltersRequest } from '../../store/filters'
import { showAlertMessage } from '../../store/alert'

type Props = {
  userId?: any
  isTeacher?: any
  values?: FormValues
  change?: any
  filters?: any
  perPage?: number
  setPage?: any
  showYourFilters?: boolean
  chosenFilter?: string
  setChosenFilter?: any
  invalid?: boolean
}

const ModulesFilter = ({ isTeacher, userId, values, change, filters, perPage, setPage, showYourFilters, chosenFilter, setChosenFilter, invalid }: Props) => {
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState<string | undefined | null>('')
  const [searchId, setSearchId] = useState<string | null>('')
  const [openFilterNameModal, setOpenFilterNameModal] = useState(false)
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState<boolean>(false)
  const [filterSelectedToDelete, setFilterSelectedToDelete] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const theme = useTheme()
  const match = useMediaQuery(theme.breakpoints.down('md'))
  const modulesFilters = filters?.items?.modules
  const { segments: { items } } = useAppSelector(state => state.education)

  const segmentsFiltered = items?.filter((item) => item.id !== 1)
  const segmentOptions = segmentsFiltered?.map((item) => ({
    id: item.id,
    name: item.name
  }))

  useEffect(() => {
    dispatch(segmentsFetchRequest())
    dispatch(getSavedFiltersRequest({ id: userId }))
    return () => {
      change('modulesFilters', undefined)
      if (typeof setChosenFilter === 'function') {
        setChosenFilter('')
      }
      dispatch(modulesResetFilters())
    }
  }, [])

  useEffect(() => {
    if (isChecked && values
      && (values?.modulesFilters?.name?.length > 0 || values?.modulesFilters?.ids?.length > 0)) {
      setSearchTerm(values?.modulesFilters?.name)
      setSearchId(values?.modulesFilters?.ids)
    } else {
      setSearchTerm(values?.modulesFilters?.name ?? '')
      setSearchId(values?.modulesFilters?.ids ?? '')
    }
  }, [isChecked, values?.modulesFilters])

  const handleOpenConfirmModal = () => {
    (!isEmpty(values?.modulesFilters) || searchTerm || searchId) && setOpenFilterNameModal(true)
  }
  const handleCloseConfirmModal = () => {
    setOpenFilterNameModal(false)
    change('filterName', null)
  }

  useEffect(() => {
    filters?.isFilterSaved && handleCloseConfirmModal()
  }, [filters?.isFilterSaved])

  const handleFiltersChange = (value: any, filterName: string) => {
    let queryValue
    setPage(1)
    if (Array.isArray(value)) {
      queryValue = value.map(data => data.value || data.id).join()
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      queryValue = value.value || value.id
    } else {
      queryValue = value
    }

    if (queryValue) {
      const params = { [filterName]: queryValue, page: 1 }
      dispatch(modulesItemsFetchRequest(params))
    } else {
      const params = { [filterName]: undefined, page: 1 }
      dispatch(modulesItemsFetchRequest(params))
    }
    dispatch(modulesSaveUiFilters({ [filterName]: queryValue }))
  }

  useEffect(() => {
    if (values?.modulesFilters) {
      Object.entries(values.modulesFilters).forEach(([key, value]) => {
        if (key && key !== 'ids' && value !== null && value !== undefined) {
          handleFiltersChange(value, key)
        }
      })
    }
  }, [values?.modulesFilters])

  const changeFilterForm = useCallback(debounce((filter, value) => {
    change(filter, value)
  }, 500), [])

  const searchById = useCallback(debounce((value) => {
    if (value === '') {
      dispatch(modulesItemsFetchRequest({ page: 1 }))
    } else {
      dispatch(fetchModuleByCodeRequest({ id: value }))
    }
  }, 500), [])

  const handleSearchByTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const params = { name: value, page: 1 }
    setSearchTerm(value)
    setPage(1)
    dispatch(modulesSaveUiFilters(params))
    changeFilterForm('modulesFilters.name', value)
  }

  const handleSearchById = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const params = { ids: value, page: 1 }
    setSearchId(value)
    dispatch(modulesSaveUiFilters(params))
    change('modulesFilters.ids', value)
    searchById(value)
  }

  const cleanModulesFilters = () => {
    change('modulesFilters', null)
    setSearchTerm('')
    setSearchId('')
    setPage(1)
    setIsChecked(false)
    if (typeof setChosenFilter === 'function') {
      setChosenFilter('')
    }
    dispatch(modulesResetFilters())
    dispatch(modulesItemsFetchRequest({ per: perPage, page: 1 }))
  }

  const handleSaveFilters = () => {
    if (!values?.filterName) {
      dispatch(showAlertMessage({
        message: 'Digite um nome para o seu filtro',
        severity: 'warning'
      }))
      return
    }

    if (modulesFilters && Object.keys(modulesFilters).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }

    const params: any = {
      controller_name: 'modules',
      filter_name: values?.filterName,
      current_filters: { ...values?.modulesFilters },
    }

    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === undefined || params[key].length === 0) {
        delete params[key]
      }
    })

    if (!filters.items?.modules) {
      dispatch(saveFiltersRequest({ id: userId, params }))
    } else {
      dispatch(updateFiltersRequest({ id: userId, params }))
    }
    cleanModulesFilters()
  }

  const handleOpenDeleteFilterModal = (filterName: string) => {
    setOpenDeleteFilterModal(true)
    setFilterSelectedToDelete(filterName)
  }

  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false)
  }

  const handleDeleteFilters = (name: string) => {
    const params = {
      controller_name: 'modules',
      filter_name: name,
    }
    dispatch(deleteFiltersRequest({ id: userId, params: { ...params } }))
    cleanModulesFilters()
    handleCloseDeleteFilterModal()
  }

  const handleCheckboxModuleFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    cleanModulesFilters()
    setIsChecked(checked)
    if (checked && typeof setChosenFilter === 'function') {
      setChosenFilter(event.target.name)
    } else {
      typeof setChosenFilter === 'function' && setChosenFilter('')
    }
  }

  const checkboxModulesFilter = (
    <FormGroup key='modules_checkbox' sx={{ display: 'flex', flexDirection: match ? 'row' : 'column', gap: 1, flexWrap: 'wrap' }}>
      {modulesFilters && Object.keys(modulesFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={item}
            value={item}
            name={item}
            onChange={handleCheckboxModuleFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  return (
    <>
      <FiltersContainer>
        <SearchContainer>
          <Label>Filtros de pesquisa</Label>
          <SearchInput
            value={searchTerm}
            name='modulesFilters.terms'
            onChange={handleSearchByTerms}
            placeholder='Termos inclusos...'
            width={match ? '280px' : '227px'}
            backgroundColor='#F0F3F4'
            className='termsFilter'
          />
        </SearchContainer>

        <Grid container columnSpacing={2} rowSpacing={2} sx={{ maxWidth: match ? '100%' : '246px' }}>
          <Grid item xs={6} md={12}>
            <SearchInput
              border='1px solid #D9D9D9'
              borderRadius='8px'
              value={searchId}
              label='Código do Módulo'
              name='modulesFilters.ids'
              onChange={handleSearchById}
              placeholder='Pesquisar por código'
              inputType='number'
              isKeyDown
            />

          </Grid>

          <Grid item xs={6} md={12}>
            <Field
              name='modulesFilters.subject_ids'
              component={AsyncSelectInput}
              placeholder='Selecione a disciplina...'
              isMulti
              label='Disciplina'
              searchParam='name'
              request={{
                path: '/subjects',
                params: {
                  basic: true,
                  teacher_user_id: isTeacher ? userId : null
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Field
              name='modulesFilters.segment_ids'
              component={SelectItem}
              placeholder='Selecione o segmento...'
              label='Segmentos'
              options={segmentOptions ?? []}
            />
          </Grid>
        </Grid>
        {showYourFilters && modulesFilters && Object.keys(modulesFilters).length > 0 && (
          <>
            <Box sx={{ display: 'flex', mt: match ? 3 : 2 }}>
              <Typography
                sx={{
                  color: '#9F9F9F',
                  mr: 2,
                  fontSize: 14,
                  fontWeight: 700
                }}
                variant='body2'
                component='span'
              >
                Filtros:
              </Typography>
              {checkboxModulesFilter}
            </Box>
          </>
        )}
      </FiltersContainer>
      <ButtonContainer>
        <Button
          sx={{ mt: 2 }}
          variant='outlined'
          onClick={cleanModulesFilters}
          disabled={!values?.modulesFilters}
        >
          Limpar Filtro
        </Button>

        <Button
          sx={{ mt: 2 }}
          onClick={handleOpenConfirmModal}
          disabled={!values?.modulesFilters}
        >
          Salvar Filtro
        </Button>
      </ButtonContainer>

      <ModalSaveFilter
        isOpen={openFilterNameModal}
        closeModal={handleCloseConfirmModal}
        action={handleSaveFilters}
        disableAction={invalid}
      />

      <ModalDelete
        isOpen={openDeleteFilterModal}
        closeModal={handleCloseDeleteFilterModal}
        action={() => handleDeleteFilters(filterSelectedToDelete)}
        type='filter'
      />
    </>
  )
}

export default ModulesFilter
