/* eslint-disable no-console */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ButtonCardContainer } from '../Activities/FiltersContainer'
import BoxCard, { BoxHeader, BoxBottom, BoxOrderItems, BoxBody, BoxContentItems } from '../card/BoxCard'
import Button from '../button/Button'
import SelectInput from '../select/SelectInput'
import VideoEmbed from './ContentPLayer'
import ModalDelete from '../Modal/ModalDelete'
import { contentItemDeleteRequest } from '../../store/contents/actions'
import { renderStatus } from '../../utils/constants'
import BarCodeIcon from '../../assets/components/Content/barCode-icon.svg'
import EditIcon from '../../assets/components/Content/edit-icon.svg'
import TrashIcon from '../../assets/components/Content/trashWhite-icon.svg'
import FileImage from '../../assets/components/Content/file.svg'
import LinkIcon from '@mui/icons-material/Link'
import { ReactComponent as ListIcon } from '../../assets/components/Content/activityList-icon.svg'

const ChipStyled = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  color: theme.palette.text.secondary,
  height: 25,
  margin: 2,
}))

const Info = styled('div')({
  marginBottom: 1
})

const BoxContent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '32px',
  '& .image': {
    height: '88px',
    margin: '8px auto',
    textAlign: 'center',
    width: '88px',
    '& img': {
      height: '100%',
      width: '100%'
    }
  },
  '& .link': {
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%',
    '& span': {
      color: theme.palette.text.primary,
      fontWeight: 500
    },
    '& a': {
      color: theme.palette.text.primary,
      fontWeight: 500,
      textDecoration: 'underline',
      transition: 'color 0.3s ease',
      '&:hover': {
        color: theme.palette.primary.light,
      }
    },
    '& span:nth-of-type(2)': {
      display: 'block',
      fontWeight: 700,
      margin: '8px 0',
    }
  }
}))

const ContentCard = ({
  content,
  selectContentCheck,
  removeQuestion,
  index,
  questionOrder,
  changeOrder,
  totalQuestions,
  isAssociable,
  isToActivity,
  showSettings
}: any) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const generalTheme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { auth: { user } } = useAppSelector((state) => state)

  const handleSelectChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    changeOrder(event.target.value, content.id)
  }

  const deleteContent = (id: any) => {
    dispatch(contentItemDeleteRequest(id))
  }

  const handleOpenConfirmModal = () => {
    setOpenDeleteContentModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenDeleteContentModal(false)
  }

  return (
    <>
      <BoxCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <BoxHeader>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <img src={BarCodeIcon} alt='ícone do código da questão' />
                Código: <span className='info'>{content?.id}</span>
              </div>
              {showSettings && content?.who_created_user_id === user.id && !isAssociable && !isToActivity ?
                <ButtonCardContainer>
                  <Button
                    onClick={() => navigate(`/content/${content?.id}/edit`)}
                    sx={{ width: 227, backgroundColor: ' #412A88' }} disableElevation>
                    <img src={EditIcon} alt='editar questão' />Editar
                  </Button>
                  <Button
                    onClick={handleOpenConfirmModal}
                    type='button'
                    style={{
                      marginRight: 10,
                      backgroundColor: '#B7B2C8',
                      color: '#FFF'
                    }}
                    disableElevation>
                    <img src={TrashIcon} alt='deletar questão' />
                    Deletar
                  </Button>
                </ButtonCardContainer> : null}
            </div>
          </BoxHeader>
        </Box>
        <Divider sx={{
          borderBottom: '1px dashed #9F9F9F',
          margin: '0 10px 0px -24px',
          width: 'calc(100% + 49px)'
        }} />
        <BoxBody>
          <BoxContentItems>

            {content?.segments?.length > 0 && <Info sx={{ width: '30%' }}><span>Segmentos:</span>{content.segments.map((segment: { id: number, name: string }) => <ChipStyled key={segment.id} label={segment.name} variant='outlined' />)}</Info>}
            {content?.subjects && <Info sx={{ width: '70%' }}><span>Disciplina:</span>{content.subjects.map((subject: { id: number, name: string, basic: boolean }) => <ChipStyled key={subject.id} label={subject.name} variant='outlined' />)}</Info>}
            {content?.topics?.length > 0 && <Info sx={{ width: '100%' }}><span>Tópicos:</span>{content.topics.map((topic: { id: number, complete_name: string }) => <ChipStyled key={topic.id} label={topic.complete_name} variant='outlined' />)}</Info>}
            {content?.age_ranges?.length > 0 && <Info sx={{ width: '100%' }}><span>Faixa etária:</span>{content.age_ranges.map((age: { id: number, minimum_age: number, maximum_age: number }) => <ChipStyled key={age.id} label={`${age.minimum_age} - ${age.maximum_age}`} variant='outlined' />)}</Info>}
            {content?.name && <Info sx={{ width: '50%' }}><span>Nome:</span>{content.name}</Info>}
            {content?.description && <Info sx={{ width: '50%' }}><span>Descrição:</span>{content.description}</Info>}


            {content?.status && <div><span>Status:</span><ChipStyled label={renderStatus[content.status]} variant='outlined' /></div>}
            {content?.themes?.length > 0 && <div><span>Temas:</span>{content.themes.map((theme: { id: number, name: string }) => <ChipStyled key={theme.id} label={theme.name} variant='outlined' />)}</div>}

            {content?.variations?.length > 0 && <div><span>Variações:</span>{content.variations.map((variation: { id: number, name: string }) => <ChipStyled key={variation.id} label={variation.name} variant='outlined' />)}</div>}
            {content?.author?.name && <div><span>Autor da questão:</span><ChipStyled label={content.author.name} variant='outlined' /></div>}
            {content?.author?.year && <div><span>Ano da questão:</span><ChipStyled label={content.author.year} variant='outlined' /></div>}

          </BoxContentItems>


          {content?.content_format?.code === 'text' &&
            <Info
              sx={{ mt: 3, overflow: 'hidden' }}
              dangerouslySetInnerHTML={{ __html: content.schema_content?.value?.value ? content.schema_content?.value?.value : content.schema_content?.value }}
            />
          }
          {content?.content_format?.code === 'document' &&
            <BoxContent>
              <div className='image' style={{ overflow: 'hidden' }}>
                <img src={FileImage} alt='ícone de arquivo' width='100%' />
              </div>
              <div className='link'>
                <span>Link:</span>
                <a target='_blank' href={content.schema_content?.value?.value ? content.schema_content?.value?.value : content.schema_content?.value} rel='noreferrer'>
                  {content.schema_content?.value?.value ? content.schema_content?.value?.value : content.schema_content?.value}
                </a>
                {content.schema_content?.value?.size
                  ? <span>Tamanho: {(Number(content.schema_content?.value?.size) / 10 ** 6).toFixed(2)} MB</span>
                  : <span>Tamanho: {(Number(content.schema_content?.size) / 10 ** 6).toFixed(2)} MB</span>}
              </div>
            </BoxContent>
          }
          {content?.content_format?.code === 'video' &&
            <BoxContent>
              <div className='link'>
                <VideoEmbed urlVideo={content.schema_content?.value} />
              </div>
            </BoxContent>
          }
          {content?.content_format?.code === 'url' &&
            <BoxContent>
              <div className='image'>
                <div style={{
                  background: '#F6F8F9',
                  borderRadius: '100%',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <LinkIcon sx={{ fill: '#9f9f9f', fontSize: 60 }} />
                </div>
              </div>
              <div className='link'>
                <span>Link:</span>
                <a target='_blank' href={content.schema_content?.value?.value ? content.schema_content?.value?.value : content.schema_content?.value} rel='noreferrer'>
                  {content.schema_content?.value?.value ? content.schema_content?.value?.value : content.schema_content?.value}
                </a>
              </div>
            </BoxContent>
          }
        </BoxBody>
      </BoxCard>
      <BoxBottom>
        {questionOrder && (<BoxOrderItems>
          <ListIcon />
          <Typography>Ordem questão:</Typography>
          <SelectInput
            name='questionsOrder'
            border='none'
            defaultValue={index + 1}
            onChange={handleSelectChange}
            placeholder={index + 1}
            width='71px'
            options={[...Array(totalQuestions).keys()].map(number => {
              const optionValue = ++number
              return { label: optionValue, value: optionValue }
            })}
          />
        </BoxOrderItems>
        )}
        {selectContentCheck}
        {removeQuestion && <Button
          onClick={() => removeQuestion(content.id)}
          type='button'
          style={{
            marginRight: 10,
            backgroundColor: generalTheme.palette.error.main,
            borderColor: generalTheme.palette.error.main,
            color: '#FFF'
          }}
          variant='outlined'
        >
          Remover
        </Button>}
      </BoxBottom>

      <ModalDelete
        isOpen={openDeleteContentModal}
        closeModal={handleCloseConfirmModal}
        action={() => deleteContent(content?.id)}
        type='content'
      />
    </>
  )
}

export default ContentCard
