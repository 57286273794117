import React, { useEffect } from 'react'
import { Box, Paper, Skeleton, Typography } from '@mui/material'
import Button from '../components/button/Button'
import BookInformations from '../components/Books/BookInformations'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import {
  fetchBookPublishedRequest,
  fetchChaptersRequest
} from '../store/books/actions'
import { useTheme } from '@mui/material/styles'
import ChapterViewCollapse from '../components/Books/collapse/ChapterViewCollapse'

interface statusDataType {
  id: number
  status: string
  color: string
}

const statusData: statusDataType[] = [
  { id: 1, status: 'Concluídas', color: '#04AEC9' },
  { id: 2, status: 'Em andamento', color: '#412A88' },
  { id: 3, status: 'Agendado', color: '#BC9BFA' },
  { id: 4, status: 'Aguardando agendamento', color: '#F69E9E' }
]

const Loading = () => {
  return (
    <Box>
      <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '18px', marginBottom: '16px', width: '250px' }}
        />

        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={{ borderRadius: '8px', height: '180px', width: '114px' }}
          />

          <Box sx={{ flex: 1 }}>
            <Skeleton
              animation='wave'
              variant='text'
              sx={{ fontSize: '24px', width: '200px' }}
            />

            <Box sx={{ display: 'flex', gap: '40px' }}>
              <Skeleton
                animation='wave'
                variant='text'
                sx={{ fontSize: '16px', width: '50px' }}
              />
              <Skeleton
                animation='wave'
                variant='circular'
                width={24}
                height={24}
              />
            </Box>

            <Skeleton
              animation='wave'
              variant='rounded'
              sx={{ height: '90px', marginTop: '16px' }}
            />
          </Box>
        </Box>

        <Box sx={{ alignItems: 'center', display: 'flex', gap: '32px', marginTop: '50px' }}>
          {Array(3).fill(0).map((_, index) => {
            return (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{ width: '120px', height: '15px' }}
              />
            )
          })}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px'
          }}
        >
          {Array(5).fill(0).map((_, index) => {
            return (
              <Skeleton
                key={index}
                animation='wave'
                variant='rounded'
                sx={{
                  borderRadius: '8px',
                  height: '68px',
                  width: '100%'
                }}
              />
            )
          })}
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '8px',
            height: '52px',
            width: '140px'
          }}
        />
      </Box>
    </Box>
  )
}

interface IBooksDetailsContainerProps {
  id: number
}

const BooksDetailsContainerTwo: React.FC<IBooksDetailsContainerProps> = ({ id }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { chapters, bookToPublish } = useAppSelector(state => state.books)

  const itWasPublished = bookToPublish.book?.chapters &&
    bookToPublish.book.chapters?.length > 0 &&
    bookToPublish.book.chapters.some((item) => item?.classroom_chapters.length > 0)

  const itWasNotPublished = chapters.items && chapters.items?.length > 0

  const handleBackPage = () => {
    navigate('/books')
  }

  useEffect(() => {
    id && dispatch(fetchBookPublishedRequest({ id }))
    id && dispatch(fetchChaptersRequest({ bookId: id }))
  }, [])

  if (chapters.isFetching || bookToPublish.isFetching) {
    return (
      <Loading />
    )
  }

  return (
    <div>
      <Paper sx={{ borderRadius: '16px', padding: '21px 28px' }}>
        <Typography
          component='h2'
          sx={{
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '16px'
          }}
        >
          Seu Livro
        </Typography>

        <BookInformations
          bookAuthors={bookToPublish.book.authors}
          bookCover={bookToPublish.book.photo_url}
          bookCoauthors={bookToPublish.book.co_authors}
          bookDescription={bookToPublish.book.description}
          bookId={bookToPublish.book.id}
          bookProgress={undefined}
          bookSubjects={bookToPublish.book.subjects}
          bookTitle={bookToPublish.book.name}
        />

        <Box sx={{ display: 'flex', gap: '32px', margin: '50px 0 16px' }}>
          {statusData.map((status: statusDataType) => {
            return (
              <Box key={status.id} sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                <Box
                  component='span'
                  sx={{
                    background: status.color,
                    borderRadius: '50%',
                    height: '14px',
                    width: '14px'
                  }}
                />
                <Box
                  component='span'
                  sx={{
                    color: '#666666',
                    fontSize: '12px',
                    fontWeight: 500,
                    letterSpacing: '0.12px'
                  }}
                >
                  {status.status}
                </Box>
              </Box>
            )
          })}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {itWasPublished
            ? (
              bookToPublish.book?.chapters?.map((chapter) => {
                return (
                  <ChapterViewCollapse
                    key={chapter.id}
                    chapterData={chapter}
                  />
                )
              })
            ) : itWasNotPublished ? (
              chapters.items?.map((chapter) => {
                return (
                  <ChapterViewCollapse
                    key={chapter.id}
                    chapterData={chapter}
                  />
                )
              })
            ) : (
              <Box sx={{ marginTop: '16px' }}>
                <Typography
                  component='h2'
                  sx={{
                    color: '#9f9f9f',
                    fontSize: '20px',
                    fontWeight: 700,
                    textAlign: 'center'
                  }}
                >
                  Não há capítulos neste livro!
                </Typography>
              </Box>
            )
          }
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>
    </div>
  )
}

export default BooksDetailsContainerTwo
