import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  padding: '11px 15px',
}))

export const ClassroomContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  '& .grid': {
    alignItems: 'center',
    display: 'flex',

    '&:not(&:first-of-type)': {
      justifyContent: 'center'
    },

    '&.last_item': {
      justifyContent: 'flex-end !important',
    }
  },

  '& .classroom_name': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#f0f3f4',
    padding: '5.5px 18px',
    width: 'fit-content'
  },

  '& .performance, & .progress': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& p': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',

      '&:last-of-type': {
        color: theme.palette.text.primary
      }
    },

    '& .linear_progress': {
      // maxWidth: '48px',
      width: '60px',
    }
  },

  '& .total_students': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& span': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',

      '&:nth-of-type(1)': {
        color: '#9f9f9f'
      },

      '&:nth-of-type(3)': {
        fontSize: '12px'
      }
    },
  },

  [theme.breakpoints.down('lg')]: {
    '& .performance, & .progress': {
      '& p:first-of-type': {
        display: 'none'
      }
    }
  },

  [theme.breakpoints.down('md')]: {
    '& .classroom_name': {
      maxWidth: '215px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  }
}))

export const Label = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '28px',

  '& p': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '120%'
  },

  '& .label': {
    color: theme.palette.text.primary,
    marginRight: '4px',
  },

  '& .number_of_students': {
    color: theme.palette.primary.light,
    marginRight: '16px'
  },

  '& .performance_range': {
    borderRadius: '32px',
    color: '#ffffff',
    padding: '4px 16px',

    '&.high': {
      background: theme.palette.secondary.main,
    },

    '&.medium': {
      background: theme.palette.primary.light
    },

    '&.low': {
      background: theme.palette.error.main
    }
  }
}))

export const EmptyMessageBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#fff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',

  '& .box': {
    alignItems: 'center',
    display: 'flex',
    gap: '24px',
    justifyContent: 'center',

    '& .image': {
      maxWidth: '170px',

      '& > img': {
        width: '100%'
      }
    },

    '& .content': {
      maxWidth: '314px',

      '& .title': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.18px',
        marginBottom: '8px'
      },

      '& .info': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '0.14px'
      }
    }
  }
}))

export const TableHead = styled(Grid)(() => ({
  marginBottom: '16px',
  paddingInline: '24px',

  '& .thead_cell': {
    alignItems: 'center',
    color: '#9f9f9f',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',

    '&:not(&:first-of-type)': {
      justifyContent: 'center'
    }
  }
}))

export const TableBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}))
