import React, { useState } from 'react'
import { Avatar, Box, Collapse, Grid, IconButton, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { stringAvatar } from '../../../utils/functions'
import { IAuthorsData } from '../../../containers/data.mockup'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import BookDetailsCard from '../card/BookDetailsCard'

const TableCollapse = styled(Grid)(({ theme }) => ({
  padding: '8px',

  '& .table_cell': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',

    '& p': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
    },

    '& .high_performance': {
      color: theme.palette.secondary.main,
      fontWeight: 700
    },

    '& .low_performance': {
      color: theme.palette.error.main,
      fontWeight: 700
    }
  },

  '& .author': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '8px',
    maxWidth: '320px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&_name': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '120%',
      maxWidth: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },

  '& .schools': {
    maxWidth: '180px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  '& .subjects': {
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}))

interface IAuthorData {
  authorData: IAuthorsData
}

const AuthorsCollapse: React.FC<IAuthorData> = ({ authorData }) => {
  const [open, setOpen] = useState(false)

  return (
    <Box
      sx={{
        background: '#f5f5f5',
        borderRadius: '8px'
      }}
    >
      <TableCollapse container columnSpacing={1}>
        <Grid item xs={3} className='table_cell'>
          <Box
            className='author'
            onClick={() => setOpen(prevState => !prevState)}
          >
            <Avatar
              alt={authorData.name}
              variant='rounded'
              {...stringAvatar(authorData.name, 40, 40, 14)}
            />

            <Typography
              className='author_name'
              title={authorData.name}
            >
              {authorData.name}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={2} className='table_cell'>
          <Typography
            className='schools'
            title={authorData.schools.map((school) => school.name).join(' / ')}
          >
            {authorData.schools.map((school) => school.name).join(' / ')}
          </Typography>
        </Grid>

        <Grid item xs={2} className='table_cell'>
          <Typography
            className='subjects'
            title={authorData.subjects.map((subject) => subject.name).join(' / ')}
          >
            {authorData.subjects.map((subject) => subject.name).join(' / ')}
          </Typography>
        </Grid>

        <Grid item xs={1} className='table_cell'>
          <Typography>
            {authorData.number_of_books}
          </Typography>
        </Grid>

        <Grid item xs={1} className='table_cell'>
          <Typography className={authorData.performance >= 50 ? 'high_performance' : 'low_performance'}>
            {authorData.performance}%
          </Typography>
        </Grid>

        <Grid item xs={1} className='table_cell'>
          <Typography>
            {authorData.nps}
          </Typography>
        </Grid>

        <Grid item xs={2} className='table_cell'>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100% !important'
            }}
          >
            <Typography>
              {authorData.iterations}
            </Typography>

            <IconButton
              onClick={() => setOpen(prevState => !prevState)}
              sx={{ width: '18px', height: '18px' }}
            >
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>
        </Grid>
      </TableCollapse>

      <Collapse in={open}>
        <Box sx={{ padding: '8px 16px 32px' }}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            {authorData.books.map((book) => {
              return (
                <Grid item key={book.id} xs={12} xl={6}>
                  <BookDetailsCard bookData={book} />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}

export default AuthorsCollapse
