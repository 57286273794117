import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '16px',

  '& .wrapper': {
    cursor: 'pointer',
    padding: '8px 24px',
    alignItems: 'center',

    '& .student': {
      alignItems: 'center',
      display: 'flex',
      gap: '12px',

      '&_name': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0.18px'
      },

      '&_education': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.14px',
        lineHeight: '100%'
      }
    }
  }
}))

export const Value = styled(Typography)<any>(({ theme, textAlign }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '120%',
  textAlign: textAlign ?? 'center'
}))

export const Divider = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  marginBottom: '32px'
}))

export const CollpaseContainer = styled(Box)(({ theme }) => ({
  marginTop: '24px',
  padding: '0 24px 24px',

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px'
  },

  '& .student_info': {
    alignItems: 'center',
    display: 'flex',
    gap: '6px',
    justifyContent: 'space-between',
    marginTop: '22px',

    '& .progress': {
      alignItems: 'center',
      display: 'flex',
      flex: '0 1 320px',
      gap: '6px',

      '& .linear_progress': {
        backgroundColor: '#e1e1e1',
        maxWidth: '170px',
        width: '100%'
      },

      '& .label, & .value': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: '120%'
      },

      '& .label': {
        fontWeight: 600
      },

      '& .progress': {
        fontWeight: 500
      }
    },

    '& .performance': {
      alignItems: 'center',
      display: 'flex',
      gap: '6px',

      '& .label': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '120%'
      },

      '& .value': {
        background: theme.palette.secondary.main,
        borderRadius: '8px',
        color: '#ffffff',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        padding: '5.4px 18px',
        width: 'fit-content'
      }
    }
  }
}))

export const Error = styled(Box)(({ theme }) => ({
  '& > p': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center'
  }
}))
