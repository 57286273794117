import React from 'react'
import { useAppSelector } from '../store/hooks'
import QuestionsView from '../components/Questions/list/QuestionsView'
import { checkCurrentRole } from '../utils/functions'

const QuestionsContainer = () => {
  const { auth: { user }, persistable: { selectedRole } } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')
  
  return (
    <QuestionsView isTeacher={isTeacher} userId={user.id} />
  )
}

export default QuestionsContainer
