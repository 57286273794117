/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import StudentForm from '../components/Management/form/StudentForm'
import { convertDate, cpfFormatter } from '../utils/functions'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getStudentByIdRequest } from '../store/students/actions'
import { RootState } from '../store/configureStore'
import { LinearProgress } from '@mui/material'
import { useAppSelector } from '../store/hooks'

function StudentFormEditContainer() {

  const [formValues, setFormValues] = useState({} as any)
  const { state } = useLocation()
  const { currentItem, isFetching,  } = useAppSelector((state) => state.students)
  const dispatch = useDispatch()
  const studentId = state?.student?.id
  const student = currentItem.item
  const classrooms = student?.classrooms
  const currentClassroomId = state?.classroom?.id
  const currentStudentClassroom = classrooms?.find((classroom: any) => classroom?.id === currentClassroomId)
  const studentPersonalData = currentItem?.item?.user
    const parents: any = student?.parents?.map((parent: any) => {
    return {
      name: parent?.name,
      email: parent?.email,
      genre: parent?.genre,
      birthday: parent.birthday && convertDate(parent?.birthday),
      phone: parent?.phone,
      taxpayer_number: parent?.taxpayer_number && cpfFormatter(parent?.taxpayer_number),
      additional_data: parent.additional_data
    }
  })

  const studentClassrooms = student.classrooms.map((classroom) => {
    return  {
      classroom: {
          id: classroom?.id,
          name: classroom.name,
          grade: {
              id: classroom.grade.id,
              name: classroom.grade.name
          }
      },
      grade: classroom.grade.name
  }
  })

  const initialValues = {
    id: studentPersonalData?.id,
    name: studentPersonalData?.name,
    taxpayer_number: studentPersonalData?.taxpayer_number && cpfFormatter(studentPersonalData?.taxpayer_number),
    birthday: studentPersonalData?.birthday && convertDate(studentPersonalData?.birthday),
    email: studentPersonalData?.email,
    registration: student?.classroom_student?.registration,
    classrooms: studentClassrooms,
    school_segment: currentStudentClassroom?.grade?.name,
    phone: studentPersonalData?.phone,
    genre: studentPersonalData?.genre === 'male'
      ? { name: 'Masculino', value: 'male' }
      : studentPersonalData?.genre === 'female'
        ? { name: 'Feminino', value: 'female' }
        : undefined,
    street: studentPersonalData?.additional_data?.address?.street,
    zipcode: studentPersonalData?.additional_data?.address?.zipcode,
    neighborhood: studentPersonalData?.additional_data?.address?.neighborhood,
    complement: studentPersonalData?.additional_data?.address?.complement,
    number: studentPersonalData?.additional_data?.address?.number,
    parents: parents
  }

  useEffect(() => {
    if (studentId) {
      dispatch(getStudentByIdRequest(studentId))
    }
  }, [studentId])

  useEffect(() => {
    setFormValues({
      ...initialValues
    })
  }, [currentItem])

  return (
    <>
      {
        isFetching ? (<LinearProgress color='secondary' />) :
          (
            <StudentForm
              initialValues={formValues}
            />
          )
      }
    </>
  )
}

export default StudentFormEditContainer
