export interface IContent {
  id?: string | number | any
  name?: string
  description?: string
  language: string
  schema_content?: {
    file_name?: string
    original_file_name?: string
    size?: number
    type?: string
    value?: string
  } | {value?: string} | undefined
  segments?: { id: number | string, name: string }[]
  subjects?: { id: number | string, name: string }[]
  supported_platforms?: object
  original_cost?: null
  status?: null
  is_restricted?: null
  authorial?: boolean
  author?: string
  age_ranges?: { id: number, maximum_age: number, minimum_age: number }[] | null
  use_universe?: null
  content_format?: number | string | null
  skills?: string[]
  topics?: {
    id: number | string,
    name: string,
    complete_name: string,
    item_number: string,
    parent: object,
    segment: any,
    subject: {id: number | string, name: string}
  }[]
  variations?: string[]
  themes?: string[]
  is_question?: boolean
  parents?: string[]
  children?: string[]
  school_unit_id?: number
  who_created_user_id?: number
  content_item?: string
}

export const ContentValue = {
  id: 0,
  name: '',
  description: '',
  language: 'pt-BR',
  original_cost:null,
  status:null,
  is_restricted:null,
  authorial:false,
  author: '',
  use_universe:null,
  skills: [],
  topics: [],
  variations: [],
  themes: [],
  is_question: false,
  parents: [],
  children: [],
  school_unit_id: 0,
  who_created_user_id: 0,
  content_item: '',
  schema_content: {},
  age_ranges: [],
  content_format: null,
  supported_platforms: {}
}