import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const BtnContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

export const TitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600
  }
}))

export const ContentBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: '16px',
  padding: '24px 32px 36px 24px',
  marginTop: '32px'
}))

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginInline: 'auto',
  maxWidth: '1096px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '502px'
  }
}))

export const BoxCard = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  height: '200px',
  maxWidth: '247px',
  width: '100%',

  '.container': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    padding: '10px',

    '& .information': {
      display: 'flex',
      gap: '2px',

      '& span': {
        fontSize: '14px',
        letterSpacing: '0.14px',
        lineHeight: '120%',

        '&:first-of-type': {
          color: theme.palette.primary.light,
          fontWeight: 800
        },

        '&:last-of-type': {
          color: theme.palette.text.primary,
          fontWeight: 500
        }
      }
    }
  }
}))
