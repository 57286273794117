import React, { useCallback, useEffect, useState } from 'react'

// Components
import AsyncSelectInput from '../select/AsyncSelectInput'
import Box from '@mui/system/Box'
import Button from '../button/Button'
import CheckboxInput from '../input/CheckboxInput'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import FiltersContainer, { ButtonContainer, SearchContainer } from '../Activities/FiltersContainer'
import FiltersSelect from '../select/FiltersSelect'
import { FormGroup, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import Label from '../label/Label'
import ModalDelete from '../Modal/ModalDelete'
import ModalSaveFilter from '../Modal/ModalSaveFilter'
import SearchInput from '../input/SearchInput'
import SelectItem from '../select/SelectItem'
import SelectSubjectCombo from '../select/SelectSubjectCombo'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  contentsItemFetchRequest,
  schoolContentsItemsFetchRequest,
  contentsResetFilter
} from '../../store/contents/actions'
import { segmentsFetchRequest } from '../../store/education'
import {
  getSavedFiltersRequest,
  saveFiltersRequest,
  updateFiltersRequest,
  deleteFiltersRequest
} from '../../store/filters'
import { showAlertMessage } from '../../store/alert'

// Utils
import { debounce, isEmpty } from 'lodash'
import { useTheme } from '@mui/material/styles'
import { FormValues } from '../Activities/form/ActivitiesSettingsForm'
import { highSchoolSegmentId } from '../../utils/constants'

// Icons
import CancelIcon from '@mui/icons-material/Cancel'

interface Props {
  userId?: any
  isTeacher?: any
  isSchoolQuestions?: boolean
  values?: FormValues
  showAllFilters?: boolean
  showYourFilters?: boolean
  change?: any
  filters?: any
  perPage?: number
  setPage?: any
  isCustomContent?: boolean
  chosenFilter?: string
  setChosenFilter?: any
  invalid?: boolean
}

const ContentsFilter = ({ isTeacher, userId, isSchoolQuestions, values, showAllFilters, showYourFilters, isCustomContent, change, filters, setPage, perPage, chosenFilter, setChosenFilter, invalid }: Props) => {
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState<string | null>('')
  const [searchId, setSearchId] = useState<string | null>('')
  const [openFilterNameModal, setOpenFilterNameModal] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState<boolean>(false)
  const [filterSelectedToDelete, setFilterSelectedToDelete] = useState<string>('')
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const customContentsFilters = filters.items?.custom_contents
  const contentsFilters = filters.items?.contents
  const { segments: { items } } = useAppSelector(state => state.education)

  const segmentsFiltered = items?.filter((item) => item.id !== 1)
  const segmentOptions = segmentsFiltered?.map((item) => ({
    id: item.id,
    name: item.name
  }))

  const handleOpenConfirmModal = () => {
    (!isEmpty(values?.contentsFilters) || searchTerm || searchId) && setOpenFilterNameModal(true)
    isCustomContent && (!isEmpty(values?.customContentsFilters) || searchTerm || searchId) && setOpenFilterNameModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenFilterNameModal(false)
    change('filterName', null)
  }

  useEffect(() => {
    dispatch(segmentsFetchRequest())
    dispatch(getSavedFiltersRequest({ id: userId }))
    return () => {
      change('customContentsFilters', undefined)
      change('contentsFilters', undefined)
      if (typeof setChosenFilter === 'function') {
        setChosenFilter('')
      }
      dispatch(contentsResetFilter())
    }
  }, [])

  useEffect(() => {
    isChecked && values?.customContentsFilters?.terms?.length > 0 && setSearchTerm(values?.customContentsFilters?.terms ?? '')
    isChecked && values?.customContentsFilters?.ids?.length > 0 && setSearchId(values?.customContentsFilters?.ids ?? '')
    isChecked && values?.contentsFilters?.terms?.length > 0 && setSearchTerm(values?.contentsFilters?.terms ?? '')
    isChecked && values?.contentsFilters?.ids?.length > 0 && setSearchId(values?.contentsFilters?.ids ?? '')
    isCustomContent && !values?.customContentsFilters?.terms && setSearchTerm('')
    isCustomContent && !values?.customContentsFilters?.ids && setSearchId('')
  }, [isChecked, values?.contentsFilters, values?.customContentsFilters])

  useEffect(() => {
    filters?.isFilterSaved && handleCloseConfirmModal()
  }, [filters?.isFilterSaved])

  const handleFiltersChange = (value: any, filterName: string) => {
    let queryValue
    if (Array.isArray(value)) {
      queryValue = value.map(data => data.value || data.id).join()
    } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      queryValue = value.value || value.id
    } else {
      queryValue = value
    }
    setPage(1)

    if (queryValue) {
      const params = { [filterName]: queryValue, page: 1 }
      isSchoolQuestions
        ? dispatch(schoolContentsItemsFetchRequest(params))
        : dispatch(contentsItemFetchRequest(params))
    } else {
      const params = { [filterName]: undefined, page: 1 }
      isSchoolQuestions
        ? dispatch(schoolContentsItemsFetchRequest(params))
        : dispatch(contentsItemFetchRequest(params))
    }
  }

  useEffect(() => {
    if (!isCustomContent && values?.contentsFilters) {
      Object.entries(values.contentsFilters).forEach(([key, value]) => {
        if (key && value !== null && value !== undefined) {
          handleFiltersChange(value, key)
          if (key === 'subject_ids' && value.every((subject: any) => 'topic_ids' in subject)) {
            const topics: { id: string, name: string }[] = value.flatMap((subject: any) => subject.topic_ids)
            handleFiltersChange(topics, 'topic_ids')
          }
        }
      })
    }
    if (isCustomContent && values?.customContentsFilters) {
      Object.entries(values.customContentsFilters).forEach(([key, value]) => {
        if (key && value !== null && value !== undefined) {
          handleFiltersChange(value, key)
          if (key === 'subject_ids' && value.every((subject: any) => 'topic_ids' in subject)) {
            const topics: { id: string, name: string }[] = value.flatMap((subject: any) => subject.topic_ids)
            handleFiltersChange(topics, 'topic_ids')
          }
        }
      })
    }
  }, [values?.contentsFilters, values?.customContentsFilters])

  const changeFilterForm = useCallback(debounce((filter, value) => {
    change(filter, value)
  }, 500), [])

  const handleSearchByTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchTerm(value)
    setPage(1)
    isCustomContent
      ? changeFilterForm('customContentsFilters.terms', value)
      : changeFilterForm('contentsFilters.terms', value)
  }

  const handleSearchById: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setSearchId(value)
    isCustomContent
      ? changeFilterForm('customContentsFilters.ids', value)
      : changeFilterForm('contentsFilters.ids', value)
  }

  const cleanContentsFilters = () => {
    !isCustomContent && values?.contentsFilters && Object.entries(values.contentsFilters).forEach(([key,]) => {
      change(`contentsFilters.${key}`, undefined)
    })
    isCustomContent && values?.customContentsFilters && Object.entries(values.customContentsFilters).forEach(([key,]) => {
      change(`customContentsFilters.${key}`, undefined)
    })
    setSearchTerm('')
    setSearchId('')
    setPage(1)
    setIsChecked(false)
    if (typeof setChosenFilter === 'function') {
      setChosenFilter('')
    }
    dispatch(contentsResetFilter())
    isSchoolQuestions
      ? dispatch(schoolContentsItemsFetchRequest({ per: perPage, page: 1 }))
      : dispatch(contentsItemFetchRequest({ per: perPage, page: 1 }))
  }

  const handleSaveFilters = () => {
    if (!values?.filterName) {
      dispatch(showAlertMessage({
        message: 'Digite um nome para o seu filtro',
        severity: 'warning'
      }))
      return
    }
    if (isCustomContent && customContentsFilters && Object.keys(customContentsFilters).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }
    if (!isCustomContent && contentsFilters && Object.keys(contentsFilters).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }

    let params: any
    if (isCustomContent) {
      params = {
        controller_name: 'custom_contents',
        filter_name: values?.filterName,
        current_filters: { ...values?.customContentsFilters }
      }
    } else {
      params = {
        controller_name: 'contents',
        filter_name: values?.filterName,
        current_filters: { ...values?.contentsFilters }
      }
    }

    Object.keys(params).forEach(key => {
      if (params[key] === null || params[key] === undefined || params[key].length === 0) {
        delete params[key]
      }
    })

    if (!isCustomContent && !filters.items?.contents) {
      dispatch(saveFiltersRequest({ id: userId, params }))
    } else if (isCustomContent && !filters.items?.custom_contents) {
      dispatch(saveFiltersRequest({ id: userId, params }))
    } else {
      dispatch(updateFiltersRequest({ id: userId, params }))
    }
    cleanContentsFilters()
    if (typeof setChosenFilter === 'function') {
      setChosenFilter('')
    }
  }

  const handleCheckboxContentFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    cleanContentsFilters()
    setIsChecked(checked)
    if (checked && typeof setChosenFilter === 'function') {
      setChosenFilter(event.target.name)
    } else {
      typeof setChosenFilter === 'function' && setChosenFilter('')
    }
  }

  const handleOpenDeleteFilterModal = (filterName: string) => {
    setOpenDeleteFilterModal(true)
    setFilterSelectedToDelete(filterName)
  }

  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false)
  }

  const handleDeleteFilters = (name: string) => {
    const params = {
      controller_name: isCustomContent ? 'custom_contents' : 'contents',
      filter_name: name,
    }
    dispatch(deleteFiltersRequest({ id: userId, params: { ...params } }))
    cleanContentsFilters()
    handleCloseDeleteFilterModal()
  }

  const checkboxContentsFilter = (
    <FormGroup key='contents_checkbox' sx={{ display: 'flex', flexDirection: matches ? 'row' : 'column', gap: 1, flexWrap: 'wrap' }}>
      {contentsFilters && Object.keys(contentsFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={item}
            value={item}
            name={item}
            onChange={handleCheckboxContentFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  const checkboxCustomContentsFilter = (
    <FormGroup
      key='custom_contents_checkbox'
      sx={{ display: 'flex', flexDirection: matches ? 'row' : 'column', gap: matches ? '16px' : '8px', flexWrap: 'wrap' }}
    >
      {customContentsFilters && Object.keys(customContentsFilters).map((item, index) => (
        <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckboxInput
            key={item}
            value={item}
            name={item}
            onChange={handleCheckboxContentFilters}
            label={item}
            labelPlacement='end'
            checked={isChecked && chosenFilter === item}
          />
          <IconButton
            color='error'
            sx={{ width: '16px', height: '16px' }}
            onClick={() => handleOpenDeleteFilterModal(item)}
            title='Excluir filtro'
          >
            <CancelIcon sx={{ fontSize: '14px' }} />
          </IconButton>
        </Box>
      ))}
    </FormGroup>
  )

  return (
    <>
      <FiltersContainer>
        <SearchContainer>
          <Label>Filtros de pesquisa</Label>
          <SearchInput
            value={searchTerm}
            name={isCustomContent ? 'customContentsFilters.terms' : 'contentsFilters.terms'}
            onChange={handleSearchByTerms}
            placeholder='Termos inclusos...'
            width={matches ? '280px' : '227px'}
            backgroundColor='#F0F3F4'
            className='termsFilter'
          />
        </SearchContainer>

        <SearchInput
          border='1px solid #D9D9D9'
          borderRadius='8px'
          value={searchId}
          label='Código do conteúdo'
          name={isCustomContent ? 'customContentsFilters.ids' : 'contentsFilters.ids'}
          onChange={handleSearchById}
          placeholder='Pesquisar por código'
          width={matches ? '100%' : '227px'}
          inputType='number'
          isKeyDown
        />

        <Box sx={{ maxWidth: matches ? '100%' : '230px', marginBlock: '16px' }}>
          <FieldArray name={isCustomContent ? 'customContentsFilters.subject_ids' : 'contentsFilters.subject_ids'}>
            {({ fields }) => {
              return (
                <SelectSubjectCombo
                  name={isCustomContent ? 'customContentsFilters.subject_ids' : 'contentsFilters.subject_ids'}
                  nameTopics='topic_ids'
                  subjects={isCustomContent ? values?.customContentsFilters?.subject_ids : values?.contentsFilters?.subject_ids}
                  segment={{ id: 4, name: 'Ensino Médio' }}
                  isTeacher={isTeacher}
                  userId={userId}
                  fields={fields}
                  highSchoolSegmentId={highSchoolSegmentId}
                />
              )
            }}
          </FieldArray>
        </Box>

        <Box sx={{ maxWidth: matches ? '100%' : '230px' }}>
          <Grid container columnSpacing={matches ? 2 : 0} rowSpacing={2}>
            <Grid item xs={6} md={12}>
              <Field
                name={isCustomContent ? 'customContentsFilters.segment_ids' : 'contentsFilters.segment_ids'}
                component={SelectItem}
                placeholder='Selecione o segmento...'
                label='Segmentos'
                options={segmentOptions ?? []}
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Field
                name={isCustomContent ? 'customContentsFilters.format_ids' : 'contentsFilters.format_ids'}
                component={FiltersSelect}
                placeholder='Selecione o formato...'
                label='Formato'
                hasBorder
                options={[
                  { id: 1, name: 'Vídeo' },
                  { id: 4, name: 'Documento' },
                  { id: 5, name: 'Link' },
                  { id: 7, name: 'Texto' },
                ]}
              />
            </Grid>
          </Grid>
        </Box>


        {showAllFilters &&
          <>
            <Box sx={{ maxWidth: matches ? '100%' : '230px', mt: 2 }}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6} md={12}>
                  <Field
                    name='contentsFilters.variation_ids'
                    component={FiltersSelect}
                    placeholder='Selecione a variação'
                    searchParam='name'
                    label='Variações'
                    hasBorder
                    request={{
                      path: '/variations'
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={12}>
                  <Field
                    name='contentsFilters.author_ids'
                    component={AsyncSelectInput}
                    placeholder='Selecione o autor'
                    searchParam='name'
                    label='Autores'
                    request={{
                      path: '/authors',
                      params: {
                        paginate: true,
                        per: 50
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            {!isCustomContent && contentsFilters && Object.keys(contentsFilters).length > 0 && (
              <Box sx={{ display: 'flex', mt: matches ? 3 : 2 }}>
                <Typography
                  sx={{
                    color: '#9F9F9F',
                    mr: 2,
                    fontSize: 14,
                    fontWeight: 700
                  }}
                  variant='body2'
                  component='span'
                >
                  Filtros:
                </Typography>
                {checkboxContentsFilter}
              </Box>
            )}
          </>
        }
        {showYourFilters && !isCustomContent && contentsFilters && Object.keys(contentsFilters).length > 0 && (
          <>
            <Box sx={{ display: 'flex', mt: matches ? 3 : 2 }}>
              <Typography
                sx={{
                  color: '#9F9F9F',
                  mr: 2,
                  fontSize: 14,
                  fontWeight: 700
                }}
                variant='body2'
                component='span'
              >
                Filtros:
              </Typography>
              {checkboxContentsFilter}
            </Box>
          </>
        )}
        {isCustomContent && customContentsFilters && Object.keys(customContentsFilters).length > 0 && (
          <Box sx={{ display: 'flex', mt: matches ? 3 : 2 }}>
            <Typography
              sx={{
                color: '#9F9F9F',
                mr: 2,
                fontSize: 14,
                fontWeight: 700
              }}
              variant='body2'
              component='span'
            >
              Filtros:
            </Typography>
            {checkboxCustomContentsFilter}
          </Box>
        )}
      </FiltersContainer>

      <ButtonContainer>
        <Button
          sx={{ mt: 2 }}
          variant='outlined'
          onClick={cleanContentsFilters}
          disabled={!values?.customContentsFilters && !values?.contentsFilters}
        >
          Limpar Filtro
        </Button>

        <Button
          sx={{ mt: 2 }}
          onClick={handleOpenConfirmModal}
          disabled={!values?.customContentsFilters && !values?.contentsFilters}
        >
          Salvar Filtro
        </Button>
      </ButtonContainer>

      <ModalSaveFilter
        isOpen={openFilterNameModal}
        closeModal={handleCloseConfirmModal}
        action={handleSaveFilters}
        disableAction={invalid}
      />

      <ModalDelete
        isOpen={openDeleteFilterModal}
        closeModal={handleCloseDeleteFilterModal}
        action={() => handleDeleteFilters(filterSelectedToDelete)}
        type='filter'
      />
    </>
  )
}

export default ContentsFilter
