import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { uniq, keyBy } from 'lodash'
import { ISubject } from './../../models/ISubject'
import { DefaultAction } from '../../utils/types'
import {
  modulesItemsFetchRequest,
  modulesItemsFetchSuccess,
  modulesItemsFetchError,
  fetchModuleByIdRequest,
  fetchModuleByIdSuccess,
  fetchModuleByIdError,
  fetchModuleByCodeRequest,
  fetchModuleByCodeSuccess,
  fetchModuleByCodeError,
  importModuleRequest,
  importModuleSuccess,
  importModuleError,
  FetchModulesFiltersPayload
} from './actions'
import { showAlertMessage } from '../alert'
import * as services from './services'
import { IModule } from '../../models/IModule'
import { setQuestionSelected } from '../questions/actions'
import { TakeableChannel } from 'redux-saga'

export function * fetchModulesItems ({ payload }:  DefaultAction<FetchModulesFiltersPayload>) {
  try {
    const { auth, modules: { filters } } = yield select(
      state => state
    )
    const userRoles = auth.roles

    const fetchSubjectParams = {
      basic: true,
      teacher_user_id: auth.user.id
    }
    let subjectIds
    if (userRoles.includes('teacher')) {
      const { data } = yield call(services.fetchSubjects, fetchSubjectParams)
      subjectIds = data && data.map((subject:ISubject) => subject.id).join(',')
    }
    const params = {
      ...filters.query,
      ...filters.ui,
      ...payload,
      urls_only: true,
      is_fractal: true,
      with_questions: true,
      subject_ids: !filters.query.subject_ids && !filters.ui.subject_ids
        ? subjectIds
        : filters.query.subject_ids || filters.ui.subject_ids
    }
    const { data: { data }, headers } = yield call(services.fetchModules, params)
    const payloadData = {
      items: data,
      totalOfPages: Number(headers['x-total-count']) >= 10000
                      ? Math.floor(10000 / filters.query.per)
                      : Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count']),
      per: Number(headers['x-per-page']) || filters.query.per
    }
    yield put(modulesItemsFetchSuccess(payloadData))
  } catch (error) {
    yield put(modulesItemsFetchError())
  }
}

function* fetchModuleByCode({ payload: { id }}: AnyAction) {
  try {
    const { data: { data }} = yield call(services.fetchModuleById, { id })
    const result = {
      items: data,
      totalOfPages: 1,
      current: 1,
      total: 1,
      per: 1
    }
    yield put(fetchModuleByCodeSuccess(result))
  } catch(error) {
    yield put(fetchModuleByCodeError(error))
  }
}

export function * fetchModuleItemById ({ payload: { id } }:AnyAction) {
  try {
    const params = {
      urls_only: true
    }
    const { data: { data } } = yield call(services.fetchModuleById, { params, id })
    yield put(fetchModuleByIdSuccess(data))
  } catch (error) {
    yield put(fetchModuleByIdError())
  }
}

export function * importModuleItem ({ payload: { id } }: AnyAction) {
  const selectedQuestions:{ byId: any, allIds: any, types?: any } = yield select(state => state.questions.selectedQuestions)
  try {
    const params = {
      urls_only: true
    }

    const { data: { data }, status } = yield call(services.fetchModuleById, { id, params })
    const normalizeModuleContents = (module:IModule) => {
      const moduleItems = module?.content_module_items?.map(
        contentModuleItem => contentModuleItem.content_item
      ) || []

      return moduleItems.map((content:any) => ({
        question: content.question ? content.question : content,
        id: content.question ? content.question.content_item_id : content.id
      }))
    }

    const moduleContentsNormalized: {question: any, id: any}[] = normalizeModuleContents(data)
    const itemsTyped = moduleContentsNormalized.map((item: any) => {
      return {
        id: item.question.content_item_id ?? item.question.id,
        type: item.question.content_parents ? 'question' : 'content'
      }
    })

    const items = [...selectedQuestions.types, ...itemsTyped]
    const itemsFiltered = new Set()
    const itemsTypeFilterd = items.filter((question: any) => {
      const duplicateQuestion = itemsFiltered.has(question.id)
      itemsFiltered.add(question.id)
      return !duplicateQuestion
    })

    yield put(
      setQuestionSelected(
        {
          byId: {
            ...selectedQuestions.byId,
            ...keyBy(moduleContentsNormalized, 'id')
          },
          allIds: uniq([
            ...selectedQuestions.allIds,
            ...moduleContentsNormalized.map(content => content.id)
          ]),
          types: itemsTypeFilterd
        }
      )
    )

    if (status === 200) {
      yield put(importModuleSuccess())
      yield put(showAlertMessage({
        message: 'Módulo importado com sucesso.',
        severity: 'success',
        duration: 4000
      }))
    }

  } catch (error) {
    yield put(showAlertMessage({
      message: 'Erro ao importar módulo.',
      severity: 'error',
      duration: 4000
    }))
    yield put(importModuleError())
  }
}

export function * watchFetchModules () {
  yield takeLatest(modulesItemsFetchRequest.type as unknown as TakeableChannel<unknown>, fetchModulesItems)
}

export function * watchFetchModuleByCode() {
  yield takeLatest(fetchModuleByCodeRequest, fetchModuleByCode)
}

export function * watchFetchModuleById () {
  yield takeLatest(fetchModuleByIdRequest.type, fetchModuleItemById)
}

export function * watchImportModule () {
  yield takeLatest(importModuleRequest.type, importModuleItem)
}

export default function * modulesSaga () {
  yield all([
    watchFetchModules(),
    watchFetchModuleByCode(),
    watchFetchModuleById(),
    watchImportModule()
  ])
}
