/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from '@mui/material/styles'

export const CancelButton = styled('button')(({ theme }) => ({
  background: '#fff',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#B7B2C8',
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: theme.typography.fontFamily,
  padding: '14px 39px',
  ':hover': {
    background: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
}))
