import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useAppSelector } from '../../../store/hooks'
import QuestionsList from './QuestionsList'
import QuestionsFilter from '../QuestionsFilter'
import { FormValues } from '../../Activities/form/ActivitiesSettingsForm'

interface QuestionViewProps {
  isTeacher: any
  userId?: any
}

const formInitialValues = {
  subject_ids: null
}

const QuestionsView = (props: QuestionViewProps) => {
  const { isTeacher, userId, } = props
  const [formValues, setFormValues] = useState<any>({})
  const [perPage, setPerPage] = useState<number>(5)
  const [page, setPage] = useState<number>(1)
  const [chosenFilter, setChosenFilter] = useState<string>('')
  const { filters } = useAppSelector(state => state)

  const filtersValues = { ...filters?.items?.custom_questions }

  useEffect(() => {
    if (filtersValues && Object.keys(filtersValues).length > 0 && chosenFilter.length > 0) {
      setFormValues({
        customQuestionsFilters: {
          ...filtersValues[chosenFilter],
          years_of_application: filtersValues.years_of_application ? new Date(String(filtersValues.years_of_application)) : null
        }
      })
    } else {
      setChosenFilter('')
      setFormValues({})
    }
  }, [chosenFilter])

  const handleSubmit = () => {
    setFormValues(formInitialValues)
  }

  return (
    <>
      <Grid item xs={12} md={3}>
        <Form
          sx={{ display: 'flex', gap: 2, width: '100%' }}
          initialValues={formValues}
          mutators={{ ...arrayMutators }}
          onSubmit={handleSubmit}
          render={({ values, form, invalid}) => {
            return (
              <>
                <Box>
                  <QuestionsFilter
                    isTeacher={isTeacher}
                    userId={userId}
                    isSchoolQuestions
                    isCustomQuestion
                    showYourFilters
                    filters={filters}
                    values={values as FormValues}
                    change={form.change}
                    perPage={perPage}
                    setPage={setPage}
                    chosenFilter={chosenFilter}
                    setChosenFilter={setChosenFilter}
                    invalid={invalid}
                  />
                </Box>
              </>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <QuestionsList
          selectedQuestions={null}
          selectQuestion={() => {
            return (null)
          }}
          selectAllItems={() => {
            return (null)
          }}
          isAllItemsSelected={() => {
            return (null)
          }}
          isSchoolQuestions
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
        />
      </Grid>
    </>
  )
}

export default QuestionsView
