import React from 'react'

import Header from '../Header/Header'
import styles from './styles.module.scss'
import Sidebar from '../Sidebar/Sidebar'
import { useSidebarContext } from '../../contexts/SidebarContext'

type DashboardProps = PropsWithChildren<{
  title: string
}>

const DashboardTemplate = ({ children, title }: DashboardProps) => {
  const { isDrawerOpen } = useSidebarContext()

  return (
    <div className={styles.dashboard} style={{ gridTemplateColumns: isDrawerOpen ? '200px 1fr' : '72px 1fr' }}>
      <Sidebar />
      <Header
        className={styles.dashboardNavbar}
        title={title}
      />
      <div className={styles.dashboardContent}>
        {children}
      </div>
    </div>
  )
}

export default DashboardTemplate
