
import { createReducer } from '@reduxjs/toolkit'

import {
  booksChangePagination,
  getBooksDeletedByUserFailure,
  getBooksDeletedByUserRequest,
  getBooksDeletedByUserSuccess
} from './actions'
import { AnyAction } from 'redux-saga'
import { BookDeletedState } from './types'

const initialState: BookDeletedState = {
  items: [],
  isFetching: false,
  isError: false,
  messageError: '',
  pagination: {
    current: 1,
    totalPages: 0,
    total: 0
  }
}

const booksDeletedReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBooksDeletedByUserRequest, (state: BookDeletedState): BookDeletedState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(getBooksDeletedByUserSuccess, (state: BookDeletedState, action: AnyAction): BookDeletedState => ({
      ...state,
      items: action.payload,
      isFetching: false,
      isError: false,
      messageError: '',
    }))
    .addCase(getBooksDeletedByUserFailure, (state: BookDeletedState): BookDeletedState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(booksChangePagination, (state: BookDeletedState, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        current: action.payload.current
      }
    }))
})

export default booksDeletedReducer
