import React, { useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { FieldRenderProps } from 'react-final-form'

interface CheckmarkInputProps extends FieldRenderProps<boolean, HTMLElement> {
  label?: string;
  sx?: React.CSSProperties
}

const CheckmarkInput: React.FC<CheckmarkInputProps> = ({ input, label, sx, ...props }) => {
  const [checked, setChecked] = useState<boolean>(input.value || false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(e.target.checked)
    setChecked(e.target.checked)
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={label}
      sx={sx}
      {...props}
    />
  )
}

export default CheckmarkInput