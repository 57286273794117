import React from 'react'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import { ssoUrl, profAppUrl } from '../utils/constants'
import { encodeQueryData } from '../utils/functions'
import Students from '../assets/students.png'
import logo from '../assets/logo.svg'
import styles from './styles.module.scss'

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}))

const NonLoggedPage = () => {
  const {
    REACT_APP_SERVER_ENV = 'local',
    REACT_APP_API_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_SSO_ENV = REACT_APP_SERVER_ENV,
    REACT_APP_PROJECT_ID = 18,
    REACT_APP_URL_STAGING
  } = process.env

  const urlRedirect = `${REACT_APP_URL_STAGING || profAppUrl[REACT_APP_API_ENV]}/login`
  const data = {
    app_id: REACT_APP_PROJECT_ID,
    url_redirect: urlRedirect
  }
  const urlString = encodeQueryData(data)
  const url = `${ssoUrl[REACT_APP_SSO_ENV]}?${urlString}`

  return (
    <div className={styles.login}>
      <div className={styles.login__section}>
        <div className={styles.login__students}>
          <img src={Students} alt='imagem de estudantes' />
        </div>
        <div className={styles.login__logo}>
          <img src={logo} alt='logo' />
        </div>
        <div className={styles.login__button}>
          <ButtonStyled href={url}>Login</ButtonStyled>
        </div>
      </div>
    </div>
  )
}

export default NonLoggedPage






