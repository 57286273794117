import React, { useEffect, useState } from 'react'
import { SecondaryTitle } from '../../title/Title'
import { Box, Grid, Typography } from '@mui/material'
import { DataField } from '../title/StyledTitle'
import { Field } from 'react-final-form'
import { ButtonOnlyIcon } from '../buttons/StyledButtons'
import TextInput from '../../input/TextInput'
import { required } from '../../../utils/formUtils'
import CepInput from '../../input/CepInput'
import { ReactComponent as SearchIcon } from '../../../assets/components/ManagersPage/Search-icon.svg'

const AddressForm = ({ change, values, name }: any) => {

  const [cepError, setCepError] = useState('')

  const handleSearchZipCode = async (zipCode: any) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
      const data = await response.json()
      if (data.erro || data.erro === 'true') {
        setCepError('CEP não encontrado.')
      } else {
        change(name ? `${name}.street` : 'street', data.logradouro)
        change(name ? `${name}.neighborhood` : 'neighborhood', data.bairro)
        setCepError('')
      }
    } catch (error) {
      setCepError('CEP não encontrado.')
    }
  }

  useEffect(() => {
    values?.length === 9 && handleSearchZipCode(values)
  }, [values])

  return (
    <>
      <Box>
        <SecondaryTitle sx={{ mt: '32px' }}>Endereço</SecondaryTitle>
        <Grid container rowSpacing={'24px'} columnSpacing={'25px'}>
          <Grid item lg={3} md={3}>
            <DataField>
              <div>
                CEP
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <Field
                  name={name ? `${name}.zipcode` : 'zipcode'}
                  component={CepInput}
                  validate={required}
                  placeholder='00000-000'
                />
                <span>
                  <ButtonOnlyIcon
                    type='button'
                    onClick={() => handleSearchZipCode(values)}
                    sx={{ background: '#412A88', borderRadius: '50%', height: '40px', width: '40px' }}>
                    <SearchIcon />
                  </ButtonOnlyIcon>
                </span>
              </Box>
            </DataField>
            {cepError && <Typography
              sx={{
                fontWeight: 400,
                fontSize: '0.75rem',
                lineHeight: '1.66',
                color: '#F69E9E'
              }}
            >
              {cepError}
            </Typography>
            }
          </Grid>
          <Grid item lg={6} md={6} />
          <Grid item lg={6} md={6}>
            <DataField>
              <div>Rua</div>
              <Field
                name={name ? `${name}.street` : 'street'}
                component={TextInput}
                validate={required}
                placeholder='Insira o nome da rua'
              />
            </DataField>
          </Grid>
          <Grid item lg={2} md={6}>
            <DataField>
              <div>Número</div>
              <Field
                name={name ? `${name}.number` : 'number'}
                component={TextInput}
                validate={required}
                placeholder='Insira o número'
              />
            </DataField>
          </Grid>
          <Grid item lg={2} md={6}>
            <DataField>
              <div>Complemento</div>
              <Field
                name={name ? `${name}.complement` : 'complement'}
                component={TextInput}
                placeholder='Insira o complemento'
              />
            </DataField>
          </Grid>
          <Grid item lg={2} md={6}>
            <DataField>
              <div>Bairro</div>
              <Field
                name={name ? `${name}.neighborhood` : 'neighborhood'}
                component={TextInput}
                validate={required}
                placeholder='Insira o nome do bairro'
              />
            </DataField>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AddressForm
