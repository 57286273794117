import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import { styled } from '@mui/material/styles'
import { ReactComponent as AddIcon } from '../../assets/components/Button/add-white-icon.svg'

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '8px',
  color: '#ffffff',
  fontSize: '16px',
  padding: '16px 48px',
  paddingInline: '24px',
  height: '50px',
  textTransform: 'capitalize',
  transition: 'background 0.3s ease-in-out',
  width: '165px',

  '&.Mui-selected': {
    color: '#ffffff',
    background: '#2d1d5f',

    '&:hover': {
      background: '#2d1d5f',
    }
  },

  '&:hover': {
    background: '#2d1d5f'
  }
}))

interface CheckButtonProps {
  label: string
  value: string | number
  checked?: boolean
  onChange: (checked: boolean, value: number | string, content: any) => void
}

const CheckButton: React.FC<CheckButtonProps> = ({ label, value, checked, onChange }) => {
  const [selected, setSelected] = useState(false)

  const selectContent = (event: any) => {
    setSelected(!selected)
    onChange(!selected, event.target.value, value)
  }

  return (
    <ToggleButtonStyled
      value={value}
      selected={checked}
      onChange={selectContent}
    >
      <AddIcon style={{ marginRight: '8px' }} />
      {label}
    </ToggleButtonStyled>
  )
}

export default CheckButton
