import React from 'react'

import ActivitiesListContainer from '../containers/ActivitiesListContainer'

const ActivitiesListPage = () => {
  return (
    <div>
      <ActivitiesListContainer />
    </div>
  )
}

export default ActivitiesListPage