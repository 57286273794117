import React, { useEffect, useState } from 'react'
import ContentsList from '../list/ContentsList'
import ContentsFilter from '../ContentsFilter'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { Box, Grid } from '@mui/material'
import { useAppSelector } from '../../../store/hooks'
import { FormValues } from '../../Activities/form/ActivitiesSettingsForm'

interface ContentsViewProps {
  contents: any
  isTeacher: any
  userId?: number | string
  isSchoolQuestions?: boolean
  isAssociable?: boolean
  isSelectable?: boolean
  associateContent?: (value: number) => void
  contentsIds?: number | string
}

const formInitialValues = {
  subject_ids: null
}

const ContentsView = (props: ContentsViewProps) => {
  const { contents, isTeacher, userId, isSchoolQuestions, isAssociable, isSelectable, associateContent, contentsIds } = props
  const [formValues, setFormValues] = useState<any>({})
  const [perPage, setPerPage] = useState<number>(5)
  const [page, setPage] = useState(1)
  const [chosenFilter, setChosenFilter] = useState('')
  const { filters } = useAppSelector(state => state)

  const filtersValues = { ...filters?.items?.custom_contents }

  useEffect(() => {
    if (filtersValues && Object.keys(filtersValues).length > 0 && chosenFilter.length > 0) {
      setFormValues({
        customContentsFilters: {
          ...filtersValues[chosenFilter]
        }
      })
    } else {
      setFormValues({})
    }
  }, [chosenFilter])

  const handleSubmit = () => {
    setFormValues(formInitialValues)
  }

  return (
    <>
      <Grid item xs={12} md={3}>
        <Form
          sx={{ display: 'flex', gap: 2 }}
          initialValues={formValues}
          mutators={{ ...arrayMutators }}
          onSubmit={handleSubmit}
          render={({ values, form, invalid }) => {
            return (
              <>
                <Box sx={{}}>
                  <ContentsFilter
                    isSchoolQuestions={isSchoolQuestions}
                    isTeacher={isTeacher}
                    userId={userId}
                    values={values as FormValues}
                    filters={filters}
                    isCustomContent
                    showYourFilters
                    change={form.change}
                    perPage={perPage}
                    setPage={setPage}
                    chosenFilter={chosenFilter}
                    setChosenFilter={setChosenFilter}
                    invalid={invalid}
                  />
                </Box>
              </>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <ContentsList
          contents={contents}
          contentsIds={contentsIds}
          selectedQuestions={null}
          associateContent={associateContent}
          selectAllItems={() => {
            return (null)
          }}
          isAllItemsSelected={() => {
            return (null)
          }}
          isSchoolQuestions
          isAssociable={isAssociable}
          isSelectable={isSelectable}
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      </Grid>

    </>
  )
}

export default ContentsView
