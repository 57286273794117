import { AnyAction } from 'redux'
import HomeIcon from '../assets/components/Header/homepage-icon.svg'
import FileIcon from '../assets/components/Header/content-icon.svg'
import BookIcon from '../assets/components/Header/book-icon.svg'
import ActivitiesIcon from '../assets/components/Header/activities-list.svg'
import ConfigurationGrayIcon from '../../src/assets/components/ManagersPage/configuration-gray-icon.svg'

export type Role = {
  id: string,
  level: number,
  name: string
}

export interface IURL {
  local: string
  staging: string
  homolog: string
  production: string
}

export const ROLES: { [key: string]: Role } = {
  OPERATOR: { id: 'fractal_operator', level: 1, name: 'fractal_operator' },
  DIRECTOR: { id: 'director', level: 2, name: 'director' },
  COORDINATOR: { id: 'coordinator', level: 3, name: 'coordinator' },
  TEACHER: { id: 'teacher', level: 4, name: 'teacher' }
}

export const profAppUrl: IURL = {
    local: 'http://localhost:8000',
    staging: 'https://staging-admin.profapp.com.br',
    production: 'https://admin.profapp.com.br',
    homolog: 'https://homolog.profapp.com.br'
  }

export const ssoUrl: IURL = {
  local: 'http://localhost:8001/',
  staging: 'https://staging-fractalid.fractaltecnologia.com.br',
  homolog: 'https://staging-fractalid.fractaltecnologia.com.br',
  production: 'https://fractalid.fractaltecnologia.com.br'
}

export const highSchoolSegmentId = 4

export const fetchRegistrationIncludes = 'account.company,contracts,representatives,representatives.account.user,representatives.account.user.address,products,products.document_requirements,registration_connections,registration_documents,registration_products,registration_products.registration_product_subsidies,registration_products.registration_product_subsidies.subsidy'
export interface DefaultAction<P> {
  type: AnyAction;
  payload: P;
}

export const genderOptions = [
  {
    label: 'Masculino',
    value: 'male'
  },
  {
    label: 'Feminino',
    value: 'female'
  },
  {
    label: 'Outro',
    value: 'other'
  }
]

export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

export const navbarIcons: { [key: string]: string } = {
  'Homepage': HomeIcon,
  'Atividades': ActivitiesIcon,
  'Editar Atividade': ActivitiesIcon,
  'Conteúdos': FileIcon,
  'Editar Conteúdo': FileIcon,
  'Criar Conteúdos': FileIcon,
  'Questões': FileIcon,
  'Criar Questões': FileIcon,
  'Editar Questões': FileIcon,
  'Livros': BookIcon,
  'Editar Livro': BookIcon,
  'Administração': ConfigurationGrayIcon
}

export const renderStatus: { [key: string]: string } = {
  'active': 'Ativa',
  'canceled': 'Cancelada',
  'adapted': 'Adaptada'
}

export const COLORS = [
  '#37A2EB',
  '#ED5E82',
  '#F69E3E',
  '#9C72FC',
  '#FDCD56',
  '#C9CBCF',
  '#F69E9E',
  '#6248B2',
  '#BC9BFA',
  '#00FFF5',
  '#393E46',
  '#E41F7B',
  '#00FF00',
  '#8E05C2',
  '#5C5470',
  '#86003C',
  '#F9D2D2',
  '#53C0C0',
]
