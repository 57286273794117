import { AnyAction } from 'redux'
import http from '../../utils/http'

const reportUrl = '/finance_status_logs'
const activitiesUrl = '/activities'

export const fetchTeacherReports = (params: object) => {
  return http.get(reportUrl, { params })
}

export const fetchFinancialReports = (params: object) => {
  return http.get(`${reportUrl}/teachers`, { params })
}

export const fetchActivityRankingReport = ({ activityId, ...params }:AnyAction) => {
  return http.get(`${activitiesUrl}/${activityId}/ranking`, { params })
}
