import React from 'react'
import BooksFormView from '../components/Books/form/BooksFormView'

const BooksFormContainer = () => {
  return (
    <div>
      <BooksFormView  />
    </div>
  )
}

export default BooksFormContainer
