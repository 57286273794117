import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  'input::placeholder': {
    color: '#cecece !important'
  },
  '> input': {
    border: '1px solid #cecece',
    borderRadius: '8px',
    padding: '10.53px 18px',
    fontSize: '14px',
    width: '100%',
    height: '40px',
    color: '#9F9F9F',
  },
  '& input:focus-visible': {
    outlineColor:  `${theme.palette.primary.main} !important`
  }
}))

const PersonNameInput: React.FC<Props> = ({
  input: { value, name, ...restInput },
  meta: { touched, error },
  placeholder,
  label,
  defaultFieldValue,
  ...rest
}: Props) => {
  return (
    <Container>
      <input
        id={`input-${name}`}
        name={name}
        placeholder={placeholder}
        value={defaultFieldValue || value}
        {...restInput}
        {...rest}
      />
      <Typography
        align='left'
        component='p'
        variant='caption'
        sx={{
          color: '#F69E9E'
        }}
      >
        {touched && error && error}
      </Typography>
    </Container>
  )
}

export default PersonNameInput
