import React from 'react'
import ActivityReopenContainer from '../containers/ActivityReopenContainer'



const ReopenActivityPage = () => {
  return (
    <ActivityReopenContainer />
  )
}

export default ReopenActivityPage
