import React from 'react'

import ActivityItemContainer from '../containers/ActivityItemContainer'

const ActivityPage = () => {
  return (
    <ActivityItemContainer />
  )
}

export default ActivityPage