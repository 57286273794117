import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { Form } from 'react-final-form'
import BooksFilters from '../BooksFilters'
import BooksList from './BooksList'
import { useAppSelector } from '../../../store/hooks'

interface IBooksViewProps {
  isTeacher?: any
  userId?: number
}

const BooksView = ({ isTeacher, userId }: IBooksViewProps) => {
  const [perPage, setPerPage] = useState(5)
  const [page, setPage] = useState(1)
  const [formValues, setFormValues] = useState<any>({})
  const [chosenFilter, setChosenFilter] = useState('')
  const { items, isFilterSaved } = useAppSelector(state => state.filters)
  const { selectedUnit } = useAppSelector(state => state.persistable)

  const filterValues = {  ...items?.books }

  useEffect(() => {
    if (filterValues && Object.keys(filterValues).length > 0 && chosenFilter.length > 0) {
      setFormValues({
        books: {
          ...filterValues[chosenFilter]
        }
      })
    } else {
      setFormValues({})
    }
  }, [chosenFilter])

  const handleSubmit = () => undefined

  return (
    <>
      <Grid item xs={12} md={3}>
        <Form
          onSubmit={handleSubmit}
          initialValues={formValues}
          render={({ values, form, invalid }) => {
            return (
              <>
                <Box>
                  <BooksFilters
                    values={values as any}
                    isTeacher={isTeacher}
                    userId={userId}
                    change={form.change}
                    invalid={invalid}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    filters={items?.books}
                    chosenFilter={chosenFilter}
                    setChosenFilter={setChosenFilter}
                    isFilterSaved={isFilterSaved}
                    schoolUnitId={selectedUnit?.id}
                  />
                </Box>
              </>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} md={9}>
        <BooksList
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Grid>
    </>
  )
}

export default BooksView
