import React, { useState, useEffect } from 'react'
import { Avatar, Grid, Typography, Box, Paper, LinearProgress, Button, Divider, Chip, CircularProgress, Skeleton } from '@mui/material'
import SelectInput from '../../components/select/SelectInput'
import { Participants, ActivityParticipants, studentsStatus, ContentQuestion, QuestionsContentIds } from '../../models/IActivities'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/configureStore'
import { ReactComponent as NoOptionsSVG } from '../../assets/no-options.svg'
import { ReactComponent as TopicSVG } from '../../assets/icons/topics.svg'
import { ReactComponent as ChooseStudentSVG } from '../../assets/chooseStudent.svg'
import { ReactComponent as GraphicSVG } from '../../assets/icons/graphic.svg'
import { ReactComponent as TimeSVG } from '../../assets/icons/time.svg'
import { ReactComponent as ProgressSVG } from '../../assets/icons/progress.svg'
import { ReactComponent as IncompleteSVG } from '../../assets/components/activity/icon-incomplete.svg'
import { ReactComponent as ReloadSVG } from '../../assets/components/activity/icon-reload.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg'
import { activityParticipantsDetailsRequest } from '../../store/activities/actions'
import Table, { Columns } from '../table/Table'
import { scrollToTop, stringAvatar } from '../../utils/functions'
import { styled, useTheme } from '@mui/material/styles'
import { DefaultOptionType } from '../../utils/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

const BoxStyled = styled(Box)(() => ({
  '& th.MuiTableCell-head': {
    color: '#9F9F9F',
    fontWeight: 600,
    fontSize: 14,
    paddingBottom: 0
  },
  '& .MuiButtonBase-root': {
    color: '#FFF',
    textTransform: 'inherit',
  },
  '& .MuiChip-label': {
    color: '#FFF'
  },
  '& .MuiTable-root': {
    minWidth: 520
  }
}))

const StatusCircle = styled('span')({
  borderRadius: '50%',
  display: 'block',
  marginRight: 'auto',
  marginLeft: 'auto',
  height: 12,
  width: 12,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableContainer = styled(Box)(({ theme }) => ({

}))

const BoxDifficulty = styled(Box)(() => ({
  height: '10px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  gap: '4px',
  'span': {
    backgroundColor: '#d1d1d1',
    height: '16px',
    width: '6px',
    display: 'block',
    borderRadius: '4px',
    ':nth-of-type(1)': {
      height: '8px',
    },
    ':nth-of-type(2)': {
      height: '10px',
    },
    ':nth-of-type(3)': {
      height: '12px',
    },
    ':nth-of-type(4)': {
      height: '14px',
    },
  },
  '&.very_easy': {
    'span': {
      ':nth-of-type(1)': {
        backgroundColor: '#412A88',
      },
    },
  },
  '&.easy': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2)': {
        backgroundColor: '#04AEC9',
      },
    },
  },
  '&.moderate': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3)': {
        backgroundColor: '#BC9BFA',
      },
    },
  },
  '&.difficult': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3), :nth-of-type(4)': {
        backgroundColor: '#60DFC8',
      },
    },
  },
  '&.very_difficult': {
    'span': {
      ':nth-of-type(1), :nth-of-type(2), :nth-of-type(3), :nth-of-type(4), , :nth-of-type(5)': {
        backgroundColor: '#F69E9E',
      },
    },
  },
}))

interface IActivityStudentsViewProps {
  participants?: Array<Participants>
  overview?: Array<ActivityParticipants>
  questions?: any
  totalParticipants?: Array<Participants>
  activityType?: string
  activityValue?: string
  discursiveQuestionsIds?: QuestionsContentIds[]
  isFetching?: boolean
  choosedClassroom?: number
}

type StateProps = {
  id: number,
  user: {
    name: string,
    id: number
  }
}

const Difficulty = (level: any) => {
  return (
    <BoxDifficulty className={level.level}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </BoxDifficulty>
  )
}

export const normalizeClassroomsAndStudents = (participants: any[]) => {
  const obj: any = {}
  const classrooms: Array<any> = []
  participants.forEach(elem => {
    !classrooms.find(classroom => classroom.id === elem.school_classroom.id) && classrooms.push(elem.school_classroom)
    if (obj[elem.school_classroom.unit.name]) {
      if (obj[elem.school_classroom.unit.name][elem.school_classroom.name]) {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name].push(
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        )
      } else {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name] = [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    } else {
      obj[elem.school_classroom.unit.name] = {
        [elem.school_classroom.name]: [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    }
  })
  return Object.keys(obj).map(unitName => {
    return ({
      unit_name: unitName,
      classrooms: Object.keys(obj[unitName]).map(classroomName => {
        return ({
          name: classroomName,
          id: classrooms.find(classroom => classroomName === classroom.name)?.id,
          students: obj[unitName][classroomName]
        })
      })
    })
  })
}

const ActivityStudentsView: React.FC<IActivityStudentsViewProps> = ({
  participants,
  overview,
  questions,
  activityType,
  activityValue,
  discursiveQuestionsIds,
  isFetching,
  choosedClassroom
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParam, setSearchParams] = useSearchParams()
  const [selectStudent, setSelectStudent] = useState<StateProps | null>(null)
  const [questionsDetails, setQuestionsDetails] = useState<any[]>([])
  const [filterSelected, setFilterSelected] = useState<string>('geral')
  const [filterQuestionType, setFilterQuestionType] = useState<string>('all')
  const [classroomSelected, setClassroomSelected] = useState<string | number>('')
  const dispatch = useDispatch()
  const theme = useTheme()
  const { activities: { item, details } } = useSelector((state: RootState) => state)
  const participantsOptions: Array<DefaultOptionType> = item.participants ? item.participants.map(participant => ({ label: participant.user.name, value: participant.user.id, classroom: participant.school_classroom.id })) : []
  const classroomWithStudents = item.participants && normalizeClassroomsAndStudents(item.participants)
  const classrooms: Array<any> = classroomWithStudents ? classroomWithStudents.flatMap(classroomUnit => classroomUnit.classrooms.map(classroom => classroom)) : []
  const classroomOptions: Array<DefaultOptionType> = classrooms ? classrooms.map(classroom => {
    return ({ label: classroom.name, value: classroom.id })
  }).flat() : []
  const navigate = useNavigate()
  const participantsSelected: Array<DefaultOptionType> = participants ? participants.map(participant => ({ label: participant.user.name, value: participant.user.id, classroom: participant.school_classroom.id })) : []

  const classroomStorage = localStorage.getItem('classroom')
  const classroom = classroomStorage && JSON.parse(classroomStorage)

  useEffect(() => {
    searchParam.get('info') === 'students' && localStorage.removeItem('classroom')
  }, [])

  const classroomChoosed: Array<DefaultOptionType> = classroomOptions ? classroomOptions?.filter(classroomItem => classroomItem.value === (choosedClassroom || classroom?.id)) : []

  const participantsChoosed: DefaultOptionType[] = participantsSelected
    ? participantsSelected
      .map(participant => ({
        label: participant?.label,
        value: participant?.value,
      }))
    : []

  const optionsClassroom: Array<DefaultOptionType> = searchParam.get('info') === 'students_classroom'
    ?
    [
      { label: 'Todas as turmas', value: 0 },
      ...classroomChoosed
    ]
    :
    [
      { label: 'Todas as turmas', value: 0 },
      ...classroomOptions
    ]

  const optionsParticipants = searchParam.get('info') === 'students_classroom'
    ?
    [
      { label: 'Todos os alunos', value: 0, classroom: 0 },
      ...participantsChoosed
    ]
    :
    [
      { label: 'Todos os alunos', value: 0, classroom: 0 },
      ...participantsOptions
    ]

  useEffect(() => {
    searchParam.get('info') === 'students_classroom' && setClassroomSelected(classroomChoosed[0]?.value)
  }, [classroomChoosed])

  const difficultyLevels: any = {
    'very_easy': { order: 1 },
    'easy': { order: 2 },
    'moderate': { order: 3 },
    'difficult': { order: 4 },
    'very_difficulty': { order: 5 }
  }

  const defaultQuestionsArray = details.data?.content_questions?.question ? [...details.data.content_questions.question] : []
  const hasQuestionsDiscursive = questions?.some((question: any) => question?.question_type === 'discursive')

  const handleSortingQuestions = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setFilterSelected(value)
    const filtered = defaultQuestionsArray && defaultQuestionsArray.filter(question => !!question.question_type)
    if (value === 'geral') {
      const reorderQuestions = defaultQuestionsArray && defaultQuestionsArray.reverse()
      setQuestionsDetails(reorderQuestions)

    } else if (value === 'less_result' && activityType === 'exam') {
      const lessResult = filtered && filtered.sort((a: ContentQuestion, b: ContentQuestion): number =>
        Number(a.participant_result) - Number(b.participant_result))
      setQuestionsDetails(lessResult)

    } else if (value === 'more_result' && activityType === 'exam') {
      const moreResult = filtered && filtered.sort((a: ContentQuestion, b: ContentQuestion): number =>
        Number(b.participant_result) - Number(a.participant_result))
      setQuestionsDetails(moreResult)

    } else if (value === 'less_time') {
      const lessTime = defaultQuestionsArray && defaultQuestionsArray.sort((a: ContentQuestion, b: ContentQuestion): number =>
        Number(a.time_on_item) - Number(b.time_on_item))
      setQuestionsDetails(lessTime)

    } else if (value === 'more_time') {
      const moreTime = defaultQuestionsArray && defaultQuestionsArray.sort((a: ContentQuestion, b: ContentQuestion): number =>
        Number(b.time_on_item) - Number(a.time_on_item))
      setQuestionsDetails(moreTime)

    } else if (value === 'less_difficulty') {
      const lessDifficulty = filtered && filtered.sort((a: ContentQuestion, b: ContentQuestion): number =>
        difficultyLevels[a.difficulty_level].order - difficultyLevels[b.difficulty_level].order)
      setQuestionsDetails(lessDifficulty)

    } else if (value === 'more_difficulty') {
      const moreDifficulty = filtered && filtered.sort((a: ContentQuestion, b: ContentQuestion): number =>
        difficultyLevels[b.difficulty_level].order - difficultyLevels[a.difficulty_level].order)
      setQuestionsDetails(moreDifficulty)
    }
  }

  const handleSortingQuestionsType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setFilterQuestionType(value)
    const discursiveQuestions = details.data.content_questions && [...details.data.content_questions.question].filter((question) => {
      return question?.question_type === value
    })
    if (value === 'all') {
      setQuestionsDetails(defaultQuestionsArray.reverse())
    } else {
      setQuestionsDetails(discursiveQuestions)
    }
  }

  useEffect(() => {
    if (details.data?.content_questions?.question) {
      setQuestionsDetails([...details.data.content_questions.question].reverse())
    }
  }, [details])

  useEffect(() => {
    setFilterSelected('geral')
  }, [details, filterQuestionType])

  const orders: { order: number }[] = questions && questions.map((_: any, index: any) => ({ order: index + 1 }))

  const questionsWithOrder = questionsDetails.map((question: any, index: any) => ({
    ...question,
    order: orders[index]?.order,
  }))

  function getStatusColorByUserId(userId: number) {
    const userStatus = overview?.find(user => user.user.id === userId)
    const colorStatus = userStatus?.status ? studentsStatus[userStatus?.status]?.color : '#F69E9E'
    return colorStatus
  }

  function getStatusByUserId(userId: number) {
    const userActivity = overview?.find(user => user.user.id === userId)
    const userStatus = userActivity?.status
    return userStatus
  }

  function getScoreByUserId(userId: number) {
    const userSelected = overview?.find(user => user.user.id === userId)
    const userScore = userSelected?.participant_score
    return userScore
  }

  function getPerformanceByUserId(userId: number) {
    const userSelected = overview?.find(user => user.user.id === userId)
    const userPerformance = userSelected?.performance
    return userPerformance
  }

  function getProgressByUserId(userId: number) {
    const userSelected = overview?.find(user => user.user.id === userId)
    const userProgress = userSelected?.progress
    return userProgress
  }

  function formatTime(seconds: any) {
    if (seconds < 0) {
      return '00:00:00'
    }
    const hour = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsRest = seconds % 60

    const hourFormated = hour.toString().padStart(2, '0')
    const minutesFormated = minutes.toString().padStart(2, '0')
    const secondsRestFormated = secondsRest.toString().padStart(2, '0')

    return `${hourFormated}:${minutesFormated}:${secondsRestFormated}`
  }

  const handleStudentClick = (student: any) => {
    setSelectStudent(student)
    student.id && dispatch(activityParticipantsDetailsRequest(student.id))
    scrollToTop()
  }

  const [selectedParticipants, setSelectedParticipants] = useState<any>([])
  const [selectedOptions, setSelectedOptions] = useState<any>(optionsParticipants)

  const handleClassroomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    if (Number(value) === 0) {
      setSelectedParticipants(participants)
      setSelectedOptions(optionsParticipants)
    } else {
      const findClassroom = classrooms.find(classroom => classroom.id === event.target.value)
      const filteredParticipants = participants?.filter((participant: any) =>
        findClassroom?.students && findClassroom?.students.some(
          (totalParticipant: any) => totalParticipant.id === participant.user.id
        )
      )
      setSelectedParticipants(filteredParticipants)

      const selected = participantsOptions?.filter((participant: any) =>
        findClassroom?.students && findClassroom?.students.some(
          (totalParticipant: any) => totalParticipant.id === participant.value
        )
      )
      setSelectedOptions([{ label: 'Todos', value: 0, classroom: 0 }, ...selected])
    }
  }

  const handleParticipantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    if (Number(value) === 0) {
      setSelectedParticipants(participants)
      setSelectedOptions(optionsParticipants)
    } else {
      const findParticipant = participantsOptions?.find(participant => participant.value === event.target.value)
      const filteredParticipants = participants?.filter((participant: any) =>
        findParticipant?.value === participant.user.id
      )
      setSelectedParticipants(filteredParticipants)
    }
  }

  useEffect(() => {
    if (choosedClassroom || classroom?.id) {
      const filteredParticipants = participants?.filter((participant: any) =>
        participant?.school_classroom?.id === (choosedClassroom || classroom?.id)
      )
      setSelectedParticipants(filteredParticipants)
    } else {
      setSelectedParticipants(participants)
    }
  }, [participants, choosedClassroom, classroom?.id])

  useEffect(() => {
    scrollToTop()
  }, [])

  const studentsColumns: Columns[] = [
    {
      key: 'avatar',
      name: 'Nome',
      width: '25px',
      render: (row: any) => {
        return (
          <Avatar
            onClick={() => handleStudentClick(row)}
            style={{ cursor: 'pointer' }}
            {...stringAvatar(row.user.name)} variant='rounded'
          />
        )
      }
    },
    {
      key: 'students',
      name: '',
      render: (row: any) => {
        return (
          <Box
            display='flex'
            flexDirection='column'
            onClick={() => handleStudentClick(row)}
            style={{ cursor: 'pointer' }}
          >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.user.name}</Typography>
            <span>{row.school_classroom.name}</span>
          </Box>
        )
      }
    },
    {
      key: 'scores',
      name: activityType === 'exam' ? 'Nota' : 'Performance',
      width: '200px',
      render: (row: any) => {
        const progress = getProgressByUserId(row.user.id)
        const score = getScoreByUserId(row.user.id)
        const intScore = score ? Number(score) : 0
        const status = getStatusByUserId(row.user.id)
        const percent = (intScore / Number(activityValue)) * 100
        const performance = getPerformanceByUserId(row.user.id)
        return (
          <Box display='flex' alignItems='center'>
            {activityType === 'exam' && typeof score !== 'undefined' && (
              <LinearProgress
                sx={{ width: '60px', backgroundColor: '#e0e6e7', marginRight: '16px' }}
                color={intScore < 7 ? 'error' : 'secondary'}
                value={percent}
                variant='determinate'
              />
            )}

            {activityType === 'commom' && typeof performance !== 'undefined' && (
              <LinearProgress
                sx={{ width: '60px', backgroundColor: '#e0e6e7', marginRight: '16px' }}
                color={performance < 50 ? 'error' : 'secondary'}
                value={performance}
                variant='determinate'
              />
            )}

            {activityType === 'exam' && score && (
              <span>{Number(score).toFixed(1)}</span>
            )}
            {activityType === 'commom' && performance && (
              <span>{performance} %</span>
            )}
            {progress && status !== 'waiting' && progress < 100 &&
              <span>
                <IncompleteSVG style={{ marginLeft: '8px' }} />
              </span>
            }
            {status === 'reopened' &&
              <span>
                <ReloadSVG style={{ marginLeft: '8px' }} />
              </span>
            }
          </Box>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '80px',
      render: (row: any) => {
        return <StatusCircle style={{ backgroundColor: getStatusColorByUserId(row.user.id) }} />
      }
    }
  ]

  const textExtract = (text: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = text
    const extractedText = tempElement.innerText
    const limitedText = extractedText.length > 100 ? extractedText.substring(0, 90) + '...' : extractedText
    return limitedText
  }

  const answeredQuestions = details.data.progress ? Math.round((details.data.progress / 100) * questions.length) : '0'

  const questionsColumns: Columns[] = [
    {
      key: 'id',
      name: '#',
      width: '5px',
      render: (row: any) => {
        return row.order && row?.order
      }
    },
    {
      key: 'enum',
      name: 'Enunciado',
      width: '200px',
      render: (row: any) => {
        return row.statement
          ? <div dangerouslySetInnerHTML={{ __html: textExtract(row.statement) }} />
          : <Typography component='span' sx={{ fontSize: '14px', fontWeight: 600 }}>Conteúdo</Typography>
      }
    },
    {
      key: 'type',
      name: 'Tipo',
      width: '5px',
      render: (row: any) => {
        return <Box sx={{ textAlign: 'center' }}>
          {
            row?.question_type === 'discursive' &&
            <Typography
              sx={{
                color: theme.palette.primary.light,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16.8px'
              }}>
              D
            </Typography>
          }
          {
            row?.question_type === 'only_choice' &&
            <Typography
              sx={{
                color: theme.palette.primary.light,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16.8px'
              }}>
              O
            </Typography>
          }
          {
            !row?.statement &&
            <Typography
              sx={{
                color: theme.palette.primary.light,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '16.8px'
              }}>
              C
            </Typography>
          }

        </Box>
      }
    },
    {
      key: 'result',
      name: 'Resultado',
      width: '15px',
      render: (row: any) => {
        const questionValue = row.question_value ? row.question_value.toFixed(1) : '--'
        const studentValue = row.participant_result ? row.participant_result.toFixed(1) : row.question_value ? '0,0' : '--'
        return (
          <span style={{ fontWeight: 500, display: 'flex', gap: '2px' }}>
            <span
              style={{
                color: studentValue !== '--' && Number(studentValue) > 0
                  ? '#04AEC9'
                  : studentValue === '--' ? '#9f9f9f' : '#f69e9e'
              }}
            >
              {studentValue}
            </span>
            <span>/</span>
            <span>{questionValue}</span>
          </span>
        )
      }
    },
    {
      key: 'difficult',
      name: 'Dificuldade',
      width: '30px',
      render: (row: any) => {
        return row.difficulty_level ? <Difficulty level={row.difficulty_level} /> : '--'
      }
    },
    {
      key: 'time',
      name: 'Tempo',
      width: '80px',
      render: (row: any) => {
        return <>{row.time_on_item ? formatTime(row.time_on_item) : '00:00:00'}</>
      }
    }
  ]

  const renderWithoutSelect = (
    <Box pt={6} pb={6} >
      <ChooseStudentSVG />
      <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
        Selecione um aluno
      </Typography>
      <Typography component='p' variant='subtitle2' >
        Clique em um dos alunos ao lado e detalhe o seu dempenho nessa atividade.
      </Typography>
    </Box>
  )

  const startDate = details && details?.data?.start_date
  const endDate = details && details?.data?.end_date

  function formatarData(data: any) {
    const dataObj = new Date(data)
    const dia = String(dataObj.getDate()).padStart(2, '0')
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0')
    const ano = dataObj.getFullYear()
    const hora = String(dataObj.getHours()).padStart(2, '0')
    const minutos = String(dataObj.getMinutes()).padStart(2, '0')
    const dataFormatada = `${dia}/${mes}/${ano} - ${hora}:${minutos}`
    return dataFormatada
  }

  const startFormatDate = startDate !== null ? formatarData(startDate) : 'Não iniciou'
  const endFormatDate = endDate !== null ? formatarData(endDate) : 'Não Finalizou'
  const { id } = useParams()
  const questionsList = details?.data?.content_questions?.question

  const renderStudent = selectStudent?.user && (
    <Box p={3} width='100%' sx={{ overflowX: 'hidden' }}>
      {/* <Box display='flex' gap={2} alignItems='center' justifyContent='flex-end'>
        <Button
          disabled={details.isFetching || details.data.status === 'reopened'}
          onClick={() => navigate(`/activities/${id}/reopen`, {state: {studentId: selectStudent?.id, studentName: selectStudent.user.name}})}
          color='primary'
          variant='contained'
          startIcon={
            details.isFetching
              ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
              : undefined
          }
        >
          {details.data.status === 'reopened' ? 'Atividade Reaberta' : 'Reabrir atividade'}
        </Button>
      </Box> */}
      <Box display='flex' gap={2} alignItems='center'>
        <Avatar {...stringAvatar(selectStudent.user.name)} variant='rounded' />
        <div>
          <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600 }}>
            {selectStudent.user.name}
          </Typography>
          <Typography variant='subtitle2' textAlign='initial'>
            {details.data.school_classroom?.name}
          </Typography>
        </div>
      </Box>
      <Box display='flex' justifyContent='space-between' mt={2} mb={3} color='#9F9F9F'>
        <div>
          <Typography component='h3' sx={{ fontSize: 14, fontWeight: 700 }}>
            Atividade iniciada:
          </Typography>
          <Typography variant='subtitle2' textAlign='initial' >
            {startFormatDate}
          </Typography>
        </div>
        <div>
          <Typography component='h3' sx={{ fontSize: 14, fontWeight: 700 }}>
            Atividade Finalizada:
          </Typography>
          <Typography variant='subtitle2' textAlign='initial'>
            {endFormatDate}
          </Typography>
        </div>
      </Box>
      <Divider />
      {details.data.status && details.data.status !== 'pending' ?
        <>
          <Box mt={3} mb={3}>
            <Typography textAlign='initial' mb={2} component='h2' sx={{ fontSize: 18, fontWeight: 600 }}>
              Resultados
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Typography display='flex' alignItems='center' variant='subtitle2' gap={2} textAlign='initial' sx={{ fontWeight: 600 }}>
                <ProgressSVG />
                Progresso
                <Box component='span' color='#9F9F9F' fontWeight={500}>{answeredQuestions} de {questions.length} exercícios respondidos</Box>
                <Chip
                  label={details.data.progress ? details.data.progress + '%' : '0%'}
                  color='secondary'
                  sx={{ padding: '5.5px 18px', fontSize: '14px' }}
                />
              </Typography>
              <Typography display='flex' alignItems='center' variant='subtitle2' gap={2} textAlign='initial' sx={{ fontWeight: 600 }}>
                <TimeSVG />
                Tempo de resolução
                <Chip
                  label={formatTime(details.data.time_on_activity)}
                  sx={{ backgroundColor: '#BC9BFA', borderRadius: '8px', padding: '5.5px 18px', fontSize: '14px' }}
                />
              </Typography>
              <Typography display='flex' alignItems='center' variant='subtitle2' gap={2} textAlign='initial' sx={{ fontWeight: 600 }}>
                <GraphicSVG />
                {activityType === 'exam'
                  ? 'Nota Aluno'
                  : 'Performance Aluno'
                }
                <Box sx={{
                  alignItems: 'center',
                  border: '1px solid #D9D9D9',
                  borderRadius: 1,
                  color: '#9F9F9F',
                  display: 'flex',
                  justifyContent: 'center',
                  height: 40,
                  width: 72
                }}>
                  {activityType === 'exam'
                    ? Number(details.data.participant_score).toFixed(1)
                    : `${Number(details.data.performance).toFixed(1)}%`
                  }
                </Box>
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box mt={3}>
            <Box mb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography textAlign='initial' component='h2' sx={{ fontSize: 18, fontWeight: 600 }}>
                Questões
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Ordenar por:</Typography>
                <SelectInput
                  defaultValue={filterSelected}
                  name='selectOrder'
                  width='132px'
                  onChange={handleSortingQuestions}
                  options={[
                    { label: 'Padrão', value: 'geral' },
                    { label: 'Maior resultado', value: 'more_result' },
                    { label: 'Maior tempo', value: 'more_time' },
                    { label: 'Maior dificuldade', value: 'more_difficulty' },
                    { label: 'Menor resultado', value: 'less_result' },
                    { label: 'Menor tempo', value: 'less_time' },
                    { label: 'Menor dificuldade', value: 'less_difficulty' },
                  ]}
                />
              </Box>
            </Box>
            <TableContainer />
            <Table
              columns={questionsColumns}
              data={questionsWithOrder}
              stickHeader
              maxHeight='600px'
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', pb: '26px' }}>
            <Button
              onClick={() => navigate(`/activities/${id}/view-activity-details`, { state: { id, discursiveQuestionsIds } })}
              type='button'
              sx={{
                background: '#fff',
                padding: '14.5px 24px',
                color: '#B7B2C8 !important',
                borderColor: '#B7B2C8',
                fontWeight: 500,
                borderRadius: '8px'
              }}
              variant='outlined'
              startIcon={
                <EyeIcon />
              }
            >
              Ver todas as questões
            </Button>
          </Box>
        </> :
        <>
          <Typography textAlign='initial' mb={2} component='h2' sx={{ fontSize: 16, fontWeight: 600, textAlign: 'center', pt: 2 }}>
            O aluno não iniciou a atividade
          </Typography>
          <NoOptionsSVG />
        </>
      }
    </Box >
  )

  if (isFetching) {
    return (
      <BoxStyled mt={4}>
        <Skeleton variant='text' animation='wave' sx={{ fontSize: '18px', width: '200px', mb: 2 }} />
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Skeleton
              animation='wave'
              variant='rectangular'
              height={40}
              sx={{ borderRadius: '8px' }}
            />
          </Grid>

          <Grid item xs={6}>
            <Skeleton
              animation='wave'
              variant='rectangular'
              height={40}
              sx={{ borderRadius: '8px' }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} mt={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', gap: '16px', mb: 2 }}>
              {Array(3).fill(0).map((item, index) => (
                <Skeleton
                  key={item + index}
                  variant='text'
                  animation='wave'
                  sx={{ fontSize: '20px', width: '100%' }}
                />
              ))}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {Array(6).fill(0).map((item, index) => (
                <Skeleton
                  key={item + index}
                  variant='rectangular'
                  animation='wave'
                  height={80}
                  width='100%'
                  sx={{ borderRadius: '8px' }}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Skeleton
              variant='rectangular'
              animation='wave'
              height='100%'
              sx={{ borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
      </BoxStyled>
    )
  }

  return (
    <BoxStyled mt={4}>
      <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
        <TopicSVG style={{ marginRight: 16 }} />
        Desempenho Alunos
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={hasQuestionsDiscursive ? 4 : 6}>
          <SelectInput
            defaultValue={searchParam.get('info') === 'students_classroom' ? classroomSelected : '0'}
            name='classroom'
            onChange={handleClassroomChange}
            options={optionsClassroom}
            label='Turma'
            sx={{ backgroundColor: '#fff', width: '100%' }}
          />
        </Grid>

        <Grid item xs={hasQuestionsDiscursive ? 4 : 6}>
          <SelectInput
            defaultValue='0'
            name='participants'
            onChange={handleParticipantChange}
            options={selectedOptions}
            label='Aluno'
            sx={{ backgroundColor: '#fff', width: '100%' }}
          />
        </Grid>

        {hasQuestionsDiscursive && (
          <Grid item xs={4}>
            <SelectInput
              disabled={questionsList === undefined || questionsList === 0}
              defaultValue={filterQuestionType}
              name='participants'
              onChange={handleSortingQuestionsType}
              options={[
                { label: 'Discursiva', value: 'discursive' },
                { label: 'Objetiva', value: 'only_choice' },
                { label: 'Todas', value: 'all' },

              ]}
              label='Questão'
              sx={{ backgroundColor: '#fff', width: '100%' }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <Table
            columns={studentsColumns}
            data={selectedParticipants || []}
          />
          <Box display='flex' justifyContent='space-between' mt={1} mb={2} pl={1} pr={1} color='#9F9F9F'>
            <Box display='flex' alignItems='center' mt={1} >
              <StatusCircle style={{ backgroundColor: '#04AEC9', marginRight: '16px' }} /> Concluído
            </Box>
            {/* <Box display='flex' alignItems='center' mt={1} >
              <StatusCircle style={{ backgroundColor: '#BC9BFA', marginRight: '16px' }} /> Reaberta
            </Box> */}
            <Box display='flex' alignItems='center' mt={1} >
              <StatusCircle style={{ backgroundColor: '#F69E9E', marginRight: '16px' }} /> Não iniciado
            </Box>
            <Box display='flex' alignItems='center' mt={1} >
              <StatusCircle style={{ backgroundColor: '#B7B2C8', marginRight: '16px' }} /> Em andamento
            </Box>
            <Box display='flex' alignItems='center' mt={1} >
              <StatusCircle style={{ backgroundColor: '#8F8888', marginRight: '16px' }} /> Expirado
            </Box>
          </Box>
          <Box sx={{
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            padding: '19px 17px',
            border: '2px dashed #ADB8CC',
            color: '#9F9F9F'
          }}>
            <Typography component='p' sx={{ fontSize: 14, fontWeight: 400, mb: 2 }}>
              <IncompleteSVG style={{ marginRight: 8 }} />
              Os alunos marcados com esse sinal não completaram a atividade avaliativa
            </Typography>
            {/* <Typography component='p' sx={{ fontSize: 14, fontWeight: 400, mb: 1 }}>
              <ReloadSVG style={{ marginRight: 8 }} />
              Os alunos marcados com esse sinal tiveram sua atividade avaliativa reaberta
            </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className='PAPER' sx={{ mt: 2, borderRadius: 4, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            {details.isFetching && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px', height: '150px' }}>
                <CircularProgress />
              </Box>
            )}
            {!selectStudent && !details.isFetching && renderWithoutSelect}
            {selectStudent && !details.isFetching && renderStudent}
          </Paper>
        </Grid>
      </Grid>
    </BoxStyled>
  )
}

export default ActivityStudentsView
