import { IUnit } from './IUnit'

export interface Participants {
  school_classroom: {
    id: 0
    name: ''
    grade: {
      id: 0
      name: ''
    }
    unit: {
      id: 0
      name: ''
    }
  }
  user: { id: number, name: string }
}

export interface ActivityParticipants {
  cheated: string
  id: number
  progress: number
  performance?: number
  school_classroom: {
    id: number
    name: string
  }
  score: number | string
  participant_score: string
  status: string
  user: { id: number, name: string }
}

export interface QuestionsContentIds {
  content_module_id?: number,
  content_module_item_id?: number,
  content_question_id?: number
}

export interface ParticipantStatus {
  status:
  {
    pending: number
    in_progress: number
    finished: number
    unfinished: number
    timed_out: number,
    need_feedback: {
      count: number,
      questions?: QuestionsContentIds[]
      length?: any
    }
  }
}

export interface ParticipantsDetails {
  activity: {
    id: number,
    name: string,
    title: string
  }
  content_questions: ContentQuestion
  id: number,
  progress: number,
  school_classroom: {
    id: number,
    name: string
  }
  end_date?: Date | string | null | undefined
  start_date?: Date | string | null
  score: number
  participant_score: string
  performance: number
  status: string
  time_on_activity: string
  user: { id: number, name: string }
  isFetching: boolean
  isError: boolean
  messageError: string
}

export interface ContentQuestion {
  difficulty_level: string,
  id: number,
  statement: string,
  time_on_item: string,
  question_value: string,
  participant_result: string,
  question?: any
}

export interface Ranking {
  classroom_name: string
  grade_name: string
  id: number
  participant_name: string
  performance: number
  position: number
  score: number
}

export interface IOverview {
  id: number
  description: string
  activity_status: string
  participants: {
    id: number
    user: {
      id: number
      name: string
    }
    school_classroom: {
      id: number
      name: string
    }
    score: number
    participant_score: string
    progress: number
    performance: number
    cheated: string
    status: string
  }[]
}

export interface IDistractor {
  id: number
  is_correct: boolean
  is_distractor: boolean
  participants: number[]
  percentage: number
  question_option_order: number
  student_count: number
}

export interface IAccurate {
  averege: number
  question_id: number
  score: number
  statement: string
  distractor: IDistractor[]
  question_type: string
}

export interface IQuestionDetails {
  more_accurate: IAccurate[]
  less_accurate: IAccurate[]
}

export interface IActivities {
  id?: string | number
  title?: string
  description?: string
  status?: string
  draft?: boolean
  duration?: number
  content?: any
  created_by_user?: { id: number, name: string }
  created_by_user_role?: { name: string }
  modules?: any[]
  content_items?: { id: string | number, order: number, value_item: number | string }[]
  education_subject_ids?: number[]
  lms_activity_participants: any[]
  content_module_type?: any
  theme_ids?: any
  contents?: { id: number, name: string } | undefined
  activity_type?: string
  exam_type?: { value: string, name: string } | string | undefined
  grades?: { id: number, name: string, value: number } | undefined
  segments?: { id: number, name: string } | null
  knowledgeArea?: { id: number, name: string }[]
  subjects?: any
  subject_ids?: { id: string | number, name: string }[] | undefined
  concourse_ids?: { id: string | number, name: string } | undefined
  years_of_application?: Date | undefined
  end_date?: Date | string | null | undefined
  start_date?: Date | string | null
  ids?: any
  education_grade_ids?: number[] | { id: number, name: string } | null
  units?: IUnit[] | undefined
  pagination?: { current: number, total: number, totalOfPages: number }
  number_of_versions?: { value: number } | number
  shuffle_options?: string
  value?: string
  participants?: Array<Participants>
  school_participants?: Array<Participants>
  question_details?: Array<IQuestionDetails>
  ranking?: Array<Ranking>
  questions?: CompleteQuestionsDetails
  variants?: Variants | null
}

interface Variants {
  id: number
  shuffle_option: string
  number_of_versions: number
  activity: Activity
  content_module: ContentModule
}

interface Activity {
  id: number
  title: string
  description: string
}

interface ContentModule {
  id: number
  name: string
  is_fractal: boolean
  content_module_items_count: number
  questions_count: number
  content_items_count: number
  activity_report?: ActivityReport[]
  activityReport?: ActivityReport[]
}

export interface ActivityReport {
  id: number
  question: string
  distractor: Distractor
  correct: Correct
  note_media: string
  average_time: string
}

export interface Distractor {
  F?: string
  D?: string
  E?: string
  C?: string
  B?: string
}

export interface Correct {
  D?: string
  C?: string
  F?: string
  B?: string
}

export interface CompleteActivityParticipants {
  user?: {
    id: number
    name: string
  }
  school_classroom?: {
    id: number
    name: string
    grade: {
      id: number
      name: string
    }
    unit: {
      id: number
      name: string
    }
  }
}
export interface CompleteQuestionsDetails {
  id?: number
  description?: string
  modules: any
  questions?: {
    options?: {
      id?: number
      description?: string
      is_correct?: boolean
      is_distractor?: boolean
      student_count?: number
      porcentage?: string
      question_option_order?: number
    }[]
    students?: {
      wrong?: {
        user: {
          id: number
          name: string
        }
        school_classroom: {
          id: number
          name: string
          grade: {
            id: number
            name: string
          }
          unit: {
            id: number
            name: string
          }
        }
      }[]
      right?: {
        user: {
          id: number
          name: string
        }
        school_classroom: {
          id: number
          name: string
          grade: {
            id: number
            name: string
          }
          unit: {
            id: number
            name: string
          }
        }
      }[]
    }
  }
}

export const ActivitiesValue = {
  id: '',
  title: '',
  description: '',
  status: '',
  start_date: '',
  end_date: '',
  subjects: [{}],
  draft: false,
  created_by_user: {},
  created_by_user_role: {},
  modules: [{ id: '', module_type: {} }],
  pagination: { current: 0, total: 0, totalOfPages: 0 },
}

export type activitiesStatusType = {
  label: string
  value: string
  color: string
}

export const activitiesStatus: { [key: string]: activitiesStatusType } = {
  waiting: { label: 'Agendada', value: 'waiting', color: '#F69E9E' },
  opened: { label: 'Em andamento', value: 'opened', color: '#412A88' },
  closed: { label: 'Concluídas', value: 'closed', color: '#04AEC9' },
  archived: { label: 'Arquivadas', value: 'archived', color: '#9F9F9F' },
  drafted: { label: 'Rascunho', value: 'drafted', color: '#BC9BFA' },
}

export const studentsStatus: { [key: string]: activitiesStatusType } = {
  waiting: { label: 'Agendada', value: 'waiting', color: '#F69E9E' },
  unfinished: { label: 'Incompleta', value: 'unfinished', color: '#8F8888' },
  timed_out: { label: 'Expirado', value: 'timed_out', color: '#8F8888' },
  in_progress: { label: 'Em andamento', value: 'in_progress', color: '#b7b2c8' },
  finished: { label: 'Finalizadas', value: 'finished', color: '#04AEC9' },
  reopened: { label: 'Reaberta', value: 'reopened', color: '#bc9bfa' }
}
