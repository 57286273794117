import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as actions from './actions'
import * as services from './services'
import { showAlertMessage } from '../alert'
import { IUpdateTeacherPayload } from './types'
import { removeNonNumericCharacters } from '../../utils/functions'
import { ISchool } from '../school_app_id/types'

function* createTeacherSaga({ payload }: any) {
  const { selectedUnit } = yield select(state => state.persistable)
  const { items } = yield select(state => state.schoolAppId)

  const schoolUnit = items?.filter((school: ISchool) => {
    return school?.school?.school_unit_id === selectedUnit?.id
  })

  try {
    const requestBody = {
      users: {
        name: payload?.name,
        email: payload?.email,
        taxpayer_number: payload?.taxpayer_number,
        birthday: payload?.birthday,
        phone: payload?.phone,
        additional_data: {
          address: {
            number: payload?.number,
            street: payload?.street,
            zipcode: payload?.zipcode,
            complement: payload?.complement,
            neighborhood: payload?.neighborhood
          }
        }
      },
      teachers_attributes:
        payload?.classrooms?.map((classroom: any) => {
          return {
            ...{
              school_classroom_id: classroom?.classroom?.id,
              education_subject_id: classroom?.subject_ids?.id,
              school_unit_id: payload?.school_unit_id,
              education_grade_id: classroom?.classroom?.grade?.id
            }
          }
        })
      ,
      roles_users_attributes: {
        user_role_id: 5,
        user_application_id: schoolUnit?.[0]?.id ?? null
      }
    }

    const response: AxiosResponse = yield call(services.createTeacherService, requestBody)

    if (response.status === 201) {
      yield put(showAlertMessage({
        message: 'Professor criado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield put(actions.createTeacherSuccess())
      yield delay(1500)
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  } catch (error: any) {
    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao criar professor.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(actions.createTeacherRejected())
  }
}

function* updateTeacherSaga({ payload }: IUpdateTeacherPayload) {
  try {
    let requestBody
    if (payload?.radioClassroomId) {
      const teacherAttributes = payload?.teacher?.subjects?.map((subject) => {
        return {
          school_classroom_id: payload?.radioClassroomId,
          education_subject_id: subject?.id,
          school_unit_id: payload?.selectedUnit?.id,
          education_grade_id: payload.teacher?.grade?.id
        }
      })
      requestBody = {
        id: payload?.teacher?.user?.id,
        data: {
          users: {
            name: payload?.teacher?.user?.name,
            email: payload.teacher?.user.email,
            taxpayer_number: payload.teacher?.user?.taxpayer_number,
            birthday: payload.teacher?.user?.birthday,
            phone: payload?.teacher?.user?.phone,
            additional_data: {
              address: {
                number: payload?.teacher?.user?.additional_data?.address?.number,
                street: payload?.teacher?.user?.additional_data?.address?.street,
                zipcode: payload?.teacher?.user?.additional_data?.address?.zipcode,
                complement: payload?.teacher?.user?.additional_data?.address?.complement,
                neighborhood: payload?.teacher?.user?.additional_data?.address?.neighborhood
              }
            }
          },
          teachers_attributes: teacherAttributes,
          roles_users_attributes: {
            user_role_id: 5
          }
        }

      }


    } else {
      const teacherAttributes = payload?.classrooms?.map((classroom) => {
        return {
          school_classroom_id: classroom.classroom.id,
          education_subject_id: classroom.subject_ids.id,
          school_unit_id: payload.school_unit_id,
          education_grade_id: classroom.classroom.grade.id
        }
      })
      requestBody = {
        id: payload.id,
        data: {
          users: {
            name: payload.name,
            email: payload.email,
            genre: payload.genre.value,
            taxpayer_number: payload.taxpayer_number && removeNonNumericCharacters(payload.taxpayer_number),
            birthday: payload.birthday,
            phone: payload.phone,
            additional_data: {
              address: {
                number: payload.number,
                street: payload.street,
                zipcode: payload.zipcode,
                complement: payload.complement,
                neighborhood: payload.neighborhood
              }
            }
          },
          teachers_attributes: teacherAttributes,
          roles_users_attributes: {
            user_role_id: 5
          }
        }
      }
    }

    const response: AxiosResponse = yield call(services.updateTeachersService, requestBody)
    if (response.status === 200) {
      yield delay(1500)
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
      yield put(showAlertMessage({
        message: 'Professor atualizado com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      yield put(actions.updateTeacherSuccess())
    }
  } catch (error: any) {

    const errorMessageCpfAndEmail = 'A validação falhou: Email já está em uso, Taxpayer number já está em uso'
    const errorMessageCpf = 'A validação falhou: Taxpayer number já está em uso'
    const errorMessageEmail = 'A validação falhou: Email já está em uso'

    let message
    switch (error?.response?.data?.error) {
      case errorMessageCpfAndEmail:
        message = 'CPF e Email já estão em uso.'
        break
      case errorMessageCpf:
        message = 'CPF já está em uso.'
        break
      case errorMessageEmail:
        message = 'Email já está em uso'
        break
      default:
        message = 'Erro ao atualizar dados.'
        break
    }

    yield put(showAlertMessage({
      message,
      severity: 'error',
      duration: 5000
    }))
    yield put(actions.updateTeacherFailure())
  }
}

export function* getAllTeachersSaga({ payload }: any) {
  try {
    const params = {
      school_unit_id: payload.school_unit_id,
      page: payload.page,
      per: payload?.per
    }
    const response: AxiosResponse = yield call(services.getAllTeachersService, { params })
    const pagination = {
      totalOfPages: Number(response.headers['x-total-pages']),
      current: Number(response.headers['x-page']),
      total: Number(response.headers['x-total-count'])
    }

    if (response.status === 200) {
      yield put(actions.getAllTeachersSuccess(response.data.data))
      yield put(actions.teachersChangePagination(pagination))
    }
  } catch (error) {
    yield put(actions.getAllTeachersFailure())
  }
}

export function* getTeacherByIdSaga({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getTeacherByIdService, payload)
    if (response.status === 200) {
      yield put(actions.getTeacherByIdSuccess(response.data))
    }
  } catch (error) {
    yield put(actions.getTeacherByIdFailure())
  }
}

export function* watchCreateTeachersSaga() {
  yield takeLatest(actions.createTeacherRequest.type, createTeacherSaga)
}
export function* watchUpdateTeachersSaga() {
  yield takeLatest(actions.updateTeacherRequest.type as any, updateTeacherSaga)
}
export function* watchGetAllTeachersSaga() {
  yield takeLatest(actions.getAllTeachersRequest.type, getAllTeachersSaga)
}
export function* watchGetTeacherByIdSaga() {
  yield takeLatest(actions.getTeacherByIdRequest.type, getTeacherByIdSaga)
}

export default function* teachersSagas() {
  yield all([
    watchCreateTeachersSaga(),
    watchUpdateTeachersSaga(),
    watchGetAllTeachersSaga(),
    watchGetTeacherByIdSaga(),
  ])
}
