import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Collapse,
  IconButton,
  Typography
} from '@mui/material'
import ClassroomReportCollapse from './ClassroomReportCollapse'

// Utils
import { IUnitClassroom } from '../../../models/IBooks'
import { styled } from '@mui/material/styles'

// Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

const SchoolButton = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  '& p': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '150%',
  },

  '& .school_name': {
    color: theme.palette.text.primary,
    marginRight: '4px'
  },

  '& .number_of_classrooms': {
    color: theme.palette.primary.light,
  }
}))

interface ISchoolReportCollapse {
  name: string
  classrooms: IUnitClassroom[]
  bookId?: number | string
  schoolCollapseIndex: number
}

const SchoolReportCollapse: React.FC<ISchoolReportCollapse> = ({
  name,
  classrooms,
  bookId,
  schoolCollapseIndex
}) => {
  const [openSchool, setOpenSchool] = useState(false)

  useEffect(() => {
    schoolCollapseIndex === 0 && setOpenSchool(true)
  }, [])

  return (
    <Box>
      <SchoolButton onClick={() => setOpenSchool(prevState => !prevState)}>
        <Typography className='school_name'>{name}</Typography>
        <Typography className='number_of_classrooms'>({classrooms.length ?? 0})</Typography>
        <IconButton>
          <KeyboardArrowDownRoundedIcon
            sx={{
              transform: openSchool ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </IconButton>
      </SchoolButton>

      <Collapse in={openSchool}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {classrooms?.map((item, index) => {
            return (
              <ClassroomReportCollapse
                key={item.id}
                classroom={item}
                bookId={bookId}
                schoolCollapseIndex={schoolCollapseIndex}
                index={index}
              />
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

export default SchoolReportCollapse
