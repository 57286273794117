import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface FetchExamPayload {
  per?: number
  page?: number
}

interface FetchExamsSuccessPayload {
  data: object[]
  pagination: object
}

export interface FetchExamsQuestionsPayload {
  id: number
}

interface FetchExamsQuestionsSuccessPayload {
  data: object[]
  id: FetchExamsQuestionsPayload
}

interface ImportExamPayload {
  id: number
}

export interface SetExamFiltersPayload {
  filters: object
}

interface FailurePayload {
  error: string
}

export const fetchExamsRequest = createAction('exams/FETCH_EXAMS_REQUEST', withPayloadType<FetchExamPayload>())
export const fetchExamsSuccess = createAction('exams/FETCH_EXAMS_SUCCESS', withPayloadType<FetchExamsSuccessPayload>())
export const fetchExamsFailure = createAction('exams/FETCH_EXAMS_ERROR', withPayloadType<FailurePayload>())

export const fetchExamsQuestionsRequest = createAction('exams/FETCH_EXAMS_QUESTIONS_REQUEST', withPayloadType<FetchExamsQuestionsPayload>())
export const fetchExamsQuestionsSuccess = createAction('exams/FETCH_EXAMS_QUESTIONS_SUCCESS', withPayloadType<FetchExamsQuestionsSuccessPayload>())
export const fetchExamsQuestionsFailure = createAction('exams/FETCH_EXAMS_QUESTIONS_FAILURE', withPayloadType<FailurePayload>())

export const importExamQuestionsRequest = createAction('exams/IMPORT_EXAM_QUESTION_REQUEST', withPayloadType<ImportExamPayload>())
export const importExamQuestionsSuccess = createAction('exams/IMPORT_EXAMS_QUESTION_SUCCESS')
export const importExamQuestionsFailure = createAction('exams/IMPORT_EXAMS_QUESTION_FAILURE')

export const examsResetFilters = createAction('exams/RESET_FILTERS')

export const setExamQuestionsFilters = createAction('exams/SET_EXAM_QUESTIONS_FILTERS', withPayloadType<SetExamFiltersPayload>())
export const clearCurrentItem = createAction('exams/CLEAR_CURRENT_ITEM')