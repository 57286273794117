import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface GetAllTeachersRequestPayload {
  school_unit_id: any
  page?: number
  per?: number
}
export interface GetAllTeachersSuccessPayload {
  data: any
}

export interface GetTeacherByIdRequestPayload {
  teacherId?: number
  schoolUnitId?: number
}
export interface GetTeacherByIdSuccessPayload {
  data: any
}

export interface ChangePaginationPayload {
  total: number
  totalOfPages: number
  current: number
}

export interface CreateUserTeacherRequestPayload {
  data: object
}

export const createTeacherRequest = createAction('users:CREATE_TEACHER_REQUEST', withPayloadType<CreateUserTeacherRequestPayload>())
export const createTeacherSuccess = createAction('users:CREATE_TEACHER_SUCCESS')
export const createTeacherRejected = createAction('users:CREATE_TEACHER_REJECTED')

export const updateTeacherRequest = createAction('users:UPDATE_TEACHER_REQUEST', withPayloadType<any>())
export const updateTeacherSuccess = createAction('users:UPDATE_TEACHER_SUCCESS')
export const updateTeacherFailure = createAction('users:UPDATE_TEACHER_FAILURE')

export const getAllTeachersRequest = createAction('users:GET_ALL_TEACHERS_REQUEST', withPayloadType<GetAllTeachersRequestPayload>())
export const getAllTeachersSuccess = createAction('users:GET_ALL_TEACHERS_SUCCESS', withPayloadType<GetAllTeachersSuccessPayload>())
export const getAllTeachersFailure = createAction('users:GET_ALL_TEACHERS_FAILURE')

export const getTeacherByIdRequest = createAction('users:GET_TEACHER_BY_ID_REQUEST', withPayloadType<GetTeacherByIdRequestPayload>())
export const getTeacherByIdSuccess = createAction('users:GET_TEACHER_BY_ID_SUCCESS', withPayloadType<GetTeacherByIdSuccessPayload>())
export const getTeacherByIdFailure = createAction('users:GET_TEACHER_BY_ID_FAILURE')

export const teachersChangePagination = createAction('users:TEACHERS_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())

export const resetTeacher = createAction('users/TEACHER_RESET_DATA')
