import { createAction } from '@reduxjs/toolkit'

import { IQuestion } from '../../models/IQuestion'
import { IActivities } from '../../models/IActivities'
import { withPayloadType } from '../../utils/functions'

export interface CreateOrEditQuestionPayload {
  data: object
}

export interface DeleteQuestionPayload {
  id?: number | string
}

export interface ModuleFetchPayload {
  id: string | number
}

export interface QuestionSuccessPayload {
  data: object
}

export interface ChangePaginationPayload {
  total?: number
  totalPages?: number
  current?: number
  per?: number
}

export interface FetchModuleSuccessPayload {
  currentItem: IQuestion
}

export interface FetchQuestionItemInActivityPayload {
  terms?: string | null
  ids?: string | number
  page?: number
  per?: number
}

export interface SchoolQuestionItemsFetchRequestPayload {
  terms?: string | null
  ids?: string | number
  page?: number
  per?: number
}

export interface QuestionSaveUiFiltersPayload {
  terms?: string | null
  ids?: string | number
  checked?: boolean
}

export interface SaveActivityPayload {
  activity?: IActivities,
  navigate?: any
}

export interface FetchActivityResponse {
  item: object
  participantStatus: object
  needFeedback: object
}

export interface AnswersPayload {
  id: number
  answers: object
}

export interface QuestionDetailsPayload {
  id: number
  questionDetails: object
}
export interface requestDiscursiveCorrectionPayload {
  activity_id: number
  content_module_id: number
  content_module_item_id: number
  content_question_id: number
}
export interface fetchDiscursiveCorrectionPayload {
  data: object
}
export interface giveTeacherFeedbackPayload {
  answerId: number
  teacher_feedback: string,
  answer_value: number,
  is_correct: boolean
}

export const createQuestionRequest = createAction('question/CREATE_QUESTION', withPayloadType<CreateOrEditQuestionPayload>())
export const createQuestionSuccess = createAction('question/CREATE_QUESTION_SUCCESSFUL', withPayloadType<QuestionSuccessPayload>())
export const createQuestionFailure = createAction('question/CREATE_QUESTION_FAILURE')

export const editQuestionRequest = createAction('question/EDIT_QUESTION', withPayloadType<CreateOrEditQuestionPayload>())
export const editQuestionSuccess = createAction('question/EDIT_QUESTION_SUCCESSFUL', withPayloadType<QuestionSuccessPayload>())
export const editQuestionFailure = createAction('question/EDIT_QUESTION_FAILURE')

export const deleteQuestionRequest = createAction('question/DELETE_QUESTION', withPayloadType<DeleteQuestionPayload>())

export const questionItemsFetchRequest = createAction('question/QUESTION_ITEMS_FETCH_REQUEST')
export const questionItemsFetchSuccess = createAction('question/QUESTION_ITEMS_FETCH_SUCCESS', withPayloadType<QuestionSuccessPayload>())
export const questionItemsFetchFailure = createAction('question/QUESTION_ITEMS_FETCH_ERROR')

export const questionItemInActivityFetch = createAction('question/QUESTION_ITEM_IN_ACTIVITY_FETCH', withPayloadType<FetchQuestionItemInActivityPayload>())

export const schoolQuestionItemsFetchRequest = createAction('question/SCHOOL_QUESTION_ITEMS_FETCH_REQUEST', withPayloadType<SchoolQuestionItemsFetchRequestPayload>())
export const schoolQuestionItemsFetchSuccess = createAction('question/SCHOOL_QUESTION_ITEMS_FETCH_SUCCESS', withPayloadType<QuestionSuccessPayload>())
export const schoolQuestionItemsFetchFailure = createAction('question/SCHOOL_QUESTION_ITEMS_FETCH_ERROR')

export const classroomsFetchRequest = createAction('question/CLASSROOM_FETCH_REQUEST')
export const classroomsFetchSuccess = createAction('question/CLASSROOM_FETCH_SUCCESS')
export const classroomsFetchFailure = createAction('question/CLASSROOM_FETCH_ERROR')

export const randomQuestionFetchRequest = createAction('question/RANDOM_QUESTION_FETCH_REQUEST')
export const randomQuestionFetchSuccess = createAction('question/RANDOM_QUESTION_FETCH_SUCCESS')
export const randomQuestionFetchFailure = createAction('question/RANDOM_QUESTION_FETCH_ERROR')

export const questionItemByIdFetchRequest = createAction('question/QUESTION_ITEM_BY_ID_FETCH_REQUEST', withPayloadType<number | string>())
export const questionItemByIdFetchSuccess = createAction('question/QUESTION_ITEM_BY_ID_FETCH_SUCCESS')
export const questionItemByIdFetchFailure = createAction('question/QUESTION_ITEM_BY_ID_FETCH_ERROR')

export const activityItemByIdFetchRequest = createAction('question/ACTIVITY_ITEM_BY_ID_FETCH_REQUEST', withPayloadType<any>())
export const activityItemByIdFetchSuccess = createAction('question/ACTIVITY_ITEM_BY_ID_FETCH_SUCCESS', withPayloadType<FetchActivityResponse>())
export const activityItemByIdFetchFailure = createAction('question/ACTIVITY_ITEM_BY_ID_FETCH_ERROR')

export const questionChangePagination = createAction('question/QUESTION_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())
export const questionChangeCurrentQuery = createAction('question/QUESTION_CHANGE_CURRENT_QUERY')
export const questionChangeFilter = createAction('question/QUESTION_CHANGE_FILTER')
export const questionResetFilter = createAction('question/QUESTION_RESET_FILTER')

export const questionTypesFetchRequest = createAction('question/QUESTION_TYPES_FETCH_REQUEST')
export const questionTypesFetchSuccess = createAction('question/QUESTION_TYPES_FETCH_SUCCESS')
export const questionTypesFetchFailure = createAction('question/QUESTION_TYPES_FETCH_ERROR')

export const questionChangeSearchId = createAction('question/QUESTION_CHANGE_SEARCH_ID')
export const questionStatusFilterChange = createAction('question/QUESTION_STATUS_FILTER_CHANGE')
export const clearQuestionDetails = createAction('question/CLEAR_QUESTION_DETAILS')
export const clearQuestionItem = createAction('question/CLEAR_QUESTION_ITEM')
export const questionAddInActivity = createAction('question/QUESTION_ADD_IN_ACTIVITY', withPayloadType<any>())
export const classroomAddInActivity = createAction('question/CLASSROOM_ADD_IN_ACTIVITY', withPayloadType<any>())
export const classroomClear = createAction('question/CLASSROOM_CLEAR')
export const classroomFetchRequest = createAction('question/CLASSROOMS_FETCH_REQUEST', withPayloadType<any>())
export const classroomFetchSuccess = createAction('question/CLASSROOMS_FETCH_SUCCESS', withPayloadType<any>())
export const classroomFetchFailure = createAction('question/CLASSROOMS_FETCH_ERROR')

export const questionRemoveViewFilters = createAction('QUESTION_REMOVE_VIEW_FILTERS')
export const addErrorsForm = createAction('question/ADD_ERRORS_FORM', withPayloadType<string[]>())
export const setQuestionCheckboxes = createAction('question/SET_QUESTION_CHECKBOXES', withPayloadType<(string | boolean)[]>())

export const clearActivityItem = createAction('question/CLEAR_ACTIVITY_ITEM')
export const clearSelectedClassrooms = createAction('question/SELECTED_CLASSROOMS_CLEAR')
export const clearSelectedQuestions = createAction('question/CLEAR_SELECTED_QUESTIONS')
export const clearPagination = createAction('question/CLEAR_PAGINATION')
export const questionSaveUiFilters = createAction('question/QUESTION_SAVE_UI_FILTERS', withPayloadType<QuestionSaveUiFiltersPayload>())
export const setQuestionSelected = createAction('question/SET_QUESTION_SELECTED', withPayloadType<{ byId: { [key: string | number]: any }, allIds: (string | number | undefined)[], types?: any[] }>())

export const fetchAnswerRequest = createAction('question/FETCH_ANSWER_REQUEST')
export const fetchAnswerSuccess = createAction('question/FETCH_ANSWER_SUCCESS', withPayloadType<AnswersPayload>())
export const fetchAnswerFailure = createAction('question/FETCH_ANSWER_ERROR')

export const fetchTeacherSubjectsRequest = createAction('question/FETCH_TEACHER_SUBJECTS_REQUEST')
export const fetchTeacherSubjectsSuccess = createAction('question/FETCH_TEACHER_SUBJECTS_SUCCESS')
export const fetchTeacherSubjectsFailure = createAction('question/FETCH_TEACHER_SUBJECTS_ERROR')

export const createQuestionVariantsRequest = createAction('question/CREATE_QUESTION_VARIANTS_REQUEST')
export const createQuestionVariantsSuccess = createAction('question/CREATE_QUESTION_VARIANTS_SUCCESS')
export const createQuestionVariantsFailure = createAction('question/CREATE_QUESTION_VARIANTS_ERROR')

export const questionDetailsFetchRequest = createAction('question/QUESTION_DETAILS_FETCH_REQUEST')
export const questionDetailsFetchSuccess = createAction('question/QUESTION_DETAILS_FETCH_SUCCESS', withPayloadType<QuestionDetailsPayload>())
export const questionDetailsFetchFailure = createAction('question/QUESTION_DETAILS_FETCH_ERROR')

export const fetchDiscursiveQuestionCorrectionRequest = createAction('question/DISCURSIVE_QUESTION_CORRECTION_REQUEST', withPayloadType<requestDiscursiveCorrectionPayload>())
export const fetchDiscursiveQuestionCorrectionSuccess = createAction('question/DISCURSIVE_QUESTION_CORRECTION_SUCCESS', withPayloadType<fetchDiscursiveCorrectionPayload>())
export const fetchDiscursiveQuestionCorrectionFailure = createAction('question/DISCURSIVE_QUESTION_CORRECTION_FAILURE')

export const giveTeacherFeedbackRequest = createAction('question/GIVE_TEACHER_FEEDBACK_REQUEST', withPayloadType<giveTeacherFeedbackPayload>())
export const giveTeacherFeedbackSuccess = createAction('question/GIVE_TEACHER_FEEDBACK_SUCCESS')
export const giveTeacherFeedbackFailure = createAction('question/GIVE_TEACHER_FEEDBACK_FAILURE')
