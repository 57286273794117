import React from 'react'
import { useAppSelector } from '../store/hooks'
import { checkCurrentRole } from '../utils/functions'
import BooksForm from '../components/Books/form/BooksForm'

const BooksFormEditContainer = ({ id, isEdit }: { id: string, isEdit: boolean }) => {
  const { auth: { user }, persistable: { selectedRole } } = useAppSelector(state => state)
  const isTeacher = user.id && selectedRole && checkCurrentRole(selectedRole, 'teacher')

  return (
    <>
      <BooksForm
        isTeacher={isTeacher}
        userId={user.id}
        id={id}
        isEdit={isEdit}
      />
    </>
  )
}

export default BooksFormEditContainer
