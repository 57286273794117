import { AnyAction, createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import {
  Books,
  Book,
  Chapter,
  BookPublished,
  ITeacher,
  IClassroomsPerformance,
  ICover,
  IBookReports,
  IStudentsPerformance,
  IBookByUser,
  IUnitsPerformance,
  IBookStudentPerformance,
  IBookData
} from '../../models/IBooks'

export interface FiltersType {
  query: {
    per: number
    page: number
  },
  chart: {
    school_classroom_ids: string | null
    school_unit_ids: string | null
    education_grade_ids: string | null
  },
  units: {
    school_classroom_id: string | null,
    school_unit_ids: string | null
  }
}

interface StateType {
  items: Books[]
  currentItem: {
    item: Book
    isFetching: boolean
    isSaving: boolean
    isSuccess: boolean
    isError: boolean
    messageError: string | null
  }
  chapters: {
    items: Chapter[],
    isFetching: boolean,
    isSaving: boolean,
    isChangingOrder: boolean,
    isError: boolean
    messageError: string | null
  },
  modulesSelected: { id: number, name: string, order: number }[]
  isFetching: boolean
  isProcessing: boolean
  isError: boolean
  isSuccess: boolean
  isSaving: boolean
  messageError: string | null
  pagination: {
    per: number
    totalOfPages: number,
    current: number
    total: number
  }
  filters: FiltersType
  covers: {
    items: ICover[],
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
  }
  classrooms: {
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
    items: any
  },
  bookToPublish: {
    isFetching: boolean,
    isProcessing: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null,
    book: BookPublished
  },
  bookCloned: {
    id: number | null,
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    messageError: string | null
  },
  teachers: {
    items: ITeacher[],
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  },
  classrooms_performance: {
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
    data: IClassroomsPerformance | null
  },
  units_performance: {
    isFetching: boolean,
    isError: boolean,
    messageError: string | null,
    data: IUnitsPerformance[]
  },
  students_performance: {
    data: IBookStudentPerformance,
    filters?: any,
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  },
  classroom_students: {
    items: IBookData[],
    isFetching: boolean,
    isError: boolean,
    messageError: string | null,
    filters: {
      school_classroom_id?: string,
      user_users_id?: string
    }
  },
  reports: {
    general: IBookReports | null
    isFetching: boolean
    isSuccess: boolean
    isError: boolean
    messageError: string | null,
    ranking: {
      isFetching: boolean,
      most_accessed: Book[],
      less_accessed: Book[]
    },
    authors: {
      isFetching: boolean,
      more_active: [],
      less_active: []
    },
    coAuthors: {
      isFetching: boolean,
      more_active: [],
      less_active: []
    },
    studentsPerformance: {
      items: IStudentsPerformance[],
      isFetching: boolean,
      isError: boolean,
      messageError: string | null,
      filters: {
        school_unit_ids: string | null,
        school_classroom_id: string | null,
        user_users_id: string | null,
        per: number,
        page: number
      }
      pagination?: {
        per: number
        totalOfPages: number,
        current: number
        total: number
      }
    },
    booksByUser: {
      items: IBookByUser[],
      isFetching: boolean,
      isError: boolean
      messageError: string | null,
      filters: {
        school_classroom_id?: any,
        user_users_id?: number
        book_books_id?: number
        des?: boolean
        asc?: boolean
        per?: number
        page?: number
      },
      pagination?: {
        per: number
        totalOfPages: number,
        current: number
        total: number
      }
    }
  },
}

const initialState: StateType = {
  items: [],
  currentItem: {
    item: {} as Book,
    isFetching: false,
    isSaving: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  chapters: {
    items: [] as Chapter[],
    isFetching: false,
    isSaving: false,
    isChangingOrder: false,
    isError: false,
    messageError: null
  },
  modulesSelected: [],
  isFetching: false,
  isProcessing: false,
  isError: false,
  isSuccess: false,
  isSaving: false,
  messageError: null,
  pagination: {
    per: 5,
    current: 1,
    totalOfPages: 0,
    total: 0
  },
  covers: {
    items: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  filters: {
    query: {
      per: 5,
      page: 1
    },
    chart: {
      education_grade_ids: null,
      school_classroom_ids: null,
      school_unit_ids: null
    },
    units: {
      school_classroom_id: null,
      school_unit_ids: null
    }
  },
  classrooms: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null,
    items: {}
  },
  bookToPublish: {
    isFetching: false,
    isProcessing: false,
    isSuccess: false,
    isError: false,
    messageError: null,
    book: {} as BookPublished
  },
  bookCloned: {
    id: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    messageError: null
  },
  teachers: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  },
  classrooms_performance: {
    isFetching: false,
    isError: false,
    messageError: null,
    data: null
  },
  units_performance: {
    isFetching: false,
    isError: false,
    messageError: null,
    data: []
  },
  students_performance: {
    isFetching: false,
    isError: false,
    messageError: null,
    data: {} as IBookStudentPerformance,
    filters: {}
  },
  classroom_students: {
    items: [],
    isFetching: false,
    isError: false,
    messageError: null,
    filters: {}
  },
  reports: {
    isFetching: false,
    isError: false,
    isSuccess: false,
    messageError: null,
    general: null,
    ranking: {
      isFetching: false,
      most_accessed: [],
      less_accessed: []
    },
    authors: {
      isFetching: false,
      more_active: [],
      less_active: []
    },
    coAuthors: {
      isFetching: false,
      more_active: [],
      less_active: []
    },
    studentsPerformance: {
      items: [],
      isFetching: false,
      isError: false,
      messageError: null,
      filters: {
        school_unit_ids: null,
        school_classroom_id: null,
        user_users_id: null,
        per: 10,
        page: 1
      },
      pagination: {
        per: 10,
        current: 1,
        totalOfPages: 0,
        total: 0
      },
    },
    booksByUser: {
      items: [],
      isFetching: false,
      isError: false,
      messageError: null,
      filters: {},
      pagination: {
        per: 5,
        totalOfPages: 0,
        current: 1,
        total: 0,
      }
    }
  },
}

const booksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.fetchBooksRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: true,
      isError: false,
      isSuccess: false,
      messageError: null,
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(actions.fetchBooksSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: null,
      items: action.payload
    }))
    .addCase(actions.fetchBooksFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.booksChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        ...action.payload
      }
    }))
    .addCase(actions.createBookRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.createBookSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: ''
    }))
    .addCase(actions.createBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: action.payload
    }))
    .addCase(actions.updateBookRequest, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isSuccess: false,
      isError: false,
      messageError: null
    }))
    .addCase(actions.updateBookSuccess, (state: StateType) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: true,
      isError: false,
      messageError: null
    }))
    .addCase(actions.updateBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isSuccess: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.fetchBookByIdRequest, (state: StateType) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchBookByIdSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchBookByIdFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isSuccess: false,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.setModules, (state: StateType, action: AnyAction) => ({
      ...state,
      modulesSelected: action.payload
    }))
    .addCase(actions.clearModules, (state: StateType) => ({
      ...state,
      modulesSelected: initialState.modulesSelected
    }))
    .addCase(actions.createChapterRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.createChapterSuccess, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.createChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.fetchChaptersRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: true,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchChaptersSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null,
        items: action.payload
      }
    }))
    .addCase(actions.fetchChaptersFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.updateChapterRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.updateChapterSuccess, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.updateChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isFetching: false,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearCurrentBook, (state: StateType) => ({
      ...state,
      currentItem: initialState.currentItem,
      chapters: initialState.chapters
    }))
    .addCase(actions.deleteChapterRequest, (state: StateType) => ({
      ...state,
      isProcessing: true,
      isError: false,
      messageError: null
    }))
    .addCase(actions.deleteChapterSuccess, (state: StateType) => ({
      ...state,
      isProcessing: false,
      isError: false,
      messageError: null
    }))
    .addCase(actions.deleteChapterFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isProcessing: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.changeChapterOrderRequest, (state: StateType) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: true
      }
    }))
    .addCase(actions.changeChapterOrderSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: false,
        items: action.payload
      }
    }))
    .addCase(actions.changeChapterOrderFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      chapters: {
        ...state.chapters,
        isChangingOrder: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearBooksFilters, (state: StateType) => ({
      ...state,
      filters: initialState.filters
    }))
    .addCase(actions.deleteBookRequest, (state: StateType) => ({
      ...state,
      isProcessing: true,
      isError: false,
      messageError: null
    }))
    .addCase(actions.deleteBookSuccess, (state: StateType) => ({
      ...state,
      isProcessing: false,
      isError: false,
      messageError: null
    }))
    .addCase(actions.deleteBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      isProcessing: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(actions.fetchClassroomsRequest, (state: StateType) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchClassroomsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: false,
        isSuccess: false,
        isError: false,
        messageError: null,
        items: action.payload
      }
    }))
    .addCase(actions.fetchClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearClassrooms, (state: StateType) => ({
      ...state,
      classrooms: initialState.classrooms
    }))
    .addCase(actions.addClassroomsRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.addClassroomsSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.addClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.changeClassroomsRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.changeClassroomsSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.changeClassroomsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.fetchBookPublishedRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: true,
        isProcessing: false,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchBookPublishedSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: false,
        messageError: null,
        book: action.payload
      }
    }))
    .addCase(actions.fetchBookPublishedFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isFetching: false,
        isProcessing: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload ?? 'Erro ao trazer os professores.'
      }
    }))
    .addCase(actions.clearBookPublished, (state: StateType) => ({
      ...state,
      bookToPublish: initialState.bookToPublish
    }))
    .addCase(actions.cloneBookRequest, (state: StateType) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.cloneBookSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null,
        id: action.payload.bookId
      }
    }))
    .addCase(actions.cloneBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookCloned: {
        ...state.bookCloned,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.addCoauthorRequest, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.addCoauthorSuccess, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.addCoauthorFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isSaving: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.getTeachersWithSubjectsRequest, (state: StateType) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.getTeachersWithSubjectsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.getTeachersWithSubjectsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        ...state.teachers,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.publishBookRequest, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: true,
        isFetching: false,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.publishBookSuccess, (state: StateType) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: false,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.publishBookFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      bookToPublish: {
        ...state.bookToPublish,
        isProcessing: false,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.updateBookStatusRequest, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.updateBookStatusSuccess, (state: StateType) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.updateBookStatusFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isSaving: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.getCoverBooksRequest, (state: StateType) => ({
      ...state,
      covers: {
        ...state.covers,
        isFetching: true,
        isSuccess: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.getCoverBooksSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      covers: {
        ...state.covers,
        items: action.payload,
        isFetching: false,
        isSuccess: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.getCoverBooksFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      covers: {
        ...state.covers,
        isFetching: false,
        isSuccess: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.classroomsPerformanceRequest, (state: StateType) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.classroomsPerformanceSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: false,
        isError: false,
        messageError: null,
        data: action.payload
      }
    }))
    .addCase(actions.classroomsPerformanceFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      classrooms_performance: {
        ...state.classrooms_performance,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearClassroomsPerformance, (state: StateType) => ({
      ...state,
      classrooms_performance: {
        ...initialState.classrooms_performance
      }
    }))
    .addCase(actions.fetchUnitsPerformanceRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      filters: {
        ...state.filters,
        units: {
          ...state.filters.units,
          ...action.payload.filters
        }
      },
      units_performance: {
        ...state.units_performance,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))


    .addCase(actions.fetchUnitsPerformanceSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      units_performance: {
        ...state.units_performance,
        isFetching: false,
        isError: false,
        messageError: null,
        data: action.payload
      }
    }))
    .addCase(actions.fetchUnitsPerformanceFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      units_performance: {
        ...state.units_performance,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearUnitsPerformance, (state: StateType) => ({
      ...state,
      units_performance: {
        ...initialState.units_performance
      }
    }))

    .addCase(actions.getBooksGeneralVisionRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: true,
        isError: false,
        messageError: null,
        general: {
          ...action.payload,
          isFetching: true
        }
      }
    }))
    .addCase(actions.getBooksGeneralVisionSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: false,
        isError: false,
        messageError: null,
        general: {
          ...action.payload,
          isFetching: false
        }
      }
    }))
    .addCase(actions.getBooksRankingRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: false,
        isError: false,
        messageError: null,
        ranking: {
          isFetching: true,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksRankingSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: false,
        isError: false,
        messageError: null,
        ranking: {
          isFetching: false,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksAuthorsRankingRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        authors: {
          isFetching: true,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksAuthorsRankingSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: false,
        isError: false,
        messageError: null,
        authors: {
          isFetching: false,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksCoauthorsRankingRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        isFetching: true,
        isError: false,
        messageError: null,
        coAuthors: {
          isFetching: true,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksCoauthorsRankingSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        coAuthors: {
          isFetching: false,
          ...action.payload,
        }
      }
    }))
    .addCase(actions.getBooksStudentsPerformanceRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        studentsPerformance: {
          ...state.reports.studentsPerformance,
          isFetching: true,
          isError: false,
          messageError: null,
          filters: {
            ...state.reports.studentsPerformance.filters,
            ...action.payload
          }
        }
      }
    }))
    .addCase(actions.getBooksStudentsPerformanceSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        studentsPerformance: {
          ...state.reports.studentsPerformance,
          items: action.payload,
          isFetching: false,
          isError: false,
          messageError: null
        }
      }
    }))
    .addCase(actions.getBooksStudentsPerformanceFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        studentsPerformance: {
          ...state.reports.studentsPerformance,
          isFetching: false,
          isError: true,
          messageError: action.payload
        }
      }
    }))
    .addCase(actions.clearBooksStudentsPerformance, (state: StateType) => ({
      ...state,
      reports: {
        ...state.reports,
        studentsPerformance: {
          ...initialState.reports.studentsPerformance
        }
      }
    }))
    .addCase(actions.studentsChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        studentsPerformance: {
          ...state.reports.studentsPerformance,
          pagination: {
            ...state.reports.studentsPerformance.pagination,
            ...action.payload
          }
        }
      }
    }))
    .addCase(actions.fetchTeachersAuthorsRequest, (state: StateType) => ({
      ...state,
      teachers: {
        items: [],
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))

    .addCase(actions.fetchTeachersAuthorsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      teachers: {
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(actions.fetchBooksByUserRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        booksByUser: {
          ...state.reports.booksByUser,
          isFetching: true,
          isError: false,
          messageError: null,
          filters: {
            ...state.reports.booksByUser.filters,
            ...action.payload
          }
        }
      }
    }))
    .addCase(actions.fetchBooksByUserSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        booksByUser: {
          ...state.reports.booksByUser,
          isFetching: false,
          isError: false,
          messageError: null,
          items: action.payload
        }
      }
    }))
    .addCase(actions.fetchBooksByUserFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        booksByUser: {
          ...state.reports.booksByUser,
          isFetching: false,
          isError: true,
          messageError: action.payload
        }
      }
    }))
    .addCase(actions.clearBooksByUser, (state: StateType) => ({
      ...state,
      reports: {
        ...state.reports,
        booksByUser: {
          ...initialState.reports.booksByUser
        }
      }
    }))
    .addCase(actions.booksByUserChangePagination, (state: StateType, action: AnyAction) => ({
      ...state,
      reports: {
        ...state.reports,
        booksByUser: {
          ...state.reports.booksByUser,
          pagination: {
            ...state.reports.booksByUser.pagination,
            ...action.payload
          }
        }
      }
    }))
    .addCase(actions.fetchStudentsPerformanceRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      students_performance: {
        ...state.students_performance,
        isFetching: true,
        isError: false,
        messageError: null,
        filters: {
          ...state.students_performance.filters,
          ...action.payload.filters
        }
      }
    }))
    .addCase(actions.fetchStudentsPerformanceSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      students_performance: {
        ...state.students_performance,
        isFetching: false,
        isError: false,
        messageError: null,
        data: action.payload
      }
    }))
    .addCase(actions.fetchStudentsPerformanceFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      students_performance: {
        ...state.students_performance,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearStudentsPerformance, (state: StateType) => ({
      ...state,
      students_performance: {
        ...initialState.students_performance
      }
    }))
    .addCase(actions.fetchClassroomStudentsRequest, (state: StateType, action: AnyAction) => ({
      ...state,
      classroom_students: {
        ...state.classroom_students,
        isFetching: true,
        isError: false,
        messageError: null,
        filters: {
          ...state.classroom_students.filters,
          ...action.payload.filters
        }
      }
    }))
    .addCase(actions.fetchClassroomStudentsSuccess, (state: StateType, action: AnyAction) => ({
      ...state,
      classroom_students: {
        ...state.classroom_students,
        isFetching: false,
        isError: false,
        messageError: null,
        items: action.payload
      }
    }))
    .addCase(actions.fetchClassroomStudentsFailure, (state: StateType, action: AnyAction) => ({
      ...state,
      classroom_students: {
        ...state.classroom_students,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(actions.clearClassroomStudents, (state: StateType) => ({
      ...state,
      classroom_students: {
        ...initialState.classroom_students
      }
    }))
})

export default booksReducer
