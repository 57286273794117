
import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { ITeacherState, IItem } from './types'
import { AnyAction } from 'redux-saga'

const initialState: ITeacherState = {
  items: [],
  currentItem: {
    item: {} as IItem,
    isFetching: false,
    isError: false,
    messageError: '',
  },
  isFetching: false,
  isError: false,
  isSaving: false,
  messageError: '',
  pagination: {
    current: 1,
    totalOfPages: 0,
    total: 0
  }
}

const teachersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.createTeacherRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.createTeacherSuccess, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.createTeacherRejected, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(actions.updateTeacherRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.updateTeacherSuccess, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.updateTeacherFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isSaving: false,
      isError: true,
      messageError: ''
    }))
    .addCase(actions.getAllTeachersRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
    }))
    .addCase(actions.getAllTeachersSuccess, (state: ITeacherState, action: AnyAction): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      items: action.payload
    }))
    .addCase(actions.getAllTeachersFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: 'Erro ao solicitar professores.'
    }))
    .addCase(actions.getTeacherByIdRequest, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(actions.getTeacherByIdSuccess, (state: ITeacherState, action: AnyAction): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(actions.getTeacherByIdFailure, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: 'Erro ao solicitar professor.'
      }
    }))
    .addCase(actions.teachersChangePagination, (state: ITeacherState, action: AnyAction) => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current
      }
    }))
    .addCase(actions.resetTeacher, (state: ITeacherState): ITeacherState => ({
      ...state,
      currentItem: initialState.currentItem
    }))
})

export default teachersReducer
