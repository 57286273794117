import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 600
}))
export const PrimaryTitle = styled('h1')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 600,
  paddingBottom: '18px',
  display: 'flex',
  alignContent: 'center',
  gap: '16px',
  letterSpacing: '0.18px'
}))
export const SecondaryTitle = styled('h2')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 600,
  paddingBottom: '18px',
  display: 'flex',
  alignContent: 'center',
  gap: '16px',
  lineHeight: '120%'
}))
