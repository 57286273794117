import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface ChangePaginationPayload {
  total?: number
  totalPages?: number
  current?: number
}

export interface GetBooksDeletedByUserRequestPayload {
  page?: number
  per?: number
}

export const getBooksDeletedByUserRequest = createAction('books:GET_BOOKS_DELETED_BY_USER_REQUEST', withPayloadType<GetBooksDeletedByUserRequestPayload>())
export const getBooksDeletedByUserSuccess = createAction('books:GET_BOOKS_DELETED_BY_USER_SUCCESS', withPayloadType<any>())
export const getBooksDeletedByUserFailure = createAction('books:GET_BOOKS_DELETED_BY_USER_FAILURE')

export const booksChangePagination = createAction('books:BOOKS_DELETED_CHANGE_PAGINATION', withPayloadType<ChangePaginationPayload>())
