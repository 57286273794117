import React from 'react'
import BooksReportsContainer from '../containers/BooksReportsContainer'

const BooksReportsPage = () => {

  return (
    <>
      <BooksReportsContainer />
    </>
  )
}

export default BooksReportsPage
