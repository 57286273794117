/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState } from 'react'
import { forceUserTempPasswordRequest } from '../../../store/auth/actions'
import { useDispatch } from 'react-redux'

import { CancelButton, ConfirmButton } from '../buttons/StyledButtons'
import { Box } from '@mui/system'

import { Typography, useTheme } from '@mui/material'


import { ClassroomStudent } from '../../../models/IClassroom'
import { PasswordInput } from '../input/TextInputStyled'

interface Props {
  student: ClassroomStudent
  setOpenResetPasswordModal: any
}

const ResetPasswordModal = ({ student, setOpenResetPasswordModal }: Props) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [tempPassword, setTempPassword] = useState<string>('')
  const [error, setError] = useState<string>('')

  const sendTempPassword = () => {
    if (tempPassword.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres.')
    } else {
      dispatch(forceUserTempPasswordRequest({ email: student?.email, password: tempPassword }))
    }
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTempPassword(value)
    setError('')
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: 500,
          paddingBottom: '24px'
        }}
        component='h1'
      >
        Gerar senha temporária:
      </Typography>
      <Box>
        <Box marginTop={'16px'} sx={{ display: 'flex', gap: '8px' }}>
          <Typography color={'#9F9F9F'} fontWeight={500} component={'span'}>Nome: </Typography>
          <Typography color={theme.palette.text.primary} fontWeight={700} component={'span'}>{student?.name}</Typography>
        </Box>
        <Box marginTop={'16px'} sx={{ display: 'flex', gap: '8px' }}>
          <Typography color={'#9F9F9F'} fontWeight={500} component={'span'}>Email: </Typography>
          <Typography color={theme.palette.text.primary} fontWeight={700} component={'span'}>{student?.email}</Typography>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
            marginTop: '16px',
          }}
        >
          <Typography color={'#9F9F9F'} fontWeight={500} component={'span'}>Senha: </Typography>
          <Typography component='span' sx={{ alignItems: 'center', display: 'flex' }}>
            <PasswordInput id='password' type='text' value={tempPassword} onChange={handlePasswordChange} />
          </Typography>
        </Box>

        {error && (
          <Typography color={theme.palette.error.light} sx={{ fontSize: '16px' }}>* {error}</Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: '24px',
          gap: '16px'
        }}
      >
        <ConfirmButton onClick={sendTempPassword}>Enviar</ConfirmButton>
        <CancelButton onClick={() => setOpenResetPasswordModal(false)}>Cancelar</CancelButton>
      </Box>
    </Box>
  )
}

export default ResetPasswordModal
