
import React, { useEffect, useState } from 'react'
import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'
import { activityItemByIdFetchRequest } from '../store/activities/actions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/configureStore'
import { Box } from '@mui/system'
import { LinearProgress, Paper, Typography, useTheme } from '@mui/material'
import { scrollToTop } from '../utils/functions'
import Button from '../components/button/Button'
import QuestionsIcon from '../assets/components/Questions/questions-icon.svg'
import { fetchDiscursiveQuestionCorrectionRequest, } from '../store/questions/actions'
import Label from '../components/label/Label'
import TeacherFeedbackForm from '../components/Questions/form/TeacherFeedbackForm'
import NotFoundData from '../assets/components/Questions/not-found-data.png'

const ActivityDiscursiveQuestionContainer = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { item, isFetching } = useSelector((state: RootState) => state.activities)
  const { discursiveQuestionCorrection } = useSelector((state: RootState) => state.questions)
  const { state } = useLocation()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(null)
  const questionValue = discursiveQuestionCorrection?.content_module_item?.value_item
  const studentsAnswers = discursiveQuestionCorrection?.answers
  const statement = discursiveQuestionCorrection?.content_question?.statement
  const isFetchingDiscursive = discursiveQuestionCorrection?.isFetching
  const theme = useTheme()
  const { auth } = useSelector((state: RootState) => state)
  const { activities } = useSelector((state: RootState) => state)
  const activityUserIdOwner = activities?.item?.created_by_user?.id
  const userLoggedId = auth?.user?.id

  useEffect(() => {
    if (id) {
      dispatch(activityItemByIdFetchRequest(id))
    }
  }, [])

  const extractedQuestions = state?.discursiveQuestion?.extractedDiscursiveQuestions
  useEffect(() => {
    if (state?.questionData?.content?.question?.id && state?.discursiveQuestion?.extractedDiscursiveQuestions) {
      const questionId = state?.questionData?.content?.question.id
      const extractedQuestions = state?.discursiveQuestion?.extractedDiscursiveQuestions
      const pageSize = 1
      const foundIndex = extractedQuestions.findIndex((question: any) => question?.id === questionId)

      if (foundIndex !== -1) {
        const calculatedPage = Math.ceil((foundIndex + 1) / pageSize)
        setCurrentPage(calculatedPage)
        setTotalPages(Math.ceil(extractedQuestions?.length / pageSize))
        setCurrentQuestionId(questionId)
      }
    }
  }, [state])

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
    if (state?.discursiveQuestion?.extractedDiscursiveQuestions && currentQuestionId) {
      const extractedQuestions = state.discursiveQuestion.extractedDiscursiveQuestions
      const currentIndex = extractedQuestions.findIndex((question: any) => question.id === currentQuestionId)
      if (currentIndex !== -1 && currentIndex < extractedQuestions.length - 1) {
        const nextQuestionId = extractedQuestions[currentIndex + 1].id
        setCurrentQuestionId(nextQuestionId)
      }
    }
  }

  const handlePrevPage = () => {
    setCurrentPage(prevPage => prevPage - 1)
    if (state?.discursiveQuestion?.extractedDiscursiveQuestions && currentQuestionId) {
      const extractedQuestions = state.discursiveQuestion.extractedDiscursiveQuestions
      const currentIndex = extractedQuestions.findIndex((question: any) => question.id === currentQuestionId)
      if (currentIndex !== -1 && currentIndex > 0) {
        const prevQuestionId = extractedQuestions[currentIndex - 1].id
        setCurrentQuestionId(prevQuestionId)
      }
    }
  }

  function findCurrentDiscursiveQuestionIds(id: any, array: any) {
    const result = array?.filter((currentQuestion: any) => currentQuestion.id === id)
    return result?.length > 0 ? result[0] : null
  }

  const currentDiscursiveQuestionIds = findCurrentDiscursiveQuestionIds(currentQuestionId, extractedQuestions)

  useEffect(() => {
    if (currentQuestionId) {
      dispatch(fetchDiscursiveQuestionCorrectionRequest({
        activity_id: Number(id),
        content_module_id: currentDiscursiveQuestionIds?.content_module_id,
        content_module_item_id: currentDiscursiveQuestionIds?.content_module_item_id,
        content_question_id: currentDiscursiveQuestionIds?.id
      }))
    }
    scrollToTop()
  }, [currentQuestionId])

  return (

    <>
      {activityUserIdOwner === userLoggedId ? (
        <>
          {
            isFetchingDiscursive ? (<LinearProgress sx={{ marginBottom: 2 }} color='secondary' />) : (
              <>
                {isFetching ? (<LinearProgress sx={{ marginBottom: 2 }} color='secondary' />) : <Box>
                  <Label sx={{ fontWeight: 500, margin: 0 }}>
                    <IconTitleSVG />
                    {item?.title}
                  </Label>
                  <Box
                    sx={{
                      alignItems: 'baseline',
                      display: 'flex',
                      marginLeft: '35px',
                      paddingTop: '8px',
                      paddingBottom: '40px',
                    }}>
                    <Box
                      sx={{
                        borderBottom: '2px dashed #ccc',
                        borderLeft: '2px dashed #ccc',
                        height: '30px',
                        width: '18px',
                      }} />
                    <Typography
                      sx={{
                        color: theme.palette.text.primary,
                        fontSize: '16px',
                        fontWeight: '600',
                        paddingLeft: '8px',
                      }}
                      component='p'>
                      Questão discursiva {currentPage}
                    </Typography>
                  </Box>
                </Box>}
                {studentsAnswers?.map((student) => {
                  return (
                    <Box key={student?.id}>
                      <TeacherFeedbackForm
                        questionValue={questionValue}
                        statement={statement}
                        student={student}
                        item={item}
                      />
                    </Box>
                  )
                })}
                {
                  studentsAnswers?.length === 0 && (
                    <Paper>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          pt: '42px',
                          pb: '73px'
                        }}
                      >
                        <img src={NotFoundData} />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '18px',
                            pt: '32px'
                          }}
                        >
                          Não existem respostas para essa questão
                        </Typography>
                      </Box>
                    </Paper>
                  )
                }
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'flex-end',
                    mt: '24px',
                  }}>
                  <Button
                    onClick={() => navigate(`/activities/${id}/view-activity-details`)}
                    variant='contained'
                    sx={{
                      alignSelf: 'flex-end',
                      alignItems: 'center',
                      backgroundColor: theme.palette.secondary.main,
                      background: theme.palette.primary.light,
                      display: 'flex',
                      fontWeight: 500,
                      fontSize: '16px',
                      gap: '11px',
                      marginTop: '32px',
                      marginBottom: '32px',
                      width: 'fit-content',
                    }}
                  ><img src={QuestionsIcon} alt='Icone lista de questões' />
                    Lista de questões
                  </Button>
                  {!(currentPage === 1) && <Button
                    border={'1px solid #B7B2C8'}
                    variant='outlined'
                    textColor={'#B7B2C8'}
                    fontWeight={500}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Questão anterior
                  </Button>}
                  {!(currentPage === totalPages) && <Button
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: '#FFF',
                      fontWeight: 500,
                      fontSize: '16px'
                    }}
                    variant='contained'
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Próxima Questão
                  </Button>}
                </Box>
              </>
            )
          }
        </>
      ) : (

        <>
          {
            isFetching ? (
              <LinearProgress
                sx={{ marginBottom: 2 }}
                color='secondary'
              />
            ) : <Box>
              <Label sx={{ fontWeight: 500, margin: 0 }}>
                <IconTitleSVG />
                {item?.title}
              </Label>
              <Box
                sx={{
                  alignItems: 'baseline',
                  display: 'flex',
                  marginLeft: '35px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                }}>
              </Box>
            </Box>
          }
          {activityUserIdOwner === userLoggedId  && <Paper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                pt: '42px',
                pb: '73px'
              }}
            >
              <img src={NotFoundData} />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '18px',
                  pt: '32px'
                }}
              >
                Você não tem permissão para corrigir atividade de outro professor.
              </Typography>
            </Box>
          </Paper>}
        </>

      )

      }
    </>

  )
}

export default ActivityDiscursiveQuestionContainer

