interface School {
  id: number
  name: string
  grade: { id: number, name: string }
}

interface Modules {
  id: number
  name: string
  status: string
  chapter: number
  difficulty_level: string
  time: number
}

export interface Chapter {
  id: number
  name: string
  modules: Modules[]
}

export interface Student {
  id: number
  name: string
  school: School
  performance: number
  progress: number
  position: number
  total_time: number
  start_date: string
  end_date: string
  activities_finished: number
  chapters: Chapter[]
}

export const students: Student[] = [
  {
    id: 1,
    name: 'Aline Oliveira',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 90,
    progress: 75,
    position: 1,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
  {
    id: 2,
    name: 'André Matias',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 86,
    progress: 75,
    position: 2,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
  {
    id: 3,
    name: 'John Erthal',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 82,
    progress: 72,
    position: 3,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
  {
    id: 4,
    name: 'Laura Montenegro',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 80,
    progress: 71,
    position: 4,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
  {
    id: 5,
    name: 'Linda Lopes Oliveira da Silva',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 77,
    progress: 71,
    position: 5,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
  {
    id: 6,
    name: 'Mário Batista',
    school: {
      id: 1000,
      name: 'Fractal Tecnologia',
      grade: { id: 99, name: '3º Ano A' }
    },
    performance: 65,
    progress: 68,
    position: 6,
    total_time: 1560,
    start_date: '01/03/2024',
    end_date: '01/06/2024',
    activities_finished: 5,
    chapters: [
      {
        id: 190,
        name: 'Fundamentos da Matemática',
        modules: [
          {
            id: 200,
            name: 'Introdução',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 900
          },
          {
            id: 201,
            name: 'Exercícios',
            status: 'finished',
            chapter: 1,
            difficulty_level: 'easy',
            time: 660
          }
        ]
      },
      {
        id: 191,
        name: 'Funções - Introdução',
        modules: [
          {
            id: 202,
            name: 'Função Afim',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 900
          },
          {
            id: 203,
            name: 'Exercícios',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'difficult',
            time: 600
          },
          {
            id: 204,
            name: 'Função Linear',
            status: 'finished',
            chapter: 2,
            difficulty_level: 'moderate',
            time: 2000
          },
          {
            id: 205,
            name: 'Exercícios',
            status: 'unfinished',
            chapter: 2,
            difficulty_level: 'very_difficulty',
            time: 0
          }
        ]
      }
    ]
  },
]


export interface ISchool {
  id: number
  name: string
}

export interface ISubject {
  id: number
  name: string
}

export interface IBook {
  id: number
  name: string
  description: string
  photo_url: string
  schools: ISchool[]
  subjects: ISubject[]
  performance: number
  acess: number
  status: string
  publication_date: string
  nps: number
}

export interface IAuthorsData {
  id: number
  name: string
  schools: ISchool[]
  subjects: ISubject[]
  number_of_books: number
  performance: number
  nps: number
  iterations: number
  books: IBook[]
}

export const authorsData: IAuthorsData[] = [
  {
    id: 1,
    name: 'Aline Costa da Silva Madeiros',
    schools: [
      { id: 1, name: 'Fractal' },
      { id: 2, name: 'Sérios' },
      { id: 3, name: 'Átrio' }
    ],
    subjects: [
      { id: 1, name: 'Matemática' },
      { id: 2, name: 'Física' },
      { id: 3, name: 'Álgebra Linear' }
    ],
    number_of_books: 15,
    performance: 98,
    nps: 100,
    iterations: 62,
    books: [
      {
        id: 1,
        name: 'Matemática Básica Vol. 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' },
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 2,
        name: 'Álgebra Linear - Do básico ao avançado',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 3,
        name: 'Física - Estática',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/028/original/trust_me.jpg?172021276',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'ready_to_publish',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 4,
        name: 'Física - Dinâmica',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'draft',
        publication_date: '10/07/2024',
        nps: 92
      }
    ]
  },
  {
    id: 2,
    name: 'Erika de Matos Medeiros',
    schools: [
      { id: 1, name: 'Fractal' },
      { id: 2, name: 'Sérios' },
      { id: 3, name: 'Átrio' }
    ],
    subjects: [
      { id: 1, name: 'Matemática' },
      { id: 2, name: 'Física' },
      { id: 3, name: 'Álgebra Linear' }
    ],
    number_of_books: 15,
    performance: 98,
    nps: 100,
    iterations: 62,
    books: [
      {
        id: 1,
        name: 'Matemática Básica Vol. 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' },
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 2,
        name: 'Álgebra Linear - Do básico ao avançado',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 3,
        name: 'Física - Estática',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/028/original/trust_me.jpg?172021276',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 4,
        name: 'Física - Dinâmica',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      }
    ]
  },
  {
    id: 3,
    name: 'Paulo Azevedo França',
    schools: [
      { id: 1, name: 'Fractal' },
      { id: 2, name: 'Sérios' },
      { id: 3, name: 'Átrio' }
    ],
    subjects: [
      { id: 1, name: 'Matemática' },
      { id: 2, name: 'Física' },
      { id: 3, name: 'Álgebra Linear' }
    ],
    number_of_books: 15,
    performance: 98,
    nps: 100,
    iterations: 62,
    books: [
      {
        id: 1,
        name: 'Matemática Básica Vol. 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' },
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 2,
        name: 'Álgebra Linear - Do básico ao avançado',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 3,
        name: 'Física - Estática',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/028/original/trust_me.jpg?172021276',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 4,
        name: 'Física - Dinâmica',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      }
    ]
  },
  {
    id: 4,
    name: 'Larissa Melo',
    schools: [
      { id: 1, name: 'Fractal' },
      { id: 2, name: 'Sérios' },
      { id: 3, name: 'Átrio' }
    ],
    subjects: [
      { id: 1, name: 'Matemática' },
      { id: 2, name: 'Física' },
      { id: 3, name: 'Álgebra Linear' }
    ],
    number_of_books: 15,
    performance: 98,
    nps: 100,
    iterations: 62,
    books: [
      {
        id: 1,
        name: 'Matemática Básica Vol. 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' },
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 2,
        name: 'Álgebra Linear - Do básico ao avançado',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        schools: [
          { id: 1, name: 'Fractal' },
          { id: 2, name: 'Sérios' },
          { id: 3, name: 'Átrio' }
        ],
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 3,
        name: 'Física - Estática',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/028/original/trust_me.jpg?172021276',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      },
      {
        id: 4,
        name: 'Física - Dinâmica',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia ante sit amet nisi rutrum elementum. Sed a tellus nunc. Nullam faucibus tellus at justo imperdiet volutpat. Fusce in odio eget dui sollicitudin condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Curabitur egestas nisl dolor, eu fringilla nisl bibendum sit amet.',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        schools: [
          { id: 1, name: 'Fractal' }
        ],
        subjects: [
          { id: 1, name: 'Matemática' },
          { id: 2, name: 'Física' }
        ],
        performance: 97,
        acess: 85,
        status: 'published',
        publication_date: '10/07/2024',
        nps: 92
      }
    ]
  }
]


export interface IGeneralStudents {
  id: number
  name: string
  performance: number
  number_of_books: number
  classroom: { id: number, name: string }
  grade: { id: number, name: string }
  school_unit: { id: number, name: string }
}

export const generalStudents: IGeneralStudents[] = [
  {
    id: 1,
    name: 'Aline Costa',
    performance: 97,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
  {
    id: 2,
    name: 'Maria Aparecida Simões',
    performance: 80,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
  {
    id: 3,
    name: 'Patrícia Costa da Silva Marques',
    performance: 72,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
  {
    id: 4,
    name: 'Marcos Freitas',
    performance: 48,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
  {
    id: 5,
    name: 'Joana Monteiro',
    performance: 30,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
  {
    id: 6,
    name: 'Tiago Dantas de Oliveira',
    performance: 10,
    number_of_books: 20,
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    }
  },
]


export interface IBooks {
  id: number
  name: string
  photo_url: string
  start_date: string
  end_date: string
  subjects: { id: number, name: string }[]
  performance: number
  progress: number
}

export interface IStudentDetails {
  id: number
  name: string
  classroom: { id: number, name: string }
  grade: { id: number, name: string }
  school_unit: { id: number, name: string }
  number_of_activities: number
  activities_finished: number
  time_on_books: number
  books: IBooks[]
}

export const studentDetails: IStudentDetails[] = [
  {
    id: 1,
    name: 'Aline Costa',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      }
    ]
  },
  {
    id: 2,
    name: 'Maria Aparecida Simões',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      }
    ]
  },
  {
    id: 3,
    name: 'Patrícia Costa da Silva Marques',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      }
    ]
  },
  {
    id: 4,
    name: 'Marcos Freitas',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      },
      {
        id: 5004,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5005,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5006,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5007,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
    ]
  },
  {
    id: 5,
    name: 'Joana Monteiro',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      }
    ]
  },
  {
    id: 6,
    name: 'Tiago Dantas de Oliveira',
    classroom: {
      id: 200,
      name: 'Turma Fractal'
    },
    grade: {
      id: 100,
      name: '3º Ano A'
    },
    school_unit: {
      id: 2000,
      name: 'Fractal School'
    },
    number_of_activities: 45,
    activities_finished: 40,
    time_on_books: 25800,
    books: [
      {
        id: 5000,
        name: 'Matemática Básica',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/041/original/matematica-cover-image.png?1721845367',
        start_date: '15/02/2024',
        end_date: '15/04/2024',
        subjects: [
          { id: 1, name: 'Matemática' }
        ],
        performance: 90,
        progress: 100
      },
      {
        id: 5001,
        name: 'Física - Estática',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/566/018/original/D2VmNuwWkAAIHPw.jpeg?1719864224',
        start_date: '16/04/2024',
        end_date: '16/06/2024',
        subjects: [
          { id: 2, name: 'Física' },
          { id: 1, name: 'Matemática' }
        ],
        performance: 95,
        progress: 100
      },
      {
        id: 5003,
        name: 'Álgebra Linear - Do básico ao avançado',
        photo_url: '//s3-sa-east-1.amazonaws.com/datagateway-stag/book/books/photos/000/565/980/original/image_%287%29.png?1718372680',
        start_date: '17/06/2024',
        end_date: '17/08/2024',
        subjects: [
          { id: 3, name: 'Álgebra Linear' }
        ],
        performance: 70,
        progress: 68
      }
    ]
  },
]


export interface IModule {
  id: number
  name: string
  start_date: string
  end_date: string
  progress: number
  performance: number
  total_questions: number
  number_of_hits: number
}

export interface IChapter {
  id: number
  chapter_order: number
  name: string
  modules: IModule[]
}

export interface IBookDetails {
  id: number
  name: string
  performance: number
  progress: number
  time: number
  chapters: IChapter[]
}

export const bookDetails: IBookDetails[] = [
  {
    id: 5000,
    name: 'Matemática Básica',
    performance: 90,
    progress: 100,
    time: 1500,
    chapters: [
      {
        id: 1,
        chapter_order: 1,
        name: 'Operações Básicas',
        modules: [
          {
            id: 10,
            name: 'Soma',
            start_date: '15/02/2024',
            end_date: '03/03/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 11,
            name: 'Subtração',
            start_date: '04/03/2024',
            end_date: '19/04/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 12,
            name: 'Multiplicação',
            start_date: '20/04/2024',
            end_date: '04/05/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
        ]
      }
    ]
  },
  {
    id: 5001,
    name: 'Física - Estática',
    performance: 95,
    progress: 100,
    time: 1360,
    chapters: [
      {
        id: 3,
        chapter_order: 1,
        name: 'Leis de Newton',
        modules: [
          {
            id: 14,
            name: '1º Lei de Newton',
            start_date: '05/05/2024',
            end_date: '20/05/2024',
            progress: 100,
            performance: 80,
            total_questions: 10,
            number_of_hits: 8
          },
          {
            id: 15,
            name: '2º Lei de Newton',
            start_date: '05/05/2024',
            end_date: '20/05/2024',
            progress: 100,
            performance: 80,
            total_questions: 10,
            number_of_hits: 8
          },
          {
            id: 16,
            name: '3º Lei de Newton',
            start_date: '05/05/2024',
            end_date: '20/05/2024',
            progress: 100,
            performance: 70,
            total_questions: 10,
            number_of_hits: 7
          }
        ]
      },
      {
        id: 4,
        chapter_order: 2,
        name: 'Iniciando com Vetores',
        modules: [
          {
            id: 17,
            name: 'Vetores',
            start_date: '05/05/2024',
            end_date: '20/05/2024',
            progress: 100,
            performance: 80,
            total_questions: 10,
            number_of_hits: 8
          }
        ]
      }
    ]
  },
  {
    id: 5003,
    name: 'Álgebra Linear - Do básico ao avançado',
    performance: 70,
    progress: 68,
    time: 2500,
    chapters: [
      {
        id: 5,
        chapter_order: 1,
        name: 'Vetores',
        modules: [
          {
            id: 18,
            name: 'Vetores',
            start_date: '21/05/2024',
            end_date: '05/06/2024',
            progress: 100,
            performance: 80,
            total_questions: 10,
            number_of_hits: 8
          },
          {
            id: 19,
            name: 'Produto Interno',
            start_date: '06/06/2024',
            end_date: '20/06/2024',
            progress: 100,
            performance: 90,
            total_questions: 10,
            number_of_hits: 9
          },
          {
            id: 20,
            name: 'Produto Vetorial',
            start_date: '21/06/2024',
            end_date: '05/07/2024',
            progress: 100,
            performance: 90,
            total_questions: 10,
            number_of_hits: 9
          },
        ]
      }
    ]
  },
  {
    id: 5004,
    name: 'Matemática Básica',
    performance: 90,
    progress: 100,
    time: 1500,
    chapters: [
      {
        id: 1,
        chapter_order: 1,
        name: 'Operações Básicas',
        modules: [
          {
            id: 10,
            name: 'Soma',
            start_date: '15/02/2024',
            end_date: '03/03/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 11,
            name: 'Subtração',
            start_date: '04/03/2024',
            end_date: '19/04/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 12,
            name: 'Multiplicação',
            start_date: '20/04/2024',
            end_date: '04/05/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
        ]
      }
    ]
  },
  {
    id: 5005,
    name: 'Matemática Básica',
    performance: 90,
    progress: 100,
    time: 1500,
    chapters: [
      {
        id: 1,
        chapter_order: 1,
        name: 'Operações Básicas',
        modules: [
          {
            id: 10,
            name: 'Soma',
            start_date: '15/02/2024',
            end_date: '03/03/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 11,
            name: 'Subtração',
            start_date: '04/03/2024',
            end_date: '19/04/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 12,
            name: 'Multiplicação',
            start_date: '20/04/2024',
            end_date: '04/05/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
        ]
      }
    ]
  },
  {
    id: 5006,
    name: 'Matemática Básica',
    performance: 90,
    progress: 100,
    time: 1500,
    chapters: [
      {
        id: 1,
        chapter_order: 1,
        name: 'Operações Básicas',
        modules: [
          {
            id: 10,
            name: 'Soma',
            start_date: '15/02/2024',
            end_date: '03/03/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 11,
            name: 'Subtração',
            start_date: '04/03/2024',
            end_date: '19/04/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 12,
            name: 'Multiplicação',
            start_date: '20/04/2024',
            end_date: '04/05/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
        ]
      }
    ]
  },
  {
    id: 5007,
    name: 'Matemática Básica',
    performance: 90,
    progress: 100,
    time: 1500,
    chapters: [
      {
        id: 1,
        chapter_order: 1,
        name: 'Operações Básicas',
        modules: [
          {
            id: 10,
            name: 'Soma',
            start_date: '15/02/2024',
            end_date: '03/03/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 11,
            name: 'Subtração',
            start_date: '04/03/2024',
            end_date: '19/04/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
          {
            id: 12,
            name: 'Multiplicação',
            start_date: '20/04/2024',
            end_date: '04/05/2024',
            progress: 100,
            performance: 100,
            total_questions: 10,
            number_of_hits: 10
          },
        ]
      }
    ]
  },
]
