import React from 'react'
import TabMaterial from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

interface StyledTabProps {
  disabled?: boolean
  completed?: boolean
  className?: string
  label: string
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined
  href?: string
  iconPosition?: 'bottom' | 'top' | 'end' | 'start' | undefined
  value?: any
  sx?: React.CSSProperties
}

const TabMaterialStyled = styled((props: StyledTabProps) => <TabMaterial disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    minHeight: 35,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: 14,
    fontWeight: 500,
    padding: '12.5px 32.5px',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light,
    flexBasis: 'auto',
    '&.Mui-selected': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
    '&.Mui-disabled': {
      color: '#666666',
      '& span': {
        color: '#666666',
        border: '1px solid #666666',
        backgroundColor: 'transparent',
      }
    }
  })
)

const Tab = (props: StyledTabProps) => {
  return (
    <TabMaterialStyled {...props} />
  )
}

export default Tab
