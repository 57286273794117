import React from 'react'
import Switch from '@mui/material/Switch'

const PerformanceSwitch = ({
  input: { value, onChange, ...restInput },
  disabled
}: any) => {
  const handleSwitchChange = (event: any) => {
    onChange(event.target.checked)
  }
  return (
    <div>
      <Switch
        {...restInput}
        checked={value}
        disabled={disabled}
        onChange={handleSwitchChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  )
}

export default PerformanceSwitch
