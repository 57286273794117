import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Assets
import BookIcon from '../assets/components/Books/book-icon.svg'
import ListIcon from '../assets/components/Books/list-icon.svg'

// Components
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Typography
} from '@mui/material'
import Button from '../components/button/Button'
import ErrorMessage from '../components/message/ErrorMessage'
import SchoolReportCollapse from '../components/Books/collapse/SchoolReportCollapse'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { classroomsPerformanceRequest, clearUnitsPerformance, fetchUnitsPerformanceRequest } from '../store/books/actions'

// Utils
import { scrollToTop } from '../utils/functions'

// Styles
import { styled } from '@mui/material/styles'
import SimpleSelect from '../components/select/SimpleSelect'

const Container = styled(Box)(({ theme }) => ({
  paddingTop: '30px',

  '& .book_name': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    marginBottom: '32px',

    '& h3': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px'
    }
  },

  '& .filters_container': {
    marginBottom: '44px',

    '& .filter': {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      width: '100%',

      '& .label': {
        fontWeight: 500
      }
    }
  }
}))

const Title = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  marginBottom: '16px',

  '& p': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px'
  }
}))

const ContentContainer = styled(Paper)(() => ({
  borderRadius: '16px',
  padding: '32px 20px 34px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}))

const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  marginTop: '32px',
}))

interface IOption {
  id: number
  name: string
}

const BooksReportClassroomPerformanceContainer = () => {
  const [unit, setUnit] = useState<IOption | null>(null)
  const [classroom, setClassroom] = useState<IOption | null>(null)
  const { id } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    units_performance: { data, isFetching, isError },
    classrooms_performance: { data: items }
  } = useAppSelector(state => state.books)

  const unitOptions = items?.units
  const classroomOptions = items?.school_classrooms?.map(classroom => ({
    id: classroom.id,
    name: classroom.name
  }))

  const handleGetUnitsData = (params?: object) => {
    dispatch(fetchUnitsPerformanceRequest({
      bookId: Number(id),
      filters: params
    }))
  }

  const handleSelectUnit = (option: IOption) => {
    setUnit(option)
    if (!option) {
      handleGetUnitsData({ school_unit_ids: null })
    } else {
      handleGetUnitsData({ school_unit_ids: String(option.id) })
    }
  }

  const handleSelectClassroom = (option: IOption) => {
    setClassroom(option)
    if (!option) {
      handleGetUnitsData({ school_classroom_id: null })
    } else {
      handleGetUnitsData({ school_classroom_id: String(option.id) })
    }
  }

  useEffect(() => {
    scrollToTop()
    dispatch(classroomsPerformanceRequest({ id: Number(id) }))
    handleGetUnitsData()

    return () => {
      dispatch(clearUnitsPerformance())
    }
  }, [])

  return (
    <Container>
      <Box className='book_name'>
        <img src={BookIcon} />
        <Typography component='h3'>
          {state?.title}
        </Typography>
      </Box>

      <Box className='filters_container'>
        <Grid container columnSpacing={5}>
          <Grid item xs={6}>
            <Box className='filter'>
              <Typography
                component='label'
                htmlFor='unit'
                className='label'
              >
                Escola
              </Typography>
              <SimpleSelect
                className='unit'
                placeholder='Selecione uma escola...'
                onChange={handleSelectUnit}
                value={unit}
                options={unitOptions ?? []}
                maxWidth='100%'
                hasBackground
                hasBorder
                isClearable
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className='filter'>
              <Typography
                component='label'
                htmlFor='classroom'
                className='label'
              >
                Turma
              </Typography>
              <SimpleSelect
                className='classroom'
                placeholder='Seleciona uma turma...'
                onChange={handleSelectClassroom}
                value={classroom}
                options={classroomOptions ?? []}
                maxWidth='100%'
                hasBackground
                hasBorder
                isClearable
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isFetching && (
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '27px', marginBottom: '16px', width: '120px' }}
        />
      )}

      {!isFetching && (
        <Title>
          <img src={ListIcon} alt='ícone de livro' />
          <Typography>Visão Geral</Typography>
        </Title>
      )}

      {isFetching ? (
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{
            borderRadius: '16px',
            height: '400px',
            marginBottom: '32px',
            width: '100%'
          }}
        />
      ) : (
        <ContentContainer>
          {isError && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '300px',
                justifyContent: 'center'
              }}
            >
              <ErrorMessage />
            </Box>
          )}

          {!isError && (
            data.map((item, index) => {
              return (
                <SchoolReportCollapse
                  key={item.id}
                  bookId={id}
                  name={item.name}
                  classrooms={item.school_classrooms}
                  schoolCollapseIndex={index}
                />
              )
            })
          )}
        </ContentContainer>
      )}

      <ButtonsContainer>
        <Button
          type='button'
          variant='contained'
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default BooksReportClassroomPerformanceContainer
