import React from 'react'
import { Box } from '@mui/material'
import DialogModal from './DialogModal'
import Button from '../button/Button'
import TrashBinIcon from '../../assets/components/Modal/trashbin-icon.svg'
import Label from '../label/Label'

interface IModalDelete {
  isOpen: boolean
  closeModal: () => void
  action?: () => void
  disableAction?: boolean
  type?: 'book' | 'chapter' | 'module' | 'exam' | 'question' | 'content' | 'filter' | 'all_filters'
}

const ModalDelete: React.FC<IModalDelete> = ({
  isOpen,
  closeModal,
  action,
  disableAction,
  type
}) => {

  let whatToDelete = ''
  switch (type) {
    case 'book':
      whatToDelete = 'livro'
      break
    case 'chapter':
      whatToDelete = 'capítulo'
      break
    case 'module':
      whatToDelete = 'módulo'
      break
    case 'exam':
      whatToDelete = 'prova'
      break
    case 'question':
      whatToDelete = 'questão'
      break
    case 'content':
      whatToDelete = 'conteúdo'
      break
    case 'filter':
      whatToDelete = 'filtro'
      break
    case 'all_filters':
      whatToDelete = 'todos os filtros'
      break
    default:
      whatToDelete = ''
  }

  const actionDeleteBook = (
    <Box display='flex' justifyContent='center' mb={4} gap={2} width='100%'>
      <Button
        sx={{ width: '107px', color: '#ffffff', fontWeight: 500 }}
        color='error'
        onClick={closeModal}
      >
        Não
      </Button>

      <Button
        sx={{ width: '107px', color: '#ffffff', fontWeight: 500 }}
        color='secondary'
        onClick={action}
        disabled={disableAction}
      >
        Sim
      </Button>
    </Box>
  )

  return (
    <>
      <DialogModal
        open={isOpen}
        handleClose={closeModal}
        actions={actionDeleteBook}
        width='500px'
      >
        <Box textAlign='center' pr={2} pl={2}>
          <img src={TrashBinIcon} alt='ícone de uma lixeira' width='205px' />
          {type === 'all_filters'
            ? (<Label sx={{ marginTop: '8px' }}>Deseja mesmo excluir {whatToDelete}?</Label>)
            : type === 'question' || type === 'exam'
              ? ((<Label sx={{ marginTop: '8px' }}>Deseja mesmo excluir esta {whatToDelete}?</Label>))
              : ((<Label sx={{ marginTop: '8px' }}>Deseja mesmo excluir este {whatToDelete}?</Label>))}
      </Box>
    </DialogModal >
    </>
  )
}

export default ModalDelete
