/* eslint-disable camelcase */
/* eslint-disable no-console */
import { AnyAction } from 'redux'
import http from '../../utils/http'
import { ISubject } from '../../models/ISubject'
import { AxiosResponse } from 'axios'
import { Feedback } from './types'

export const questionUrl = '/questions'
export const questionsEndpoint = '/questions/done'
export const subjectsEndpoint = '/subjects'
export const randomQuestionsEndpoint = '/questions/aleatory'
export const activityEndpoint = '/activities'
export const classroomsEndpoint = '/classrooms'
export const questionTypesEndpoint = '/content_module_types'
export const progressesEndpoint = '/progresses'
export const contentModuleItemsEndpoint = '/content_module_items'
export const questionVariantsEndpoint = '/variants'
export const fetchModulesEndpoint = '/content_modules'
export const questionsDetails = '/question_detail'

type FetchSubjectsParam = {
  basic: boolean
  teacher_user_id: string | number
  teacherSubject?: any
}

type fetchQuestionItemByIdPrams = {
  questionId: string | number
  params: object
}

type OptionsValue = {
  id: number | string
  name: string
}

export const createQuestion = (params: object) => {
  return http.post(questionUrl, params)
}

export const deleteQuestion = (id: any) => {
  return http.delete(`${questionUrl}/${id}`)
}

export const editQuestion = (params: any) => {
  return http.put(`${questionUrl}/${params.id}`, params)
}

export const fetchQuestionsItems = (params: object) => {
  return http.get(questionsEndpoint, params)
}

export const fetchSchoolQuestions = (params: object) => {
  return http.get(questionUrl, params)
}

export const fetchSubjects = (params: FetchSubjectsParam) => {
  return http
    .get('/subjects', {
      params
    })
    .then(({ data }) => {
      if (!params.teacherSubject) {
        return data.map((subject: ISubject) => subject.id).join(',')
      } else {
        return data.map((item: OptionsValue) => ({
          value: item.id,
          label: item.name
        }))
      }
    })
}

export const fetchRandomQuestionItem = (params: object) => {
  return http.get(randomQuestionsEndpoint, params)
}

export const addQuestionItem = ({ values, history }: AnyAction) => {
  return http
    .post('/questions', { ...values })
    .then(() => {
      history.push('/questions')
    })
    .catch(error => error)
}

export const editQuestionItem = ({ moduleId, url, values }: AnyAction) => {
  const requestUrl =
    url === 'curator'
      ? `/questions/${moduleId}/approval`
      : `/questions/${moduleId}`
  return http.put(requestUrl, { ...values })
}

export const deleteQuestionItemById = (questionId: number | string) =>
  http.delete(`${questionUrl}/${questionId}`)

export const fetchQuestionItemById = (params: fetchQuestionItemByIdPrams) => {
  return http.get(`${questionUrl}/${params.questionId}`, {
    params: params.params
  })
}

export const fetchActivityItemById = ({ id, params }: { id: number, params: any }) =>
  http.get(`${activityEndpoint}/${id}`, params)

export const fetchClassrooms = (params: object) =>
  http.get(`${classroomsEndpoint}/with_students`, { params })

export const fetchQuestionTypes = () => http.get(questionTypesEndpoint)

export const toggleQuestionLock = ({ questionId, action }: AnyAction) => {
  return http.put(`/questions/${questionId}/${action}`)
}

export const fetchAnswers = (params: object) => {
  return http.get(progressesEndpoint, { params })
}

export const fetchContentModuleItems = (id: number | string) => {
  return http.get(`${contentModuleItemsEndpoint}/${id}`)
}

export const createQuestionVariants = (params: object) => {
  return http.post(questionVariantsEndpoint, params)
}

export const updateQuestionVariants = (params: any) => {
  return http.put(`${questionVariantsEndpoint}/${params.id}`, params.variantsOption)
}

export const deleteQuestionVariants = (params: any) => {
  return http.delete(`${questionVariantsEndpoint}/${params.id}`)
}

export const fetchModuleById = ({ params, id }: { params?: object, id: number }) => {
  return http.get(`${fetchModulesEndpoint}/${id}/module_items`, { params })
}

export const fetchActivityParticipantStatus = ({ id }: { id: number }) =>
  http.get(`${activityEndpoint}/${id}/participant_status`)

export const fetchActivityNeedFeedback = ({ id }: { id: number }) =>
  http.get(`${activityEndpoint}/${id}/need_feedback`)

export const questionDetails = ({ params, id }: { params?: object, id: number }) => {
  return http.get(`${activityEndpoint}/${id}/question_detail`, { params })
}

export const fetchDiscursiveCorrection = async (payload: any) => {

  const {id, content_module_id, content_module_item_id, content_question_id } = payload
  const params = {
    content_module_id: content_module_id,
    content_module_item_id: content_module_item_id,
    content_question_id: content_question_id
  }
  const response: AxiosResponse<any> = await http.get(`/activities/${id}/discursive_correction`, {params})
  return response
}

export const giveTeacherFeedbackService = (payload: Feedback) => {
  return http.put(`answers/${payload?.id}`, payload )
}
