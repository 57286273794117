
import { createReducer } from '@reduxjs/toolkit'
import {
  GetRolesSuccessPayload,
  GetUserSuccessPayload,
  SetUserTokenSuccessPayload,
  getRoleRejected,
  getRoleRequest,
  getRoleSuccess,
  getUserDataRejected,
  getUserDataRequest,
  getUserDataSuccess,
  setUserTokenRejected,
  setUserTokenRequest,
  setUserTokenSuccess,
} from './actions'

interface UserState {
  user: object
  isFetching: boolean
  isError: boolean
  messageError: string | null
  roles: object
  credentials: object
}

const initialState: UserState = {
  user: {},
  isFetching: false,
  isError: false,
  messageError: '',
  roles: [],
  credentials: {}
}

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserTokenRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(setUserTokenSuccess, (state: UserState, action: { payload: SetUserTokenSuccessPayload }): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: '',
      credentials: action.payload.credentials
    }))
    .addCase(setUserTokenRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getUserDataRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getUserDataSuccess, (state: UserState, action: { payload: GetUserSuccessPayload }): UserState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      user: action.payload
    }))
    .addCase(getUserDataRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
    .addCase(getRoleRequest, (state: UserState): UserState => ({
      ...state,
      isFetching: true,
      isError: false,
      messageError: ''
    }))
    .addCase(getRoleSuccess, (state: UserState, action: { payload: GetRolesSuccessPayload }): UserState => ({
      ...state,
      isFetching: false,
      isError: false,
      messageError: '',
      roles: action.payload,
    }))
    .addCase(getRoleRejected, (state: UserState): UserState => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: ''
    }))
})

export default userReducer
