import * as React from 'react'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'

type CardProps = PropsWithChildren<{
  variant?: 'elevation' | 'outlined' | undefined
}>

const CardStyled = styled(Card)({
  background: '#F9F9F9',
  borderRadius: 16,
  '& .MuiCardContent-root': {
    padding: 24,
  },
  '& div > img': {
    marginRight: 10,
    verticalAlign: 'text-top',
  }
})

export default function ActivitiesCard({ children, variant }: CardProps) {
  return (
    <CardStyled sx={{ maxWidth: 333, width: '100%' }} variant={variant}>
      {children}
    </CardStyled>
  )
}
