import { isDate } from 'date-fns'

// validation
const requiredField = 'Campo Obrigatório *'

export const composeValidators = (...validators: Array<any>) => (value: any) => (
  validators.reduce((error, validator) => error || validator(value), undefined)
)

export const required = (value: any) => {
  return value && (value.length > 0 || !!value.name || isDate(value) || typeof value === 'number') ? undefined : requiredField
}

export const requiredTeacherScore = (value: any) => {
  return (value || value === 0) ? undefined : requiredField
}

export const fileRequired = (value: any) => {
  return (value && value.name) || (value && value.file_name) ? undefined : requiredField
}

export const videoRequired = (value: any) => {
  if (!(value && (value.length > 0 || !!value.name || isDate(value)))) {
    return requiredField
  } else if (value) {
    try {
      const isValid = new URL(value)
      return isValid && undefined
    } catch (e) {
      return 'Link inválido *'
    }
  }
}

export const validateFilterName = (value: string) => {
  const regex = /^[a-zA-Z0-9]+$/
  return !regex.test(value) ? 'Evite caracteres especiais e espaços em branco!' : null
}

export const minLength = (min: number) => (value: any) => value?.length >= min ? undefined : `min. ${min} caracteres.`

export const maxLength = (max: number) => (value: any) => value?.length <= max ? undefined : `máx. ${max} caracteres.`

export const jsonToFormdata = (formData: FormData, data:any, parentKey:any) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      jsonToFormdata(
        formData,
        data[key],
        parentKey
          ? !isNaN(Number(key)) ? `[${parentKey}][]` : `${parentKey}[${key}]`
          : key
      )
    })
  } else {
    const value = data === null || data === undefined ? '' : data
    if (value !== '') formData.append(`data[attributes]${parentKey}`, value)
  }
}

export const validateEmail = (value: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return !regex.test(value) ? 'Endereço de e-mail inválido!' : undefined
}


