import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { AxiosResponse } from 'axios'

import { signOutRequest } from '../auth/actions'

import {
  setUserTokenRequest,
  setUserTokenSuccess,
  setUserTokenRejected,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataRejected,
  getRoleSuccess,
  getRoleRejected,
} from './actions'
import * as services from './services'

export function* setUserRequest({ payload: token }: AnyAction) {
  try {
    yield put(setUserTokenSuccess({ credentials: token }))

  } catch (error) {
    console.error(error)
    yield put(setUserTokenRejected())
  }
}

export function* getUserData() {
  try {
    const response: AxiosResponse<any> = yield call(services.getUserData)
    yield put(getUserDataSuccess(response?.data))
  } catch (error) {
    yield put(getUserDataRejected())
  }
}

export function* getRoles() {
  try {
    const params = {
      user_application_id: process.env.REACT_APP_PROJECT_ID || 18
    }
    const { data: { data } } = yield call(services.getRoles, params)
    if (!data.length) {
      yield put(signOutRequest())
    }
    yield put(getUserDataRequest())
    yield put(getRoleSuccess(data))

  } catch (error) {
    console.error(error)
    yield put(signOutRequest())
    yield put(getRoleRejected())
  }
}

export function* watchUserSagas() {
  yield takeLatest(setUserTokenRequest.type, setUserRequest)
}

export default function* userSagas() {
  yield all([watchUserSagas()])
}
