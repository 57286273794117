import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Typography
} from '@mui/material'
import ErrorMessage from '../message/ErrorMessage'
import SimpleSelect from '../select/SimpleSelect'
import Table, { Columns } from '../table/Table'

// Utils
import { IUnitClassroom, IUnitsPerformance } from '../../models/IBooks'
import { styled, useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

// Icons
import BagIcon from '../../assets/components/Books/bag-light-icon.svg'
import ChartIcon from '../../assets/components/Books/chart-light-icon.svg'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import ProgressIcon from '../../assets/components/Books/progress-icon.svg'
import PeopleLightIcon from '../../assets/components/Books/people-light-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Books/view-outlined-icon.svg'


const Container = styled(Box)(() => ({
  background: '#ffffff',
  borderRadius: '16px',
  marginTop: '16px',
  padding: '32px 24px',

  '& .header': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between'
  },

  '& .table_container': {
    marginTop: '16px'
  }
}))

const BoxTitle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& img': {
    width: '12px'
  },

  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

const BoxFilter = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
}))

const ButtonStyled = styled(Button)(() => ({
  color: '#B7B2C8',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: 500,
  height: '48px',
  lineHeight: '120%',
  minWidth: 'fit-content',
  paddingInline: '24px',
  textTransform: 'none'
}))

const Label = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '4px',
  '&.align': {
    justifyContent: 'flex-end'
  }
}))

const Loading = () => {
  return (
    <Box>
      <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        {Array(4).fill(0).map((_, index) => {
          return (
            <Skeleton
              key={index}
              animation='wave'
              variant='rounded'
              sx={{
                height: '24px',
                width: '100px'
              }}
            />
          )
        })}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px' }}>
        {Array(2).fill(0).map((_, index) => {
          return (
            <Skeleton
              key={index}
              animation='wave'
              variant='rounded'
              sx={{ borderRadius: '8px', height: '60px', width: '100%' }}
            />
          )
        })}
      </Box>
    </Box>
  )
}

interface IPerformanceClassroomViewProps {
  bookId: number
  isFetching: boolean
  isError: boolean
  data: IUnitsPerformance[]
  title?: string
  handleGetData: (params?: object) => void
}

interface IOption {
  value: number
  name: string
}

const PerformanceClassroomView: React.FC<IPerformanceClassroomViewProps> = ({
  bookId,
  isFetching,
  isError,
  data,
  title,
  handleGetData
}) => {
  const [unitSelected, setUnitSelected] = useState<IOption | null>(null)
  const [classrooms, setClassrooms] = useState<IUnitClassroom[]>([])
  const [units, setUnits] = useState<IOption[]>([])
  const theme = useTheme()
  const navigate = useNavigate()

  const loadClassrooms = () => {
    const classroomsFiltered = data.flatMap((item) => item.school_classrooms)
    setClassrooms(classroomsFiltered)
  }

  const loadUnits = () => {
    const unitsFiltered = data.map((item) => ({ value: item.id, name: item.name }))
    unitsFiltered.forEach((item) => {
      if (!units.some(unit => unit.value === item.value)) {
        setUnits(prevState => [...prevState, item])
      }
    })
  }

  const handleSelectUnit = (unitOption: IOption) => {
    setUnitSelected(unitOption)
    if (!unitOption) {
      handleGetData({
        school_unit_ids: null
      })
    } else {
      handleGetData({
        school_unit_ids: String(unitOption.value)
      })
    }
  }

  useEffect(() => {
    loadClassrooms()
    loadUnits()
  }, [data])

  const columns: Columns[] = [
    {
      key: 'classroom',
      name: (
        <Label>
          <img src={BagIcon} />
          <Typography>Turma</Typography>
        </Label>
      ),
      width: '40%',
      align: 'left',
      render: (row: IUnitClassroom) => {
        return (
          <Box
            sx={{
              background: theme.palette.primary.light,
              borderRadius: '8px',
              color: '#f0f3f4',
              fontSize: '14px',
              fontWeight: 400,
              letterSpacing: '0.14px',
              maxWidth: '260px',
              overflow: 'hidden',
              padding: '5.5px 18px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 'fit-content'
            }}
            title={row.name}
          >
            {row.name ?? '-----'}
          </Box>
        )
      }
    },
    {
      key: 'performance',
      name: (
        <Label>
          <img src={ChartIcon} width='16px' />
          <Typography>Desempenho</Typography>
        </Label>
      ),
      width: '25%',
      align: 'left',
      render: (row: IUnitClassroom) => {
        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <LinearProgress
              sx={{ maxWidth: '80px', width: '100%', backgroundColor: '#e1e1e1' }}
              color={row.average_performance >= 50 ? 'secondary' : 'error'}
              value={row.average_performance ?? 0}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: '#666666',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%'
              }}
            >
              {row.average_performance ?? '000'}%
            </Box>
          </Box>
        )
      }
    },
    {
      key: 'progress',
      name: (
        <Label>
          <img src={ProgressIcon} />
          <Typography>Progresso</Typography>
        </Label>
      ),
      width: '25%',
      align: 'left',
      render: (row: IUnitClassroom) => {
        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <LinearProgress
              sx={{ maxWidth: '80px', width: '100%', backgroundColor: '#e1e1e1' }}
              color={row.average_progress >= 50 ? 'secondary' : 'error'}
              value={row.average_progress ?? 0}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: '#666666',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%'
              }}
            >
              {row.average_progress ?? '000'}%
            </Box>
          </Box>
        )
      }
    },
    {
      key: 'access',
      name: (
        <Label className='align'>
          <img src={PeopleLightIcon} />
          <Typography>Acessos</Typography>
        </Label>
      ),
      width: '10%',
      align: 'left',
      render: (row: IUnitClassroom) => {
        return (
          <Box
            sx={{
              color: '#666666',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '120%',
              textAlign: 'right'
            }}
          >
            {row.students_with_performance ?? '000'}/{row.total_students ?? '000'}
          </Box>
        )
      }
    }
  ]

  return (
    <Container>
      <Box className='header'>
        <BoxTitle>
          <img src={ChartIcon} />
          <Typography className='title'>Desempenho das turmas</Typography>
        </BoxTitle>

        <BoxFilter>
          <img src={SchoolIcon} />
          <SimpleSelect
            className='unit'
            placeholder='Escola...'
            onChange={handleSelectUnit}
            value={unitSelected}
            options={units}
            isClearable
          />
        </BoxFilter>

        <Box>
          <ButtonStyled
            type='button'
            variant='outlined'
            startIcon={<ViewIcon />}
            onClick={() => navigate(`/books/${bookId}/report/classroom-performance`, { state: { title } })}
          >
            Visualizar lista completa
          </ButtonStyled>
        </Box>
      </Box>

      <Box className='table_container'>
        {isFetching && (
          <Loading />
        )}

        {!isFetching && !isError && (
          <Table
            columns={columns}
            data={classrooms}
          />
        )}

        {isError && (
          <ErrorMessage />
        )}
      </Box>
    </Container>
  )
}

export default PerformanceClassroomView
