import React from 'react'
import StudentFormCreateContainer from '../containers/StudentFormCreateContainer'

const ManageStudentCreatePage = () => {
  return (
    <div>
      <StudentFormCreateContainer />
    </div>
  )
}

export default ManageStudentCreatePage
