import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress } from '@mui/material'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { createQuestionRequest, editQuestionRequest } from '../../../store/questions/actions'
import Tabs from '../../tabs/Tabs'
import Tab from '../../tabs/Tab'
import Label from '../../label/Label'
import Button from '../../button/Button'
import StepOne from './steps/StepOne'
import StepTwo from './steps/StepTwo'
import StepThree from './steps/StepThree'
import SuccessMessage from '../../message/SuccessMessage'
import ContentSupportView from './ContentSupportView'
import ListIcon from '../../../assets/components/Questions/list-icon.svg'
import StepsIcon from '../../../assets/components/Questions/steps-icon.svg'
import styles from './styles.module.scss'

const NumberIcon = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  color: '#FFF',
  height: 23,
  padding: 3,
  width: 23,
}))

const ImgStyle = styled('img')({
  marginRight: 16,
})

const DivBtns = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: '3rem',
  height: 90,
})

interface QuestionsFormProps {
  isTeacher: any
  userId?: any
  initialValues?: QuestionFormValues
}
export interface QuestionFormValues {
  id?: number | string
  answer_type?: { value: string, name: string }
  difficulty_level?: { value: string, name: string }
  enem_difficulty_level?: { value: string, name: string }
  content_parents?: number[]
  enem_type?: boolean
  polemic?: boolean
  question_options?: { is_correct: boolean, description: string, comment?: string }[]
  question_type?: { id: number | string, name: string, value: string }
  resolution?: string | null
  segment_ids?: { id: number | string, name: string }
  statement?: string | null
  subject_ids?: {
    id?: number | string
    name?: string
    topics?: { id?: number | string, name?: string }[]
    knowledge_area?: { id?: number | string, name?: string }
    competences?: { id?: number | string, name?: string }
    skills?: { id?: number | string, name?: string }[]
    basic?: boolean
  }[]
  theme_ids?: { id: number | string, name: string }[]
  use_universe_ids?: { id: number | string, name: string } | null
}

const QuestionsForm = (props: QuestionsFormProps) => {
  const { isTeacher, userId, initialValues } = props
  const [activeStep, setActiveStep] = useState(1)
  const [showContent, setShowContent] = useState<boolean>(false)
  const [contentsIds, setContentsIds] = useState(initialValues?.content_parents?.[0] ?? 0)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { questions: { isSuccess, isSavingOrUpdating }, contents } = useAppSelector(state => state)

  const handleTabChange = (_: any, value: number) => {
    setActiveStep(value)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const associateContent = (value: number) => {
    setContentsIds(value)
  }

  const removeContent = (id: number | string) => {
    contentsIds === id && setContentsIds(0)
  }

  const onSubmit = (values: QuestionFormValues) => {
    const data = {
      ...values,
    }
    !initialValues?.id
    ? dispatch(createQuestionRequest({ data }))
    : dispatch(editQuestionRequest({ data }))
  }

  const Buttons = ({ invalid, sx }: { invalid: boolean, sx?: React.CSSProperties }) => {
    return (
      <DivBtns sx={sx}>
        <Button
          onClick={() => {
            if(activeStep === 1){
              navigate('/questions')
            }
            handleBack()
          }}
          disabled={isSavingOrUpdating}
        >
          Voltar
        </Button>
        <Button
          type={activeStep === 3 ? 'submit' : 'button'}
          variant='contained'
          color={activeStep === 3 ? 'secondary' : 'primary'}
          disabled={invalid || isSavingOrUpdating}
          onClick={(e) => {
            if (activeStep !== 3) {
              e.preventDefault()
              handleNext()
            }
          }}
          startIcon={
            isSavingOrUpdating
              ? <CircularProgress size={20} variant='indeterminate' color='inherit' />
              : undefined
          }
          style={{ color: activeStep === 3 ? '#ffffff' : 'primary' }}
        >
          {activeStep === 3 ? 'Finalizar' : 'Avançar'}
        </Button>
      </DivBtns>
    )
  }

  return (
    <>
      <div>
        {isSuccess && !isSavingOrUpdating && (
          <SuccessMessage
            type='questions'
            path='questions'
            isEdit={Boolean(initialValues?.id)}
          />
        )}
        {!isSuccess && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'left', mb: 4, flexWrap: 'wrap' }}>
              <Box sx={{ display: 'flex', alignItems: 'left', mb: 4, width: '100%' }}>
                <Label>
                  <ImgStyle src={ListIcon} alt='icone de lista' />
                  Lista de Questões / Criar Questão
                </Label>
              </Box>
              <Box sx={{ padding: '8px 0', width: 'fit-content', alignSelf: 'flex-start', mr: 2 }}>
                <Label>
                  <ImgStyle src={StepsIcon} alt='icone das etapas' />
                  Etapas:
                </Label>
              </Box>
              <Tabs
                sx={{ border: 'none' }}
                variant='fullWidth'
                value={activeStep}
                onChange={handleTabChange}
              >
                <Tab className={activeStep >= 1 ? styles.completed : ''} icon={<NumberIcon>1</NumberIcon>} iconPosition='start' label='Propriedades' value={1} disabled={activeStep !== 1} />
                <Tab className={activeStep >= 2 ? styles.completed : ''} icon={<NumberIcon>2</NumberIcon>} iconPosition='start' label='Conteúdo' value={2} disabled={activeStep !== 2} />
                <Tab className={activeStep >= 3 ? styles.completed : ''} icon={<NumberIcon>3</NumberIcon>} iconPosition='start' label='Pedagógico' value={3} disabled={activeStep !== 3} />
              </Tabs>
            </Box>

            <Form
              sx={{ display: 'flex', gap: 2 }}
              initialValues={initialValues}
              onSubmit={onSubmit}
              mutators={{ ...arrayMutators }}
              render={({ handleSubmit, values, form, invalid, ...rest }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div style={{ display: showContent ? 'none' : 'initial' }}>
                      <StepOne
                        rest={rest}
                        values={values}
                        activeStep={activeStep}
                        isTeacher={isTeacher}
                        userId={userId}
                        change={form.change}
                        initialValues={initialValues}
                      />
                      <StepTwo
                        activeStep={activeStep}
                        values={values}
                      />
                      <StepThree
                        values={values}
                        activeStep={activeStep}
                        isTeacher={isTeacher}
                        userId={userId}
                        setShowContent={setShowContent}
                        removeContent={removeContent}
                        form={form}
                      />
                      <Buttons
                        invalid={invalid}
                      />
                    </div>

                    <div style={{ display: !showContent ? 'none' : 'initial' }}>
                      <ContentSupportView
                        contents={contents}
                        contentsIds={contentsIds}
                        isTeacher={isTeacher}
                        userId={userId}
                        associateContent={associateContent}
                        setShowContent={setShowContent}
                        form={form}
                      />
                    </div>
                  </form>
                )
              }}
            />
          </>
        )}

      </div>
    </>
  )
}

export default QuestionsForm
