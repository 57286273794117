import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import {
  modulesItemsFetchRequest,
  modulesItemsFetchSuccess,
  modulesItemsFetchError,
  modulesSaveUiFilters,
  modulesResetFilters,
  clearCurrentItem,
  fetchModuleByIdRequest,
  fetchModuleByIdSuccess,
  fetchModuleByIdError,
  fetchModuleByCodeRequest,
  fetchModuleByCodeSuccess,
  fetchModuleByCodeError,
  importModuleRequest,
  importModuleSuccess,
  importModuleError
} from './actions'

type Filter = {
  query: object
  ui: object
}

interface StateType {
  items: object[] | null
  isFetching: boolean
  isError: boolean
  pagination: object
  filters: Filter
  messageError: string | null
  currentItem: {
    isFetching: boolean,
    isError: boolean,
    item: any,
    messageError: string | null
  }
  importModule: object | null
}

const initialState: StateType = {
  items: [],
  pagination: {
    totalOfPages: 0,
    current: 1,
    total: 0,
    per: 1
  },
  isFetching: false,
  isError: false,
  messageError: null,
  currentItem: {
    isFetching: false,
    isError: false,
    item: null,
    messageError: null
  },
  importModule: {
    importModuleId: null,
    isImporting: false,
    isError: false,
    messageError: false
  },
  filters: {
    query: {},
    ui: {
      subject_ids: null,
      competence_ids: null,
      skill_ids: null,
      knowledge_area_ids: null,
      theme_ids: null,
      segment_ids: null,
      use_universe_ids: null,
      ids: null,
    }
  }
}

const modulesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(modulesItemsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(modulesItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      pagination: {
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current,
        total: action.payload.total,
        per: action.payload.per
      },
      items: action.payload.items,
      isFetching: false
    }))
    .addCase(modulesItemsFetchError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: null,
      pagination: {
        ...initialState.pagination
      },
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(modulesSaveUiFilters, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filters: {
        ...state.filters,
        ui: {
          ...state.filters.ui,
          ...action.payload
        }
      }
    }))
    .addCase(modulesResetFilters, (): StateType => ({
      ...initialState,
    }))
    .addCase(clearCurrentItem, (state: StateType): StateType => ({
      ...state,
      currentItem: {
        ...initialState.currentItem
      }
    }))
    .addCase(fetchModuleByCodeRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: true,
      filters: {
        ...state.filters,
        query: {
          ...state.filters.query,
          ...action.payload
        }
      }
    }))
    .addCase(fetchModuleByCodeSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: [action.payload.items],
      pagination: {
        totalOfPages: action.payload.totalOfPages,
        current: action.payload.current,
        total: action.payload.total,
        per: action.payload.per
      },
      isFetching: false
    }))
    .addCase(fetchModuleByCodeError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: [],
      isFetching: false,
      isError: true,
      pagination: { ...initialState.pagination },
      messageError: action.payload
    }))
    .addCase(fetchModuleByIdRequest, (state: StateType): StateType => ({
      ...state,
      currentItem: {
        ...initialState.currentItem,
        isFetching: true
      }
    }))
    .addCase(fetchModuleByIdSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...initialState.currentItem,
        isFetching: false,
        item: action.payload
      }
    }))
    .addCase(fetchModuleByIdError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...initialState.currentItem,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(importModuleRequest, (state: StateType, action: AnyAction): StateType => {
      return {
        ...state,
        importModule: {
          ...state.importModule,
          importModuleId: action.payload.id,
          isImporting: true
        }
      }
    })
    .addCase(importModuleSuccess, (state: StateType): StateType => {
      return {
        ...state,
        importModule: {
          ...initialState.importModule
        }
      }
    })
    .addCase(importModuleError, (state: StateType, action: AnyAction): StateType => {
      return {
        ...state,
        importModule: {
          isImporting: false,
          isError: true,
          messageError: action.payload
        }
      }
    })
})

export default modulesReducer
