import React, { Fragment } from 'react'
import { Box, Grid, LinearProgress, Paper, Skeleton, styled, Typography } from '@mui/material'
import { ButtonLightStyled } from '../button/Button'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as CheckmarkSVG } from '../../assets/icons/checkmark.svg'
import { ReactComponent as CrossSVG } from '../../assets/icons/cross.svg'
import { ReactComponent as CheckSVG } from '../../assets/icons/check-gray.svg'
import { ReactComponent as NoteSVG } from '../../assets/icons/note-icon.svg'
import { ReactComponent as DistractorSVG } from '../../assets/icons/distractor-icon.svg'
import { ReactComponent as DetailsSVG } from '../../assets/components/activity/icon-details.svg'

const Distractor = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: 8,
  color: '#FFF',
  fontWeight: 400,
  marginLeft: '8px',
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',

  '& .tooltiptext': {
    visibility: 'hidden',
    width: '80px',
    backgroundColor: '#555',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '6px',
    padding: '5px 0',
    position: 'absolute',
    zIndex: 1,
    bottom: '125%',
    left: '50%',
    marginLeft: '-60px',
    opacity: 0,
    transition: 'opacity 0.3s',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: '#555 transparent transparent transparent',
    }
  },

  '&:hover .tooltiptext': {
    visibility: 'visible',
    opacity: 1
  }
}))

const CheckmarkIcon = styled(CheckmarkSVG)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '50%',
  height: 16,
  padding: '4px 3px 4px 2px',
  marginRight: 8,
  width: 16
}))

const CrossIcon = styled(CrossSVG)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  height: 16,
  padding: 4,
  marginRight: 8,
  width: 16
}))

const CheckmarkQuestion = styled(CheckSVG)(() => ({
  marginRight: 8
}))

const questionStyled = {
  alignItems: 'baseline',
  borderBottom: '1px solid #D9D9D966',
  fontSize: 14,
  pb: 1
}

type Props = {
  moreAccurate?: any
  lessAccurate?: any,
  isFetching?: boolean
}

const textExtract = (text: string) => {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = text
  const extractedText = tempElement.innerText
  const limitedText = extractedText.length > 100 ? extractedText.substring(0, 90) + '...' : extractedText
  return limitedText
}

const optionOrder = (order: number) => {
  switch (order) {
    case 1:
      return 'A'
    case 2:
      return 'B'
    case 3:
      return 'C'
    case 4:
      return 'D'
    case 5:
      return 'E'
    default:
      break
  }
}

const QuestionsPerformanceView = ({ moreAccurate, lessAccurate, isFetching }: Props) => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Paper sx={{ borderRadius: 4, mt: 4, p: 3 }}>
      { isFetching ? (
        <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton animation='wave' height={50} width={100} />
          <Skeleton animation='wave' height={50} width={100} />
        </Box>
        <Box>
            <Skeleton animation='wave' height={'50px'} width={'250px'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
            <Skeleton animation='wave' height={'50px'} width={'250px'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
            <Skeleton animation='wave' height={'50px'} width={'100%'} />
        </Box>
      </Box>
      ) :
        <>
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
              <DetailsSVG style={{ marginRight: 16 }} />
              Detalhes da questão
            </Typography>
            <ButtonLightStyled
              onClick={() => navigate(`/activities/${id}/view-activity-details`)}
              startIcon={<ViewSVG />}
              variant='outlined'
            >
              Visualizar lista completa
            </ButtonLightStyled>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography component='p' variant='subtitle2' color='secondary'>
                <CheckmarkIcon />Mais acertadas
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} component='p' variant='subtitle2'>
                <DistractorSVG /> Distrator
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} component='p' variant='subtitle2'>
                <NoteSVG />  %
              </Typography>
            </Grid>
            {moreAccurate && moreAccurate?.slice(-3).reverse().map((question: any, index: any) => (
              <Grid item container sx={questionStyled} key={`${index}-${question.question_id}`}>
                <Grid item xs={8}>
                  <CheckmarkQuestion />{index + 1} - {textExtract(question.statement)}
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>

                  {Array.isArray(question.distractor) && question?.distractor?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        {question.distractor[index] !== null ?
                          <Distractor>
                            {optionOrder(question.distractor[index]?.question_option_order)}
                            <span className='tooltiptext'>{question.distractor[index]?.percentage} %</span>
                          </Distractor> : null}
                      </Fragment>
                    )
                  })}

                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <LinearProgress
                    sx={{ width: '100%', backgroundColor: '#F0F3F4' }}
                    color='secondary'
                    value={question.averege}
                    variant='determinate'
                  />
                  <span>{question.averege}</span>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 6 }}>
            <Grid item xs={8} >
              <Typography component='p' variant='subtitle2' color='error'>
                <CrossIcon />Menos acertadas
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} component='p' variant='subtitle2'>
                <DistractorSVG /> Distrator
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} component='p' variant='subtitle2'>
                <NoteSVG />  %
              </Typography>
            </Grid>
            {lessAccurate && lessAccurate?.slice(-3).reverse().map((question: any, index: any) => (
              <Grid item container sx={questionStyled} key={`${index}-${question}`}>
                <Grid item xs={8}>
                  <CheckmarkQuestion />{index + 1} - {textExtract(question.statement)}
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
                  {Array.isArray(question.distractor) && question?.distractor?.map((item: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        {question.distractor[index] !== null ?
                          <Distractor>
                            {optionOrder(question.distractor[index]?.question_option_order)}
                            <span className='tooltiptext'>{question.distractor[index]?.percentage} %</span>
                          </Distractor> : null}
                      </Fragment>
                    )
                  })}
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <LinearProgress
                    sx={{ width: '100%', backgroundColor: '#F0F3F4' }}
                    color='error'
                    value={question.averege}
                    variant='determinate'
                  />
                  <span>{question.averege}</span>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      }
    </Paper>
  )
}

export default QuestionsPerformanceView
