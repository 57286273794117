import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

export const gradesOfClassRoomSelected = createAction('grades/GRADES_OF_CLASSROOMS_SELECTED')

interface StateType {
  gradesWithClassrooms: Array<{ value: number }> | null
}

const initialState: StateType = {
  gradesWithClassrooms: null
}

const gradesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(gradesOfClassRoomSelected, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      gradesWithClassrooms: action.payload
    }))
})

export default gradesReducer