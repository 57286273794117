// React
import React, { useState } from 'react'

// Components
import { Box, Typography } from '@mui/material'
import DatePickerField from '../../input/DatePickerField'

// Utils
import { styled } from '@mui/material/styles'
import { Dates, IClassroomModules } from '../../../models/IBooks'

// Icons
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'

const ModuleItemContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#EFE7FF',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  gap: '30px',
  padding: '6px 16px',

  [theme.breakpoints.down(1190)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '16px'
  },

  '& .module': {
    display: 'flex',
    gap: '16px',

    '&_code': {
    alignItems: 'center',
    display: 'flex',

    '& img': {
      marginRight: '8px'
    },

    '& span': {
      fontSize: '14px',
      fontWeight: 600,

      '&:first-of-type': {
        color: theme.palette.text.primary,
        marginRight: '4px'
      },

      '&:last-of-type': {
        color: theme.palette.primary.light
      }
    }
  },

  '&_name': {
    alignItems: 'center',
    display: 'flex',

    '& span': {
      fontSize: '14px',
      fontWeight: 600,

      '&:first-of-type': {
        color: theme.palette.primary.main,
        marginRight: '4px'
      },

      '&:last-of-type': {
        color: theme.palette.text.primary,
        maxWidth: '350px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  }
  }
}))

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  gap: '32px',
  justifyContent: 'flex-end',

  '& .field': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '&_label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.14px'
    }
  }
}))

interface ModuleItemProps {
  module: IClassroomModules
  chapterId: number
  classroomChapterId: number
  dates: Dates[]
  changeModuleDate: (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => void
  classroomChapterDates: {
    start_date?: Date,
    end_date?: Date
  }
  enableDateField?: boolean
}

const ModuleItem: React.FC<ModuleItemProps> = ({
  module,
  chapterId,
  classroomChapterId,
  dates,
  changeModuleDate,
  classroomChapterDates,
  enableDateField
}) => {
  const [date, setDate] = useState({
    start_date: module.start_date ? new Date(module.start_date) : undefined,
    end_date: module.end_date ? new Date(module.end_date) : undefined
  })
  const chapterItem = dates?.find((date) => date.chapterId === chapterId)
  const classroomChapterItem = chapterItem?.classroom_chapters?.find((item: any) => item.id === classroomChapterId)
  const moduleItem = classroomChapterItem?.classroom_modules?.find((item: any) => item.id === module.id)
  const startDate = moduleItem?.start_date ? moduleItem?.start_date?.split(' ')[0] : undefined
  const endDate = moduleItem?.end_date ? moduleItem?.end_date?.split(' ')[0] : undefined

  const handleChangeDate = (chapterId: number, classroomChapterId: number, moduleId: number, key: any, value: Date) => {
    setDate({ ...date, [key]: value })
    changeModuleDate(chapterId, classroomChapterId, moduleId, key, value)
  }

  const filterRangeStartDate = (time: any) => {
    const selectedDate = String(date.end_date)
    const selectDateTime = new Date(selectedDate)
    return time < selectDateTime.getTime()
  }

  const filterRangeEndDate = (time: any) => {
    const selectedDate = String(date.start_date)
    const selectedDateTime = new Date(selectedDate)
    return time > selectedDateTime.getTime()
  }

  const startDateLim = classroomChapterDates.start_date ? new Date(classroomChapterDates.start_date) : undefined
  startDateLim?.setHours(0)
  startDateLim?.setMinutes(0)

  const endDateLim = classroomChapterDates.end_date ? new Date(classroomChapterDates.end_date) : undefined
  endDateLim?.setHours(23)
  endDateLim?.setMinutes(59)

  return (
    <ModuleItemContainer>
      <Box className='module'>
        <Box className='module_code'>
          <img src={BarCodeIcon} />
          <Typography
            component='span'
          >
            Código:
          </Typography>

          <Typography
            component='span'
          >
            {module.content_module.id}
          </Typography>
        </Box>

        <Box className='module_name'>
          <Typography
            component='span'
          >
            Nome:
          </Typography>

          <Typography
            component='span'
            title={module.content_module.name}
          >
            {module.content_module.name}
          </Typography>
        </Box>
      </Box>

      <FormBox>
        <Box className='field'>
          <Typography className='field_label'>Início:</Typography>
          <DatePickerField
            name='start_date'
            locale='pt-BR'
            timeIntervals={1}
            timeCaption='Hora'
            dateFormat='dd/MM/yyyy'
            placeholder='00/00/00'
            width='170px'
            disabled={!enableDateField}
            changeDate={(value: any) =>
              handleChangeDate(
                chapterId,
                classroomChapterId,
                module.id,
                'start_date',
                value
              )}
            date={startDate}
            startDate={date?.start_date}
            endDate={date?.start_date}
            minDate={startDateLim}
            maxDate={date?.end_date ?? endDateLim}
            filterTime={date?.end_date && filterRangeStartDate}
            selectsStart
            readOnly
          />
        </Box>

        <Box className='field'>
          <Typography className='field_label'>
            Fim:
          </Typography>
          <DatePickerField
            name='end_date'
            locale='pt-BR'
            timeIntervals={1}
            timeCaption='Hora'
            dateFormat='dd/MM/yyyy'
            placeholder='00/00/00'
            width='170px'
            disabled={!enableDateField}
            changeDate={(value: any) =>
              handleChangeDate(
                chapterId,
                classroomChapterId,
                module.id,
                'end_date',
                value
              )}
            date={endDate}
            startDate={date?.end_date}
            endDate={date?.end_date}
            minDate={date?.start_date ?? startDateLim}
            maxDate={endDateLim}
            filterTime={filterRangeEndDate}
            readOnly
          />
        </Box>
      </FormBox>
    </ModuleItemContainer>
  )
}

export default ModuleItem
