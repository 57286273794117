import React, { useState } from 'react'
import { Box, Collapse, IconButton } from '@mui/material'
import ClassroomViewCollapse from './ClassroomViewCollapse'
import { styled } from '@mui/material/styles'
import { useAppSelector } from '../../../store/hooks'
import { IClassroomChapters } from '../../../models/IBooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const SchoolNameBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  width: 'fit-content',

  '& span': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '150%'
  },

  '& .school_name': {
    color: theme.palette.text.primary,
    marginRight: '4px'
  },

  '& .school_numberOfClassrooms': {
    color: theme.palette.primary.light,
    marginRight: '12px'
  }
}))

interface ISchoolViewCollpseProps {
  school?: {
    id: number
    name?: string
    numberOfClassrooms?: number
    schoolData?: IClassroomChapters[]
  }
}

const SchoolViewCollapse: React.FC<ISchoolViewCollpseProps> = ({ school }) => {
  const [openSchool, setOpenSchool] = useState(false)
  const { classrooms: { items } } = useAppSelector(state => state.books)

  return (
    <Box>
      <SchoolNameBox onClick={() => setOpenSchool(prevState => !prevState)}>
        <Box
          className='school_name'
          component='span'
        >
          {school?.name}
        </Box>

        <Box
          className='school_numberOfClassrooms'
          component='span'
        >
          ({school?.numberOfClassrooms})
        </Box>

        <IconButton sx={{ width: '18px', height: '18px' }}>
          <KeyboardArrowDownIcon
            fontSize='small'
            sx={{ transform: openSchool ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </IconButton>
      </SchoolNameBox>

      <Collapse in={openSchool}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginTop: '16px'
          }}
        >
          {school?.schoolData?.map((classroom) => {
            const students = items?.[school?.id]?.find((item: any) => item?.id === classroom?.school_classroom?.id)

            return (
              <ClassroomViewCollapse
                key={classroom.id}
                classroom={classroom}
                students={students?.users}
              />
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

export default SchoolViewCollapse
