import React from 'react'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import ImagePreview from './ImagePreview'

const FileContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  '& > div': {
    maxHeight: '439px !important',
    width: '100% !important',
  },
  '& > video': {
    borderRadius: '16px',
    height: '439px !important',
  },
}))

const MediaPreview = ({ url, type }: any) => {
  function extractType(type: any) {
    return type.split('/')[0]
  }
  const extractedFileType = extractType(type)
  const isVideo = extractedFileType === 'video'
  const isImage = extractedFileType === 'image'
  return (
    <>
      {
        isVideo && (
          <Box
            width={'100%'}>
            <FileContainer>
              <video width={'100%'} controls>
                <source src={url || URL.createObjectURL(url)} />
                O seu navegador não suporta o elemento <code>audio</code>.
              </video>
            </FileContainer>
          </Box>
        )
      }
      {
        isImage && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
              <ImagePreview url={url}/>
          </Box>
        )
      }
    </>
  )
}

export default MediaPreview
