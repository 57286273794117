/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Card, Skeleton } from '@mui/material'

import { ReactComponent as IconTitleSVG } from '../assets/icons/icon-title.svg'
import { ReactComponent as MoreSVG } from '../assets/icons/more.svg'
import { ReactComponent as SuperSVG } from '../assets/super.svg'
import { ReactComponent as UserWithPapersSVG } from '../assets/papers.svg'
import { ReactComponent as StudingSVG } from '../assets/studing.svg'
import { ReactComponent as ThinkingSVG } from '../assets/thinking.svg'

import { RootState } from '../store/configureStore'
import {
  clearSelectedQuestions,
  clearQuestionDetails,
  // fetchAnswerRequest,
  questionDetailsFetchRequest
} from '../store/questions/actions'
// import { activityRankingReportFetchRequest } from '../store/reports/index'
import {
  activitiesOverviewFetchRequest,
  activityItemByIdFetchRequest,
  clearActivityItem
} from '../store/activities/actions'
import Label from '../components/label/Label'
import { DefaultOptionType } from '../utils/types'
import { styled } from '@mui/system'
import ScoreStudentsView from '../components/view/ScoreStudentsView'
import StudentsPerformanceView from '../components/view/StudentsPerformanceView'
import QuestionsPerformanceView from '../components/view/QuestionsPerformanceView'
import ActivityClassroomsView from '../components/view/ActivityClassroomsView'
import Button from '../components/button/Button'
import ActivityStudentsView from '../components/view/ActivityStudentsView'
import { IQuestion } from '../models/IQuestion'
import { IActivities } from '../models/IActivities'
import { activityRankingReportFetchRequest, activityRankingReset } from '../store/reports'
import { scrollToTop } from '../utils/functions'


const SelectsContainer = styled('div')({
  display: 'flex',
  gap: 48,
  marginTop: 32,
  '& .MuiBox-root': {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 16
  },
  '& > div > .MuiFormControl-root > .MuiOutlinedInput-root': {
    backgroundColor: '#FFF',
    maxWidth: '381px'
  },
  '& .MuiTypography-body2': {
    color: '#666'
  }
})

const InfoCardContainer = styled('div')(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '28px 28px 12px',
  marginTop: 32,
  gap: 8,
  '& > div': {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: 32
  }
}))

const InfoCard = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  height: 201,
  paddingTop: 28,
  textAlign: 'center',
  width: 247,
  '& p': {
    fontSize: 14,
    '& span': {
      color: theme.palette.primary.light,
      fontWeight: 800
    }
  }
}))

export const normalizeClassroomsAndStudents = (participants: any[]) => {
  const obj: any = {}
  const classrooms: Array<any> = []
  participants.forEach((elem: any) => {
    !classrooms.find((classroom) => classroom.id === elem.school_classroom.id) && classrooms.push(elem.school_classroom)
    if (obj[elem.school_classroom.unit.name]) {
      if (obj[elem.school_classroom.unit.name][elem.school_classroom.name]) {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name].push(
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        )
      } else {
        obj[elem.school_classroom.unit.name][elem.school_classroom.name] = [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    } else {
      obj[elem.school_classroom.unit.name] = {
        [elem.school_classroom.name]: [
          {
            name: elem.user.name,
            id: elem.user.id,
            answers: elem.answers
          }
        ]
      }
    }
  })
  return Object.keys(obj).map(unitName => {
    return ({
      unit_name: unitName,
      classrooms: Object.keys(obj[unitName]).map(classroomName => {
        return ({
          name: classroomName,
          id: classrooms.find(classroom => classroomName === classroom.name)?.id,
          students: obj[unitName][classroomName]
        })
      })
    })
  })
}

const ActivityItemContainer = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParam, setSearchParams] = useSearchParams()
  const { questions: { questionsDetails } } = useSelector((state: RootState) => state)
  const { activities: { overview, item, isFetching, participantStatus, pagination } } = useSelector((state: RootState) => state)
  const {
    reports: { activitiesRanking }
  } = useSelector((state: RootState) => state)

  const overviewData = overview?.participants?.map((item) => {
    return {
      classroom_name: item?.school_classroom?.name,
      participant_id: item?.id,
      participant_score: Number(item?.participant_score),
      performance: item?.performance
    }
  })

  // filter the participants who finished the activity
  const participantsFinished = activitiesRanking.items.filter((item) => item.participant_status !== 'pending')

  const classroomWithStudents = item.participants && normalizeClassroomsAndStudents(item.participants)
  const classrooms: Array<any> = classroomWithStudents ? classroomWithStudents.map(classroomUnit => classroomUnit.classrooms.map(classroom => classroom)).flat() : []
  const classroomsOrdered = classrooms.sort((a: any, b: any) => a.id - b.id)
  const classroomOptions: Array<DefaultOptionType> = classrooms ? classrooms.map(classroom => {
    return ({ label: classroom.name, value: classroom.id })
  }).flat() : []
  const [selectedClassroom, setSelectedClassroom] = useState<any>(classroomOptions[0]?.value)
  const currentClassroom = selectedClassroom || classroomOptions[0]

  const StatusFinished = participantStatus && participantStatus?.status?.finished
  const StatusPending = participantStatus && participantStatus?.status?.pending
  const StatusInProgress = participantStatus && participantStatus?.status?.in_progress
  const needFeedback = StatusFinished && (
    participantStatus?.status?.need_feedback?.length !== undefined
      ? participantStatus?.status?.need_feedback?.length * StatusFinished
      : 0
  )

  const classroomList = classrooms.map(classroom => {
    return classroom.id
  })

  const discursiveQuestionsIds = participantStatus && participantStatus?.status?.need_feedback?.questions

  const classroomId = currentClassroom && classroomOptions[0]?.value

  const modules = item.modules
  let questions: IQuestion[] | undefined = []
  questions = modules?.flatMap((moduleWrapper: any) => {
    return moduleWrapper.module?.content_module_items.map((item: IActivities) => item.content?.question)
  })

  const questionDetailsData: any = {
    id: params.id,
    classroomIds: String(classroomList),
  }

  const studentActivitiesData: any = {
    activityId: params.id,
    classroomId: String(classroomList)
  }

  const payloadRanking: any = {
    activityId: params?.id,
    per: 1000000,
    page: 1
  }

  useEffect(() => {
    scrollToTop()
    params?.id && dispatch(activityItemByIdFetchRequest(params.id))
    params?.id && dispatch(activityRankingReportFetchRequest(payloadRanking))
    return () => {
      setSelectedClassroom(null)
      dispatch(clearActivityItem())
      dispatch(clearQuestionDetails())
      dispatch(clearSelectedQuestions())
      dispatch(activityRankingReset())
      localStorage.removeItem('classroom')
    }
  }, [params?.id])

  useEffect(() => {
    classroomId && classroomList && dispatch(questionDetailsFetchRequest(questionDetailsData))
    classroomId && dispatch(activitiesOverviewFetchRequest(studentActivitiesData))
  }, [classroomId])

  const [choosedClassroom, setChoosedClassroom] = useState(undefined)

  const handleClassroomChoosed = (classroom: any) => {
    setChoosedClassroom(classroom)
  }

  const handleBackPage = () => {
    if (searchParam.get('info') === 'students_classroom' || searchParam.get('info') === 'students') {
      navigate(-1)
    } else {
      navigate('/activities', { state: { currentPage: pagination?.current } })
    }
  }

  const moreAccurate = questionsDetails?.items?.more_accurate
  const lessAccurate = questionsDetails?.items?.less_accurate

  let numberOfDiscursiveQuestion = 0
  if (questions) {
    for (let index = 0; index < questions.length; index++) {
      if (questions[index]?.question_type === 'discursive') {
        numberOfDiscursiveQuestion += 1
      }
    }
  }

  const renderInfoPage = (<>
    <InfoCardContainer>
      <div>
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <SuperSVG />
            <p><span>{StatusFinished}</span> alunos que finalizaram</p>
          </InfoCard>
        )
        }
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <UserWithPapersSVG />
            <p><span>{StatusInProgress}</span> alunos que estão fazendo</p>
          </InfoCard>
        )
        }
        {isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <StudingSVG />
            <p><span>{StatusPending}</span> alunos que não iniciaram</p>
          </InfoCard>

        )
        }
        {
          (numberOfDiscursiveQuestion >= 1) &&
          <InfoCard>
            <ThinkingSVG />
            <p><span>{String(needFeedback).padStart(2, '0')}</span> correções pendentes</p>
          </InfoCard>
        }
      </div>
      {isFetching ? (<Skeleton sx={{ mt: '16px' }} animation='wave' height={30} width={'300px'} />) : <div style={{ cursor: 'pointer' }} onClick={() => setSearchParams({ info: 'students' })}>
        <MoreSVG />
        <p>Ver lista completa de alunos</p>
      </div>}
    </InfoCardContainer>

    <SelectsContainer />

    <ActivityClassroomsView
      classrooms={classroomsOrdered}
      activityType={item?.activity_type}
      overview={overview?.participants}
      onClassroomChoosed={handleClassroomChoosed}
      isFetching={isFetching}
    />

    <ScoreStudentsView
      currentClassroom={currentClassroom}
      classrooms={classrooms}
      activityType={item?.activity_type}
      activityValue={item?.value}
      data={activitiesRanking.items.length > 0 ? participantsFinished : overviewData}
      isFetching={isFetching || activitiesRanking.isFetching}
    />

    <StudentsPerformanceView
      classroomId={currentClassroom?.value}
      participants={item?.participants}
      overview={overview?.participants}
      activityType={item?.activity_type}
      activityValue={item?.value}
      isFetching={isFetching}
    />

    <QuestionsPerformanceView
      moreAccurate={moreAccurate}
      lessAccurate={lessAccurate}
      isFetching={isFetching}
    />
  </>)

  return (
    <div>
      <Label sx={{
        display: 'flex'
      }}>
        <IconTitleSVG />
        {
          isFetching ? (<Skeleton animation='wave' sx={{ width: '200px' }} />) : (item.title)
        }
      </Label>
      {searchParam.get('info') !== 'students' &&
        searchParam.get('info') !== 'finished' && searchParam.get('info') !== 'students_classroom' && renderInfoPage}
      {searchParam.get('info') === 'students' && (
        <ActivityStudentsView
          questions={questions}
          overview={overview?.participants}
          participants={item.participants}
          activityType={item?.activity_type}
          activityValue={item?.value}
          discursiveQuestionsIds={discursiveQuestionsIds}
          isFetching={isFetching}
        />
      )}
      {searchParam.get('info') === 'students_classroom' && (
        <ActivityStudentsView
          questions={questions}
          overview={overview?.participants}
          participants={item.participants}
          choosedClassroom={choosedClassroom}
          activityType={item?.activity_type}
          activityValue={item?.value}
          discursiveQuestionsIds={discursiveQuestionsIds}
          isFetching={isFetching}
        />
      )}
      <Box display='flex' justifyContent='flex-end' mt={4}>
        <Button onClick={handleBackPage}>Voltar</Button>
      </Box>
    </div>
  )
}

export default ActivityItemContainer
