import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { JsonFormat } from '../../utils/types'
import { IQuestion } from '../../models/IQuestion'
import { IUnit } from '../../models/IUnit'
import { withPayloadType } from '../../utils/functions'

export type QuestionJson = JsonFormat<'questions', IQuestion>

export interface UnitsPayload {
  data: FormData
}

export interface ModuleFetchPayload {
  id: string | number
}

export interface UnitssSuccessPayload {
  data: object
}

export interface ChangePaginationPayload {
  total: number
  totalPages: number
  current: number
}

export interface FetchModuleSuccessPayload {
  currentItem: IQuestion
}
export const unitsFetchRequest = createAction('units/UNITS_FETCH_REQUEST')
export const unitsFetchSuccess = createAction('units/UNIT_FETCH_SUCCESS', withPayloadType<UnitssSuccessPayload>())
export const unitsFetchError = createAction('units/UNITS_FETCH_ERROR')

export const unitsWithClassroomsFetchRequest = createAction('units/UNITS_WITH_CLASSROOMS_FETCH_REQUEST')
export const unitsWithClassroomsFetchSuccess = createAction('units/UNITS_WITH_CLASSROOMS_FETCH_SUCCESS', withPayloadType<UnitssSuccessPayload>())
export const unitsWithClassroomsFetchError = createAction('units/UNITS_WITH_CLASSROOMS_FETCH_ERROR')

export const unitsWithClassroomsSelected = createAction('units/UNITS_WITH_CLASSROOMS_SELECTED')
export const unitsWithClassroomsSelectedClear = createAction('units/UNITS_WITH_CLASSROOMS_SELECTED_CLEAR')

interface StateType {
  items: [],
  messageError: string | null,
  selectedUnit: IUnit | null
  activities: object
  units: []
  unitsWithClassrooms: {
    items: [] | null,
    isFetching: boolean,
    isError: boolean,
    messageError: string | null,
    selectedUnitsWithClassrooms: IUnit[]
  }
}

const initialState: StateType = {
  items: [],
  messageError: null,
  selectedUnit: null,
  activities: {},
  units: [],
  unitsWithClassrooms: {
    items: null,
    isFetching: false,
    isError: false,
    messageError: null,
    selectedUnitsWithClassrooms: []
  }
}

const unitsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(unitsFetchRequest, (state: StateType): StateType => ({
      ...state
    }))
    .addCase(unitsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: action.payload,
      messageError: null
    }))
    .addCase(unitsFetchError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      activities: {
        ...state.units,
        messageError: action.payload
      }
    }))
    .addCase(unitsWithClassroomsFetchRequest, (state: StateType): StateType => ({
      ...state,
      unitsWithClassrooms: {
        ...state.unitsWithClassrooms,
        isError: false,
        messageError: null,
        isFetching: true
      }
    }))
    .addCase(unitsWithClassroomsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      unitsWithClassrooms: {
        ...state.unitsWithClassrooms,
        items: action.payload,
        isFetching: false
      }
    }))
    .addCase(unitsWithClassroomsFetchError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      unitsWithClassrooms: {
        ...state.unitsWithClassrooms,
        isError: true,
        messageError: action.payload,
        isFetching: false
      }
    }))
    .addCase(unitsWithClassroomsSelected, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      unitsWithClassrooms: {
        ...state.unitsWithClassrooms,
        selectedUnitsWithClassrooms: action.payload
      }
    }))
    .addCase(unitsWithClassroomsSelectedClear, (state: StateType): StateType => ({
      ...state,
      unitsWithClassrooms: initialState.unitsWithClassrooms
    }))
})

export default unitsReducer
