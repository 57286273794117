import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'

const FilterContainerStyled = styled('div')(({ theme }) => ({
  // maxWidth: 230,
  [theme.breakpoints.down('md')] : {
    maxWidth: '100%',
    width: '100%',
    '& .termsFilter': {
      width: 280
    }
  }
}))

const ButtonContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'end',
  gap: 8,
  marginTop: 24,
  '& button': {
    maxWidth: 227,
    width: '100%'
  },
  [theme.breakpoints.down(906)]: {
    flexFlow: 'row',
    marginBottom: 40,
  }
}))

const ButtonCardContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
  gap: 8,

  '& button': {
    width: 137
  },
  [theme.breakpoints.down(1025)]: {
    flexFlow: 'row'
  }
}))

const SearchContainerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: 26,
  marginBottom: 24,
  [theme.breakpoints.down(906)]: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}))

const FiltersContainer = ({ children }: { children: ReactNode }) => {
  return (
    <FilterContainerStyled>{children}</FilterContainerStyled>
  )
}

export const ButtonContainer = ({ children }: { children: ReactNode }) => {
  return (
    <ButtonContainerStyled>{children}</ButtonContainerStyled>
  )
}

export const ButtonCardContainer = ({ children }: { children: ReactNode }) => {
  return (
    <ButtonCardContainerStyled>{children}</ButtonCardContainerStyled>
  )
}

export const SearchContainer = ({ children }: { children: ReactNode }) => {
  return (
    <SearchContainerStyled>{children}</SearchContainerStyled>
  )
}

export default FiltersContainer
