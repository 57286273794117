// React
import React from 'react'

// Components
import {
  Box,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material'
import ReadMoreTooltip from '../../tooltip/ReadMoreTooltip'

// Utils
import { styled } from '@mui/material/styles'
import { IBookByUser } from '../../../models/IBooks'

// Icons
import BarCodeIcon from '../../../assets/components/Books/barcode-icon.svg'
import BarCodeLightIcon from '../../../assets/components/Books/barcode-light-icon.svg'
import BookIcon from '../../../assets/components/Books/book-icon.svg'
import BookLightIcon from '../../../assets/components/Books/book-light-icon.svg'
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import ChartLightIcon from '../../../assets/components/Books/chart-light-icon.svg'
import ProgressIcon from '../../../assets/components/Books/progress-icon.svg'
import ProgressLightIcon from '../../../assets/components/Books/progress-light-icon.svg'
import NoteBookIcon from '../../../assets/components/Books/notebook-icon.svg'
import NoteBookLightIcon from '../../../assets/components/Books/notebook-light-icon.svg'

const Card = styled(Box)<any>(({ theme, selected }) => ({
  background: selected ? theme.palette.primary.main : '#fff',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: '16px',
  cursor: 'pointer',
  padding: '24px 16px',
  display: 'flex',
  gap: '8px',
  transition: 'background 0.3s ease-in-out',

  '&:hover': {
    background: !selected && '#f7f7f7',
  },

  '& .cover': {
    borderRadius: '8px',
    height: '120px',
    flex: '0 0 80px',
    overflow: 'hidden',
    width: '80px !important',

    '&_image': {
      aspectRatio: '3/2',
      height: '100%',
      width: '100% !important'
    },

    '&_image-default': {
      alignItems: 'center',
      background: '#f1f1f1',
      color: '#c9c9c9',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      fontSize: '20px',
      fontWeight: 600,
      justifyContent: 'center',
      width: '100%',
    }
  },

  '& .book': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    '&_title': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& h3': {
        color: selected ? '#fff' : theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '120%'
      }
    },

    '&_datasheet': {
      '&-item': {
        alignItems: 'center',
        display: 'flex',
        gap: '4px',

        '& span': {
          color: selected ? '#fff' : theme.palette.text.primary,
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%'
        },

        '& .code': {
          color: selected ? '#fff' : theme.palette.primary.light,
          fontWeight: 700
        },

        '& .subject': {
          fontWeight: 700
        },

        '& .linear_progress': {
          width: '48px'
        },

        '& .end_date': {
          color: theme.palette.error.main
        }
      },

      '& .last_item': {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }
  },

  [theme.breakpoints.down('lg')]: {
    '& .book': {
      '&_datasheet': {
        '& .last_item': {
          justifyContent: 'flex-start'
        }
      }
    }
  }
}))

interface IBookStudentCardProps {
  book: IBookByUser
  selected: boolean
  handleSelectBook: (id: number) => void
}

const BookStudentCard: React.FC<IBookStudentCardProps> = ({
  book,
  selected,
  handleSelectBook
}) => {
  return (
    <Card
      selected={selected}
      onClick={() => handleSelectBook(book.id)}
    >
      <Box className='cover'>
        {book.photo_url
          ? (
            <img
              src={book.photo_url}
              alt='Capa do livro'
              className='cover_image'
            />
          )
          : (
            <Box className='cover_image-default'>
              <span>Livro</span>
              <span>sem</span>
              <span>capa</span>
            </Box>
          )}
      </Box>

      <Box className='book'>
        <Box className='book_title'>
          {selected ? <img src={BookLightIcon} /> : <img src={BookIcon} />}
          <Typography component='h3'>
            {book.name}
          </Typography>
        </Box>

        <Box className='book_datasheet'>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12} lg={6}>
              <Box className='book_datasheet-item'>
                {selected ? <img src={BarCodeLightIcon} /> : <img src={BarCodeIcon} />}
                <Typography
                  component='span'
                >
                  Cód:
                </Typography>

                <Typography
                  component='span'
                  className='value code'
                >
                  {book.id}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6} className='last_item'>
              <Box className='book_datasheet-item'>
                {selected ? <img src={NoteBookLightIcon} /> : <img src={NoteBookIcon} />}
                <Typography component='span'>Disc.:</Typography>
                {book.subjects?.length === 1 ? (
                  <Box
                    component='span'
                    className='value subject'
                  >
                    {book.subjects?.[0]?.name}
                  </Box>
                ) : (
                  <ReadMoreTooltip
                    className='value subject'
                    principalName={book.subjects?.[0]?.name}
                    allNames={book.subjects?.map(subject => subject.name).join(' / ')}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box className='book_datasheet-item'>
                {selected ? <img src={ChartLightIcon} /> : <img src={ChartIcon} />}
                <Typography
                  component='span'
                >
                  Desempenho:
                </Typography>
                <LinearProgress
                  className='linear_progress'
                  color={book.performance >= 50 ? 'secondary' : 'error'}
                  value={book.performance}
                  variant='determinate'
                />
                <Typography
                  component='span'
                  className='value'
                >
                  {book.performance.toFixed(2)}%
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={6} className='last_item'>
              <Box className='book_datasheet-item'>
                {selected ? <img src={ProgressLightIcon} /> : <img src={ProgressIcon} />}
                <Typography
                  component='span'
                >
                  Progresso:
                </Typography>
                <LinearProgress
                  className='linear_progress'
                  color={book.progress >= 50 ? 'secondary' : 'error'}
                  value={book.progress}
                  variant='determinate'
                />
                <Typography
                  component='span'
                  className='value'
                >
                  {book.progress.toFixed(2)}%
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}

export default BookStudentCard
