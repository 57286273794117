import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import StudentInfoItem from '../items/StudentInfoItem'

// Utils
import http from '../../../utils/http'
import { IStudentGeneralVision, IUnitClassroom } from '../../../models/IBooks'
import { useTheme } from '@mui/material/styles'

// Styles
import {
  ClassroomContainer,
  Container,
  EmptyMessageBox,
  Label,
  TableBody,
  TableHead
} from './styles/ClassroomReportCollapse.styles'

// Icons
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import PeopleIcon from '../../../assets/components/Books/people-icon.svg'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'
import NoClassroomImage from '../../../assets/components/Books/no-classroom-image.svg'

const EmptyMessage = () => {
  return (
    <EmptyMessageBox>
      <Box className='box'>
        <Box className='image'>
          <img src={NoClassroomImage} alt='ícone' />
        </Box>

        <Box className='content'>
          <Typography className='title'>
            Ainda não há alunos com este nível de desempenho.
          </Typography>

          <Typography className='info'>
            Continue acompanhando para atualizações.
          </Typography>
        </Box>
      </Box>
    </EmptyMessageBox>
  )
}

interface IClassroomReportCollapseProps {
  bookId?: number | string
  classroom: IUnitClassroom
  schoolCollapseIndex: number
  index: number
}

const ClassroomReportCollapse: React.FC<IClassroomReportCollapseProps> = ({
  bookId,
  classroom,
  schoolCollapseIndex,
  index
}) => {
  const [openClassroom, setOpenClassroom] = useState(false)
  const [students, setStudents] = useState({} as IStudentGeneralVision)
  const [isLoading, setIsLoading] = useState(false)
  const [openPerfTooltip, setOpenPerfTooltip] = useState(false)
  const [openProgTooltip, setOpenProgTooltip] = useState(false)
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const handleOpenPerfTooltip = () => {
    setOpenPerfTooltip(lgDown)
  }

  const handleClosePerfTooltip = () => {
    setOpenPerfTooltip(false)
  }

  const handleOpenProgTooltip = () => {
    setOpenProgTooltip(lgDown)
  }

  const handleCloseProgTooltip = () => {
    setOpenProgTooltip(false)
  }

  const loadStudents = async () => {
    setIsLoading(true)
    try {
      const response = await http.get(`/books/${bookId}/students_general_vision?school_classroom_id=${classroom.id}`)
      setStudents(response.data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const handleOpenClassroom = () => {
    setOpenClassroom(prevState => !prevState)
    !openClassroom && loadStudents()
  }

  useEffect(() => {
    if (schoolCollapseIndex === 0 && index === 0) {
      setOpenClassroom(true)
      loadStudents()
    }

    return () => {
      setStudents({} as IStudentGeneralVision)
    }
  }, [])

  return (
    <Container>
      <ClassroomContainer onClick={handleOpenClassroom}>
        <Grid container columnSpacing={1}>
          <Grid item xs={4} lg={2} className='grid'>
            <Typography className='classroom_name'>
              {classroom.name}
            </Typography>
          </Grid>

          <Grid item xs={3} lg={4} className='grid'>
            <Tooltip
              title='Progresso'
              open={openProgTooltip}
              onOpen={handleOpenProgTooltip}
              onClose={handleCloseProgTooltip}
              arrow
            >
              <Box className='progress'>
                <img src={ProgressIcon} />
                <Typography>Progresso:</Typography>
                <LinearProgress
                  className='linear_progress'
                  color={classroom.average_progress >= 50 ? 'secondary' : 'error'}
                  value={classroom.average_progress ?? 0}
                  variant='determinate'
                />
                <Typography>{classroom.average_progress ?? 0}%</Typography>
              </Box>
            </Tooltip>
          </Grid>

          <Grid item xs={3} lg={4} className='grid'>
            <Tooltip
              title='Performance'
              open={openPerfTooltip}
              onOpen={handleOpenPerfTooltip}
              onClose={handleClosePerfTooltip}
              arrow
            >
              <Box className='performance'>
                <img src={ChartIcon} />
                <Typography>Desempenho:</Typography>
                <LinearProgress
                  className='linear_progress'
                  color={classroom.average_performance >= 50 ? 'secondary' : 'error'}
                  value={classroom.average_performance ?? 0}
                  variant='determinate'
                />
                <Typography>{classroom.average_performance ?? 0}%</Typography>
              </Box>
            </Tooltip>
          </Grid>

          <Grid item xs={2} lg={2} className='grid last_item'>
            <Box className='total_students'>
              <img src={PeopleIcon} />
              <Box component='span'>Acessos:</Box>
              <Box component='span'>{classroom.students_with_performance ?? 0}</Box> /
              <Box component='span'>{classroom.total_students ?? 0}</Box>
            </Box>
          </Grid>
        </Grid>

        <IconButton>
          <KeyboardArrowDownRoundedIcon
            sx={{
              transform: openClassroom ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        </IconButton>
      </ClassroomContainer>

      <Collapse in={openClassroom}>
        <Box sx={{ marginTop: '32px' }}>
          {isLoading && (
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress variant='indeterminate' color='inherit' />
            </Box>
          )}
          {!isLoading && (
            <>
              <Box>
                <Label>
                  <Typography className='label'>
                    Alto desempenho
                  </Typography>

                  <Typography className='number_of_students'>
                    ({students?.high_performance?.length})
                  </Typography>

                  <Typography className='performance_range high'>
                    de 75% a 100%
                  </Typography>
                </Label>

                {students?.high_performance?.length > 0 && (
                  <Box>
                    <TableHead container columnSpacing={1}>
                      <Grid item xs={6} className='thead_cell'>Aluno</Grid>
                      <Grid item xs={2} className='thead_cell'>Engajamento</Grid>
                      <Grid item xs={2} className='thead_cell'>Desempenho</Grid>
                      <Grid item xs={2} className='thead_cell'>Tempo</Grid>
                    </TableHead>


                    <TableBody>
                      {students?.high_performance?.sort((a, b) => b.performance - a.performance)?.map((item) => {
                        return (
                          <StudentInfoItem
                            key={item.user.id}
                            student={item}
                          />
                        )
                      })}
                    </TableBody>
                  </Box>
                )}

                {students?.high_performance?.length === 0 && (
                  <EmptyMessage />
                )}
              </Box>

              <Box sx={{ marginTop: '32px' }}>
                <Label>
                  <Typography className='label'>
                    Médio desempenho
                  </Typography>

                  <Typography className='number_of_students'>
                    ({students?.medium_performance?.length ?? 0})
                  </Typography>

                  <Typography className='performance_range medium'>
                    de 25% a 75%
                  </Typography>
                </Label>

                {students?.medium_performance?.length > 0 && (
                  <Box>
                    <TableHead container columnSpacing={1}>
                      <Grid item xs={6} className='thead_cell'>Aluno</Grid>
                      <Grid item xs={2} className='thead_cell'>Engajamento</Grid>
                      <Grid item xs={2} className='thead_cell'>Desempenho</Grid>
                      <Grid item xs={2} className='thead_cell'>Tempo</Grid>
                    </TableHead>


                    <TableBody>
                      {students?.medium_performance?.sort((a, b) => b.performance - a.performance)?.map((item) => {
                        return (
                          <StudentInfoItem
                            key={item.user.id}
                            student={item}
                          />
                        )
                      })}
                    </TableBody>
                  </Box>
                )}

                {students?.medium_performance?.length === 0 && (
                  <EmptyMessage />
                )}
              </Box>

              <Box sx={{ marginTop: '32px' }}>
                <Label>
                  <Typography className='label'>
                    Baixo desempenho
                  </Typography>

                  <Typography className='number_of_students'>
                    ({students?.low_performance?.length})
                  </Typography>

                  <Typography className='performance_range low'>
                    de 0% a 25%
                  </Typography>
                </Label>

                {students?.low_performance?.length > 0 && (
                  <Box>
                    <TableHead container columnSpacing={1}>
                      <Grid item xs={6} className='thead_cell'>Aluno</Grid>
                      <Grid item xs={2} className='thead_cell'>Engajamento</Grid>
                      <Grid item xs={2} className='thead_cell'>Desempenho</Grid>
                      <Grid item xs={2} className='thead_cell'>Tempo</Grid>
                    </TableHead>


                    <TableBody>
                      {students?.low_performance?.sort((a, b) => b.performance - a.performance)?.map((item) => {
                        return (
                          <StudentInfoItem
                            key={item.user.id}
                            student={item}
                          />
                        )
                      })}
                    </TableBody>
                  </Box>
                )}

                {students?.low_performance?.length === 0 && (
                  <EmptyMessage />
                )}
              </Box>
            </>
          )}
        </Box>
      </Collapse>
    </Container>
  )
}

export default ClassroomReportCollapse
