/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { textTransform } from '@mui/system'
interface ButtonProps {
  bgColor?: string
  bgActiveColor?: string
  bgHoverColor?: string
}
export const ConfirmButton = styled('button')(({ theme }) => ({
  background: theme.palette.secondary.main,
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  color: 'white',
  fontFamily: theme.typography.fontFamily,
  padding: '14px 39px',
  ':hover': {
    background: theme.palette.secondary.main
  },
}))
export const ConfirmMoveButton = styled('button')(({ theme }) => ({
  background: theme.palette.primary.main,
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  color: 'white',
  fontFamily: theme.typography.fontFamily,
  padding: '14px 39px',
  ':hover': {
    background: '#2B1961',
  },
  ':active': {
    background: theme.palette.primary.light
  }
}))
export const CancelMoveStudentButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#B7B2C8',
  fontFamily: theme.typography.fontFamily,
  padding: '14px 39px',
  ':hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  ':active': {
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light
  }
}))
export const CancelButton = styled('button')(({ theme }) => ({
  border: 'none',
  borderRadius: '8px',
  background: theme.palette.error.main,
  cursor: 'pointer',
  color: 'white',
  fontFamily: theme.typography.fontFamily,
  padding: '14px 39px',
  ':hover': {
    background: '#E97777',
  },
}))
export const ActionButton = styled('button')(({ theme }) => ({
  padding: '2px',
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  '&:hover': {
    '> svg': {
      '> path': {
        stroke: theme.palette.primary.light,
      },
    },
  },
}))

export const AddButton = styled('button')<ButtonProps>(({ theme, bgColor, bgActiveColor, bgHoverColor }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'end',
  backgroundColor: bgColor || theme.palette.primary.main,
  borderRadius: '8px',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '16px',
  marginBottom: '24px',
  marginRight: '16px',
  outline: 'none',
  padding: '15px 40px',
  width: 'fit-content',
  svg: {
    marginRight: '8px',
  },
  ':hover': {
    backgroundColor: bgHoverColor || '#2B1961',
  },
  ':active': {
    backgroundColor: bgActiveColor || theme.palette.primary.light,
  },
}))

export const CircleButton = styled('button')(({ theme }) => ({
  borderRadius: '50%',
  padding: '5px 8px',
  background: 'rgba(217, 217, 217, 0.4) !important',
  border: 'none',
  '> span > svg > path' : {
    stroke: '#666 !important',
  },
  '&:hover': {
   background:  ` ${theme.palette.secondary.main}!important`,
   cursor: 'pointer'
  }
}))

export const ButtonOnlyIcon = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer'
}))

export const SaveButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  color: '#fff',
  fontFamily: theme.typography.fontFamily,
  padding: '14.5px 39px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%',
  textTransform: 'none',
  '&:hover': {
    background: '#2B1961'
  },
  '&:active': {
    background: theme.palette.primary.light
  },
  '&:disabled': {
    background: theme.palette.text.secondary
  }
}))

export const BackButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: '1px solid #B7B2C8',
  padding: '14.5px 39px',
  color: '#B7B2C8',
  fontSize: '16px',
  fontFamily: theme.typography.fontFamily,
  borderRadius: '8px',
  cursor: 'pointer',
  '&:active': {
    color: '#fff',
    background: theme.palette.primary.light
  }
}))

export const MoveStudentsButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  borderRadius: '8px',
  color: '#fff',
  display: 'flex',
  fontSize: '16px',
  fonWeight: 500,
  gap: '8px',
  lineHeight: '120%',
  padding: '15px 24px',
  '&:hover': {
    backgroundColor: '#2B1961',
    cursor: 'pointer',
  },
  '&:active': {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  '&:disabled': {
    backgroundColor: theme.palette.text.secondary
  }
}))
export const DeleteStudentsButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.error.main,
  border: 'none',
  borderRadius: '8px',
  color: '#fff',
  display: 'flex',
  fontSize: '16px',
  fonWeight: 500,
  gap: '8px',
  lineHeight: '120%',
  padding: '15px 24px',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
    cursor: 'pointer',
  },
  '&:active': {
    backgroundColor: theme.palette.primary.light,
    cursor: 'pointer',
  },
  '&:disabled': {
    backgroundColor: theme.palette.text.secondary
  }
}))
export const AddSubjectWithClassroom = styled('button')(({ theme }) => ({
  alignItems: 'center',
  color:theme.palette.primary.light,
  display: 'flex',
  fontSize: '14px',
  fontWeight: 600,
  gap: '4px',
  padding: '26px 0px 19px 0px',
  '&:hover': {
    // backgroundColor: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '&:active': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  '&:disabled': {
    backgroundColor: theme.palette.text.secondary
  }
}))
