import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../store/hooks'

// Components
import TeacherForm from '../components/Management/form/TeacherForm'
import { convertDate, cpfFormatter } from '../utils/functions'
import { LinearProgress } from '@mui/material'
import { getTeacherByIdRequest } from '../store/teachers/actions'

function TeacherFormEditContainer() {
  const [formValues, setFormValues] = useState({} as any)
  const { state } = useLocation()
  const { currentItem } = useAppSelector((state) => state.teachers)
  const { selectedUnit } = useAppSelector((state) => state.persistable)
  const dispatch = useDispatch()
  const teacherId = state?.teacherId
  const teacher = currentItem?.item?.user

  const initialValues = {
    id: teacher?.id,
    name: teacher?.name,
    birthday: teacher?.birthday && convertDate(teacher?.birthday),
    email: teacher?.email,
    phone: teacher?.phone,
    genre: { name: teacher?.genre === 'male' ? 'Masculino' : 'Feminino', value: teacher?.genre },
    taxpayer_number: teacher?.taxpayer_number && cpfFormatter(teacher?.taxpayer_number),
    street: teacher?.additional_data?.address?.street,
    zipcode: teacher?.additional_data?.address?.zipcode,
    neighborhood: teacher?.additional_data?.address?.neighborhood,
    complement: teacher?.additional_data?.address?.complement,
    number: teacher?.additional_data?.address?.number,
    classrooms: teacher?.classrooms?.flat()
  }

  useEffect(() => {
    if (teacherId) {
      dispatch(getTeacherByIdRequest({ teacherId, schoolUnitId: selectedUnit?.id }))
    }
  }, [])
  useEffect(() => {
    setFormValues({
      ...initialValues
    })
  }, [teacher])

  return (
    <>
      {
        currentItem?.isFetching ? (<LinearProgress color='secondary' />) :
        (
          <TeacherForm
          initialValues={formValues}
        />
        )
      }
    </>
  )
}

export default TeacherFormEditContainer
