import React from 'react'
import TeacherFormEditContainer from '../containers/TeacherFormEditContainer'

const ManageTeacherEditPage = () => {
  return (
    <div>
      <TeacherFormEditContainer />
    </div>
  )
}

export default ManageTeacherEditPage
