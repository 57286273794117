import { all, call, put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as actions from './actions'
import * as services from './services'

export function* getSchoolsAppsIdsSaga() {
  try {
    const response: AxiosResponse = yield call(services.getSchoolsAppIdsService)
    if (response.status === 200) {
      yield put(actions.getSchoolAppIdSuccess(response.data))
    }
  } catch (error) {
    yield put(actions.getSchoolAppIdRejected())
  }
}

export function* watchGetSchoolsAppsIdsSaga() {
  yield takeLatest(actions.getSchoolAppIdRequest.type, getSchoolsAppsIdsSaga)
}

export default function* getSchoolsAppsIdsSagas() {
  yield all([
    watchGetSchoolsAppsIdsSaga()
  ])
}
