import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import CheckIcon from '../../assets/components/HomePage/checktwo-icon.svg'
import NoDataImage from '../../assets/components/HomePage/graphic-image.svg'

const Container = styled(Box)({
  background: '#FFFFFF',
  borderRadius: '16px',
  padding: '32px 16px 21px',
})

const Title = styled('div')({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  marginBottom: '40px',

  '& .title': {
    color: '#666666',
    fontSize: 18,
    fontWeight: 600
  }
})

const Graphic = () => {
  return (
    <Container>
      <Title>
        <img src={CheckIcon} alt='ícone de progresso' />
        <Typography className='title'>Progresso dos Alunos</Typography>
      </Title>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ maxWidth: '204px', width: '100%', height: '144px' }}>
          <img src={NoDataImage} alt='' width='100%' />
        </Box>
        <Box sx={{ maxWidth: '272px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography
            component='h3'
            sx={{
              color: '#666666',
              fontSize: 18,
              fontWeight: 700,
              lineHeight: '120%',
              margin: '4px 0 8px',
              maxWidth: '204px',
              textAlign: 'center',
            }}
          >
            Em breve, dados relevantes chegando!
          </Typography>
          <Typography
            sx={{
              color: '#9f9f9f',
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '120%',
              textAlign: 'center',
            }}
          >
            Nossos servidores estão trabalhando para gerar os dados que precisa
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default Graphic
