import React from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import FileIcon from '../../../assets/components/Questions/file-icon.svg'

const Container = styled('div')({
  maxWidth: '528px',
  width: '100%',
  border: '1px solid #B7B2C8',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between'
})

const Label = styled('div')(({theme}) => ({
  fontSize: '14px',
  margin: 0,
  '& span': {
    color: theme.palette.primary.main,
    fontWeight: 700
  }
}))

interface ContentSupportCardProps {
  name?: string
  description?: string 
  sizeFile?: number
  author?: string
  onClick: () => void
}

const ContentSupportCard: React.FC<ContentSupportCardProps> = (props) => {
  const { name, description, sizeFile, author, onClick } = props
  return (
    <Grid item lg={6}>
      <Container>
        <Box sx={{ display: 'flex', gap: '16px', margin: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '56px', height: '56px' }}>
              <img src={FileIcon} alt='ícone de arquivo' />
            </Box>
            {sizeFile && (
              <span style={{ fontSize: '10px', fontWeight: '700', marginTop: '8px' }}>
                {(sizeFile / 10**6).toFixed(2)} MB
              </span>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Label>
              <span>Nome:</span> {name}
            </Label>
            <Label>
              <span>Descrição:</span> {description}
            </Label>
            <Label>
              <span>Criado por:</span> {author}
            </Label>
          </Box>
        </Box>
        <IconButton
          color='error'
          sx={{ width: '18px', height: '18px', margin: '8px' }}
          onClick={onClick}
        >
          <CancelIcon />
        </IconButton>
      </Container>
    </Grid>
  )
}

export default ContentSupportCard