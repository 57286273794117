import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  height: '300px',
  width: '100%',

  '& .recharts-legend-wrapper': {
    top: '-10px !important'
  },

  '& .recharts-tooltip-wrapper': {
    zIndex: 10
  }
}))

export const TooltipContainer = styled(Box)(() => ({
  background: '#ffffff',
  border: '1px solid #d9d9d9',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '20px 8px'
}))

export const TooltipContent = styled(Box)(({ theme }) => ({
  '&:not(&:first-of-type)': {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    paddingTop: '20px'
  },

  '.tooltip_title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '120%',
    marginBottom: '8px'
  },

  '.tooltip_content': {
    '&:not(&:first-of-type)': {
      marginTop: '4px'
    },

    '&-label': {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    },

    '&-value': {
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      marginLeft: '4px',
      padding: '4px 6px',
    },

    '& .modules': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '32px',
      color: theme.palette.secondary.main,
    },

    '& .performance': {
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: '32px',
      color: theme.palette.primary.light
    }
  }
}))

export const LegendContainer = styled('ul')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  listStyleType: 'none',
  marginBottom: '16px'
}))

export const LegendItem = styled('li')<any>(({ background, isHiden }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  gap: '4px',
  '& .circle': {
    background: isHiden ? '#ccc' : background,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '12px',
    width: '12px',
  },

  '& .label': {
    fontSize: '12px',
    fontWeight: 500,
    textDecoration: isHiden ? 'line-through' : 'none',
  }
}))
