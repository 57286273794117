import React from 'react'
import { Box } from '@mui/material'
import BooksFormCloneContainer from '../containers/BooksFormCloneContainer'

const BooksClonePage = () => {
  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      <BooksFormCloneContainer />
    </Box>
  )
}

export default BooksClonePage
