/* eslint-disable no-constant-condition */
import React, { useEffect, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Box, Card, Skeleton, Grid, Pagination } from '@mui/material'
import { ReactComponent as BookSVG } from '../assets/components/Books/book-icon.svg'
import { ReactComponent as CreatedSVG } from '../assets/components/Books/created-icon.svg'
import { ReactComponent as PublishedSVG } from '../assets/components/Books/published-icon.svg'
import { ReactComponent as SketchSVG } from '../assets/components/Books/sketch-icon.svg'
import { ReactComponent as AuthorSVG } from '../assets/components/Books/authors-icon.svg'
import { ReactComponent as ExportSVG } from '../assets/components/Books/export-icon.svg'
import { RootState } from '../store/configureStore'
import Label from '../components/label/Label'
import { styled } from '@mui/system'
import BooksCreatorsRankingView from '../components/view/BooksCreatorsRankingView'
import BooksCoauthorsView from '../components/view/BookCoauthorsView'
import BooksStudentsPerformanceView from '../components/view/BookStudentsPerformanceView'
import BooksCatalogView from '../components/view/BooksCatalogView'
import BooksMoreUsedView from '../components/view/BooksMoreUsedView'
import BooksLeastUsedView from '../components/view/BooksLeastUsedView'
import Button from '../components/button/Button'
import {subjectsFetchRequest } from '../store/education'
import { getBooksGeneralVisionRequest, 
  getBooksRankingRequest,
  getBooksAuthorsRankingRequest, 
  getBooksCoauthorsRankingRequest,
  getBooksStudentsPerformanceRequest,
  fetchBooksRequest,
  fetchTeachersAuthorsRequest } from '../store/books/actions'

const SelectsContainer = styled('div')({
  display: 'flex',
  gap: 48,
  marginTop: 32,
  '& .MuiBox-root': {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 16
  },
  '& > div > .MuiFormControl-root > .MuiOutlinedInput-root': {
    backgroundColor: '#FFF',
    maxWidth: '381px'
  },
  '& .MuiTypography-body2': {
    color: '#666'
  }
})

const InfoCardContainer = styled('div')(({ theme }) => ({
  border: `1px dashed ${theme.palette.text.secondary}`,
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  marginTop: 32,
  gap: 8,
  '& > div': {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    gap: 32
  }
}))

const InfoCard = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  height: 201,
  paddingTop: 28,
  textAlign: 'center',
  width: 247,
  '& p': {
    fontSize: 14,
    '& span': {
      color: theme.palette.primary.light,
      fontWeight: 800
    }
  }
}))

const HeaderReports = styled('div')(() => ({
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: '32px',
  '.createReports': {
    justifySelf: 'end',
    'button' : {
       backgroundColor: '#BC9BFA',
       'svg':{
        marginRight: '8px'
       }
    }
  },
}))

// interface SubjectsProps {
//   items: []
// }

const BookReportsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParam, setSearchParams] = useSearchParams()

  const { 
    education: { subjects },
    books: { 
      items, 
      isFetching, 
      pagination, 
      reports : { general, ranking, authors, coAuthors, studentsPerformance},
      teachers },
    auth: { user : { units } } // eslint-disable-line
  } = useSelector((state: RootState) => state)

  const unitOptions: Array<{ label: string, value: number }> = units ? units.map(unit => ({ label: unit.name, value: unit.id })) : []

  const options = [
    ...unitOptions
  ]

  const handleBackPage = () => {
    navigate('/books')
  }

  const bookAuthors = teachers && teachers.items
  const authorsOptions: Array<{label: string, value: number }> = bookAuthors ? bookAuthors.map((author: any) => ({ label: author.name, value: author.id })) : []
  const schoolIds = units && units[0].id

  const [perPage, setPerPage] = useState(10)// eslint-disable-line
  const [page, setPage] = useState(1)// eslint-disable-line
  const [selectedUnit, setSelectedUnit] = useState<string>('')
  const [selectedSubject, setSelectedSubject] = useState<string>('')
  const [selectedAuthor, setSelectedAuthor] = useState<string>('')

  useEffect(() => {
    dispatch(subjectsFetchRequest())
    dispatch(getBooksGeneralVisionRequest({school_unit_ids : schoolIds}))
    dispatch(getBooksRankingRequest())
    dispatch(getBooksAuthorsRankingRequest())
    dispatch(getBooksCoauthorsRankingRequest())
    dispatch(getBooksStudentsPerformanceRequest({school_unit_ids : schoolIds}))
    dispatch(fetchBooksRequest({ per: perPage, page: 1, desc: true}))
    dispatch(fetchTeachersAuthorsRequest({school_unit_ids : schoolIds}))
  }, [])


  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(fetchBooksRequest({ 
      belongs_to_unit_id: selectedUnit,
      education_subject_ids: selectedSubject,
      book_author_ids: selectedAuthor,
      per: perPage,
      page: page,
      desc: true,
     }))
  }


  const handleFilterUnits: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    setSelectedUnit(value)

    dispatch(fetchBooksRequest({ 
      belongs_to_unit_id: value,
      education_subject_ids: selectedSubject,
      book_author_ids: selectedAuthor,
      per: perPage,
      page: page,
      desc: true,
     }))
  }

  const handleFilterSubjects: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value
    setSelectedSubject(value)

    dispatch(fetchBooksRequest({
      belongs_to_unit_id: selectedUnit,
      education_subject_ids: value,
      book_author_ids: selectedAuthor,
      per: perPage,
      page: page,
      desc: true,
    }))
  }

  const handleFilterAuthors: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value
    setSelectedAuthor(value)

    dispatch(fetchBooksRequest({
      belongs_to_unit_id: selectedUnit,
      education_subject_ids: selectedSubject,
      book_author_ids: value,
      per: perPage,
      page: page,
      desc: true,
    }))
  }

  const renderInfoPage = (<>
    <InfoCardContainer>
      <div>
        {general?.isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <CreatedSVG />
            <p><span>{general && general?.created_books}</span> Livros criados</p>
          </InfoCard>
        )
        }
        {general?.isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <PublishedSVG />
            <p><span>{general?.published_books}</span> Livros publicados</p>
          </InfoCard>
        )
        }
        {general?.isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <SketchSVG />
            <p><span>{general?.drafted_books}</span> Rascunho(s)</p>
          </InfoCard>

        )
        }
        {general?.isFetching ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center'
            }}
          >
            <Skeleton variant='rectangular' animation='wave' sx={{ width: '247px', height: '201px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <InfoCard>
            <AuthorSVG />
            <p><span>{general?.authors}</span> Autores</p>
          </InfoCard>
        )
        }
      </div>
      {isFetching ? (<Skeleton sx={{ mt: '16px' }} animation='wave' height={30} width={'300px'} />) : <div style={{ cursor: 'pointer' }} onClick={() => setSearchParams({ info: 'students' })}>
      </div>}
    </InfoCardContainer>

    <SelectsContainer />

    
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <BooksMoreUsedView
            mostAccessed={ranking?.most_accessed}
            subjectsOptions={subjects.items && subjects?.items}
            unitOptions={options}
          />
      </Grid>
      <Grid item xs={12} md={6}>
        <BooksLeastUsedView
            lessAccessed={ranking?.less_accessed}
            subjectsOptions={subjects.items && subjects?.items}
            unitOptions={options}
          />
      </Grid>
   </Grid>
    <BooksCreatorsRankingView
      authors={authors}
    />

    <BooksCoauthorsView
      coAuthors={coAuthors}
    />

    <BooksStudentsPerformanceView
      studentsPerformance={studentsPerformance}
    />

    <BooksCatalogView
      books={items}
      isFetching={isFetching}
      unitOptions={options}
      subjectsOptions={subjects.items && subjects?.items}
      authorsOptions={authorsOptions}
      handleFilterUnits={handleFilterUnits}
      handleFilterSubjects={handleFilterSubjects}
      handleFilterAuthors={handleFilterAuthors}
    />
  </>)

  return (
    <div>
      <HeaderReports>
        <Label sx={{
          display: 'flex'
        }}>
          <BookSVG /> Visão geral livros
        </Label>
        <div className='createReports'>
          <Button variant='contained'>
            <ExportSVG /> Exportar relatório completo
          </Button>
        </div>
      </HeaderReports>
      {searchParam.get('info') !== 'students' &&
      searchParam.get('info') !== 'finished' && searchParam.get('info') !== 'students_classroom' && renderInfoPage}
       <Box sx={{ marginTop: '32px' }}>
          <Pagination
            count={pagination.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Button
          type='button'
          onClick={handleBackPage}
        >
          Voltar
        </Button>
      </Box>
    </div>
  )
}

export default BookReportsContainer
