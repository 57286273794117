import React, { useState, memo } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import './styles.scss'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 'none',
    color: theme.palette.text.primary,
    fontWeight: 500,
    padding: '0 16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  [`&.MuiTableRow-root`]: {
    background: '#F9F9F9'
  },
  '& th': {
    fontWeight: 600,
    '&:first-of-type': {
      borderRadius: '16px 0 0 16px'
    }
  },
  '& td': {
    color: '#9F9F9F',
    '& img': {
      marginRight: 10,
      verticalAlign: 'text-top'
    },
    '&:first-of-type': {
      borderRadius: '16px 0 0 16px',
      fontWeight: 600,
    },
    '&:last-of-type': {
      borderRadius: '0 16px 16px 0'
    }
  },
  '& .MuiTableCell-root': {
    border: 'none'
  }
}))

export type Columns = {
  key: string,
  name: string | JSX.Element,
  width?: string,
  render: any,
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
}

type TableProps = {
  columns: Columns[],
  data?: any[],
  withoutHead?: boolean,
  isFetching?: boolean,
  pagination?: { current: number, total: number, totalOfPages: number },
  fetchItems?: (param: any) => void,
  color?: any,
  stickHeader?: boolean,
  maxHeight?: string
}

function CustomTable({ columns, data, withoutHead, isFetching, pagination, color, fetchItems, stickHeader, maxHeight }: TableProps) {
  const [page, setPage] = useState(1)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    typeof fetchItems === 'function' && fetchItems({ page: value })
  }

  return (
    <TableContainer className='table' component={Paper} sx={{ background: 'none', maxHeight: maxHeight, paddingRight: '5px' }}>
      <Table
        stickyHeader={stickHeader}
        sx={{ minWidth: 700, borderCollapse: 'separate',  borderSpacing: '0 16px' }}
        aria-label='customized table'
      >
        {!withoutHead &&
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                key={column.key}
                align={column.align || 'left'}
              >
                {column.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>}
        <TableBody>
          {isFetching ? (
            <StyledTableRow>
              <StyledTableCell align='left'>
                <CircularProgress thickness={2} />
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            data &&
            data?.map((row, index) => {
              return (
                <StyledTableRow key={`${row?.id}-${index}`}>
                  {columns.map(column => {
                    const showIndex = column?.render && column?.render(row)
                    return (
                      <StyledTableCell
                        className={color === 'red' ? 'red' : ''}
                        key={`${column?.key}[${row?.id}-${index}]`}
                        align={column.align || 'left'}
                        sx={{ width: column.width }}
                      >
                        {showIndex}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })
          )}
        </TableBody>
      </Table>
      {pagination &&
        pagination.totalOfPages > 1 && (
          <Pagination
            count={pagination.totalOfPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChange}
          />
        )}
    </TableContainer>
  )
}

export default memo(CustomTable)
