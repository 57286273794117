import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { styled } from '@mui/system'

const BarStyled = styled(Bar)({
  backgroundColor: '#F9F9F9',
  borderRadius: 16,
  height: '250px !important',
  padding: 16
})

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

interface ICounterArray {
  user_id: number
  school_classroom_id: number
  school_classroom_name: string
  participant_score: number
}

const backgrounds = [
  '#F69E9E',
  '#F8BEBE',
  '#F9D2D2',
  '#E6D9FF',
  '#D5BEFF',
  '#BC9BFA',
  '#A48DEA',
  '#6248B2',
  '#886FD6',
  '#BC9BFA'
]

export function ScoresChart({ scores, activityType, activityValue }: any) {
  const step = activityValue && Number(activityValue) / 10

  const counter = (arr : ICounterArray[], min : number, max : number) => {
    let count = 0
    for(let i=0; i<arr.length;i++) {
      if (activityType === 'exam' && min === 0 && max === step && arr[i].participant_score >= min && arr[i].participant_score <= max) {
        count++
      } else if (activityType === 'commom' && min === 0 && max === 10 && arr[i].participant_score >= min && arr[i].participant_score <= max) {
        count++
      } else if(arr[i].participant_score > min && arr[i].participant_score <= max){
        count++
      }
    }
    return count
  }

  const xAxisToExam = activityValue && activityType === 'exam' ? Array(Number(10)).fill(0).map((_, index: number) => {
    if (index === 0) {
      return `${(index * step).toFixed(1)} a ${((index + 1)*step).toFixed(1)}`
    }
    return `${(index * step + 0.1).toFixed(1)} a ${((index + 1)*step).toFixed(1)}`
  }) : []

  const xAxisToCommom = Array(10).fill(0).map((_, index: number) => {
    if (index < 1) {
      return `${index * 10} a ${index * 10 + 10}`
    }
    return `${index * 10 + 1} a ${index * 10 + 10}`
  })

  const labels = activityType === 'exam'
  ? xAxisToExam
  : xAxisToCommom

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: scores && scores.length > 0 && Array.isArray(scores[0])
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          position: 'right',
          text: activityType === 'exam' ? 'Notas' : 'Performance (%)'
        }
      },
      y: {
        title: {
          display: true,
          position: 'right',
          text: 'Nº de alunos'
        },
        ticks: {
          stepSize: 1
      }
      }
    }
  }

  const data = {
    labels,
    datasets: scores && scores.length > 0 && Array.isArray(scores[0])
    ? scores.map((score: ICounterArray[], index: number) => {
      return {
        label: score[0]?.school_classroom_name,
        data: activityType === 'exam'
          ? labels.map((_, i) => (score[0] && counter(score, i*step, (i+1)*step)))
          : labels.map((_, i) => ( score[0] && counter(score, i*10, (i*10)+10))),
        backgroundColor: backgrounds[backgrounds.length - index - 1],
        borderRadius: 6
      }
    })
    : [
      {
        label: 'Alunos',
        data: activityType === 'exam'
          ? labels.map((_, index) => ( scores && counter(scores, index*step, (index+1)*step)))
          : labels.map((_, index) => ( scores && counter(scores, (index*10), (index*10)+10))),
        backgroundColor: backgrounds,
        barThickness: 55,
        borderRadius: 8
      }
    ]
  }

  return <BarStyled options={options} data={data} />
}
