import React from 'react'

// Assets
import { ReactComponent as CheckIcon } from '../assets/components/HomePage/check-icon.svg'

// Components
import StatusCard from '../components/Home/StatusCard'
// import Chat from '../components/Home/Chat'
import ClassroomsList from '../components/Home/ClassroomsList'
import { Box, Typography } from '@mui/material'
import Carousel from '../components/Home/Carousel'
import Graphic from '../components/Home/Graphic'
import CalendarWithEvents from '../components/Calendar/CalendarWithEvents'

// Styles
import { styled } from '@mui/material/styles'

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateAreas: '"col1 col2"',
  gridTemplateColumns: 'minmax(50%, 1213px) minmax(300px, 500px)',
  columnGap: '50px',
  height: '100%',
  // padding: '24px 50px',

  '& .first_column': {
    gridArea: 'col1',

    '& .label': {
      alignItems: 'center',
      display: 'flex',
      gap: '16px',
      marginBottom: '16px',

      '& h3': {
        color: theme.palette.text.primary,
        fontSize: 18,
        fontWeight: 600
      }
    }
  },

  '& .last_column': {
    gridArea: 'col2'
  },

  // [theme.breakpoints.down('lg')]: {
  //   padding: '24px 16px'
  // }
}))

const HomeContainer = () => {
  return (
    <>
      <Container>
        <Box className='first_column'>
          <Box className='label'>
            <CheckIcon />
            <Typography component='h3'>
              Pedagógico
            </Typography>
          </Box>

          <Box>
            <Carousel />
          </Box>

          <Box sx={{ marginTop: '32px' }}>
            <Graphic />
          </Box>

          <Box>
            <ClassroomsList />
          </Box>

          <Box>
            <StatusCard />
          </Box>
        </Box>

        <Box className='last_column'>
          <CalendarWithEvents />
        </Box>
      </Container>
    </>
  )
}

export default HomeContainer
