/**
 * TODO: Decouple this component:
 * This file is a React Router 4 wrapper. It has a impure component
 * because it's  highly coupled to route configs and Login and NotFoundPage Components.
 * Can it will be an library in a not far distant future?
 *
 * TODO: Write some case tests for logged and not logged case
 */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Routes as Switch, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import reverse from 'lodash/reverse'

import { RootState } from '../store/configureStore'
import { privateRoutes, notLoggedRoutes, Route as RouteType } from './pathUrls'
import NotFoundPage from '../pages/NotFoundPage'
import { signOutRequest } from '../store/auth/actions'


const Routes = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    auth: { isLogged },
  } = useSelector((state: RootState) => state)
  const filteredPrivateRoutes = React.useCallback((): RouteType[] => {
    const filtered = privateRoutes
    if (isLogged && (!filtered || filtered.length === 0)) {
      dispatch(signOutRequest())
    }
    return filtered
  },
    []
  )

  React.useEffect(() => {
    !isLogged && location.pathname !== '/' && navigate('/')
  }, [isLogged])

  const defaultPrivateRoute = filteredPrivateRoutes().find((route) => route.default)

  const setRoute = (route: any) => {
    const { component: Component, template: Template } = route
    const props = {
      ...route,
      element: route.template ? <Template title={route.title}><Component /></Template> : <Component />,
      key: route.path,
      exact: true
    }
    return <Route {...props} />
  }


  /**
     * This preserves the path for Navigate to wished page after login
     * @param {*} route
     */
  const setPrivateRoute = (route: RouteType) => {
    return setRoute({
      ...route,
      template: route.template,
      component: route.component
    })
  }

  const setNavigate = (route: RouteType) => <Route element={<Navigate key={route.path} to={defaultPrivateRoute?.path ? defaultPrivateRoute.path : '/'} />} />

  const routesPrecedence = [
    filteredPrivateRoutes().map(setPrivateRoute),
    notLoggedRoutes.map(isLogged ? setNavigate : setRoute),
  ]

  /**
     * Two precedence rules:
     * 1 - User is Logged: Private Routes > Public Routes
     * 2 - User is Not Logged: Public Routes > Not Logged Routes > Private Routes
     *
     * These two rules allow overwrites on direction of user status, easing the route configuration.
     */
  const routes = isLogged ? routesPrecedence : reverse(routesPrecedence)

  return (
    <Switch>
      {routes}
      <Route path='*' element={<NotFoundPage />} />
    </Switch>
  )
}


export default Routes
