import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'


// Assets
import { ReactComponent as DataIcon } from '../../../assets/components/ManagersPage/data-icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/components/ManagersPage/close-icon.svg'
import { ReactComponent as PlusCircledIcon } from '../../../assets/components/ManagersPage/plus-circled.svg'
// import { ReactComponent as SearchIcon } from '../../../assets/components/ManagersPage/Search-icon.svg'
import { ReactComponent as RoundedPlusIcon } from '../../../assets/components/ManagersPage/rounded-plus-icon.svg'

// Components
import AddressForm from './AddressForm'
import {
  AddButton,
  AddSubjectWithClassroom,
  BackButton,
  ButtonOnlyIcon,
  SaveButton
} from '../buttons/StyledButtons'
import arrayMutators from 'final-form-arrays'
import CpfInput from '../../input/CpfInput'
import DateInput from '../input/DataPickerInput'
import { Box } from '@mui/system'
import {
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Typography
} from '@mui/material'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import Input from '../../input/Input'
import { PrimaryTitle, SecondaryTitle } from '../../title/Title'
import { DataField, SelectContainer, SelectTitle } from '../title/StyledTitle'
import PhoneInput from '../../input/PhoneInput'
import EmailInput from '../../input/EmailInput'
import PersonNameInput from '../../input/PersonNameInput'
import SelectField from '../../select/SelectField'
import TextInput from '../../input/TextInput'
import AutoCompleteSelect from '../../select/AutoCompleteInputSelect'
import AsyncSelectInput from '../../select/AsyncSelectInput'

// Styles
import './studentFormStyles.scss'

// Utils
import {
  required,
  validateEmail,
  maxLength,
  composeValidators,
  minLength
} from '../../../utils/formUtils'
import {
  convertDate,
  cpfFormatter,
  removeNonNumericCharacters,
  zipCodeFormatter
} from '../../../utils/functions'

// Redux
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'
import {
  createStudentRequest,
  getAllStudentsRequest,
  getStudentByIdRequest,
  updateStudentDataRequest
} from '../../../store/students/actions'
import { Parent } from '../../../models/IClassroom'
import { getSchoolAppIdRequest } from '../../../store/school_app_id/actions'

const StudentForm = ({ initialValues }: any) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { isSaving, isFetching, items } = useAppSelector((state) => state.students)
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [taxpayerNumber, setTaxpayerNumber] = useState('')
  const [customInitialValues, setCustomInitialValues] = useState<any>({})

  useEffect(() => {
    !initialValues?.id && dispatch(getAllStudentsRequest({ school_unit_id: selectedUnit?.id }))
  }, [selectedUnit?.id])

  useEffect(()=> {
    dispatch(getSchoolAppIdRequest())
  }, [selectedUnit?.id])

  useEffect(() => {
    const singleStudent = items?.find((student: any) => {
      return removeNonNumericCharacters(student?.user?.taxpayer_number) === removeNonNumericCharacters(taxpayerNumber)
    })

    if (taxpayerNumber) {
      dispatch(getStudentByIdRequest(singleStudent?.user?.id))
    }

    const parents = singleStudent?.parents?.map((parent: Parent) => {
      return {
        name: parent?.name,
        taxpayer_number: parent?.taxpayer_number && cpfFormatter(parent?.taxpayer_number),
        birthday: parent?.birthday && convertDate(parent?.birthday),
        email: parent?.email,
        phone: parent?.phone,
        additional_data: {
          address: {
            zipcode: parent?.additional_data?.address?.zipcode && zipCodeFormatter(parent?.additional_data?.address?.zipcode),
            street: parent?.additional_data?.address?.street,
            number: parent?.additional_data?.address?.number,
            complement: parent?.additional_data?.address?.complement,
            neighborhood: parent?.additional_data?.address?.neighborhood
          }
        }
      }
    })

    const item = {
      id: singleStudent?.user?.id,
      registration: singleStudent?.registration,
      taxpayer_number: singleStudent?.user?.taxpayer_number && cpfFormatter(singleStudent?.user?.taxpayer_number),
      name: singleStudent?.user?.name,
      email: singleStudent?.user?.email,
      birthday: singleStudent?.user?.birthday && convertDate(singleStudent?.user?.birthday),
      phone: singleStudent?.user?.phone,
      zipcode: singleStudent?.user?.additional_data?.address?.zipcode && zipCodeFormatter(singleStudent?.user?.additional_data?.address?.zipcode),
      neighborhood: singleStudent?.user?.additional_data?.address?.neighborhood,
      street: singleStudent?.user?.additional_data?.address?.street,
      number: singleStudent?.user?.additional_data?.address?.number,
      complement: singleStudent?.user?.additional_data?.address?.complement,
      parents: parents,
      genre: singleStudent?.user?.genre === 'female'
        ? { name: 'Feminino', value: 'female' }
        : singleStudent?.genre === 'male'
          ? { name: 'Masculino', value: 'male' }
          : undefined,
    }
    singleStudent && !initialValues?.id && setCustomInitialValues(item ?? {})
  }, [taxpayerNumber])

  const onSubmitForm = (values: any) => {
    const cpfWithoutFormatting = values?.taxpayer_number?.replace(/\D/g, '')
    let parentsData = values?.parents?.map((parent: Parent) => {
      return ({
        ...parent,
        email: parent?.email?.toLowerCase(),
        additional_data: {
          address: {
            ...parent?.additional_data?.address
          }
        }
      })
    })

    parentsData = parentsData?.map((parent: Parent) => ({
      ...parent,
      taxpayer_number: parent?.taxpayer_number?.replace(/\D/g, '')
    }))

    const studentDataToSend = {
      ...values,
      taxpayer_number: cpfWithoutFormatting,
      school_unit_id: selectedUnit?.id,
      parents: parentsData
    }

    if (initialValues?.id || customInitialValues?.id) {
      dispatch(updateStudentDataRequest(studentDataToSend))
    } else {
      dispatch(createStudentRequest(studentDataToSend))
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      initialValues={
        initialValues && Object?.keys(initialValues)?.length > 0
          ? initialValues
          : customInitialValues
      }
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, form, values }) => {

        const NoClassroom = values?.classrooms?.[0]?.classroom === undefined
        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <PrimaryTitle>
                <DataIcon /> Dados cadastrais
              </PrimaryTitle>
              <Paper sx={{ borderRadius: '16px', padding: '32px 24px' }}>
                <PrimaryTitle>Aluno</PrimaryTitle>
                <SecondaryTitle sx={{ pb: '5px' }}>Dados pessoais</SecondaryTitle>
                <Box sx={{ mb: '32px' }}>
                  <Box>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      <Grid item md={6} lg={6} xl={3}>
                        <DataField>
                          <div>
                            CPF
                          </div>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '8px',
                              width: '100% !important'
                            }}
                          >
                            {isFetching ? (<Skeleton variant='rounded' animation='wave' sx={{
                              height: '40px',
                              width: '100%',
                              borderRadius: '8px'
                            }}
                            />
                            ) : (
                              <Field
                                name='taxpayer_number'
                                id='taxpayer_number'
                                component={AutoCompleteSelect}
                                disabled={initialValues?.id}
                                validate={required}
                                placeholder='Digite o CPF'
                                setTaxpayerNumber={setTaxpayerNumber}
                                options={items?.map((option: any) => option?.user?.taxpayer_number)}
                                disableClearable
                              />
                            )
                            }
                          </Box>
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={3} xl={3} >
                        <DataField>
                          <div>
                            Matrícula
                          </div>
                          <Field
                            name='registration'
                            component={TextInput}
                            validate={required}
                            placeholder='Digite a matrícula...'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={6} >
                        <DataField>
                          <div>Nome do aluno</div>
                          <Field
                            name='name'
                            component={Input}
                            validate={required}
                            placeholder='Insira o nome do aluno'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={3}>
                        <DataField>
                          <div>Data de nascimento</div>
                          <Field
                            name='birthday'
                            component={DateInput}
                            placeholder='00/00/00'
                            label='Data de nascimento'
                            validate={required}
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={6} lg={6} xl={3}>
                        <DataField sx={{
                          '& > div > div > div > input': {
                            textTransform: 'lowercase'
                          }
                        }} className='email'>
                          <div>Email</div>
                          <Field
                            name='email'
                            component={Input}
                            validate={composeValidators(required, validateEmail)}
                            placeholder='Insira o email do aluno'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={3} lg={4} xl={3}>
                        <DataField>
                          <div>Contato</div>
                          <Field
                            name='phone'
                            component={PhoneInput}
                            validate={required}
                            placeholder='(00) 0 0000-0000'
                          />
                        </DataField>
                      </Grid>
                      <Grid item md={3} lg={4} xl={3}>
                        <SelectContainer>
                          <SelectTitle>Sexo biológico</SelectTitle>
                          <Field
                            name='genre'
                            placeholder={theme.breakpoints.values.md ? 'Selecione...' : 'Selecione o sexo biológico'}
                            component={SelectField}
                            width='100%'
                            hasBorder
                            options={[
                              { name: 'Masculino', value: 'male' },
                              { name: 'Feminino', value: 'female' }
                            ]}
                          />
                        </SelectContainer>
                      </Grid>
                    </Grid>
                    {
                      isFetching ? (
                        <CircularProgress sx={{ margin: '24px' }} />
                      ) : (
                        <FieldArray
                          name='classrooms'
                        >
                          {({ fields }) => {
                            return (
                              <Box>
                                <Box sx={{ width: '100%' }}>
                                  <AddSubjectWithClassroom
                                    type='button'
                                    onClick={() => fields.push({})}
                                    disabled={false}
                                  >
                                    <RoundedPlusIcon />
                                    Adicionar turmas
                                  </AddSubjectWithClassroom>
                                </Box>
                                {fields.map((name, index) => (
                                  <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '24px'
                                  }}
                                    key={index}
                                  >
                                    <Box display={'flex'} gap={'24px'} pb={'24px'}>
                                      <SelectContainer>
                                        <SelectTitle>Turma</SelectTitle>
                                        <Field
                                          name={`${name}.classroom`}
                                          hasBorder
                                          component={AsyncSelectInput}
                                          placeholder='Selecione a turma ...'
                                          searchParam='name'

                                          request={{
                                            path: '/classrooms/with_students_for_coordinator',
                                            params: {school_unit_id: selectedUnit?.id}
                                          }}
                                          validate={required}
                                        />
                                      </SelectContainer>
                                      <DataField>
                                        <div>Série</div>
                                        <Field
                                          name={`${name}.grade`}
                                          component={Input}
                                          placeholder='Série'
                                          initialValue={
                                            values?.classrooms[index]?.classroom?.grade?.name
                                          }
                                          disabled
                                        />
                                      </DataField>
                                      <ButtonOnlyIcon

                                        type='button'
                                        onClick={() => fields.remove(index)}
                                      >
                                        <CloseIcon />
                                      </ButtonOnlyIcon>
                                    </Box>
                                  </Box>
                                ))}
                                {
                                    NoClassroom && <Typography
                                      sx={{
                                        color: theme.palette.error.main,
                                        fontSize: '0.75rem',
                                        paddingLeft: '24px',

                                      }}
                                    >
                                      Campos Obrigatórios *
                                    </Typography>
                                  }
                              </Box>
                            )
                          }}
                        </FieldArray>
                      )
                    }
                  </Box>
                  <AddressForm defaultValue={state?.student?.additional_data} change={form.change} values={values?.zipcode} />
                </Box>
                <FieldArray name='parents'>
                  {({ fields }) => (
                    <Box
                      sx={{ display: 'grid' }}
                    >
                      <Box>
                        {fields.map((name, index) => {
                          return (
                            <Box sx={{ position: 'relative' }} key={index}>
                              <Box>
                                <PrimaryTitle sx={{ mb: 0, pb: '8px' }}>Responsável {index + 1}</PrimaryTitle>
                              </Box>
                              <Box
                                sx={{
                                  width: '100%',
                                  background: '#f9f9f9',
                                  borderRadius: '8px',
                                  padding: '29px 47px 32px 32px',
                                  mb: '32px'
                                }}
                              >
                                <Box sx={{ display: 'grid', width: '100%', }}>
                                  <ButtonOnlyIcon
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      position: 'absolute',
                                      top: 50,
                                      right: 10
                                    }}
                                    type='button'
                                    onClick={() => fields.remove(index)}
                                  >
                                    <CloseIcon />
                                  </ButtonOnlyIcon>
                                </Box>
                                <Box>
                                  <Grid container rowSpacing={3} columnSpacing={3}>
                                    <Grid md={12} lg={12} item>
                                      <DataField>
                                        <div>
                                          Nome do responsável {index + 1}
                                        </div>
                                        <Field
                                          name={`${name}.name`}
                                          component={PersonNameInput}
                                          validate={required}
                                          placeholder='Insira o nome do responsável'
                                        />
                                      </DataField>
                                    </Grid>
                                    <Grid md={6} lg={3} item>
                                      <DataField>
                                        <div>
                                          CPF
                                        </div>
                                        <Field
                                          name={`${name}.taxpayer_number`}
                                          component={CpfInput}
                                          maxLength={14}
                                          validate={composeValidators(required, maxLength(14), minLength(14))}
                                          placeholder='Insira o CPF do responsável'
                                          disabled={initialValues?.parents && initialValues?.parents[index]?.taxpayer_number}
                                        />
                                      </DataField>
                                    </Grid>
                                    <Grid md={6} lg={3} item>
                                      <DataField>
                                        <div>
                                          Data de nascimento
                                        </div>
                                        <Field
                                          name={`${name}.birthday`}
                                          component={DateInput}
                                          placeholder='00/00/00'
                                          label='Data de nascimento'
                                          validate={required}
                                        />
                                      </DataField>
                                    </Grid>
                                    <Grid md={6} lg={3} item>
                                      <DataField className='email'>
                                        <div>
                                          Email
                                        </div>
                                        <Field
                                          name={`${name}.email`}
                                          component={EmailInput}
                                          validate={composeValidators(required, validateEmail)}
                                          placeholder='Insira o email do responsável'
                                        />
                                      </DataField>
                                    </Grid>
                                    <Grid md={6} lg={3} item>
                                      <DataField>
                                        <div>
                                          Contato
                                        </div>
                                        <Field
                                          name={`${name}.phone`}
                                          component={PhoneInput}
                                          validate={required}
                                          placeholder='(00) 0 0000-0000 '
                                        />
                                      </DataField>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Box>
                                  <AddressForm
                                    name={`${name}.additional_data.address`}
                                    change={form.change}
                                    values={values?.parents[index]?.additional_data?.address?.zipcode}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          )
                        })}
                      </Box>
                      <AddButton
                        sx={{ display: 'flex', justifySelf: 'end', marginRight: 0 }}
                        type='button'
                        onClick={() => fields.push({})}
                        bgColor={theme.palette.primary.light}
                        bgActiveColor={theme.palette.secondary.main}
                      >
                        <PlusCircledIcon />
                        Adicionar Responsável
                      </AddButton>
                    </Box>
                  )}
                </FieldArray>
              </Paper>
            </Box>
            <Box
              sx={{
                mt: '32px',
                display: 'flex',
                gap: '16px',
                justifyContent: 'flex-end'
              }}
            >
              <BackButton
                onClick={() => navigate('/manage-students')}
              >
                Voltar
              </BackButton>
              <SaveButton
                startIcon={
                  (isSaving || isFetching)
                    ? <CircularProgress
                      size={20}
                      variant='indeterminate'
                      color='inherit' />
                    : undefined
                }
                disabled={ isSaving || isFetching || NoClassroom}
                type='submit'
              >
                Salvar
              </SaveButton>
            </Box>
          </form>
        )
      }}
    />
  )
}

export default StudentForm
