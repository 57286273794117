import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled(Box)(() => ({
  paddingInline: '16px',
  width: '100%',
}))

export const Title = styled('h3')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '120%'
}))

export const SubTitle = styled('h5')(() => ({
  color: '#9F9F9F',
  fontSize: '14px',
  fontWeight: 600,
}))

export const Teacher = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  '& .avatar': {
    marginLeft: '7px',
    marginRight: '16px'
  },

  '& .teacher': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '120%'
  }
}))

export const Content = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '23px'
}))

export const Subject = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',

  '& .name': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '120%',
    maxWidth: '430px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))

export const LoadingContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  height: '350px',
  justifyContent: 'center',

  '& > p': {
    color: theme.palette.text.primary,
    fontWeight: 600
  }
}))
