
import { AnyAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects'
import { keyBy } from 'lodash'
import { saveAs } from 'file-saver'
import {
  activitiesItemsFetchRequest,
  activitiesItemsFetchSuccess,
  activitiesItemsFetchFailure,
  activitySaveRequest,
  activitySaveSuccess,
  activitySaveFailure,
  activityUpdateRequest,
  activityReopenSuccess,
  activityReopenFailure,
  activityReopenRequest,
  activityUpdateSuccess,
  activityUpdateFailure,
  activityItemByIdFetchRequest,
  activityItemByIdFetchSuccess,
  activityItemByIdFetchFailure,
  archiveActivityRequest,
  archiveActivitySuccess,
  archiveActivityFailure,
  SaveActivityPayload,
  UpdateActivityPayload,
  activitiesOverviewFetchRequest,
  activitiesOverviewFetchSuccess,
  activitiesOverviewFetchFailure,
  activityParticipantsDetailsRequest,
  activityParticipantsDetailsSuccess,
  activityParticipantsDetailsFailure,
  activityReportFetchSuccess,
  activityReportFetchFailure,
  activityReportFetchRequest,
  activityDownloadFileRequest,
  activityDownloadFileRequestSuccess,
  activityDownloadFileRequestFailure,
  generateActivityReportSuccess,
  generateActivityReportFailure,
  generateActivityReportRequest

} from './actions'
import { questionAddInActivity, clearSelectedQuestions, clearSelectedClassrooms } from '../questions/actions'
import * as services from './services'
import { showAlertMessage } from '../alert'
import { createQuestionVariants, updateQuestionVariants, deleteQuestionVariants } from '../questions/services'
import { checkCurrentRole } from '../../utils/functions'
import { DefaultAction } from '../../utils/types'
import { TakeableChannel } from 'redux-saga'
import { toast } from 'react-toastify'

export function* fetchActivities({ payload }: AnyAction) {
  try {
    const userParams = {
      school_unit_ids: null
    }
    const { persistable } = yield select(state => state)
    const { filters } = yield select(state => state.activities)
    const { selectedRole, selectedUnit } = persistable
    const { units } = yield select(state => state.auth?.user)

    if (!checkCurrentRole(selectedRole, 'fractal_operator')) {
      userParams.school_unit_ids = selectedUnit ? selectedUnit.id : units[0]?.id
    }

    const params = {
      ...payload,
      ...filters,
      ...userParams
    }
    const response: AxiosResponse<any> = yield call(services.fetchActivities, {
      params,
      id: selectedRole.id
    })

    yield put(activitiesItemsFetchSuccess({
      items: response.data.data,
      pagination: {
        totalOfPages: Number(response.headers['x-total-pages']),
        current: Number(response.headers['x-page']),
        total: Number(response.headers['x-total-count']),
      }
    }))
  } catch (error) {
    yield put(activitiesItemsFetchFailure())
  }
}

export function* saveActivity({ payload }: DefaultAction<SaveActivityPayload>) {
  const { activity } = payload
  try {
    const selectedRoleId: number = yield select(state => state.persistable.selectedRole.id)
    const requestParams = { ...activity, user_role_id: selectedRoleId }
    const response: AxiosResponse = yield call(services.saveActivity, requestParams)
    const moduleId = response.data && response.data.modules && response?.data?.modules[0]?.module?.id
    if (moduleId && requestParams?.shuffle_options && requestParams?.shuffle_options !== 'no_shuffle') {
      const questionVariation = {
        shuffle_option: requestParams.shuffle_options,
        number_of_versions: requestParams.number_of_versions ?? 1,
        content_content_module_id: moduleId,
        lms_activity_id: response.data.id
      }
      yield call(createQuestionVariants, questionVariation)
    }
    yield put(activitySaveSuccess())
    yield put(clearSelectedQuestions())
    yield put(clearSelectedClassrooms())
  } catch (error) {
    console.error(error)
    yield put(activitySaveFailure())
  }
}

function* updateActivity({ payload }: DefaultAction<UpdateActivityPayload>) {
  const { id, activity } = payload
  try {
    const selectedRoleId: number = yield select(state => state.persistable.selectedRole.id)
    const requestParams = { ...activity, user_role_id: selectedRoleId }
    const response: AxiosResponse = yield call(services.updateActivity, { id, requestParams })
    const moduleId = response.data.modules[0]?.module?.id
    const variants = response.data.variants

    if (variants && moduleId && requestParams?.shuffle_options && requestParams?.shuffle_options !== 'no_shuffle') {
      const questionVariation = {
        content_content_module_id: moduleId,
        shuffle_option: requestParams.shuffle_options,
        number_of_versions: requestParams.number_of_versions ?? 1
      }
      const params = {
        id: variants.id,
        variantsOption: questionVariation
      }
      yield call(updateQuestionVariants, params)
    }

    if (variants && (requestParams?.shuffle_options === 'no_shuffle' || requestParams?.number_of_versions === 0)) {
      const params = {
        id: variants.id
      }
      yield call(deleteQuestionVariants, params)
    }

    if (!variants && requestParams?.shuffle_options && requestParams?.number_of_versions) {
      const questionVariation = {
        shuffle_option: requestParams.shuffle_options,
        number_of_versions: requestParams.number_of_versions ?? 1,
        content_content_module_id: moduleId,
        lms_activity_id: response.data.id
      }
      yield call(createQuestionVariants, questionVariation)
    }

    yield put(activityUpdateSuccess(response.data))
    yield put(clearSelectedQuestions())
    yield put(clearSelectedClassrooms())
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(activityUpdateFailure(err.message))
    } else {
      throw Error(String(err))
    }
  }
}

function* reopenActivity({ payload }: DefaultAction<any>) {

  const data = {
    id: payload.id,
    started_at: payload.start_date,
    finished_at: payload.end_date,
    current_time: new Date
  }

  try {
    const { item: { id } } = yield select(state => state.activities)
    const activityId = id
    const response: AxiosResponse = yield call(services.reopenActivity, data)
    if (response.status === 200) {
      yield put(activityReopenSuccess(response.data))
      yield put(activityParticipantsDetailsRequest(payload.id))
      yield put(showAlertMessage({
        message: 'Atividade reaberta com sucesso.',
        severity: 'success',
        duration: 5000
      }))
      payload.redirect(`/activities/${activityId}/view?info=students`)
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(activityReopenFailure(err.message))
      yield put(showAlertMessage({
        message: 'Erro ao reabrir atividade.',
        severity: 'error',
        duration: 5000
      }))
    } else {
      throw Error(String(err))
    }
  }
}

export function* fetchActivityItemById({ payload }: DefaultAction<any>) {
  try {
    const selectedRoleId: number = yield select(
      state => state.persistable.selectedRole.id
    )
    const params = {
      user_role_id: selectedRoleId
    }
    const response: AxiosResponse<any> = yield call(services.fetchActivityItemById, {
      id: payload,
      params
    })
    const { data } = response
    let classroomIds: any = []
    let participants: any = []
    if (data?.participants) {
      classroomIds = [...new Set(data?.participants.map((item: any) => item.school_classroom.id))]
      participants = data.participants
    } else {
      data?.school_participants?.forEach((school: any) => {
        school?.school_classrooms?.forEach((item: any) => classroomIds.push(item.id))
      })

      data?.school_participants?.forEach((participant: any) => {
        participant.school_classrooms.forEach((classroom: any) => {
          classroom.participants.forEach((participantData: any) => {
            participants.push({
              id: participantData.participant_id,
              user: {
                id: participantData.id,
                name: participantData.name
              },
              school_classroom: {
                id: classroom.id,
                name: classroom.name,
                grade: classroom.grade,
                unit: {
                  id: participant.id,
                  name: participant.name
                }
              }
            })
          })
        })
      })
    }
    const responseStatus: AxiosResponse<any> = yield call(services.fetchActivityParticipantStatus, {
      id: payload
    })

    let responseQuestionsDetails = null
    if (data.status !== 'waiting' && data.status !== 'drafted') {
      try {
        const responseQuestions: AxiosResponse = yield call(services.fetchActivityQuestionDetailsList, {
          school_classroom_ids: classroomIds,
          id: payload
        })

        responseQuestionsDetails = responseQuestions.data
      } catch (error: any) {
        console.error(error)
      }
    }

    yield put(activityItemByIdFetchSuccess({
      item: {
        ...data,
        participants: participants,
        questions: responseQuestionsDetails
      },
      participantStatus: responseStatus.data,
    }))

    const studentsById = data.modules[0].module.content_module_items.map(
      (moduleItem: { content: { question: any; id: any } }) => ({
        question: moduleItem.content.question || moduleItem.content,
        id: moduleItem.content.id,
        type: moduleItem.content.question ? 'question' : 'content'
      })
    )

    yield put(
      questionAddInActivity({
        byId: keyBy(studentsById, 'id'),
        allIds: studentsById.map((item: { id: any }) => item.id),
        types: studentsById.map((item: any) => ({ id: item.id, type: item.type }))
      })
    )
  } catch (error) {
    console.error(error)
    yield put(activityItemByIdFetchFailure())
  }
}

export function* archiveActivity({ payload: id }: AnyAction) {
  try {
    const { pagination, filters } = yield select(state => state.activities)
    const response: AxiosResponse = yield call(services.archiveActivity, id)
    if (response.status === 200) {
      yield put(archiveActivitySuccess())
      yield put(activitiesItemsFetchRequest({
        order: filters.order,
        page: pagination.current
      }))
    }
  } catch (error) {
    yield put(showAlertMessage({
      message: 'Falha ao arquivar atividade.',
      severity: 'error',
      duration: 5000
    }))
    if (axios.isAxiosError(error)) {
      yield put(archiveActivityFailure(error.message))
    } else {
      throw Error(String(error))
    }
  }
}

export function* fetchStudentActivities({ payload }: AnyAction) {
  interface ResponseData {
    data: {
      data: Array<any>
    }
  }
  try {
    const { activityId, classroomId } = payload
    const params = {
      school_classroom_ids: classroomId,
      id: activityId
    }

    const response: ResponseData = yield call(services.studentActivities, { params })

    yield put(activitiesOverviewFetchSuccess({ items: response.data }))
  } catch (error) {
    yield put(activitiesOverviewFetchFailure())
  }
}

function* fetchActivityParticipantsDetails({ payload }: AnyAction) {
  try {
    const response: AxiosResponse = yield call(services.fetchActivityParticipantsDetails, payload)
    yield put(activityParticipantsDetailsSuccess(response.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(activityParticipantsDetailsFailure(error.message))
    } else {
      throw Error(String(error))
    }
  }
}

function* activityReport({ payload }: AnyAction) {
  try {
    const response: AxiosResponse = yield call(services.fetchActivityReport, payload)
    yield put(activityReportFetchSuccess(response.data.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(activityReportFetchFailure())
    } else {
      throw Error(String(error))
    }
  }
}

function* fetchActivityDownloadFileRequest({ payload }: AnyAction) {
  try {
    yield call(services.requestActivityDownloadFile, payload.id)
    yield put(activityDownloadFileRequestSuccess())
    yield put(
      showAlertMessage({
        message: 'Arquivo solicitado, espere para baixar.',
        severity: 'success',
        duration: 3000,
      })
    )
    yield delay(5000)
    try {
      const response: AxiosResponse = yield call(services.downloadActivityFile, payload.id)

      saveAs(response.data, `atividade-${payload.title}.docx`)
    } catch (error) {
      yield put(
        showAlertMessage({
          message: 'Falha ao baixar o arquivo.',
          severity: 'error',
          duration: 5000,
        })
      )
    }
  } catch (error) {
    yield put(activityDownloadFileRequestFailure())
    yield put(
      showAlertMessage({
        message: 'Falha ao solicitar arquivo para baixar.',
        severity: 'error',
        duration: 5000,
      })
    )
  }
}

export function * generateActivityReport ({ payload }: any) {
  const notifyError = () => toast.error('Falha ao solicitar relatório.')
  const notifySuccess = () => toast.success('Relatório enviado para o email.')
  try {
    const { user } = yield select(state => state.auth)
    const email = user.email
    const id = payload.id
    const body = { email }
    yield call(services.generateActivityReport, { id, body })
    yield put(generateActivityReportSuccess())
    notifySuccess()
  } catch (error) {
    notifyError()
    yield put(generateActivityReportFailure())
  }
}

function* watchActivityDownloadFileRequest() {
  yield takeLatest(activityDownloadFileRequest as unknown as TakeableChannel<unknown>, fetchActivityDownloadFileRequest)
}

function* watchFetchActivities() {
  yield takeLatest(activitiesItemsFetchRequest.type, fetchActivities)
}

function* watchSaveActivity() {
  yield takeLatest(activitySaveRequest.type as unknown as TakeableChannel<unknown>, saveActivity)
}

function* watchUpdateActivity() {
  yield takeLatest(activityUpdateRequest.type as unknown as TakeableChannel<unknown>, updateActivity)
}

function* watchReopenActivity() {
  yield takeLatest(activityReopenRequest.type as unknown as TakeableChannel<unknown>, reopenActivity)
}

function* watchArchiveActivity() {
  yield takeLatest(archiveActivityRequest.type as unknown as TakeableChannel<unknown>, archiveActivity)
}

function* watchFetchActivityById() {
  yield takeLatest(activityItemByIdFetchRequest.type as unknown as TakeableChannel<unknown>, fetchActivityItemById)
}

function* watchOverviewActivity() {
  yield takeLatest(activitiesOverviewFetchRequest.type, fetchStudentActivities)
}

function* watchFetchActivityParticipantsDetails() {
  yield takeLatest(activityParticipantsDetailsRequest.type, fetchActivityParticipantsDetails)
}
function* watchFetchActivityReport() {
  yield takeLatest(activityReportFetchRequest.type, activityReport)
}
function* watchGenerateActivityReport() {
  yield takeLatest(generateActivityReportRequest.type, generateActivityReport)
}

export default function* accountSagas() {
  yield all([
    watchFetchActivities(),
    watchSaveActivity(),
    watchUpdateActivity(),
    watchArchiveActivity(),
    watchOverviewActivity(),
    watchFetchActivityById(),
    watchFetchActivityParticipantsDetails(),
    watchReopenActivity(),
    watchFetchActivityReport(),
    watchActivityDownloadFileRequest(),
    watchGenerateActivityReport()
  ])
}
