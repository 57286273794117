import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import ContentsView from '../../Contents/list/ContentsView'
import Button from '../../button/Button'

interface ContentSupporViewProps {
  contents: any
  contentsIds: number
  isTeacher: boolean | undefined
  userId: any
  form?: any
  associateContent: (value: number) => void
  setShowContent: (value: boolean) => void
}

const ContentSupportView: React.FC<ContentSupporViewProps> = ({
  contents,
  contentsIds,
  isTeacher,
  userId,
  form,
  associateContent,
  setShowContent
}) => {
  return (
    <>
      <Paper sx={{ padding: '56px 32px 56px 24px', borderRadius: '16px' }}>
        <Grid container>
          <ContentsView
            contents={contents}
            contentsIds={contentsIds}
            isTeacher={isTeacher}
            isSchoolQuestions
            userId={userId}
            associateContent={associateContent}
            isAssociable={!!associateContent}
          />
        </Grid>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '3rem', height: 90 }}>
        <Button
          type='button'
          variant='outlined'
          onClick={() => setShowContent(false)}
        >
          Voltar
        </Button>
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => {
            form.change('content_parents', [contentsIds])
            setShowContent(false)
          }}
        >
          Atualizar
        </Button>
      </Box>
    </>
  )
}

export default ContentSupportView
