import React, { useState } from 'react'

// Components
import { Box, Typography } from '@mui/material'
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

// Utils
import { COLORS } from '../../utils/constants'

// Styles
import {
  Container,
  LegendContainer,
  LegendItem,
  TooltipContainer,
  TooltipContent
} from './styles/PerformanceLineChart.styles'

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        {payload.map((item: any, index: number) => {
          return (
            <TooltipContent key={`${item?.color}-${index}`}>
              <Typography className='tooltip_title'>{item.dataKey}</Typography>
              <Box className='tooltip_content'>
                <Typography
                  component='span'
                  className='tooltip_content-label'
                >
                  Módulos:
                </Typography>
                <Typography
                  component='span'
                  className='tooltip_content-value modules'
                >
                  {item.payload[`${item.dataKey} - modules`]}
                </Typography>
              </Box>

              <Box className='tooltip_content'>
                <Typography
                  component='span'
                  className='tooltip_content-label'
                >
                  Progresso:
                </Typography>
                <Typography
                  component='span'
                  className='tooltip_content-value performance'
                >
                  {item.payload[item.dataKey]}%
                </Typography>
              </Box>
            </TooltipContent>
          )
        })}
      </TooltipContainer>
    )
  }
  return null
}

const CustomLegend = ({ payload, onClick, lineProps }: any) => {
  return (
    <LegendContainer>
      {payload.map((item: any, index: number) => {
        return (
          <LegendItem
            key={index}
            background={item?.color}
            isHiden={lineProps?.[item.dataKey]}
            onClick={() => onClick(item)}
          >
            <span className='circle' />
            <span className='label'>{item.value}</span>
          </LegendItem>)
      })}
    </LegendContainer>
  )
}

const renderCustomBarLabel = ({
  payload,  // eslint-disable-line
  x,
  y,
  width,
  height,  // eslint-disable-line
  value
}: any) => {
  return (
    <text
      x={x + width / 2}
      y={y}
      fill='#666'
      textAnchor='middle'
      style={{ fontSize: '12px' }}
      dy={-6}
    >
      {`${value}%`}
    </text>
  )
}

const ProgressBarChart = ({ label, chartData }: any) => {
  const [lineProps, setLineProps] = useState(
    label.reduce((acc: any, item: any) => {
      acc[item.school_classroom.name] = false
      return acc
    }, {})
  )

  const handleClickLegend = (event: any) => {
    setLineProps({
      ...lineProps,
      [event.dataKey]: !lineProps[event.dataKey]
    })
  }

  return (
    <Container>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart data={chartData} height={300} barGap={20}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            label={{
              value: 'Capítulos',
              styles: { textAnchor: 'middle' },
              position: 'bottom',
              offset: -10
            }}
            height={45}
          />

          <YAxis
            tickCount={6}
            domain={[0, 100]}
            label={{
              value: 'Progresso (%)',
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: -11
            }}
          />

          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign='top'
            content={
              <CustomLegend
                onClick={handleClickLegend}
                lineProps={lineProps}
              />
            }
          />
          {label?.map((item: any, index: number) => {
            return (
              <Bar
                key={item.school_classroom.id}
                dataKey={item.school_classroom.name}
                fill={COLORS[index]}
                barSize={25}
                hide={lineProps?.[item?.school_classroom?.name] === true}
                activeBar={<Rectangle fill={COLORS[index]} stroke={COLORS[index]} />}
                label={renderCustomBarLabel}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default ProgressBarChart
