import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

export const CollapseTitleStyle = styled('span')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: 8,
  color: '#FFF',
  fontSize: 14,
  padding: '5px 18px',
}))

export const DataField = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  '& div:first-of-type': {
    color: '#9F9F9F',
    fontSize: '14px',
    fontWeight: 500
  },

  '& div:nth-of-type(2)': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 700,
    '& > div': {
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    '& > svg': {
      '& > path': {
        fill: '#666 !important'
      }
    }
  }
}))

export const SelectContainer = styled('div')(() => ({
  display: 'flex', flexDirection: 'column', gap: '8px',
  '.select__single-value':{
    color: '#9F9F9F',
    fontSize: '14px',
    fontWeight: 500
  }
}))

export const SelectTitle = styled('div')(() => ({
  fontWeight: 500, color: '#9F9F9F', fontSize: '14px'
}))
