import { once } from 'lodash'
import { Middleware, AnyAction, Dispatch } from '@reduxjs/toolkit'

import axiosInstance from '../utils/http'
import { signOutRequest as logout } from './auth/actions'

const clearUserOnAuthErrors = once(next => {
  axiosInstance.interceptors.response.use(
    response => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      const { response } = error
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      const isUnauthorizedError = [401, 400].indexOf(response?.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')

      if (isUnauthorizedError && !isAuthEndpoint) next(logout())

      return Promise.reject(error)
    }
  )
})

const setXToken = (token: string) => {
  axiosInstance.defaults.headers.common['X-TOKEN'] = token
}

export const onAuthErrors: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  clearUserOnAuthErrors(next)
  next(action)
}

/**
 * This set the token on two moments: On localStorage rehydrate
 * and on user sign in.
 * TODO: Set the token on signup too
 */
export const addTokenToRequest: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  const isStorageRehydrate = action.type === 'persist/REHYDRATE'
  const isUserSignedIn = action.type === 'auth:UPDATE_CREDENTIALS_REQUEST'

  if (isStorageRehydrate && action.payload) {
    setXToken(action.payload.auth?.credentials?.token)
  }

  if (isUserSignedIn && action.payload) {
    setXToken(action.payload.token)
  }

  next(action)
}
