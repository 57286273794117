import { createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { JsonFormat } from '../../utils/types'
import { DiscursiveQuestionCorrection, IQuestion } from '../../models/IQuestion'
import { IUser } from '../../models/IUser'
import { IQuestionDetails } from '../../models/IActivities'
import * as action from './actions'

export type QuestionJson = JsonFormat<'questions', IQuestion>


type Filter = {
  query: object
  ui: any
  view: object
  statuses: object
  questionCheckboxes: []
}

type ParticipantType = {
  user: IUser,
  answers: {
    isFetching: boolean
    items: []
    isError: boolean
    messageError: string | null
  }
}
interface StateType {
  isSchoolQuestions: boolean
  item: {
    participants: ParticipantType[]
  }
  items: { result: { questions: (string | number)[] }, entities: { questions: { [key: string | number]: IQuestion } } }
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  isSavingOrUpdating: boolean
  messageError: string | null
  errorsForm: object[] | null
  classrooms: {
    selected: {
      byId: object,
      allIds: [],
      classroomsIds: []
    },
    items: [],
    isFetching: boolean,
    isError: boolean,
    messageError: string | null
  }
  selectedQuestions: { byId: { [key: string | number]: any }, allIds: (string | number)[], types: { id?: number | string, type: string }[] }
  pagination: { per: number, total: number, results: number, current: number, totalPages: number }
  currentItem: {
    item: IQuestion | null
    isFetching: boolean
    isError: boolean
    messageError: string | null
  }
  questionsDetails: {
    items: IQuestionDetails | null,
    isFetching: boolean,
    isError: boolean,
    isSuccess: boolean,
    messageError: any
  }
  randomQuestion: object
  searchQuestionId: string
  filter: Filter
  teacherSubjects: object
  types: object
  currentQuery: object
  discursiveQuestionCorrection: DiscursiveQuestionCorrection
}

const initialState: StateType = {
  isSchoolQuestions: false,
  item: {
    participants: []
  },
  items: { result: { questions: [] }, entities: { questions: {} } },
  isFetching: false,
  isError: false,
  isSuccess: false,
  isSavingOrUpdating: false,
  messageError: null,
  errorsForm: [],
  types: {},
  currentQuery: {},
  classrooms: {
    selected: {
      byId: {},
      allIds: [],
      classroomsIds: []
    },
    items: [],
    isFetching: false,
    isError: false,
    messageError: null
  },
  questionsDetails: {
    items: {} as IQuestionDetails,
    isFetching: false,
    isError: false,
    isSuccess: false,
    messageError: null
  },
  selectedQuestions: {
    byId: {},
    allIds: [],
    types: []
  },
  pagination: {
    per: 1,
    total: 0,
    results: 10,
    current: 1,
    totalPages: 0
  },
  currentItem: {
    item: null,
    isFetching: false,
    isError: false,
    messageError: null
  },
  randomQuestion: {
    item: null,
    isFetching: false,
    isError: false,
    messageError: null
  },
  searchQuestionId: '',
  filter: {
    view: [],
    query: {},
    statuses: {
      teacher: 'default',
      curator: 'default'
    },
    questionCheckboxes: [],
    ui: {
      checked: false,
      terms: null,
      segment_ids: null,
      state_ids: null,
      region_ids: null,
      ids: null,
      image: null,
      subject_ids: null,
      topicsBySubjectIds: null,
      competence_ids: null,
      skill_ids: null,
      knowledge_area_ids: null,
      theme_ids: null,
      author_ids: null,
      scope_ids: null,
      concourse_scope_ids: null,
      concourse_ids: null,
      concourse_segment_ids: null,
      institution_ids: null,
      use_universe_ids: null,
      season_ids: null,
      grade_ids: null,
      unit_ids: null,
      exam_model_ids: null,
      years: null,
      years_of_application: null,
      years_of_admission: null,
      control_statuses: null,
      difficulty_levels: null,
      question_option_styles: null,
      question_types: null,
      statuses: null,
      phase_ids: null,
      area_ids: null,
      level_ids: null,
      with_image_problems: null,
      enem_type: null,
      multidisciplinary: null,
      content_parents: null,
      polemic: null,
      with_resolution: null,
      teacher: {
        with_pedagogical_filter: null,
        with_resolution_filter: null
      },
      curator: {
        with_pedagogical_filter: null,
        with_resolution_filter: null
      }
    }
  },
  teacherSubjects: {
    isFetching: false,
    items: [],
    isError: false,
    messageError: null
  },
  discursiveQuestionCorrection: {
    isFetching: false,
    isError: false,
    messageError: '',
    content_module: {
      id: 0,
      name: '',
      is_fractal: false,
      content_module_items_count: 0,
      questions_count: 0,
      content_items_count: 0,
    },
    content_module_item: {
      id: 0,
      item_order: 0,
      content_content_module_id: 0,
      contentable: {
        contentable_id: 0,
        contentable_type: ''
      },
      value_item: 0,
    },
    content_question: {
      id: 0,
      statement: '',
      question_type: '',
      difficulty_level: '',
      question_value: 0
    },
    answers: []
  }
}

const questionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.classroomFetchRequest, (state: StateType): StateType => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.classroomFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      classrooms: {
        selected: state.classrooms.selected,
        items: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.classroomFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(action.createQuestionRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isSavingOrUpdating: true,
      isError: false
    }))
    .addCase(action.createQuestionSuccess, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isSavingOrUpdating: false,
      isError: false,
      isSuccess: true
    }))
    .addCase(action.createQuestionFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isSavingOrUpdating: false,
      messageError: action.payload,
      isError: true
    }))
    .addCase(action.editQuestionRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isSavingOrUpdating: true
    }))
    .addCase(action.editQuestionSuccess, (state: StateType): StateType => ({
      ...state,
      isFetching: false,
      isSuccess: true,
      isSavingOrUpdating: false
    }))
    .addCase(action.editQuestionFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isSavingOrUpdating: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(action.questionItemsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isSchoolQuestions: false,
      isFetching: true,
      isSavingOrUpdating: false,
      isError: false,
      messageError: null,
      filter: {
        ...state.filter,
        query: {
          ...state.filter.query,
          ...action.payload
        }
      }
    }))
    .addCase(action.questionItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: action.payload.data,
      isFetching: false,
      isError: false,
      messageError: null
    }))
    .addCase(action.questionItemsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(action.questionItemInActivityFetch, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isSchoolQuestions: false,
      isFetching: true,
      isError: false,
      messageError: null,
      filter: {
        ...state.filter,
        query: {
          ...state.filter.query,
          ...action.payload
        }
      }
    }))
    .addCase(action.schoolQuestionItemsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isSchoolQuestions: true,
      isFetching: true,
      isError: false,
      messageError: null,
      filter: {
        ...state.filter,
        query: {
          ...state.filter.query,
          ...action.payload
        }
      }
    }))
    .addCase(action.schoolQuestionItemsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: action.payload.data,
      isFetching: false,
      isError: false,
      isSuccess: false,
      messageError: null
    }))
    .addCase(action.schoolQuestionItemsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }))
    .addCase(action.randomQuestionFetchRequest, (state: StateType): StateType => ({
      ...state,
      randomQuestion: {
        item: null,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.randomQuestionFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      randomQuestion: {
        item: action.payload.data,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.randomQuestionFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      randomQuestion: {
        item: null,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(action.questionItemByIdFetchRequest, (state: StateType): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: true,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.questionItemByIdFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        item: action.payload,
        isFetching: false,
        isError: false,
        messageError: null
      }
    }))
    .addCase(action.questionItemByIdFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentItem: {
        ...state.currentItem,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(action.clearQuestionItem, (state: StateType): StateType => ({
      ...state,
      currentItem: {
        ...initialState.currentItem
      }
    }))
    .addCase(action.clearQuestionDetails, (state: StateType): StateType => ({
      ...state,
      questionsDetails: {
        ...initialState.questionsDetails
      }
    }))
    .addCase(action.questionTypesFetchRequest, (state: StateType): StateType => ({
      ...state,
      types: {
        ...state.types,
        IsFetching: true,
        IsError: false,
        ItemsMessageError: null
      }
    }))
    .addCase(action.questionTypesFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      types: {
        ...state.types,
        items: action.payload.data,
        ItemsIsFetching: false,
        ItemsIsError: false,
        ItemsMessageError: null
      }
    }))
    .addCase(action.questionTypesFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      types: {
        ...state.types,
        ItemsIsFetching: false,
        ItemsIsError: true,
        ItemsMessageError: action.payload
      }
    }))
    .addCase(action.questionChangePagination, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      pagination: {
        ...state.pagination,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        current: action.payload.current,
        per: action.payload.per
      }
    }))
    .addCase(action.questionChangeCurrentQuery, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      currentQuery: action.payload
    }))
    .addCase(action.questionChangeFilter, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filter: {
        ...state.filter,
        ...action.payload,
        query: {
          ...state.filter.query,
          ...action.payload.query
        },
        ui: {
          ...state.filter.ui,
          ...action.payload.ui
        }
      }
    }))
    .addCase(action.questionStatusFilterChange, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filter: {
        ...state.filter,
        statuses: {
          ...state.filter.statuses,
          ...action.payload.filter
        },
        ui: {
          ...state.filter.ui,
          [action.payload.type]: {
            ...state.filter.ui[action.payload.type],
            ...action.payload.ui
          }
        }
      }
    }))
    .addCase(action.questionResetFilter, (state: StateType): StateType => ({
      ...state,
      filter: { ...initialState.filter }
    }))
    .addCase(action.questionChangeSearchId, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      searchQuestionId: action.payload
    }))
    .addCase(action.questionAddInActivity, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      selectedQuestions: action.payload
    }))
    .addCase(action.classroomAddInActivity, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        selected: action.payload
      }
    }))
    .addCase(action.classroomClear, (state: StateType): StateType => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        items: []
      }
    }))
    .addCase(action.clearSelectedClassrooms, (state: StateType): StateType => ({
      ...state,
      classrooms: {
        ...state.classrooms,
        selected: initialState.classrooms.selected
      }
    }))
    .addCase(action.addErrorsForm, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      errorsForm: action.payload
    }))
    .addCase(action.setQuestionCheckboxes, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isError: false,
      messageError: null,
      filter: {
        ...state.filter,
        questionCheckboxes: action.payload
      }
    }))
    .addCase(action.clearSelectedQuestions, (state: StateType): StateType => ({
      ...state,
      selectedQuestions: initialState.selectedQuestions
    }))
    .addCase(action.clearPagination, (state: StateType): StateType => ({
      ...state,
      pagination: initialState.pagination
    }))
    .addCase(action.questionSaveUiFilters, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      filter: {
        ...state.filter,
        ui: {
          ...state.filter.ui,
          ...action.payload
        }
      }
    }))
    .addCase(action.fetchTeacherSubjectsRequest, (state: StateType): StateType => ({
      ...state,
      teacherSubjects: {
        ...state.teacherSubjects,
        isFetching: true
      }
    }))
    .addCase(action.fetchTeacherSubjectsSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      teacherSubjects: {
        ...state.teacherSubjects,
        isFetching: false,
        items: action.payload
      }
    }))
    .addCase(action.fetchTeacherSubjectsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      teacherSubjects: {
        ...state.teacherSubjects,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(action.setQuestionSelected, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      isError: false,
      messageError: null,
      selectedQuestions: action.payload
    }))
    .addCase(action.questionDetailsFetchRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      questionsDetails: {
        items: action.payload.data,
        isFetching: true,
        isError: false,
        isSuccess: false,
        messageError: null
      }
    }))
    .addCase(action.questionDetailsFetchSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      questionsDetails: {
        items: action.payload.questionDetails,
        isFetching: false,
        isError: false,
        isSuccess: true,
        messageError: null
      }
    }))
    .addCase(action.questionDetailsFetchFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      types: {
        ...state.types,
        ItemsIsFetching: false,
        ItemsIsError: true,
        ItemsMessageError: action.payload
      }
    }))
    .addCase(action.fetchDiscursiveQuestionCorrectionRequest, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      discursiveQuestionCorrection: {
        ...action.payload,
        isFetching: true,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(action.fetchDiscursiveQuestionCorrectionSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      discursiveQuestionCorrection: {
        ...action.payload,
        isFetching: false,
        isError: false,
        messageError: '',
      }
    }))
    .addCase(action.fetchDiscursiveQuestionCorrectionFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      discursiveQuestionCorrection: {
        ...state.discursiveQuestionCorrection,
        isFetching: false,
        isError: true,
        messageError: action.payload
      }
    }))
    .addCase(action.giveTeacherFeedbackRequest, (state: StateType): StateType => ({
      ...state,
        isFetching: true,
        isError: false,
        messageError: '',

    }))
    .addCase(action.giveTeacherFeedbackSuccess, (state: StateType): StateType => ({
      ...state,
        isFetching: false,
        isError: false,
        messageError: '',
    }))
    .addCase(action.giveTeacherFeedbackFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
        isFetching: false,
        isError: true,
        messageError: action.payload
    }))
})

export default questionsReducer
