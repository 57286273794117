import React from 'react'
import TabsMaterial from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'

type Props = PropsWithChildren<{
  value: number | string
  onChange: any
  variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined
  sx?: Array<object | boolean> | object
}>

const TabsMaterialStyled = styled(TabsMaterial)(({ theme }) => ({
  borderBottom: '1px solid #9F9F9F',
  '& .MuiTabs-indicator': {
    display: 'none',
    backgroundColor: theme.palette.primary.light,
  },
}))

const Tabs = ({ children, value, onChange, variant = 'fullWidth', ...rest }: Props) => {
  return (
    <TabsMaterialStyled value={value} onChange={onChange} variant={variant} {...rest}>
      {children}
    </TabsMaterialStyled>
  )
}

export default Tabs