import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as services from './services'
import axios, { AxiosResponse } from 'axios'


function* fetchTeachersClassroomsSaga({ payload }: AnyAction) {

  try {
    const params = {
      school_unit_id: payload
    }
    const response: AxiosResponse = yield call(services.fetchTeachersClassrooms, params)
    yield put(actions.fetchTeachersClassroomsSuccess(response.data.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(actions.fetchTeachersClassroomsFailure(error.message))
    } else {
      throw Error(String(error))
    }
  }
}

function* watchfetchTeachersClassrooms() {
  yield takeLatest(actions.fetchTeachersClassroomsRequest.type, fetchTeachersClassroomsSaga)
}

export default function* teachersClassroomsSagas() {
  yield all([
    watchfetchTeachersClassrooms()
  ])
}
