import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  unitsFetchRequest,
  unitsFetchSuccess,
  unitsFetchError,
  unitsWithClassroomsFetchRequest,
  unitsWithClassroomsFetchSuccess,
  unitsWithClassroomsFetchError
} from './index'
import * as services from './services'

export function * fetchUnits () {
  try {
    const { data } = yield call(services.fetchUnits)
    yield put(unitsFetchSuccess(data))
  } catch (error) {
    yield put(unitsFetchError())
  }
}

export function * fetchUnitsWithClassrooms () {
  try {
    const { data } = yield call(services.fetchUnits)
    yield put(unitsWithClassroomsFetchSuccess(data))
  } catch (error) {
    yield put(unitsWithClassroomsFetchError())
  }
}

export function * watchUnits () {
  yield takeLatest(unitsFetchRequest.type, fetchUnits)
  yield takeLatest(unitsWithClassroomsFetchRequest.type, fetchUnitsWithClassrooms)
}

export default function * unitsSaga () {
  yield all([watchUnits()])
}
