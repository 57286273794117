import { styled } from '@mui/material/styles'

export const PasswordInput = styled('input')(({ theme }) => ({
  border: '1px solid #D9D9D9',
  borderRadius: '8px',
  fontSize: '1rem',
  padding: '11px 20px',
  ':hover': {
    outline: `1px solid ${theme.palette.primary.main}`
  },
  ':focus': {
    outline: `2px solid ${theme.palette.primary.main}`
  }
}))
