import React, { useRef, useState } from 'react'
import { FormControlLabel, RadioGroup, Radio, Paper, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from 'react-final-form'
import SimpleEditor from '../../Editor/SimpleEditor'
import Input from '../../input/Input'
import InputFile from '../../input/InputFile'
import vidIcon from '../../../assets/components/Content/vid-icon.svg'
import docIcon from '../../../assets/components/Content/doc-icon.svg'
import textIcon from '../../../assets/components/Content/text-icon.svg'
import LinkIcon from '@mui/icons-material/Link'
import styles from './styles.module.scss'
import { fileRequired, required, videoRequired } from '../../../utils/formUtils'

const ImgStyle = styled('img')({
  marginRight: 16,
})

const SchemaContent = (props: any) => {
  const { contentFormat, change, initialValues } = props
  const [editMode, setEditMode] = useState(false)
  const videoInputRef = useRef<HTMLInputElement>(null)
  const urlInputRef = useRef<HTMLInputElement>(null)
  const radioGroupRef = useRef<HTMLInputElement>(null)

  const handleClearButtonClick = (value: number) => {
    if (value === 1) {
      change('schema_content', undefined)
      change('fileData', undefined)
      change('fileUpload', undefined)
      change('attachment', undefined)
      change('textInput', undefined)
      change('urlInput', undefined)
    } else if (value === 4) {
      setEditMode(true)
      change('videoInput', undefined)
      change('textInput', undefined)
      change('urlInput', undefined)
      change('schema_content', undefined)
    } else if (value === 5) {
      change('schema_content', undefined)
      change('videoInput', undefined)
      change('textInput', undefined)
      change('fileData', undefined)
      change('fileUpload', undefined)
      change('attachment', undefined)
    } else if (value === 7) {
      change('videoInput', undefined)
      change('schema_content', undefined)
      change('fileData', undefined)
      change('fileUpload', undefined)
      change('attachment', undefined)
      change('urlInput', undefined)
    }
  }

  const handleButtonClick = (value: number) => {
    handleClearButtonClick(value)
    if (radioGroupRef.current) {
      const radioButton = radioGroupRef.current.querySelector(`input[value="${value}"]`) as HTMLInputElement
      if (radioButton) {
        radioButton.click()
      }
    }
  }

  initialValues !== undefined && handleButtonClick(+initialValues?.content_format)

  const renderFormats = (props: any) => {
    const { input } = props
    return (
      <>
        <RadioGroup
          sx={{ display: 'none' }}
          ref={radioGroupRef}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            input.onChange(e.target.value)
          }}
          value={input.value || null}
        >
          <FormControlLabel value={1} control={<Radio color='primary' />} label='Vídeo' />
          <FormControlLabel value={4} control={<Radio color='primary' />} label='Documento' />
          <FormControlLabel value={5} control={<Radio color='primary' />} label='Link' />
          <FormControlLabel value={7} control={<Radio color='primary' />} label='Texto' />
        </RadioGroup>

      </>
    )
  }

  return (
    <div>
      <Paper sx={{ padding: '30px', borderRadius: 4, backgroundColor: '#f9f9f9' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', mb: 4 }}>
          <p className={styles.format_title}>Formato *</p>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>

            <Field
              name='content_format_id'
              component={renderFormats}
              required
              validate={required}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button className={+contentFormat === 1 ? `${styles.format_bt} ${styles.selected}` : `${styles.format_bt}`} onClick={(e) => {
                e.preventDefault()
                handleButtonClick(1)
              }} name='video'>
                <ImgStyle src={vidIcon} alt='Vídeo' />
                Vídeo
              </button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button className={+contentFormat === 4 ? `${styles.format_bt} ${styles.selected}` : `${styles.format_bt}`} onClick={(e) => {
                e.preventDefault()
                handleButtonClick(4)
              }} name='doc'>
                <ImgStyle src={docIcon} alt='Documentos' />
                Documento
              </button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className={+contentFormat === 5 ? `${styles.format_bt} ${styles.selected}` : `${styles.format_bt}`}
                onClick={(e) => {
                  e.preventDefault()
                  handleButtonClick(5)
                }}
                name='link'
              >
                <LinkIcon className='icon' />
                Link
              </button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <button className={+contentFormat === 7 ? `${styles.format_bt} ${styles.selected}` : `${styles.format_bt}`} onClick={(e) => {
                e.preventDefault()
                handleButtonClick(7)
              }} name='text'>
                <ImgStyle src={textIcon} alt='Texto' />
                Texto
              </button>
            </Box>

          </Box>
        </Box>

        {+contentFormat === 1 ?
          <Field
            name='videoInput'
            component={Input}
            type='text'
            label='Link'
            placeholder='https://www.youtube.com'
            InputProps={{
              style: { backgroundColor: '#ffffff' },
              inputRef: videoInputRef
            }}
            required={+contentFormat === 1}
            validate={+contentFormat === 1 ? videoRequired : undefined}
          />
          : ''
        }
        {+contentFormat === 4 ?
          <>
            <Field
              component={InputFile}
              name='attachment'
              change={change}
              contentFormat={contentFormat}
              initialValues={initialValues}
              editMode={editMode}
              setEditMode={setEditMode}
              required={+contentFormat === 4}
              validate={+contentFormat === 4 ? fileRequired : undefined}
            />
          </>
          :
          null
        }
        {+contentFormat === 5 ?
          <Field
            name='urlInput'
            component={Input}
            type='text'
            label='Link'
            placeholder='www.example.com'
            InputProps={{
              style: { backgroundColor: '#ffffff' },
              inputRef: urlInputRef
            }}
            required={+contentFormat === 5}
            validate={+contentFormat === 5 ? videoRequired : undefined}
          />
          : ''
        }
        {+contentFormat === 7 ?
          <Field
            name='textInput'
            component={SimpleEditor}
            required={+contentFormat === 7}
            validate={+contentFormat === 7 ? required : undefined}
          />
          : ''
        }

      </Paper>
    </div>
  )
}

export default SchemaContent
