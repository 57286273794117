import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  Skeleton,
  Typography
} from '@mui/material'
import ErrorMessage from '../message/ErrorMessage'
import SimpleSelect from '../select/SimpleSelect'

// Utils
import { IBookStudentPerformance } from '../../models/IBooks'
import { stringAvatar } from '../../utils/functions'

// Styles
import {
  ButtonStyled,
  ClassroomsContainer,
  Container,
  Label,
  Student,
  StudentsContainer
} from './styles/PerformanceStudentsView.style'

// Icons
import ChartIcon from '../../assets/components/Books/chart-icon.svg'
import SchoolIcon from '../../assets/components/Books/school-icon.svg'
import { ReactComponent as ViewIcon } from '../../assets/components/Books/view-outlined-icon.svg'

interface IOption {
  id: number
  name: string
}

interface IPerformanceStudentsViewProps {
  bookId: number
  isFetching: boolean
  isError: boolean
  data: IBookStudentPerformance
  handleGetData: (params?: object) => void
  title?: string
  unitsWithClassrooms: {
    id: number,
    name: string,
    classrooms: { id: number, name: string }[]
  }[]
  classrooms: number[]
}

const PerformanceStudentsView: React.FC<IPerformanceStudentsViewProps> = ({
  bookId,
  isFetching,
  isError,
  data,
  handleGetData,
  title,
  unitsWithClassrooms,
  classrooms
}) => {
  const [unit, setUnit] = useState<IOption | null>(null)
  const [classroomsId, setClassroomsId] = useState<number[]>(classrooms)
  const navigate = useNavigate()

  const handleUnitsChange = (option: IOption) => {
    setUnit(option)
    let params: any
    if (!option) {
      params = { school_unit_ids: null }
    } else {
      params = { school_unit_ids: option?.id }
    }
    handleGetData(params)
  }

  const handleSelectClassroom = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number
  ) => {
    setClassroomsId((prevState: number[]) => {
      if (prevState.includes(value)) {
        const ids = prevState.filter(item => item !== value)
        if (ids.length === 0) {
          handleGetData({ school_classroom_id: null })
        } else {
          handleGetData({ school_classroom_id: String(ids) })
        }
        return ids
      } else {
        const ids = [...prevState, value]
        handleGetData({ school_classroom_id: String(ids) })
        return ids
      }
    })
  }

  const handleNavigateToPage = () => {
    navigate(`/books/${bookId}/report/students-performance`, { state: { title } })
  }

  return (
    <Container>
      <Box className='header'>
        <Box className='title_container'>
          <img src={ChartIcon} />
          <Typography className='title'>Desempenho dos alunos</Typography>
        </Box>

        <Box className='filter_container'>
          <img src={SchoolIcon} />
          <SimpleSelect
            className='school'
            options={unitsWithClassrooms?.map(unit => ({ id: unit.id, name: unit.name }))}
            value={unit}
            onChange={handleUnitsChange}
            placeholder='Escola...'
            isClearable
          />
        </Box>

        <Box className='button_container'>
          <ButtonStyled
            type='button'
            variant='outlined'
            onClick={handleNavigateToPage}
            startIcon={<ViewIcon />}
          >
            Visualizar lista completa
          </ButtonStyled>
        </Box>
      </Box>

      {!isError && (
        <ClassroomsContainer>
          {(unit && unit?.id) ? (
            unitsWithClassrooms?.filter(item => item.id === unit?.id)?.flatMap(item => {
              return item.classrooms.map(classroom => (
                <Box key={classroom.id} className='checkbox_container'>
                  <Checkbox
                    id={String(classroom.id)}
                    checked={classroomsId.includes(classroom.id)}
                    onChange={(e) => handleSelectClassroom(e, classroom.id)}
                  />
                  <label
                    htmlFor={String(classroom.id)}
                    title={classroom.name}
                    className={`label ${classroomsId.includes(classroom.id) ? 'checked' : ''}`}
                  >
                    {classroom.name}
                  </label>
                </Box>
              ))
            })
          ) : (
            unitsWithClassrooms.flatMap(item => (
              item.classrooms.map(classroom => (
                <Box key={classroom.id} className='checkbox_container'>
                  <Checkbox
                    id={String(classroom.id)}
                    checked={classroomsId.includes(classroom.id)}
                    onChange={(e) => handleSelectClassroom(e, classroom.id)}
                  />
                  <label
                    htmlFor={String(classroom.id)}
                    title={classroom.name}
                    className={`label ${classroomsId.includes(classroom.id) ? 'checked' : ''}`}
                  >
                    {classroom.name}
                  </label>
                </Box>
              ))
            ))
          )}
        </ClassroomsContainer>
      )}

      {isFetching && (
        <Grid container columnSpacing={3}>
          {Array(2).fill(0).map((_, index) => (
            <Grid key={index} item xs={6}>
              <Skeleton
                animation='wave'
                variant='rounded'
                sx={{
                  height: '17px',
                  width: '240px',
                  marginBottom: '16px'
                }}
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {Array(5).fill(0).map((_, index) => (
                  <Skeleton
                    key={index}
                    animation='wave'
                    variant='rounded'
                    sx={{
                      borderRadius: '8px',
                      height: '56px',
                      width: '100%'
                    }}
                  />
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {!isFetching && !isError && (
        <Grid container columnSpacing={3}>
          <Grid item xs={6}>
            <Label>
              Alunos com melhor desempenho
            </Label>

            <StudentsContainer>
              {data.high_performance?.slice(0, 5)?.map((item) => {
                return (
                  <Student key={item.user.id}>
                    <Avatar
                      alt={item.user.name}
                      variant='rounded'
                      {...stringAvatar(item.user.name, 40, 40, 14)}
                    />
                    <Box className=' student_data'>
                      <Typography component='span'>{item.user.name}</Typography>
                      <Typography component='span'>{item.school_classroom.grade.name}</Typography>
                    </Box>

                    <Box className='student_performance'>
                      {item.performance?.toFixed(2) ?? 0}%
                    </Box>
                  </Student>
                )
              })}
            </StudentsContainer>
          </Grid>

          <Grid item xs={6}>
            <Label>
              Alunos com menor desempenho
            </Label>

            <StudentsContainer>
              {data?.low_performance?.slice(0, 5)?.map((item) => {
                return (
                  <Student key={item.user.id}>
                    <Avatar
                      alt={item.user.name}
                      variant='rounded'
                      {...stringAvatar(item.user.name, 40, 40, 14)}
                    />
                    <Box className=' student_data'>
                      <Typography component='span'>{item.user.name}</Typography>
                      <Typography component='span'>{item.school_classroom.grade.name}</Typography>
                    </Box>

                    <Box className='student_performance less_accurate'>
                      {item.performance?.toFixed(2) ?? 0}%
                    </Box>
                  </Student>
                )
              })}
            </StudentsContainer>
          </Grid>
        </Grid>
      )}

      {isError && (
        <ErrorMessage />
      )}
    </Container>
  )
}

export default PerformanceStudentsView
