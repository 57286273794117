/* eslint-disable no-console */
import { AxiosResponse } from 'axios'
import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import queryString from 'query-string'
import { normalize, schema } from 'normalizr'
import { clearSubjects } from '../filters/index'

import * as actions from './actions'
import * as services from './services'
import { IRole } from '../../models/IRole'
import { checkCurrentRole } from '../../utils/functions'
import { ResponseGenerator, HTTPResponseFormat } from '../../utils/types'
import { showAlertMessage } from '../alert'

const getStatusFilters = (query: string, to: string) => {
  const filters: { [key: string]: any } = {
    enforce_pedagogical: {
      curator: {
        pedagogical_filter_statuses: 'revision'
      },
      teacher: {
        pedagogical_filter_statuses: 'reject,accepted'
      }
    },
    enforce_resolution: {
      curator: {
        resolution_statuses: 'revision',
        pedagogical_exclude_statuses: 'pending,basic'
      },
      teacher: {
        resolution_statuses: 'reject,pending',
        pedagogical_exclude_statuses: 'pending,basic'
      }
    },
    enforce_both: {
      curator: {
        resolution_statuses: 'revision',
        pedagogical_filter_statuses: 'revision'
      },
      teacher: {
        resolution_statuses: 'reject,pending',
        pedagogical_filter_statuses: 'reject,accepted'
      }
    },
    default: {
      curator: {
        resolution_statuses: 'revision',
        pedagogical_filter_statuses: 'revision',
        conditional: 'or',
        pedagogical_exclude_statuses: 'pending,basic'
      },
      teacher: {
        resolution_statuses: 'reject,pending',
        pedagogical_filter_statuses: 'reject,accepted',
        pedagogical_exclude_statuses: 'pending,basic',
        conditional: 'or'
      }
    }
  }
  return filters[query][to]
}

export function* createNewQuestion({ payload }: AnyAction) {
  try {
    const data = payload.data ? payload.data : payload
    const schoolId: (number | string) = yield select(state => state.persistable.selectedUnit.id)

    const subjectIds = String(data.subject_ids?.map((subject: any) => subject.id))
    const topicIds = String(data.subject_ids?.map((subject: any) => (
      subject.topics?.map((topic: { id: number, name: string }) => topic.id))
    ))
    const questionOptions = data.question_options?.map((question: { id: number, description: string, is_correct: boolean }, index: number) => ({
      description: question.description,
      is_correct: question.is_correct || false,
      question_option_order: index + 1
    }))
    const skillIds = String(data.subject_ids?.map((subject: any) => (
      subject.skills?.map((skill: { value: number, label: string }) => skill.value)
    )))
    const skillSubjects = data.subject_ids?.map((subject: any) => (
      subject.skills?.map((skill: { value: number }) => ({ skill_id: skill.value, subject_id: subject.id }))
    ))
    const skillsWithSubjects = [].concat(...skillSubjects)
    const themeIds = String(data.theme_ids?.map((theme: { id: number, name: string }) => theme.id))
    const parsed = {
      resolution: data.resolution,
      statement: data.statement,
      question_options: data.question_type.value === 'only_choice' ? questionOptions : [],
      difficulty_level: data.difficulty_level.value,
      segment_ids: String(data.segment_ids.id),
      subject_ids: subjectIds,
      topic_ids: topicIds,
      school_unit_id: schoolId,
      resolution_status: 'done',
      question_type: data.question_type.value,
      question_option_style: 'alphabetic',
      content_parent_ids: String(data?.content_parents),
      answer_type: data.answer_type?.value,
      enem_type: data.enem_type || false,
      polemic: data.polemic || false,
      content_universe_id: data?.user_universe_ids,
      enem_difficulty_level: data?.enem_difficulty_level?.value,
      content_use_universe_id: data?.use_universe_ids?.id,
      language: 'pt-BR',
      skill_ids: skillIds,
      theme_ids: themeIds,
      skill_subjects: skillsWithSubjects
    }

    const question: object = yield call(services.createQuestion, parsed)
    yield put(actions.createQuestionSuccess({ data: question }))
    yield put(clearSubjects())
  } catch (error) {
    yield put(actions.createQuestionFailure())
    yield put(clearSubjects())
  }
}

export function* deleteQuestionItemById({ payload }: AnyAction) {
  try {
    const { pagination: { per, current }, items } = yield select(state => state.questions)
    yield call(services.deleteQuestion, payload)
    let params: any = {
      page: current,
      per: per || 1
    }
    if (Object.keys(items.result.questions).length - 1 === 0) {
      params = {
        page: current - 1 !== 0 ? current - 1 : 1,
        per: per || 1
      }
    }
    yield put(actions.schoolQuestionItemsFetchRequest(params))
  } catch (error) {
    console.log(error)   // eslint-disable-line no-console
  }
}

export function* fetchAnswers({ payload }: AnyAction): Generator<any, void, any> {
  interface ResponseData {
    data: {
      data: Array<any>
    }
  }
  try {
    const { studentId, activityId } = payload
    const params = {
      user_user_id: studentId,
      lms_activity_id: activityId
    }
    const response: ResponseData = yield call(services.fetchAnswers, params)
    if (response?.data.data.length) {
      const responses = yield all(
        response?.data.data.map((item: any) => {
          return call(services.fetchContentModuleItems, item.content_content_module_item_id)
        })
      )
      const answers = responses.map((item: any) => {
        const progressesInfo = response.data.data.find(
          progress =>
            progress.content_content_module_item_id === item.data.data.id
        )
        return {
          ...progressesInfo,
          questionId: item.data.data.content.id,
          question: item.data.data.content.question
        }
      })

      yield put(actions.fetchAnswerSuccess({ id: studentId, answers: answers }))
      // yield put(actions.fetchAnswerSuccess())
    } else {
      // yield put(actions.fetchAnswerSuccess({ studentId, answers: [] }))
    }

  } catch (error) {
    console.log(error)   // eslint-disable-line no-console
  }
}


export function* fetchQuestionDetails({ payload }: AnyAction) {
  interface ResponseData {
    data: {
      data: Array<any>
    }
  }
  try {
    const { id, classroomIds, questionIds } = payload
    const params = {
      school_classroom_ids: classroomIds,
      content_question_ids: questionIds
    }
    const response: ResponseData = yield call(services.questionDetails, {
      id: id,
      params: params
    })
    yield put(actions.questionDetailsFetchSuccess({ id: id, questionDetails: response.data }))
  } catch (error) {
    console.log(error)   // eslint-disable-line no-console
  }
}

export function* editCustomQuestion({ payload }: AnyAction) {
  try {
    const data = payload.data ? payload.data : payload
    const schoolId: (number | string) = yield select(state => state.persistable.selectedUnit.id)
    const subjectIds = String(data.subject_ids?.map((subject: any) => subject.id))
    const topicIds = String(data.subject_ids?.map((subject: any) => (
      subject.topics?.map((topic: { id: number, name: string }) => topic.id))
    ))
    const questionOptions = data.question_options?.map((question: { id: number, description: string, is_correct: boolean }, index: number) => ({
      description: question.description,
      is_correct: question.is_correct || false,
      question_option_order: index + 1
    }))
    const skillIds = String(data.subject_ids?.map((subject: any) => (
      subject.skills?.map((skill: { value: number, label: string }) => skill.value)
    )))
    const skillSubjects = data.subject_ids?.map((subject: any) => (
      subject.skills?.map((skill: { value: number }) => ({ skill_id: skill.value, subject_id: subject.id }))
    ))
    const skillsWithSubjects = [].concat(...skillSubjects)
    const themeIds = String(data.theme_ids?.map((theme: { id: number, name: string }) => theme.id))
    const parsed = {
      id: data.id,
      resolution: data.resolution,
      statement: data.statement,
      question_options: data.question_type.value === 'only_choice' ? questionOptions : [],
      difficulty_level: data.difficulty_level.value,
      segment_ids: String(data.segment_ids.id),
      subject_ids: subjectIds,
      topic_ids: topicIds,
      school_unit_id: schoolId,
      resolution_status: 'done',
      question_type: data.question_type.value,
      question_option_style: 'alphabetic',
      content_parent_ids: String(data?.content_parents),
      answer_type: data.answer_type?.value,
      enem_type: data.enem_type || false,
      polemic: data.polemic || false,
      content_universe_id: data?.user_universe_ids,
      enem_difficulty_level: data?.enem_difficulty_level?.value,
      content_use_universe_id: data?.use_universe_ids?.id,
      language: 'pt-BR',
      skill_ids: skillIds,
      skill_subjects: skillsWithSubjects,
      theme_ids: themeIds
    }
    const question: object = yield call(services.editQuestion, parsed)
    yield put(actions.editQuestionSuccess({ data: question }))
    yield put(actions.clearQuestionItem())
    yield put(clearSubjects())
  } catch (error) {
    yield put(actions.editQuestionFailure())
    yield put(actions.clearQuestionItem())
    yield put(clearSubjects())
  }
}

export function* questionItemsFetch({ payload }: AnyAction) {
  try {
    const queryFilter: object = yield select(state => state.questions.filter.query)
    const curator: string = yield select(state => state.questions.filter.statuses.curator)
    const initialParams = {
      plain: false,
      paginate: true,
      urls_only: true,
      per: 10,
      page: 1,
      basic: true
    }
    const normalizeParams = {
      ...initialParams,
      ...getStatusFilters(curator, 'curator'),
      ...queryFilter,
      ...payload
    }

    const parsedParams = {
      params: normalizeParams,
    }
    const response: { data: { data: object }, headers: { 'x-page': string, 'x-total-count': string, 'x-total-pages': string } } = yield call(services.fetchQuestionsItems, parsedParams)
    const { data, headers } = response

    const pagination = {
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']),
      totalPages: Number(headers['x-total-pages'])
    }
    yield put(actions.questionChangePagination(pagination))
    yield put(actions.questionItemsFetchSuccess({ data: data.data }))
    yield put(actions.clearQuestionItem())
  } catch (error) {
    yield put(actions.questionItemsFetchFailure())
  }
}

type AuthType = {
  user: {
    id: number | string,
    units: { id: number }[]
  },
  roles: IRole[]
}

export function* fetchQuestionItemByIdSagas({ payload }: AnyAction) {
  try {
    const params = {
      plain: true
    }
    const questionId = payload
    const response: AxiosResponse<any> = yield call(services.fetchQuestionItemById, {
      questionId,
      params
    })
    yield put(actions.questionItemByIdFetchSuccess(response.data.data))
  } catch (error) {
    yield put(actions.questionItemByIdFetchFailure())
  }
}

export function* fetchQuestionInActivityItems({ payload }: AnyAction) {
  try {
    const queryFilter: { per: number } = yield select(state => state.questions.filter.query)
    const auth: AuthType = yield select(state => state.auth)
    const persistable: { selectedRole: IRole } = yield select(state => state.persistable)
    const selectedRole = persistable.selectedRole
    const fetchSubjectParams = {
      basic: true,
      teacher_user_id: auth.user.id
    }
    const subjectIds: ResponseGenerator = checkCurrentRole(selectedRole, 'teacher')
      ? yield services.fetchSubjects(fetchSubjectParams)
      : null
    const initialParams = {
      plain: false,
      paginate: true,
      per: 1,
      page: 1,
      basic: true,
      subject_ids: subjectIds,
      question_type: 'only_choice',
      statuses: 'active'
    }
    const normalizeParams = {
      ...initialParams,
      ...queryFilter,
      ...payload
    }
    const parsedParams = {
      params: normalizeParams,
      paramsSerializer: (params: any) => {
        return queryString.stringify(params)
      }
    }
    const response: { data: { data: object }, headers: { 'x-page': string, 'x-total-count': string, 'x-total-pages': string } } = yield call(services.fetchQuestionsItems, parsedParams)
    const { data, headers } = response
    const pagination = {
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count']),
      totalPages: Number(headers['x-total-count']) >= 10000
        ? Math.floor(10000 / queryFilter.per)
        : Number(headers['x-total-pages']),
      per: queryFilter.per
    }
    const myData = { questions: data.data }
    const question = new schema.Entity('questions')
    const scheme = { questions: [question] }
    yield put(actions.questionChangePagination(pagination))
    yield put(
      actions.questionItemsFetchSuccess({ data: normalize(myData, scheme) })
    )
  } catch (error) {
    yield put(actions.questionItemsFetchFailure())
  }
}

export function* fetchSchoolQuestions({ payload }: AnyAction) {
  try {
    const queryFilter: { per: number, subject_ids?: string } = yield select(state => state.questions.filter.query)
    const auth: AuthType = yield select(state => state.auth)
    const persistable: { selectedRole: IRole, selectedUnit: { id: number | string } } = yield select(state => state.persistable)
    const selectedRole = persistable.selectedRole
    const { unit } = payload
    const fetchSubjectParams = {
      basic: true,
      teacher_user_id: auth.user.id
    }
    const subjectIds: ResponseGenerator = checkCurrentRole(selectedRole, 'teacher')
      ? yield call(services.fetchSubjects, fetchSubjectParams)
      : null

    const normalizeParams = {
      ...queryFilter,
      ...payload,
      school_unit_id: unit || persistable.selectedUnit?.id,
      subject_ids: payload.subject_ids || queryFilter.subject_ids || subjectIds,
      order: 'desc'
    }
    const parsedParams = {
      params: normalizeParams,
      paramsSerializer: (params: any) => {
        return queryString.stringify(params)
      }
    }

    const response: HTTPResponseFormat<{ data: { data: object } }> = yield call(services.fetchSchoolQuestions, parsedParams)
    const { data, headers } = response
    const pagination = {
      totalPages: Number(headers['x-total-count']) >= 10000
        ? Math.floor(10000 / queryFilter.per)
        : Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']) >= 10000 ? 10000 : Number(headers['x-total-count']),
      per: queryFilter.per
    }
    const myData = { questions: data.data }
    const question = new schema.Entity('questions')
    const scheme = { questions: [question] }
    yield put(actions.questionChangePagination(pagination))
    yield put(actions.schoolQuestionItemsFetchSuccess({ data: normalize(myData, scheme) }))
    yield put(actions.clearQuestionItem())
  } catch (error) {
    console.error(error)
    yield put(actions.schoolQuestionItemsFetchFailure())
  }
}

export function* fetchClassrooms({ payload: { unitIds, setClassroomInActivityData } }: AnyAction) {
  try {
    yield put(actions.classroomClear())
    const params = {
      school_unit_ids: unitIds,
      per: 100,
      page: 1
    }
    const { data: { data } } = yield call(services.fetchClassrooms, params)
    yield put(actions.classroomFetchSuccess(data))
    if (setClassroomInActivityData) {
      yield put(actions.classroomAddInActivity(setClassroomInActivityData))
    }
  } catch (error) {
    console.error(error)
    yield put(actions.classroomFetchFailure())
  }
}

export function* discursiveQuestionCorrectionSaga({ payload }: any) {
  try {
    const requestBody = {
      content_module_id: payload?.content_module_id,
      content_module_item_id: payload?.content_module_item_id,
      content_question_id: payload?.content_question_id
    }
    const response: AxiosResponse = yield call(services.fetchDiscursiveCorrection, { ...requestBody, id: payload?.activity_id })
    yield put(actions.fetchDiscursiveQuestionCorrectionSuccess(response?.data))
  } catch (error) {
    console.error(error)
    yield put(actions.fetchDiscursiveQuestionCorrectionFailure())
  }
}

export function* giveTeacherFeedbackSaga({ payload }: any) {
  try {
    let requestBody: any
    if (payload.is_correct !== undefined && payload.is_correct !== null) {
      requestBody = {
        teacher_feedback: payload?.teacher_feedback,
        answer_value: payload?.answer_value,
        id: payload?.answerId,
        is_correct: payload?.is_correct
      }
    } else {
      requestBody = {
        teacher_feedback: payload?.teacher_feedback,
        answer_value: payload?.answer_value,
        id: payload?.answerId
      }
    }
    const response: AxiosResponse = yield call(services.giveTeacherFeedbackService, requestBody)
    yield put(actions.giveTeacherFeedbackSuccess(response?.data))
    yield put(
      showAlertMessage({
        message: 'Correção enviada.',
        severity: 'success',
        duration: 4000,
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.giveTeacherFeedbackFailure())
  }
}

function* watchGiveTeacherFeedback() {
  yield takeLatest(actions.giveTeacherFeedbackRequest.type, giveTeacherFeedbackSaga)
}

function* watchCreateQuestion() {
  yield takeLatest(actions.createQuestionRequest.type, createNewQuestion)
}

function* watchEditQuestion() {
  yield takeLatest(actions.editQuestionRequest.type, editCustomQuestion)
}

function* watchDeleteQuestion() {
  yield takeLatest(actions.deleteQuestionRequest.type, deleteQuestionItemById)
}

function* watchFetchQuestionById() {
  yield takeLatest(actions.questionItemByIdFetchRequest, fetchQuestionItemByIdSagas)
}

function* watchFetchQuestion() {
  yield takeLatest(actions.questionItemsFetchRequest.type, questionItemsFetch)
}

function* watchFetchQuestionItemInActivity() {
  yield takeLatest(actions.questionItemInActivityFetch.type, fetchQuestionInActivityItems)
}

function* watchFetchClassroom() {
  yield takeLatest(actions.classroomFetchRequest.type, fetchClassrooms)
}

function* watchFetchSchoolQuestions() {
  yield takeLatest(actions.schoolQuestionItemsFetchRequest, fetchSchoolQuestions)
}

function* watchFetchAnswers() {
  yield takeLatest(actions.fetchAnswerRequest.type, fetchAnswers)
}

function* watchFetchQuestionDetails() {
  yield takeLatest(actions.questionDetailsFetchRequest.type, fetchQuestionDetails)
}
function* watchDiscursiveQuestionCorrectionSaga() {
  yield takeLatest(actions.fetchDiscursiveQuestionCorrectionRequest.type, discursiveQuestionCorrectionSaga)
}


export default function* questionsSaga() {
  yield all([
    watchCreateQuestion(),
    watchEditQuestion(),
    watchDeleteQuestion(),
    watchFetchQuestionById(),
    watchFetchQuestion(),
    watchFetchQuestionItemInActivity(),
    watchFetchClassroom(),
    watchFetchSchoolQuestions(),
    watchFetchAnswers(),
    watchFetchQuestionDetails(),
    watchDiscursiveQuestionCorrectionSaga(),
    watchGiveTeacherFeedback()
  ])
}
