import React, { useCallback, useEffect, useState } from 'react'
import { Box, FormGroup, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from 'react-final-form'
import { debounce } from 'lodash'
import FiltersContainer from '../Activities/FiltersContainer'
import Label from '../label/Label'
import SearchInput from '../input/SearchInput'
import AsyncSelectInput from '../select/AsyncSelectInput'
import SelectItem from '../select/SelectItem'
import Button from '../button/Button'
import CheckboxInput from '../input/CheckboxInput'
import ModalSaveFilter from '../Modal/ModalSaveFilter'
import ModalDelete from '../Modal/ModalDelete'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { clearBooksFilters, clearClassrooms, fetchBooksRequest, fetchClassroomsRequest } from '../../store/books/actions'
import {
  // deleteAllFiltersRequest,
  deleteFiltersRequest,
  getSavedFiltersRequest,
  saveFiltersRequest,
  updateFiltersRequest
} from '../../store/filters'
import { showAlertMessage } from '../../store/alert'
import CancelIcon from '@mui/icons-material/Cancel'

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '23px',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: '16px',
  }
}))

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    gap: '16px',
    justifyContent: 'flex-end',
    marginBottom: '56px',
  }
}))

const BooksFilters = ({
  values,
  change,
  invalid,
  isTeacher,
  userId,
  perPage,
  setPage,
  filters,
  isFilterSaved,
  chosenFilter,
  setChosenFilter,
  schoolUnitId
}: any) => {
  const match = useMediaQuery('(max-width:906px)')
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchName, setSearchName] = useState<string>('')
  const [searchCode, setSearchCode] = useState<string>('')
  const [isChecked, setIsChecked] = useState(false)
  const [openSaveFilterModal, setOpenSaveFilterModal] = useState<boolean>(false)
  const [openDeleteFilterModal, setOpenDeleteFilterModal] = useState<boolean>(false)
  // const [openDeleteAllFiltersModal, setOpenDeleteAllFiltersModal] = useState<boolean>(false)
  const [filterSelectedToDelete, setFilterSelectedToDelete] = useState<string>('')
  const { selectedUnit } = useAppSelector(state => state.persistable)
  const { classrooms } = useAppSelector(state => state.books)

  const changeFilterForm = useCallback(debounce((filter, value) => {
    change(filter, value)
  }, 500), [])

  const handleSearchByTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchTerm(value)
    changeFilterForm('books.terms', value)
  }

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
    changeFilterForm('books.name', value)
  }

  const handleSearchByCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchCode(value)
    changeFilterForm('books.book_ids', value)
  }

  const handleFiltersChange = (value: any, filterName: string) => {
    let filterValue: any
    if (typeof value === 'string') {
      filterValue = value
    } else if (Array.isArray(value)) {
      filterValue = value?.map((item) => item.id ?? item.value).join(',')
    } else if (typeof value === 'object') {
      filterValue = value.id || value.value
    } else {
      return
    }
    const params = { [filterName]: filterValue, page: 1 }
    dispatch(fetchBooksRequest(params))
  }

  const cleanBooksFilters = () => {
    values && values?.books && Object.entries(values?.books).forEach(([key,]) => {
      change(`books.${key}`, undefined)
    })
    setSearchTerm('')
    setSearchName('')
    setSearchCode('')
    setIsChecked(false)
    setPage(1)
    setChosenFilter?.('')
    dispatch(clearBooksFilters())
    dispatch(fetchBooksRequest({ per: perPage, page: 1, desc: true }))
  }

  // open the save filter modal
  const handleOpenSaveFilterModal = () => {
    setOpenSaveFilterModal(true)
  }

  // close the save filter modal
  const handleCloseSaveFilterModal = () => {
    setOpenSaveFilterModal(false)
    change('filterName', null)
  }

  // open the delete filter modal
  const handleOpenDeleteFilterModal = (filterName: string) => {
    setOpenDeleteFilterModal(true)
    setFilterSelectedToDelete(filterName)
  }

  // close the delete filter modal
  const handleCloseDeleteFilterModal = () => {
    setOpenDeleteFilterModal(false)
  }

  // open the delete all filters modal
  // const handleOpenDeleteAllFiltersModal = () => {
  //   setOpenDeleteAllFiltersModal(true)
  // }

  // close the delete all filters modal
  // const handleCloseDeleteAllFiltersModal = () => {
  //   setOpenDeleteAllFiltersModal(false)
  // }

  const handleSaveFilters = () => {
    if (!values?.filterName) {
      dispatch(showAlertMessage({
        message: 'Digite um nome para o seu filtro',
        severity: 'warning'
      }))
      return
    }

    if (filters && Object.keys(filters).find(item => item === values?.filterName)) {
      dispatch(showAlertMessage({
        message: 'Nome já em uso.',
        severity: 'warning'
      }))
      return
    }

    const params: any = {
      controller_name: 'books',
      filter_name: values?.filterName,
      current_filters: { ...values?.books }
    }

    Object.keys(params).forEach(key => {
      if (!params[key] || params[key].length === 0) {
        delete params[key]
      }
    })

    if (!filters) {
      dispatch(saveFiltersRequest({ id: userId, params }))
    } else {
      dispatch(updateFiltersRequest({ id: userId, params }))
    }
    cleanBooksFilters()
    setChosenFilter?.('')
  }

  const handleCheckboxBookFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    cleanBooksFilters()
    setIsChecked(checked)
    if (checked) {
      setChosenFilter?.(name)
    } else {
      setChosenFilter?.('')
    }
  }

  const handleDeleteFilter = (name: string) => {
    const params = {
      controller_name: 'books',
      filter_name: name,
    }
    dispatch(deleteFiltersRequest({ id: userId, params: { ...params } }))
    cleanBooksFilters()
  }

  // const handleDeleteAllFilters = () => {
  //   dispatch(deleteAllFiltersRequest({ id: userId }))
  //   handleCloseDeleteAllFiltersModal()
  // }

  useEffect(() => {
    dispatch(getSavedFiltersRequest({ id: userId }))
  }, [])

  useEffect(() => {
    isChecked && values?.books?.terms?.length > 0 && setSearchTerm(values?.books?.terms)
    isChecked && values?.books?.name?.length > 0 && setSearchName(values?.books?.name)
    isChecked && values?.books?.book_ids?.length > 0 && setSearchCode(values?.books?.book_ids)
  }, [isChecked, values?.books])

  useEffect(() => {
    isFilterSaved && handleCloseSaveFilterModal()
  }, [isFilterSaved])

  useEffect(() => {
    values && values?.books && Object.entries(values?.books).forEach(([key, value]) => {
      handleFiltersChange(value, key)
    })
  }, [values?.books])

  useEffect(() => {
    selectedUnit?.id && dispatch(fetchClassroomsRequest({ school_unit_ids: [selectedUnit?.id] }))

    return () => {
      dispatch(clearClassrooms())
    }
  }, [selectedUnit?.id])

  return (
    <div>
      <FiltersContainer>
        <SearchContainer sx={{ display: 'flex', flexDirection: 'column', gap: '23px' }}>
          <Label sx={{ marginBottom: 0 }}>Filtros de pesquisa</Label>
          <SearchInput
            value={searchTerm}
            name='books.terms'
            onChange={handleSearchByTerms}
            placeholder='Termos inclusos...'
            width='280px'
            backgroundColor='rgba(217, 217, 217, 0.30)'
            className='termsFilter'
          />
        </SearchContainer>

        <Box sx={{ marginTop: '24px', maxWidth: match ? '100%' : '280px' }}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, md: 0 }}>
            <Grid item xs={6} md={12}>
              <SearchInput
                name='books.name'
                label='Título do livro'
                value={searchName}
                onChange={handleSearchByName}
                placeholder='Pesquise pelo título'
                width='100%'
                border='1px solid #d9d9d9'
                borderRadius='8px'
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <SearchInput
                name='books.book_ids'
                label='Código do livro'
                inputType='number'
                isKeyDown
                value={searchCode}
                onChange={handleSearchByCode}
                placeholder='Pesquise por código'
                width='100%'
                border='1px solid #d9d9d9'
                borderRadius='8px'
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Field
                name='books.education_subject_ids'
                component={AsyncSelectInput}
                label='Disciplinas'
                placeholder='Selecione...'
                isMulti
                request={{
                  path: '/subjects',
                  params: {
                    basic: true,
                    with_knowledge_area: false,
                    teacher_user_id: isTeacher ? userId : null
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Field
                name='books.school_classroom_ids'
                component={SelectItem}
                label='Turmas'
                placeholder='Selecione...'
                isMulti
                options={classrooms.items?.all
                  ? classrooms.items?.all?.map((classroom: any) => (
                    { value: classroom?.id, label: classroom?.name }
                  ))
                  : []}
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Field
                name='books.book_author_ids'
                component={AsyncSelectInput}
                label='Autor'
                placeholder='Selecione...'
                request={{
                  path: '/teachers/authors',
                  params: {
                    school_unit_id: schoolUnitId
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Field
                name='books.book_co_authors_ids'
                component={AsyncSelectInput}
                label='Coautor'
                placeholder='Selecione...'
                request={{
                  path: '/teachers/co_authors',
                  params: {
                    school_unit_id: schoolUnitId
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </FiltersContainer>

      {filters && Object.keys(filters).length > 0 && (
        <Box sx={{ display: 'flex', mt: match ? 3 : 2 }}>
          <Typography
            sx={{
              color: '#9F9F9F',
              mr: 2,
              fontSize: 14,
              fontWeight: 700
            }}
            variant='body2'
            component='span'
          >
            Filtros:
          </Typography>

          <FormGroup
            key='books_checkbox'
            sx={{
              display: 'flex',
              flexDirection: match ? 'row' : 'column',
              gap: match ? '16px' : '8px',
              flexWrap: 'wrap'
            }}
          >
            {Object.keys(filters).map((item, index) => (
              <Box key={`${item}-${index}`} sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckboxInput
                  key={item}
                  value={item}
                  name={item}
                  onChange={handleCheckboxBookFilters}
                  label={item}
                  labelPlacement='end'
                  checked={isChecked && chosenFilter === item}
                />
                <IconButton
                  color='error'
                  sx={{ width: '16px', height: '16px' }}
                  onClick={() => handleOpenDeleteFilterModal(item)}
                  title='Excluir filtro'
                >
                  <CancelIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              </Box>
            ))}
          </FormGroup>
        </Box>
      )}

      <ButtonContainer sx={{ display: 'flex', flexDirection: match ? 'row' : 'column', marginTop: '24px' }}>
        <Button
          type='button'
          sx={{ mt: 2, maxWidth: '280px' }}
          variant='outlined'
          disabled={!values?.books}
          onClick={cleanBooksFilters}
        >
          Limpar Filtro
        </Button>

        <Button
          type='button'
          sx={{ mt: 2, maxWidth: '280px' }}
          disabled={!values?.books}
          onClick={handleOpenSaveFilterModal}
        >
          Salvar Filtro
        </Button>

        {/* <Button
          type='button'
          color='error'
          sx={{ color: '#fff', mt: 2, maxWidth: '280px' }}
          onClick={handleOpenDeleteAllFiltersModal}
        >
          Deletar todos os filtros
        </Button> */}
      </ButtonContainer>

      <ModalSaveFilter
        isOpen={openSaveFilterModal}
        closeModal={handleCloseSaveFilterModal}
        action={handleSaveFilters}
        disableAction={invalid}
      />

      <ModalDelete
        isOpen={openDeleteFilterModal}
        closeModal={handleCloseDeleteFilterModal}
        action={() => {
          handleDeleteFilter(filterSelectedToDelete)
          handleCloseDeleteFilterModal()
        }}
        type='filter'
      />

      {/* <ModalDelete
        isOpen={openDeleteAllFiltersModal}
        closeModal={handleCloseDeleteAllFiltersModal}
        action={handleDeleteAllFilters}
        type='all_filters'
      /> */}
    </div>
  )
}

export default BooksFilters
