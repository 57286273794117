import React, { useState } from 'react'
import { Avatar, Box, Checkbox, Collapse, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PeopleIcon from '../../../assets/components/Books/peopletwo-icon.svg'
import { ITeacherClassroom } from '../../../models/IClassroom'
import { stringAvatar } from '../../../utils/functions'

const ClassroomContainer = styled(Box)(({ theme }) => ({
  background: '#f5f5f5',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '18px 16px',

  '& .classroom': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    '&_name': {
      background: theme.palette.primary.light,
      borderRadius: '8px',
      color: '#f9f9f9',
      fontSize: '14px',
      fontWeight: 400,
      padding: '5.5px 18px',
    },

    '&_students': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& span': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      }
    }
  },
}))

const StudentContainer = styled(Box)(() => ({
  gap: '24px',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',

  '& .student': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px'
  }
}))

interface IClassroomCollapseProps {
  classroom?: ITeacherClassroom
  classroomsSelected: any[]
  handleSelectClassroom: (event: React.ChangeEvent<HTMLInputElement>, classroomId?: number) => void
}

const ClassroomCollapse: React.FC<IClassroomCollapseProps> = ({ classroom, classroomsSelected, handleSelectClassroom }) => {
  const [seeClassroom, setSeeClassroom] = useState(false)

  return (
    <>
      <ClassroomContainer>
        <Box
          className='classroom'
          onClick={() => setSeeClassroom(prevState => !prevState)}
          sx={{ marginBottom: seeClassroom ? '32px' : 0 }}
        >
          <Grid container>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <span className='classroom_name'>
                {classroom?.name}
              </span>
            </Grid>

            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component='label' sx={{ cursor: 'pointer', fontSize: 14, fontWeight: 500 }}>
                <Checkbox
                  onChange={(e) => handleSelectClassroom(e, classroom?.id)}
                  checked={classroomsSelected && classroomsSelected.includes(classroom?.id)}
                />
                Selecionar turma
              </Box>
            </Grid>

            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Box className='classroom_students'>
                <img src={PeopleIcon} width='16px' />
                <span>{classroom?.users?.length} {classroom?.users && classroom?.users?.length > 1 ? 'alunos' : 'aluno'}</span>
                <KeyboardArrowDownIcon
                  fontSize='small'
                  className='arrow_icon'
                  sx={{
                    transform: seeClassroom ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Collapse in={seeClassroom}>
          <StudentContainer>
            {classroom?.users.map((student) => {
              return (
                <Box key={student.id} className='student'>
                  <Avatar
                    alt={student.name}
                    variant='rounded'
                    {...stringAvatar(student?.name)}
                  />
                  <span>{student.name}</span>
                </Box>
              )
            })}
          </StudentContainer>
        </Collapse>
      </ClassroomContainer>
    </>
  )
}

export default ClassroomCollapse
