import React, { ReactNode } from 'react'
import { styled } from '@mui/material/styles'

export const BoxBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  '& p': {
    marginTop: 0
  },
  '& span:not(.MuiChip-label)': {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginRight: 7
  }
}))

const BoxCardStyled = styled('div')({
  backgroundColor: '#FFFFFF',
  padding: '24px',
  '& h5': {
    fontSize: 14
  }
})

const BoxHeaderStyled = styled('div')(({ theme }) => ({
  width:'100%',
  paddingBottom: 16,
  fontSize: 14,
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& img': {
      paddingRight: 10
    },
    '& .MuiChip-root': {
      height: 20,
      marginBottom: 0,
      marginLeft: 8
    },
    '& .info': {
      color: theme.palette.primary.light,
      fontWeight: 700,
      marginLeft: 8,
    }
  }
}))

interface BoxBottomProps {
  toggle?: boolean
}

const BoxBottomStyled = styled('div')<BoxBottomProps>(({ theme }) => ({
  backgroundColor: '#F5F5F5',
  borderTop: '1px solid #d9d9d9',
  borderBottom: '1px solid #d9d9d9',
  display: 'flex',
  padding: '24px 40px',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  gap: 28,
  '& .MuiButton-root': {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    '& path': {
      fill: theme.palette.primary.light
    }
  }
}))

const BoxOrderItemsStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  '& div#questionsOrder': {
    fontWeight: 600
  },
  '& > svg > path': {
    fill: '#9F9F9F',
    height: 18,
    width: 23
  }
})

const BoxValueItemStyled = styled('div')({
  '& .MuiBox-root': {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  '& .MuiFormControl-root': {
    width: '87px'
  }
})

const BoxContentItemsStyled = styled('div')({
    backgroundColor: 'rgb(240 243 244 / 40%)',
    margin: '0 -24px',
    padding: '24px 24px 20px',
    display: 'flex',
    flexWrap: 'wrap',
})

const BoxCard = ({ children }: { children: ReactNode }) => {
  return (
    <BoxCardStyled className='boxCard'>{children}</BoxCardStyled>
  )
}

export const BoxHeader = ({ children }: { children: ReactNode }) => {
  return (
    <BoxHeaderStyled>{children}</BoxHeaderStyled>
  )
}

export const BoxContentItems = ({ children }: { children: ReactNode }) => {
  return (
    <BoxContentItemsStyled>{children}</BoxContentItemsStyled>
  )
}

export const BoxBottom = ({ children }: { children: ReactNode }) => {
  return (
    <BoxBottomStyled>{children}</BoxBottomStyled>
  )
}

export const BoxOrderItems = ({ children }: { children: ReactNode }) => {
  return (
    <BoxOrderItemsStyled>{children}</BoxOrderItemsStyled>
  )
}

export const BoxValueItem = ({ children }: { children: ReactNode }) => {
  return (
    <BoxValueItemStyled>{children}</BoxValueItemStyled>
  )
}


export default BoxCard
