// React
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import {
  Avatar,
  Box,
  // Button,
  Grid,
  LinearProgress,
  Pagination,
  Skeleton,
  Typography
} from '@mui/material'
import BackButton from '../components/button/Button'
import RenderWithoutStudent from '../components/Books/render/RenderWithoutStudent'
import RenderStudent from '../components/Books/render/RenderStudent'
import SimpleSelect from '../components/select/SimpleSelect'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { getBooksStudentsPerformanceRequest } from '../store/books/actions'
import { classroomFetchRequest } from '../store/questions/actions'

// Utils
import { styled } from '@mui/material/styles'
import { scrollToTop, stringAvatar } from '../utils/functions'
import { IStudentsPerformance } from '../models/IBooks'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
// import { ReactComponent as ExportIcon } from '../assets/components/Books/export-icon.svg'
import GeneralVisionIcon from '../assets/components/Books/general-vision-icon.svg'

const Header = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  gap: '16px',
  justifyContent: 'space-between',
  marginBottom: '32px',

  '& .title': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',

    '& h2': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
    }
  },

  '& .export_button': {
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%',
    minWidth: 'auto',
    padding: '14.5px 27.2px',
    textTransform: 'capitalize'
  }
}))

const BoxFilter = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px'
  }
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  marginTop: '40px',

  '& .students_container': {
    flex: 1,

    '& .general_vision': {
      alignItems: 'center',
      display: 'flex',
      gap: '16px',

      '& h3': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        letterSpacing: '0.18px'
      }
    },

    '& .students': {
      marginTop: '32px',

      '&_table-header': {
        marginBottom: '16px',

        '& .cell': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 600,
          letterSpacing: '0.14px'
        }
      },

      '&_table-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }
    }
  },

  '& .student_container': {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '16px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-start',
    padding: '32px 16px'
  },

  '& .pagination_container': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px'
  }
}))

const StudentContainer = styled(Box)<any>(({ theme, selected }) => ({
  background: selected ? theme.palette.primary.main : '#fff',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px 16px',
  transition: 'background 0.3s ease-in-out',

  '&:hover': {
    background: !selected && '#f7f7f7'
  },

  '& .cell': {
    alignItems: 'center',
    display: 'flex'
  },

  '& .datasheet': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    maxWidth: '340px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&_description': {
      maxWidth: '340px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '& p': {
        lineHeight: '120%',
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&.name': {
          color: selected ? '#fff' : theme.palette.text.primary,
          fontSize: '16px',
          fontWeight: 600,
          marginBottom: '4px'
        },

        '&.education_info': {
          color: selected ? '#fff' : '#9f9f9f',
          fontSize: '14px',
          fontWeight: 400
        }
      },
    }
  },

  '& .performance': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '&_linear-progress': {
      backgroundColor: '#e1e1e1',
      width: '54px'
    },

    '&_value': {
      color: selected ? '#fff' : '#9f9f9f',
      fontSize: '14px',
      lineHeight: '120%'
    }
  },

  '& .number_of_books': {
    color: selected ? '#fff' : '#9f9f9f',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '120%',
    textAlign: 'center',
    width: '100%'
  }
}))

const BtnContainer = styled(Box)(({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '32px'
}))

interface IStudentProps {
  student: IStudentsPerformance
  handleSelectStudent: (id: number) => void
  selected: boolean
}

const Student: React.FC<IStudentProps> = ({ student, handleSelectStudent, selected }) => {
  return (
    <StudentContainer
      selected={selected}
      onClick={() => handleSelectStudent(student.user.id)}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={6} className='cell'>
          <Box className='datasheet'>
            <Avatar
              alt={student.user.name}
              variant='rounded'
              {...stringAvatar(student.user.name, 40, 40, 14)}
            />

            <Box className='datasheet_description'>
              <Typography
                className='name'
                title={student?.user?.name}
              >
                {student.user.name}
              </Typography>

              {student.school_classroom?.map((item) => {
                return (
                  <Typography
                    key={item.grade.id}
                    className='education_info'
                  >
                    {item?.grade?.name} / {item?.school_unit?.name}
                  </Typography>
                )
              })}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} className='cell'>
          <Box className='performance'>
            <LinearProgress
              className='performance_linear-progress'
              color={student.performance >= 50 ? 'secondary' : 'error'}
              value={student.performance ?? 0}
              variant='determinate'
            />
            <Box
              className='performance_value'
              component='span'
            >
              {student.performance ? student.performance.toFixed(2) : '000'}%
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} className='cell'>
          <Box className='number_of_books'>
            {student.access}
          </Box>
        </Grid>
      </Grid>
    </StudentContainer>
  )
}

interface IOption {
  id: number
  name: string
}

interface IStudentOption extends IOption {
  classroom: string
}

interface IClassroomOption extends IOption {
  students: IStudentOption[]
}

const BooksReportsStudentsPerformanceContainer = () => {
  const [studentData, setStudentData] = useState({} as IStudentsPerformance)
  const [studentId, setStudentId] = useState<number | null>(null)
  const [unitSelected, setUnitSelected] = useState<IOption | null>(null)
  const [unitOptions, setUnitOptions] = useState<IOption[]>([])
  const [classroomOptions, setClassroomOptions] = useState<IClassroomOption[]>([])
  const [classroomSelected, setClassroomSelected] = useState<IClassroomOption | any>(null)
  const [studentOptions, setStudentOptions] = useState<IStudentOption[]>([])
  const [studentSelected, setStudentSelected] = useState<IStudentOption | any>(null)
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user: { units } } = useAppSelector(state => state.auth)
  const { classrooms } = useAppSelector(state => state.questions)
  const { studentsPerformance: { pagination, items, isFetching }} = useAppSelector(state => state.books.reports)

  const loadUnitOptions = () => {
    const options = units?.map(unit => ({
      id: unit.id,
      name: unit.name
    }))
    setUnitOptions(options ?? [])
  }

  const loadClassroomOptions = () => {
    const classroomOptions = classrooms.items?.map((classroom: any) => ({
      id: classroom?.id,
      name: classroom?.name,
      students: classroom?.students?.map((student: any) => ({
        id: student.id,
        name: student.name,
        classroom: student.classroom_name
      }))
    }))

    const studentOptions = classroomOptions?.reduce((acc: IStudentOption[], item) => {
      item?.students?.forEach((student: any) => acc.push(student))
      return acc
    }, [])

    setClassroomOptions(classroomOptions)
    setStudentOptions(studentOptions)
  }

  useEffect(() => {
    scrollToTop()
    dispatch(getBooksStudentsPerformanceRequest({ school_unit_ids: String(units?.map(unit => unit.id)) }))
    dispatch(classroomFetchRequest({ unitIds: units?.map(unit => unit.id) }))
    loadUnitOptions()
  }, [units])

  useEffect(() => {
    loadClassroomOptions()
  }, [classrooms.items])

  const handleUnitChange = (event: any) => {
    setStudentId(null)
    setStudentData({} as IStudentsPerformance)
    setUnitSelected(event)
    setPage(1)
    let params: any
    if (event?.id) {
      params = {
        school_unit_ids: event?.id,
        school_classroom_id: classroomSelected?.id,
        page: 1
      }
    } else {
      params = {
        school_unit_ids: String(units?.map(unit => unit.id))
      }
    }
    dispatch(getBooksStudentsPerformanceRequest(params))
    dispatch(classroomFetchRequest({ unitIds: params?.school_unit_ids }))
  }

  const handleClassroomChange = (event: any) => {
    setStudentId(null)
    setStudentData({} as IStudentsPerformance)
    setClassroomSelected(event)
    let params: any
    if (event?.id) {
      params = {
        school_classroom_id: event?.id,
        school_unit_ids: unitSelected?.id,
        page: 1
      }
      const students = classroomOptions.find((item) => item.id === event?.id)?.students
      setStudentOptions(students ?? [])
    } else {
      params = {
        school_classroom_id: null,
        school_unit_ids: unitSelected?.id ?? String(units?.map(unit => unit.id)),
        page: 1
      }

      const studentOptions = classroomOptions?.reduce((acc: IStudentOption[], item) => {
        item?.students?.forEach((student: any) => acc.push(student))
        return acc
      }, [])
      setStudentOptions(studentOptions)
    }
    dispatch(getBooksStudentsPerformanceRequest(params))
  }

  const handleStudentChange = (event: any) => {
    setStudentId(null)
    setStudentData({} as IStudentsPerformance)
    setStudentSelected(event)
    let params: any
    if (event?.id) {
      params = {
        user_users_id: event?.id
      }
    } else {
      params = {
        user_users_id: null
      }
    }
    dispatch(getBooksStudentsPerformanceRequest(params))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    dispatch(getBooksStudentsPerformanceRequest({ page: value }))
  }

  const handleBackPage = () => {
    navigate('/books/reports')
  }

  const handleSelectStudent = (id: number) => {
    setStudentId(null)
    setStudentData({} as IStudentsPerformance)
    setStudentId(id)
    const result = items.find(student => student.user.id === id)
    result && setStudentData(result)
  }

  useEffect(() => {
    studentSelected && scrollToTop()
  }, [studentSelected])

  return (
    <>
      <Box>
        <Header>
          <Box className='title'>
            <img src={BookIcon} />
            <Typography
              component='h2'
            >
              Livros / Desempenho dos alunos
            </Typography>
          </Box>

          {/* <Button
            type='button'
            variant='contained'
            className='export_button'
            startIcon={<ExportIcon />}
          >
            Exportar Relatório
          </Button> */}
        </Header>

        <Box>
          <Grid container columnSpacing={4}>
            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='school'>Escola</label>
                <SimpleSelect
                  className='school'
                  placeholder='Escola...'
                  hasBackground
                  hasBorder
                  isClearable
                  maxWidth='100%'
                  onChange={handleUnitChange}
                  options={unitOptions}
                  value={unitSelected}
                />
              </BoxFilter>
            </Grid>

            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='classroom'>Turma</label>
                <SimpleSelect
                  className='classroom'
                  placeholder='Turma...'
                  hasBackground
                  hasBorder
                  maxWidth='100%'
                  isClearable
                  onChange={handleClassroomChange}
                  options={classroomOptions}
                  value={classroomSelected}
                />
              </BoxFilter>
            </Grid>

            <Grid item xs={4}>
              <BoxFilter>
                <label htmlFor='student'>Aluno</label>
                <SimpleSelect
                  className='student'
                  placeholder='Aluno...'
                  hasBackground
                  hasBorder
                  maxWidth='100%'
                  isClearable
                  onChange={handleStudentChange}
                  options={studentOptions}
                  value={studentSelected}
                />
              </BoxFilter>
            </Grid>
          </Grid>
        </Box>

        <Container>
          <Box className='students_container'>
            <Box className='general_vision'>
              <img src={GeneralVisionIcon} />
              <Typography component='h3'>
                Visão Geral
              </Typography>
            </Box>

            <Box className='students'>
              <Grid
                container
                className='students_table-header'
                columnSpacing={3}
              >
                <Grid item xs={6} className='cell'>
                  Aluno
                </Grid>

                <Grid item xs={3} className='cell'>
                  Desempenho
                </Grid>

                <Grid item xs={3} className='cell'>
                  Quant. livros
                </Grid>
              </Grid>

              <Box className='students_table-body'>
                {isFetching && (
                  Array(10).fill(0).map((_, i) => (
                    <Skeleton
                      key={i}
                      animation='wave'
                      variant='rounded'
                      sx={{
                        borderRadius: '8px',
                        height: '56px',
                        width: '100%'
                      }}
                    />
                  ))
                )}
                {!isFetching && (
                  items?.map((student, index) => {
                    return (
                      <Student
                        key={`${student.user.id}-${index}`}
                        student={student}
                        handleSelectStudent={handleSelectStudent}
                        selected={student.user.id === studentId}
                      />
                    )
                  })
                )}
              </Box>

              {pagination && pagination.totalOfPages > 1 && (
                <Box className='pagination_container'>
                  <Pagination
                    count={pagination?.totalOfPages}
                    size='small'
                    color='primary'
                    page={page}
                    onChange={handleChangePage}
                    className='pagination'
                  />
                </Box>
              )}
            </Box>
          </Box>

          {isFetching && (
            <Box sx={{ flex: 1 }}>
              <Skeleton
                animation='wave'
                variant='rounded'
                sx={{
                  borderRadius: '16px',
                  height: '313px',
                  width: '100%'
                }}
              />
            </Box>
          )}

          {!isFetching && (
            <Box className='student_container'>
              {!studentId && (<RenderWithoutStudent />)}
              {studentId && Object.keys(studentData).length > 0 && (
                <RenderStudent studentDetail={studentData} />
              )}
            </Box>
          )}
        </Container>
      </Box>

      <BtnContainer>
        <BackButton
          type='button'
          variant='contained'
          onClick={handleBackPage}
        >
          Voltar
        </BackButton>
      </BtnContainer>
    </>
  )
}

export default BooksReportsStudentsPerformanceContainer
