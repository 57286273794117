import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress, Pagination, Typography } from '@mui/material'
import SelectInput from '../../select/SelectInput'
import ContentsCard from '../../Contents/ContentCard'
import CheckboxInput from '../../input/CheckboxInput'
import RadioButton from '../../button/RadioButton'
import { scrollToTop } from '../../../utils/functions'
import { ReactComponent as NoOptionsImage } from '../../../assets/components/Content/noOptions-image.svg'
import { IContent } from '../../../models/IContent'

import {
  contentsItemFetchRequest,
  schoolContentsItemsFetchRequest
} from '../../../store/contents/actions'
import NoItemsCard from '../../card/NoItemsCard'

const HeaderList = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 24,
  '& p': {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  '& span': {
    fontSize: 14
  },
  '& .selectPage': {
    alignItems: 'center',
    display: 'flex',
    gap: 9
  }
}))

const ContentsList = ({
  contents,
  associateContent,
  selectAllItems,
  isAllItemsSelected,
  selectQuestion,
  selectedQuestions,
  isSchoolQuestions,
  isSelectable,
  isAssociable,
  contentsIds,
  page,
  perPage,
  setPage,
  setPerPage,
  isToActivity
}: any) => {
  const dispatch = useAppDispatch()
  const { selectedUnit } = useAppSelector(state => state.persistable)

  useEffect(() => {
    setPage(1)
    isSchoolQuestions
      ? dispatch(schoolContentsItemsFetchRequest({ per: perPage, page: 1 }))
      : dispatch(contentsItemFetchRequest({ per: perPage, page: 1 }))
  }, [perPage, selectedUnit])

  const handleGetContentsItems = (params: object) => {
    isSchoolQuestions
      ? dispatch(schoolContentsItemsFetchRequest(params))
      : dispatch(contentsItemFetchRequest(params))
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    handleGetContentsItems({ page: value })
    scrollToTop()
  }

  const handleChangePerPage: React.ChangeEventHandler<HTMLInputElement> = event => {
    setPerPage(Number(event.target.value))
  }

  if (contents.isFetching) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <HeaderList>
        <Typography component='p' variant='body2'>{contents.pagination?.total} conteúdo(s) listado(s)</Typography>
        {isSelectable &&
          <CheckboxInput
            name='select_content_all'
            label='Selecionar todos os conteúdos'
            labelPlacement='end'
            onChange={selectAllItems('content')}
            checked={isAllItemsSelected('content')}
          />
        }
        <div className='selectPage'>
          <Typography component='div' variant='body2'>Exibir:</Typography>
          <SelectInput
            name='selectPage'
            defaultValue={perPage}
            onChange={handleChangePerPage}
            width='150px'
            options={[
              { label: '1 por página', value: 1 },
              { label: '5 por página', value: 5 },
              { label: '10 por página', value: 10 },
              { label: '15 por página', value: 15 },
            ]}
          />
        </div>
      </HeaderList>
      {isSelectable && (
        <Box>
        <Typography 
              sx={{
                color:'#ff0000 !important',
                fontSize:'12px',
                marginBottom: '16px'
              }}
            >
                Selecione pelo menos um item para avançar. *
            </Typography>
        </Box>
      )}
      {contents?.items && !isEmpty(contents.items)
        ? contents.items.map((content: IContent) => {
          return (
            <Box key={content.id} sx={{ marginBottom: '16px', border: '1px solid #d9d9d9', borderRadius: '16px', overflow: 'hidden' }}>
              <ContentsCard
                key={`${content.name}-${content.id}`}
                content={content}
                isAssociable={isAssociable}
                isToActivity={isToActivity}
                showSettings
                selectContentCheck={
                  isAssociable ?
                    <RadioButton
                      label='Associar'
                      value={content.id}
                      checked={contentsIds === content.id}
                      onChange={associateContent}
                    />
                    : isSelectable ?
                      <CheckboxInput
                        value={content.id}
                        name='select_content'
                        label='Selecionar'
                        labelPlacement='end'
                        onChange={(event: any) => selectQuestion(event, content)}
                        checked={!!selectedQuestions?.byId[content.id]}
                      />
                      : null
                }
              />
            </Box>
          )
        })
        : contents?.items && isEmpty(contents.items) && Object.keys(contents.filters.query).length > 2
          ? (
            <NoItemsCard>
              <NoOptionsImage />
              <h3>Nenhum conteúdo encontrado.</h3>
            </NoItemsCard>
          ) : (
            <NoItemsCard>
              <NoOptionsImage />
              <h3>Você ainda não possui conteúdo listados</h3>
              <p>Para listar conteúdos preencha os <span>filtros de pesquisa</span> no canto esquerdo superior</p>
            </NoItemsCard>
          )
      }
      {!contents.isFetching && contents.pagination &&
        contents.pagination?.totalPages > 1 && (
          <Pagination
            count={contents.pagination?.totalPages}
            size='small'
            color='primary'
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          />
        )}
    </>
  )
}

export default ContentsList
