import React from 'react'
import TeacherForm from '../components/Management/form/TeacherForm'


function TeacherFormCreateContainer() {
  return (
    <>
      <TeacherForm />
    </>
  )
}

export default TeacherFormCreateContainer
