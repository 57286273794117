import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TableContainer = styled(Grid)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '8px',
  padding: '8px 24px',

  '& .grid_item': {
    display: 'flex',
    alignItems: 'center',

    '&:not(&:first-of-type)': {
      justifyContent: 'center'
    }
  },

  '& .student_name': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    maxWidth: '100%',

    '& p': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '120%',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },

  '& .student_performance, & .student_progress': {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    '& .linear_progress': {
      width: '48px',
    },

    '& p': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
    }
  },

  '& .student_time': {
    '& > p': {
      background: theme.palette.primary.light,
      borderRadius: '8px',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',
      padding: '4px 16px',
    }
  }
}))
