import { AxiosResponse } from 'axios'
import {
  booksChangePagination,
  getBooksDeletedByUserFailure,
  getBooksDeletedByUserRequest,
  getBooksDeletedByUserSuccess
} from './actions'
import {
  all,
  call,
  put,
  takeLatest
} from 'redux-saga/effects'
import * as services from './service'

export function* getBooksDeletedByUserSaga({ payload }: any) {
  try {
    const params = {
      page: payload?.page,
      per: payload?.per
    }
    const response: AxiosResponse = yield call(services.getBooksDeletedByUserService, { params })
    const pagination = {
      totalPages: Number(response.headers['x-total-pages']),
      current: Number(response.headers['x-page']),
      total: Number(response.headers['x-total-count'])
    }
    if (response.status === 200) {
      yield put(getBooksDeletedByUserSuccess(response.data))
      yield put(booksChangePagination(pagination))
    }
  } catch (error) {
    yield put(getBooksDeletedByUserFailure())
  }
}

export function* watchBooksDeletedSaga() {
  yield takeLatest(getBooksDeletedByUserRequest.type, getBooksDeletedByUserSaga)
}

export default function* booksDeletedSaga() {
  yield all([
    watchBooksDeletedSaga()
  ])
}
