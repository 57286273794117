// React
import React, { useEffect, useState } from 'react'

// Components
import {
  Box,
  CircularProgress,
  Paper,
  Typography
} from '@mui/material'
import BookInformations from '../BookInformations'
import Btn from '../../button/Button'
import ChapterItem from '../items/ChapterItem'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { publishBookRequest } from '../../../store/books/actions'

// Utils
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'

interface BookScheduleFormProps {
  activeTab: number
  prevTab: () => void
}

const BookScheduleForm: React.FC<BookScheduleFormProps> = ({
  activeTab,
  prevTab
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { bookToPublish, currentItem } = useAppSelector(state => state.books)
  const [dates, setDates] = useState<any[]>([])
  const [error, setError] = useState(false)

  const handleChangeChapterDate = (chapterId: number, classroomChapterId: number, key: string, value: Date) => {
    const isDate = dates.some((item) => item.chapterId === chapterId)
    if (!isDate) {   // add chapter
      setDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: [{
            id: classroomChapterId,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }]
        }
      ])
    } else {  // update chapter
      const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
      const update = [...dates]
      const classroomChapterIndex = update?.[chapterIndex]?.classroom_chapters?.findIndex((classroomChapter: any) => classroomChapter.id === classroomChapterId)
      if (classroomChapterIndex === -1) {  // add classroom_chapter
        update[chapterIndex].classroom_chapters = [
          ...update[chapterIndex].classroom_chapters,
          {
            id: classroomChapterId,
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`,
            classroom_modules: []
          }
        ]
      } else {  // update classroom_chapter
        update[chapterIndex].classroom_chapters[classroomChapterIndex] = {
          ...update[chapterIndex].classroom_chapters[classroomChapterIndex],
          [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
        }
      }
      setDates(update)
    }
  }

  const handleChangeModuleDate = (
    chapterId: number,
    classroomChapterId: number,
    moduleId: number,
    key: string,
    value: Date
  ) => {
    const chapterIndex = dates.findIndex((item) => item.chapterId === chapterId)
    if (chapterIndex === -1) {   // add module
      setDates((prevState: any) => [
        ...prevState,
        {
          chapterId,
          classroom_chapters: [
            {
              id: classroomChapterId,
              classroom_modules: [{
                id: moduleId,
                [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
              }]
            }
          ]
        }
      ])
    } else {
      const update = [...dates]
      const classroomChapterIndex = update?.[chapterIndex]?.classroom_chapters?.findIndex((item: any) => item.id === classroomChapterId)
      if (classroomChapterIndex === -1) {
        update[chapterIndex].classroom_chapters = [
          ...update[chapterIndex].classroom_chapters,
          {
            id: classroomChapterId,
            classroom_modules: [{
              id: moduleId,
              [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
            }]
          }
        ]
      } else {
        const moduleIndex = update?.[chapterIndex]?.classroom_chapters?.[classroomChapterIndex]?.classroom_modules?.findIndex((item: any) => item.id === moduleId)
        if (moduleIndex === -1) {
          update[chapterIndex].classroom_chapters[classroomChapterIndex].classroom_modules = [
            ...update[chapterIndex].classroom_chapters[classroomChapterIndex].classroom_modules,
            {
              id: moduleId,
              [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
            }
          ]
        } else {
          update[chapterIndex].classroom_chapters[classroomChapterIndex].classroom_modules[moduleIndex] = {
            ...update[chapterIndex].classroom_chapters[classroomChapterIndex].classroom_modules[moduleIndex],
            [key]: key === 'start_date' ? `${format(value, 'dd/MM/yyyy')} 00:00` : `${format(value, 'dd/MM/yyyy')} 23:59`
          }
        }
      }
      setDates(update)
    }
  }

  // check if there is a date on the chapter
  const hasChapterDate = bookToPublish.book?.chapters?.some((chapter) =>
    chapter?.classroom_chapters?.some((item) => (!!item.start_date || !!item.end_date))
  )

  // check if there is a date on the module
  const hasModuleDate = bookToPublish.book?.chapters?.some((chapter) =>
    chapter?.classroom_chapters?.some((item) => item?.classroom_modules?.some(module =>
      (!!module.start_date || !!module.end_date)
    ))
  )

  useEffect(() => {
    if (hasChapterDate || hasModuleDate) {
      const values = bookToPublish.book?.chapters?.map((item) => {
        return {
          chapterId: item.id,
          classroom_chapters: item.classroom_chapters.map((classroomChapter) => ({
            id: classroomChapter.id,
            start_date: classroomChapter.start_date ? format(new Date(classroomChapter.start_date), 'dd/MM/yyyy HH:mm') : null,
            end_date: classroomChapter.end_date ? format(new Date(classroomChapter.end_date), 'dd/MM/yyyy HH:mm') : null,
            classroom_modules: classroomChapter.classroom_modules.map((module) => ({
              id: module.id,
              start_date: module.start_date ? format(new Date(module.start_date), 'dd/MM/yyyy HH:mm') : null,
              end_date: module.start_date ? format(new Date(module.end_date), 'dd/MM/yyyy HH:mm') : null
            }))
          }))
        }
      })

      const valuesFiltered = values.filter((chapter) => {
        const hasChapterDate = chapter?.classroom_chapters?.some((item) => (!!item.start_date || !!item.end_date))
        const hasModuleDate = chapter?.classroom_chapters?.some((item) =>
          item?.classroom_modules?.some(module =>
            (!!module.start_date || !!module.end_date)
          ))
        return hasChapterDate || hasModuleDate
      })
      setDates(valuesFiltered)
    }
  }, [hasChapterDate, hasModuleDate])

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (dates.length === 0) {
      setError(true)
      return
    }
    dispatch(publishBookRequest({
      dates,
      bookData: {
        id: bookToPublish.book.id,
        name: bookToPublish.book.name,
        description: bookToPublish.book.description,
        subjects: bookToPublish.book?.subjects,
        coauthors: bookToPublish.book?.co_authors,
        status: 'published'
      }
    }))
  }

  if (activeTab !== 2) {
    return null
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Paper sx={{ borderRadius: '16px', padding: '21px 28px' }}>
          <Box>
            <Typography
              sx={{
                color: theme.palette.text.primary,
                fontSize: '18px',
                fontWeight: 600,
                letterSpacing: '0.18px',
                marginBottom: '14px'
              }}
            >
              Seu livro
            </Typography>

            <BookInformations
              bookCover={bookToPublish.book?.photo_url}
              bookTitle={bookToPublish.book?.name}
              bookDescription={bookToPublish.book?.description}
              bookSubjects={bookToPublish.book?.subjects}
              bookAuthors={bookToPublish.book?.authors}
              bookCoauthors={bookToPublish.book?.co_authors}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '18px' }}>
            {bookToPublish.book?.chapters &&
              bookToPublish.book?.chapters.length > 0 &&
              bookToPublish.book?.chapters?.map((chapter, index) => {
                return (
                  <ChapterItem
                    key={chapter.id}
                    chapterData={chapter}
                    chapterIndex={index}
                    dates={dates}
                    addDates={setDates}
                    changeChapterDate={handleChangeChapterDate}
                    changeModuleDate={handleChangeModuleDate}
                  />
                )
              })}
          </Box>

          {error && (
            <Box sx={{ marginTop: '8px' }}>
              <Typography
                align='left'
                sx={{
                  color: theme.palette.error.main,
                  fontSize: '13px',
                  fontWeight: 500
                }}
              >
                Adicione datas em pelo menos 1 capítulo!
              </Typography>
            </Box>
          )}
        </Paper>

        <Box>
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Btn
              type='button'
              variant='outlined'
              onClick={() => prevTab()}
              disabled={bookToPublish.isProcessing}
            >
              Voltar
            </Btn>
            <Btn
              variant='contained'
              type='submit'
              disabled={(bookToPublish.isProcessing || currentItem.isSaving)}
              startIcon={(bookToPublish.isProcessing || currentItem.isSaving) && (
                <CircularProgress size={20} variant='indeterminate' color='inherit' />
              )}
            >
              Publicar
            </Btn>
          </Box>
        </Box>
      </form>
    </div>
  )
}

export default BookScheduleForm
