import React from 'react'
import BooksReportsStudentsPerformanceContainer from '../containers/BooksReportsStudentsPerformanceContainer'

const BooksReportsStudentPerformancePage = () => {
  return (
    <>
      <BooksReportsStudentsPerformanceContainer />
    </>
  )
}

export default BooksReportsStudentPerformancePage
