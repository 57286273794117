import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'
import { IUser } from '../../models/IUser'

export interface AuthCredentials {
  token: string;
}

export interface UpdateCredentialsSuccess {
  credentials: AuthCredentials
}

export const signOutRequest = createAction('auth:SIGN_OUT_REQUEST')
export const signOutSuccess = createAction('auth:SIGN_OUT_SUCCESS')
export const signOutFailure = createAction('auth:SIGN_OUT_FAILURE')

export const updateCredentialsRequest = createAction('auth:UPDATE_CREDENTIALS_REQUEST', withPayloadType<AuthCredentials>())
export const updateCredentialsSuccess = createAction('auth:UPDATE_CREDENTIALS_SUCCESS', withPayloadType<UpdateCredentialsSuccess>())
export const updateCredentialsFailure = createAction('auth:UPDATE_CREDENTIALS_FAILURE')

export const getUserDataRequest = createAction('users:GET_USER_DATA_REQUEST')
export const getUserDataSuccess = createAction('users:GET_USER_DATA_SUCCESS', withPayloadType<IUser>())
export const getUserDataRejected = createAction('users:GET_USER_DATA_REJECTED')

export const getRoleRequest = createAction('users:GET_ROLE_REQUEST')
export const getRoleSuccess = createAction('users:GET_ROLE_SUCCESS', withPayloadType<any>())
export const getRoleRejected = createAction('users:GET_ROLE_REJECTED')

export const clearUnits = createAction('users/CLEAR_UNITS')
export const clearRoles = createAction('users/CLEAR_ROLES')

export const forceUserTempPasswordRequest = createAction('users/FORCE_UPDATE_TEMP_PASSWORD_REQUEST', withPayloadType<object>())
export const forceUserTempPasswordSuccess = createAction('users/FORCE_UPDATE_TEMP_PASSWORD_SUCCESS')
export const forceUserTempPasswordRejected = createAction('users/FORCE_UPDATE_TEMP_PASSWORD_REJECTED')
