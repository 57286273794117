import React from 'react'
import { Box, LinearProgress, Paper, Skeleton, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { useSearchParams } from 'react-router-dom'
import { ButtonLightStyled } from '../button/Button'
import { IClassroom } from '../../models/IClassroom'
import Table, { Columns } from '../table/Table'
import { ReactComponent as StudentsSVG } from '../../assets/icons/classroom.svg'
import { ReactComponent as PeopleSVG } from '../../assets/icons/people.svg'
import { ReactComponent as PencilSVG } from '../../assets/icons/pencil.svg'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ActivityParticipants } from '../../models/IActivities'

const PencilIcon = styled(PencilSVG)({
  marginRight: 8,
  height: 15,
  width: 15,
  '& path': {
    fill: '#D9D9D9'
  }
})

const TableHeadLabel = styled('span')(() => ({
  color: '#9f9f9f',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '120%'
}))

const TableBodyLabel = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '120%'
}))


interface IActivityClassroomsViewProps {
  classrooms: Array<IClassroom>
  activityType?: string
  overview?: Array<ActivityParticipants>
  onClassroomChoosed?: any
  isFetching?: boolean
}

const ActivityClassroomsView: React.FC<IActivityClassroomsViewProps> = ({
  classrooms,
  activityType,
  overview,
  onClassroomChoosed,
  isFetching
}) => {
  const theme = useTheme()

  const averageByActivity = (classroomId: number) => {
    const x: any = overview?.filter((item) => item.school_classroom.id === classroomId)
    const sumNotes = x?.reduce((sum: number, item: ActivityParticipants) => sum + parseFloat(item.participant_score), 0)
    return {
      id: x?.[0]?.school_classroom?.id,
      name: x?.[0]?.school_classroom?.name,
      average: x?.length > 0 ? sumNotes / x.length : 0
    }
  }

  const averageByClassroom = (classroomId: number, numberOfStudents: number) => {
    const x: any = overview?.filter((item) => item.school_classroom.id === classroomId)
    const sumNotes = x?.reduce((sum: number, item: ActivityParticipants) => sum + parseFloat(item.participant_score), 0)
    return {
      id: x?.[0]?.school_classroom?.id,
      name: x?.[0]?.school_classroom?.name,
      average: x?.length > 0 ? sumNotes / numberOfStudents : 0
    }
  }

  const performanceByActivity = (classroomId: number) => {
    const classroomFiltered: any = overview?.filter((item) => item.school_classroom.id === classroomId)
    const sumNotes = classroomFiltered?.reduce((sum: number, item: ActivityParticipants) => sum + (item.performance ?? 0), 0)
    return {
      id: classroomFiltered?.[0]?.school_classroom?.id,
      name: classroomFiltered?.[0]?.school_classroom?.name,
      average: classroomFiltered?.length > 0 ? sumNotes / classroomFiltered.length : 0
    }
  }

  const performanceByClassroom = (classroomId: number, numberOfStudents: number) => {
    const classroomFiltered: any = overview?.filter((item) => item.school_classroom.id === classroomId)
    const sumNotes = classroomFiltered?.reduce((sum: number, item: ActivityParticipants) => sum + (item.performance ?? 0), 0)
    return {
      id: classroomFiltered?.[0]?.school_classroom?.id,
      name: classroomFiltered?.[0]?.school_classroom?.name,
      average: classroomFiltered?.length > 0 ? sumNotes / numberOfStudents : 0
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParam, setSearchParams] = useSearchParams()

  const chooseClassroom = (classroom: any) => {
    setSearchParams({ info: 'students_classroom' })
    const classroomChoosed = { id: classroom?.id }
    localStorage.setItem('classroom', JSON.stringify(classroomChoosed))
    onClassroomChoosed(classroom?.id)
  }

  const columns: Columns[] = [
    {
      key: 'title',
      name: <TableHeadLabel>Turma</TableHeadLabel>,
      width: '25%',
      align: 'left',
      render: (row: IClassroom) => {
        return <>
          <TableBodyLabel onClick={() => chooseClassroom(row)}>{row.name}</TableBodyLabel>
        </>
      }
    },
    {
      key: 'average_by_activity',
      name: <TableHeadLabel>Média Atividade</TableHeadLabel>,
      width: '25%',
      align: activityType === 'exam' ? 'center' : 'left',
      render: (row: IClassroom) => {
        const averageData = averageByActivity(Number(row.id))
        const performanceData = performanceByActivity(Number(row.id))
        const average: number = averageData?.average
        const performance: number = performanceData?.average
        return (activityType === 'exam' ? (
          <>
            <PencilIcon />
            <Box
              component='span'
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%',
                color: average >= 7 && average < 8.5
                  ? theme.palette.primary.light
                  : average >= 8.5
                    ? theme.palette.secondary.main
                    : theme.palette.error.main
              }}
            >
              {average?.toFixed(2)}
            </Box>
          </>
        ) : (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
            <LinearProgress
              sx={{
                width: '100px',
                backgroundColor: '#e0e6e7',
                '.MuiLinearProgress-bar': {
                  backgroundColor: performance >= 50 && performance < 80
                    ? theme.palette.secondary.main
                    : performance >= 80
                      ? theme.palette.primary.light
                      : theme.palette.error.main
                }
              }}
              value={performance ?? 0}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: performance >= 50 && performance < 80
                  ? theme.palette.secondary.main
                  : performance >= 80
                    ? theme.palette.primary.light
                    : theme.palette.error.main
              }}
            >
              {performance?.toFixed(1)} %
            </Box>
          </Box>
        ))
      }
    },
    {
      key: 'average_by_classroom',
      name: <TableHeadLabel>Média Turma</TableHeadLabel>,
      width: '25%',
      align: activityType === 'exam' ? 'center' : 'left',
      render: (row: IClassroom) => {
        const averageData = averageByClassroom(Number(row.id), row.students.length)
        const performanceData = performanceByClassroom(Number(row.id), row.students.length)
        const average: number = averageData?.average
        const performance = performanceData?.average
        return (activityType === 'exam' ? (
          <>
            <PencilIcon />
            <Box
              component='span'
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '120%',
                color: average >= 7 && average < 8.5
                  ? theme.palette.primary.light
                  : average >= 8.5
                    ? theme.palette.secondary.main
                    : theme.palette.error.main
              }}
            >
              {average?.toFixed(2)}
            </Box>
          </>
        ) : (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
            <LinearProgress
              sx={{
                width: '100px',
                backgroundColor: '#e0e6e7',
                '.MuiLinearProgress-bar': {
                  backgroundColor: performance >= 50 && performance < 80
                    ? theme.palette.secondary.main
                    : performance >= 80
                      ? theme.palette.primary.light
                      : theme.palette.error.main
                }
              }}
              value={performance ?? 0}
              variant='determinate'
            />
            <Box
              component='span'
              sx={{
                color: performance >= 50 && performance < 80
                  ? theme.palette.secondary.main
                  : performance >= 80
                    ? theme.palette.primary.light
                    : theme.palette.error.main
              }}
            >
              {performance?.toFixed(1)} %
            </Box>
          </Box>
        ))
      }
    },
    {
      key: 'students',
      name: <TableHeadLabel>Alunos</TableHeadLabel>,
      width: '25%',
      align: 'right',
      render: (row: IClassroom) => {
        return (
          <TableBodyLabel sx={{ fontWeight: 500 }}>
            <PeopleSVG style={{ marginRight: 8 }} />{row?.students?.length}
          </TableBodyLabel>
        )
      }
    },
  ]

  return (
    <Paper sx={{ borderRadius: 4, mt: 4, p: 3 }}>
      {isFetching ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton animation='wave' height={50} width={100} />
            <Skeleton animation='wave' height={50} width={100} />
          </Box>
          <Skeleton animation='wave'  width={'100%'} height={200} />
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='space-between' mb={2}>
            <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
              <StudentsSVG style={{ marginRight: 16 }} />
              Turmas
            </Typography>
            <ButtonLightStyled style={{ cursor: 'pointer' }} onClick={() => setSearchParams({ info: 'students' })} startIcon={<ViewSVG />} variant='outlined'>Visualizar lista completa</ButtonLightStyled>
          </Box>
          <div>
            <Table
              columns={columns}
              data={classrooms}
            />
          </div>
        </>
      )
      }
    </Paper>

  )
}

export default ActivityClassroomsView
