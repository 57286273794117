import React from 'react'
import StudentForm from '../components/Management/form/StudentForm'

function StudentFormCreateContainer() {
  return (
    <>
      <StudentForm />
    </>
  )
}

export default StudentFormCreateContainer
