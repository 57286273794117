import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

interface getSchoolAppIdPayload {
  data: {
    id: number
    name: string
  }[]
}

export const getSchoolAppIdRequest = createAction('appId:GET_SCHOOL_APP_ID_REQUEST')
export const getSchoolAppIdSuccess = createAction('appId:GET_SCHOOL_APP_ID_SUCCESS', withPayloadType<getSchoolAppIdPayload>())
export const getSchoolAppIdRejected = createAction('appId:GET_SCHOOL_APP_ID_FAILURE')
