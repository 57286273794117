import React, { useState } from 'react'
import { Avatar, CircularProgress, Divider, Paper, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { Field, Form } from 'react-final-form'
import Button from '../../button/Button'
import { required, requiredTeacherScore } from '../../../utils/formUtils'
import SimpleEditor from '../../Editor/SimpleEditor'
import { bytesToMB, stringAvatar } from '../../../utils/functions'
import InputWithSlider from '../../input/InputWithSlider'
import { giveTeacherFeedbackRequest } from '../../../store/questions/actions'
import { useAppDispatch } from '../../../store/hooks'
import MediaPreview from '../media/MediaPreview'
import { RootState } from '../../../store/configureStore'
import { useSelector } from 'react-redux'
import EditIcon from '../../../assets/components/Questions/edit-icon.svg'
import PerformanceSwitch from '../../Switch/PerformanceSwitch'

const TeacherFeedbackForm = ({ student, questionValue, statement, item }: any) => {

  const dispatch = useAppDispatch()
  const theme = useTheme()
  const studentAnswer = student?.statement
  const shift = student?.school_classroom?.shift
  const classroom = student?.school_classroom
  const isExam = item?.activity_type === 'exam'
  const { isFetching } = useSelector((state: RootState) => state.questions)
  const [feedBack, setFeedback] = isExam ? useState(!!(student?.teacher_feedback && student?.answer_value)) : useState(!!(student?.teacher_feedback))

  const handleSubmitFeedback = (values: any) => {
    dispatch(giveTeacherFeedbackRequest({
      answer_value: values?.score,
      answerId: student?.id,
      teacher_feedback: values?.teacher_feedback,
      is_correct: values?.is_correct
    }))
  }

  let submit: any
  const giveFeedback = (event: any) => {
    setFeedback(true)
    submit(event)
  }

  return (
    <div>
      <Form
        onSubmit={handleSubmitFeedback}
        initialValues={{
          score: student?.answer_value === null ? questionValue : student?.answer_value,
          teacher_feedback: student?.teacher_feedback,
          is_correct: student?.is_correct
        }}
        render={({ handleSubmit, values }) => {
          submit = handleSubmit
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <>
                  <Paper
                    sx={{
                      borderRadius: '16px',
                      background: (feedBack) ? '#D9D9D9' : '#FFFFFF',
                      mt: 2,
                      mb: '8px',
                      padding: '40px 32px 32px',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: '18px',
                            fontWeight: 600,
                            paddingBottom: '24px'
                          }}
                          component='p'
                        >
                          Nome
                        </Typography>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '16px'
                          }}
                        >
                          {student?.user && <Avatar
                            style={{ width: '56px', height: '56px' }}
                            {...stringAvatar(student ? student.user.name : '')}
                            variant='rounded'
                          />}
                          <Typography
                            sx={{
                              fontSize: '16px',
                              paddingLeft: '8px',
                              fontWeight: '600',
                              color: theme.palette.text.primary
                            }}
                            component='p'
                          >
                            {student?.user?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: '18px',
                            fontWeight: 600,
                            paddingBottom: '24px'
                          }}
                          component='p'
                        >
                          Turma
                        </Typography>
                        <Typography>{classroom?.name}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <Typography
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: '18px',
                            fontWeight: 600,
                            paddingBottom: '24px'
                          }}
                          component='p'>
                          Turno
                        </Typography>
                        <Typography>
                          {(() => {
                            let translatedShift = ''
                            switch (shift) {
                              case 'morning':
                                translatedShift = 'Manhã'
                                break
                              case 'afternoon':
                                translatedShift = 'Vespertino'
                                break
                              case 'night':
                                translatedShift = 'Noturno'
                                break
                              case 'fulltime':
                                translatedShift = 'Integral'
                                break
                              default:
                                translatedShift = 'Sem turno'
                                break
                            }
                            return translatedShift
                          })()}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      sx={{
                        borderBottom: `2px solid ${theme.palette.text.secondary}`,
                        paddingTop: '32px'
                      }} />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#9F9F9F',
                          paddingTop: '24px'
                        }}>
                        Enunciado
                      </Typography>
                      <div
                        style={{
                          color: theme.palette.text.primary,
                          fontSize: '18px',
                          fontWeight: 500,
                          paddingTop: '32px'
                        }}
                        dangerouslySetInnerHTML={{ __html: statement }} />
                    </Box>
                    <Box sx={{ pt: '16px', pb: '25px' }}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#9F9F9F',
                          paddingTop: '24px',
                          pb: '32px'
                        }}>
                        Resposta
                      </Typography>
                      {
                        student?.answer_files?.length === 0 ? (
                          <div
                            style={{
                              fontSize: '18px',
                              fontWeight: 500,
                              color: theme.palette.text.primary,
                              paddingTop: '18px'
                            }}
                            dangerouslySetInnerHTML={{ __html: studentAnswer }}
                          />
                        ) : (
                          <>
                            {student?.answer_files?.map((answer: any) => {
                              const fileSizeInMB = bytesToMB(answer?.file_file_size)
                              const fileType = answer?.file_content_type
                              return (
                                <Box
                                  key={answer?.id}
                                >
                                  <Box>
                                    <MediaPreview
                                      url={answer?.url}
                                      type={fileType}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      paddingTop: '16px',
                                      gap: '4px'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '21px',
                                        letterSpacing: '0em',
                                        textAlign: 'left'
                                      }}
                                    >
                                      Link:
                                    </Typography>
                                    <a
                                      href={answer?.url}
                                      target='_blank'
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: theme.palette.text.primary,
                                      }}
                                      rel='noreferrer'
                                    >
                                      {answer?.url}
                                    </a>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: 700,
                                      lineHeight: '21px',
                                      letterSpacing: '0em',
                                      textAlign: 'left'
                                    }}
                                  >
                                    Tamanho: {fileSizeInMB}MB
                                  </Typography>
                                </Box>
                              )
                            })}
                          </>)
                      }
                    </Box>
                    {isExam && <Box>
                      <Field
                        disabled={feedBack}
                        component={InputWithSlider}
                        label='Nota do aluno'
                        max={questionValue}
                        name='score'
                        placeholder='00.00'
                        step={0.1}
                        type='number'
                        validate={requiredTeacherScore}
                      />

                    </Box>}
                    {
                      !isExam && <Box>
                        <Typography sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 500,
                          pb: '8px'
                        }}>
                          A resposta está:
                        </Typography>
                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                          Incorreta
                          <Field
                            disabled={feedBack}
                            name='is_correct'
                            component={PerformanceSwitch}
                            defaultValue={student && student?.is_correct}
                          />
                          Correta
                        </Box>

                      </Box>
                    }
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 500,
                          color: '#9F9F9F',
                          paddingTop: '24px',
                          paddingBottom: '8px'
                        }}>
                        Comentário/Feedback
                      </Typography>
                      {(feedBack) ? (
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 500,
                            lineHeight: '22px',
                            letterSpacing: '0.01em',
                            textAlign: 'left',
                            color: '#666'
                          }}
                          dangerouslySetInnerHTML={{ __html: values?.teacher_feedback || student?.teacher_feedback }} />
                      ) : (
                        <Field
                          component={SimpleEditor}
                          name='teacher_feedback'
                          placeholder='Enunciado...'
                          searchParam='name'
                          minRows={10}
                          validate={required}
                        />)}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      {
                        (feedBack) ? (
                          <Button
                            onClick={() => setFeedback(false)}
                            variant='contained'
                            sx={{
                              alignSelf: 'flex-end',
                              alignItems: 'center',
                              backgroundColor: theme.palette.secondary.main,
                              background: theme.palette.primary.light,
                              display: 'flex',
                              fontWeight: 500,
                              fontSize: '16px',
                              gap: '11px',
                              marginTop: '32px',
                              marginBottom: '32px',
                              width: 'fit-content',
                            }}
                          ><img src={EditIcon} alt='ícone de editar questão' />
                            Editar
                          </Button>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px'
                            }}
                          >
                            {
                              isFetching && <CircularProgress />
                            }
                            <Button
                              type='submit'
                              variant='contained'
                              disabled={!values.teacher_feedback}
                              onClick={event => giveFeedback(event)}
                              sx={{
                                backgroundColor: theme.palette.secondary.main,
                                marginTop: '32px',
                                marginBottom: '32px',
                                width: 'fit-content',
                                alignSelf: 'flex-end',
                                fontWeight: 500,
                                fontSize: '16px'
                              }}
                            >
                              Salvar
                            </Button>
                          </Box>
                        )}
                    </Box>
                  </Paper>
                </>
              </Box >
            </form>
          )
        }} />
    </div>
  )
}

export default TeacherFeedbackForm
