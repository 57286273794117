import React from 'react'
import BooksDeletedContainer from '../containers/BooksDeletedContainer'

const BooksDeletedPage = () => {
  return (
    <BooksDeletedContainer/>
  )
}

export default BooksDeletedPage
