import React from 'react'
import ActivitiesNewContainer from '../containers/ActivitiesNewContainer'

const ActivitiesNewPage = () => {

  return (
    <div>
      <ActivitiesNewContainer />
    </div>
  )
}

export default ActivitiesNewPage
