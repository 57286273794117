import React from 'react'

import { Avatar, Box, Grid, LinearProgress, Paper, Skeleton, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { ActivityParticipants, Participants } from '../../models/IActivities'
import { ButtonLightStyled } from '../button/Button'
import Table, { Columns } from '../table/Table'
import { stringAvatar } from '../../utils/functions'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as PerformanceSVG } from '../../assets/components/activity/icon-performance.svg'
import { orderBy } from 'lodash'


const paperStyled = {
  borderRadius: 4,
  mt: 4,
  p: 3,
  '& .MuiTable-root': {
    minWidth: '300px'
  },
  '& td': {
    p: 1,
    '&:first-of-type': {
      pl: 2
    },
    '&:last-of-type': {
      pr: 2
    }
  },
  '& span': {
    color: '#04AEC9',
    fontWeight: '500'
  },
  '& .red span': {
    color: '#F69E9E'
  }

}

interface IStudentsPerformanceViewProps {
  participants?: Array<Participants>
  classroomId: number | string | null,
  activityValue?: number | string | null
  overview?: Array<ActivityParticipants>
  activityType?: string
  isFetching?: boolean
}

const StudentsPerformanceView: React.FC<IStudentsPerformanceViewProps> = ({
  participants,
  overview,
  activityValue,
  activityType,
  isFetching
}) => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [seachParam, setSearchParams] = useSearchParams()

  const getScoreByUserId = (userId: number) => {
    const userSelected = overview?.find(user => user.user.id === userId)
    const userScore = userSelected?.participant_score
    return userScore
  }

  const getPerformanceById = (userId: number) => {
    const userSelected = overview?.find(user => user.user.id === userId)
    return userSelected?.performance
  }

  const getColumns = (isLower: boolean) => {
    const columns: Columns[] = [
      {
        key: 'avatar',
        name: '',
        width: '45px',
        render: (row: any) => {
          return <Avatar {...stringAvatar(row.user.name)} variant='rounded' />
        }
      },
      {
        key: 'students',
        name: '',
        render: (row: any) => {
          return <Box display='flex' flexDirection='column' >
            <Typography variant='subtitle1' color='#666' fontWeight={600}>{row.user.name}</Typography>
            <p style={{ margin: '0' }}>{row.school_classroom.name}</p>
          </Box>
        }
      },
      {
        key: 'scores',
        name: '',
        width: '150px',
        render: (row: any) => {
          const score = getScoreByUserId(row.user.id)
          const performance = getPerformanceById(row.user.id)
          return activityType === 'exam'
            ? (
              <Box
                alignItems='center'
                display='flex'
                justifyContent='flex-end'
                gap='2px'
              >
                <Typography
                  color={isLower ? 'error' : 'secondary'}
                  fontSize={16}
                  fontWeight={500}
                >
                  {score ? Number(score).toFixed(1) : 0}
                </Typography>
                <span style={{ color: 'currentcolor', fontWeight: 500 }}>/</span>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500
                  }}
                >
                  {activityValue}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px', width: '100%' }}>
                <LinearProgress
                  sx={{ width: '100%', backgroundColor: '#e0e6e7' }}
                  color={isLower ? 'error' : 'secondary'}
                  value={performance ?? 0}
                  variant='determinate'
                />
                <Typography sx={{ width: '100%' }}>{performance?.toFixed(1) ?? '0.0'} %</Typography>
              </Box>
            )
        }
      }
    ]

    return columns
  }

  const participantsWithScores = participants?.map(participant => {
    const participantOverview = overview?.find(item => item.user.id === participant.user.id)
    let score = null
    if (activityType === 'exam') {
      score = participantOverview?.participant_score ?? 0
    } else {
      score = participantOverview?.performance ?? 0
    }
    return {
      ...participant,
      participant_score: score
    }
  })
  const sortedParticipants = orderBy(participantsWithScores, 'participant_score', 'desc')

  return (
    <Paper sx={paperStyled}>

      {
        isFetching ? (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Skeleton animation='wave' height={50} width={100} />
              <Skeleton animation='wave' height={50} width={100} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '32px'
              }}
            >
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />

              </Box>
              <Box width={'100%'}>
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
                <Skeleton animation='wave' height={'50px'} width={'100%'} />
              </Box>

            </Box>
          </Box>
        ) : (
          <>
            <Box display='flex' justifyContent='space-between' mb={2}>
              <Typography component='h2' sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}>
                <PerformanceSVG style={{ marginRight: 16 }} />
                {activityType === 'exam' ? 'Desempenho Alunos' : 'Performance dos alunos'}
              </Typography>
              <ButtonLightStyled
                onClick={() => setSearchParams({ info: 'students' })}
                startIcon={<ViewSVG />}
                variant='outlined'>
                Visualizar lista completa
              </ButtonLightStyled>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ width: 520 }}>
                <Typography component='p' variant='subtitle2' color='#9F9F9F'>
                  {activityType === 'exam' ? 'Alunos com melhor desempenho' : 'Alunos com maior performance'}
                </Typography>
                <Table
                  columns={getColumns(false)}
                  data={sortedParticipants?.slice(0, 5) || []}
                  withoutHead
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component='p' variant='subtitle2' color='#9F9F9F'>
                  {activityType === 'exam' ? 'Alunos com menor desempenho' : 'Alunos com menor performance'}
                </Typography>
                <Table
                  columns={getColumns(true)}
                  data={sortedParticipants?.slice(-5).reverse() || []}
                  withoutHead
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Paper>
  )
}

export default StudentsPerformanceView
