import React from 'react'
import { Box, Typography } from '@mui/material'
import VideoEmbed from './ContentPLayer'
import FileIcon from '../../assets/components/Content/file-icon.svg'

interface ISchemaContentProps {
  format?: {
    code?: string
  }
  content?: {
    file_name?: string
    original_file_name?: string
    size?: number
    type?: string
    value?: string
  }
}

const SchemaContent: React.FC<ISchemaContentProps> = ({ format, content }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 1, p: 1 }}>
      {(format?.code === 'document' || format?.code === 'url') && (
        <>
          <Box textAlign='center' mb={2}>
            <Box width='85px' height='85px' mb={1} marginX='auto'>
              <img src={FileIcon} alt='ícone de arquivo' style={{ width: '100%', height: '100%' }} />
            </Box>
            <Box>
              <Typography sx={{ fontSize: '14px' }}>{content?.type?.toUpperCase()}</Typography>
              <Typography sx={{ fontSize: '14px' }}>{format?.code.toUpperCase()}</Typography>
            </Box>
          </Box>
          <Box textAlign='center'>
            <Typography sx={{ fontSize: '14px', mb: 1 }}>
              <strong>Link:</strong>{' '}
              <a
                href={content?.value}
                target='_blank'
                rel='noopener noreferrer'
              >
                {content?.value}
              </a>
            </Typography>
            {content?.size && (
              <Typography sx={{ fontSize: '14px' }}>
                <strong>Tamanho: {(content.size / 10 ** 6).toFixed(2)} MB</strong>
              </Typography>
            )}
          </Box>
        </>
      )}
      {format?.code === 'video' && <VideoEmbed urlVideo={content?.value} />}
      {format?.code === 'text' && (
        <>
          <Box sx={{ width: '100%' }}>
            {content?.value && <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content?.value }} />}
          </Box>
        </>
      )}
    </Box>
  )
}

export default SchemaContent
