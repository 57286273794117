import React from 'react'
import { Avatar, AvatarGroup, Box, ClickAwayListener, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { Author } from '../../models/IBooks'
import CloseIcon from '@mui/icons-material/Close'
import AuthorIcon from '../../assets/components/Books/student-icon.svg'
import AuthorsIcon from '../../assets/components/Tooltip/author-icon.svg'
import { stringAvatar } from '../../utils/functions'

interface AuthorTooltipProps {
  authors?: Author[]
  coauthors?: { id: number, name: string }[]
  closeTooltip: () => void
}

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#d9d9d9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    color: '#666666',
    padding: '16px',
  },
}))

const IconBtn = styled('button')({
  background: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background 0.3s ease-in-out',
  '& path': {
    fill: '#9F9F9F'
  },
  '&:hover': {
    background: '#0000000a'
  }
})

const orderedName = (name?: string) => {
  let newName
  if (name && name.includes(',')) {
    const x = name && name.split(', ')
    newName = x && x[1] + ' ' + x[0]
  } else {
    newName = name
  }
  return newName
}

const AuthorTooltip: React.FC<AuthorTooltipProps> = ({ authors, coauthors, closeTooltip }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
        <IconBtn onClick={closeTooltip}>
          <CloseIcon fontSize='small' />
        </IconBtn>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
          <img src={AuthorIcon} alt='ícone de autores' width='14px' height='14px' />
          <Typography component='h3' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '120%' }}>Autores</Typography>
        </Box>
        {authors && authors.map((author: any) => {
          const name = orderedName(author?.name)

          return (
            <Box key={author.id} sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
              {name && <Avatar {...stringAvatar(name, 30, 30)} />}
              <Typography
                component='span'
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '120%'
                }}
              >
                {name}
              </Typography>
            </Box>
          )
        })}
      </Box>

      {coauthors && coauthors.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '32px' }}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
            <img src={AuthorsIcon} alt='ícone de autores' width='18px' height='18px' />
            <Typography component='h3' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '120%' }}>Coautores</Typography>
          </Box>

          {coauthors && coauthors.map((coauthor: any) => {
            const name = orderedName(coauthor?.name)

            return (
              <Box key={coauthor.id} sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                {name && <Avatar {...stringAvatar(name, 30, 30)} />}
                <Typography
                  component='span'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '120%'
                  }}
                >
                  {name}
                </Typography>
              </Box>
            )
          })}

        </Box>
      )}
    </Box>
  )
}

interface AuthorsTooltipProps {
  closeTooltip: () => void
  openTooltip: () => void
  open: boolean
  authors: Author[]
  coauthors?: { id: number, name: string }[]
}

const AuthorsTooltip: React.FC<AuthorsTooltipProps> = ({
  closeTooltip,
  openTooltip,
  open,
  authors,
  coauthors
}) => {
  return (
    <>
      <ClickAwayListener onClickAway={closeTooltip}>
        <div>
          <TooltipStyled
            placement='left-start'
            PopperProps={{
              disablePortal: true,
            }}
            onClose={closeTooltip}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<AuthorTooltip closeTooltip={closeTooltip} authors={authors ?? []} coauthors={coauthors} />}
          >
            <AvatarGroup
              max={3}
              sx={{
                cursor: 'pointer',
                '.MuiAvatarGroup-avatar': {
                  width: '24px',
                  height: '24px',
                  fontSize: '12px',

                  '&.MuiAvatar-root': {
                    border: 'none'
                  }
                }
              }}
              onClick={openTooltip}
            >
              {authors && authors.length > 0 && authors.map((author) => {
                const name = orderedName(author?.name)
                return (
                  <Avatar
                    key={author.id}
                    {...stringAvatar(name ?? '', 30, 30)}
                  />
                )
              })}
            </AvatarGroup>
          </TooltipStyled>

        </div>
      </ClickAwayListener>
    </>
  )
}

export default AuthorsTooltip
