import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import { persistor } from '../configureStore'
import { getHighestRole } from '../../utils/functions'

import * as actions from './actions'

import { selectedRoleFetch } from '../persistable'
import * as services from './services'
import { TakeableChannel } from 'redux-saga'
import { toast } from 'react-toastify'

export function * getUserData () {
  try {
    const response:AxiosResponse<any> = yield call(services.getUserData)
    yield put(actions.getUserDataSuccess(response?.data))
  } catch (error) {
    yield put(actions.getUserDataRejected())
  }
}

export function * getRoles () {
  try {
    const { persistable } = yield select(state => state)
    const params = {
      user_application_id: process.env.REACT_APP_PROJECT_ID || 18
    }
    const { data: { data } } = yield call(services.getRoles, params)
    if (!data.length) {
      yield put(actions.signOutRequest())
    }
    yield put(actions.getUserDataRequest())
    if (data.length && !persistable.selectedRole) {
      yield put(selectedRoleFetch(getHighestRole(data)))
    }
    yield put(actions.getRoleSuccess(data))
  } catch (error) {
    yield put(actions.signOutRequest())
    yield put(actions.getRoleRejected())
  }
}

export function * signOut () {
  try {
    yield call(services.signOut)
    yield put({ type: 'app:RESET_STORE' })
    localStorage.clear()
    yield put(actions.signOutSuccess())
  } catch (error) {
    yield put({ type: 'app:RESET_STORE' })
    yield put(actions.signOutFailure())
  } finally {
    persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
  }
}

export function * updateCredentials (
  { payload }: any
) {
  try {
    const token = payload
    yield put(actions.updateCredentialsSuccess({ credentials: token }))
  } catch (e) {
    yield put(actions.updateCredentialsFailure())
  }
}

export function * updateUserTempPassword ({payload}: any) {
  const requestBody = {
    email: payload.email,
    password: payload.password
  }

  const notifyError = () => toast.error('Erro ao atualizar senha temporária.')
  const notifySuccess = () => toast.success('Senha temporária atualizada!')

  try {
    const response: AxiosResponse = yield call(services.updateUserTempPasswordService, requestBody)
    if (response.status === 200) {
      notifySuccess()
      yield put(actions.forceUserTempPasswordSuccess())
    }
  } catch (error) {
    notifyError()
    yield put(actions.forceUserTempPasswordRejected())
  }
}

// Watchers
export function * watchAuth () {
  yield takeLatest(actions.getUserDataRequest.type, getUserData)
  yield takeLatest(actions.getRoleRequest.type, getRoles)
  yield takeLatest(actions.updateCredentialsRequest.type as unknown as TakeableChannel<unknown>, updateCredentials)
  yield takeLatest(actions.signOutRequest, signOut)
  yield takeLatest(actions.forceUserTempPasswordRequest, updateUserTempPassword)
}

export default function * authSagas () {
  yield all([
    watchAuth()
  ])
}
