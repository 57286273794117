// React
import React, { useEffect, useRef, useState } from 'react'

// Components
import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  LinearProgress,
  Pagination,
  Typography
} from '@mui/material'
import BookStudentCard from '../card/BookStudentCard'
import ChapterStudentCollapse from '../collapse/ChapterStudentCollapse'
import SimpleSelect from '../../select/SimpleSelect'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearBooksByUser, fetchBooksByUserRequest } from '../../../store/books/actions'
import { subjectsFetchRequest } from '../../../store/education'

// Utils
import { styled } from '@mui/material/styles'
import { formatTime, stringAvatar } from '../../../utils/functions'
import { IBookByUser, IBookChapter, IStudentsPerformance } from '../../../models/IBooks'

// Icons
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import ClockIcon from '../../../assets/components/Books/clock-icon.svg'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'

const BoxFilter = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',

  '& label': {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px',
    whiteSpace: 'nowrap'
  }
}))

const RenderStudentContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .datasheet': {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',

    '&_description': {
      '& .student_name': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0.18px',
        marginBottom: '4px'
      },

      '& .student_info-education': {
        color: '#9f9f9f',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0.14px'
      }
    }
  },

  '& .info': {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    marginTop: '16px',

    '&_activities, &_time': {
      alignItems: 'center',
      display: 'flex',
      gap: '4px',
      '& span': {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',

        '&:first-of-type': {
          color: '#9f9f9f'
        },

        '&:last-of-type': {
          color: theme.palette.text.primary
        }
      }
    }
  },

  [theme.breakpoints.down('lg')]: {
    '& .info': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}))

const Divider = styled(Box)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.text.secondary}`,
  marginBlock: '32px',
  marginInline: '-16px'
}))

const BooksContainer = styled(Box)(({ theme }) => ({
  '& .title': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0.18px',
    marginBottom: '24px'
  },

  '& .filters': {
    marginBottom: '16px',
  },

  '& .books': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },

  '& .book_stats': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    '&-title': {
      color: theme.palette.text.primary,
      fontSize: '18px',
      fontWeight: 600,
      letterSpacing: '0.18px',
      marginBottom: '8px'
    },

    '&-progress': {
      alignItems: 'center',
      display: 'flex',

      '& img': {
        marginRight: '8px'
      },

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: '120%',
        marginRight: '16px'
      },

      '& p:first-of-type': {
        fontWeight: 600
      },

      '& p:last-of-type': {
        fontWeight: 500
      },

      '& .linear_progress': {
        marginRight: '16px',
        maxWidth: '250px',
        width: '100%',
      }
    },

    '&-time': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '120%'
      },

      '& .time_box': {
        background: theme.palette.primary.light,
        borderRadius: '8px',
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%',
        marginLeft: '8px',
        padding: '5.5px 18px'
      }
    },

    '&-performance': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',

      '& p': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '120%'
      },

      '& .performance': {
        background: theme.palette.secondary.main,
        borderRadius: '8px',
        color: '#f9f9f9',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '120%',
        padding: '5.5px 18px',
      }
    }
  },

  '& .chapters_stats': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '16px'
  }
}))

const LoadingContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '300px',
  justifyContent: 'center',
  gap: '8px',
  marginTop: '32px',

  '& .label': {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 500
  }
}))

interface IOption {
  id: number
  name: string
}

interface IStudentDetailProps {
  studentDetail: IStudentsPerformance
}

const RenderStudent: React.FC<IStudentDetailProps> = ({ studentDetail }) => {
  const [bookSelected, setBookSelected] = useState<number | null>(null)
  const [book, setBook] = useState({} as IBookByUser)
  const [page, setPage] = useState(1)
  const [subject, setSubject] = useState<IOption | null>(null)
  const [order, setOrder] = useState(null as any)
  const [totalOfPages, setTotalOfPages] = useState<number | null>(null)
  const [currentChapterPage, setCurrentChapterPage] = useState(1)
  const [chapters, setChapters] = useState<IBookChapter[]>([])
  const bookRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const { booksByUser: { items, isFetching, pagination } } = useAppSelector(state => state.books.reports)
  const { subjects: { items: subjectOptions } } = useAppSelector(state => state.education)

  // paginate chapters
  const paginateData = () => {
    const bookChapters = book?.book_chapters ? [...book.book_chapters] : []
    const chapters = bookChapters?.sort((a, b) => a.chapter_order - b.chapter_order)
    if (chapters && chapters.length > 5) {
      setTotalOfPages(Math.ceil(chapters.length / 5))
      const startIndex = (currentChapterPage - 1) * 5
      const endIndex = startIndex + 5
      const data = chapters.slice(startIndex, endIndex)
      setChapters(data)
    } else {
      setChapters(chapters)
    }
  }

  const getBooksByUser = (params: object) => {
    const schoolIds = studentDetail.school_classroom.map(i => i.id)
    dispatch(fetchBooksByUserRequest({
      school_classroom_id: String(schoolIds),
      user_users_id: studentDetail.user.id,
      per: 5,
      ...params
    }))
  }

  const clearAll = () => {
    setBookSelected(null)
    setBook({} as IBookByUser)
    setPage(1)
    setTotalOfPages(null)
    setCurrentChapterPage(1)
    setChapters([])
  }

  // select book
  const handleSelectBook = (id: number) => {
    clearAll()
    setBookSelected(id)
    const result = items.find(book => book.id === id)
    result && setBook(result as any)
    setTimeout(() => {
      bookRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, 400)
  }

  // chapters pagination
  const handleChangeChapterPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentChapterPage(value)
  }

  // books pagination
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    clearAll()
    setPage(value)
    getBooksByUser({ page: value })
  }

  // order filter
  const hanldeOrderChange = (value: { id: string, name: string }) => {
    setOrder(value)
    clearAll()
    let params: any
    if (!value) {
      params = { asc: undefined, desc: true }
    } else if (value?.id === 'desc') {
      params = { asc: undefined, [value?.id]: true }
    } else {
      params = { desc: undefined, [value?.id]: true }
    }
    getBooksByUser(params)
  }

  // subject filter
  const handleSubjectChange = (option: IOption) => {
    setSubject(option)
    clearAll()
    let params: any
    if (!option) {
      params = { education_subject_ids: undefined }
    } else {
      params = { education_subject_ids: option.id }
    }
    getBooksByUser(params)
  }

  useEffect(() => {
    getBooksByUser({ desc: true })
    dispatch(subjectsFetchRequest())
    return () => {
      clearAll()
      dispatch(clearBooksByUser())
    }
  }, [studentDetail.user.id])

  useEffect(() => {
    paginateData()
  }, [book, currentChapterPage])

  return (
    <RenderStudentContainer>
      <Box className='datasheet'>
        <Avatar
          alt={studentDetail.user.name}
          className='datasheet_avatar'
          variant='rounded'
          {...stringAvatar(studentDetail.user.name, 58, 58, 20)}
        />

        <Box className='datasheet_description'>
          <Typography className='student_name'>
            {studentDetail.user.name}
          </Typography>
          {studentDetail.school_classroom?.map((item, index) => (
            <Typography
              key={`${item.grade.id}-${index}`}
              className='student_info-education'
            >
              {item.grade.name} / {item.school_unit.name}
            </Typography>
          ))}
        </Box>
      </Box>

      <Box className='info'>
        <Box className='info_time'>
          <img src={ClockIcon} />
          <Box component='span'>Tempo no(s) livro(s):</Box>
          <Box component='span'>
            {formatTime(studentDetail.time)}
          </Box>
        </Box>
      </Box>

      <Divider />

      <BooksContainer>
        <Typography
          component='h3'
          className='title'
        >
          Selecione o livro que deseja visualizar
        </Typography>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          className='filters'
        >
          <Grid item xs={12} lg={6} className='filters_cell'>
            <BoxFilter>
              <label htmlFor='subject'>Disciplina</label>
              <SimpleSelect
                className='subject'
                placeholder='Selecione...'
                hasBackground
                hasBorder
                maxWidth='100%'
                isClearable
                options={subjectOptions}
                onChange={handleSubjectChange}
                value={subject}
              />
            </BoxFilter>
          </Grid>

          <Grid item xs={12} lg={6} className='filters_cell'>
            <BoxFilter>
              <label htmlFor='order'>Ordernar por:</label>
              <SimpleSelect
                className='order'
                placeholder='Selecione...'
                hasBackground
                hasBorder
                maxWidth='100%'
                isClearable
                options={[
                  { id: 'desc', name: 'Maior performance' },
                  { id: 'asc', name: 'Menor performance' }
                ]}
                value={order}
                onChange={hanldeOrderChange}
              />
            </BoxFilter>
          </Grid>
        </Grid>

        {isFetching && (
          <LoadingContainer>
            <CircularProgress size={20} />
            <Box component='span' className='label'>
              Carregando livros...
            </Box>
          </LoadingContainer>
        )}

        {!isFetching && (
          <Box className='books'>
            {items?.map((book, index) => {
              return (
                <BookStudentCard
                  key={`${book.id}-${index}`}
                  book={book}
                  selected={bookSelected === book.id}
                  handleSelectBook={handleSelectBook}
                />
              )
            })}
          </Box>
        )}

        {pagination && pagination?.totalOfPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Pagination
              count={pagination?.totalOfPages}
              size='small'
              color='primary'
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        )}

        <Collapse in={Boolean(bookSelected)}>
          <Box>
            <Divider />

            <Box className='book_stats'>
              <Typography
                component='h3'
                className='book_stats-title'
              >
                Resultados
              </Typography>

              <Box className='book_stats-progress'>
                <img src={ProgressIcon} />
                <Typography>Progresso</Typography>
                <LinearProgress
                  className='linear_progress'
                  color={book?.progress >= 50 ? 'secondary' : 'error'}
                  value={book?.progress ?? 0}
                  variant='determinate'
                />
                <Typography>{book?.progress?.toFixed(2)}%</Typography>
              </Box>

              <Box className='book_stats-time'>
                <img src={ClockIcon} />
                <Typography>Tempo de leitura</Typography>
                <Box className='time_box'>{formatTime(book.time)}</Box>
              </Box>

              <Box className='book_stats-performance'>
                <img src={ChartIcon} />
                <Typography>Desempenho</Typography>
                <Box className='performance'>{book.performance?.toFixed(2)}%</Box>
              </Box>
            </Box>

            <Box className='chapters_stats' ref={bookRef}>
              {chapters?.map((book) => {
                return (
                  <ChapterStudentCollapse
                    key={book.id}
                    chapterOrder={book.chapter_order}
                    chapterName={book.name}
                    modules={book.content_modules}
                  />
                )
              })}
            </Box>

            {totalOfPages && totalOfPages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Pagination
                  count={totalOfPages}
                  size='small'
                  color='primary'
                  page={currentChapterPage}
                  onChange={handleChangeChapterPage}
                />
              </Box>
            )}
          </Box>
        </Collapse>
      </BooksContainer>
    </RenderStudentContainer>
  )
}

export default RenderStudent
