import React from 'react'
import { Field } from 'react-final-form'
import { Box, Typography } from '@mui/material'
import Label from '../label/Label'
import DialogModal from './DialogModal'
import Input from '../input/Input'
import Button from '../button/Button'
import { validateFilterName } from '../../utils/formUtils'
import FilterSaveImage from '../../assets/components/Books/filterSave-image.svg'

interface IModalSaveFilter {
  isOpen: boolean
  closeModal: () => void
  action?: () => void
  disableAction?: boolean
}

const ModalSaveFilter: React.FC<IModalSaveFilter> = ({
  isOpen,
  closeModal,
  action,
  disableAction
}) => {

  const actionsSaveFilter = (
    <Box display='flex' justifyContent='center' mb={5} gap={2} width='100%'>
      <Button
        type='button'
        variant='outlined'
        onClick={closeModal}
        sx={{ width: 102 }}
      >
        Cancelar
      </Button>
      <Button
        type='button'
        sx={{ width: 102 }}
        onClick={action}
        disabled={disableAction}
      >
        Salvar
      </Button>
    </Box>
  )

  return (
    <>
      <DialogModal
        open={isOpen}
        handleClose={closeModal}
        actions={actionsSaveFilter}
        width='368px'
      >
        <Box textAlign='center' pr={2} pl={2} width='320px'>
          <img src={FilterSaveImage} alt='ícone de salvar filtro' />
          <Label>Nomeie seu filtro</Label>
          <Typography
            sx={{
              color: '#9f9f9f',
              fontSize: '14px',
              fontWeight: 500
            }}
          >
            Digite um nome para o seu filtro
          </Typography>
          <Field
            name='filterName'
            component={Input}
            placeholder='Digite aqui...'
            style={{ alignSelf: 'center', marginTop: 8, width: 237 }}
            validate={validateFilterName}
          />
        </Box>
      </DialogModal>
    </>
  )
}

export default ModalSaveFilter
