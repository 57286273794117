import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Chip, Collapse, Grid, Stack, Typography } from '@mui/material'
import Button from '../../button/Button'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { setModules } from '../../../store/books/actions'
import ModulesFilter from '../../Modules/ModulesFilter'
import NewModulesList from '../../Modules/ModulesList'

const ModuleButton = styled(Button)(({ theme }) => ({
  height: 'fit-content',
  padding: 0,
  transition: 'transform 0.3s ease-in-out',

  '& span': {
    fontSize: '18px',
    fontWeight: 700
  },

  '& .title': {
    color: '#666666',
    marginRight: '4px',
  },

  '& .number_of_modules': {
    color: theme.palette.primary.light,
    marginRight: '8px'
  },
}))

const ChipStyled = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: '4px 8px',

  '& .MuiChip-label': {
    color: '#f0f3f4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '150%'
  },

  '& .MuiSvgIcon-root': {
    color: '#f69e9e',
    backgroundColor: '#f0f3f4',
    borderRadius: '100%',
    fontSize: '15px'
  }
}))

const ModulesList = ({ values, change, isTeacher, userId }: any) => {
  const [seeModules, setSeeModules] = useState(true)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)
  const dispatch = useAppDispatch()
  const { modules } = useAppSelector(state => state)
  const { modulesSelected } = useAppSelector(state => state.books)

  const removeModule = (moduleId: number) => {
    const newListOfModules = modulesSelected?.filter((module: any) => module.id !== moduleId)
    dispatch(setModules(
      newListOfModules?.map((module: any, index: number) => {
        return {
          id: module.id,
          name: module.name,
          order: index + 1
        }
      })
    ))
  }

  return (
    <Box>
      {modulesSelected && modulesSelected.length > 0 && (
        <Box sx={{ marginTop: '33px' }}>
          <ModuleButton variant='text' onClick={() => setSeeModules(prevState => !prevState)}>
            <span className='title'>Módulos Selecionados</span>
            <span className='number_of_modules'>({modulesSelected?.length})</span>
            <KeyboardArrowDownIcon
              fontSize='small'
              className='arrow_icon'
              sx={{
                transform: seeModules ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ModuleButton>

          <Collapse in={seeModules} timeout={300}>
            <Stack direction='row' sx={{ marginTop: '16px', flexWrap: 'wrap', gap: '8px' }}>
              {modulesSelected && modulesSelected.length > 0 && modulesSelected.map((module: any) => {
                return (
                  <ChipStyled
                    key={module.id}
                    label={module.name}
                    onDelete={() => removeModule(module.id)}
                  />
                )
              })}
            </Stack>
          </Collapse>
        </Box>
      )}

      <Box>
        <Typography
          component='h3'
          sx={{
            color: '#666666',
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '120%',
            margin: '32px 0 34px',
          }}
        >
          Adicione os módulos do seu capítulo
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={12} md={3}>
          <ModulesFilter
            isTeacher={isTeacher}
            userId={userId}
            perPage={perPage}
            setPage={setPage}
            change={change}
            values={values}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <NewModulesList
            modules={modules}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            isSelectable
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ModulesList
