// React
import React, { useState } from 'react'

// Components
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material'
import ContentCard from '../../Contents/ContentCard'
import DialogModal from '../../Modal/DialogModal'
import QuestionCard from '../../Questions/QuestionCard'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { clearCurrentItem, fetchModuleByIdRequest } from '../../../store/modules/actions'

// Utils
import { format } from 'date-fns'
import { styled } from '@mui/material/styles'
import { IContentModule } from '../../../models/IBooks'

// Icons
import CalendarIcon from '../../../assets/components/Books/calendar-icon.svg'
import ChartIcon from '../../../assets/components/Books/chart-icon.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ProgressIcon from '../../../assets/components/Books/progress-dark-icon.svg'
import ViewIcon from '../../../assets/components/Books/view-icon.svg'

const ModuleContainer = styled(Box)(({ theme }) => ({
  background: '#f9f9f9',
  borderRadius: '16px',
  margin: '0px 16px',

  '& .module': {
    padding: '16px 24px',

    '&_name': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      gap: '4px',
      justifyContent: 'space-between',

      '& .name': {
        color: theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 600,
      },

      '& .button': {
        height: '18px',
        width: '18px'
      }
    },

    '&_stats': {
      marginTop: '8px',
      '& .date': {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',

        '& p': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%'
        }
      },

      '&-progress, &-performance': {
        alignItems: 'center',
        display: 'flex',
        gap: '6px',

        '& p': {
          color: '#9f9f9f',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '120%'
        },

        '& .linear_progress': {
          maxWidth: '120px',
          width: '100%'
        }
      }
    },

    '&_btn_container': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',

      '& .view_module_btn': {
        color: theme.palette.primary.light,
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '0.12px',
        textDecoration: 'underline',
        textTransform: 'none'
      }
    }
  }
}))

interface IModuleStudentCollapseProps {
  module: IContentModule
}

const ModuleStudentCollapse: React.FC<IModuleStudentCollapseProps> = ({ module }) => {
  const [open, setOpen] = useState(false)
  const [openModule, setOpenModule] = useState(false)
  const dispatch = useAppDispatch()
  const { currentItem } = useAppSelector(state => state.modules)
  const startDate = module.start_date ? format(new Date(module.start_date), 'dd/MM/yyyy') : '--/--/----'
  const endDate = module.end_date ? format(new Date(module.end_date), 'dd/MM/yyyy') : '--/--/----'

  const handleShowModule = () => {
    setOpenModule(prevState => !prevState)
    dispatch(fetchModuleByIdRequest({ id: module.content_module.id }))
  }

  const handleCloseModule = () => {
    setOpenModule(prevState => !prevState)
    dispatch(clearCurrentItem())
  }

  return (
    <>
      <ModuleContainer>
        <Box className='module'>
          <Box
            className='module_name'
            onClick={() => setOpen(prevState => !prevState)}
          >
            <Typography
              component='h3'
              className='name'
            >
              {module?.content_module?.name}
            </Typography>

            <IconButton className='button'>
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </IconButton>
          </Box>

          <Collapse in={open}>
            <Grid
              container
              className='module_stats'
              rowSpacing={2}
              columnSpacing={2}
            >
              <Grid item xs={6}>
                <Box className='date'>
                  <img src={CalendarIcon} />
                  <Typography>Início:</Typography>
                  <Typography>{startDate}</Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className='date'>
                  <img src={CalendarIcon} />
                  <Typography>Final:</Typography>
                  <Typography>{endDate}</Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className='module_stats-progress'>
                  <img src={ProgressIcon} />
                  <Typography>Progresso:</Typography>
                  <LinearProgress
                    className='linear_progress'
                    color={module?.progress >= 50 ? 'secondary' : 'error'}
                    value={module?.progress ?? 0}
                    variant='determinate'
                  />
                  <Typography>{module?.progress ?? 0}%</Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className='module_stats-performance'>
                  <img src={ChartIcon} />
                  <Typography>Desempenho:</Typography>
                  <LinearProgress
                    className='linear_progress'
                    color={module?.performance >= 50 ? 'secondary' : 'error'}
                    value={module?.performance ?? 0}
                    variant='determinate'
                  />
                  <Typography>{module?.performance ?? 0}%</Typography>
                </Box>
              </Grid>
            </Grid>

            <Box className='module_btn_container'>
              <Button
                type='button'
                variant='text'
                startIcon={<img src={ViewIcon} />}
                className='view_module_btn'
                onClick={handleShowModule}
              >
                Visualizar módulo
              </Button>
            </Box>
          </Collapse>
        </Box>

        <DialogModal
          open={openModule}
          handleClose={handleCloseModule}
          width='820px'
          height='520px'
        >
          <div>
            {currentItem.isFetching && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress thickness={2} />
              </Box>
            )}
            {currentItem.item &&
              !currentItem.isFetching &&
              currentItem.item.content_module_items.map((item: { content_item: { question: any } }, key: any) => {
                return (item.content_item.question) ? (
                  <Box
                    key={key}
                    sx={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '16px',
                      marginBottom: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    <QuestionCard
                      question={item.content_item.question}
                    />
                  </Box>
                ) : (
                  <Box
                    key={key}
                    sx={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '16px',
                      marginBottom: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    <ContentCard content={item.content_item} />
                  </Box>
                )
              })}
          </div>
        </DialogModal>
      </ModuleContainer>
    </>
  )
}

export default ModuleStudentCollapse
