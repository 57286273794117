import { ROLES } from './constants'
import { IActivities } from '../models/IActivities'
import { IRole} from '../models/IRole'
import { IUser } from '../models/IUser'

export default class ActivityStatusManager {
  static activityCodes = {
    WAITING: 'waiting',
    OPENED: 'opened',
    CLOSED: 'closed',
    ARCHIVED: 'archived',
    DRAFTED: 'drafted'
  }

  static userProgressCodes = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    FINISHED: 'finished',
    UNFINISHED: 'unfinished',
    TIMED_OUT: 'timed_out'
  }
}

export const canEditActivity = (activity: IActivities, user: IUser, currentRole: IRole | null) => {
  const {
    created_by_user: owner,
    created_by_user_role: ownerRole,
    status,
    draft
  } = activity
  const isOwner = owner?.id === user.id

  if (!isOwner) {
    return false
  }

  if (status !== ActivityStatusManager.activityCodes.WAITING && status !== ActivityStatusManager.activityCodes.DRAFTED && !draft) {
    return false
  }

  if (currentRole && currentRole.name === ROLES.TEACHER.id) {
    if (ownerRole?.name === ROLES.TEACHER.id && !isOwner) {
      return false
    }
  }

  if (currentRole && currentRole.name === ROLES.COORDINATOR.id) {
    if (ownerRole?.name === ROLES.OPERATOR.id) {
      return false
    }
  }

  return true
}
