import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled('div')(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '16px',
  marginTop: '16px',
  padding: '48px 24px',

  '& .header': {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '16px',

    '& .title_container': {
      alignItems: 'center',
      display: 'flex',
      gap: '8px',
      marginRight: '120px',

      '& img': {
        width: '12px'
      },

      '& .title': {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '120%'
      }
    },

    '& .filter_container': {
      alignItems: 'center',
      display: 'flex',
    },

    '& .button_container': {
      flex: 1,
      textAlign: 'right'
    }
  }
}))

export const ButtonStyled = styled(Button)(() => ({
  color: '#B7B2C8',
  border: '1px solid #B7B2C8',
  borderRadius: '8px',
  fontSize: '16px',
  fontWeight: 500,
  height: '48px',
  lineHeight: '120%',
  minWidth: 'fit-content',
  paddingInline: '24px',
  textTransform: 'none'
}))

export const ClassroomsContainer = styled(Box)(({ theme }) => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  marginBottom: '16px',
  padding: '7px 22px',

  '& .checkbox_container': {
    '& .label': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%',

      '&.checked': {
        color: theme.palette.text.primary
      }
    }
  }
}))

export const Label = styled(Typography)(() => ({
  color: '#9f9f9f',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '120%',
  marginBottom: '16px'
}))

export const StudentsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}))

export const Student = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: '#f9f9f9',
  borderRadius: '8px',
  display: 'flex',
  gap: '13px',
  padding: '8px 24px',

  '& .student_data': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '& span:first-of-type': {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '120%'
    },

    '& span:last-of-type': {
      color: '#9f9f9f',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%'
    }
  },

  '& .student_performance': {
    color: theme.palette.secondary.main,
    flex: 1,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.14px',
    textAlign: 'right'
  },

  '& .less_accurate': {
    color: theme.palette.error.main
  }
}))
