
import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { styled, useTheme } from '@mui/material/styles'

type Props = FieldRenderProps<string, any>

const Container = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  'input::placeholder': {
    color: '#cecece !important',
    fontFamily: theme.typography.fontFamily
  },
  '> input': {
    border: '1px solid #cecece',
    borderRadius: '8px',
    padding: '10.53px 32px 10.53px 18px',
    fontSize: '14px',
    width: '100%',
    height: '40px',
    color: '#9F9F9F',
  },
  '& input:focus-visible': {
    outlineColor: '#412A88 !important'
  }
}))

const CepInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
}) => {

  const theme = useTheme()
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    input.onBlur(event)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cepInput = event.target.value.replace(/\D/g, '')
    const formattedCep = cepInput.slice(0, 8)

    if (formattedCep.length >= 5) {
      cepInput = formattedCep.slice(0, 5) + '-' + formattedCep.slice(5)
    }
    input.onChange(cepInput)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && input?.value?.length === 6) {
      event.preventDefault()
      const formattedCep = input?.value?.slice(0, 4)
      input.onChange(formattedCep)
    }
  }

  return (
    <>
      <Container>
        <input
          type='text'
          value={input.value}
          placeholder='00000-000'
          maxLength={9}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      {touched && error && <div style={{ color: theme.palette.error.main, fontSize: '0.75rem', textAlign: 'right', fontWeight: 400 }}>{error}</div>}
      </Container>
    </>
  )
}

export default CepInput
