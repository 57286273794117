import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import {
  financialReportItemsFetchRequest,
  financialReportItemsFetchSuccess,
  financialReportItemsFetchError,
  teacherReportSolvedItemsFetchRequest,
  teacherReportSolvedItemsFetchSuccess,
  teacherReportSolvedItemsFetchError,
  teacherReportItemsFetchRequest,
  teacherReportItemsFetchSuccess,
  teacherReportItemsFetchError,
  activityRankingReportFetchRequest,
  activityRankingReportFetchSuccess,
  activityRankingReportFetchError,
  rankingChangePagination
} from './index'
import * as services from './services'

export function * fetchTeacherReports ({ payload }:AnyAction) {
  try {
    const { id, params, page, qtyPerPage } = payload
    const parsedParams = {
      user_ids: id,
      page: page === undefined ? 1 : page,
      per: qtyPerPage,
      ...params
    }
    const { data: { data } } = yield call(
      services.fetchTeacherReports,
      parsedParams
    )
    yield put(teacherReportItemsFetchSuccess(data))
  } catch (error) {
    yield put(teacherReportItemsFetchError())
  }
}

export function * fetchTeacherReportsSolvedQuestions ({ payload }:AnyAction) {
  try {
    const { id, params, page, qtyPerPage } = payload
    const parsedParams = {
      user_ids: id,
      page: page === undefined ? 1 : page,
      per: qtyPerPage,
      ...params
    }
    const { data: { data } } = yield call(
      services.fetchTeacherReports,
      parsedParams
    )
    yield put(teacherReportSolvedItemsFetchSuccess(data))
  } catch (error) {
    yield put(teacherReportSolvedItemsFetchError())
  }
}

export function * fetchFinancialReports ({ payload }:AnyAction) {
  try {
    const { params, page } = payload
    const parsedParams = {
      page: page === undefined ? 1 : page,
      per: 100,
      ...params
    }
    const { data: { data } } = yield call(
      services.fetchFinancialReports,
      parsedParams
    )
    yield put(financialReportItemsFetchSuccess(data))
  } catch (error) {
    yield put(financialReportItemsFetchError())
  }
}

export function * fetchActivityRankingReport ({ payload }:AnyAction) {
  try {
    const { data: { data }, headers } = yield call(
      services.fetchActivityRankingReport,
      payload
    )

    const pagination = {
      totalOfPages: Number(headers['x-total-pages']),
      current: Number(headers['x-page']),
      total: Number(headers['x-total-count']),
      per: 10
    }

    yield put(activityRankingReportFetchSuccess(data))
    yield put(rankingChangePagination(pagination))
  } catch (error) {
    yield put(activityRankingReportFetchError())
  }
}

export function * watchReports () {
  yield takeLatest(teacherReportItemsFetchRequest.type, fetchTeacherReports)
  yield takeLatest(teacherReportSolvedItemsFetchRequest.type, fetchTeacherReportsSolvedQuestions)
  yield takeLatest(financialReportItemsFetchRequest.type, fetchFinancialReports)
  yield takeLatest(activityRankingReportFetchRequest.type, fetchActivityRankingReport)
}

export default function * reportsSaga () {
  yield all([watchReports()])
}
