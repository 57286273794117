import React from 'react'
import Select from 'react-select'
import { styled } from '@mui/material/styles'

const SelectBox = styled(Select)<any>(({ hasBackground, hasBorder, theme, maxWidth }) => ({
  fontSize: 14,
  maxWidth: maxWidth ?? '200px !important',
  width: '100%',
  '.select__control': {
    background: hasBackground ? '#ffffff' : 'inherit',
    border: hasBorder ? '1px solid #d9d9d9' : 'none',
    borderRadius: '8px',
    outline: 'none',
    boxShadow: 'none',

    '&:hover': {
      border: hasBorder ? '1px solid #d9d9d9' : 'none',
      outline: 'none',
      boxShadow: 'none',
    },

    '.select__value-container': {
      padding: '2px 6px',

      '.select__placeholder': {
        color: hasBackground ? '#d9d9d9' : theme.palette.text.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '120%'
      }
    }
  },

  '& .select__control--is-focused': {
    border: hasBorder ? '1px solid #d9d9d9' : 'none',
  },

  '.select__menu': {
    width: maxWidth ?? '200px',
    zIndex: 88
  }
}))

interface ISimpleSelectProps {
  className?: string
  placeholder?: string
  hasBorder?: boolean
  hasBackground?: boolean
  maxWidth?: string
  onChange?: (e: any) => void
  options?: any[]
  value?: any,
  isClearable?: boolean
}

const SimpleSelect: React.FC<ISimpleSelectProps> = ({
  className,
  placeholder,
  hasBackground,
  hasBorder,
  maxWidth,
  onChange,
  options,
  value,
  isClearable
}) => {
  return (
    <SelectBox
      className={className}
      classNamePrefix='select'
      id={className}
      name='school'
      isSearchable={false}
      isClearable={isClearable}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null
      }}
      loadingMessage={() => 'Carregando...'}
      noOptionsMessage={() => 'Não encontrado'}
      getOptionValue={(option: any) => option.name || option.label}
      getOptionLabel={(option: any) => option.name || option.label}
      options={options}
      value={value}
      onChange={onChange}
      hasBorder={hasBorder}
      hasBackground={hasBackground}
      maxWidth={maxWidth}
      title={value?.name ?? value?.label}
    />
  )
}

export default SimpleSelect
