import { AxiosResponse } from 'axios'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'

import { withPayloadType } from '../../utils/functions'
import { RequestParams  } from '../../utils/types'
import { ISubject  } from '../../models/ISubject'

export interface FetchSubjectPayload {
  params?: RequestParams | undefined
}

export interface FetchExamsQuestionsPayload {
  id: number
}

export interface SetExamFiltersPayload {
  filters: object
}

export interface SaveFiltersPayload {
  params: object
  id: number
}

export interface SavedFiltersResponse {
  data:object
  afterEffect(res:AxiosResponse):void
  handleErrorEffect():void
}

export interface DeleteFiltersPayload {
  id: number
  params: object
}

//  Subject
export const fetchSubjects = createAction('filters/FETCH_SUBJECT', withPayloadType<FetchSubjectPayload>())
export const fetchSubjectsSuccessful = createAction('filters/FETCH_SUBJECT_SUCCESSFUL')
export const fetchSubjectsFailure = createAction('filters/FETCH_SUBJECT_FAILURE')
export const clearSubjects = createAction('filters/CLEAR_SUBJECTS')
export const fetchAgeRangeError = createAction('contents/FETCH_AGE_RANGE_ERROR')

//  Topics
export const clearTopic = createAction('filters/CLEAR_TOPIC')
export const fetchTopics = createAction('filters/FETCH_TOPICS')
export const fetchTopicsSuccessful = createAction('filters/FETCH_TOPICS_SUCCESSFUL')
export const fetchTopicsFailure = createAction('filters/FETCH_TOPICS_FAILURE')

//  Difficulty Levels
export const fetchDifficultyLevels = createAction('filters/FETCH_DIFFICULTY_LEVELS')
export const fetchDifficultyLevelsSuccessful = createAction('filters/FETCH_DIFFICULTY_LEVELS_SUCCESSFUL')
export const fetchDifficultyLevelsFailure = createAction('filters/FETCH_DIFFICULTY_LEVELS_FAILURE')

//  Segments
export const fetchSegments = createAction('filters/FETCH_SEGMENTS')
export const fetchSegmentsSuccessful = createAction('filters/FETCH_SEGMENTS_SUCCESSFUL')
export const fetchSegmentsFailure = createAction('filters/FETCH_SEGMENTS_FAILURE')

// AGE RANGE
export const fetchAgeRangeRequest = createAction('filters/FETCH_AGE_RANGE_REQUEST')
export const fetchAgeRangeSuccess = createAction('filters/FETCH_AGE_RANGE_SUCCESS')

// SAVED FILTERS
export const getSavedFiltersRequest = createAction('filters/GET_SAVED_FILTERS_REQUEST', withPayloadType<{ id: number }>())
export const getSavedFiltersSuccess = createAction('filters/GET_SAVED_FILTERS_SUCCESS', withPayloadType<SavedFiltersResponse>())
export const getSavedFiltersFailure = createAction('filters/GET_SAVED_FILTERS_FAILURE')

export const saveFiltersRequest = createAction('filters/SAVE_FILTERS_REQUEST', withPayloadType<SaveFiltersPayload>())
export const saveFiltersSuccess = createAction('filters/SAVE_FILTERS_SUCCESS')
export const saveFiltersFailure = createAction('filters/SAVE_FILTERS_FAILURE')

export const updateFiltersRequest = createAction('filters/UPDATE_FILTERS_REQUEST', withPayloadType<SaveFiltersPayload>())
export const updateFiltersSuccess = createAction('filters/UPDATE_FILTERS_SUCESS')
export const updateFiltersFailure = createAction('filters/UPDATE_FILTERS_FAILURE')

export const deleteFiltersRequest = createAction('filters/DELETE_FILTERS', withPayloadType<DeleteFiltersPayload>())
export const deleteAllFiltersRequest = createAction('filters/DELETE_ALL_FILTERS', withPayloadType<{ id: number }>())


interface StateType {
  subjects: object
  subject: ISubject[]
  difficulty_levels: object
  segments: object
  ageRange: object
  items: any,
  isFilterSaved: boolean
}

const initialState: StateType = {
  items: {},
  subjects: {
    items: [],
    isFetching: false,
    error: ''
  },
  subject: [],
  difficulty_levels: {
    items: [],
    isFetching: false,
    error: ''
  },
  segments: {
    items: [],
    isFetching: false,
    error: ''
  },
  ageRange: {
    items: [],
    isFetching: false,
    error: ''
  },
  isFilterSaved: false
}

const filtersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchSubjects, (state: StateType): StateType => ({
      ...state,
      subjects: {
        ...state.subjects,
        isFetching: true
      }
    }))
    .addCase(fetchSubjectsSuccessful, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subjects: {
        items: action.payload,
        isFetching: false,
        error: ''
      }
    }))
    .addCase(fetchSubjectsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subjects: {
        ...state.subjects,
        isFetching: false,
        error: action.payload
      }
    }))
    .addCase(clearTopic, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subject: [
        ...state.subject.filter(subjectItem => subjectItem.id !== action.id)
      ]
    }))
    .addCase(fetchTopics, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subject: action.payload.length ? [
        ...state.subject.filter(
          subjectItems => subjectItems.id !== action.payload
        ),
        {
          id: action.payload,
          isFetching: true,
          topics: []
        }
      ] : state.subject
    }))
    .addCase(fetchTopicsSuccessful, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subject: [
        ...state.subject.filter(
          subjectItems => subjectItems.id !== action.payload.id
        ),
        {
          id: action.payload.id,
          isFetching: false,
          topics: action.payload.data
        }
      ]
    }))
    .addCase(fetchTopicsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      subject: [
        ...state.subject.filter(
          subjectItems => subjectItems.id !== action.payload.id
        ),
        {
          id: action.payload.id,
          isFetching: false,
          topics: [],
        }
      ]
    }))
    .addCase(fetchDifficultyLevels, (state: StateType): StateType => ({
      ...state,
      difficulty_levels: {
        ...initialState.difficulty_levels,
        isFetching: true
      }
    }))
    .addCase(fetchDifficultyLevelsSuccessful, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      difficulty_levels: {
        ...initialState.difficulty_levels,
        items: action.payload,
        isFetching: false
      }
    }))
    .addCase(fetchDifficultyLevelsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      difficulty_levels: {
        ...initialState.difficulty_levels,
        isFetching: false,
        error: action.payload
      }
    }))
    .addCase(fetchSegments, (state: StateType): StateType => ({
      ...state,
      segments: {
        ...initialState.difficulty_levels,
        isFetching: true
      }
    }))
    .addCase(fetchSegmentsSuccessful, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      segments: {
        ...initialState.difficulty_levels,
        items: action.payload,
        isFetching: false
      }
    }))
    .addCase(fetchSegmentsFailure, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      segments: {
        ...initialState.difficulty_levels,
        isFetching: false,
        error: action.payload
      }
    }))
    .addCase(fetchAgeRangeRequest, (state: StateType): StateType => ({
      ...state,
      ageRange: {
        ...state.ageRange,
        isFetching: true
      }
    }))
    .addCase(fetchAgeRangeSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      ageRange: {
        ...state.ageRange,
        isFetching: false,
        items: action.payload
      }
    }))
    .addCase(fetchAgeRangeError, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      ageRange: {
        ...state.ageRange,
        isFetching: false,
        error: action.payload
      }
    }))
    .addCase(getSavedFiltersRequest, (state: StateType): StateType => ({
      ...state,
      items: {},
      isFilterSaved: false
    }))
    .addCase(getSavedFiltersSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: action.payload,
      isFilterSaved: false
    }))
    .addCase(getSavedFiltersFailure, (state: StateType): StateType => ({
      ...state,
      items: {},
      isFilterSaved: false
    }))
    .addCase(saveFiltersRequest, (state: StateType): StateType => ({
      ...state,
      isFilterSaved: false
    }))
    .addCase(saveFiltersSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: {
        ...state.items,
        ...action.payload
      },
      isFilterSaved: true
    }))
    .addCase(saveFiltersFailure, (state: StateType): StateType => ({
      ...state,
      isFilterSaved: false
    }))
    .addCase(updateFiltersRequest, (state: StateType): StateType => ({
      ...state,
      isFilterSaved: false
    }))
    .addCase(updateFiltersSuccess, (state: StateType, action: AnyAction): StateType => ({
      ...state,
      items: {
        ...state.items,
        ...action.payload
      },
      isFilterSaved: true
    }))
    .addCase(updateFiltersFailure, (state: StateType): StateType => ({
      ...state,
      isFilterSaved: false
    }))
    .addCase(clearSubjects, (state: StateType): StateType => ({
      ...state,
      subject: initialState.subject
    }))
})

export default filtersReducer
