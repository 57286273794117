/* eslint-disable camelcase */
import http from '../../utils/http'

const studentEndpoint = '/students'

export const createStudentService = (payload: object) => {
  return http.post(studentEndpoint, payload)
}
export const getStudentById = (payload: number) => {
  return http.get(`${studentEndpoint}/${payload}`)
}
export const getAllStudents = ({school_unit_id}: any) => {
  return http.get(`${studentEndpoint}?school_unit_id=${school_unit_id}`)
}
export const updateStudentService = (payload: any) => {
  return http.put(`${studentEndpoint}/${payload?.id}`, payload)
}
export const deleteStudentService = (payload: any) => {
  return http.delete(`${studentEndpoint}/${payload?.user_user_id}`,)
}
