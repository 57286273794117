import React from 'react'
import { Box } from '@mui/system'
import { Skeleton } from '@mui/material'

const LoadingMoveTeachers = () => {

  const loadingSkeletons = Array.from({ length: 5 })
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='42px'
    >
      <Box>
        <Skeleton variant='rectangular' animation='wave' sx={{ height: '20px', width: '300px', borderRadius: '8px' }} />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        {loadingSkeletons.map((_, index) => (
          <Box
            key={index}
            display='flex'
            justifyContent='space-between'
          >
            <Box
              display='flex'
              gap='16px'
            >
              <Skeleton variant='rectangular' animation='wave' sx={{ height: '20px', width: '20px', borderRadius: '50%' }} />
              <Skeleton variant='rectangular' animation='wave' sx={{ height: '20px', width: '200px', borderRadius: '8px' }} />
            </Box>
            <Skeleton variant='rectangular' animation='wave' sx={{ height: '20px', width: '100px', borderRadius: '8px' }} />
          </Box>

        ))}

        <Box
          display='flex'
          gap='32px'
          justifyContent='flex-end'
          sx={{
            paddingTop: '30px'
          }}
        >
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '35px', width: '100px', borderRadius: '8px' }} />
          <Skeleton variant='rectangular' animation='wave' sx={{ height: '35px', width: '100px', borderRadius: '8px' }} />
        </Box>



      </Box>
    </Box>
  )
}

export default LoadingMoveTeachers
