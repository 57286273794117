import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BooksFormContainer from '../containers/BooksFormContainer'
import BooksFormEditContainer from '../containers/BooksFormEditContainer'

const BooksFormPage = () => {
  const { id } = useParams()
  const { state } = useLocation()

  return (
    <div>
      {!id && <BooksFormContainer />}
      {id && <BooksFormEditContainer id={id} isEdit={state?.isEdit} />}
    </div>
  )
}

export default BooksFormPage
