import { createAction, createReducer } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { withPayloadType } from '../../utils/functions'

interface AlertState {
  open: boolean,
  message: string,
  duration?: number,
  severity?: 'error' | 'info' | 'success' | 'warning',
  vertical?: 'bottom' | 'top'
  horizontal?: 'center' | 'left' | 'right'
}

interface ShowAlertMessagePayload {
  message: string | null
  severity: string
  duration?: number
}

export const showAlertMessage = createAction('alert/SHOW_ALERT_MESSAGE', withPayloadType<ShowAlertMessagePayload>())
export const hideAlertMessage = createAction('alert/HIDE_ALERT_MESSAGE')

const initialState: AlertState = {
  open: false,
  message: '',
  duration: undefined
}

const alertReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showAlertMessage, (state: AlertState, action: AnyAction): AlertState => ({
      ...state,
      open: true,
      message: action.payload?.message,
      severity: action.payload?.severity,
      vertical: action.payload?.vertical,
      horizontal: action.payload?.horizontal,
      duration: action.payload?.duration
    }))
    .addCase(hideAlertMessage, (): AlertState => ({
      ...initialState
    }))
})

export default alertReducer
