

import React from 'react'
import ActivityDiscursiveQuestionContainer from '../containers/ActivityDiscursiveQuestionContainer'

const ActivityDiscursiveQuestionPage = () => {
  return (
    <ActivityDiscursiveQuestionContainer />
  )
}

export default ActivityDiscursiveQuestionPage
