import React from 'react'
import { Box, Chip, CircularProgress, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAppDispatch } from '../../store/hooks'
import BoxCard, { BoxHeader, BoxBottom, BoxBody } from '../card/BoxCard'
import Button from '../button/Button'
import DialogModal from '../Modal/DialogModal'
import QuestionCard from '../Questions/QuestionCard'
import { IQuestion } from '../../models/IQuestion'
import barCodeSVG from '../../assets/icons/bar-code.svg'
import { ReactComponent as ViewSVG } from '../../assets/icons/view.svg'
import { ReactComponent as ImportSVG } from '../../assets/icons/import.svg'

const ImportStyled = styled(ImportSVG)(({ theme }) => ({
  '& path': {
    fill: 'none !important',
    stroke: theme.palette.primary.light
  }
}))

const ChipStyled = styled(Chip)(({ theme }) => ({
  borderColor: theme.palette.text.secondary,
  color: theme.palette.text.secondary,
  height: 25
}))

interface ExamsCardProps {
  exam: any,
  importingExamId: number,
  importExamQuestions: any,
  isImporting: boolean,
  currentItem: any,
  fetchExamsQuestions: any,
  clearCurrentItem: any
}

const ExamCard: React.FC<ExamsCardProps> = ({
  exam,
  importExamQuestions,
  importingExamId,
  isImporting,
  currentItem,
  fetchExamsQuestions,
  clearCurrentItem
}) => {
  const dispatch = useAppDispatch()
  return (
    <>
      <BoxCard>
        <BoxHeader>
          <div>
            <img src={barCodeSVG} alt='ícone do código da questão' />
            Código: <span className='info'>{exam.id}</span>
          </div>
        </BoxHeader>
        <Divider sx={{
          borderBottom: '1px dashed #9F9F9F',
          margin: '0 10px 34px -24px',
          width: 'calc(100% + 48px)'
        }} />
        <BoxBody>
          {exam.concourse && <div><span>Concurso:</span>{`${exam.concourse.name} - ${exam.year_of_admission}`}</div>}
          {exam.phase && <div><span>Fase:</span><ChipStyled label={exam.phase.name} variant='outlined' /></div>}
          {exam.segments.length > 0 && <div><span>Segmentos:</span>{exam.segments.map((segment: { id: number, name: string }) => <ChipStyled key={segment.id} label={segment.name} variant='outlined' />)}</div>}
          {exam.season && <div><span>Temporada:</span><ChipStyled label={exam.season.name} variant='outlined' /></div>}
          {exam.subject && <div><span>Disciplina:</span><ChipStyled label={exam.subject.name} variant='outlined' /></div>}
          {exam.area && <div><span>Área:</span><ChipStyled label={exam.area.name} variant='outlined' /></div>}
          {exam.level && <div><span>Dificuldade:</span><ChipStyled label={exam.level.name} variant='outlined' /></div>}
        </BoxBody>
      </BoxCard>
      <BoxBottom>
        <Button
          type='button'
          style={{ marginRight: 10 }}
          variant='outlined'
          startIcon={<ViewSVG />}
          onClick={() => dispatch(fetchExamsQuestions(exam.id))}
        >
          Visualizar conteṹdos
        </Button>
        <Button
          type='button'
          style={{ marginRight: 10 }}
          variant='outlined'
          disabled={importingExamId === exam.id && isImporting}
          startIcon={(importingExamId === exam.id && isImporting) ? <CircularProgress size={18} thickness={2} /> : <ImportStyled />}
          onClick={() => dispatch(importExamQuestions({ id: exam.id }))}
        >
          Importar conteúdos
        </Button>
      </BoxBottom>
      <DialogModal
        open={currentItem.isFetching || (currentItem.examId === exam.id)}
        handleClose={() => dispatch(clearCurrentItem())}
        width='820px'
        height='520px'
      >
        <div>
          {currentItem.isFetching && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress thickness={2} />
            </Box>
          )}
          {currentItem.items &&
            !currentItem.isFetching &&
            currentItem.items.map((question: IQuestion) => (
              <Box
                key={question.id}
                sx={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '16px',
                  marginBottom: '16px',
                  overflow: 'hidden'
                }}
              >
                <QuestionCard
                  question={question}
                />
              </Box>
            ))}
        </div>
      </DialogModal>
    </>
  )
}

export default ExamCard
