import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Components
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  Pagination,
  Skeleton,
  Typography
} from '@mui/material'
import SimpleSelect from '../components/select/SimpleSelect'
import StudentReportCollapse from '../components/Books/collapse/StudentReportCollapse'

// Redux
import {
  classroomsPerformanceRequest,
  clearBooksStudentsPerformance,
  clearClassroomsPerformance,
  clearClassroomStudents,
  clearStudentsPerformance,
  fetchClassroomStudentsRequest,
  fetchStudentsPerformanceRequest,
  getBooksStudentsPerformanceRequest
} from '../store/books/actions'
import { useAppDispatch, useAppSelector } from '../store/hooks'

// Utils
import { scrollToTop, stringAvatar } from '../utils/functions'
import { IBookData } from '../models/IBooks'

// Styles
import {
  Container,
  DashedContainer,
  ErrorMessageBox,
  FiltersContainer,
  // LoadingBox,
  StudentCard,
  StudentsContainer
} from './styles/BooksReportStudentsPerformanceContainer.styles'

// Icons
import BookIcon from '../assets/components/Books/book-icon.svg'
import NotFoundStudentImage from '../assets/components/Books/not_found-image.svg'
// import { ReactComponent as ExportIcon } from '../assets/components/Books/export-icon.svg'

const StudentsRankingLoading = () => {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginBottom: '16px'
        }}
      >
        <Skeleton
          animation='wave'
          variant='rounded'
          sx={{ height: '13px', width: '11px' }}
        />

        <Skeleton
          animation='wave'
          variant='text'
          sx={{ fontSize: '27px', width: '190px' }}
        />
      </Box>

      <Box
        sx={{
          border: `1px dashed #d9d9d9`,
          borderRadius: '16px',
          padding: '32px 22px'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={2} maxWidth='1240px'>
            {Array(6).fill(1).map((_, index) => {
              return (
                <Grid item xs={6} xl={4} key={index}>
                  <Skeleton
                    animation='wave'
                    variant='rounded'
                    sx={{ borderRadius: '16px', maxWidth: '350px', width: '100%', height: '133px' }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

const StudentsListLoading = () => {
  return (
    <Box>
      <Grid container mb={2}>
        {Array(6).fill(0).map((_, index) => {
          return (
            <Grid
              item
              key={index}
              xs={index === 0 ? 4 : index > 3 ? 1 : 2}
            >
              {index !== 5 && (
                <Skeleton
                  animation='wave'
                  variant='text'
                  sx={{
                    fontSize: '1rem',
                    marginLeft: index === 0 ? '20%' : '0px',
                    textAlign: index !== 0 ? 'center' : 'left',
                    maxWidth: '100px',
                    width: '100%'
                  }}
                />
              )}
            </Grid>
          )
        })}
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {Array(6).fill(0).map((_, index) => {
          return (
            <Skeleton
              key={index}
              animation='wave'
              variant='rounded'
              sx={{
                borderRadius: '16px',
                height: '64px',
                width: '100%'
              }}
            />
          )
        })}
      </Box>
    </Box>
  )
}

interface IOption {
  id: number
  name: string
}

const BooksReportStudentsPerformanceContainer = () => {
  const [classroomSelected, setClassroomSelected] = useState<IOption | null>(null)
  const [classroomChosen, setClassroomChosen] = useState<IOption | null>(null)
  const [studentSelected, setStudentSelected] = useState<IOption | null>(null)
  const [totalOfPages, setTotalOfPages] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [studentItems, setStudentItems] = useState<IBookData[]>([])
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useAppDispatch()
  const {
    classrooms_performance: classroomsPerformance,
    students_performance: studentsPerformance,
    classroom_students: classroomStudents
  } = useAppSelector(state => state.books)
  const { items } = useAppSelector(state => state.books.reports.studentsPerformance)

  const classrooms = classroomsPerformance.data?.school_classrooms?.map(item => ({
    id: item.id,
    name: item.name
  }))
  const studentOptions = items?.map(item => item.user)?.sort((a, b) => a?.name.localeCompare(b?.name, 'pt', { sensitivity: 'base' }))

  const paginateData = () => {
    const students = [...classroomStudents.items]?.sort((a, b) => a?.user?.name.localeCompare(b?.user?.name, 'pt', { sensitivity: 'base' }))
    if (students && students.length > 10) {
      setTotalOfPages(Math.ceil(students.length / 10))
      const startIndex = (currentPage - 1) * 10
      const endIndex = startIndex + 10
      const data = students.slice(startIndex, endIndex)
      setStudentItems(data)
    } else {
      setStudentItems(students)
      setTotalOfPages(null)
      setCurrentPage(1)
    }
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  const handleBackPage = () => {
    navigate(`/books/${id}/report`)
  }

  const handleGetStudentsData = (params?: object) => {
    dispatch(fetchClassroomStudentsRequest({
      bookId: Number(id),
      filters: params
    }))
  }

  const handleGetStudentsPerformanceData = (params?: object) => {
    dispatch(fetchStudentsPerformanceRequest({
      bookId: Number(id),
      filters: params
    }))
  }

  const handleChosenClassroom = (option: IOption) => {
    setClassroomChosen(option)
    let params: any
    if (!option) {
      params = { school_classroom_id: null }
    } else {
      params = { school_classroom_id: String(option.id) }
    }
    handleGetStudentsPerformanceData(params)
  }

  const handleSelectClassroom = (option: IOption) => {
    setClassroomSelected(option)
    let params: any
    if (!option) {
      const ids = classrooms?.map(item => item.id)
      params = { school_classroom_id: String(ids) }
    } else {
      params = { school_classroom_id: String(option?.id) }
    }
    handleGetStudentsData(params)
    dispatch(getBooksStudentsPerformanceRequest(params))
  }

  const handleSelectStudent = (option: IOption) => {
    setStudentSelected(option)
    let params: any
    if (!option) {
      params = {
        user_users_id: null
      }
    } else {
      params = { user_users_id: option.id }
    }
    handleGetStudentsData(params)
  }

  useEffect(() => {
    scrollToTop()
    if (id) {
      dispatch(classroomsPerformanceRequest({ id: Number(id) }))
      handleGetStudentsPerformanceData()
    }

    return () => {
      dispatch(clearClassroomsPerformance())
      dispatch(clearStudentsPerformance())
      dispatch(clearClassroomStudents())
      dispatch(clearBooksStudentsPerformance())
      setTotalOfPages(null)
      setCurrentPage(1)
      setStudentItems([])
    }
  }, [])

  useEffect(() => {
    if (classrooms && classrooms.length > 0) {
      const ids = classrooms?.map(item => item.id)
      handleGetStudentsData({
        school_classroom_id: String(ids)
      })

      dispatch(getBooksStudentsPerformanceRequest({
        school_classroom_id: String(ids),
        book_books_id: id,
        per: 999
      }))
    }

  }, [classroomsPerformance.data])

  useEffect(() => {
    paginateData()
  }, [classroomStudents.items, currentPage])

  return (
    <Container>
      <Box className='header'>
        <Box className='book_title'>
          <img src={BookIcon} alt='ícone de livro' />
          <Typography
            component='h2'
            className='title'
          >
            {state?.title ?? classroomsPerformance?.data?.name}
          </Typography>
        </Box>

        <Box className='filter'>
          <Box component='label' htmlFor='classroom'>
            Turma
          </Box>

          <SimpleSelect
            className='classroom'
            placeholder='Pesquise pelo nome da turma...'
            hasBorder
            hasBackground
            maxWidth='292px'
            isClearable
            options={classrooms ?? []}
            onChange={handleChosenClassroom}
            value={classroomChosen}
          />
        </Box>

        {/* <Button
          type='button'
          variant='contained'
          startIcon={<ExportIcon />}
          className='btn_export'
        >
          Exportar Relatório
        </Button> */}
      </Box>

      {studentsPerformance.isFetching && (
        <StudentsRankingLoading />
      )}

      {!studentsPerformance.isFetching && (
        <>
          <Box className='label_container'>
            <img
              src={BookIcon}
              width='11px'
              alt='ícone de livro'
            />
            <Typography
              component='h3'
              className='label'
            >
              Alunos em destaque
            </Typography>
          </Box>

          <DashedContainer>
            <StudentsContainer>
              <Grid container rowSpacing={2} columnSpacing={2} maxWidth='1240px'>
                {studentsPerformance?.data?.high_performance?.map((student, index) => {
                  return (
                    <Grid
                      key={student.user.id}
                      item
                      xs={6}
                      xl={4}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <StudentCard className='card'>
                        <Box
                          component='span'
                          className='student_position'
                        >
                          {index + 1}
                        </Box>

                        <Box className='student_description'>
                          <Avatar
                            alt={student.user.name}
                            variant='rounded'
                            {...stringAvatar(student?.user?.name, 52, 52, 16)}
                          />

                          <Box className='student_description-school'>
                            <Typography
                              title={student?.user?.name}
                            >
                              {student?.user?.name}
                            </Typography>
                            <Typography
                              title={`${student.school_classroom.grade.name} / ${student.school_classroom.school_unit.name}`}
                            >
                              {student.school_classroom.grade.name} / {student.school_classroom.school_unit.name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className='student_stats'>
                          <Box className='student_stats-performance'>
                            <Typography
                              component='span'
                              className='label'
                            >
                              Desempenho:
                            </Typography>

                            <LinearProgress
                              variant='determinate'
                              value={student.performance ?? 0}
                              className='linearProgress_performance'
                            />

                            <Typography
                              component='span'
                              className='value'
                            >
                              {student.performance?.toFixed(1) ?? 0}%
                            </Typography>
                          </Box>

                          <Box className='student_stats-progress'>
                            <Typography
                              component='span'
                              className='label'
                            >
                              Progresso:
                            </Typography>

                            <LinearProgress
                              variant='determinate'
                              value={student.progress ?? 0}
                              className='linearProgress_progress'
                            />

                            <Typography
                              component='span'
                              className='value'
                            >
                              {student.progress?.toFixed(1) ?? 0}%
                            </Typography>
                          </Box>
                        </Box>
                      </StudentCard>
                    </Grid>
                  )
                })}
              </Grid>
            </StudentsContainer>
          </DashedContainer>
        </>
      )}

      <Box sx={{ marginTop: '40px' }}>
        <FiltersContainer>
          <Box className='filter'>
            <Typography component='label'>Turma:</Typography>
            <SimpleSelect
              className='classroom'
              placeholder='Pesquise pelo nome da turma...'
              hasBorder
              hasBackground
              maxWidth='100%'
              isClearable
              options={classrooms ?? []}
              onChange={handleSelectClassroom}
              value={classroomSelected}
            />
          </Box>

          <Box className='filter'>
            <Typography component='label'>Aluno:</Typography>
            <SimpleSelect
              className='student'
              placeholder='Pesquise pelo nome do aluno...'
              hasBorder
              hasBackground
              maxWidth='100%'
              isClearable
              options={studentOptions ?? []}
              onChange={handleSelectStudent}
              value={studentSelected}
            />
          </Box>
        </FiltersContainer>

        {classroomStudents.isFetching && (
          <StudentsListLoading />
        )}

        {!classroomStudents.isFetching && !classroomStudents.isError && (
          <Box>
            <Grid container marginBottom={2} sx={{ paddingInline: '24px' }}>
              <Grid item xs={5}>
                <Typography sx={{ marginLeft: '10%' }}>Aluno</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ textAlign: 'center' }}>Início</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ textAlign: 'center' }}>Fim</Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ textAlign: 'center' }}>Tempo</Typography>
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {studentItems?.map((student) => {
                return (
                  <StudentReportCollapse
                    key={student.user.id}
                    studentData={student}
                    bookId={Number(id)}
                  />
                )
              })}
            </Box>
          </Box>
        )}

        {!classroomStudents.isFetching && classroomStudents.isError && (
          <ErrorMessageBox>
            <Box className='image'>
              <img src={NotFoundStudentImage} alt='ícone' />
            </Box>

            <Typography className='message'>
              Ops! Parece que não há registros de aluno(s) para este livro.
            </Typography>
          </ErrorMessageBox>
        )}
      </Box>

      {totalOfPages && totalOfPages > 1 && (
        <Box className='pagination'>
          <Pagination
            count={totalOfPages}
            size='small'
            color='primary'
            page={currentPage}
            onChange={handleChangePage}
          />
        </Box>
      )}

      <Box className='btn_container'>
        <Button
          type='button'
          variant='contained'
          onClick={handleBackPage}
          className='btn_back'
        >
          Voltar
        </Button>
      </Box>
    </Container>
  )
}

export default BooksReportStudentsPerformanceContainer
