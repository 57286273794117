import { createAction } from '@reduxjs/toolkit'
import { JsonFormat } from '../../utils/types'
import { IModule } from './../../models/IModule'
import { withPayloadType } from '../../utils/functions'

export type ModuleJson = JsonFormat<'modules', IModule>

export interface ModuleFetchPayload {
  id: string | number
}

export interface FetchModulesSuccessPayload {
  items: []
  totalOfPages?: number | string
  current?: number | string
  total?: number | string
  per?: number | string
}

export interface FetchModuleSuccessPayload {
  currentItem: IModule
}

export interface FetchModulesFiltersPayload {
  terms?: string | null
  ids?: string | number
  page?: number
  per?: number
  subject_ids?: any
}

export const modulesItemsFetchRequest = createAction('modules/ITEMS_FETCH_REQUEST', withPayloadType<FetchModulesFiltersPayload>())
export const modulesItemsFetchSuccess = createAction('modules/ITEMS_FETCH_SUCCESS', withPayloadType<FetchModulesSuccessPayload>())
export const modulesItemsFetchError = createAction('modules/ITEMS_FETCH_ERROR')

export const modulesSaveUiFilters = createAction('modules/SAVE_UI_FILTERS', withPayloadType<FetchModulesFiltersPayload>())
export const modulesResetFilters = createAction('modules/RESET_FILTERS')

export const clearCurrentItem = createAction('modules/CLEAR_CURRENT_ITEM')

export const fetchModuleByCodeRequest = createAction('modules/FETCH_MODULE_BY_CODE_REQUEST', withPayloadType<ModuleFetchPayload>())
export const fetchModuleByCodeSuccess = createAction('modules/FETCH_MODULE_BY_CODE_SUCCESS', withPayloadType<FetchModulesSuccessPayload>())
export const fetchModuleByCodeError = createAction<any>('modules/FETCH_MODULE_BY_CODE_ERROR')

export const fetchModuleByIdRequest = createAction('modules/FETCH_MODULE_BY_ID_REQUEST', withPayloadType<ModuleFetchPayload>())
export const fetchModuleByIdSuccess = createAction('modules/FETCH_MODULE_BY_ID_SUCCESS', withPayloadType<FetchModuleSuccessPayload>())
export const fetchModuleByIdError = createAction('modules/FETCH_MODULE_BY_ID_ERROR')

export const importModuleRequest = createAction('modules/IMPORT_MODULE_REQUEST', withPayloadType<ModuleFetchPayload>())
export const importModuleSuccess = createAction('modules/IMPORT_MODULE_SUCCESS')
export const importModuleError = createAction('module/IMPORT_MODULE_ERROR')
