import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

type Props = FieldRenderProps<string, any> & {
  maxCharacters?: number;
};

const TextAreaStyled = styled('input')(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.text.secondary}`,
  fontSize: 14,
  fontFamily: 'Montserrat, sans-serif',
  color: '#9F9F9F',
  padding: '12px 14px',
  height: '40px',
  width: '100%',
  '&::placeholder': {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400
  },
  '&:focus-visible': {
    outlineColor: theme.palette.primary.main,
  },
  '&:disabled': {
    backgroundColor: '#F0F3F4',
  },
}))

const CpfInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
  label,
  maxCharacters,
  ...rest
}: Props) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target

    value = value.replace(/\D/g, '')

    if (value.length > 3) {
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
    }
    if (value.length > 6) {
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
    }
    if (value.length > 9) {
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    }

    input.onChange(value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      {label && (
        <Typography
          sx={{
            color: '#9F9F9F',
            mb: 1,
            fontSize: 14,
            fontWeight: 500,
          }}
          variant='body2'
          component='label'
        >
          {label}
        </Typography>
      )}
      <TextAreaStyled
        id={`textarea-${input.name}`}
        {...input}
        {...rest}
        onChange={handleInputChange}
        maxLength={14}
      />
      {touched && error && (
        <Typography
          align='left'
          component='p'
          variant='caption'
          sx={{
            color: '#F69E9E',
          }}
        >
          {error}
        </Typography>
      )}
      {maxCharacters && input.value && input.value.length >= maxCharacters && (
        <Typography
          align='left'
          component='p'
          variant='caption'
          sx={{
            color: '#F69E9E',
          }}
        >
          máx. {maxCharacters} caracteres.
        </Typography>
      )}
    </Box>
  )
}

export default CpfInput
